import React, { useEffect, useState } from "react";
import Header from "./HeaderNavbar";
import Footer from "./Footer";
import loadingGif from "../../Assets/loading-gif.gif";
import { CSmartTable } from "@coreui/react-pro";
import { useLocation, useNavigate } from "react-router-dom";
import StLogo from "../../Assets/student_pic.png";
import {
  getDynamicTimetable,
  getDynamicTimetableByStaff,
  getDynamicTimetableByDate,
  getDynamicTimetableByStaffWithDate,
  getUnitSessionNameFromtable,
} from "../../services/Apiservices";
import { format } from "date-fns";
import { HiEllipsisVertical, HiOutlineCalendarDays } from "react-icons/hi2";
import { TiTickOutline } from "react-icons/ti";
import { TbRepeat } from "react-icons/tb";
// import { IoIosCloseCircle } from "react-icons/io";
import { IoIosClose } from "react-icons/io";
import {
  AiOutlineLeft,
  AiOutlineRight,
  AiOutlineArrowLeft,
  AiOutlineDown,
  AiOutlineUp,
  AiOutlineSearch,
  AiOutlineSolution,
} from "react-icons/ai";
import { FaCaretLeft, FaCaretRight } from "react-icons/fa";
import { CContainer } from "@coreui/react-pro";
import { IoVideocam, IoCalendarOutline } from "react-icons/io5";
import {
  BsFillPlusCircleFill,
  BsChevronDown,
  BsBook,
  BsCalendar2Date,
  BsChevronRight,
} from "react-icons/bs";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import { BiTime } from "react-icons/bi";
import { AiOutlineCloseCircle } from "react-icons/ai";
import PosterImg from "../../Assets/Poster-Img.png";
import FramePoster from "../../Assets/FramePoster.png";
import OffImg from "../../Assets/OffImg.png";
import SclLogo from "../../Assets/SchoolLogo.png";
import GMedal from "../../Assets/GoldMedal.png";
import SMedal from "../../Assets/SilverMedal.png";
import BMedal from "../../Assets/BronzeMedal.png";
import SImg1 from "../../Assets/Stu-Img1.png";
import SImg2 from "../../Assets/Stu-Img2.png";
import SImg3 from "../../Assets/Stu-Img3.png";
import Sample from "../../Assets/sample_Img.png";
import moment from "moment";
import { Authcontext } from "./Context/Context";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Fade from "react-reveal/Fade";
import { CModal, CModalHeader } from "@coreui/react";
import Select from "react-select";
import { toast } from "react-toastify";
import Danger from "../components/Toastify/Danger";

const ResultData = ({ unitID, sessionID }) => {
  const [data, setData] = React.useState("");
  // const [Subdata,setSubData] = React.useState('')

  React.useEffect(() => {
    const asynccc = async () => {
      let getUnitVales = {
        uId: unitID,
        sId: sessionID,
      };

      let getresultUnits = await getUnitSessionNameFromtable(getUnitVales);
      // console.log(getresultUnits,"QQQQQQQQQQQQQQQQQQQQQQQ");

      // let variName = await getresultUnits.unit.variableName;
      setData(getresultUnits);
      // setSubData(getresultUnits.session.variableName)
    };
    asynccc();
  }, [unitID, sessionID]);

  return (
    <>
      <div className="Unit-Font">
        {/* {data.unit ? data.unit.variableName : ""} */}
        {data.unit
          ? data.unit.variableName.toString().length > 40
            ? data.unit.variableName.toString().slice(0, 40) + "..."
            : data.unit.variableName
          : ""}
      </div>
      <div className="Unit-Font">
        {data.session ? data.session.variableName : ""}
      </div>
    </>
  );
};

const ResultDataView = ({ unitID, sessionID }) => {
  const [data, setData] = React.useState("");
  // const [Subdata,setSubData] = React.useState('')

  React.useEffect(() => {
    const asynccc = async () => {
      let getUnitVales = {
        uId: unitID,
        sId: sessionID,
      };

      let getresultUnits = await getUnitSessionNameFromtable(getUnitVales);
      // console.log(getresultUnits,"QQQQQQQQQQQQQQQQQQQQQQQ");

      // let variName = await getresultUnits.unit.variableName;
      setData(getresultUnits);
      // setSubData(getresultUnits.session.variableName)
    };
    asynccc();
  }, [unitID, sessionID]);

  return (
    <>
      <div>
        <span className="UnitFontNumber">
          {data.unit ? data.unit.variableName : ""}
        </span>
      </div>
      <div>
        <span className="UnitFontNumber color_schedule">
          {data.session ? data.session.variableName : ""}
        </span>
      </div>
    </>
  );
};

const ResultDataWeek = ({ unitID, sessionID }) => {
  const [data, setData] = React.useState("");
  // const [Subdata,setSubData] = React.useState('')

  React.useEffect(() => {
    const asynccc = async () => {
      let getUnitVales = {
        uId: unitID,
        sId: sessionID,
      };

      let getresultUnits = await getUnitSessionNameFromtable(getUnitVales);
      // console.log(getresultUnits,"QQQQQQQQQQQQQQQQQQQQQQQ");

      // let variName = await getresultUnits.unit.variableName;
      setData(getresultUnits);
      // setSubData(getresultUnits.session.variableName)
    };
    asynccc();
  }, [unitID, sessionID]);

  return (
    <>
      <span className="boxsec">
        {/* {data.unit ? data.unit.variableName : ""} */}
        {data.unit
          ? localStorage.length > 0 &&
            JSON.parse(localStorage.userCreds).userType === "Teaching"
            ? data.unit.variableName
            : data.unit.variableName.toString().length > 25
            ? data.unit.variableName.toString().slice(0, 25) + "..."
            : data.unit.variableName
          : ""}
      </span>
      <br />
      <span className="boxsec">
        {/* {data.session ? data.session.variableName : ""} */}
        {data.session
          ? localStorage.length > 0 &&
            JSON.parse(localStorage.userCreds).userType === "Teaching"
            ? data.session.variableName
            : data.session.variableName.toString().length > 15
            ? data.session.variableName.toString().slice(0, 15) + "..."
            : data.session.variableName
          : ""}
      </span>
    </>
  );
};

const NewDashboard = ({
  VideoConfFunc,
  tConvert,
  CuttentDateTime,
  tableBgColors,
  openBookview,
  showProfile,
  falseShowProfile,
  notofShowProfile,
  ShowNotifications,
  showNotification,
  falseShowNotification,
  disableOnOuterTouch,
  shoq,
  KpostIframe,
  closeForm,
  openForm,
  trueShowNotification,
  goToMainRoutes
}) => {
  const navigate = useNavigate();
  const { state, dispatch } = React.useContext(Authcontext);
  // console.log(state,"CONTEXT STORAGE");
  const [userCredsvalues, setuserCredsvalues] = useState("");
  const [timeTableData, settimeTableData] = useState(state?.DayTimetable.length > 0 ? state?.DayTimetable : []);
  const [pendSession, setpendSession] = useState([]);
  const [weektimeTableData, setweektimeTableData] = useState(state?.WeekTimetable.length > 0 ? state?.WeekTimetable : []);
  const [selectedIndexValue, setselectedIndexValue] = useState(0);
  const [timeTableDataLength, settimeTableDataLength] = useState(state?.DayTimetable ? state?.DayTimetable.length : 0);
  const [timeTablePeriod, settimeTablePeriod] = useState(state?.DayTimeTablePeriod.length > 0 ? state?.DayTimeTablePeriod : []);
  const [weektimeTablePeriod, setweektimeTablePeriod] = useState(state?.WeekTimeTablePeriod.length > 0 ? state?.WeekTimeTablePeriod : []);
  const [weektimeTablePeriodUnique, setweektimeTablePeriodUnique] = useState(
    []
  );
  const [singleDateValue, setsingleDateValue] = useState([]);
  const [dateEnable, setdateEnable] = useState("");
  const [selectedDate, setselectedDate] = useState("");
  const [calendar, setCalendar] = useState(new Date());
  const [prevTimeTableData, setprevTimeTableData] = useState([]);
  const [nextDateTimeTbale, setnextDateTimeTbale] = useState(state?.WeekTimetable.length > 0 ? Object.keys(state?.WeekTimetable)[
    Object.keys(state?.WeekTimetable).length - 1
  ].split(" ")[1] : "");
  const [prevDateTimeTable, setprevDateTimeTable] = useState(state?.WeekTimetable.length > 0 ? Object.keys(state?.WeekTimetable)[0].split(" ")[1] : "");
  const [TimeTablechangeCount, setTimeTablechangeCount] = useState(0);
  const [viewMore, setViewMore] = useState(false);
  const [overAll, setOverAll] = useState(true);
  const [birthday, setBirthday] = useState(false);
  const [isLoading, setisLoading] = useState(state?.DayTimetable.length > 0 ? false : true);

  const [myClass, setMyClass] = useState({
    one: false,
    two: false,
  });
  const [myClassa, setMyClassa] = useState({
    one: false,
    two: false,
  });

  const [examSchedule, setExamSchedule] = useState(false);
  // const [assignAccordian, setAssignAccordian] = useState(true);
  const yearMonth = format(new Date(), "dd MMM, yyyy");
  const [viewMoreAchievements, setViewMoreAchievements] = useState(false);
  const [viewMoreAttendance, setViewMoreAttendance] = useState(false);
  const [viewMoreDiary, setViewMoreDiary] = useState(false);
  const [viewMoreExamResult, setViewMoreExamResult] = useState(false);
  const [viewMoreAssignment, setViewMoreAssignment] = useState(false);

  const [AllAchive1, setAllAchive1] = useState(false);
  const [AllAchive2, setAllAchive2] = useState(true);
  const [AllAchive3, setAllAchive3] = useState(false);
  const [AccdamicDec, setAccdamicDec] = useState(true);
  const [AccdamicDec2, setAccdamicDec2] = useState(true);

  const [selectedtimetableDate, setselectedtimetableDate] = useState(
    moment().format("DD/MMM/YYYY")
  );

  const [repeatDropdown, setRepeatDropdown] = useState("");

  const [hide, setHide] = useState({
    one: false,
  });
  const [style, setStyle] = useState({
    background: "#F4F4F4",
  });

  const [styleTwo, setStyleTwo] = useState({
    background: "none",
    color: "none",
  });

  const [styleTwio, setStyleTwio] = useState({
    background: "none",
    color: "none",
  });

  const [card, setCard] = useState({
    one: true,
    date: false,
    subject: false,
  });

  const usersData = [
    {
      sub: "Physics",
      ass: "Assignment 1",
      assi: "Girija BalaSubramani",
      date: "19-01-2023",
      submi: "18-01-2023",
      tot: 25,
      mar: 30,
    },
    {
      sub: "Tamil",
      ass: "Assignment 1",
      assi: "Girija BalaSubramani",
      date: "19-01-2023",
      submi: "18-01-2023",
      tot: 25,
      mar: 30,
    },
  ];
  const usersDataO = [
    {
      sub: "Physics",
      ass: "Assignment 1",
      assi: "Girija BalaSubramani",
      date: "19-01-2023",
      submi: "18-01-2023",
      tot: 25,
      mar: 30,
    },
  ];

  const columnsO = [
    {
      key: "sub",
      label: "Subject",
    },

    {
      key: "ass",
      label: "Assignment",
    },
    {
      key: "assi",
      label: "Assigned By",
    },
    {
      key: "date",
      label: "Date of Submission",
    },
    {
      key: "submi",
      label: "Submitted Date",
    },
    {
      key: "tot",
      label: "Total Marks",
    },
    {
      key: "mar",
      label: "Marks Obtained",
    },
  ];

  const columns = [
    {
      key: "sub",
      label: "Subject",
    },

    {
      key: "ass",
      label: "Assignment",
    },
    {
      key: "assi",
      label: "Assigned By",
    },
    {
      key: "date",
      label: "Date of Submission",
    },
    {
      key: "submi",
      label: "Submitted Date",
    },
    {
      key: "tot",
      label: "Total Marks",
    },
    {
      key: "mar",
      label: "Marks Obtained",
    },
  ];

  const [weeklyTable, setWeeklyTable] = useState(true);

  const diarySubMEnu = [
    {
      time: "10:30 - 11:00",
      content: " UI / UX Design for Diary Concept",
      secContent:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type.",
    },
    {
      time: "11:30 - 12:00",
      content: " UI / UX Design for Diary Concept",
      secContent:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ",
    },
    {
      time: "14:30 - 15:00",
      content: " UI / UX Design for Diary Concept",
      secContent:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    },
  ];

  let AttendanceView;

  if (
    localStorage.length > 0 &&
    JSON.parse(localStorage.userCreds).userType === "Student"
  ) {
    AttendanceView = [
      {
        name: "Present",
        number: "82",
        next: "10",
      },
      {
        name: "Absent",
        number: "5",
        next: "2",
      },
      {
        name: "Total Working Days",
        number: "288",
        next: "20",
      },
    ];
  } else {
    AttendanceView = [
      {
        name: "Present",
        number: "120",
        next: "30",
      },
      {
        name: "Absent",
        number: "24",
        next: "6",
      },
      {
        name: "Total Working Days",
        number: "288",
        next: "20",
      },
    ];
  }

  const hieghtChart = [
    "260px",
    "230px",
    "195px",
    "246px",
    "207px",
    "230px",
    "207px",
    "207px",
    "194px",
    "201px",
    "201px",
    "81px",
  ];

  const hieghtChartMobile = [
    "170px",
    "150px",
    "135px",
    "160px",
    "147px",
    "150px",
    "147px",
    "147px",
    "135px",
    "140px",
    "140px",
    "35px",
  ];

  const [isHovering, setIsHovering] = useState(false);

  const ChartMonth = [
    {
      name: "Jan",
    },
    {
      name: "Feb",
    },
    {
      name: "Mar",
    },
    {
      name: "Apr",
    },
    {
      name: "May",
    },
    {
      name: "Jun",
    },
    {
      name: "Jul",
    },
    {
      name: "Aug",
    },
    {
      name: "Sep",
    },
    {
      name: "Oct",
    },
    {
      name: "Nov",
    },
    {
      name: "Dec",
    },
  ];

  const colorsAttendance = ["#05A26F", "#FB4C4C", "#3073F9"];

  const [visible, setVisible] = useState(false);
  const [Post, setPost] = useState(false);

  const ShowProfile = () => {
    notofShowProfile();
  };

  const DayDate = [
    {
      date: 19,
      mon: "Sep",
      Sub: "English",
      time: "(10:00 am - 10:45 am)",
      unit: "Life of PI",
    },
    {
      date: 22,
      mon: "Sep",
      Sub: "Maths",
      time: "(10:45 am - 11:30 am)",
      unit: "Matrices",
    },
    {
      date: 24,
      mon: "Sep",
      Sub: "Physics",
      time: "(11:30 am - 12:15 pm)",
      unit: "Electrostatistics",
    },
  ];

  const DashboardSubject = [
    {
      date: "06 Sep 2022",
      Subject: "Maths",
      banner: "Fourier Series half Range",
    },
    {
      date: "07 Sep 2022",
      Subject: "Tamil",
      banner: "Fourier Series half Range",
    },
    {
      date: "08 Sep 2022",
      Subject: "English",
      banner: "Fourier Series half Range",
    },
    {
      date: "09 Sep 2022",
      Subject: "Chemistry",
      banner: "Fourier Series half Range",
    },
    {
      date: "10 Sep 2022",
      Subject: "Physics",
      banner: "Fourier Series half Range",
    },
  ];

  const AssignmentViewMore = [
    {
      Subject: "General English",
      Time: "09:00 am - 09:45 am",
      UnitNumber: 11,
      post: "Posted date",
      postq: "Submission date",

      postDate: moment().format("DD MMM YYYY"),
      postqDate: "25 Apr 2023",

      Topic: "Hydroxy Compounds and Ethers",
      Session: 2,
      Name: "Sheeba V",
    },
    {
      Subject: "Tamil",
      Time: "09:45 am - 10:30 am",
      UnitNumber: 3,
      post: "Posted date",
      postq: "Submission date",

      postDate: moment().format("DD MMM YYYY"),
      postqDate: "25 Apr 2023",

      Topic: "Theory of Equations",
      Session: 10,
      Name: "Sheeba V",
    },
    {
      Subject: "Mathematics",
      Time: "10:45 am - 11:30 am",
      UnitNumber: 8,
      post: "Posted date",
      postq: "Submission date",

      postDate: moment().format("DD MMM YYYY"),
      postqDate: "25 Apr 2023",

      Topic: "Biotechnology",
      Session: 14,
      Name: "Sheeba V",
    },
    {
      Subject: "Bio - Zoology",
      Time: "11:30 am - 12:40 pm",
      UnitNumber: 11,
      post: "Posted date",
      postq: "Submission date",

      postDate: moment().format("DD MMM YYYY"),
      postqDate: "25 Apr 2023",

      Topic: "Hydroxy Compounds and Ethers",
      Session: 3,
      Name: "Sheeba V",
    },
  ];

  const ExamScheduleVM = [
    {
      subject: "General English",
      date: "19 sep 2022",
      time: "(10:00 am - 01:00 pm)",
      unit: "Hydroxy Compounds and Ethers",
      name: "Sheeba V",
    },
    {
      subject: "Tamil",
      date: "23 sep 2022",
      time: "(10:00 am - 01:00 pm)",
      unit: "Hydroxy Compounds and Ethers",
      name: "Sheeba V",
    },
    {
      subject: "Mathematics",
      date: "24 sep 2022",
      time: "(10:00 am - 01:00 pm)",
      unit: "Hydroxy Compounds and Ethers",
      name: "Sheeba V",
    },
    {
      subject: "Bio - Zoology",
      date: "25 sep 2022",
      time: "(10:00 am - 01:00 pm)",
      unit: "Hydroxy Compounds and Ethers",
      name: "Sheeba V",
    },
    {
      subject: "Bio - Botany",
      date: "27 sep 2022",
      time: "(10:00 am - 01:00 pm)",
      unit: "Hydroxy Compounds and Ethers",
      name: "Sheeba V",
    },
    {
      subject: "Chemistry",
      date: "28 sep 2022",
      time: "(10:00 am - 01:00 pm)",
      unit: "Hydroxy Compounds and Ethers",
      name: "Sheeba V",
    },
    {
      subject: "Physics",
      date: "29 sep 2022",
      time: "(10:00 am - 01:00 pm)",
      unit: "Hydroxy Compounds and Ethers",
      name: "Sheeba V",
    },
  ];

  const DiarySchool = [
    {
      Time: "10:30 am - 11:00 am",
      Compet: "School Chess Competition",
      Concept: "UI / Ux design for Diary Concept",
    },
    {
      Time: "11:00 am - 11:30 am",
      Compet: "School Culturals Competition",
      Concept: "UI / Ux design for Diary Concept",
    },
    {
      Time: "11:30 am - 12:00 pm",
      Compet: "School Viva Competition",
      Concept: "UI / Ux design for Diary Concept",
    },
    {
      Time: "12:00 pm - 12:30 pm",
      Compet: "School Annual Day Competition",
      Concept: "UI / Ux design for Diary Concept",
    },
    {
      Time: "12:30 pm - 01:00 pm",
      Compet: "School Dance Competition",
      Concept: "UI / Ux design for Diary Concept",
    },
  ];

  const SchoolPoster = [
    {
      name: "Wesley School",
      mail: "@Wesley school",
      day: "#School",
      sport: "#Results",
      content:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text.",
      time: "1hr",
    },
    {
      name: "Wesley School",
      mail: "@Wesley school",
      day: "#sportsday",
      sport: "#football",
      content:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text.",
      time: "5hr",
    },
    {
      name: "Wesley School",
      mail: "@Wesley school",
      day: "#Holiday",
      sport: "#Diwali",
      content:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text.",
      time: "10hr",
    },
  ];

  const ExamSubject = [
    {
      subject: "English",
      color: "linear-gradient(180deg, #28DAA0 0%, #05A26F 99.99%)",
      count: "70",
      width: "70%",
    },
    {
      subject: "Tamil",
      color: "linear-gradient(180deg, #FF8F88 0%, #FC361B 100%)",
      // color:"linear-gradient(180deg, #28DAA0 0%, #05A26F 99.99%);",
      count: "30",
      width: "30%",
    },
    {
      subject: "Maths",
      color: "linear-gradient(180deg, #28DAA0 0%, #05A26F 99.99%)",
      count: "90",
      width: "90%",
    },
    {
      subject: "Physics",
      color: "linear-gradient(180deg, #28DAA0 0%, #05A26F 99.99%)",
      count: "64",
      width: "64%",
    },
    {
      subject: "Chemistry",
      color: "linear-gradient(180deg, #FF8F88 0%, #FC361B 100%)",
      // color:"linear-gradient(180deg, #28DAA0 0%, #05A26F 99.99%);",
      count: "20",
      width: "20%",
    },
    {
      subject: "Botany",
      color: "linear-gradient(180deg, #28DAA0 0%, #05A26F 99.99%)",
      count: "80",
      width: "80%",
    },
    {
      subject: "Zoology",
      color: "linear-gradient(180deg, #28DAA0 0%, #05A26F 99.99%)",
      count: "72",
      width: "72%",
    },
  ];

  const Dates = [
    {
      date: "1",
    },
    {
      date: "2",
    },
    {
      date: "3",
    },
    {
      date: "4",
    },
    {
      date: "5",
    },
    {
      date: "6",
    },
    {
      date: "7",
    },
    {
      date: "8",
    },
    {
      date: "9",
    },
    {
      date: "10",
    },
    {
      date: "11",
    },
    {
      date: "12",
    },
    {
      date: "13",
    },
    {
      date: "14",
    },
    {
      date: "15",
    },
    {
      date: "16",
    },
    {
      date: "17",
    },
    {
      date: "18",
    },
    {
      date: "19",
    },
    {
      date: "20",
    },
    {
      date: "21",
    },
    {
      date: "22",
    },
    {
      date: "23",
    },
    {
      date: "24",
    },
    {
      date: "25",
    },
    {
      date: "26",
    },
    {
      date: "27",
    },
    {
      date: "28",
    },
    {
      date: "29",
    },
    {
      date: "30",
    },
    {
      date: "31",
    },
  ];

  const NameClass = [
    {
      name: "Lilly",
      class: "XII - B",
      wish: "Send Wishes",
    },
    {
      name: "Pooja",
      class: "XII - B",
      wish: "Send Wishes",
    },
    {
      name: "Akila",
      class: "XII - B",
      wish: "Send Wishes",
    },
  ];

  const DropDownValuesChedule = [
    {
      value: "Half Yearly Exam (September - 2023) ",
    },
    {
      value: "Quaterly Exam (August - 2023) ",
    },
    {
      value: "Ist Mid-Term (may - 2023)",
    },
    {
      value: "IInd Mid-Term (June - 2023)",
    },
    {
      value: "IIIrd Mid-Term (July - 2023)",
    },
  ];
  const [examResultDrop, setExamResultDrop] = useState({
    one: true,
    two: true,
    three: true,
    four: true,
    five: true,
  });
  const valueChangeExam = (e) => {
    if (e.value === "all") {
      setExamResultDrop({
        one: true,
        two: true,
        three: true,
        four: true,
        five: true,
      });
    }
    if (e.value === "Ist") {
      setExamResultDrop({
        one: true,
        two: false,
        three: false,
        four: false,
        five: false,
      });
    }
    if (e.value === "IInd") {
      setExamResultDrop({
        one: false,
        two: true,
        three: false,
        four: false,
        five: false,
      });
    }
    if (e.value === "IIIrd") {
      setExamResultDrop({
        one: false,
        two: false,
        three: true,
        four: false,
        five: false,
      });
    }
    if (e.value === "Quaterly") {
      setExamResultDrop({
        one: false,
        two: false,
        three: false,
        four: true,
        five: false,
      });
    }
    if (e.value === "Half") {
      setExamResultDrop({
        one: false,
        two: false,
        three: false,
        four: false,
        five: true,
      });
    }
  };

  const DropDownValuesexam = [
    {
      value: "all",
      label: "All",
    },
    {
      value: "Ist",
      label: "Ist Mid-Term (may - 2023)",
    },
    {
      value: "IInd",
      label: "IInd Mid-Term (June - 2023)",
    },
    {
      value: "IIIrd",
      label: "IIIrd Mid-Term (July - 2023)",
    },
    {
      value: "Quaterly",
      label: "Quaterly Exam (August - 2023) ",
    },
    {
      value: "Half",
      label: "Half Yearly Exam (September - 2023) ",
    },
  ];

  const DiaryDropdown = [
    {
      value: "One Time",
    },
    {
      value: "Daily",
    },
    {
      value: "Weekly",
    },
    {
      value: "Monthly",
    },
  ];

  const Weekly = [
    {
      number: "1st",
    },
    {
      number: "2nd",
    },
    {
      number: "3rd",
    },
    {
      number: "4th",
    },
    {
      number: "5th",
    },
  ];

  const Days = [
    {
      day: "Sun",
    },
    {
      day: "Mon",
    },
    {
      day: "Tue",
    },
    {
      day: "Wed",
    },
    {
      day: "Thu",
    },
    {
      day: "Fri",
    },
    {
      day: "Sat",
    },
  ];

  const Months = [
    {
      month: "Jan",
    },
    {
      month: "Feb",
    },
    {
      month: "Mar",
    },
    {
      month: "Apr",
    },
    {
      month: "May",
    },
    {
      month: "Jun",
    },
    {
      month: "Jul",
    },
    {
      month: "Aug",
    },
    {
      month: "Sep",
    },
    {
      month: "Oct",
    },
    {
      month: "Nov",
    },
    {
      month: "Dec",
    },
  ];

  const NameClassUpcoming = [
    {
      name1: "Vijay M",
      class1: "VI - A",
      date: "15 Sep",
    },
    {
      name1: "Bala B",
      class1: "XII - A",
      date: "16 Sep",
    },
    {
      name1: "Dhilip M",
      class1: "VI - A",
      date: "15 Sep",
    },
    {
      name1: "Chandran K",
      class1: "VII - A",
      date: "15 Sep",
    },
    {
      name1: "Ram V",
      class1: "X - A",
      date: "15 Sep",
    },
  ];

  const BirthDate = [
    {
      name: "Abi V",
      class: "XII - B",
      date: "28 Dec 2003",
    },
    {
      name: "Deepak H",
      class: "XII - B",
      date: "28 Dec 2003",
    },
    {
      name: "Ajith C",
      class: "XII - B",
      date: "28 Dec 2003",
    },
    {
      name: "Sriram K",
      class: "XII - B",
      date: "29 Dec 2003",
    },
    {
      name: "Naveen M",
      class: "XII - B",
      date: "29 Dec 2003",
    },
    {
      name: "Kalai E",
      class: "XII - B",
      date: "30 Dec 2003",
    },
    {
      name: "Jai P",
      class: "XII - B",
      date: "31 Dec 2003",
    },
    {
      name: "Prabhu P",
      class: "XII - B",
      date: "31 Dec 2003",
    },
    {
      name: "Kumar J",
      class: "XII - B",
      date: "31 Dec 2003",
    },
    {
      name: "Raj A",
      class: "XII - B",
      date: "31 Dec 2003",
    },
  ];

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let location = useLocation();
  // console.log(location.state?.refInstID, "conss");

  const current = new Date();
  const date = ` ${current.getDate()} ${
    months[current.getMonth()]
  } ${current.getFullYear()}`;

  const CurrentDateItem = format(new Date(), "dd/MMM/yyyy");

  useEffect(() => {
    // console.log(localStorage, "YYYYYYYYYYYYYYYYYYYYYYYYYY");
    if (localStorage.length > 0) {
      let retrData = JSON.parse(localStorage.userCreds);

      setuserCredsvalues(retrData);

      // console.log(retrData, "YYYYYYYYYYYYYYYYYYYYYYYYYY");
      if (retrData) {
        dispatch({ type: "LOGIN", payload: retrData });
      }

      const getTimeTablefunc = async () => {
        let allquesbod, allquestres, weekallquesbod, weekallquestres;

        if (retrData.userType === "Teaching") {

          try {

          allquesbod = {
            refInstID: retrData.refInstID,
            refStaffID: retrData.staffID,
            refYearID: retrData.yearId,
            date: moment().format("DD/MM/YYYY"),
          };

          weekallquesbod = {
            refInstID: retrData.refInstID,
            refStaffID: retrData.staffID,
            refYearID: retrData.yearId,
          };

          allquestres = await getDynamicTimetableByStaffWithDate(allquesbod);

          weekallquestres = await getDynamicTimetableByStaff(weekallquesbod);

          } catch (err){
              console.log(err);
          }

        } else {

          try {

          allquesbod = {
            refInstID: retrData.refInstID,
            refMediumID: retrData.mediumId,
            refYearID: retrData.yearID,
            refSectionID: retrData.classID,
            refVariableID: retrData.variableID,
            date: moment().format("DD/MM/YYYY"),
          };

          weekallquesbod = {
            refInstID: retrData.refInstID,
            refMediumID: retrData.mediumId,
            refYearID: retrData.yearID,
            refSectionID: retrData.classID,
            refVariableID: retrData.variableID,
          };

          allquestres = await getDynamicTimetableByDate(allquesbod);

          weekallquestres = await getDynamicTimetable(weekallquesbod);

          } catch (err){
            console.log(err);
          }
        }

        // console.log(allquestres,"allquestres allquestres");
        if(allquestres.status === "403" || weekallquestres.status === "403"){
          goToMainRoutes();
        }

        if (allquestres.status === "success") {

          let TimeTableConData = {
            DayTimeTablePeriod: JSON.parse(allquestres.periodDetails).filter(
              (item) => item.breakName === undefined
            ),
            DayTimetable: allquestres.value.filter((item) => item.katbookSessionID !== null),
          }

          dispatch({ type: "DAY_TIMETABLE", payload: TimeTableConData });
          settimeTablePeriod(
            JSON.parse(allquestres.periodDetails).filter(
              (item) => item.breakName === undefined
            )
          );

          settimeTableData(
            allquestres.value.filter((item) => item.katbookSessionID !== null)
          );

          setselectedIndexValue(0);

          settimeTableDataLength(
            allquestres.value.filter((item) => item.katbookSessionID !== null)
              .length
          );
        }

        if (weekallquestres.status === "success") {

          let TimeTableConData = {
            WeekTimeTablePeriod: JSON.parse(weekallquestres.periodDetails).filter(
              (item) => item.breakName === undefined
            ),
            WeekTimetable: weekallquestres.value,
          }

          dispatch({ type: "WEEK_TIMETABLE", payload: TimeTableConData });

          setweektimeTablePeriod(
            JSON.parse(weekallquestres.periodDetails).filter(
              (item) => item.breakName === undefined
            )
          );

          setweektimeTableData(weekallquestres.value);

          if(weekallquestres.value.length > 0){
            setnextDateTimeTbale(
              Object.keys(weekallquestres.value)[
                Object.keys(weekallquestres.value).length - 1
              ].split(" ")[1]
            );
            setprevDateTimeTable(
              Object.keys(weekallquestres.value)[0].split(" ")[1]
            );
          }
          setTimeTablechangeCount(TimeTablechangeCount);
        }

        setisLoading(false);
      };

      getTimeTablefunc();
    } else {
    }
  }, []);

  const FetchNewTimeTableData = async (indexvals) => {
    setselectedIndexValue(indexvals);
  };

  const updateDates = async (updateDate) => {
    // alert(updateDate);
    // alert(moment(updateDate).format("DD/MM/YYYY"));
    setselectedtimetableDate(moment(updateDate).format("DD/MMM/YYYY"));

    let allquesbod, allquestres;

    if (userCredsvalues.userType === "Teaching") {
      allquesbod = {
        refInstID: userCredsvalues.refInstID,
        refStaffID: userCredsvalues.staffID,
        refYearID: userCredsvalues.yearId,
        date: moment(updateDate).format("DD/MM/YYYY"),
      };

      allquestres = await getDynamicTimetableByStaffWithDate(allquesbod);
    } else {
      allquesbod = {
        refInstID: userCredsvalues.refInstID,
        refMediumID: userCredsvalues.mediumId,
        refYearID: userCredsvalues.yearID,
        refSectionID: userCredsvalues.classID,
        refVariableID: userCredsvalues.variableID,
        date: moment(updateDate).format("DD/MM/YYYY"),
      };

      allquestres = await getDynamicTimetableByDate(allquesbod);
    }

    if (allquestres.status === "success") {
      settimeTablePeriod(
        JSON.parse(allquestres.periodDetails).filter(
          (item) => item.breakName === undefined
        )
      );

      settimeTableData(
        allquestres.value.filter((item) => item.katbookSessionID !== null)
      );

      setselectedIndexValue(0);

      settimeTableDataLength(
        allquestres.value.filter((item) => item.katbookSessionID !== null)
          .length
      );
    }
  };

  const fetchNextTimeTableList = async (nextDate, prevDate) => {
    // alert(nextDate);

    let allquesbod, allquestres;

    if (userCredsvalues.userType === "Teaching") {
      allquesbod = {
        refInstID: userCredsvalues.refInstID,
        refStaffID: userCredsvalues.staffID,
        refYearID: userCredsvalues.yearId,
        lastTimeTableDate: nextDate,
      };

      allquestres = await getDynamicTimetableByStaff(allquesbod);
    } else {
      allquesbod = {
        refInstID: userCredsvalues.refInstID,
        refMediumID: userCredsvalues.mediumId,
        refYearID: userCredsvalues.yearID,
        refSectionID: userCredsvalues.classID,
        refVariableID: userCredsvalues.variableID,
        lastTimeTableDate: nextDate,
      };

      allquestres = await getDynamicTimetable(allquesbod);
    }

    if (allquestres.status === "success") {
      if (prevTimeTableData.length <= TimeTablechangeCount) {
        let datestore = {
          count: TimeTablechangeCount + 1,
          date: prevDate,
        };
        prevTimeTableData.push(datestore);
      }
      console.log(prevTimeTableData, "prevTimeTableData");

      console.log(JSON.parse(allquestres.periodDetails)[0].breakName);
      console.log(
        JSON.parse(allquestres.periodDetails).filter(
          (item) => item.breakName === undefined
        )
      );
      setweektimeTablePeriod(
        JSON.parse(allquestres.periodDetails).filter(
          (item) => item.breakName === undefined
        )
      );
      setweektimeTableData(allquestres.value);

      if(allquestres.value.length > 0){

      setnextDateTimeTbale(
        Object.keys(allquestres.value)[
          Object.keys(allquestres.value).length - 1
        ].split(" ")[1]
      );

      setprevDateTimeTable(Object.keys(allquestres.value)[0].split(" ")[1]);

      }

      setTimeTablechangeCount(TimeTablechangeCount + 1);

      console.log(TimeTablechangeCount + 1, "prevTimeTableData");
    }
  };

  const fetchPrevTimeTableList = async () => {
    // alert(moment(prevTimeTableData[TimeTablechangeCount - 1].date).subtract(1, 'day').format("DD/MMM/YYYY"))
    let allquesbod, allquestres;

    if (userCredsvalues.userType === "Teaching") {
      allquesbod = {
        refInstID: userCredsvalues.refInstID,
        refStaffID: userCredsvalues.staffID,
        refYearID: userCredsvalues.yearId,
        lastTimeTableDate: moment(
          prevTimeTableData[TimeTablechangeCount - 1].date
        )
          .subtract(1, "day")
          .format("DD/MMM/YYYY"),
      };

      allquestres = await getDynamicTimetableByStaff(allquesbod);
    } else {
      allquesbod = {
        refInstID: userCredsvalues.refInstID,
        refMediumID: userCredsvalues.mediumId,
        refYearID: userCredsvalues.yearID,
        refSectionID: userCredsvalues.classID,
        refVariableID: userCredsvalues.variableID,
        lastTimeTableDate: moment(
          prevTimeTableData[TimeTablechangeCount - 1].date
        )
          .subtract(1, "day")
          .format("DD/MMM/YYYY"),
      };

      allquestres = await getDynamicTimetable(allquesbod);
    }

    if (allquestres.status === "success") {
      console.log(prevTimeTableData, "prevTimeTableData");

      console.log(JSON.parse(allquestres.periodDetails)[0].breakName);
      console.log(
        JSON.parse(allquestres.periodDetails).filter(
          (item) => item.breakName === undefined
        )
      );
      setweektimeTablePeriod(
        JSON.parse(allquestres.periodDetails).filter(
          (item) => item.breakName === undefined
        )
      );
      setweektimeTableData(allquestres.value);

      if(allquestres.value.length > 0){

      setnextDateTimeTbale(
        Object.keys(allquestres.value)[
          Object.keys(allquestres.value).length - 1
        ].split(" ")[1]
      );

      setprevDateTimeTable(Object.keys(allquestres.value)[0].split(" ")[1]);

      }

      setTimeTablechangeCount(TimeTablechangeCount - 1);

      console.log(TimeTablechangeCount - 1, "prevTimeTableData");
    }
  };

  const instDetail = localStorage.getItem("instDetail");
  const userInstDetail = JSON.parse(instDetail);

  const NextPendingSessions = async(x) =>{

    if(pendSession.length > 0 && pendSession.filter((q) => String(q.timetableId) === String(x.id)).length > 0){
      let objIndex = pendSession.findIndex((obj) => String(obj.timetableId) === String(x.id));
      let prevCount = pendSession[objIndex].pendingsessioncount;
      if(prevCount === 1){
      let RemainSessions = pendSession.filter((q) => String(q.timetableId) !== String(x.id))
      setpendSession([...RemainSessions]);
      console.log([...pendSession],"Pending session details");

    } else {

      let RemainSessions = pendSession.filter((q) => String(q.timetableId) !== String(x.id))
      let bod = {
        "timetableId": x.id,
        "pendingsessioncount" : prevCount - 1
      }
      setpendSession([...RemainSessions, bod]);
      console.log([...pendSession, bod],"Pending session details");
      
    }

    }
  }

  const viewPendingSessions = async(x) =>{
    console.log(x,"Pending session details");
    console.log(pendSession,"Pending session details");
    console.log(pendSession.length,"Pending session details");
    if(pendSession.length > 0 && pendSession.filter((q) => String(q.timetableId) === String(x.id)).length > 0){
      let objIndex = pendSession.findIndex((obj) => String(obj.timetableId) === String(x.id));
      let prevCount = pendSession[objIndex].pendingsessioncount;
      let RemainSessions = pendSession.filter((q) => String(q.timetableId) !== String(x.id))
      // console.log(pendSession[objIndex],"Pending session details");
      // console.log(pendSession[objIndex].pendingsessioncount,"Pending session details");
      // pendSession[objIndex].pendingsessioncount = pendSession[objIndex].pendingsessioncount + 1;
      // console.log(pendSession,"Pending session details");
      // setpendSession(pendSession);

      let bod = {
        "timetableId": x.id,
        "pendingsessioncount" : prevCount + 1
      }
      setpendSession([...RemainSessions, bod]);
      console.log([...pendSession, bod],"Pending session details");
    } else {
      let bod = {
        "timetableId": x.id,
        "pendingsessioncount" : 1
      }
      setpendSession([...pendSession, bod]);
      console.log([...pendSession, bod],"Pending session details");
    }
  }

  // console.log(JSON.parse(localStorage.userCreds).institutionName.toUpperCase())

  // Header Functions End
  return (
    <div style={{ overflow: "hidden" }} onClick={() => disableOnOuterTouch()}>
      <div className="DisplayNone">
        <Header
          showProfile={showProfile}
          // profileIcon={KpIcon}
          // username={state.adminName ? state.adminName : state.user}
          falseShowProfile={falseShowProfile}
          notofShowProfile={notofShowProfile}
          institutionName={
            localStorage.length > 0 &&
            JSON.parse(localStorage.userCreds).institutionName.toUpperCase()
          }
          ShowNotifications={ShowNotifications}
          showNotification={showNotification}
          falseShowNotification={falseShowNotification}
          trueShowNotification={trueShowNotification}
          userType={userCredsvalues?.userType}
        />
      </div>

      <div className="DisplayNoneWeb">
        {overAll && (
          <Header
            showProfile={showProfile}
            // profileIcon={KpIcon}
            // username={state.adminName ? state.adminName : state.user}
            falseShowProfile={falseShowProfile}
            notofShowProfile={notofShowProfile}
            institutionName={
              localStorage.length > 0 &&
              JSON.parse(localStorage.userCreds).institutionName.toUpperCase()
            }
            ShowNotifications={ShowNotifications}
            showNotification={showNotification}
            falseShowNotification={falseShowNotification}
            trueShowNotification={trueShowNotification}
            userType={userCredsvalues?.userType}
          />
        )}
      </div>

      <div
        style={{ backgroundColor: "#f4f4f4" }}
        className={overAll ? "Dash_home_QWI" : ""}
      >
        <div>
          {overAll && (
            <div className="mb-5">
              <div className="FlexMainDivAll PaddingLR">
                <div className="col-md-7 DTC-Card padding-left-0 padding-right-0">
                  <div className="d-flex gap-2 align-items-center">
                    <div className="title-txt-label">
                      <div className="Today-Font ColorFont">Today Classes</div>
                    </div>

                    <div className="pl-3 D-FlexDV pr-3">
                      <div className="Date-Font">
                        {moment().format("DD MMM YYYY")}
                      </div>

                      <div
                        className="View-More-Font"
                        onClick={() => {
                          setViewMore(true);
                          setExamSchedule(false);
                          setViewMoreAttendance(false);
                          setViewMoreDiary(false);
                          setViewMoreAssignment(false);
                          setViewMoreExamResult(false);
                          setViewMoreAchievements(false);
                          setBirthday(false);
                          setOverAll(false);
                        }}
                      >
                        View more &nbsp;
                        <AiOutlineRight size={15} className="DisplayNone" />
                      </div>
                    </div>
                  </div>
                  {isLoading ? (
                    <>
                      <div
                        className={"mt-4 mb-4"}
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <img src={loadingGif} width="40px" />
                      </div>
                    </>
                  ) : (
                    <>
                      {timeTableData.length > 0 && (
                        <div className="DisplayWeb">
                          <div className="padg1234">
                            <div className="Subject-Timing">
                              <div className="MainDiv">
                                <div
                                  style={{
                                    color:
                                      selectedIndexValue === 0 ? "#ccc" : "",
                                    cursor:
                                      selectedIndexValue === 0
                                        ? "inherit"
                                        : "pointer",
                                  }}
                                  onClick={() => {
                                    if (selectedIndexValue !== 0) {
                                      FetchNewTimeTableData(
                                        selectedIndexValue - 1
                                      );
                                    }
                                  }}
                                >
                                  <AiOutlineLeft className="DisplayNone" />
                                </div>

                                <div
                                  className="d-flex flex-column gap-3"
                                  style={{
                                    width: "100%",
                                    padding: "0px 15px",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "10px",
                                      }}
                                    >
                                      <span
                                        style={{
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          openBookview(
                                            timeTableData[selectedIndexValue]
                                              .katbookUnitID,
                                            timeTableData[selectedIndexValue]
                                              .katbookSessionID,
                                            timeTableData[selectedIndexValue]
                                              .refSubjectID,
                                            timeTableData[selectedIndexValue]
                                              .refSectionID
                                          );
                                        }}
                                        className="E-Font"
                                      >
                                        {timeTableData.length > 0 &&
                                          timeTableData[selectedIndexValue]
                                            .subjectName}
                                        {/* {console.log(timeTableData[selectedIndexValue],"TTTTTTTTTTTTTTTTT")}  */}
                                        &nbsp;{" "}
                                        {localStorage.length > 0 &&
                                        JSON.parse(localStorage.userCreds)
                                          .userType === "Teaching"
                                          ? `${
                                              timeTableData.length > 0 &&
                                              timeTableData[selectedIndexValue]
                                                .variableName
                                            } 
                                    ${
                                      timeTableData.length > 0 &&
                                      timeTableData[selectedIndexValue].section
                                    }`
                                          : ""}
                                      </span>
                                      {/* {localStorage.length > 0 && JSON.parse(localStorage.userCreds).userType === "Teaching" &&
                                    <span
                                    style={{
                                        cursor: "pointer",
                                      }}
                                      className="E-Font"
                                      >
                                    {timeTableData.length > 0 &&
                                        timeTableData[selectedIndexValue]
                                          .variableName}&nbsp;
                                    {timeTableData.length > 0 &&
                                  timeTableData[selectedIndexValue]
                                    .section}
                                    </span>
                                    } */}
                                      <IoVideocam
                                        onClick={() => {
                                          if (
                                            (selectedtimetableDate ===
                                              moment().format("DD/MMM/YYYY") &&
                                              CuttentDateTime <
                                                timeTablePeriod.filter(
                                                  (g) =>
                                                    String(g.PeriodNumber) ===
                                                    String(
                                                      timeTableData[
                                                        selectedIndexValue
                                                      ].period
                                                    )
                                                )[0]?.EndTime) ||
                                            selectedtimetableDate !==
                                              moment().format("DD/MMM/YYYY")
                                          ) {
                                            VideoConfFunc(
                                              selectedtimetableDate,
                                              timeTableData[selectedIndexValue]
                                                ?.subjectName,
                                              timeTableData.length > 0 &&
                                                timeTablePeriod.length > 0 &&
                                                timeTablePeriod.filter(
                                                  (g) =>
                                                    String(g.PeriodNumber) ===
                                                    String(
                                                      timeTableData[
                                                        selectedIndexValue
                                                      ].period
                                                    )
                                                )[0]?.StartTime,
                                              timeTableData.length > 0 &&
                                                timeTablePeriod.length > 0 &&
                                                timeTablePeriod.filter(
                                                  (g) =>
                                                    String(g.PeriodNumber) ===
                                                    String(
                                                      timeTableData[
                                                        selectedIndexValue
                                                      ].period
                                                    )
                                                )[0]?.EndTime,
                                              timeTableData[selectedIndexValue]
                                                ?.staffName,
                                              timeTableData[selectedIndexValue]
                                                ?.staffDesignation,
                                              timeTableData[selectedIndexValue]
                                                ?.katbookUnitID,
                                              timeTableData[selectedIndexValue]
                                                ?.katbookSessionID,
                                              timeTableData[selectedIndexValue]
                                                ?.refStaffID,
                                              timeTableData[selectedIndexValue]
                                                ?.id,
                                              timeTableData[selectedIndexValue]
                                                ?.refSubjectID,
                                              timeTableData[selectedIndexValue]
                                                ?.refSectionID
                                            );
                                          }
                                          // document.querySelector("body").style.overflow = "hidden";
                                        }}
                                        style={{
                                          marginLeft: "10px",
                                          color:
                                            selectedtimetableDate ===
                                            moment().format("DD/MMM/YYYY")
                                              ? String(CuttentDateTime) <
                                                String(
                                                  timeTablePeriod.filter(
                                                    (g) =>
                                                      String(g.PeriodNumber) ===
                                                      String(
                                                        timeTableData[
                                                          selectedIndexValue
                                                        ].period
                                                      )
                                                  )[0]?.EndTime
                                                )
                                                ? "#1653cc"
                                                : "#ccc"
                                              : "#1653cc",
                                          cursor:
                                            selectedtimetableDate ===
                                            moment().format("DD/MMM/YYYY")
                                              ? CuttentDateTime <
                                                timeTablePeriod.filter(
                                                  (g) =>
                                                    String(g.PeriodNumber) ===
                                                    String(
                                                      timeTableData[
                                                        selectedIndexValue
                                                      ].period
                                                    )
                                                )[0]?.EndTime
                                                ? "pointer"
                                                : "default"
                                              : "pointer",
                                        }}
                                        className="VideoPointer"
                                      />{" "}
                                      &nbsp;{" "}
                                    </div>
                                    {/* {CuttentDateTime} */}
                                    {/* {timeTablePeriod.filter(g => String(g.PeriodNumber) === String(timeTableData[selectedIndexValue].period))[0]?.EndTime} */}
                                    {/* { console.log(timeTablePeriod.length > 0 && timeTablePeriod.filter(g => String(g.PeriodNumber) === String(timeTableData[selectedIndexValue].period))[0]?.EndTime,"JJJJJJJJJJJJJJ")}
                            { console.log(CuttentDateTime,"JJJJJJJJJJJJJJ")} */}
                                    <div>
                                      <span className="T-Font">
                                        {/* {console.log(timeTableData.length > 0 && timeTablePeriod.length > 0 && timeTablePeriod.filter(g => String(g.PeriodNumber) === String(timeTableData[selectedIndexValue].period)),"TTTTTTTTTTTTTTTTT")} */}
                                        {tConvert(
                                          timeTableData.length > 0 &&
                                            timeTablePeriod.length > 0 &&
                                            timeTablePeriod.filter(
                                              (g) =>
                                                String(g.PeriodNumber) ===
                                                String(
                                                  timeTableData[
                                                    selectedIndexValue
                                                  ].period
                                                )
                                            )[0]?.StartTime
                                        )}
                                        &nbsp; - &nbsp;
                                        {tConvert(
                                          timeTableData.length > 0 &&
                                            timeTablePeriod.length > 0 &&
                                            timeTablePeriod.filter(
                                              (g) =>
                                                String(g.PeriodNumber) ===
                                                String(
                                                  timeTableData[
                                                    selectedIndexValue
                                                  ].period
                                                )
                                            )[0]?.EndTime
                                        )}
                                        {/* 08:00 am - 08:45 am{" "} */}
                                      </span>
                                    </div>
                                  </div>

                                  <div className="InnerDiv">
                                    <div
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        openBookview(
                                          timeTableData[selectedIndexValue]
                                            .katbookUnitID,
                                          timeTableData[selectedIndexValue]
                                            .katbookSessionID,
                                          timeTableData[selectedIndexValue]
                                            .refSubjectID,
                                          timeTableData[selectedIndexValue]
                                            .refSectionID
                                        );
                                      }}
                                      className="DdddFff"
                                    >
                                      <ResultData
                                        unitID={
                                          timeTableData.length > 0 &&
                                          timeTableData[selectedIndexValue]
                                            ?.katbookUnitID
                                        }
                                        sessionID={
                                          timeTableData.length > 0 &&
                                          timeTableData[selectedIndexValue]
                                            ?.katbookSessionID
                                        }
                                      />
                                      {/* <div className="Unit-Font">
                                Unit 2 - Supplementary
                              </div>
                              <div className="Unit-Font">
                                Chapter - The Midnight Visitor
                              </div> */}
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "10px",
                                      }}
                                    >
                                      <img src={Sample} />
                                      {localStorage.length > 0 &&
                                      JSON.parse(localStorage.userCreds)
                                        .userType === "Teaching" ? (
                                        <>
                                          <span className="Img_Name">
                                            {localStorage.length > 0 &&
                                              JSON.parse(localStorage.userCreds)
                                                .adminName}{" "}
                                          </span>
                                        </>
                                      ) : (
                                        <>
                                          <span className="Img_Name">
                                            {timeTableData.length > 0 &&
                                              timeTableData[selectedIndexValue]
                                                .staffName}{" "}
                                          </span>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    color:
                                      selectedIndexValue ===
                                      timeTableData.length - 1
                                        ? "#ccc"
                                        : "",
                                    cursor:
                                      selectedIndexValue ===
                                      timeTableData.length - 1
                                        ? "inherit"
                                        : "pointer",
                                  }}
                                  onClick={() => {
                                    if (
                                      selectedIndexValue <
                                      timeTableData.length - 1
                                    ) {
                                      FetchNewTimeTableData(
                                        selectedIndexValue + 1
                                      );
                                    }
                                  }}
                                >
                                  <AiOutlineRight />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {timeTableData.length === 0 && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100px",
                            fontSize: "25px",
                            width: "100%",
                          }}
                        >
                          Classes not available
                        </div>
                      )}
                    </>
                  )}

                  {/* For Mobile App  */}
                  <div className="DisplayMobile">
                    <div className="padg1234">
                      {timeTableData.length > 0 &&
                        timeTableData.map((x, index) => {
                          return (
                            <div>
                              <div className="Subject-Timing ">
                                <div className="MainDiv">
                                  <div>
                                    <AiOutlineLeft
                                      style={{ cursor: "pointer" }}
                                      className="DisplayNone"
                                    />
                                  </div>

                                  <div className="d-flex flex-column gap-3">
                                    <div
                                      style={{
                                        display: "flex",
                                        gap: "10px",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <span
                                          onClick={() => {
                                            openBookview(
                                              x.katbookUnitID,
                                              x.katbookSessionID,
                                              x.refSubjectID,
                                              x.refSectionID
                                            );
                                          }}
                                          className="E-Font"
                                        >
                                          {x.subjectName} &nbsp;{" "}
                                        </span>
                                        <IoVideocam
                                          style={{
                                            color:
                                              selectedtimetableDate ===
                                              moment().format("DD/MMM/YYYY")
                                                ? String(CuttentDateTime) <
                                                  String(
                                                    timeTablePeriod.filter(
                                                      (g) =>
                                                        String(
                                                          g.PeriodNumber
                                                        ) === String(x.period)
                                                    )[0]?.EndTime
                                                  )
                                                  ? "#1653cc"
                                                  : "#ccc"
                                                : "#1653cc",
                                            cursor:
                                              selectedtimetableDate ===
                                              moment().format("DD/MMM/YYYY")
                                                ? CuttentDateTime <
                                                  timeTablePeriod.filter(
                                                    (g) =>
                                                      String(g.PeriodNumber) ===
                                                      String(x.period)
                                                  )[0]?.EndTime
                                                  ? "pointer"
                                                  : "default"
                                                : "pointer",
                                          }}
                                          onClick={() => {
                                            // alert(selectedtimetableDate)
                                            if (
                                              (selectedtimetableDate ===
                                                moment().format(
                                                  "DD/MMM/YYYY"
                                                ) &&
                                                CuttentDateTime <
                                                  timeTablePeriod.filter(
                                                    (g) =>
                                                      String(g.PeriodNumber) ===
                                                      String(x.period)
                                                  )[0]?.EndTime) ||
                                              selectedtimetableDate !==
                                                moment().format("DD/MMM/YYYY")
                                            ) {
                                              VideoConfFunc(
                                                selectedtimetableDate,
                                                x?.subjectName,
                                                timeTablePeriod.filter(
                                                  (g) =>
                                                    String(g.PeriodNumber) ===
                                                    String(x.period)
                                                )[0]?.StartTime,
                                                timeTablePeriod.filter(
                                                  (g) =>
                                                    String(g.PeriodNumber) ===
                                                    String(x.period)
                                                )[0]?.EndTime,
                                                x?.staffName,
                                                x?.staffDesignation,
                                                x?.katbookUnitID,
                                                x?.katbookSessionID,
                                                x?.refStaffID,
                                                x?.id,
                                                x?.refSubjectID,
                                                x?.refSectionID
                                              );
                                            }
                                            // document.querySelector("body").style.overflow = "hidden";
                                          }}
                                          className="VideoPointer"
                                        />{" "}
                                        &nbsp;{" "}
                                      </div>
                                      <div>
                                        <span className="T-Font">
                                          {tConvert(
                                            timeTablePeriod.length > 0 &&
                                              timeTablePeriod.filter(
                                                (g) =>
                                                  String(g.PeriodNumber) ===
                                                  String(x.period)
                                              )[0]?.StartTime
                                          )}
                                          &nbsp; - &nbsp;
                                          {tConvert(
                                            timeTablePeriod.length > 0 &&
                                              timeTablePeriod.filter(
                                                (g) =>
                                                  String(g.PeriodNumber) ===
                                                  String(x.period)
                                              )[0]?.EndTime
                                          )}
                                        </span>
                                      </div>
                                    </div>

                                    <div className="InnerDiv">
                                      <div
                                        onClick={() => {
                                          openBookview(
                                            x.katbookUnitID,
                                            x.katbookSessionID,
                                            x.refSubjectID,
                                            x.refSectionID
                                          );
                                        }}
                                        className="DdddFff"
                                      >
                                        <ResultDataView
                                          unitID={x?.katbookUnitID}
                                          sessionID={x?.katbookSessionID}
                                        />
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          gap: "10px",
                                        }}
                                      >
                                        <img src={Sample} />
                                        <span className="Img_Name">
                                          {localStorage.length > 0 &&
                                          JSON.parse(localStorage.userCreds)
                                            .userType === "Teaching"
                                            ? JSON.parse(localStorage.userCreds)
                                                .adminName
                                            : x.staffName}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <AiOutlineRight
                                      style={{ cursor: "pointer" }}
                                      className="DisplayNone"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                  {/* For Mobile App  */}
                </div>

                <div className="col-md-5 D-ES-Card DisplayNone padding-left-0 padding-right-0">
                  <div
                    className="pr-3"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div className="Exam-Schedule">
                      <div className="Exam-Font">Exam Schedule</div>
                    </div>
                    <div
                      className="View-More-FontColor DisplayNone"
                      onClick={() => {
                        setExamSchedule(true);
                        setOverAll(false);
                      }}
                    >
                      View more &nbsp;
                      <AiOutlineRight size={15} />
                    </div>
                    <div className="NewDown_Icon">
                      <BsChevronDown size={15} />
                    </div>
                  </div>

                  <div className="DisplayWeb">
                    <div className="Padding_25_15">
                      <div className="Subject-Unit ">
                        <div
                          style={{
                            padding: "10px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "20px",
                            }}
                          >
                            <div>
                              <AiOutlineLeft
                                style={{ cursor: "pointer", color: "white" }}
                              />
                            </div>
                            <div className="D_A_M-Css">
                              <div className="NT-Font">19</div>
                              <div className="Month-font">Sep</div>
                            </div>

                            <div>
                              <div>
                                <span className="E-Font1">English </span> &nbsp;{" "}
                                <span className="Time-font">
                                  (10:00 am - 01:00 pm)
                                </span>
                              </div>
                              <div>
                                <span className="Unit-Name">
                                  Unit 1 - Unit Name
                                </span>
                              </div>
                            </div>
                          </div>

                          <div>
                            <AiOutlineRight
                              style={{ cursor: "pointer", color: "white" }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* For Mobile App  */}
                  <div className="DisplayMobile">
                    <div className="Padding_25_15">
                      {DayDate.map((x) => {
                        return (
                          <div className="mb-2">
                            <div className="Subject-Unit">
                              <div
                                style={{
                                  padding: "10px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "20px",
                                  }}
                                >
                                  <div className="DisplayNone">
                                    <AiOutlineLeft
                                      style={{
                                        cursor: "pointer",
                                        color: "white",
                                      }}
                                    />
                                  </div>
                                  <div className="D_A_M-Css">
                                    <div className="NT-Font">{x.date}</div>
                                    <div className="Month-font">{x.mon}</div>
                                  </div>

                                  <div>
                                    <div>
                                      <span className="E-Font1">{x.Sub} </span>{" "}
                                      &nbsp;{" "}
                                      <span className="Time-font">
                                        {/* (10:00 am - 01:00 pm) */}
                                        {x.time}
                                      </span>
                                    </div>
                                    <div>
                                      <span className="Unit-Name">
                                        Unit 1 - {x.unit}
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                <div className="DisplayNone">
                                  <AiOutlineRight
                                    style={{
                                      cursor: "pointer",
                                      color: "white",
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  {/* For Mobile App  */}
                </div>

                {/* For Mobile */}
                <div className="DisplayNoneWeb">
                  {/* {assignAccordian ? ( */}
                  <div className="col-md-5 D-ES-Card padding-left-0 padding-right-0">
                    <div
                      className="pr-3"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div className="Exam-Schedule">
                        <div className="Exam-Font">Exam Schedule</div>
                      </div>
                      <div className="View-More-FontColor DisplayNone">
                        View more &nbsp;
                        <AiOutlineRight size={15} />
                      </div>
                      <div
                        className="NewDown_Icon"
                        onClick={() => {
                          setExamSchedule(true);
                          setOverAll(false);
                        }}
                      >
                        <BsChevronRight size={15} />
                      </div>
                    </div>

                    <div className="DisplayWeb">
                      <div className="Padding_25_15">
                        <div className="Subject-Unit ">
                          <div
                            style={{
                              padding: "10px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "20px",
                              }}
                            >
                              <div>
                                <AiOutlineLeft
                                  style={{ cursor: "pointer", color: "white" }}
                                />
                              </div>
                              <div className="D_A_M-Css">
                                <div className="NT-Font">19</div>
                                <div className="Month-font">Sep</div>
                              </div>

                              <div>
                                <div>
                                  <span className="E-Font1">English </span>{" "}
                                  &nbsp;{" "}
                                  <span className="Time-font">
                                    (10:00 am - 01:00 pm)
                                  </span>
                                </div>
                                <div>
                                  <span className="Unit-Name">
                                    Unit 1 - Unit Name
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div>
                              <AiOutlineRight
                                style={{ cursor: "pointer", color: "white" }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* For Mobile App  */}
                    <div className="DisplayMobile">
                      <div className="Padding_25_15">
                        {DayDate.map((x) => {
                          return (
                            <div className="mb-2">
                              <div className="Subject-Unit">
                                <div
                                  style={{
                                    padding: "10px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "20px",
                                    }}
                                  >
                                    <div className="DisplayNone">
                                      <AiOutlineLeft
                                        style={{
                                          cursor: "pointer",
                                          color: "white",
                                        }}
                                      />
                                    </div>
                                    <div className="D_A_M-Css">
                                      <div className="NT-Font">{x.date}</div>
                                      <div className="Month-font">{x.mon}</div>
                                    </div>

                                    <div>
                                      <div>
                                        <span className="E-Font1">
                                          {x.Sub}{" "}
                                        </span>{" "}
                                        &nbsp;{" "}
                                        <span className="Time-font">
                                          {/* (10:00 am - 01:00 pm) */}
                                          {x.time}
                                        </span>
                                      </div>
                                      <div>
                                        <span className="Unit-Name">
                                          Unit 1 - {x.unit}
                                        </span>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="DisplayNone">
                                    <AiOutlineRight
                                      style={{
                                        cursor: "pointer",
                                        color: "white",
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    {/* For Mobile App  */}
                  </div>
                </div>
                {/* For Mobile */}
              </div>

              <div className="FlexMainDivAll1 PaddingLR">
                <div className="col-md-4 OuterDiv1 DisplayNone padding-left-0 padding-right-0">
                  <div className="d-flex align-items-center justify-content-between pr-3">
                    <div className="title-txt-label">
                      <div className="Today-Font">Attendance</div>
                    </div>
                    <div
                      className="View-More-FontColor1 DisplayNone"
                      onClick={() => {
                        setViewMoreAttendance(true);
                        setOverAll(false);
                        window.scrollTo(0, 0);
                      }}
                    >
                      View more &nbsp;
                      <AiOutlineRight size={15} />
                    </div>
                    <div className="NewDown_Icon">
                      <BsChevronDown size={15} />
                    </div>
                  </div>
                  <div className="P_15_20_10_20">
                    <div
                      style={{ padding: "0px 10px" }}
                      className="DisplayNone"
                    >
                      <h6>{moment().format("DD MMM YYYY")}</h6>
                    </div>

                    <div style={{ padding: "10px 10px" }}>
                      <div className="WorkingDiv">
                        <div className="DisplayNone">
                          <h6>{moment().format("MMMM")}</h6>
                        </div>
                        <div className="DisplayNoneWeb">
                          <h6>{moment().format("DD MMM YYYY")}</h6>
                        </div>
                        <div className="Attend_Gap DisplayNone">
                          <div>
                            <span className="PAT_Font">Present</span>{" "}
                            <span className="h6">4</span>
                          </div>
                          <div>
                            <span className="PAT_Font">Absent</span>{" "}
                            <span className="h6">1</span>
                          </div>
                          <div>
                            <span className="PAT_Font">
                              Total Working Days{" "}
                            </span>
                            <span className="h6">20</span>
                          </div>
                        </div>

                        <div className="Padd2101 DisplayNoneWeb">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              // padding: "10px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "5px",
                              }}
                            >
                              <div className="PreFontColor">Present</div>
                              <div className="PreFontSize">120</div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "5px",
                              }}
                            >
                              <div className="PreFontColor1">Absent</div>
                              <div className="PreFontSize1">24</div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "5px",
                              }}
                            >
                              <div className="PreFontColor2">
                                Total Working Days
                              </div>
                              <div className="PreFontSize2">170</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="Padd2101 DisplayNone">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          // padding: "10px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "5px",
                          }}
                        >
                          <div className="PreFontColor">Present</div>
                          <div className="PreFontSize">153</div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "5px",
                          }}
                        >
                          <div className="PreFontColor1">Absent</div>
                          <div className="PreFontSize1">17</div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "5px",
                          }}
                        >
                          <div className="PreFontColor2">
                            Total Working Days
                          </div>
                          <div className="PreFontSize2">170</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* For Mobile */}
                <div className="DisplayNoneWeb">
                  {/* {assignAccordianAttend ? ( */}
                  <div className="col-md-4 OuterDiv1 padding-left-0 padding-right-0">
                    <div className="d-flex align-items-center justify-content-between pr-3">
                      <div className="title-txt-label">
                        <div className="Today-Font ColorFont">Attendance</div>
                      </div>
                      <div className="View-More-FontColor1 DisplayNone">
                        View more &nbsp;
                        <AiOutlineRight size={15} />
                      </div>
                      <div
                        className="NewDown_Icon"
                        onClick={() => {
                          setViewMoreAttendance(true);
                          setOverAll(false);
                        }}
                      >
                        <BsChevronRight size={15} />
                      </div>
                    </div>
                    <div className="P_15_20_10_20">
                      <div
                        style={{ padding: "0px 10px" }}
                        className="DisplayNone"
                      >
                        <h6>05 Sep 2022</h6>
                      </div>

                      <div style={{ padding: "10px 10px" }}>
                        <div className="WorkingDiv">
                          <div className="DisplayNone">
                            <h6>September</h6>
                          </div>
                          <div className="DisplayNoneWeb">
                            <h6>05 Sep 2022</h6>
                          </div>
                          <div className="Attend_Gap DisplayNone">
                            <div>
                              <span className="PAT_Font">Present</span>{" "}
                              <span className="h6">4</span>
                            </div>
                            <div>
                              <span className="PAT_Font">Absent</span>{" "}
                              <span className="h6">1</span>
                            </div>
                            <div>
                              <span className="PAT_Font">
                                Total Working Days{" "}
                              </span>
                              <span className="h6">26</span>
                            </div>
                          </div>

                          <div className="Padd2101 DisplayNoneWeb">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                // padding: "10px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "5px",
                                }}
                              >
                                <div className="PreFontColor">Present</div>
                                <div className="PreFontSize">153</div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "5px",
                                }}
                              >
                                <div className="PreFontColor1">Absent</div>
                                <div className="PreFontSize1">17</div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "5px",
                                }}
                              >
                                <div className="PreFontColor2">
                                  Total Working Days
                                </div>
                                <div className="PreFontSize2">170</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="Padd2101 DisplayNone">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            // padding: "10px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "5px",
                            }}
                          >
                            <div className="PreFontColor">Present</div>
                            <div className="PreFontSize">153</div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "5px",
                            }}
                          >
                            <div className="PreFontColor1">Absent</div>
                            <div className="PreFontSize1">17</div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "5px",
                            }}
                          >
                            <div className="PreFontColor2">
                              Total Working Days
                            </div>
                            <div className="PreFontSize2">170</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ) : ( */}
                  {/* <div className="col-md-4 OuterDiv1 padding-left-0 padding-right-0">
                    <div className="d-flex align-items-center justify-content-between pr-3">
                      <div className="title-txt-label">
                        <div className="Today-Font">Attendance</div>
                      </div>
                      <div className="View-More-FontColor1 DisplayNone">
                        View more &nbsp;
                        <AiOutlineRight size={15} />
                      </div>
                      <div
                        className="NewDown_Icon"
                        onClick={() => {
                          setAssignAccordianAttend(true);
                        }}
                      >
                        <BsChevronUp size={15} />
                      </div>
                    </div>
                  </div> */}
                  {/* )} */}
                </div>
                {/* For Mobile */}

                <div className="col-md-3 OuterDiv2 padding-left-0 padding-right-0">
                  <div
                    className="pr-3"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div className="Exam-Schedule">
                      <div className="d-flex flex-row align-items-center gap-4">
                        <div className="Exam-Font">Diary</div>
                        <div className="DisplayNoneWeb">
                          <BsFillPlusCircleFill
                            size={30}
                            style={{ color: "#2F71F5" }}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="View-More-FontColor"
                      onClick={() => {
                        setViewMoreDiary(true);
                        setOverAll(false);
                      }}
                    >
                      View more &nbsp;
                      <AiOutlineRight size={15} className="DisplayNone" />
                    </div>
                  </div>

                  <div className="DiaryScroll">
                    <div style={{ padding: "0px 10px" }}>
                      <h6 className="ColorStyle">05 Sep 2022</h6>
                    </div>

                    {DiarySchool.map((x) => {
                      return (
                        <Fade bottom>
                          <div className="DiaryScroll1 ">
                            <div className="WorkingDiv1">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "5px",
                                }}
                              >
                                <span className="TimeFontCol">
                                  {/* 10:30 am - 11:00 am */}
                                  {x.Time}
                                </span>
                                <span className="SclComp DisplayNoneWeb">
                                  {/* School Chess Competition */}
                                  {x.Compet}
                                </span>
                                <span className="TimeFontCol">
                                  {/* UI / UX Design for Diary Concept */}
                                  {x.Concept}
                                </span>
                              </div>
                            </div>
                          </div>
                        </Fade>
                      );
                    })}
                  </div>
                </div>

                <div className="col-md-5 OuterDiv3 DisplayNone padding-left-0 padding-right-0">
                  <div
                    className="pr-3"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div className="Exam-Schedule1">
                      <div className="Exam-Font">Assignment</div>
                    </div>
                    <div
                      className="View-More-FontColor1 DisplayNone"
                      onClick={() => {
                        setViewMoreAssignment(true);
                        setOverAll(false);
                      }}
                    >
                      View more &nbsp;
                      <AiOutlineRight size={15} style={{ cursor: "pointer" }} />
                    </div>
                    <div className="NewDown_Icon">
                      <BsChevronDown size={15} />
                    </div>
                  </div>

                  <div className="Ex-Schedule-Height">
                    {DashboardSubject.map((item) => {
                      return (
                        <Fade bottom>
                          <div className="WorkingDiv2 mb-2">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "5px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  flexDirection: "row",
                                  gap: "25px",
                                }}
                              >
                                <div>
                                  <span className="Date-Font-Color">
                                    {/* 06 Sep 2022 */}
                                    {item.date}
                                  </span>
                                </div>
                                <div className="Subject-Border">
                                  <span className="Subject-Border-Font">
                                    {/* Maths */}
                                    {item.Subject}
                                  </span>
                                </div>
                              </div>
                              <div>
                                <span className="Subject-Topic">
                                  {/* Fourier Series half range */}
                                  {item.banner}
                                </span>
                              </div>
                            </div>
                          </div>
                        </Fade>
                      );
                    })}
                  </div>
                </div>

                {/* For Mobile */}
                <div className="DisplayNoneWeb">
                  {/* {assignAccordianAssign ? ( */}
                  <div className="col-md-5 OuterDiv3 padding-left-0 padding-right-0">
                    <div
                      className="pr-3"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div className="Exam-Schedule1">
                        <div className="Exam-Font">Assignment</div>
                      </div>
                      <div className="View-More-FontColor DisplayNone">
                        View more &nbsp;
                        <AiOutlineRight
                          size={15}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                      <div
                        className="NewDown_Icon"
                        onClick={() => {
                          setViewMoreAssignment(true);
                          setOverAll(false);
                        }}
                      >
                        <BsChevronRight size={15} />
                      </div>
                    </div>

                    <div className="Ex-Schedule-Height">
                      {DashboardSubject.map((item) => {
                        return (
                          <div className="WorkingDiv2 mb-2">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "5px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  flexDirection: "row",
                                  gap: "25px",
                                }}
                              >
                                <div>
                                  <span className="Date-Font-Color">
                                    {/* 06 Sep 2022 */}
                                    {item.date}
                                  </span>
                                </div>
                                <div className="Subject-Border">
                                  <span className="Subject-Border-Font">
                                    {/* Maths */}
                                    {item.Subject}
                                  </span>
                                </div>
                              </div>
                              <div>
                                <span className="Subject-Topic">
                                  {/* Fourier Series half range */}
                                  {item.banner}
                                </span>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  {/* ) : ( */}
                  {/* <div>
                    <div className="col-md-5 OuterDiv3_Height padding-left-0 padding-right-0">
                      <div
                        className="pr-3"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div className="Exam-Schedule1">
                          <div className="Exam-Font">Assignment</div>
                        </div>
                        <div className="View-More-FontColor DisplayNone">
                          View more &nbsp;
                          <AiOutlineRight
                            size={15}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                        <div
                          className="NewDown_Icon"
                          onClick={() => {
                            setAssignAccordianAssign(true);
                          }}
                        >
                          <BsChevronUp size={15} />
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {/* )} */}
                </div>
                {/* For Mobile  */}
              </div>

              <div className="FlexMainDivAll1 PaddingLR">
                <div className="col-md-4 OuterDiv4 padding-left-0 padding-right-0">
                  <div className="PaddClassPoster">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="d-flex gap-3 align-items-center">
                        <img src={PosterImg} alt="" />
                        <span className="ClassPosterFont">Class Poster</span>
                      </div>
                      <div>
                        <BsFillPlusCircleFill
                          size={25}
                          style={{ color: "white" }}
                        />
                      </div>
                    </div>
                    <div className="OuterCard-Info mt-4">
                      <div style={{ padding: "25px" }}>
                        <div className="d-flex align-items-center gap-3">
                          <div>
                            <img
                              src={FramePoster}
                              alt=""
                              style={{
                                border: "1px solid green",
                                borderRadius: "15px",
                              }}
                            />
                          </div>
                          <div className="d-flex flex-column">
                            <div>
                              <span className="Off-Font">Akash Official</span>
                            </div>
                            <div>
                              <span className="Mail-Font">
                                @akash54 &nbsp; 2hr{" "}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="mt-3">
                          <h6 className="Content-Font">
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum is simply dummy
                            text.
                          </h6>
                        </div>
                        <div className="d-flex gap-3">
                          <div>
                            <span className="SportsFont">#sportsday</span>
                          </div>
                          <div>
                            <span className="SportsFont">#football</span>
                          </div>
                        </div>
                        <div className="mt-3">
                          <img
                            src={OffImg}
                            alt=""
                            style={{ width: "100%", height: "100%" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-5 OuterDiv6 DisplayNone padding-left-0 padding-right-0">
                  <div className="d-flex align-items-center justify-content-between gap-4">
                    <div className="title-txt-label">
                      <div className="Today-Font">Exam Results</div>
                    </div>

                    <div
                      className="d-flex align-items-center View-More-Font mr-3"
                      onClick={() => {
                        setViewMoreExamResult(true);
                        setOverAll(false);
                        window.scrollTo(0, 0);
                      }}
                    >
                      View more &nbsp;
                      <AiOutlineRight
                        size={15}
                        className="DisplayNone"
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                    {/* <div>
                    <select name="cars" id="cars" className="DropAlignDesign">
                      {DropDownValues.map((x) => {
                        return (
                          <option className="DropAlignDesignFont">
                            {x.value}
                          </option>
                        );
                      })}
                    </select>
                  </div> */}

                    {/* <div className="View-More-FontColor1 DisplayNone">
                    Unit Test 1 &nbsp;
                    <BsChevronDown size={15} style={{ cursor: "pointer" }} />
                  </div> */}
                    <div className="NewDown_Icon">
                      <BsChevronDown size={15} />
                    </div>
                  </div>
                  <div style={{ padding: "20px" }} className="DisplayNone">
                    <div className="d-flex flex-row align-items-center justify-content-between px-4">
                      <div>
                        <span className="Month-Exam-Font">
                          {" "}
                          Monthly Exam (March - 2023){" "}
                        </span>
                      </div>
                      <div
                        className="d-flex align-items-center gap-3"
                        // style={{ padding: "0px 30px" }}
                      >
                        <span className="TotalCount-Font">Total </span>

                        <span className="CountNumber-Font">
                          426<span className="CountNumber-Font1">/700</span>
                        </span>
                      </div>
                    </div>

                    <div>
                      {ExamSubject.map((x) => {
                        return (
                          <div className="d-flex flex-row justify-content-evenly align-items-center pt-4">
                            <div className="lanex_re">{x.subject}</div>

                            <div className="ProgressBarColor">
                              <div
                                style={{
                                  width: `${x.width}`,
                                  backgroundImage: `${x.color}`,
                                }}
                                className="ProgressBarColorIn"
                              ></div>
                            </div>
                            <div>
                              <span className="ProNumber">
                                {x.count}
                                <span className="PercentNumber">/100</span>
                              </span>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  <div className="DisplayNoneWeb">
                    <div className="Ex-Schedule-Height">
                      <div className="WorkingMobileDiv">
                        <span>
                          Unit Test 1 &nbsp; <BsChevronDown />
                        </span>
                        <div
                          className="d-flex align-items-center gap-3 mt-3"
                          style={{ padding: "0px 5px" }}
                        >
                          <div className="TotalCount-Font">Total </div>

                          <div className="CountNumber-Font">
                            426<span className="CountNumber-Font1">/700</span>
                          </div>
                          <div className="PerCountExRs">
                            <span className="PerCountExRsFont">76%</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* For Mobile */}
                <div className="DisplayNoneWeb">
                  {/* {assignAccordianER ? ( */}
                  <div className="col-md-5 OuterDiv6 padding-left-0 padding-right-0">
                    <div className="d-flex align-items-center justify-content-between pr-3">
                      <div className="title-txt-label">
                        <div className="Today-Font ColorFont">Exam Results</div>
                      </div>
                      <div className="View-More-FontColor1 DisplayNone">
                        Unit Test 1 &nbsp;
                        <BsChevronDown
                          size={15}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                      <div
                        className="NewDown_Icon"
                        onClick={() => {
                          setViewMoreExamResult(true);
                          setOverAll(false);
                          window.scrollTo(0, 0);
                        }}
                      >
                        <BsChevronRight size={15} />
                      </div>
                    </div>
                    <div style={{ padding: "20px" }} className="DisplayNone">
                      <div
                        className="d-flex align-items-center gap-3 mt-3"
                        style={{ padding: "0px 30px" }}
                      >
                        <span className="TotalCount-Font">Total </span>

                        <span className="CountNumber-Font">
                          426<span className="CountNumber-Font1">/700</span>
                        </span>
                      </div>

                      <div>
                        {ExamSubject.map((x) => {
                          return (
                            <div className="d-flex flex-row justify-content-evenly align-items-center pt-4">
                              <div className="lanex_re">{x.subject}</div>

                              <div className="ProgressBarColor">
                                <div
                                  style={{
                                    width: `${x.width}`,
                                    backgroundImage: `${x.color}`,
                                  }}
                                  className="ProgressBarColorIn"
                                ></div>
                              </div>
                              <div>
                                <span className="ProNumber">
                                  {x.count}
                                  <span className="PercentNumber">/100</span>
                                </span>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div className="DisplayNoneWeb">
                      <div className="Ex-Schedule-Height">
                        <div className="WorkingMobileDiv">
                          <span>
                            Unit Test 1 &nbsp; <BsChevronDown />
                          </span>
                          <div
                            className="d-flex align-items-center gap-3 mt-3"
                            style={{ padding: "0px 5px" }}
                          >
                            <div className="TotalCount-Font">Total </div>

                            <div className="CountNumber-Font">
                              426<span className="CountNumber-Font1">/700</span>
                            </div>
                            <div className="PerCountExRs">
                              <span className="PerCountExRsFont">76%</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ) : ( */}

                  {/* )} */}
                </div>
                {/* For Mobile */}

                <div className="col-md-3 OuterDiv5 padding-left-0 padding-right-0">
                  <div className="PaddClassPoster">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="d-flex gap-3 align-items-center">
                        <img src={PosterImg} alt="" />
                        <span className="ClassPosterFont">School Poster</span>
                      </div>
                    </div>
                    <div className="HeightCScroll">
                      {SchoolPoster.map((item) => {
                        return (
                          <Fade bottom>
                            <div className="OuterCard-Info2 mt-4">
                              <div className="pd25">
                                <div className="d-flex align-items-center gap-3">
                                  <div>
                                    <img
                                      src={SclLogo}
                                      alt=""
                                      style={{
                                        border: "1px solid green",
                                        borderRadius: "15px",
                                      }}
                                    />
                                  </div>

                                  <div className="d-flex flex-column">
                                    <div>
                                      <span className="Off-Font">
                                        {/* Wesely School */}
                                        {item.name}
                                      </span>
                                    </div>
                                    <div>
                                      <span className="Mail-Font">
                                        {item.mail} &nbsp; {item.time}{" "}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="mt-3">
                                  <h6 className="Content-Font">
                                    {/* Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum is simply dummy text. */}
                                    {item.content}
                                  </h6>
                                </div>
                                <div className="d-flex gap-3">
                                  <div>
                                    <span className="SportsFont">
                                      {/* #sportsday */}
                                      {item.day}
                                    </span>
                                  </div>
                                  <div>
                                    <span className="SportsFont">
                                      {/* #football */}
                                      {item.sport}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Fade>
                        );
                      })}
                    </div>
                  </div>
                </div>

                {/* For Mobile Screen */}
                {/* <div className="col-md-12 padding-left-0 padding-right-0 DisplayNoneWeb">
                <div className="SclPosterDiv">
                  <div className="d-flex align-items-center">
                    <div className="title-txt-label">
                      <div className="Today-Font">School Poster</div>
                    </div>

                    <div className="pl-3 D-FlexDV pr-3">
                      <div className="Date-Font"> </div>

                      <div
                        className="View-More-Font"
                        onClick={() => {
                          setViewMore(true);
                          setOverAll(false);
                        }}
                      >
                        View more &nbsp;
                        <AiOutlineRight size={15} className="DisplayNone" />
                      </div>
                    </div>
                  </div>

                  <div className="DisplayMobile">
                    <div className="padg_Scl_1234">
                      {NewSchoolPoster.map((x) => {
                        return (
                          <div>
                            <div className="Subject-Timing ">
                              <div className="MainDiv1">
                                <div className="d-flex flex-column gap-3">
                                  <img
                                    src={OffImgq}
                                    alt=""
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      marginLeft: "3%",
                                      borderRadius: "10px 10px 0px 0px",
                                    }}
                                  />

                                  <div className="InnerDiv">
                                    <div className="pd_New_25">
                                      <div className="Schoolmoile_date">
                                        {x.day}
                                      </div>
                                      <div className="Schoolmobile_name">
                                        {x.name}
                                      </div>
                                      <div className="Schoolmobile_content">
                                        {x.content}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <AiOutlineRight
                                    style={{ cursor: "pointer" }}
                                    className="DisplayNone"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div> */}
                {/* For Mobile Screen */}
              </div>

              <div className="FlexMainDivAll PaddingLR">
                <div className="col-md-7 OuterDiv7 padding-left-0 padding-right-0">
                  <div className="d-flex align-items-center justify-content-between pr-3">
                    <div className="title-txt-label">
                      <div className="Today-Font ColorFont">Achievements</div>
                    </div>
                    <div
                      className="d-flex align-items-center View-More-Font"
                      onClick={() => {
                        setViewMoreAchievements(!viewMoreAchievements);
                        setOverAll(false);
                        window.scrollTo(0, 0);
                      }}
                    >
                      View more &nbsp;
                      <AiOutlineRight
                        size={15}
                        className="DisplayNone"
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>
                  <div className="PaddTBLR">
                    <div className="A_Flex_Card">
                      <div>
                        <AiOutlineLeft
                          size={20}
                          style={{ cursor: "pointer" }}
                          className="DisplayNone"
                        />
                      </div>
                      <div className="GradeCard1">
                        <div>
                          <img src={GMedal} alt="" className="ImgMedalWidth" />
                        </div>
                        <div className="d-flex justify-content-center flex-column align-items-center marginMinusTop">
                          <div>
                            <img src={SImg1} alt="" className="ImgWidth" />
                          </div>
                          <div className="d-flex flex-column align-items-center mt-3">
                            <div className="Name_Font_St">Kalyani Kumari</div>
                            <div className="Class_Font_Sec">XII B</div>
                          </div>
                        </div>
                        <div className="d-flex flex-column align-items-center mt-3">
                          <div className="SportsCompFont">
                            InterSchool Chess
                          </div>
                          <div className="SportsCompFont">Tournament</div>
                        </div>
                      </div>

                      <div className="GradeCard2">
                        <div>
                          <img src={SMedal} alt="" className="ImgMedalWidth" />
                        </div>
                        <div className="d-flex justify-content-center flex-column align-items-center marginMinusTop">
                          <div>
                            <img src={SImg2} alt="" className="ImgWidth" />
                          </div>
                          <div className="d-flex flex-column align-items-center mt-3">
                            <div className="Name_Font_St">Karthicka</div>
                            <div className="Class_Font_Sec">XII B</div>
                          </div>
                        </div>
                        <div className="d-flex flex-column align-items-center mt-3">
                          <div className="SportsCompFont">
                            State-Level Football
                          </div>
                          <div className="SportsCompFont">Tournament</div>
                        </div>
                      </div>

                      <div className="GradeCard3">
                        <div>
                          <img src={BMedal} alt="" className="ImgMedalWidth" />
                        </div>
                        <div className="d-flex justify-content-center flex-column align-items-center marginMinusTop">
                          <div>
                            <img src={SImg3} alt="" className="ImgWidth" />
                          </div>
                          <div className="d-flex flex-column align-items-center mt-3">
                            <div className="Name_Font_St">Lilly</div>
                            <div className="Class_Font_Sec">XII B</div>
                          </div>
                        </div>
                        <div className="d-flex flex-column align-items-center mt-3">
                          <div className="SportsCompFont">
                            InterSchool Tennis
                          </div>
                          <div className="SportsCompFont">Tournament</div>
                        </div>
                      </div>

                      <div>
                        <AiOutlineRight
                          size={20}
                          style={{ cursor: "pointer" }}
                          className="DisplayNone"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-5 OuterDiv8 padding-left-0 padding-right-0">
                  <div
                    className="pr-3"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div className="title-txt-label">
                      <div className="Exam-Font ColorFont">Birthday's</div>
                    </div>
                    <div
                      className="View-More-FontColor"
                      onClick={() => {
                        setBirthday(true);
                        setOverAll(false);
                        window.scrollTo(0, 0);
                      }}
                    >
                      View more &nbsp;
                      <AiOutlineRight
                        size={15}
                        style={{ cursor: "pointer" }}
                        className="DisplayNone"
                      />
                    </div>
                  </div>

                  {/* <div>
                    <select className="DropDown_Css" >
                      <option>Kalai</option>
                      <option>Chelvan</option>
                      <option>Srithar</option>
                    </select>
                </div> */}

                  <Fade bottom>
                    <div className="BirthdayScrollVisible">
                      <div className="OuterDiv9">
                        <div className="PaddingDiv9">
                          <div>
                            <p className="Today-Font-White">Today</p>
                          </div>

                          <div className="d-flex align-items-center justify-content-between BorderLineImg">
                            <div className="d-flex align-items-center gap-4">
                              <div>
                                <img src={SImg3} alt="" width={50} />
                              </div>
                              <div className="d-flex flex-column align-items-left ">
                                <div className="LilFont">Lilly</div>
                                <div className="LilClassFont">XII-B</div>
                              </div>
                            </div>
                            <div>
                              <div className="WishesOuter">
                                <span className="WishesFont">Send Wishes</span>
                              </div>
                            </div>
                          </div>

                          <div
                            className="d-flex align-items-center justify-content-between"
                            style={{
                              padding: "20px 0px",
                            }}
                          >
                            <div className="d-flex align-items-center gap-4">
                              <div>
                                <img src={SImg1} alt="" width={50} />
                              </div>
                              <div className="d-flex flex-column align-items-left ">
                                <div className="LilFont">Kalyani Kumari</div>
                                <div className="LilClassFont">XII-B</div>
                              </div>
                            </div>
                            <div>
                              <div className="WishesOuter">
                                <span className="WishesFont">Send Wishes</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div style={{ padding: "15px 20px 0px 20px" }}>
                        <p className="Today-Font-White">Upcoming</p>
                      </div>

                      <div className="d-flex align-items-center justify-content-between pad_20_20_0_20">
                        <div className="d-flex align-items-center gap-4">
                          <div>
                            <img src={SImg2} alt="" width={50} />
                          </div>
                          <div className="d-flex flex-column align-items-left ">
                            <div className="LilFont">Karthicka</div>
                            <div className="LilClassFont">XII-B</div>
                          </div>
                        </div>
                        <div>
                          <div className="UpcomBirth">
                            <span className="UpComBirthDate">15 Sep</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Fade>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Today Classes for view more */}
        {viewMore && (
          <>
            <div className="DisplayNoneWeb">
              <div className="d-flex flex-row align-items-center justify-content-between Header-Style">
                <div className="d-flex flex-row align-items-center gap-3">
                  <div className="Today-Font">
                    <AiOutlineArrowLeft
                      size={20}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setViewMore(!viewMore);
                        setOverAll(true);
                      }}
                    />
                  </div>
                  <div className="Today-Font">Today Classes</div>
                </div>
                <div
                  onClick={() => {
                    setWeeklyTable(!weeklyTable);
                  }}
                  style={{
                    background:
                      "linear-gradient(316.94deg, #3072F6 2.33%, #1652C8 97.6%)",
                    padding: "7px",
                    color: "white",
                    borderRadius: "9px",
                    paddingTop: "4px",
                  }}
                >
                  <BsCalendar2Date size={20} />
                </div>
              </div>
            </div>
                { console.log(weeklyTable,"Pending session details")}
            {weeklyTable ? (
              <>
                <div className="d-flex flex-row px-2 py-3 w-100">
                  <div
                    className="col-md-12 OuterDiv10 padding-left-0 padding-right-0"
                    style={{ marginBottom: "50px" }}
                  >
                    <div className="Display_Center">
                      <div className="d-flex align-items-center justify-content-start">
                        <div className="title-txt-label mob-display-none">
                          <div className="Today-Font DisplayNone">
                            <AiOutlineArrowLeft
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setViewMore(!viewMore);
                                setOverAll(true);
                                updateDates(moment().format("DD/MMM/YYYY"));
                              }}
                            />{" "}
                            &nbsp; Today Classes
                          </div>
                        </div>
                      </div>

                      <div className="mob-display-none">
                        <div className="d-flex align-items-center gap-3 pr-4 mt-2 Weekly_Display_None">
                          <div className="d-flex align-items-center gap-1">
                            <div className="mob-display-none">
                              <div className="d-flex align-items-center gap-1 border_time">
                                <div
                                  onClick={() => {
                                    // alert(moment(selectedtimetableDate).format('dddd'))
                                    if (
                                      moment(selectedtimetableDate).format(
                                        "dddd"
                                      ) === "Monday"
                                    ) {
                                      updateDates(
                                        moment(
                                          selectedtimetableDate,
                                          "DD/MMM/YYYY"
                                        ).add(-2, "days")
                                      );
                                    } else {
                                      updateDates(
                                        moment(
                                          selectedtimetableDate,
                                          "DD/MMM/YYYY"
                                        ).add(-1, "days")
                                      );
                                    }
                                  }}
                                >
                                  <FaCaretLeft
                                    size={25}
                                    className="Icon_color_TT cursor-pointer"
                                  />
                                </div>
                                <div className="DateDesignFont">
                                  {selectedtimetableDate}
                                </div>
                                <div
                                  onClick={() => {
                                    // alert(moment(selectedtimetableDate).format('dddd'))
                                    if (
                                      moment(selectedtimetableDate).format(
                                        "dddd"
                                      ) === "Saturday"
                                    ) {
                                      updateDates(
                                        moment(
                                          selectedtimetableDate,
                                          "DD/MMM/YYYY"
                                        ).add(2, "days")
                                      );
                                    } else {
                                      updateDates(
                                        moment(
                                          selectedtimetableDate,
                                          "DD/MMM/YYYY"
                                        ).add(1, "days")
                                      );
                                    }
                                  }}
                                >
                                  <FaCaretRight
                                    size={25}
                                    className="Icon_color_TT cursor-pointer"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            onClick={() => {
                              setWeeklyTable(!weeklyTable);
                            }}
                            className="Weekly-Class mob-display-none"
                          >
                            <div className="Weekly-Font">
                              Weekly Time Table &nbsp; &nbsp;{" "}
                              <HiOutlineCalendarDays size={20} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* For Mobile  */}

                    <div className="DisplayNoneWeb">
                      <div className="d-flex justify-content-center">
                        <div className="d-flex align-items-center gap-1 border_time">
                          <div
                            onClick={() => {
                              // alert(moment(selectedtimetableDate).format('dddd'))
                              if (
                                moment(selectedtimetableDate).format("dddd") ===
                                "Monday"
                              ) {
                                updateDates(
                                  moment(
                                    selectedtimetableDate,
                                    "DD/MMM/YYYY"
                                  ).add(-2, "days")
                                );
                              } else {
                                updateDates(
                                  moment(
                                    selectedtimetableDate,
                                    "DD/MMM/YYYY"
                                  ).add(-1, "days")
                                );
                              }
                            }}
                          >
                            <FaCaretLeft
                              size={25}
                              className="Icon_color_TT cursor-pointer"
                            />
                          </div>
                          <div className="DateDesignFont">
                            {selectedtimetableDate}
                          </div>
                          <div
                            onClick={() => {
                              // alert(moment(selectedtimetableDate).format('dddd'))
                              if (
                                moment(selectedtimetableDate).format("dddd") ===
                                "Saturday"
                              ) {
                                updateDates(
                                  moment(
                                    selectedtimetableDate,
                                    "DD/MMM/YYYY"
                                  ).add(2, "days")
                                );
                              } else {
                                updateDates(
                                  moment(
                                    selectedtimetableDate,
                                    "DD/MMM/YYYY"
                                  ).add(1, "days")
                                );
                              }
                            }}
                          >
                            <FaCaretRight
                              size={25}
                              className="Icon_color_TT cursor-pointer"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* For Mobile  */}

                    <div className="OuterSemiDiv1">
                    { console.log(pendSession,"Pending session details") }
                      {timeTableData.length > 0 &&
                        timeTableData.map((x, index) => {
                          let SUbjectSc = `${x.subjectName} ${localStorage.length > 0 &&
                            JSON.parse(localStorage.userCreds).userType ===
                              "Teaching"
                              ? `${x.variableName} ${x.section}`
                              : ""
                          }`;
                          let PendingSessData, pendSubject;
                          if(x.pendingSession !== null && x.pendingSession.length > 0 && pendSession.filter((q) => String(q.timetableId) === String(x.id)).length > 0 && pendSession.filter((q) => String(q.timetableId) === String(x.id))[0]?.pendingsessioncount > 0){
                            PendingSessData = x.pendingSession[pendSession.filter((q) => String(q.timetableId) === String(x.id))[0]?.pendingsessioncount - 1];
                            pendSubject = `${PendingSessData.subjectName} ${
                            localStorage.length > 0 &&
                            JSON.parse(localStorage.userCreds).userType ===
                              "Teaching"
                              ? `${PendingSessData.variableName} 
                                    ${PendingSessData.section}`
                              : ""
                          }`;
                          } else {
                            PendingSessData = []
                          }

                          console.log(PendingSessData,"PendingSessData")

                          return (
                            <>
                            { pendSession.filter((q) => String(q.timetableId) === String(x.id)).length > 0 && pendSession.filter((q) => String(q.timetableId) === String(x.id))[0]?.pendingsessioncount > 0 ?
                            (
                            <>
                            <div className="col-sm-4 paddingTopBot 1">
                              <div className="OuterSemiDiv2 padding-left-0">
                                <div className="mob-display-none">
                                  <div className="d-flex flex-row-reverse align-items-center justify-content-between gap-5 pad_TC_Sub">
                                    <div className="NumberCountDay">
                                      <div className="Today-Font">
                                        0{PendingSessData.period}
                                      </div>
                                    </div>
                                    <div>
                                      <span
                                        style={{
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          openBookview(
                                            PendingSessData.katbookUnitID,
                                            PendingSessData.katbookSessionID,
                                            PendingSessData.refSubjectID,
                                            PendingSessData.refSectionID
                                          );
                                        }}
                                        className="SubjectFontSub"
                                      >
                                        {/* Chemistry */}
                                        {/* {SUbjectSc} */}

                                        {/* Subject Name */}

                                        {SUbjectSc.toString().length > 25
                                          ? SUbjectSc.toString().slice(0, 25) +
                                            "..."
                                          : SUbjectSc}

                                        {/* Subject Name */}

                                        {/* {x.subjectName}&nbsp;
                                        {localStorage.length > 0 && JSON.parse(localStorage.userCreds).userType === "Teaching" ?
                                        `${x.variableName} 
                                      ${x.section}` : ''} */}
                                      </span>
                                      {/* {localStorage.length > 0 && JSON.parse(localStorage.userCreds).userType === "Teaching" &&
                                      <span
                                      style={{
                                          cursor: "pointer",
                                        }}
                                        className="E-Font"
                                        >
                                      &nbsp;&nbsp;{x.variableName}&nbsp;
                                      {x.section}
                                      </span>
                                      } */}
                                    </div>

                                    <div></div>
                                  </div>
                                </div>

                                <div className="DisplayNoneWeb">
                                  <div className="d-flex justify-content-center pad_TC_Sub">
                                    <div className="NumberCountDay">
                                      <div className="Today-Font">
                                        0{PendingSessData.period}
                                      </div>
                                    </div>
                                    <div>
                                      <span
                                        style={{
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          openBookview(
                                            PendingSessData.katbookUnitID,
                                            PendingSessData.katbookSessionID,
                                            PendingSessData.refSubjectID,
                                            PendingSessData.refSectionID
                                          );
                                        }}
                                        className="SubjectFontSub"
                                      >
                                        {/* Chemistry */}
                                        {/* {SUbjectSc} */}

                                        {/* Subject Name */}

                                        {SUbjectSc.toString().length > 25
                                          ? SUbjectSc.toString().slice(0, 25) +
                                            "..."
                                          : SUbjectSc}

                                        {/* Subject Name */}

                                        {/* {x.subjectName}&nbsp;
                                        {localStorage.length > 0 && JSON.parse(localStorage.userCreds).userType === "Teaching" ?
                                        `${x.variableName} 
                                      ${x.section}` : ''} */}
                                      </span>
                                      {/* {localStorage.length > 0 && JSON.parse(localStorage.userCreds).userType === "Teaching" &&
                                      <span
                                      style={{
                                          cursor: "pointer",
                                        }}
                                        className="E-Font"
                                        >
                                      &nbsp;&nbsp;{x.variableName}&nbsp;
                                      {x.section}
                                      </span>
                                      } */}
                                    </div>

                                    <div></div>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center">
                                {x.pendingSession !== null && x.pendingSession.length > 0 &&
                                <>
                                { pendSession?.filter((q) => String(q.timetableId) === String(x.id)).length > 0 && x.pendingSession.length - pendSession.filter((q) => String(q.timetableId) === String(x.id))[0]?.pendingsessioncount === 0 ? 
                                (
                                  <></>
                                ) : (
                                  <div className="d-flex flex-column"
                                  onClick={() => {
                                    viewPendingSessions(x)
                                  }}
                                  >
                                    <div className="left_arrow_TT">
                                      <MdChevronLeft
                                        size={20}
                                        className="left_arrow_font_TT cursor-pointer"
                                      />
                                    </div>
                                    <div className="left_position">{pendSession.length > 0 && pendSession.filter((q) => String(q.timetableId) === String(x.id)).length > 0 ? x.pendingSession.length - pendSession.filter((q) => String(q.timetableId) === String(x.id))[0]?.pendingsessioncount : x.pendingSession.length}</div>
                                  </div>
                                )
                                  }
                                  </>
                                  }

                                  <div className="PD_20_Sub_U_S">
                                    <div className="back_color_subject"
                                    style={{
                                      backgroundColor: PendingSessData.status === "Pending" ? "#fee0e0" : PendingSessData.status === "Completed" ? "" : ""
                                    }}
                                    >
                                      {/* Video and Time */}

                                      <div className="Video_Gap_Mob pb-1">
                                        {/* <div
                                          onClick={() => {
                                            // alert(selectedtimetableDate)
                                            if (
                                              (selectedtimetableDate ===
                                                moment().format(
                                                  "DD/MMM/YYYY"
                                                ) &&
                                                CuttentDateTime <
                                                  timeTablePeriod.filter(
                                                    (g) =>
                                                      String(g.PeriodNumber) ===
                                                      String(PendingSessData.period)
                                                  )[0]?.EndTime) ||
                                              selectedtimetableDate !==
                                                moment().format("DD/MMM/YYYY")
                                            ) {
                                              VideoConfFunc(
                                                selectedtimetableDate,
                                                PendingSessData?.subjectName,
                                                timeTablePeriod.filter(
                                                  (g) =>
                                                    String(g.PeriodNumber) ===
                                                    String(PendingSessData.period)
                                                )[0]?.StartTime,
                                                timeTablePeriod.filter(
                                                  (g) =>
                                                    String(g.PeriodNumber) ===
                                                    String(PendingSessData.period)
                                                )[0]?.EndTime,
                                                PendingSessData?.staffName,
                                                PendingSessData?.staffDesignation,
                                                PendingSessData?.katbookUnitID,
                                                PendingSessData?.katbookSessionID,
                                                PendingSessData?.refStaffID,
                                                PendingSessData?.id,
                                                PendingSessData?.refSubjectID,
                                                PendingSessData?.refSectionID
                                              );
                                            }
                                            // document.querySelector("body").style.overflow = "hidden";
                                          }}
                                        >
                                          <IoVideocam
                                            style={{
                                              color:
                                                selectedtimetableDate ===
                                                moment().format("DD/MMM/YYYY")
                                                  ? String(CuttentDateTime) <
                                                    String(
                                                      timeTablePeriod.filter(
                                                        (g) =>
                                                          String(
                                                            g.PeriodNumber
                                                          ) === String(PendingSessData.period)
                                                      )[0]?.EndTime
                                                    )
                                                    ? "#1653cc"
                                                    : "#ccc"
                                                  : "#1653cc",
                                              cursor:
                                                selectedtimetableDate ===
                                                moment().format("DD/MMM/YYYY")
                                                  ? CuttentDateTime <
                                                    timeTablePeriod.filter(
                                                      (g) =>
                                                        String(
                                                          g.PeriodNumber
                                                        ) === String(PendingSessData.period)
                                                    )[0]?.EndTime
                                                    ? "pointer"
                                                    : "default"
                                                  : "pointer",
                                            }}
                                            className="VideoSize-Color"
                                          />
                                        </div> */}
                                        {/* <div className="DisplayNone">
                                          <span className="Time-Font-am">
                                            {tConvert(
                                              timeTablePeriod.length > 0 &&
                                                timeTablePeriod.filter(
                                                  (g) =>
                                                    String(g.PeriodNumber) ===
                                                    String(PendingSessData.period)
                                                )[0]?.StartTime
                                            )}
                                            &nbsp; - &nbsp;
                                            {tConvert(
                                              timeTablePeriod.length > 0 &&
                                                timeTablePeriod.filter(
                                                  (g) =>
                                                    String(g.PeriodNumber) ===
                                                    String(PendingSessData.period)
                                                )[0]?.EndTime
                                            )}
                                          </span>
                                        </div>
                                        <div className="DisplayNoneWeb">
                                          <span className="Time-Font-am">
                                            {tConvert(
                                              timeTablePeriod.length > 0 &&
                                                timeTablePeriod.filter(
                                                  (g) =>
                                                    String(g.PeriodNumber) ===
                                                    String(PendingSessData.period)
                                                )[0]?.StartTime
                                            )}
                                            &nbsp; - &nbsp;
                                            {tConvert(
                                              timeTablePeriod.length > 0 &&
                                                timeTablePeriod.filter(
                                                  (g) =>
                                                    String(g.PeriodNumber) ===
                                                    String(PendingSessData.period)
                                                )[0]?.EndTime
                                            )}
                                          </span>
                                        </div> */}
                                        <div className={"Time-Font-am"}>
                                        {PendingSessData?.estStartDate && moment(new Date(PendingSessData?.estStartDate)).format("DD MMM YYYY")}
                                        </div>
                                      </div>

                                      {/* Video and Time */}

                                      <div
                                        style={{
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          openBookview(
                                            PendingSessData.katbookUnitID,
                                            PendingSessData.katbookSessionID,
                                            PendingSessData.refSubjectID,
                                            PendingSessData.refSectionID
                                          );
                                        }}
                                        className="d-flex flex-column align-items-start gap-1"
                                      >
                                        <ResultDataView
                                          unitID={PendingSessData?.katbookUnitID}
                                          sessionID={PendingSessData?.katbookSessionID}
                                        />
                                      </div>

                                      <div className="d-flex align-items-center justify-content-between mt-2">
                                        <div className="d-flex align-items-center gap-1">
                                          <div>
                                            <img
                                              src={Sample}
                                              alt=""
                                              width={25}
                                            />
                                          </div>
                                          <div>
                                            <span className="ImgNameFont">
                                              {/* Sheeba V */}

                                              {localStorage.length > 0 &&
                                              JSON.parse(localStorage.userCreds)
                                                .userType === "Teaching"
                                                ? JSON.parse(
                                                    localStorage.userCreds
                                                  ).adminName
                                                : x.staffName}
                                              {/* {x.staffName} */}
                                            </span>
                                          </div>
                                        </div>

                                        {/* Scheduled Task */}
                                        {PendingSessData.status === "Scheduled" &&
                                        <div className="d-flex align-items-center gap-1">
                                          <div>
                                            <AiOutlineSolution
                                              size={20}
                                              className="color_schedule"
                                            />
                                          </div>
                                          <div className="ImgNameFont color_schedule">
                                            {PendingSessData?.status}
                                          </div>
                                        </div>
                                        }
                                        {/* Scheduled Task */}

                                        {/* Completed Task */}
                                        {PendingSessData.status === "Completed" &&
                                        <div className="d-flex align-items-center gap-1">
                                            <div className="">
                                              <TiTickOutline
                                                size={25}
                                                className="color_complete_background"
                                              />
                                            </div>
                                            <div className="ImgNameFont color_complete">
                                              Completed
                                            </div>
                                          </div>
                                        }
                                        {/* Completed Task */}

                                        {/* Not complete Task */}
                                        {PendingSessData.status === "Pending" &&
                                        <div className="d-flex align-items-center gap-1">
                                            <div className="">
                                              <IoIosClose
                                                size={25}
                                                className="color_In_complete_background"
                                              />
                                            </div>
                                            <div className="ImgNameFont color_In_complete">
                                              Not Completed
                                            </div>
                                          </div>
                                          }
                                        {/* Not complete Task */}

                                      
                                      </div>
                                    </div>
                                  </div>
                                  { console.log(pendSession,"Pending session details") }
                                  {pendSession?.length > 0 && pendSession.filter((q) => String(q.timetableId) === String(x.id))[0]?.pendingsessioncount > 0 &&
                                  <div className="d-flex flex-column"
                                  onClick={() => {
                                    NextPendingSessions(x)
                                  }}
                                  >
                                    <div className="right_arrow_TT">
                                      <MdChevronRight
                                        size={20}
                                        className="right_arrow_font_TT cursor-pointer"
                                      />
                                    </div>
                                    <div className="right_position">{pendSession.filter((q) => String(q.timetableId) === String(x.id))[0]?.pendingsessioncount}</div>
                                  </div>
                                  }
                                </div>
                              </div>
                            </div>
                            </>
                            ) : (
                              <>
                            <div className="col-sm-4 paddingTopBot 1">
                              <div className="OuterSemiDiv2 padding-left-0">
                                <div className="mob-display-none">
                                  <div className="d-flex flex-row-reverse align-items-center justify-content-between gap-5 pad_TC_Sub">
                                    <div className="NumberCountDay">
                                      <div className="Today-Font">
                                        0{x.period}
                                      </div>
                                    </div>
                                    <div>
                                      <span
                                        style={{
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          openBookview(
                                            x.katbookUnitID,
                                            x.katbookSessionID,
                                            x.refSubjectID,
                                            x.refSectionID
                                          );
                                        }}
                                        className="SubjectFontSub"
                                      >
                                        {/* Chemistry */}
                                        {/* {SUbjectSc} */}

                                        {/* Subject Name */}

                                        {SUbjectSc.toString().length > 25
                                          ? SUbjectSc.toString().slice(0, 25) +
                                            "..."
                                          : SUbjectSc}
                                          

                                        {/* Subject Name */}

                                        {/* {x.subjectName}&nbsp;
                                        {localStorage.length > 0 && JSON.parse(localStorage.userCreds).userType === "Teaching" ?
                                        `${x.variableName} 
                                      ${x.section}` : ''} */}
                                      </span>
                                      {/* {localStorage.length > 0 && JSON.parse(localStorage.userCreds).userType === "Teaching" &&
                                      <span
                                      style={{
                                          cursor: "pointer",
                                        }}
                                        className="E-Font"
                                        >
                                      &nbsp;&nbsp;{x.variableName}&nbsp;
                                      {x.section}
                                      </span>
                                      } */}
                                    </div>

                                    <div></div>
                                  </div>
                                </div>

                                <div className="DisplayNoneWeb">
                                  <div className="d-flex justify-content-center pad_TC_Sub">
                                    <div className="NumberCountDay">
                                      <div className="Today-Font">
                                        0{x.period}
                                      </div>
                                    </div>
                                    <div>
                                      <span
                                        style={{
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          openBookview(
                                            x.katbookUnitID,
                                            x.katbookSessionID,
                                            x.refSubjectID,
                                            x.refSectionID
                                          );
                                        }}
                                        className="SubjectFontSub"
                                      >
                                        {/* Chemistry */}
                                        {/* {SUbjectSc} */}

                                        {/* Subject Name */}

                                        {SUbjectSc.toString().length > 25
                                          ? SUbjectSc.toString().slice(0, 25) +
                                            "..."
                                          : SUbjectSc}

                                        {/* Subject Name */}

                                        {/* {x.subjectName}&nbsp;
                                        {localStorage.length > 0 && JSON.parse(localStorage.userCreds).userType === "Teaching" ?
                                        `${x.variableName} 
                                      ${x.section}` : ''} */}
                                      </span>
                                      {/* {localStorage.length > 0 && JSON.parse(localStorage.userCreds).userType === "Teaching" &&
                                      <span
                                      style={{
                                          cursor: "pointer",
                                        }}
                                        className="E-Font"
                                        >
                                      &nbsp;&nbsp;{x.variableName}&nbsp;
                                      {x.section}
                                      </span>
                                      } */}
                                    </div>

                                    <div></div>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center">
                                {x.pendingSession !== null && x.pendingSession.length > 0 &&
                                <>
                                { pendSession?.filter((q) => String(q.timetableId) === String(x.id)).length > 0 && x.pendingSession.length - pendSession.filter((q) => String(q.timetableId) === String(x.id))[0]?.pendingsessioncount === 0 ? 
                                (
                                  <></>
                                ) : (
                                  <div className="d-flex flex-column"
                                  onClick={() => {
                                    viewPendingSessions(x)
                                  }}
                                  >
                                    <div className="left_arrow_TT">
                                      <MdChevronLeft
                                        size={20}
                                        className="left_arrow_font_TT cursor-pointer"
                                      />
                                    </div>
                                    <div className="left_position">{pendSession.length > 0 && pendSession.filter((q) => String(q.timetableId) === String(x.id)).length > 0 ? x.pendingSession.length - pendSession.filter((q) => String(q.timetableId) === String(x.id))[0]?.pendingsessioncount : x.pendingSession.length}</div>
                                  </div>
                                )
                                  }
                                  </>
                                  }

                                  <div className="PD_20_Sub_U_S">
                                    <div className="back_color_subject"
                                    style={{
                                      backgroundColor: x.status === "Pending" ? "#fee0e0" : x.status === "Completed" ? "" : ""
                                    }}
                                    >
                                      {/* Video and Time */}

                                      <div className="Video_Gap_Mob pb-1">
                                        <div
                                          onClick={() => {
                                            // alert(selectedtimetableDate)
                                            if (
                                              (selectedtimetableDate ===
                                                moment().format(
                                                  "DD/MMM/YYYY"
                                                ) &&
                                                CuttentDateTime <
                                                  timeTablePeriod.filter(
                                                    (g) =>
                                                      String(g.PeriodNumber) ===
                                                      String(x.period)
                                                  )[0]?.EndTime) ||
                                              selectedtimetableDate !==
                                                moment().format("DD/MMM/YYYY")
                                            ) {
                                              VideoConfFunc(
                                                selectedtimetableDate,
                                                x?.subjectName,
                                                timeTablePeriod.filter(
                                                  (g) =>
                                                    String(g.PeriodNumber) ===
                                                    String(x.period)
                                                )[0]?.StartTime,
                                                timeTablePeriod.filter(
                                                  (g) =>
                                                    String(g.PeriodNumber) ===
                                                    String(x.period)
                                                )[0]?.EndTime,
                                                x?.staffName,
                                                x?.staffDesignation,
                                                x?.katbookUnitID,
                                                x?.katbookSessionID,
                                                x?.refStaffID,
                                                x?.id,
                                                x?.refSubjectID,
                                                x?.refSectionID
                                              );
                                            }
                                            // document.querySelector("body").style.overflow = "hidden";
                                          }}
                                        >
                                          <IoVideocam
                                            style={{
                                              color:
                                                selectedtimetableDate ===
                                                moment().format("DD/MMM/YYYY")
                                                  ? String(CuttentDateTime) <
                                                    String(
                                                      timeTablePeriod.filter(
                                                        (g) =>
                                                          String(
                                                            g.PeriodNumber
                                                          ) === String(x.period)
                                                      )[0]?.EndTime
                                                    )
                                                    ? "#1653cc"
                                                    : "#ccc"
                                                  : "#1653cc",
                                              cursor:
                                                selectedtimetableDate ===
                                                moment().format("DD/MMM/YYYY")
                                                  ? CuttentDateTime <
                                                    timeTablePeriod.filter(
                                                      (g) =>
                                                        String(
                                                          g.PeriodNumber
                                                        ) === String(x.period)
                                                    )[0]?.EndTime
                                                    ? "pointer"
                                                    : "default"
                                                  : "pointer",
                                            }}
                                            className="VideoSize-Color"
                                          />
                                        </div>
                                        <div className="DisplayNone">
                                          <span className="Time-Font-am">
                                            {tConvert(
                                              timeTablePeriod.length > 0 &&
                                                timeTablePeriod.filter(
                                                  (g) =>
                                                    String(g.PeriodNumber) ===
                                                    String(x.period)
                                                )[0]?.StartTime
                                            )}
                                            &nbsp; - &nbsp;
                                            {tConvert(
                                              timeTablePeriod.length > 0 &&
                                                timeTablePeriod.filter(
                                                  (g) =>
                                                    String(g.PeriodNumber) ===
                                                    String(x.period)
                                                )[0]?.EndTime
                                            )}
                                          </span>
                                        </div>
                                        <div className="DisplayNoneWeb">
                                          <span className="Time-Font-am">
                                            {tConvert(
                                              timeTablePeriod.length > 0 &&
                                                timeTablePeriod.filter(
                                                  (g) =>
                                                    String(g.PeriodNumber) ===
                                                    String(x.period)
                                                )[0]?.StartTime
                                            )}
                                            &nbsp; - &nbsp;
                                            {tConvert(
                                              timeTablePeriod.length > 0 &&
                                                timeTablePeriod.filter(
                                                  (g) =>
                                                    String(g.PeriodNumber) ===
                                                    String(x.period)
                                                )[0]?.EndTime
                                            )}
                                          </span>
                                        </div>
                                      </div>

                                      {/* Video and Time */}

                                      <div
                                        style={{
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          openBookview(
                                            x.katbookUnitID,
                                            x.katbookSessionID,
                                            x.refSubjectID,
                                            x.refSectionID
                                          );
                                        }}
                                        className="d-flex flex-column align-items-start gap-1"
                                      >
                                        <ResultDataView
                                          unitID={x?.katbookUnitID}
                                          sessionID={x?.katbookSessionID}
                                        />
                                      </div>

                                      <div className="d-flex align-items-center justify-content-between mt-2">
                                        <div className="d-flex align-items-center gap-1">
                                          <div>
                                            <img
                                              src={Sample}
                                              alt=""
                                              width={25}
                                            />
                                          </div>
                                          <div>
                                            <span className="ImgNameFont">
                                              {/* Sheeba V */}

                                              {localStorage.length > 0 &&
                                              JSON.parse(localStorage.userCreds)
                                                .userType === "Teaching"
                                                ? JSON.parse(
                                                    localStorage.userCreds
                                                  ).adminName
                                                : x.staffName}
                                              {/* {x.staffName} */}
                                            </span>
                                          </div>
                                        </div>

                                        {/* Scheduled Task */}
                                        {x.status === "Scheduled" &&
                                        <div className="d-flex align-items-center gap-1">
                                          <div>
                                            <AiOutlineSolution
                                              size={20}
                                              className="color_schedule"
                                            />
                                          </div>
                                          <div className="ImgNameFont color_schedule">
                                            {x?.status}
                                          </div>
                                        </div>
                                        }
                                        {/* Scheduled Task */}

                                        {/* Completed Task */}
                                        {x.status === "Completed" &&
                                        <div className="d-flex align-items-center gap-1">
                                            <div className="">
                                              <TiTickOutline
                                                size={25}
                                                className="color_complete_background"
                                              />
                                            </div>
                                            <div className="ImgNameFont color_complete">
                                              Completed
                                            </div>
                                          </div>
                                        }
                                        {/* Completed Task */}

                                        {/* Not complete Task */}
                                        {x.status === "Pending" &&
                                        <div className="d-flex align-items-center gap-1">
                                            <div className="">
                                              <IoIosClose
                                                size={25}
                                                className="color_In_complete_background"
                                              />
                                            </div>
                                            <div className="ImgNameFont color_In_complete">
                                              Not Completed
                                            </div>
                                          </div>
                                          }
                                        {/* Not complete Task */}
                                      </div>
                                    </div>
                                  </div>
                                  { console.log(pendSession,"Pending session details") }
                                  {pendSession?.length > 0 && pendSession.filter((q) => String(q.timetableId) === String(x.id))[0]?.pendingsessioncount > 0 &&
                                  <div className="d-flex flex-column"
                                  onClick={() => {
                                    NextPendingSessions(x)
                                  }}
                                  >
                                    <div className="right_arrow_TT">
                                      <MdChevronRight
                                        size={20}
                                        className="right_arrow_font_TT cursor-pointer"
                                      />
                                    </div>
                                    <div className="right_position">{pendSession.filter((q) => String(q.timetableId) === String(x.id))[0]?.pendingsessioncount}</div>
                                  </div>
                                  }
                                </div>
                              </div>
                            </div>
                            </>
                            )
                            }
                            
                            </>
                          );
                        })}
                      {timeTableData.length === 0 && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "200px",
                            fontSize: "25px",
                            width: "100%",
                          }}
                        >
                          Timetable not available
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="d-flex flex-row px-2 py-3 w-100">
                <div className="col-md-12 OuterDiv10 padding-left-0 padding-right-0">
                  <div className="title-txt-label mob-display-none">
                    <div className="Today-Font DisplayNone">
                      <AiOutlineArrowLeft
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setWeeklyTable(!weeklyTable);
                        }}
                      />
                      &nbsp; Weekly Timetable
                    </div>
                  </div>

                  {Object.keys(weektimeTableData).length > 0 && (
                    <CContainer style={{ maxWidth: "1500px" }}>
                      <div className="py-4">
                        <div className="tableborderstyle">
                          <div className="Scroll_Table">
                            <table>
                              <tr>
                                <th className="tableheadDay">Days</th>

                                {JSON.parse(localStorage.userCreds).userType ===
                                  "Student" &&
                                  weektimeTablePeriod.map((x, i) => (
                                    <th className="tablehead">
                                      Period {x.PeriodNumber}
                                      <p className="tabletime">
                                        ({tConvert(x.StartTime)} -{" "}
                                        {tConvert(x.EndTime)})
                                      </p>
                                    </th>
                                  ))}

                                {JSON.parse(localStorage.userCreds).userType ===
                                  "Teaching" && (
                                  <th colSpan={10} className="tablehead">
                                    Teacher Timetable List
                                  </th>
                                )}

                                {/*               
                <th className="tablehead">
                  Period 2 <p className="tabletime">09:00am - 09:45am</p>
                </th>
                <th className="tablehead">
                  Period 3 <p className="tabletime">09:00am - 09:45am</p>
                </th>
                <th className="tablehead">
                  Period 4 <p className="tabletime">09:00am - 09:45am</p>
                </th>
                <th className="tablehead">
                  Period 5 <p className="tabletime">09:00am - 09:45am</p>
                </th>
                <th className="tablehead">
                  Period 6 <p className="tabletime">09:00am - 09:45am</p>
                </th>
                <th className="tablehead">
                  Period 7 <p className="tabletime">09:00am - 09:45am</p>
                </th> */}
                              </tr>

                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <div
                                  onClick={() => {
                                    if (TimeTablechangeCount > 0) {
                                      fetchPrevTimeTableList();
                                    }
                                  }}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    // borderRight: "1px solid #c1c1c1",
                                    // borderBottom: "1px solid #c1c1c1",
                                    // borderLeft: "1px solid #c1c1c1",
                                    borderBottomLeftRadius: "10px",
                                    borderBottomRightRadius: "10px",
                                    width: "50px",
                                    cursor:
                                      TimeTablechangeCount === 0
                                        ? ""
                                        : "pointer",
                                    backgroundColor:
                                      TimeTablechangeCount === 0
                                        ? "#c1c1c1"
                                        : "#1653cc",
                                  }}
                                >
                                  <AiOutlineUp
                                    style={{
                                      color:
                                        TimeTablechangeCount > 0
                                          ? "#fff"
                                          : "#ccc",
                                    }}
                                    size={20}
                                  />
                                </div>
                              </div>

                              {JSON.parse(localStorage.userCreds).userType ===
                                "Student" &&
                                Object.keys(weektimeTableData).map((z, i) => {
                                  return (
                                    <>
                                      <tr>
                                        <td className="days">
                                          {moment(z.split(" ")[1]).format(
                                            "MMM DD"
                                          )}
                                        </td>

                                        {/* { console.log(weektimeTableData[z],"GGGGGGGGGGGGGGGGGGG")} */}

                                        {weektimeTableData[z].map(
                                          (x, index) => {
                                            return (
                                              <>
                                                {x?.subjectName ? (
                                                  <>
                                                    <td className="tdata">
                                                      <div className="boxtab">
                                                        <span
                                                          onClick={() => {
                                                            openBookview(
                                                              x.katbookUnitID,
                                                              x.katbookSessionID,
                                                              x.refSubjectID,
                                                              x.refSectionID
                                                            );
                                                          }}
                                                          className="boxtitle"
                                                        >
                                                          {x.subjectName.toString()
                                                            .length > 13
                                                            ? x.subjectName
                                                                .toString()
                                                                .slice(0, 10) +
                                                              "..."
                                                            : x.subjectName}
                                                        </span>
                                                        &nbsp;&nbsp;
                                                        {x?.katbookUnitID !==
                                                          null &&
                                                          x?.katbookSessionID !==
                                                            null && (
                                                            <span
                                                              onClick={() => {
                                                                // alert(selectedtimetableDate)
                                                                if (
                                                                  (z.split(
                                                                    " "
                                                                  )[1] ===
                                                                    moment().format(
                                                                      "DD/MMM/YYYY"
                                                                    ) &&
                                                                    CuttentDateTime <
                                                                      weektimeTablePeriod.filter(
                                                                        (g) =>
                                                                          String(
                                                                            g.PeriodNumber
                                                                          ) ===
                                                                          String(
                                                                            x.period
                                                                          )
                                                                      )[0]
                                                                        ?.EndTime) ||
                                                                  z.split(
                                                                    " "
                                                                  )[1] !==
                                                                    moment().format(
                                                                      "DD/MMM/YYYY"
                                                                    )
                                                                ) {
                                                                  VideoConfFunc(
                                                                    z.split(
                                                                      " "
                                                                    )[1],
                                                                    x?.subjectName,
                                                                    weektimeTablePeriod.filter(
                                                                      (g) =>
                                                                        String(
                                                                          g.PeriodNumber
                                                                        ) ===
                                                                        String(
                                                                          x.period
                                                                        )
                                                                    )[0]
                                                                      ?.StartTime,
                                                                    weektimeTablePeriod.filter(
                                                                      (g) =>
                                                                        String(
                                                                          g.PeriodNumber
                                                                        ) ===
                                                                        String(
                                                                          x.period
                                                                        )
                                                                    )[0]
                                                                      ?.EndTime,
                                                                    x?.staffName,
                                                                    x?.staffDesignation,
                                                                    x?.katbookUnitID,
                                                                    x?.katbookSessionID,
                                                                    x?.refStaffID,
                                                                    x?.id,
                                                                    x?.refSubjectID,
                                                                    x?.refSectionID
                                                                  );
                                                                }
                                                                // document.querySelector("body").style.overflow = "hidden";
                                                              }}
                                                            >
                                                              <IoVideocam
                                                                style={{
                                                                  color:
                                                                    z.split(
                                                                      " "
                                                                    )[1] ===
                                                                    moment().format(
                                                                      "DD/MMM/YYYY"
                                                                    )
                                                                      ? String(
                                                                          CuttentDateTime
                                                                        ) <
                                                                        String(
                                                                          weektimeTablePeriod.filter(
                                                                            (
                                                                              g
                                                                            ) =>
                                                                              String(
                                                                                g.PeriodNumber
                                                                              ) ===
                                                                              String(
                                                                                x.period
                                                                              )
                                                                          )[0]
                                                                            ?.EndTime
                                                                        )
                                                                        ? "#1653cc"
                                                                        : "#ccc"
                                                                      : "#1653cc",
                                                                  cursor:
                                                                    z.split(
                                                                      " "
                                                                    )[1] ===
                                                                    moment().format(
                                                                      "DD/MMM/YYYY"
                                                                    )
                                                                      ? CuttentDateTime <
                                                                        weektimeTablePeriod.filter(
                                                                          (g) =>
                                                                            String(
                                                                              g.PeriodNumber
                                                                            ) ===
                                                                            String(
                                                                              x.period
                                                                            )
                                                                        )[0]
                                                                          ?.EndTime
                                                                        ? "pointer"
                                                                        : "default"
                                                                      : "pointer",
                                                                }}
                                                                className="VideoSize-Color"
                                                              />
                                                            </span>
                                                          )}
                                                        <div
                                                          onClick={() => {
                                                            openBookview(
                                                              x.katbookUnitID,
                                                              x.katbookSessionID,
                                                              x.refSubjectID,
                                                              x.refSectionID
                                                            );
                                                          }}
                                                        >
                                                          <ResultDataWeek
                                                            unitID={
                                                              x?.katbookUnitID
                                                            }
                                                            sessionID={
                                                              x?.katbookSessionID
                                                            }
                                                          />
                                                        </div>
                                                        {x?.katbookUnitID !==
                                                          null &&
                                                          x?.katbookSessionID !==
                                                            null && (
                                                            <div className=" d-flex gap-1 mt-2">
                                                              <div>
                                                                <img
                                                                  src={Sample}
                                                                  alt=""
                                                                  width={20}
                                                                />
                                                              </div>
                                                              <div>
                                                                <span
                                                                  className={
                                                                    "boxsec"
                                                                  }
                                                                >
                                                                  {/* Sheeba V */}

                                                                  {localStorage.length >
                                                                    0 &&
                                                                  JSON.parse(
                                                                    localStorage.userCreds
                                                                  ).userType ===
                                                                    "Teaching"
                                                                    ? JSON.parse(
                                                                        localStorage.userCreds
                                                                      )
                                                                        .adminName
                                                                    : x.staffName}
                                                                  {/* {x.staffName} */}
                                                                </span>
                                                              </div>
                                                            </div>
                                                          )}
                                                      </div>
                                                    </td>
                                                  </>
                                                ) : (
                                                  <>
                                                    <td> - </td>
                                                  </>
                                                )}
                                              </>
                                            );
                                          }
                                        )}
                                      </tr>
                                    </>
                                  );
                                })}

                              {JSON.parse(localStorage.userCreds).userType ===
                                "Teaching" &&
                                Object.keys(weektimeTableData).map((z, i) => {
                                  return (
                                    <>
                                      <tr>
                                        <td className="days">
                                          {moment(z.split(" ")[1]).format(
                                            "MMM DD"
                                          )}
                                        </td>

                                        {/* { console.log(weektimeTableData[z],"GGGGGGGGGGGGGGGGGGG")} */}

                                        {weektimeTableData[z].map(
                                          (x, index) => {
                                            let SUbjectSc = `${x.subjectName} ${x.variableName} ${x.section}`;
                                            return (
                                              <>
                                                {x?.subjectName ? (
                                                  <>
                                                    <td className="tdata">
                                                      Period{" "}
                                                      {
                                                        weektimeTablePeriod.filter(
                                                          (q) =>
                                                            String(
                                                              q.PeriodNumber
                                                            ) ===
                                                            String(x.period)
                                                        )[0].PeriodNumber
                                                      }
                                                      <p className="tabletime">
                                                        (
                                                        {tConvert(
                                                          weektimeTablePeriod.filter(
                                                            (g) =>
                                                              String(
                                                                g.PeriodNumber
                                                              ) ===
                                                              String(x.period)
                                                          )[0]?.StartTime
                                                        )}{" "}
                                                        -{" "}
                                                        {tConvert(
                                                          weektimeTablePeriod.filter(
                                                            (g) =>
                                                              String(
                                                                g.PeriodNumber
                                                              ) ===
                                                              String(x.period)
                                                          )[0]?.EndTime
                                                        )}
                                                        )
                                                      </p>
                                                      <div className="boxtab">
                                                        <span
                                                          onClick={() => {
                                                            openBookview(
                                                              x.katbookUnitID,
                                                              x.katbookSessionID,
                                                              x.refSubjectID,
                                                              x.refSectionID
                                                            );
                                                          }}
                                                          className="boxtitle"
                                                        >
                                                          {/* {x.subjectName.toString().length > 13 ? x.subjectName.toString().slice(0,10)+'...' : x.subjectName} */}
                                                          {SUbjectSc}
                                                        </span>
                                                        {/* <span
                                                      style={{
                                                          cursor: "pointer",
                                                        }}
                                                        className="boxtitle"
                                                        >
                                                      &nbsp;{x?.variableName}&nbsp;
                                                      {x?.section}
                                                      </span> */}
                                                        &nbsp;&nbsp;
                                                        {x?.katbookUnitID !==
                                                          null &&
                                                          x?.katbookSessionID !==
                                                            null && (
                                                            <span
                                                              onClick={() => {
                                                                // alert(selectedtimetableDate)
                                                                if (
                                                                  (z.split(
                                                                    " "
                                                                  )[1] ===
                                                                    moment().format(
                                                                      "DD/MMM/YYYY"
                                                                    ) &&
                                                                    CuttentDateTime <
                                                                      weektimeTablePeriod.filter(
                                                                        (g) =>
                                                                          String(
                                                                            g.PeriodNumber
                                                                          ) ===
                                                                          String(
                                                                            x.period
                                                                          )
                                                                      )[0]
                                                                        ?.EndTime) ||
                                                                  z.split(
                                                                    " "
                                                                  )[1] !==
                                                                    moment().format(
                                                                      "DD/MMM/YYYY"
                                                                    )
                                                                ) {
                                                                  VideoConfFunc(
                                                                    z.split(
                                                                      " "
                                                                    )[1],
                                                                    x?.subjectName,
                                                                    weektimeTablePeriod.filter(
                                                                      (g) =>
                                                                        String(
                                                                          g.PeriodNumber
                                                                        ) ===
                                                                        String(
                                                                          x.period
                                                                        )
                                                                    )[0]
                                                                      ?.StartTime,
                                                                    weektimeTablePeriod.filter(
                                                                      (g) =>
                                                                        String(
                                                                          g.PeriodNumber
                                                                        ) ===
                                                                        String(
                                                                          x.period
                                                                        )
                                                                    )[0]
                                                                      ?.EndTime,
                                                                    x?.staffName,
                                                                    x?.staffDesignation,
                                                                    x?.katbookUnitID,
                                                                    x?.katbookSessionID,
                                                                    x?.refStaffID,
                                                                    x?.id,
                                                                    x?.refSubjectID,
                                                                    x?.refSectionID
                                                                  );
                                                                }
                                                                // document.querySelector("body").style.overflow = "hidden";
                                                              }}
                                                            >
                                                              {x.katbookUnitID !==
                                                                null && (
                                                                <IoVideocam
                                                                  style={{
                                                                    color:
                                                                      z.split(
                                                                        " "
                                                                      )[1] ===
                                                                      moment().format(
                                                                        "DD/MMM/YYYY"
                                                                      )
                                                                        ? String(
                                                                            CuttentDateTime
                                                                          ) <
                                                                          String(
                                                                            weektimeTablePeriod.filter(
                                                                              (
                                                                                g
                                                                              ) =>
                                                                                String(
                                                                                  g.PeriodNumber
                                                                                ) ===
                                                                                String(
                                                                                  x.period
                                                                                )
                                                                            )[0]
                                                                              ?.EndTime
                                                                          )
                                                                          ? "#1653cc"
                                                                          : "#ccc"
                                                                        : "#1653cc",
                                                                    cursor:
                                                                      z.split(
                                                                        " "
                                                                      )[1] ===
                                                                      moment().format(
                                                                        "DD/MMM/YYYY"
                                                                      )
                                                                        ? CuttentDateTime <
                                                                          weektimeTablePeriod.filter(
                                                                            (
                                                                              g
                                                                            ) =>
                                                                              String(
                                                                                g.PeriodNumber
                                                                              ) ===
                                                                              String(
                                                                                x.period
                                                                              )
                                                                          )[0]
                                                                            ?.EndTime
                                                                          ? "pointer"
                                                                          : "default"
                                                                        : "pointer",
                                                                  }}
                                                                  className="VideoSize-Color"
                                                                />
                                                              )}
                                                            </span>
                                                          )}
                                                        <div
                                                          onClick={() => {
                                                            openBookview(
                                                              x.katbookUnitID,
                                                              x.katbookSessionID,
                                                              x.refSubjectID,
                                                              x.refSectionID
                                                            );
                                                          }}
                                                        >
                                                          <ResultDataWeek
                                                            unitID={
                                                              x?.katbookUnitID
                                                            }
                                                            sessionID={
                                                              x?.katbookSessionID
                                                            }
                                                          />
                                                        </div>
                                                        {x?.katbookUnitID !==
                                                          null &&
                                                          x?.katbookSessionID !==
                                                            null && (
                                                            <div className=" d-flex gap-1 mt-2">
                                                              <div>
                                                                <img
                                                                  src={Sample}
                                                                  alt=""
                                                                  width={20}
                                                                />
                                                              </div>
                                                              <div>
                                                                <span
                                                                  className={
                                                                    "boxsec"
                                                                  }
                                                                >
                                                                  {/* Sheeba V */}

                                                                  {localStorage.length >
                                                                    0 &&
                                                                  JSON.parse(
                                                                    localStorage.userCreds
                                                                  ).userType ===
                                                                    "Teaching"
                                                                    ? JSON.parse(
                                                                        localStorage.userCreds
                                                                      )
                                                                        .adminName
                                                                    : x.staffName}
                                                                  {/* {x.staffName} */}
                                                                </span>
                                                              </div>
                                                            </div>
                                                          )}
                                                      </div>
                                                    </td>
                                                  </>
                                                ) : (
                                                  <>
                                                    <td> - </td>
                                                  </>
                                                )}
                                              </>
                                            );
                                          }
                                        )}
                                      </tr>
                                    </>
                                  );
                                })}

                              <div
                                style={{
                                  padding: "0px 20px",
                                  marginTop: "-25px",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <div
                                  onClick={() => {
                                    fetchNextTimeTableList(
                                      nextDateTimeTbale,
                                      prevDateTimeTable
                                    );
                                  }}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    // borderRight: "1px solid #c1c1c1",
                                    // borderTop: "1px solid #c1c1c1",
                                    // borderLeft: "1px solid #c1c1c1",
                                    borderTopLeftRadius: "10px",
                                    borderTopRightRadius: "10px",
                                    width: "50px",
                                    cursor: "pointer",
                                    backgroundColor: "#1653cc",
                                  }}
                                >
                                  <AiOutlineDown
                                    size={20}
                                    style={{ color: "#fff" }}
                                  />
                                </div>
                              </div>
                            </table>
                          </div>
                        </div>
                      </div>
                    </CContainer>
                  )}

                  {Object.keys(weektimeTableData).length === 0 && (
                    <CContainer style={{ maxWidth: "1500px" }}>
                      <div className="px-3 py-4">
                        <div className="tableborderstyle">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "200px",
                              fontSize: "25px",
                              width: "100%",
                            }}
                          >
                            Timetable not available
                          </div>
                        </div>
                      </div>
                    </CContainer>
                  )}
                </div>
              </div>
            )}
          </>
        )}
        {/* Today Classes for view more */}

        {/* Exam Schedule for View more */}
        {examSchedule && (
          <>
            <div className="d-flex flex-row align-items-center justify-content-between Header-Style web-display-none">
              <div className="d-flex flex-row align-items-center gap-3">
                <div className="Today-Font">
                  <AiOutlineArrowLeft
                    size={20}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setExamSchedule(!examSchedule);
                      setOverAll(true);
                      window.scrollTo(0, 0);
                    }}
                  />
                </div>
                <div className="Today-Font">&nbsp; Exam Schedule</div>
              </div>

              <div
                onClick={ShowProfile}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  // marginRight: '15px',
                }}
              >
                <div onClick={ShowProfile} className="cursor-hover">
                  <img
                    src={StLogo}
                    style={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "50%",
                      marginRight: "10px",
                      marginLeft: "10px",
                    }}
                    alt=""
                  />
                </div>
                <div
                  style={{
                    cursor: "pointer",
                  }}
                  className="dropdown-profile"
                >
                  <div className={"Name_Nikil"}>
                    {localStorage.length > 0 &&
                      JSON.parse(localStorage.userCreds).adminName}
                  </div>
                  <div className={"Class_section"}>
                    {localStorage.length > 0 &&
                      JSON.parse(localStorage.userCreds).designation}
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="d-flex align-items-center justify-content-between"
              style={{backgroundColor:"#E6E6E6",padding:"10px 15px",border:"1px solid #CACACA",}}>
                <div>
                  Monthly Exam (September - 2022) 
                </div>
                <div>
                  <AiOutlineRight size={20} style={{cursor:"pointer"}} />
                </div>
              </div> */}
            <div className="Assignment_None">
              <select className="DropDown_Css">
                <option>Monthly Exam (August - 2023)</option>
                <option>Monthly Exam (September - 2023)</option>
                <option>Monthly Exam (October - 2023)</option>
                <option>Monthly Exam (November- 2023)</option>
              </select>
            </div>

            <div className="d-flex px-2 py-3 flex-row w-100">
              <div className="col-md-12 OuterDiv10 padding-left-0 padding-right-0 MTop_Examchedule">
                <div className="d-flex flex-row align-items-center gap-5">
                  <div className="title-txt-label mob-display-none">
                    <div className="Today-Font DisplayNone">
                      <AiOutlineArrowLeft
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setExamSchedule(!examSchedule);
                          setOverAll(true);
                        }}
                      />{" "}
                      &nbsp; Exam Schedule
                    </div>
                  </div>
                  <div className="mob-display-none">
                    <select name="cars" id="cars" className="DropAlignDesign">
                      {DropDownValuesChedule.map((x) => {
                        return (
                          <option className="DropAlignDesignFont">
                            {/* Monthly Exam (September - 2023) */}
                            {x.value}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div className="OuterSemiDiv1">
                  {ExamScheduleVM.map((x) => {
                    return (
                      <div className="col-sm-4 paddingTopBot 2">
                        <div className="OuterSemiDiv3 padding-left-0">
                          <div className="d-flex flex-column gap-1">
                            <div>
                              <span className="G_E_Font">
                                {/* General English */}
                                {x.subject}
                              </span>
                            </div>
                            <div>
                              <span className="G_E_Date_Font">
                                {/* 19 Sep 2022  */}
                                {x.date} &nbsp;
                                <span className="G_E_Time_Font">
                                  {/* (10:00 am - 01:00 pm) */}
                                  {x.time}
                                </span>
                              </span>
                            </div>
                          </div>

                          <div className="d-flex flex-column gap-3 mt-3">
                            <div>
                              <span className="U_Topic_Font">
                                Unit 1 -{" "}
                                <span className="U_Topic_Les_Font">
                                  {/* Hydroxy Compounds and Ethers  */}
                                  {x.unit}
                                </span>
                              </span>
                            </div>
                            <div className="d-flex flex-row gap-3 align-items-center">
                              <div>
                                <img
                                  src={Sample}
                                  alt=""
                                  width={40}
                                  height={40}
                                />
                              </div>
                              <div>
                                <span className="Img_Name_Font">
                                  {/* Sheeba V */}
                                  {x.name}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </>
        )}
        {/* Exam Schedule for View more */}

        {/* My Attendance for View more */}
        {viewMoreAttendance && (
          <>
            <div className="d-flex flex-row align-items-center justify-content-between Header-Style web-display-none">
              <div className="d-flex flex-row align-items-center gap-3">
                <div className="Today-Font">
                  <AiOutlineArrowLeft
                    size={20}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setViewMoreAttendance(!viewMoreAttendance);
                      setOverAll(true);
                      window.scrollTo(0, 0);
                    }}
                  />
                </div>
                <div className="Today-Font">&nbsp; Attendance</div>
              </div>

              <div
                onClick={ShowProfile}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  // marginRight: '15px',
                }}
              >
                <div onClick={ShowProfile} className="cursor-hover">
                  <img
                    src={StLogo}
                    style={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "50%",
                      marginRight: "10px",
                      marginLeft: "10px",
                    }}
                    alt=""
                  />
                </div>
                <div
                  style={{
                    cursor: "pointer",
                  }}
                  className="dropdown-profile"
                >
                  <div className={"Name_Nikil"}>
                    {localStorage.length > 0 &&
                      JSON.parse(localStorage.userCreds).adminName}
                  </div>
                  <div className={"Class_section"}>
                    {localStorage.length > 0 &&
                      JSON.parse(localStorage.userCreds).designation}
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex flex-row px-2 py-3 w-100 Scroll_Attendance_Month">
              <div className="col-md-12 OuterDiv10 padding-left-0 padding-right-0 MTop Padding_0px Back_None">
                <div className="title-txt-label mob-display-none">
                  <div className="Today-Font DisplayNone">
                    <AiOutlineArrowLeft
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setViewMoreAttendance(false);
                        setOverAll(true);
                      }}
                    />{" "}
                    &nbsp; Attendance
                  </div>
                </div>

                <div className="AttendanceFlex">
                  <div
                    className="col-md-8 Padding_0px"
                    // className={"float-left column_Box padFirsLef maritopGin"}
                  >
                    <div className="viewAttend">
                      <h5 className={"AttendViHead"}>Total Attendance</h5>
                      <div className="AttendTotalDays">
                        {AttendanceView.map((x, i) => {
                          return (
                            <div
                              className="d-flex flex-column gap-2"
                              style={{ color: colorsAttendance[i] }}
                              key={i}
                            >
                              <div className="text-center nameAttenvi">
                                {x.name}
                              </div>
                              <div className="text-center numberAttenvi">
                                {x.number}
                              </div>
                            </div>
                          );
                        })}
                      </div>

                      <div className="mob-display-none">
                        {isHovering && (
                          <div className="d-flex flex-column ml-5 w-25 shadow bg-white rounded hideattendance">
                            <div className="px-5 py-3 bg-secondary text-white">
                              Month - num%
                            </div>
                            <div className="px-3 py-2 text-primary">
                              Working Days - num
                            </div>
                            <div className="px-3 py-2 text-success">
                              Present - num
                            </div>
                            <div className="px-3 py-2 text-danger">
                              Absent - num
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="Width_Scroll">
                        <div className="Chart_Month">
                          <div className="mob-display-none">
                            {ChartMonth.map((x, i) => {
                              return (
                                <div className="progressvi">
                                  <div
                                    className="barboriee1vi"
                                    style={{
                                      height: hieghtChart[i],
                                    }}
                                    key={i}
                                    onMouseOver={() => {
                                      setIsHovering(true);
                                    }}
                                    onMouseOut={() => {
                                      setIsHovering(false);
                                    }}
                                  ></div>
                                  <div className="janatten">{x.name}</div>
                                </div>
                              );
                            })}
                          </div>

                          {/* For Mobile */}
                          <div className="web-display-none">
                            {ChartMonth.map((x, i) => {
                              return (
                                <div className="progressvi">
                                  <div
                                    className="barboriee1vi"
                                    style={{
                                      height: hieghtChartMobile[i],
                                    }}
                                    key={i}
                                    onMouseOver={() => {
                                      setIsHovering(true);
                                    }}
                                    onMouseOut={() => {
                                      setIsHovering(false);
                                    }}
                                  ></div>
                                  <div className="janatten">{x.name}</div>
                                </div>
                              );
                            })}
                          </div>
                          {/* For Mobile */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-md-4 Padding_0px"
                    // className={"float-left column_Box padFirsLef maritopGin"}
                  >
                    <div className="viewAttend1">
                      <h5 className={"AttendViHead"}>This Month</h5>
                      <div className="Attend_Month">
                        {AttendanceView.map((x, i) => {
                          return (
                            <div
                              className="d-flex flex-column gap-2"
                              style={{ color: colorsAttendance[i] }}
                              key={i}
                            >
                              <div className="text-center nameAttenvi">
                                {x.name}
                              </div>
                              <div className="text-center numberAttenvi">
                                {x.next}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div className="ClassendarVi">
                        <Calendar
                          onChange={setCalendar}
                          value={calendar}
                          maxDate={new Date()}
                        />
                        <br />
                        <p className="text-center">{calendar.toDateString()}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {/* My Attendance for View more */}

        {/* Diary for View more */}
        {viewMoreDiary && (
          <>
            <div className="d-flex flex-row align-items-center justify-content-between Header-Style web-display-none">
              <div className="d-flex flex-row align-items-center gap-3">
                <div className="Today-Font">
                  <AiOutlineArrowLeft
                    size={20}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setViewMoreDiary(!viewMoreDiary);
                      setOverAll(true);
                      window.scrollTo(0, 0);
                    }}
                  />
                </div>
                <div className="Today-Font">&nbsp; Diary</div>
              </div>

              <div
                onClick={ShowProfile}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  // marginRight: '15px',
                }}
              >
                <div onClick={ShowProfile} className="cursor-hover">
                  <img
                    src={StLogo}
                    style={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "50%",
                      marginRight: "10px",
                      marginLeft: "10px",
                    }}
                    alt=""
                  />
                </div>
                <div
                  style={{
                    cursor: "pointer",
                  }}
                  className="dropdown-profile"
                >
                  <div className={"Name_Nikil"}>
                    {localStorage.length > 0 &&
                      JSON.parse(localStorage.userCreds).adminName}
                  </div>
                  <div className={"Class_section"}>
                    {localStorage.length > 0 &&
                      JSON.parse(localStorage.userCreds).designation}
                  </div>
                </div>
              </div>
            </div>

            <div className="d-flex flex-row px-2 py-3 w-100">
              <div className="col-md-12 OuterDiv10 padding-left-0 padding-right-0 MTop_Diary">
                <div className="mob-display-none">
                  <div className="d-flex align-items-center justify-content-start gap-5">
                    <div className="title-txt-label mob-display-none ">
                      <div className="Today-Font DisplayNone">
                        <AiOutlineArrowLeft
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setViewMoreDiary(false);
                            setOverAll(true);
                          }}
                        />{" "}
                        &nbsp; Diary
                      </div>
                    </div>
                    <div
                      style={{ cursor: "pointer" }}
                      //  onClick={handleShow}
                      onClick={() => {
                        setPost(true);
                        setVisible(true);
                      }}
                      className="d-flex align-items-center gap-3"
                    >
                      <div>
                        <BsFillPlusCircleFill
                          size={25}
                          style={{ color: "#0166CF", cursor: "pointer" }}
                        />
                      </div>
                      <div className="DateDesignPlus">
                        <div className="DateDesignFont">Add</div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <Modal
                      show={show}
                      onHide={handleClose}
                      backdrop="static"
                      keyboard={false}
                     
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Modal title</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        I will not close if you click outside me. Don't even try to
                        press escape key.
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                          Close
                        </Button>
                        <Button variant="primary">Understood</Button>
                      </Modal.Footer>
                    </Modal> */}

                <CModal
                  alignment="center"
                  visible={visible}
                  onClose={() => setVisible(false)}
                  className="ModalHeight"
                >
                  <div
                    style={{
                      backgroundColor: "white",
                      padding: "0px 30px 10px 30px",
                      borderRadius: "10px",
                      width: "600px",
                    }}
                  >
                    <CModalHeader>
                      <div className="AddTitleFont">Add Title</div>
                    </CModalHeader>

                    <div className="DescFont">
                      <span>Description</span>
                    </div>

                    <div>
                      <textarea className="TextArea_Diary" rows="1" cols="70" />
                    </div>

                    <div className="d-flex justify-content-between mt-1">
                      <div className="Cal_Date">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "15px",
                            padding: "8px 10px",
                          }}
                        >
                          <div className="Diary_Modal_Icon">
                            <IoCalendarOutline
                              size={20}
                              style={{ color: "white", padding: "0px" }}
                            />
                          </div>
                          <div>
                            <span className="Diary_Modal_Font">
                              15 Jan 2023
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="Cal_Date">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            padding: "8px 10px",
                          }}
                        >
                          <div className="Diary_Modal_Icon">
                            <BiTime
                              size={20}
                              style={{ color: "white", padding: "0px" }}
                            />
                          </div>
                          <div>
                            <span className="Diary_Modal_Font">
                              10:00 - 12:00
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="Cal_Date">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            padding: "8px 10px",
                          }}
                        >
                          <div className="Diary_Modal_Icon">
                            <TbRepeat
                              size={20}
                              style={{ color: "white", padding: "0px" }}
                            />
                          </div>
                          <div>
                            <div className="Diary_Modal_Font">
                              <select
                                name="timetable"
                                id="timetable"
                                className="TimeDropdown"
                                onChange={(e) => {
                                  setRepeatDropdown(e.target.value);
                                }}
                              >
                                {DiaryDropdown.map((x) => {
                                  return (
                                    <option value={x.value}>{x.value}</option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {repeatDropdown === "Weekly" ? (
                      <div>
                        <div className="WeeklyOuterDiv mt-3">
                          <div style={{ padding: "15px 25px" }}>
                            <div
                              style={{
                                paddingBottom: "15px",
                                borderBottom: "1px solid #DBDBDB",
                              }}
                              className="d-flex flex-column gap-3"
                            >
                              <div>
                                <span className="P_Week_Font">
                                  Preferred Week
                                </span>
                              </div>
                              <div className="d-flex justify-content-between">
                                {Weekly.map((x) => {
                                  return (
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <input type="checkbox" /> &nbsp;
                                      <span className="Week_Days_Font">
                                        {x.number}
                                      </span>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>

                            <div className="d-flex flex-column gap-3 mt-2">
                              <div>
                                <span className="P_Week_Font">
                                  Preferred Days
                                </span>
                              </div>
                              <div className="d-flex justify-content-between">
                                {Days.map((x) => {
                                  return (
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <input type="checkbox" /> &nbsp;
                                      <span>{x.day}</span>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : repeatDropdown === "Monthly" ? (
                      <div className="WeeklyOuterDiv mt-2">
                        <div style={{ padding: "5px 25px" }}>
                          <div
                            style={{
                              paddingBottom: "15px",
                              borderBottom: "1px solid #DBDBDB",
                            }}
                            className="d-flex flex-column gap-3"
                          >
                            <div>
                              <span className="P_Week_Font">
                                Preferred Month
                              </span>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                // justifyContent:"space-between",
                                gap: "20px",
                                flexWrap: "wrap",
                              }}
                            >
                              {Months.map((x) => {
                                return (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <input type="checkbox" /> &nbsp;
                                    <span className="Week_Days_Font">
                                      {x.month}
                                    </span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>

                          <div className="d-flex flex-column gap-3 my-2">
                            <div>
                              <span className="P_Week_Font">
                                Preferred Dates
                              </span>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: "15px 13px",
                              }}
                            >
                              {Dates.map((x) => {
                                return (
                                  <div className="d-flex align-items-center">
                                    <input type="checkbox" /> &nbsp;
                                    <span>{x.date}</span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>

                          <div className="d-flex flex-column gap-3 mt-2">
                            <div>
                              <span className="P_Week_Font">
                                Preferred Days
                              </span>
                            </div>
                            <div className="d-flex justify-content-between">
                              {Days.map((x) => {
                                return (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <input type="checkbox" /> &nbsp;
                                    <span>{x.day}</span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    <div className="d-flex align-items-center justify-content-between mt-3">
                      <div className="d-flex flex-column gap-2">
                        <div>
                          <span className="Notify_Modal">Notify</span>
                        </div>
                        <div>
                          <div className="Modal_Diary_Time_Font">
                            30 Minutes Before &nbsp;
                            <span>
                              <AiOutlineDown />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex flex-column gap-2">
                        <div>
                          <span className="Diary_Prio_Font">Priority</span>
                        </div>
                        <div className="d-flex align-items-center gap-3">
                          <div>
                            <input
                              type="radio"
                              id="pri1"
                              name="age"
                              value="Low"
                            />{" "}
                            &nbsp;
                            <span className="Modal_LHM_Font">Low</span>
                          </div>
                          <div>
                            <input
                              type="radio"
                              id="pri2"
                              name="age"
                              value="Medium"
                            />{" "}
                            &nbsp;
                            <span>Medium</span>
                          </div>
                          <div>
                            <input
                              type="radio"
                              id="pri3"
                              name="age"
                              value="High"
                            />{" "}
                            &nbsp;
                            <span>High</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex justify-content-end align-items-center mt-3">
                      <div className="Modal_Button_Add">
                        <div className="Button_Add_Font">Add</div>
                      </div>
                    </div>
                  </div>
                </CModal>

                <div className="d-flex flex-row w-100 p-3">
                  <div
                    className="col-md-8"
                    // className={"float-left column_Box padFirsLef maritopGin"}
                  >
                    <div className="viewAttend">
                      <div className="datediarysubmenu">21 Aug 2022</div>

                      <div className="contentSubmenu">
                        There are 3 tasks for today.
                      </div>

                      <div className="pb-4">
                        {diarySubMEnu.map((x) => {
                          return (
                            <div className="paintContentSubmenu">
                              <div className="d-flex flex-row justify-content-between align-items-center">
                                <div className="diarySubmenuDate">
                                  10:30 - 11:00
                                </div>
                                <div>
                                  <HiEllipsisVertical
                                    size={25}
                                    className={"ellipsePlus"}
                                  />
                                </div>
                              </div>
                              <div className="seconContentsubmenu">
                                UI / UX Design for Diary Concept
                              </div>
                              <div className="thirdContentsubmenu">
                                Lorem Ipsum is simply dummy text of the printing
                                and typesetting industry. Lorem Ipsum has been
                                the industry's standard dummy text ever since
                                the 1500s, when an unknown printer took a galley
                                of type.
                              </div>
                            </div>
                          );
                        })}
                      </div>

                      <div></div>

                      <div className="datediarysubmenu">22 Aug 2022</div>

                      <div className="contentSubmenu">
                        There are 3 tasks for today.
                      </div>

                      {diarySubMEnu.map((x) => {
                        return (
                          <div className="paintContentSubmenu">
                            <div className="d-flex flex-row justify-content-between align-items-center">
                              <div className="diarySubmenuDate">
                                10:30 - 11:00
                              </div>
                              <div>
                                <HiEllipsisVertical
                                  size={25}
                                  className={"ellipsePlus"}
                                />
                              </div>
                            </div>
                            <div className="seconContentsubmenu">
                              UI / UX Design for Diary Concept
                            </div>
                            <div className="thirdContentsubmenu">
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has been the
                              industry's standard dummy text ever since the
                              1500s, when an unknown printer took a galley of
                              type.
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  <div
                    className="col-md-4 mob-display-none"
                    // className={"float-left column_Box padFirsLef maritopGin"}
                  >
                    <div className="viewAttend">
                      <div className="ClassendarVi">
                        <Calendar
                          onChange={setCalendar}
                          value={calendar}
                          maxDate={new Date()}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {/* Diary for View more */}

        {/* Assignment for View more */}
        {viewMoreAssignment && (
          <>
            <div className="d-flex flex-row align-items-center justify-content-between Header-Style web-display-none">
              <div className="d-flex flex-row align-items-center gap-3">
                <div className="Today-Font">
                  <AiOutlineArrowLeft
                    size={20}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setViewMoreAssignment(!viewMoreAssignment);
                      setOverAll(true);
                      window.scrollTo(0, 0);
                    }}
                  />
                </div>
                <div className="Today-Font">&nbsp; Assignment</div>
              </div>

              <div
                onClick={ShowProfile}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  // marginRight: '15px',
                }}
              >
                <div onClick={ShowProfile} className="cursor-hover">
                  <img
                    src={StLogo}
                    style={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "50%",
                      marginRight: "10px",
                      marginLeft: "10px",
                    }}
                    alt=""
                  />
                </div>
                <div
                  style={{
                    cursor: "pointer",
                  }}
                  className="dropdown-profile"
                >
                  <div className={"Name_Nikil"}>
                    {localStorage.length > 0 &&
                      JSON.parse(localStorage.userCreds).adminName}
                  </div>
                  <div className={"Class_section"}>
                    {localStorage.length > 0 &&
                      JSON.parse(localStorage.userCreds).designation}
                  </div>
                </div>
              </div>
            </div>

            <div className="Assignment_None">
              {AllAchive1 && (
                <>
                  <div className="Assign_Mobile_Color">
                    <div className="">
                      <div className="PCP_Assignment">
                        <div
                          className="nameAchivevi_colorbluesih"
                          onClick={() => {
                            setAllAchive1(true);
                            setAllAchive2(false);
                            setAllAchive3(false);
                            setAccdamicDec(true);
                            setAccdamicDec2(true);
                            setStyle({ background: "#F4F4F4" });
                            setCard({
                              one: true,
                              date: false,
                              subject: false,
                            });
                            setStyleTwo({
                              background: "",
                              color: "",
                            });
                            setStyleTwio({
                              background: "",
                              color: "",
                            });
                          }}
                        >
                          Past
                        </div>
                        <div
                          className="nameAchivevi"
                          onClick={() => {
                            setAllAchive1(false);
                            setAllAchive2(true);
                            setAllAchive3(false);
                            setAccdamicDec(true);
                            setAccdamicDec2(false);
                            setStyle({ background: "#0333511a" });
                            setCard({
                              one: true,
                              date: false,
                              subject: false,
                            });
                            setStyleTwo({
                              background: "",
                              color: "",
                            });
                            setStyleTwio({
                              background: "",
                              color: "",
                            });
                          }}
                        >
                          Current
                        </div>
                        <div
                          className="nameAchivevi"
                          onClick={() => {
                            setAllAchive1(false);
                            setAllAchive2(false);
                            setAllAchive3(true);
                            setAccdamicDec(false);
                            setAccdamicDec2(true);
                            setStyle({ background: "#ba00001a" });
                            setCard({
                              one: true,
                              date: false,
                              subject: false,
                            });
                            setStyleTwo({
                              background: "",
                              color: "",
                            });
                            setStyleTwio({
                              background: "",
                              color: "",
                            });
                          }}
                        >
                          Pending
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {AllAchive2 && (
                <>
                  <div className="Assign_Mobile_Color">
                    <div className="">
                      <div className="PCP_Assignment">
                        <div
                          className="nameAchivevi"
                          onClick={() => {
                            setAllAchive1(true);
                            setAllAchive2(false);
                            setAllAchive3(false);
                            setAccdamicDec(true);
                            setAccdamicDec2(true);
                            setStyle({ background: "#F4F4F4" });
                            setCard({
                              one: true,
                              date: false,
                              subject: false,
                            });
                            setStyleTwo({
                              background: "",
                              color: "",
                            });
                            setStyleTwio({
                              background: "",
                              color: "",
                            });
                          }}
                        >
                          Past
                        </div>
                        <div
                          className="nameAchivevi_colorbluesih"
                          onClick={() => {
                            setAllAchive1(false);
                            setAllAchive2(true);
                            setAllAchive3(false);
                            setAccdamicDec(true);
                            setAccdamicDec2(false);
                            setStyle({ background: "#0333511a" });
                            setCard({
                              one: true,
                              date: false,
                              subject: false,
                            });
                            setStyleTwo({
                              background: "",
                              color: "",
                            });
                            setStyleTwio({
                              background: "",
                              color: "",
                            });
                          }}
                        >
                          Current
                        </div>
                        <div
                          className="nameAchivevi"
                          onClick={() => {
                            setAllAchive1(false);
                            setAllAchive2(false);
                            setAllAchive3(true);
                            setAccdamicDec(false);
                            setAccdamicDec2(true);
                            setStyle({ background: "#ba00001a" });
                            setCard({
                              one: true,
                              date: false,
                              subject: false,
                            });
                            setStyleTwo({
                              background: "",
                              color: "",
                            });
                            setStyleTwio({
                              background: "",
                              color: "",
                            });
                          }}
                        >
                          Pending
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {AllAchive3 && (
                <>
                  <div className="Assign_Mobile_Color">
                    <div className="">
                      <div className="PCP_Assignment">
                        <div
                          className="nameAchivevi"
                          onClick={() => {
                            setAllAchive1(true);
                            setAllAchive2(false);
                            setAllAchive3(false);
                            setAccdamicDec(true);
                            setAccdamicDec2(true);
                            setStyle({ background: "#F4F4F4" });
                            setCard({
                              one: true,
                              date: false,
                              subject: false,
                            });
                            setStyleTwo({
                              background: "",
                              color: "",
                            });
                            setStyleTwio({
                              background: "",
                              color: "",
                            });
                          }}
                        >
                          Past
                        </div>
                        <div
                          className="nameAchivevi"
                          onClick={() => {
                            setAllAchive1(false);
                            setAllAchive2(true);
                            setAllAchive3(false);
                            setAccdamicDec(true);
                            setAccdamicDec2(false);
                            setStyle({ background: "#0333511a" });
                            setCard({
                              one: true,
                              date: false,
                              subject: false,
                            });
                            setStyleTwo({
                              background: "",
                              color: "",
                            });
                            setStyleTwio({
                              background: "",
                              color: "",
                            });
                          }}
                        >
                          Current
                        </div>
                        <div
                          className="nameAchivevi_colorbluesih"
                          onClick={() => {
                            setAllAchive1(false);
                            setAllAchive2(false);
                            setAllAchive3(true);
                            setAccdamicDec(false);
                            setAccdamicDec2(true);
                            setStyle({ background: "#ba00001a" });
                            setCard({
                              one: true,
                              date: false,
                              subject: false,
                            });
                            setStyleTwo({
                              background: "",
                              color: "",
                            });
                            setStyleTwio({
                              background: "",
                              color: "",
                            });
                          }}
                        >
                          Pending
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className="d-flex flex-row px-2 py-3 w-100 P_0">
              <div
                className="col-md-12 OuterDiv10 padding-left-0 padding-right-0 MTop_Assignment P_0"
                style={{ height: "640px", overflow: "hidden" }}
              >
                <div className="title-txt-label mob-display-none">
                  <div className="Today-Font DisplayNone">
                    <AiOutlineArrowLeft
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setViewMoreAssignment(false);
                        setOverAll(true);
                      }}
                    />{" "}
                    &nbsp; Assignment
                  </div>
                </div>

                <div className="mob-display-none">
                  {AllAchive1 && (
                    <>
                      <div className="d-flex">
                        <div className="PCP_Assignment mob-display-none">
                          <div
                            className="nameAchivevi_colorbluesih"
                            onClick={() => {
                              setAllAchive1(true);
                              setAllAchive2(false);
                              setAllAchive3(false);
                              setAccdamicDec(true);
                              setAccdamicDec2(true);
                              setStyle({ background: "#F4F4F4" });
                              setCard({
                                one: true,
                                date: false,
                                subject: false,
                              });
                              setStyleTwo({
                                background: "",
                                color: "",
                              });
                              setStyleTwio({
                                background: "",
                                color: "",
                              });
                            }}
                          >
                            Past Assignment
                          </div>
                          <div
                            className="nameAchivevi"
                            onClick={() => {
                              setAllAchive1(false);
                              setAllAchive2(true);
                              setAllAchive3(false);
                              setAccdamicDec(true);
                              setAccdamicDec2(false);
                              setStyle({ background: "#0333511a" });
                              setCard({
                                one: true,
                                date: false,
                                subject: false,
                              });
                              setStyleTwo({
                                background: "",
                                color: "",
                              });
                              setStyleTwio({
                                background: "",
                                color: "",
                              });
                            }}
                          >
                            Current Assignment
                          </div>
                          <div
                            className="nameAchivevi"
                            onClick={() => {
                              setAllAchive1(false);
                              setAllAchive2(false);
                              setAllAchive3(true);
                              setAccdamicDec(false);
                              setAccdamicDec2(true);
                              setStyle({ background: "#ba00001a" });
                              setCard({
                                one: true,
                                date: false,
                                subject: false,
                              });
                              setStyleTwo({
                                background: "",
                                color: "",
                              });
                              setStyleTwio({
                                background: "",
                                color: "",
                              });
                            }}
                          >
                            Pending Assignment
                          </div>
                        </div>

                        <div className="mob-display-none">
                          <div
                            className="pastassignClass border border-secondary px-3 py-2 rounded"
                            onClick={() => {
                              setCard({
                                one: false,
                                date: true,
                                subject: false,
                              });
                              setStyleTwo({
                                background: "#2196F3",
                                color: "#fff",
                              });
                              setStyleTwio({
                                background: "",
                                color: "",
                              });
                            }}
                            style={styleTwo}
                          >
                            Date wise{" "}
                            <span>
                              <IoCalendarOutline />
                            </span>
                          </div>

                          <div
                            className="pastassignClassw border border-secondary px-3 py-2 rounded"
                            onClick={() => {
                              setCard({
                                one: false,
                                date: false,
                                subject: true,
                              });
                              setStyleTwo({
                                background: "",
                                color: "",
                              });
                              setStyleTwio({
                                background: "#2196F3",
                                color: "#fff",
                              });
                            }}
                            style={styleTwio}
                          >
                            Subject wise{" "}
                            <span>
                              <BsBook />
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="d-flex justify-content-evenly">
                        <div className="PCP_Assignment Assignment_None">
                          <div
                            className="nameAchivevi_colorbluesih"
                            onClick={() => {
                              setAllAchive1(true);
                              setAllAchive2(false);
                              setAllAchive3(false);
                              setAccdamicDec(true);
                              setAccdamicDec2(true);
                              setStyle({ background: "#F4F4F4" });
                              setCard({
                                one: true,
                                date: false,
                                subject: false,
                              });
                              setStyleTwo({
                                background: "",
                                color: "",
                              });
                              setStyleTwio({
                                background: "",
                                color: "",
                              });
                            }}
                          >
                            Past
                          </div>
                          <div
                            className="nameAchivevi"
                            onClick={() => {
                              setAllAchive1(false);
                              setAllAchive2(true);
                              setAllAchive3(false);
                              setAccdamicDec(true);
                              setAccdamicDec2(false);
                              setStyle({ background: "#0333511a" });
                              setCard({
                                one: true,
                                date: false,
                                subject: false,
                              });
                              setStyleTwo({
                                background: "",
                                color: "",
                              });
                              setStyleTwio({
                                background: "",
                                color: "",
                              });
                            }}
                          >
                            Current
                          </div>
                          <div
                            className="nameAchivevi"
                            onClick={() => {
                              setAllAchive1(false);
                              setAllAchive2(false);
                              setAllAchive3(true);
                              setAccdamicDec(false);
                              setAccdamicDec2(true);
                              setStyle({ background: "#ba00001a" });
                              setCard({
                                one: true,
                                date: false,
                                subject: false,
                              });
                              setStyleTwo({
                                background: "",
                                color: "",
                              });
                              setStyleTwio({
                                background: "",
                                color: "",
                              });
                            }}
                          >
                            Pending
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  {AllAchive2 && (
                    <>
                      <div className="d-flex">
                        <div className="PCP_Assignment mob-display-none">
                          <div
                            className="nameAchivevi"
                            onClick={() => {
                              setAllAchive1(true);
                              setAllAchive2(false);
                              setAllAchive3(false);
                              setAccdamicDec(true);
                              setAccdamicDec2(true);
                              setStyle({ background: "#F4F4F4" });
                              setCard({
                                one: true,
                                date: false,
                                subject: false,
                              });
                              setStyleTwo({
                                background: "",
                                color: "",
                              });
                              setStyleTwio({
                                background: "",
                                color: "",
                              });
                            }}
                          >
                            Past Assignment
                          </div>
                          <div
                            className="nameAchivevi_colorbluesih"
                            onClick={() => {
                              setAllAchive1(false);
                              setAllAchive2(true);
                              setAllAchive3(false);
                              setAccdamicDec(true);
                              setAccdamicDec2(false);
                              setStyle({ background: "#0333511a" });
                              setCard({
                                one: true,
                                date: false,
                                subject: false,
                              });
                              setStyleTwo({
                                background: "",
                                color: "",
                              });
                              setStyleTwio({
                                background: "",
                                color: "",
                              });
                            }}
                          >
                            Current Assignment
                          </div>
                          <div
                            className="nameAchivevi"
                            onClick={() => {
                              setAllAchive1(false);
                              setAllAchive2(false);
                              setAllAchive3(true);
                              setAccdamicDec(false);
                              setAccdamicDec2(true);
                              setStyle({ background: "#ba00001a" });
                              setCard({
                                one: true,
                                date: false,
                                subject: false,
                              });
                              setStyleTwo({
                                background: "",
                                color: "",
                              });
                              setStyleTwio({
                                background: "",
                                color: "",
                              });
                            }}
                          >
                            Pending Assignment
                          </div>
                        </div>
                      </div>

                      <div className="d-flex justify-content-evenly">
                        <div className="PCP_Assignment Assignment_None">
                          <div
                            className="nameAchivevi"
                            onClick={() => {
                              setAllAchive1(true);
                              setAllAchive2(false);
                              setAllAchive3(false);
                              setAccdamicDec(true);
                              setAccdamicDec2(true);
                              setStyle({ background: "#F4F4F4" });
                              setCard({
                                one: true,
                                date: false,
                                subject: false,
                              });
                              setStyleTwo({
                                background: "",
                                color: "",
                              });
                              setStyleTwio({
                                background: "",
                                color: "",
                              });
                            }}
                          >
                            Past
                          </div>
                          <div
                            className="nameAchivevi_colorbluesih"
                            onClick={() => {
                              setAllAchive1(false);
                              setAllAchive2(true);
                              setAllAchive3(false);
                              setAccdamicDec(true);
                              setAccdamicDec2(false);
                              setStyle({ background: "#0333511a" });
                              setCard({
                                one: true,
                                date: false,
                                subject: false,
                              });
                              setStyleTwo({
                                background: "",
                                color: "",
                              });
                              setStyleTwio({
                                background: "",
                                color: "",
                              });
                            }}
                          >
                            Current
                          </div>
                          <div
                            className="nameAchivevi"
                            onClick={() => {
                              setAllAchive1(false);
                              setAllAchive2(false);
                              setAllAchive3(true);
                              setAccdamicDec(false);
                              setAccdamicDec2(true);
                              setStyle({ background: "#ba00001a" });
                              setCard({
                                one: true,
                                date: false,
                                subject: false,
                              });
                              setStyleTwo({
                                background: "",
                                color: "",
                              });
                              setStyleTwio({
                                background: "",
                                color: "",
                              });
                            }}
                          >
                            Pending
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  {AllAchive3 && (
                    <>
                      <div className="d-flex">
                        <div className="PCP_Assignment mob-display-none">
                          <div
                            className="nameAchivevi"
                            onClick={() => {
                              setAllAchive1(true);
                              setAllAchive2(false);
                              setAllAchive3(false);
                              setAccdamicDec(true);
                              setAccdamicDec2(true);
                              setStyle({ background: "#F4F4F4" });
                              setCard({
                                one: true,
                                date: false,
                                subject: false,
                              });
                              setStyleTwo({
                                background: "",
                                color: "",
                              });
                              setStyleTwio({
                                background: "",
                                color: "",
                              });
                            }}
                          >
                            Past Assignment
                          </div>
                          <div
                            className="nameAchivevi"
                            onClick={() => {
                              setAllAchive1(false);
                              setAllAchive2(true);
                              setAllAchive3(false);
                              setAccdamicDec(true);
                              setAccdamicDec2(false);
                              setStyle({ background: "#0333511a" });
                              setCard({
                                one: true,
                                date: false,
                                subject: false,
                              });
                              setStyleTwo({
                                background: "",
                                color: "",
                              });
                              setStyleTwio({
                                background: "",
                                color: "",
                              });
                            }}
                          >
                            Current Assignment
                          </div>
                          <div
                            className="nameAchivevi_colorbluesih"
                            onClick={() => {
                              setAllAchive1(false);
                              setAllAchive2(false);
                              setAllAchive3(true);
                              setAccdamicDec(false);
                              setAccdamicDec2(true);
                              setStyle({ background: "#ba00001a" });
                              setCard({
                                one: true,
                                date: false,
                                subject: false,
                              });
                              setStyleTwo({
                                background: "",
                                color: "",
                              });
                              setStyleTwio({
                                background: "",
                                color: "",
                              });
                            }}
                          >
                            Pending Assignment
                          </div>
                        </div>
                      </div>

                      <div className="d-flex justify-content-evenly">
                        <div className="PCP_Assignment Assignment_None">
                          <div
                            className="nameAchivevi"
                            onClick={() => {
                              setAllAchive1(true);
                              setAllAchive2(false);
                              setAllAchive3(false);
                              setAccdamicDec(true);
                              setAccdamicDec2(true);
                              setStyle({ background: "#F4F4F4" });
                              setCard({
                                one: true,
                                date: false,
                                subject: false,
                              });
                              setStyleTwo({
                                background: "",
                                color: "",
                              });
                              setStyleTwio({
                                background: "",
                                color: "",
                              });
                            }}
                          >
                            Past
                          </div>
                          <div
                            className="nameAchivevi"
                            onClick={() => {
                              setAllAchive1(false);
                              setAllAchive2(true);
                              setAllAchive3(false);
                              setAccdamicDec(true);
                              setAccdamicDec2(false);
                              setStyle({ background: "#0333511a" });
                              setCard({
                                one: true,
                                date: false,
                                subject: false,
                              });
                              setStyleTwo({
                                background: "",
                                color: "",
                              });
                              setStyleTwio({
                                background: "",
                                color: "",
                              });
                            }}
                          >
                            Current
                          </div>
                          <div
                            className="nameAchivevi_colorbluesih"
                            onClick={() => {
                              setAllAchive1(false);
                              setAllAchive2(false);
                              setAllAchive3(true);
                              setAccdamicDec(false);
                              setAccdamicDec2(true);
                              setStyle({ background: "#ba00001a" });
                              setCard({
                                one: true,
                                date: false,
                                subject: false,
                              });
                              setStyleTwo({
                                background: "",
                                color: "",
                              });
                              setStyleTwio({
                                background: "",
                                color: "",
                              });
                            }}
                          >
                            Pending
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>

                {card.one && (
                  <div className="OuterSemiDiv1">
                    {AssignmentViewMore.map((x, index) => {
                      return (
                        <div className="col-sm-4 paddingTopBot 3">
                          <div
                            className="AssignmentDiv padding-left-0"
                            style={style}
                          >
                            <div className="d-flex flex-column gap-2 mb-3">
                              <div>
                                <span className="SubjectFontSub">
                                  {/* Chemistry */}
                                  {x.Subject}
                                </span>
                              </div>
                              <div className="topicassignsubMenu">
                                {x.Topic}
                              </div>
                            </div>
                            <div className="d-flex flex-row gap-5">
                              <div className="d-flex flex-column">
                                <div className="postViewmore">{x.post}</div>
                                <div className="postdateviewmore">
                                  {x.postDate}
                                </div>
                              </div>
                              <div className="d-flex flex-column">
                                <div className="postViewmore">{x.postq}</div>
                                <div className="postdateviewmore">
                                  {x.postqDate}
                                </div>
                              </div>
                            </div>
                            <div className="d-flex flex-row align-items-center justify-content-between mt-4">
                              <div className=" d-flex align-items-center gap-1">
                                <div>
                                  <img src={Sample} alt="" width={35} />
                                </div>
                                <div>
                                  <span className="ImgNameFont">
                                    {/* Sheeba V */}
                                    {x.Name}
                                  </span>
                                </div>
                              </div>
                              <div className="creamNumviewmore">
                                <div className="creamNumviewmoreFont">
                                  70/100
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}

                {card.date && (
                  <div className="OuterSemiDiv1ioio">
                    <div className="SubjectFontSub ml-5 mt-4">19 Sep 2023</div>
                    <div className="tableDateAssign">
                      <CSmartTable
                        clickableRows
                        columns={columns}
                        items={usersData}
                        tableProps={{
                          hover: true,
                        }}
                      />
                    </div>
                    <div className="SubjectFontSub ml-5">14 Sep 2023</div>
                    <div className="tableDateAssign">
                      <CSmartTable
                        clickableRows
                        columns={columns}
                        items={usersData}
                        tableProps={{
                          hover: true,
                        }}
                      />
                    </div>
                  </div>
                )}
                {card.subject && (
                  <div
                    className="OuterSemiDiv1ioio mt-2 py-3"
                    style={{
                      height: "550px",
                      overflow: "auto",
                      scrollBehavior: "smooth",
                    }}
                  >
                    <div className="d-flex flex-row">
                      <span className="SubjectFontSub ml-5">Physics</span>
                      <span className="SubjectFontSub ml-5 text-primary">
                        14 Sep 2023
                      </span>
                    </div>
                    <div className="tableDateAssign">
                      <CSmartTable
                        clickableRows
                        columns={columnsO}
                        items={usersDataO}
                        tableProps={{
                          hover: true,
                        }}
                      />
                    </div>
                    <div className="d-flex flex-row">
                      <span className="SubjectFontSub ml-5 mt-4">Physics</span>
                      <span className="SubjectFontSub ml-5 mt-4 text-primary">
                        13 Sep 2023
                      </span>
                    </div>
                    <div className="tableDateAssign">
                      <CSmartTable
                        clickableRows
                        columns={columnsO}
                        items={usersDataO}
                        tableProps={{
                          hover: true,
                        }}
                      />
                    </div>
                    <div className="d-flex flex-row">
                      <span className="SubjectFontSub ml-5 mt-4">English</span>
                      <span className="SubjectFontSub ml-5 mt-4 text-primary">
                        06 Sep 2023
                      </span>
                    </div>
                    <div className="tableDateAssign">
                      <CSmartTable
                        clickableRows
                        columns={columnsO}
                        items={usersDataO}
                        tableProps={{
                          hover: true,
                        }}
                      />
                    </div>
                    <div className="d-flex flex-row">
                      <span className="SubjectFontSub ml-5 mt-4">
                        Mathematics
                      </span>
                      <span className="SubjectFontSub ml-5 mt-4 text-primary">
                        06 Sep 2023
                      </span>
                    </div>
                    <div className="tableDateAssign">
                      <CSmartTable
                        clickableRows
                        columns={columnsO}
                        items={usersDataO}
                        tableProps={{
                          hover: true,
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
        {/* Assignment for View more */}

        {/* Exam Result for View more */}

        {viewMoreExamResult && (
          <>
            <div className="d-flex flex-row align-items-center justify-content-between Header-Style web-display-none">
              <div className="d-flex flex-row align-items-center gap-3">
                <div className="Today-Font">
                  <AiOutlineArrowLeft
                    size={20}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setViewMoreExamResult(!viewMoreExamResult);
                      setOverAll(true);
                      window.scrollTo(0, 0);
                    }}
                  />
                </div>
                <div className="Today-Font">&nbsp; Exam Results</div>
              </div>

              <div
                onClick={ShowProfile}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  // marginRight: '15px',
                }}
              >
                <div onClick={ShowProfile} className="cursor-hover">
                  <img
                    src={StLogo}
                    style={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "50%",
                      marginRight: "10px",
                      marginLeft: "10px",
                    }}
                    alt=""
                  />
                </div>
                <div
                  style={{
                    cursor: "pointer",
                  }}
                  className="dropdown-profile"
                >
                  <div className={"Name_Nikil"}>
                    {localStorage.length > 0 &&
                      JSON.parse(localStorage.userCreds).adminName}
                  </div>
                  <div className={"Class_section"}>
                    {localStorage.length > 0 &&
                      JSON.parse(localStorage.userCreds).designation}
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="d-flex align-items-center justify-content-between"
                style={{backgroundColor:"#E6E6E6",padding:"10px 15px",border:"1px solid #CACACA",}}>
                  <div>
                    Monthly Exam (September - 2022) 
                  </div>
                  <div>
                    <AiOutlineRight size={20} style={{cursor:"pointer"}} />
                  </div>
            </div>      */}

            <div className="Assignment_None">
              <select className="DropDown_Css">
                <option value="Aug_23">Monthly Exam (August - 2023)</option>
                <option value="Sep_23">Monthly Exam (September - 2023)</option>
                <option value="Oct_23">Monthly Exam (October - 2023)</option>
                <option value="Nov_23">Monthly Exam (November- 2023)</option>
              </select>
            </div>

            <div className="d-flex flex-row px-2 py-3 w-100">
              <div className="col-md-12 OuterDiv10 padding-left-0 padding-right-0 MTop_Exam_Results">
                <div className="mob-display-none">
                  <div className="d-flex mob-display-none">
                    <div className="title-txt-label mob-display-none">
                      <div className="Today-Font DisplayNone">
                        <AiOutlineArrowLeft
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setViewMoreExamResult(false);
                            setOverAll(true);
                          }}
                        />{" "}
                        &nbsp; Exam Results
                      </div>
                    </div>{" "}
                    &nbsp; &nbsp;
                    <div className="mt-2">
                      <Select
                        options={DropDownValuesexam}
                        onChange={valueChangeExam}
                        className="selectresultexam"
                      />
                    </div>
                  </div>
                </div>

                {/* For Mobile */}
                <div className="Assignment_None">
                  <div className="">
                    {examResultDrop.one && (
                      <div className="d-flex flex-row px-3 py-3">
                        <div className="col-md-12 OuterDiv10iao">
                          <div className="d-flex flex-row align-items-center justify-content-between px-2 pt-3">
                            <div>
                              <span className="Month-Exam-Font">
                                {" "}
                                <strong>Ist Mid-Term (may - 2023) </strong>
                              </span>
                            </div>
                          </div>
                          <div
                            className="d-flex align-items-center mt-3 px-2 justify-content-between"
                            // style={{ padding: "0px 30px" }}
                          >
                            <div>
                              <span className="TotalCount-Font">Total </span>
                              <br />
                              <span className="CountNumber-Font">
                                426
                                <span className="CountNumber-Font1">/700</span>
                              </span>
                            </div>
                            <div>
                              <span className="TotalCount-Font">Rank </span>
                              <br />

                              <span className="CountNumber-Font1">29</span>
                            </div>
                          </div>
                          <div className="mb-3">
                            {ExamSubject.map((x) => {
                              return (
                                <div className="d-flex flex-row justify-content-evenly align-items-center pt-4">
                                  <div className="lanex_re">{x.subject}</div>

                                  <div className="ProgressBarColor">
                                    <div
                                      style={{
                                        width: `${x.width}`,
                                        backgroundImage: `${x.color}`,
                                      }}
                                      className="ProgressBarColorIn"
                                    ></div>
                                  </div>
                                  <div>
                                    <span className="ProNumber">
                                      {x.count}
                                      <span className="PercentNumber">
                                        /100
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {/* For Mobile */}

                <div className="mob-display-none">
                  <div className="d-flex flex-row justify-content-between">
                    {examResultDrop.one && (
                      <div className="d-flex flex-row px-3 py-3 widthResultExam">
                        <div className="col-md-12 OuterDiv10iao">
                          <div className="d-flex flex-row align-items-center justify-content-between px-2 pt-3">
                            <div>
                              <span className="Month-Exam-Font">
                                {" "}
                                <strong>Ist Mid-Term (may - 2023) </strong>
                              </span>
                            </div>
                          </div>
                          <div
                            className="d-flex align-items-center mt-3 px-2 justify-content-between"
                            // style={{ padding: "0px 30px" }}
                          >
                            <div>
                              <span className="TotalCount-Font">Total </span>
                              <br />
                              <span className="CountNumber-Font">
                                426
                                <span className="CountNumber-Font1">/700</span>
                              </span>
                            </div>
                            <div>
                              <span className="TotalCount-Font">Rank </span>
                              <br />

                              <span className="CountNumber-Font1">29</span>
                            </div>
                          </div>
                          <div className="mb-3">
                            {ExamSubject.map((x) => {
                              return (
                                <div className="d-flex flex-row justify-content-evenly align-items-center pt-4">
                                  <div className="lanex_re">{x.subject}</div>

                                  <div className="ProgressBarColor">
                                    <div
                                      style={{
                                        width: `${x.width}`,
                                        backgroundImage: `${x.color}`,
                                      }}
                                      className="ProgressBarColorIn"
                                    ></div>
                                  </div>
                                  <div>
                                    <span className="ProNumber">
                                      {x.count}
                                      <span className="PercentNumber">
                                        /100
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    )}
                    {examResultDrop.two && (
                      <div className="d-flex flex-row px-3 py-3 widthResultExam">
                        <div className="col-md-12 OuterDiv10iao">
                          <div className="d-flex flex-row align-items-center justify-content-between px-2 pt-3">
                            <div>
                              <span className="Month-Exam-Font">
                                {" "}
                                <strong>IInd Mid-Term (June - 2023) </strong>
                              </span>
                            </div>
                          </div>
                          <div
                            className="d-flex align-items-center mt-3 px-2 justify-content-between"
                            // style={{ padding: "0px 30px" }}
                          >
                            <div>
                              <span className="TotalCount-Font">Total </span>
                              <br />
                              <span className="CountNumber-Font">
                                426
                                <span className="CountNumber-Font1">/700</span>
                              </span>
                            </div>
                            <div>
                              <span className="TotalCount-Font">Rank </span>
                              <br />

                              <span className="CountNumber-Font1">29</span>
                            </div>
                          </div>
                          <div className="mb-3">
                            {ExamSubject.map((x) => {
                              return (
                                <div className="d-flex flex-row justify-content-evenly align-items-center pt-4">
                                  <div className="lanex_re">{x.subject}</div>

                                  <div className="ProgressBarColor">
                                    <div
                                      style={{
                                        width: `${x.width}`,
                                        backgroundImage: `${x.color}`,
                                      }}
                                      className="ProgressBarColorIn"
                                    ></div>
                                  </div>
                                  <div>
                                    <span className="ProNumber">
                                      {x.count}
                                      <span className="PercentNumber">
                                        /100
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    )}
                    {examResultDrop.three && (
                      <div className="d-flex flex-row px-3 py-3 widthResultExam">
                        <div className="col-md-12 OuterDiv10iao">
                          <div className="d-flex flex-row align-items-center justify-content-between px-2 pt-3">
                            <div>
                              <span className="Month-Exam-Font">
                                {" "}
                                <strong>IIIrd Mid-Term (July - 2023) </strong>
                              </span>
                            </div>
                          </div>
                          <div
                            className="d-flex align-items-center mt-3 px-2 justify-content-between"
                            // style={{ padding: "0px 30px" }}
                          >
                            <div>
                              <span className="TotalCount-Font">Total </span>
                              <br />
                              <span className="CountNumber-Font">
                                426
                                <span className="CountNumber-Font1">/700</span>
                              </span>
                            </div>
                            <div>
                              <span className="TotalCount-Font">Rank </span>
                              <br />

                              <span className="CountNumber-Font1">29</span>
                            </div>
                          </div>
                          <div className="mb-3">
                            {ExamSubject.map((x) => {
                              return (
                                <div className="d-flex flex-row justify-content-evenly align-items-center pt-4">
                                  <div className="lanex_re">{x.subject}</div>

                                  <div className="ProgressBarColor">
                                    <div
                                      style={{
                                        width: `${x.width}`,
                                        backgroundImage: `${x.color}`,
                                      }}
                                      className="ProgressBarColorIn"
                                    ></div>
                                  </div>
                                  <div>
                                    <span className="ProNumber">
                                      {x.count}
                                      <span className="PercentNumber">
                                        /100
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="d-flex flex-row justify-content-between">
                    {examResultDrop.four && (
                      <div className="d-flex flex-row px-3 py-3 widthResultExam">
                        <div className="col-md-12 OuterDiv10iao">
                          <div className="d-flex flex-row align-items-center justify-content-between px-2 pt-3">
                            <div>
                              <span className="Month-Exam-Font">
                                {" "}
                                <strong>Quaterly Exam (August - 2023) </strong>
                              </span>
                            </div>
                          </div>
                          <div
                            className="d-flex align-items-center mt-3 px-2 justify-content-between"
                            // style={{ padding: "0px 30px" }}
                          >
                            <div>
                              <span className="TotalCount-Font">Total </span>
                              <br />
                              <span className="CountNumber-Font">
                                426
                                <span className="CountNumber-Font1">/700</span>
                              </span>
                            </div>
                            <div>
                              <span className="TotalCount-Font">Rank </span>
                              <br />

                              <span className="CountNumber-Font1">29</span>
                            </div>
                          </div>
                          <div className="mb-3">
                            {ExamSubject.map((x) => {
                              return (
                                <div className="d-flex flex-row justify-content-evenly align-items-center pt-4">
                                  <div className="lanex_re">{x.subject}</div>

                                  <div className="ProgressBarColor">
                                    <div
                                      style={{
                                        width: `${x.width}`,
                                        backgroundImage: `${x.color}`,
                                      }}
                                      className="ProgressBarColorIn"
                                    ></div>
                                  </div>
                                  <div>
                                    <span className="ProNumber">
                                      {x.count}
                                      <span className="PercentNumber">
                                        /100
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    )}
                    {examResultDrop.five && (
                      <div className="d-flex flex-row px-3 py-3 widthResultExam">
                        <div className="col-md-12 OuterDiv10iao">
                          <div className="d-flex flex-row align-items-center justify-content-between px-2 pt-3">
                            <div>
                              <span className="Month-Exam-Font">
                                {" "}
                                <strong>
                                  Half Yearly Exam (September - 2023){" "}
                                </strong>
                              </span>
                            </div>
                          </div>
                          <div
                            className="d-flex align-items-center mt-3 px-2 justify-content-between"
                            // style={{ padding: "0px 30px" }}
                          >
                            <div>
                              <span className="TotalCount-Font">Total </span>
                              <br />
                              <span className="CountNumber-Font">
                                426
                                <span className="CountNumber-Font1">/700</span>
                              </span>
                            </div>
                            <div>
                              <span className="TotalCount-Font">Rank </span>
                              <br />

                              <span className="CountNumber-Font1">29</span>
                            </div>
                          </div>
                          <div className="mb-3">
                            {ExamSubject.map((x) => {
                              return (
                                <div className="d-flex flex-row justify-content-evenly align-items-center pt-4">
                                  <div className="lanex_re">{x.subject}</div>

                                  <div className="ProgressBarColor">
                                    <div
                                      style={{
                                        width: `${x.width}`,
                                        backgroundImage: `${x.color}`,
                                      }}
                                      className="ProgressBarColorIn"
                                    ></div>
                                  </div>
                                  <div>
                                    <span className="ProNumber">
                                      {x.count}
                                      <span className="PercentNumber">
                                        /100
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="widthResultExam"></div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {/* Exam Results for View more */}

        {/* My Achievements for View more */}
        {viewMoreAchievements && (
          <>
            <div className="web-display-none">
              <div className="d-flex flex-row align-items-center justify-content-between Header-Style">
                <div className="d-flex flex-row align-items-center gap-3">
                  <div className="Today-Font">
                    <AiOutlineArrowLeft
                      size={20}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setViewMoreAchievements(!viewMoreAchievements);
                        setOverAll(true);
                        window.scrollTo(0, 0);
                      }}
                    />
                  </div>
                  <div className="Today-Font">&nbsp; Achievements</div>
                </div>

                <div
                  onClick={ShowProfile}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    // marginRight: '15px',
                  }}
                >
                  <div onClick={ShowProfile} className="cursor-hover">
                    <img
                      src={StLogo}
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                        marginRight: "10px",
                        marginLeft: "10px",
                      }}
                      alt=""
                    />
                  </div>
                  <div
                    style={{
                      cursor: "pointer",
                    }}
                    className="dropdown-profile"
                  >
                    <div className={"Name_Nikil"}>
                      {localStorage.length > 0 &&
                        JSON.parse(localStorage.userCreds).adminName}
                    </div>
                    <div className={"Class_section"}>
                      {localStorage.length > 0 &&
                        JSON.parse(localStorage.userCreds).designation}
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="d-flex align-items-center justify-content-around"
              style={{backgroundColor:"#E6E6E6",padding:"10px 15px",border:"1px solid #CACACA",}}>
              <div className="Assignment_PCP">
                Past
              </div>
              <div className="Assignment_PCP">
                Current
              </div>
              <div className="Assignment_PCP">
                Pending
              </div>
            </div> */}

              <div className="Assignment_None">
                {AllAchive1 && (
                  <div className="Assign_Mobile_Color">
                    <div className="">
                      <div className="PCP_Assignment">
                        <div
                          className="nameAchivevi_colorbluesih"
                          onClick={() => {
                            setAllAchive1(true);
                            setAllAchive2(false);
                            setAllAchive3(false);
                            setAccdamicDec(true);
                            setAccdamicDec2(true);
                          }}
                        >
                          All
                        </div>
                        <div
                          className="nameAchivevi"
                          onClick={() => {
                            setAllAchive1(false);
                            setAllAchive2(true);
                            setAllAchive3(false);
                            setAccdamicDec(true);
                            setAccdamicDec2(false);
                          }}
                        >
                          Academic
                        </div>
                        <div
                          className="nameAchivevi"
                          onClick={() => {
                            setAllAchive1(false);
                            setAllAchive2(false);
                            setAllAchive3(true);
                            setAccdamicDec(false);
                            setAccdamicDec2(true);
                          }}
                        >
                          Sports
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {AllAchive2 && (
                  <div className="Assign_Mobile_Color">
                    <div className="">
                      <div className="PCP_Assignment">
                        <div
                          className="nameAchivevi"
                          onClick={() => {
                            setAllAchive1(true);
                            setAllAchive2(false);
                            setAllAchive3(false);
                            setAccdamicDec(true);
                            setAccdamicDec2(true);
                          }}
                        >
                          All
                        </div>
                        <div
                          className="nameAchivevi_colorbluesih"
                          onClick={() => {
                            setAllAchive1(false);
                            setAllAchive2(true);
                            setAllAchive3(false);
                            setAccdamicDec(true);
                            setAccdamicDec2(false);
                          }}
                        >
                          Academic
                        </div>
                        <div
                          className="nameAchivevi"
                          onClick={() => {
                            setAllAchive1(false);
                            setAllAchive2(false);
                            setAllAchive3(true);
                            setAccdamicDec(false);
                            setAccdamicDec2(true);
                          }}
                        >
                          Sports
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {AllAchive3 && (
                  <div className="Assign_Mobile_Color">
                    <div className="">
                      <div className="PCP_Assignment">
                        <div
                          className="nameAchivevi"
                          onClick={() => {
                            setAllAchive1(true);
                            setAllAchive2(false);
                            setAllAchive3(false);
                            setAccdamicDec(true);
                            setAccdamicDec2(true);
                          }}
                        >
                          All
                        </div>
                        <div
                          className="nameAchivevi"
                          onClick={() => {
                            setAllAchive1(false);
                            setAllAchive2(true);
                            setAllAchive3(false);
                            setAccdamicDec(true);
                            setAccdamicDec2(false);
                          }}
                        >
                          Academic
                        </div>
                        <div
                          className="nameAchivevi_colorbluesih"
                          onClick={() => {
                            setAllAchive1(false);
                            setAllAchive2(false);
                            setAllAchive3(true);
                            setAccdamicDec(false);
                            setAccdamicDec2(true);
                          }}
                        >
                          Sports
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="d-flex flex-row px-2 py-3 w-100">
              <div
                className="col-md-12 OuterDiv10 padding-left-0 padding-right-0 MTop_Achievements"
                style={{ height: "640px" }}
              >
                <div className="d-flex align-items-center justify-content-start gap-5 Acheive_None">
                  <div className="title-txt-label mob-display-none">
                    <div className="Today-Font DisplayNone">
                      <AiOutlineArrowLeft
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setViewMoreAchievements(!viewMoreAchievements);
                          setOverAll(true);
                        }}
                      />{" "}
                      &nbsp; Achievements
                    </div>
                  </div>
                  <div className="mob-display-none">
                    <div className="d-flex align-items-center gap-3">
                      <div>
                        <AiOutlineLeft
                          size={17}
                          style={{ cursor: "pointer", color: "#858585" }}
                        />
                      </div>
                      <div className="DateDesign">
                        <div className="DateDesignFont">March</div>
                      </div>
                      <div>
                        <AiOutlineRight
                          size={17}
                          style={{ cursor: "pointer", color: "#858585" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mob-display-none">
                  {AllAchive1 && (
                    <div className="d-flex">
                      <div className="d-flex px-5 pt-3 gap-5">
                        <div
                          className="nameAchivevi_colorbluesih"
                          onClick={() => {
                            setAllAchive1(true);
                            setAllAchive2(false);
                            setAllAchive3(false);
                            setAccdamicDec(true);
                            setAccdamicDec2(true);
                          }}
                        >
                          All
                        </div>
                        <div
                          className="nameAchivevi"
                          onClick={() => {
                            setAllAchive1(false);
                            setAllAchive2(true);
                            setAllAchive3(false);
                            setAccdamicDec(true);
                            setAccdamicDec2(false);
                          }}
                        >
                          Academic
                        </div>
                        <div
                          className="nameAchivevi"
                          onClick={() => {
                            setAllAchive1(false);
                            setAllAchive2(false);
                            setAllAchive3(true);
                            setAccdamicDec(false);
                            setAccdamicDec2(true);
                          }}
                        >
                          Sports
                        </div>
                      </div>
                    </div>
                  )}

                  {AllAchive2 && (
                    <div className="d-flex">
                      <div className="d-flex px-5 pt-3 gap-5">
                        <div
                          className="nameAchivevi"
                          onClick={() => {
                            setAllAchive1(true);
                            setAllAchive2(false);
                            setAllAchive3(false);
                            setAccdamicDec(true);
                            setAccdamicDec2(true);
                          }}
                        >
                          All
                        </div>
                        <div
                          className="nameAchivevi_colorbluesih"
                          onClick={() => {
                            setAllAchive1(false);
                            setAllAchive2(true);
                            setAllAchive3(false);
                            setAccdamicDec(true);
                            setAccdamicDec2(false);
                          }}
                        >
                          Academic
                        </div>
                        <div
                          className="nameAchivevi"
                          onClick={() => {
                            setAllAchive1(false);
                            setAllAchive2(false);
                            setAllAchive3(true);
                            setAccdamicDec(false);
                            setAccdamicDec2(true);
                          }}
                        >
                          Sports
                        </div>
                      </div>
                    </div>
                  )}

                  {AllAchive3 && (
                    <div className="d-flex">
                      <div className="d-flex px-5 pt-3 gap-5">
                        <div
                          className="nameAchivevi"
                          onClick={() => {
                            setAllAchive1(true);
                            setAllAchive2(false);
                            setAllAchive3(false);
                            setAccdamicDec(true);
                            setAccdamicDec2(true);
                          }}
                        >
                          All
                        </div>
                        <div
                          className="nameAchivevi"
                          onClick={() => {
                            setAllAchive1(false);
                            setAllAchive2(true);
                            setAllAchive3(false);
                            setAccdamicDec(true);
                            setAccdamicDec2(false);
                          }}
                        >
                          Academic
                        </div>
                        <div
                          className="nameAchivevi_colorbluesih"
                          onClick={() => {
                            setAllAchive1(false);
                            setAllAchive2(false);
                            setAllAchive3(true);
                            setAccdamicDec(false);
                            setAccdamicDec2(true);
                          }}
                        >
                          Sports
                        </div>
                      </div>
                    </div>
                  )}

                  <hr className="eioMT" />
                </div>

                {hide.one && (
                  <div className="zoIndex">
                    <div className="shadow-lg p-3 mb-5 bg-white rounded hiegtAchivementViewmore">
                      <div className="widthyuio">
                        <div className="Name_Font_St d-flex justify-content-center flex-column align-items-center marginMinusTop mt-3">
                          Profile
                        </div>
                        <div className="d-flex justify-content-end px-3 marginMinusTop cursor-hover">
                          <AiOutlineCloseCircle
                            onClick={() => {
                              setHide({
                                one: false,
                              });
                            }}
                          />
                        </div>
                        <div className="d-flex justify-content-center align-items-center mt-3">
                          <img src={SImg2} alt="" className="ImgWidthZo" />
                        </div>
                        <div className="d-flex flex-column align-items-center mt-3">
                          <div className="Name_Font_St">Karthicka</div>
                          <div className="Class_Font_Sec">XII B</div>
                        </div>
                        <div className="Name_Font_St d-flex justify-content-center flex-column align-items-center marginMinusTop mt-3 mb-4">
                          Achievements
                        </div>
                        <div className="classoverflow">
                          <div className="classgap_top">
                            <div>
                              <img
                                src={SMedal}
                                alt=""
                                className="ImgMedalWidth"
                              />
                            </div>
                            <div>
                              <p>
                                <strong>
                                  2021 Salesperson of the Year Award (Johnson &
                                  Associates)
                                </strong>
                              </p>
                              <p>
                                In recognition for achieving 193% of annual
                                target; nearest competitor achieved 102:
                              </p>
                            </div>
                          </div>
                          <div className="classgap">
                            <div>
                              <img
                                src={SMedal}
                                alt=""
                                className="ImgMedalWidth"
                              />
                            </div>
                            <div>
                              <p>
                                <strong>
                                  2020 Top Performer Award Quarter 4 (Johnson &
                                  Associates)
                                </strong>
                              </p>
                              <p>
                                In recognition for achieving 127% of quarterly
                                target (nearest competitor achieved 97%)
                              </p>
                            </div>
                          </div>
                          <div className="classgap">
                            <div>
                              <img
                                src={SMedal}
                                alt=""
                                className="ImgMedalWidth"
                              />
                            </div>
                            <div>
                              <p>
                                <strong>
                                  2019 Salesperson of the Year Award (Barnaby
                                  Scone Consultants)
                                </strong>
                              </p>
                              <p>
                                In recognition for consistently exceeding every
                                quarterly target
                              </p>
                            </div>
                          </div>
                          <div className="classgap">
                            <div>
                              <img
                                src={SMedal}
                                alt=""
                                className="ImgMedalWidth"
                              />
                            </div>
                            <div>
                              <p>
                                <strong>
                                  2021 Salesperson of the Year Award ‘@ (Johnson
                                  & Associates)
                                </strong>
                              </p>
                              <p>
                                In recognition for consistently exceeding every
                                quarterly target
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="Achieve_Scroll">
                  <div className="Achieve_Padding">
                    {AccdamicDec && (
                      <div style={{ paddingBottom: "15px" }}>
                        <div className="nameDecachivVi">December</div>
                        <div className="ScrollNew">
                          <div className="A_Flex_Card1 Birth_Card">
                            <Fade bottom>
                              <div
                                className="GradeCard1"
                                onClick={() => {
                                  setHide({
                                    one: true,
                                  });
                                }}
                              >
                                <div>
                                  <img
                                    src={GMedal}
                                    alt=""
                                    className="ImgMedalWidth"
                                  />
                                </div>
                                <div className="d-flex justify-content-center flex-column align-items-center marginMinusTop">
                                  <div>
                                    <img
                                      src={SImg1}
                                      alt=""
                                      className="ImgWidth"
                                    />
                                  </div>
                                  <div className="d-flex flex-column align-items-center mt-3">
                                    <div className="Name_Font_St">
                                      Kalyani Kumari
                                    </div>
                                    <div className="Class_Font_Sec">XII B</div>
                                  </div>
                                </div>
                                <div className="d-flex flex-column align-items-center mt-3">
                                  <div className="SportsCompFont">
                                    InterSchool Chess
                                  </div>
                                  <div className="SportsCompFont">
                                    Tournament
                                  </div>
                                </div>
                              </div>
                            </Fade>
                            <Fade bottom>
                              <div
                                className="GradeCard2"
                                onClick={() => {
                                  setHide({
                                    one: true,
                                  });
                                }}
                              >
                                <div>
                                  <img
                                    src={SMedal}
                                    alt=""
                                    className="ImgMedalWidth"
                                  />
                                </div>
                                <div className="d-flex justify-content-center flex-column align-items-center marginMinusTop">
                                  <div>
                                    <img
                                      src={SImg2}
                                      alt=""
                                      className="ImgWidth"
                                    />
                                  </div>
                                  <div className="d-flex flex-column align-items-center mt-3">
                                    <div className="Name_Font_St">
                                      Karthicka
                                    </div>
                                    <div className="Class_Font_Sec">XII B</div>
                                  </div>
                                </div>
                                <div className="d-flex flex-column align-items-center mt-3">
                                  <div className="SportsCompFont">
                                    State-Level Football
                                  </div>
                                  <div className="SportsCompFont">
                                    Tournament
                                  </div>
                                </div>
                              </div>
                            </Fade>
                            <Fade bottom>
                              <div
                                className="GradeCard3"
                                onClick={() => {
                                  setHide({
                                    one: true,
                                  });
                                }}
                              >
                                <div>
                                  <img
                                    src={BMedal}
                                    alt=""
                                    className="ImgMedalWidth"
                                  />
                                </div>
                                <div className="d-flex justify-content-center flex-column align-items-center marginMinusTop">
                                  <div>
                                    <img
                                      src={SImg3}
                                      alt=""
                                      className="ImgWidth"
                                    />
                                  </div>
                                  <div className="d-flex flex-column align-items-center mt-3">
                                    <div className="Name_Font_St">Lilly</div>
                                    <div className="Class_Font_Sec">XII B</div>
                                  </div>
                                </div>
                                <div className="d-flex flex-column align-items-center mt-3">
                                  <div className="SportsCompFont">
                                    InterSchool Tennis
                                  </div>
                                  <div className="SportsCompFont">
                                    Tournament
                                  </div>
                                </div>
                              </div>
                            </Fade>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="borderCenert"></div>
                    {AccdamicDec2 && (
                      <div>
                        <div className="nameDecachivVi mt-3">September</div>
                        <div className="A_Flex_Card1">
                          <Fade bottom>
                            <div
                              className="GradeCard"
                              onClick={() => {
                                setHide({
                                  one: true,
                                });
                              }}
                            >
                              <div>
                                <img
                                  src={GMedal}
                                  alt=""
                                  className="ImgMedalWidth"
                                />
                              </div>
                              <div className="d-flex justify-content-center flex-column align-items-center marginMinusTop">
                                <div>
                                  <img
                                    src={SImg3}
                                    alt=""
                                    className="ImgWidth"
                                  />
                                </div>
                                <div className="d-flex flex-column align-items-center mt-3">
                                  <div className="Name_Font_St">Vijay Mari</div>
                                  <div className="Class_Font_Sec">XII B</div>
                                </div>
                              </div>
                              <div className="d-flex flex-column align-items-center mt-3">
                                <div className="SportsCompFont">
                                  Zonal level 100m
                                </div>
                                <div className="SportsCompFont">Event</div>
                              </div>
                            </div>
                          </Fade>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {/* My Achievements for View more */}

        {/* Birthday's for view more */}
        {birthday && (
          <>
            <div className="d-flex flex-row align-items-center justify-content-between Header-Style web-display-none">
              <div className="d-flex flex-row align-items-center gap-3">
                <div className="Today-Font">
                  <AiOutlineArrowLeft
                    size={20}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setBirthday(!birthday);
                      setOverAll(true);
                      window.scrollTo(0, 0);
                    }}
                  />
                </div>
                <div className="Today-Font">&nbsp; Birthday</div>
              </div>

              <div
                onClick={ShowProfile}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  // marginRight: '15px',
                }}
              >
                <div onClick={ShowProfile} className="cursor-hover">
                  <img
                    src={StLogo}
                    style={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "50%",
                      marginRight: "10px",
                      marginLeft: "10px",
                    }}
                    alt=""
                  />
                </div>
                <div
                  style={{
                    cursor: "pointer",
                  }}
                  className="dropdown-profile"
                >
                  <div className={"Name_Nikil"}>
                    {localStorage.length > 0 &&
                      JSON.parse(localStorage.userCreds).adminName}
                  </div>
                  <div className={"Class_section"}>
                    {localStorage.length > 0 &&
                      JSON.parse(localStorage.userCreds).designation}
                  </div>
                </div>
              </div>
            </div>

            <div className="Assignment_None">
              {myClass.one ? (
                <div className="d-flex text-center Birthday_Mobile_Color">
                  <div
                    className="Today_Birth_Font Pad_10_15 width_today_birth cursor-hover"
                    onClick={() => {
                      setMyClass({
                        one: false,
                        two: false,
                      });
                    }}
                  >
                    Birthday's
                  </div>
                  <div className="d-flex text-center Today_Birth_Fontcolor Today_Birth_border birthsideborder Pad_10_15">
                    All Students
                  </div>
                </div>
              ) : (
                <div className="d-flex text-center Birthday_Mobile_Color">
                  <div className="Today_Birth_Fontcolor Today_Birth_border birthsideborder Pad_10_15">
                    Birthday's
                  </div>
                  <div
                    className="d-flex text-center Today_Birth_Font Pad_10_15 cursor-hover"
                    onClick={() => {
                      setMyClass({
                        one: true,
                        two: false,
                      });
                    }}
                  >
                    All Students
                  </div>
                </div>
              )}
            </div>

            <div className="d-flex flex-row px-2 py-3 w-100">
              <div className="col-md-12 OuterDiv10 padding-left-0 padding-right-0 MTop_Birthday Padding_0px">
                <div className="title-txt-label mob-display-none">
                  <div className="Today-Font DisplayNone">
                    <AiOutlineArrowLeft
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setBirthday(!birthday);
                        setOverAll(true);
                      }}
                    />{" "}
                    &nbsp; Birthday's
                  </div>
                </div>

                <div className="d-flex flex-row w-100 p-3">
                  <div className="col-md-5 Padding_0px">
                    <div className="BirthDiv1">
                      <div className="mob-display-none">
                        <div className="Today_Birth_Font d-flex justify-content-center align-items-center border-bottom border-secondary padfifteen">
                          My Class Students
                        </div>
                      </div>

                      <div className="mob-display-none">
                        {myClass.one ? (
                          <div className="d-flex">
                            <div
                              className="Today_Birth_Font padfifteen width_today_birth cursor-hover"
                              onClick={() => {
                                setMyClass({
                                  one: false,
                                  two: false,
                                });
                              }}
                            >
                              Today's Birthday
                            </div>
                            <div className="Today_Birth_Fontcolor Today_Birth_border birthsideborder padfifteen shadow p-3 rounded">
                              All Students
                            </div>
                          </div>
                        ) : (
                          <div className="d-flex">
                            <div className="Today_Birth_Fontcolor Today_Birth_border birthsideborder padfifteen shadow p-3 rounded">
                              Today's Birthday
                            </div>
                            <div
                              className="Today_Birth_Font padfifteen cursor-hover"
                              onClick={() => {
                                setMyClass({
                                  one: true,
                                  two: false,
                                });
                              }}
                            >
                              All Students
                            </div>
                          </div>
                        )}
                      </div>
                      {myClass.one ? (
                        <>
                          <div className="Assignment_None">
                            <div className="d-flex justify-content-between align-items-center mt-3 px-3">
                              <div>
                                <select className="Select_Birth_Css">
                                  <option>XII - B</option>
                                  <option>XI - A</option>
                                  <option>XI - B</option>
                                  <option>XII - A</option>
                                </select>
                              </div>
                              <div className="d-flex align-items-center Birth_Input">
                                <div>
                                  <AiOutlineSearch
                                    size={20}
                                    className="Birth_Search"
                                  />
                                </div>
                                <div>
                                  <input
                                    type="text"
                                    placeholder="Search Students"
                                    className="Birth_Input_Css"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="StudentScroll_Birth mt-3 padfifteen">
                            {BirthDate.map((x) => {
                              return (
                                <div style={{ padding: "15px 0px" }}>
                                  <div className="d-flex flex-row align-items-center gap-3">
                                    <div>
                                      <img src={Sample} alt="" />
                                    </div>
                                    <div className="d-flex flex-column">
                                      <div className="Name_Birth_Name_A_Font">
                                        {/* Abi V */}
                                        {x.name}
                                      </div>
                                      <div className="Name_Birth_Class_A_Font">
                                        {/* XII - B */}
                                        {x.class}
                                      </div>
                                      <div className="Name_Birth_Date_A_Font">
                                        {/* 28 Feb 2003 */}
                                        {x.date}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </>
                      ) : (
                        <div>
                          <div className="Today_Birth_Font mt-2">Today</div>
                          <div className="Birth_Height padfifteen">
                            {NameClass.map((x) => {
                              return (
                                <div style={{ padding: "15px 0px" }}>
                                  <div className="d-flex flex-row w-100 justify-content-between align-items-center">
                                    <div className="d-flex flex-row align-items-center gap-3">
                                      <div>
                                        <img src={SImg1} alt="" />
                                      </div>
                                      <div className="d-flex flex-column">
                                        <div className="Name_Birth_Font">
                                          {/* Lilly D */}
                                          {x.name}
                                        </div>
                                        <div className="Name_Class_Font">
                                          {/* XII - B */}
                                          {x.class}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="Button_Design">
                                      <div className="Button_Design_Font">
                                        {/* Send Wishes */}
                                        {x.wish}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>

                          <div className="Today_Birth_Font mt-2">Upcoming</div>

                          <div className="Birth_Height_Upcoming padfifteen">
                            {NameClassUpcoming.map((x) => {
                              return (
                                <div style={{ padding: "15px 0px" }}>
                                  <div className="d-flex flex-row w-100 justify-content-between align-items-center">
                                    <div className="d-flex flex-row align-items-center gap-3">
                                      <div>
                                        <img src={SImg3} alt="" />
                                      </div>
                                      <div className="d-flex flex-column">
                                        <div className="Name_Birth_Font">
                                          {x.name1}
                                        </div>
                                        <div className="Name_Class_Font">
                                          {x.class1}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="Button_Design">
                                      <div className="Button_Design_Font">
                                        {x.date}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-md-7 mob-display-none">
                    <div className="BirthDiv2">
                      <div className="Today_Birth_Font padfifteen d-flex flex-row justify-content-center align-items-center border-bottom border-secondary">
                        School Students
                      </div>
                      {myClassa.two ? (
                        <div className="d-flex">
                          <div
                            className="Today_Birth_Font padfifteen width_today_birth cursor-hover"
                            onClick={() => {
                              setMyClassa({
                                one: false,
                                two: false,
                              });
                            }}
                          >
                            Today's Birthday
                          </div>
                          <div className="Today_Birth_Fontcolor Today_Birth_border birthsideborder padfifteen shadow p-3 rounded">
                            All Students
                          </div>
                        </div>
                      ) : (
                        <div className="d-flex">
                          <div className="Today_Birth_Fontcolor Today_Birth_border birthsideborder padfifteen shadow p-3 rounded">
                            Today's Birthday
                          </div>
                          <div
                            className="Today_Birth_Font padfifteen cursor-hover"
                            onClick={() => {
                              setMyClassa({
                                one: false,
                                two: true,
                              });
                            }}
                          >
                            All Students
                          </div>
                        </div>
                      )}

                      {myClassa.two ? (
                        <div className="StudentScroll_Birth mt-3 padfifteen">
                          {BirthDate.map((x) => {
                            return (
                              <div style={{ padding: "15px 0px" }}>
                                <div className="d-flex flex-row align-items-center gap-3">
                                  <div>
                                    <img src={Sample} alt="" />
                                  </div>
                                  <div className="d-flex flex-column">
                                    <div className="Name_Birth_Name_A_Font">
                                      {/* Abi V */}
                                      {x.name}
                                    </div>
                                    <div className="Name_Birth_Class_A_Font">
                                      {/* XII - B */}
                                      {x.class}
                                    </div>
                                    <div className="Name_Birth_Date_A_Font">
                                      {/* 28 Feb 2003 */}
                                      {x.date}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        <div>
                          <div className="Birth_Height padfifteen">
                            {NameClass.map((x) => {
                              return (
                                <div style={{ padding: "15px 0px" }}>
                                  <div className="d-flex flex-row w-100 justify-content-between align-items-center">
                                    <div className="d-flex flex-row align-items-center gap-3">
                                      <div>
                                        <img src={SImg1} alt="" />
                                      </div>
                                      <div className="d-flex flex-column">
                                        <div className="Name_Birth_Font">
                                          {/* Lilly D */}
                                          {x.name}
                                        </div>
                                        <div className="Name_Class_Font">
                                          {/* XII - B */}
                                          {x.class}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="Button_Design">
                                      <div className="Button_Design_Font">
                                        {/* Send Wishes */}
                                        {x.wish}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>

                          <div className="Today_Birth_Font mt-2">Upcoming</div>

                          <div className="Birth_Height_Upcoming padfifteen">
                            {NameClassUpcoming.map((x) => {
                              return (
                                <div style={{ padding: "15px 0px" }}>
                                  <div className="d-flex flex-row w-100 justify-content-between align-items-center">
                                    <div className="d-flex flex-row align-items-center gap-3">
                                      <div>
                                        <img src={SImg3} alt="" />
                                      </div>
                                      <div className="d-flex flex-column">
                                        <div className="Name_Birth_Font">
                                          {x.name1}
                                        </div>
                                        <div className="Name_Class_Font">
                                          {x.class1}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="Button_Design">
                                      <div className="Button_Design_Font">
                                        {x.date}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        <Footer
          openForm={openForm}
          shoq={shoq}
          closeForm={closeForm}
          KpostIframe={KpostIframe}
        />
      </div>
    </div>
  );
};

export default NewDashboard;
