const subMenuAssessment = [
  {
    title: 'Current Assignment',
    url: '../exam/student',
  },
  {
      title: 'Pending Assignment',
      url: '../assignment/pending',
  },
  {
    title: 'Past Assignment',
    url: '../assignment/past',
  },
    
]

export const menuItems = [
    // ...
    {
      title: 'Dashboard',
      page: 1,
      url: '/dashboard',
    },
    {
        title: 'Katbook',
        page: 2,
        url: '/BookView',
      },
      {
        title: 'Timetable',
        page: 3,
        url: '/timetable',
      }, 
      {
        title: 'Attendance',
        page: 4,
        url: '/attendance',
      },    
     {
        title : 'Assignment',
        // url : '/',
        submenu: subMenuAssessment
    },
    {
      title : 'Report',
      url: '/StudentsReport'
    },
  ];