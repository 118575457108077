
import React, { Fragment, useState, useEffect } from "react";
import parse from "html-react-parser";
import moment from "moment";
import { toast } from "react-toastify";
import { CSmartTable } from "@coreui/react-pro";
// Hooks
import useResponsive from "../../hooks/useResponsive";

// Component or Elements
import Header from "./HeaderNavbar";
import Footer from "./Footer";

// Styles
import "./StudentAssignment.css";

// Icons


// Custom Components

import KButton from "./common/KButton";
import KHeader from "./common/KHeader";
import KRequiredLabel from "./common/KRequiredLabel";
import KEditor from "./common/KEditor";
import Warning from "./Toastify/Warning";
import Success from "./Toastify/Success";
import Danger from "./Toastify/Danger";

// Services
import {

  postAnswersByStudent,
  getTableData,
  Student_Assignment_post_Report,
  Pending_Assignment_List_table,
} from "../../services/Apiservices";


const PastAssignment = ({
  showProfile,
  falseShowProfile,
  notofShowProfile,
  ShowNotifications,
  showNotification,
  falseShowNotification,
  disableOnOuterTouch,
  shoq,
  KpostIframe,
  closeForm,
  openForm,
  goToMainRoutes
}) => {
  // Initialization
  // Hooks Initialization
  const { isBigScreen } = useResponsive();

  // SetState Initialization

  const [answerEditor, setAnswerEditor] = useState([]);
  const [draftCollection, setDraftCollection] = useState([]);
  const [responseCollection, setResponseCollection] = useState([]);

  const [tableAssignmentDisplay, setTableAssignmentDisplay] = useState([]);
  const [selectedAssignment, setSelectedAssignment] = useState("");
  const[dataVisible3,setDataVisible3] = useState(false);
  const[dataVisible1,setDataVisible1] = useState(true);
  const[dataVisible2,setDataVisible2] = useState(false);
  
  
  const[postData,setPostData] = useState([])

  const [questionDisplay, setQuestionDisplay] = useState([
    // {
    //   _id:"87878798798",
    //   question:"Hello"
    // }
  ]);

  
  useEffect(() => {
    pending_assignment_table();
  }, []);

  const columns_ThirdTabel_Assign_Pending = [
    {
      key: "No",
      label: "S.No",
      _style: { width: "6%" },
    },
    {
      key: "Question",
      label: "List of Question and Answer",
      _style: { width: "65%" },
    },
    // {
    //   key: "correctAnswer",
    //   label: "Student Answer",
    //   _style: { width: "30%" },
    // },
    {
        key: "MarksObtained",
        label: "Marks Obtained",
        _style: { width: "15%" },
      },
    {  key: "comment", 
       label: "Comment", 
       _style: { width: "15%" } },
  ];

  const[assignmentSecondData,setAssignmentSecondData] = useState([{

    No:"",
    assignmentId:"",
    SubjectName: "",
    assignmentName: "",
    Assignedby: "",
    totalMarks: "",
    lsd:"",
    
  }])
  

  const Pending_Assignment_table1 = [
    {
      key: "No",
      label: "S.No",
      _style: { width: "6%" },
    },
    {
      key: "katbookSubjectName",
      label: "Subject",
      _style: { width: "12%" },
    },
    {
      key: "assignmentName",
      label: "Assignment Name",
      _style: { width: "16%" },
    },
    {
        key: "staffName",
        label: "Assignment By Teacher",
        _style: { width: "15%" },
      },
    {  key: "totalMarks", 
       label: "Marks", 
       _style: { width: "15%" } },

    {  key: "lsd", 
       label: "Last Date of Submission", 
       _style: { width: "15%" } },
       
    // { key: "Action", _style: { width: "10%" } },
  ];

  const Pending_Assignment_table2 = [
    // {
    //   key: "No",
    //   label: "S.no",
    //   _style: { width: "6%" },
    // },
    {
      key: "SubjectName",
      label: "Subject",
      _style: { width: "12%" },
    },
    {
      key: "assignmentName",
      label: "Assignment Name",
      _style: { width: "16%" },
    },
    {
        key: "Assignedby",
        label: "Assignment By Teacher",
        _style: { width: "15%" },
      },
    {  key: "totalMarks", 
       label: "Marks", 
       _style: { width: "15%" } },

    {  key: "lsd", 
       label: "Last Date of Submission", 
       _style: { width: "15%" } },
       
   
  ];


  const AnswerEditorHandler = (key, value, data) => {
    const previousData = answerEditor.find((k) => k.key === key);
    const removeExistingKey = answerEditor.filter((k) => k.key !== key);
    if (data !== undefined) {
      setAnswerEditor([
        ...removeExistingKey,
        {
          key: key,
          value: value,
          ...data,
        },
      ]);
    } else {
      setAnswerEditor([
        ...removeExistingKey,
        {
          ...previousData,
          key: key,
          value: value,
        },
      ]);
    }
  };


  const Student_Past_Question_Answer = async (assignmentId) => {
    let response;
    setQuestionDisplay([]);
    const body = {
      studentId: localStorage.length > 0 && JSON.parse(localStorage.userCreds).userName,
      assignmentId: assignmentId,

    };
    console.log("sdf5f45",body)
    try {
      response = await getTableData(body);
      // console.log(response, "qalist123455");
      if (response) {
        if (response.status==="success") {
          // toast.success(response.message);
          if (response.data) {
            const data = response.data.map((x, index) => {
             
              return {
                No: index + 1,
                Question:x.question,
                // Question:`
                //   Question :${x?.question }
                //   Correct Answer : ${x?.correctAnswer}
                //   Student Answer : ${x?.studentAnswer === null ? 'Student Not Answer' : x?.studentAnswer}`
                //   ,
                //  correctAnswer:`${x.studentAnswer === null ? '-' : x.studentAnswer}`,
                 comment:`${x?.comment === null ? '-' : x?.comment}`, 
                
                // Comsment:`${x?.Comment === 'undefined' ? '-' : x?.Comment}`, 
                // MarksObtained:`${x.studentMark == null ? '-' : x.studentMark}`,
                  MarksObtained: `${x.answerType === "RadioButton" ? (x.studentAnswer === x.correctAnswer ? x.marks : 0) : (x.studentMark !== null ? x.studentMark : "-")}`,
                  correctAnswer:x.correctAnswer,
                  // studentAnswer:x.studentAnswer,
                  studentAnswer : `${x?.studentAnswer === null ? 'Student Not Answer' : x?.studentAnswer}`,
                  answerType:x.answerType,
                  option: x.options === "" || x.options === null ? "" : `${JSON.parse(x.options)?.map((x, i) => `${i + 1}.${x.options}`).join('\n')}`,
                  
                
              };
            });
            setQuestionDisplay(data);
          }
        } 
        
        else {
          toast.error(response.message);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };


  const AnswerCollectionHandler = (key, value, isDraft, toRemove) => {
    const removeDraftCollection = draftCollection.filter(
      (item) => item.key !== key
    );
    const removeResponseCollection = responseCollection.filter(
      (item) => item.key !== key
    );

    const response = {
      key: key,
      ...value,
    };

    if (toRemove) {
      setResponseCollection(removeResponseCollection);
      setDraftCollection(removeDraftCollection);
    } else {
      if (isDraft) {
        setDraftCollection([...removeDraftCollection, response]);
        setResponseCollection(removeResponseCollection);
      } else {
        setDraftCollection(removeDraftCollection);
        setResponseCollection([...removeResponseCollection, response]);
      }
    }
  };
  var entities = {
    amp: "&",
    apos: "'",
    "#x27": "'",
    "#x2F": "/",
    "#39": "'",
    "#47": "/",
    lt: "<",
    gt: ">",
    nbsp: " ",
    quot: '"',
  };
  function decodeHTMLEntities(text) {
    return text.replace(/&([^;]+);/gm, function (match, entity) {
      return entities[entity] || match;
    });
  }


  // const getAssignmentQuestions = async (data) => {
  //   console.log(data, "fghhjjjjjjj");
  //   const { _id } = data;
  //   const body = {
      
  //     studentId: localStorage.length > 0 && JSON.parse(localStorage.userCreds).userName,
  //     assignmentId:_id,
  //   };
  //   console.log("12457",body)
  //   const response = await getTableData(body);
  //   if (response) {
  //     console.log("145858588",response)
  //     if (response.success) {
  //       setQuestionDisplay(response.data);
  //       try {
  //         const userCreds = await JSON.parse(localStorage.getItem("userCreds"));
  //         console.log(response, "AHIAHOAJAJJ");
  //         const csvStructureData = response.data.map((x) => [
  //           userCreds.userName,
  //           _id,
  //           x.assignmentId,
  //           decodeHTMLEntities(String(x.question).replace(/<(.|\n)*?>/g, "")),
  //           x.answerType === "RadioButton"
  //             ? x.options.reduce((prev, next, index) => {
  //                 if (index === 1) {
  //                   return `${index}) ${decodeHTMLEntities(
  //                     String(prev.option).replace(/<(.|\n)*?>/g, "")
  //                   )}\n ${index + 1}) ${decodeHTMLEntities(
  //                     String(next.option).replace(/<(.|\n)*?>/g, "")
  //                   )}`;
  //                 }
  //                 return `${prev}\n ${index + 1} ${decodeHTMLEntities(
  //                   String(next.option).replace(/<(.|\n)*?>/g, "")
  //                 )}`;
  //               })
  //             : "",
  //           "",
  //         ]);

  //         setCsvStructure([
  //           [
  //             [
  //               "StudentId",
  //               "AssignmentId",
  //               "questionId",
  //               "Question",
  //               "Options (Note : Kindly Type the Option Number in Answer Field ) ",
  //               "Answer",
  //             ],
  //             ...csvStructureData,
  //           ],
  //         ]);
  //       } catch (err) {
  //         console.log(err, "CSVSTRUCTURE");
  //       }

  //       setSelectedAssignment(_id);
  //     } else {
  //       toast.error(<Danger body={response?.error} />);
  //     }
  //   }
  // };

  const postAnswer = async () => {
    try {
      const userCreds = await JSON.parse(localStorage.getItem("userCreds"));
      const answers = responseCollection.map((answer) => ({
        answer: answer.value,
        isCorrect: answer.value == answer.answer ? true : false,
        questionid: answer._id,
      }));
     
      const body = {
        answers: answers,
      };
      const postanswer = await postAnswersByStudent(body);
      if (postanswer) {
        if (postanswer.success) {
          toast.success(<Success body={postanswer.message} />);
          setSelectedAssignment("");
          setDraftCollection([]);
          setResponseCollection([]);
          setQuestionDisplay([]);
          setAnswerEditor([]);
          //pending_assignment_table();
        }
      } else {
        // toast.danger(<Danger body={postanswer?.error} />);
      }
    } catch (err) {
      console.log(err, "POST_ANSEWG");

      console.log(err);
    }
  };

  const pending_assignment_table = async () => {
    const userCreds = await JSON.parse(localStorage.getItem("userCreds"));
    
    if (userCreds) {
      try {
       
        const body = {
          sectionId:userCreds. classID,
          classId:userCreds.variableID,
          studentId:userCreds.userName,
        }
        // console.log("asdjklasjlkjdas",body)
        // const studentAssignment = await getStudentAssignment();
        const response = await Pending_Assignment_List_table(body);

        if(response.status === "403"){
          goToMainRoutes()
        }
        
        // if (response.success) {
          // toast.success(response.message);
          if (response.status === "success")        
          {
            // console.log("jfklsdjf",response.data)
            setTableAssignmentDisplay(response.data.map((x,i)=>{
              return({
                No:i+1,
                assignmentId : x.assignmentId,
                staffId: x.staffId,
                Assignedby:x.staffName,
                staffName: x.staffName,
                staffDesignation: x.staffDesignation,
                katbookSubjectId: x.katbookSubjectId,
                katbookSubjectName:x.katbookSubjectName,
                assignmentName: x.assignmentName,
                refInstaId:x.refInstId,
                username: x.username,
                classId: x.classId,
                sectionId: x.sectionId,
                totalMarks: x.totalMarks,
                // lsd: x.lsd,
                lsd:`${moment(x.lsd).format("DD-MMM-YYYY")}`,
              })
            }));
          }
        // } 
        else {
          // toast.error(response.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };


   return (
    <div onClick={() => disableOnOuterTouch()}>
      {/* Header Section Starts  */}
      <div className="Navbar">
        <Header
          showProfile={showProfile}
          falseShowProfile={falseShowProfile}
          notofShowProfile={notofShowProfile}
          ShowNotifications={ShowNotifications}
          showNotification={showNotification}
          falseShowNotification={falseShowNotification}
        />
      </div>

      {/* Header Section Ends */}

     

      <div className={"Dash_home_QWI"}>
        <div
          className="myattendcard2w p-3"
          style={{
            height: window.innerHeight - 117 + "px",
            overflowY: "auto",
          }}
        >
          {/* Assessment Table Starts */}
          <div className="container-fluid px-5 py-3 ">
              <div className="">
                <div className="mb-4">
                   <h4>
                   Pending Assignment - {`${moment().format("DD MMM YYYY")}`}
                   </h4>
                    
                </div>
                  {dataVisible1 && (
                    <div className="tabelstyle">
                      <CSmartTable
                        columns={Pending_Assignment_table1}
                        columnFilter
                        columnSorter
                        items={tableAssignmentDisplay}
                        itemsPerPage={5}
                        itemsPerPageSelect={tableAssignmentDisplay.length > 5}
                        pagination
                        clickableRows
                        onRowClick={(item)=>{
                          setDataVisible1(false);
                          setDataVisible2(true);
                          setDataVisible3(true);
                          Student_Past_Question_Answer(item.assignmentId)
                          setAssignmentSecondData([{
                            No:item.No,
                            assignmentId:item._id,
                            assignmentName:item.assignmentName,
                            SubjectName:item.katbookSubjectName,
                            Assignedby: item.Assignedby,
                            totalMarks: item.totalMarks,
                            lsd:item.lsd,
                           
                          }])
                        }}
                        // scopedColumns={{
                        //   Action: (item) => (
                        //     <td>
                        //       <button
                        //         type="button"
                        //         className=" clickmeBtn "
                        //         title="Delete"
                        //         onClick={() => {
                        //           setDataVisible1(false);
                        //           setDataVisible2(true);
                        //           setDataVisible3(true);
                        //           Student_Past_Question_Answer(item.assignmentId)
                        //           setAssignmentSecondData([{
                        //             No:item.No,
                        //             assignmentId:item._id,
                        //             assignmentName:item.assignmentName,
                        //             SubjectName:item.katbookSubjectName,
                        //             Assignedby: item.Assignedby,
                        //             totalMarks: item.totalMarks,
                        //             lsd:item.lsd,
                                   
                        //           }])
                                  
                        //         }}
                        //       >
                        //         view
                        //       </button>
                        //     </td>
                        //   ),
                        // }}
                        tableProps={{
                          striped: true,
                          hover: true,
                          responsive: true,
                        }}
                      />
                    </div>
                  )}
                  
                  {dataVisible2 && (
                    <div className="tabelstyle">
                      <CSmartTable
                        columns={Pending_Assignment_table2}
                        items={assignmentSecondData}
                        // columnFilter
                        // columnSorter
                        // itemsPerPage={5}
                        // itemsPerPageSelect={assignmentSecondData.length > 5}
                        // pagination
                        scopedColumns={{
                          Action: (item) => (
                            <td>
                              
                            </td>
                          ),
                        }}
                        tableProps={{
                          striped: true,
                          hover: true,
                          responsive: true,
                        }}
                      />
                    </div>
                  )}

                  
              </div>
              <br />
              {dataVisible3 && (
                <>
                  <div className="mt-2 d-flex justify-content-between">
                    <h4>Question and Answer </h4>
                    <button
                      className="backBtn mb-2"
                      onClick={() => {
                        // setMainTable(true);
                        // setSubTable(true);
                        // setSubTableBox(false);
                        setDataVisible3(false);
                        setDataVisible1(true);
                       setDataVisible2(false);
                        
                      }}
                    >
                      Back
                    </button>
                  </div>
                  <div className="mt-2 tabelstyle ">
                        <CSmartTable
                          items={questionDisplay}
                          columnSorter
                          columnFilter
                          itemsPerPage={5}
                          itemsPerPageSelect={questionDisplay.length > 5}
                          pagination
                          columns={columns_ThirdTabel_Assign_Pending}
                          scopedColumns={{
                            // Question : (item) =>(<td className="textalign">{parse(item.Question)}</td>),
                                
                            Question : (item) =>(<td className="textalign">
                        
                            <span><b>Question :</b>{parse(item.Question)}</span>
                            <div>
     
                                 {(
                                   item.answerType === "RadioButton" && 
                                   <span className="optionName"> 
     
                                   {parse(item.option)}
                                 </span>
     
                                 )}
                             </div>
                            <span><b>Correct Answer :</b> {parse(item.correctAnswer)}</span>
                            <span >
                            <b>Student Answer : </b> {parse(item.studentAnswer)}</span>
                            
                          </td>),
                           MarksObtained: (item) => (
                             <td>
                              
                               {item.MarksObtained}
       
                             </td>
                           ),
                           comment: (item) => (
                             <td>
                              
                               {questionDisplay.find(data => data.QuestionId === item.questionId)?.comment
                                 ?
                                 parse(questionDisplay.find(data => data.QuestionId === item.questionId)?.comment)
                                 : <p style={{color:"red"}}>-</p>
                               }
                             </td>
                           ),
                            
                          }}
                          tableProps={{
                            hover: true,
                            responsive: true,
                          }}
                        />
                     </div>
                 </>


            )}

              
           </div>
                 

          






          {/* <KHeader
            style={{
              justifyContent: "space-between",
            }}
            frontElement={
              selectedAssignment !== "" && (
                <div
                  style={{
                    marginTop: "26px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                   
                    setSelectedAssignment("");
                    
                  }}
                >
                  <IoIosArrowBack size={25} />
                </div>
              )
            }
            title={`Past Assignment 000 - ${moment().format("DD MMM YYYY")}`}
            containerClassName={`marginTop-20 justify-content-front`}
            LastElement={<div></div>}
          />
          <div className="marginLeft-20 marginRight-20 paddingLeft-10">
             
            <table
              style={{
                borderCollapse: "separate",
                borderSpacing: "0px 15px",
              }}
              className="w-100 StudentAssignment__table1"
            >
              <tr
                style={{
                  border: "1px solid #E6ECF1",
                }}
              >               
                <th className="p-2">
                  <KRequiredLabel
                    className={"mb-0"}
                    title={"Subject "}
                    isRequired={false}
                  />
                </th>
                <th className="p-2">
                  <KRequiredLabel
                    className={"mb-0"}
                    title={"Assignment Name"}
                    isRequired={false}
                  />
                </th>
                <th className="p-2">
                  <KRequiredLabel
                    className={"mb-0"}
                    title={"Assignment By Teacher"}
                    isRequired={false}
                  />
                </th>
                <th className="p-2">
                  <KRequiredLabel
                    className={"mb-0"}
                    title={"Total Marks"}
                    isRequired={false}
                  />
                </th>
                <th className="p-2">
                  <KRequiredLabel
                    className={"mb-0"}
                    title={"Last Date of Submission"}
                    isRequired={false}
                  />
                </th>
              </tr>

              {tableAssignmentDisplay
                .filter((filteredData) => {
                  if (selectedAssignment === "") {
                    return filteredData;
                  } else if (filteredData._id === selectedAssignment) {
                    return filteredData;
                  }
                })
                .map((tableData, index) => (
                  <tr
                    className="py-3 StudentAssignment__tableElements"
                    style={{
                      border: "1px solid #E6ECF1",
                    }}
                    onClick={() => {
                      if (selectedAssignment === "") {
                        
                        // Student_Past_Question_Answer(tableData._id)
                        setDataVisible3(true);
                      }
                    }}
                  >
                    <td className="p-2 StudentAssignment__tableStart">
                      {tableData.katbookSubjectName}
                    </td>
                    <td className="p-2">{tableData.assignmentName}</td>
                    <td className="p-2">{tableData.staffName}</td>
                    <td className="p-2">{tableData.totalMarks}</td>
                    <td className="p-2 StudentAssignment__tableEnd">
                      {`${moment(tableData.lsd).format("DD MMM YYYY")}`}
                    </td>
                  </tr>
                ))}
            </table>

            {dataVisible3 && (
                <>
                  <div className="mt-5 d-flex justify-content-between">
                    <h4>Question and Answer </h4>
                    <button
                      className="backBtn mb-2"
                      onClick={() => {
                        // setMainTable(true);
                        // setSubTable(true);
                        // setSubTableBox(false);
                        setDataVisible3(false);
                      }}
                    >
                      Back
                    </button>
                  </div>
                  <div className="d-flex flex-column pl-3 pr-3 tabelstyle ">
                        <CSmartTable
                          items={questionDisplay}
                          columnSorter
                          columnFilter
                          pagination
                          columns={columns_ThirdTabel_Assign_status}
                          scopedColumns={{
                            Question : (item) =>(<td className="textalign">{parse(item.Question)}</td>),

                            
                          }}
                          tableProps={{
                            hover: true,
                            responsive: true,
                          }}
                        />
                     </div>
                 </>


                )}

          </div> */}
          
          <Fragment>
               
             {selectedAssignment !== "" && (
              <div>
                <KHeader
                  style={{
                    justifyContent: "space-between",
                  }}
                  title={`Question & Answer`}
                  containerClassName={`marginTop-20 justify-content-front`}
                  
                />
                {/* Download Upload Section Starts */}
                  
               

                {questionDisplay.map((data, index) => (
                  <Fragment key={index}>
                    {/* Question Section Start */}
                    <div
                      className={`d-flex flex-row w-100 ${
                        index === 0 ? "marginTop-20" : "paddingTop-20"
                      } ${isBigScreen ? "paddingLeft-20" : ""}`}
                    >
                      <div className="col-8 border-d8d8d8 d-flex flex-row align-items-center py-2">
                        <div className="d-flex flex-row ">
                          <b>Question No {index + 1} : </b>
                          &nbsp;{parse(data.question)}
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="d-flex flex-row">
                          {answerEditor.filter((item) => item.key === index)
                            .length === 0 ? (
                            <KButton
                              onClick={() => {
                                AnswerEditorHandler(index, "", data);
                              }}
                              style={{
                                height: "fit-content",
                              }}
                              btnColor={"green"}
                              className="d-flex flex-row justify-content-center align-items-center py-2 px-3 mr-2"
                              label="Answer"
                            />
                          ) : (
                            <Fragment>
                              {draftCollection.filter(
                                (item) => item.key === index
                              ).length > 0 && (
                                <Fragment>
                                  <KButton
                                    style={{
                                      height: "fit-content",
                                    }}
                                    className="d-flex flex-row justify-content-center align-items-center py-2 px-3 mr-2"
                                    label="Edit"
                                    onClick={() => {
                                      AnswerCollectionHandler(
                                        index,
                                        answerEditor.find(
                                          (item) => item.key === index
                                        ),
                                        false,
                                        true
                                      );
                                    }}
                                  />
                                  <KButton
                                    onClick={() => {
                                      AnswerCollectionHandler(
                                        index,
                                        answerEditor.find(
                                          (item) => item.key === index
                                        ),
                                        false,
                                        false
                                      );
                                    }}
                                    style={{
                                      height: "fit-content",
                                    }}
                                    btnColor={"green"}
                                    className="d-flex flex-row justify-content-center align-items-center py-2 px-3 mr-2"
                                    label="Submit"
                                  />
                                </Fragment>
                              )}
                            </Fragment>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* Question Section End */}
                    {answerEditor.filter((item) => item.key === index).length >
                      0 && (
                      <Fragment>
                        {/* Answer Section Start */}
                        <div
                          className={`${isBigScreen ? "paddingLeft-10" : ""}`}
                        >
                          <div className="col-8">
                            <KRequiredLabel title={`Answer ${index + 1}`} />
                            <Fragment>
                              {draftCollection.filter(
                                (item) => item.key === index
                              ).length > 0 ||
                              responseCollection.filter(
                                (item) => item.key === index
                              ).length > 0 ? (
                                <div className="py-2 px-2 border-d8d8d8 d-flex align-items-center">
                                  <div>
                                    {parse(
                                      answerEditor.find(
                                        (item) => item.key === index
                                      )?.value
                                        ? answerEditor.find(
                                            (item) => item.key === index
                                          )?.value
                                        : ""
                                    )}
                                  </div>
                                </div>
                              ) : (
                                <Fragment>
                                  {answerEditor.find(
                                    (item) => item.key === index
                                  )?.answerType === "TextArea" ||
                                  answerEditor.find(
                                    (item) => item.key === index
                                  )?.answerType === "TextBox" ? (
                                    <KEditor
                                      height={500}
                                      onChange={(value) => {
                                        AnswerEditorHandler(index, value);
                                      }}
                                      value={
                                        answerEditor.find(
                                          (item) => item.key === index
                                        )?.value
                                          ? answerEditor.find(
                                              (item) => item.key === index
                                            )?.value
                                          : ""
                                      }
                                    />
                                  ) : (
                                    answerEditor.find(
                                      (item) => item.key === index
                                    )?.answerType === "RadioButton" && (
                                      <Fragment>
                                        {data.options.map((option, i) => (
                                          <Fragment key={i}>
                                            <div className="d-flex flex-row">
                                              <div className="pr-2">
                                                <input
                                                  type="radio"
                                                  name={data._id}
                                                  checked={
                                                    answerEditor.find(
                                                      (item) =>
                                                        item.key === index
                                                    )?.value
                                                      ? answerEditor.find(
                                                          (item) =>
                                                            item.key === index
                                                        )?.value ==
                                                        option.option
                                                        ? true
                                                        : false
                                                      : false
                                                  }
                                                  onChange={(e) => {
                                                    AnswerEditorHandler(
                                                      index,
                                                      option.option
                                                    );
                                                  }}
                                                />
                                              </div>
                                              <div>
                                                <span>
                                                  {parse(option.option)}
                                                </span>
                                              </div>
                                            </div>
                                          </Fragment>
                                        ))}
                                      </Fragment>
                                    )
                                  )}
                                </Fragment>
                              )}
                            </Fragment>

                            {answerEditor.filter((item) => item.key === index)
                              .length > 0 &&
                              draftCollection.filter(
                                (item) => item.key === index
                              ).length === 0 &&
                              responseCollection.filter(
                                (item) => item.key === index
                              ).length === 0 && (
                                <div className="d-flex flex-row justify-content-end pt-3">
                                  <KButton
                                    isDisabled={
                                      !(
                                        answerEditor.filter(
                                          (item) => item.key === index
                                        ).length > 0 &&
                                        answerEditor.find(
                                          (item) => item.key === index
                                        )?.value &&
                                        answerEditor.find(
                                          (item) => item.key === index
                                        )?.value.length > 0
                                      )
                                    }
                                    style={{
                                      height: "fit-content",
                                    }}
                                    onClick={() => {
                                      AnswerCollectionHandler(
                                        index,
                                        answerEditor.find(
                                          (item) => item.key === index
                                        ),
                                        false,
                                        false
                                      );
                                    }}
                                    btnColor="green"
                                    className="d-flex flex-row justify-content-center align-items-center py-2 px-3 mr-2"
                                    label="Submit"
                                  />
                                  <KButton
                                    isDisabled={
                                      !(
                                        answerEditor.filter(
                                          (item) => item.key === index
                                        ).length > 0 &&
                                        answerEditor.find(
                                          (item) => item.key === index
                                        )?.value &&
                                        answerEditor.find(
                                          (item) => item.key === index
                                        )?.value.length > 0
                                      )
                                    }
                                    onClick={() =>
                                      AnswerCollectionHandler(
                                        index,
                                        answerEditor.find(
                                          (item) => item.key === index
                                        ),
                                        true,
                                        false
                                      )
                                    }
                                    style={{
                                      height: "fit-content",
                                    }}
                                    className="d-flex flex-row justify-content-center align-items-center py-2 px-3 mr-2"
                                    label="Draft"
                                  />
                                  <KButton
                                    style={{
                                      height: "fit-content",
                                    }}
                                    onClick={() => {
                                      const removeDraftCollection =
                                        draftCollection.filter(
                                          (item) => item.key !== index
                                        );
                                      const removeResponseCollection =
                                        responseCollection.filter(
                                          (item) => item.key !== index
                                        );
                                      const removeExistingKey =
                                        answerEditor.filter(
                                          (k) => k.key !== index
                                        );
                                      setAnswerEditor(removeExistingKey);
                                      setResponseCollection(
                                        removeResponseCollection
                                      );
                                      setDraftCollection(removeDraftCollection);
                                    }}
                                    btnColor="red"
                                    className="d-flex flex-row justify-content-center align-items-center py-2 px-3 mr-2"
                                    label="Cancel"
                                  />
                                </div>
                              )}
                          </div>
                        </div>
                        {/* Answer Section End */}
                      </Fragment>
                    )}
                  </Fragment>
                ))}

                {/* Post Button Starts */}
                <div
                  className={` d-flex flex-row justify-content-end ${
                    isBigScreen ? "paddingRight-20" : ""
                  }`}
                >
                  <div className="d-flex flex-row pt-3">
                    <KButton
                      btnColor={"green"}
                      style={{
                        height: "fit-content",
                      }}
                      isDisabled={
                        questionDisplay.length > 0 &&
                        responseCollection.length === questionDisplay.length
                          ? false
                          : true
                      }
                      onClick={() => {
                        postAnswer(selectedAssignment);
                      }}
                      className="d-flex flex-row justify-content-center align-items-center px-4 py-2 mr-2"
                      label="Post"
                    />
                  </div>
                </div>
                {/* Post Button  End */}
              </div>
            )} 
          </Fragment>
        </div>
      </div>
      {/* Footer Section Starts */}
      <Footer
        openForm={openForm}
        shoq={shoq}
        closeForm={closeForm}
        KpostIframe={KpostIframe}
      />
      {/* Footer Section Ends */}
    </div>
  );
};

export default PastAssignment;
