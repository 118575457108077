import React, { useState } from 'react'
import {
    CSmartTable,
} from '@coreui/react-pro'
import Select from 'react-select';
import { getDailyTask,taskupdate } from '../../../services/Apiservices';
import { useEffect } from 'react';
import { toast } from "react-toastify";
const TodayTask = ({ backtaskStatus }) => {

    const [todayTask, setTodayTask] = useState([]);
    const [todayTaskid, setTodayTaskid] = useState([]);
    const [selectedtask, setSelectedtask] = useState([]);
    const handleChangeStatus = (selectedOption, index,taskid) => {
        const { value } = selectedOption;
        setTodayTask((prevTasks) => {
            const updatedTasks = [...prevTasks];
            updatedTasks[index].status = value;
            return updatedTasks;
        });
       
          let taskdata = {
            id: todayTaskid,
            status: selectedOption.value
          }
       
          setSelectedtask((prevState) => [...prevState, taskdata]);
        
        console.log("Selected value:",todayTaskid, value);
    };

    const handleChangeSummary = (e, index) => {
        const { value } = e.target;
        setTodayTask((prevTasks) => {
            const updatedTasks = [...prevTasks];
            updatedTasks[index].summary = value;
            return updatedTasks;
        });
        console.log("Input value:", value);
    };
   
    const columns = [
        {
            key: 'sNo',
            _style: { width: '5%' },
            filter: true,
            sorter: false,

        },
        {
            key: 'class',
            _style: { width: '10%' },
            filter: true,
            sorter: false,
        },
        {
            key: 'Subject',
            _style: { width: '10%' },
            filter: true,
            sorter: false,
        },
        {
            key: 'unit',
            _style: { width: '30%' },
            filter: true,
            sorter: false,
        },
        {
            key: 'period',
            _style: { width: '10%' },
            filter: true,
            sorter: false,
        },
        {
            key: 'status',
            _style: { width: '15%' },
            filter: true,
            sorter: false,
        },
        {
            key: 'Summary',
            _style: { width: '15%' },
            filter: true,
            sorter: false,
        },
    ]


    const options = [
        { value: 'Completed', label: 'Completed' },
        { value: 'Carried For the Next Day', label: 'Carried for the next day' },
        { value: 'Pending', label: 'Pending' },
    ];

    const currentDate = new Date();
    const day = currentDate.getDate().toString().padStart(2, '0');
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const year = currentDate.getFullYear();
    const formattedDate = `${year}-${month}-${day}`;

    useEffect(() => {
        GetDailyTaskReport();
    }, [])
// Task Status Update API Function
    const GetDailyTaskReport = async () => {
        let retrData = JSON.parse(localStorage.userCreds);
        const bod = {
            estStartDate: formattedDate,
            refStaffID: retrData.staffID,
        }
        try {
            const res = await getDailyTask(bod)
            console.log(res.value, "awitresdfewqas");
            if (res) {
                if (res.status) {
                    if (res.value !== null) {
                        const data = res.value.map((x, i) => {
                            return {
                                id: x.id,
                                sNo: i + 1,
                                class: x.variableName,
                                Subject: x.subjectName,
                                unit: x.unitName,
                                period: x.period,
                            }
                        })
                        setTodayTask(data)
                    }else{
                        toast.error("No items found")
                    }
                }
            }
        } catch (e) {
            console.log(e);
        }
    }
    const handleRowClick = (rowData) => {
        console.log('Clicked Row Data:', rowData);
        console.log('Clicked Row ID:', rowData.id); // Access the id property
        setTodayTaskid(rowData.id);
      };
      
    const UpdateTask = async () => {
        console.log(selectedtask,"selectedtask")
        try {
      
        let bod =selectedtask
          let res = await taskupdate(bod);
          console.log(bod,"updatetaskbod")
          if (res?.status === "success") {
            toast.success(res?.value, {
              position: toast.POSITION.TOP_RIGHT,
            });
           
          } else {
            //toast.error(<Danger body={"Something error in create API"} />);
          }
        } catch (e) {
          //console.log(e);
        }
      };
     
    return (
        <div>
            <div className='todaytaskcalass mt-5'>
                Task Status
            </div>

            <div className='d-flex justify-content-center align-items-center mb-2 three'>
                <div>
                    {localStorage.length > 0 &&
                        JSON.parse(localStorage.userCreds).adminName}
                </div> &nbsp; &nbsp;
                <span>
                    Teaching Assignments on {formattedDate}
                </span>
            </div>

            <div className="paddingtaskReport mt-4"
            >
                <CSmartTable
                    activePage={1}
                    clickableRows
                    columns={columns}
                    columnFilter
                    columnSorter
                    items={todayTask}
                    onRowClick={handleRowClick}
                    tableProps={{
                        striped: true,
                        hover: true,
                    }}
                    scopedColumns={{
                        status: (item, index) => {
                            return (               
                                <td>
                                    <Select
                                        options={options}
                                        value={item.status ? options.find((option) => option.value === item.status) : null}
                                        onChange={
                                            (selectedOption) =>{
                                                handleChangeStatus(selectedOption, index,item._id)
                                            console.log(todayTask,"teststatus")
                                            } 

                                        }
                                      
                                    />
                                </td>
                            )
                        },
                        Summary: (item, index) => {
                            return (                              
                                <td>
                                    <input
                                        value={item.summary}
                                        onChange={(e) => handleChangeSummary(e, index)}
                                        isRequired={true}
                                        placeholder="Enter Summary"
                                        className='w-100 KInput__input'
                                        containerClassName="pl-md-3 pl-0 pt-2 pt-md-0 w-100"
                                    />
                                </td>
                            )
                        }
                    }}
                />
            </div>

            <div className='mt-2 paddingtaskReport d-flex justify-content-end gap-2'>
             
            {todayTask.length ===0? (
                        <button type="button" class="btn btn-primary" disabled={true} onClick={() => UpdateTask()}>Confirm</button>
                      ) : (
                        <button type="button" class="btn btn-primary"  disabled={false} onClick={() => UpdateTask()}>Confirm</button>
                      )}
                <button type="button" class="btn btn-danger" onClick={backtaskStatus}>Cancel</button>
                
            </div>
        </div>
    )
}

export default TodayTask
