import React from 'react'
import KButton from '../common/KButton/KButton'
import { useState } from 'react'
import { CSmartTable } from '@coreui/react-pro'
import { gettoViewDailyTask } from '../../../services/Apiservices'
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { toast } from 'react-toastify'

const AppraisalReport = () => {

    const [state, setState] = useState({
        month: false,
        week: false,
        today: false,
    })

    const [appraisalReport, setAppraisalReport] = useState(false)

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const [monthValue, setMonthValue] = useState('');

    const [todayValue, setTodayValue] = useState('');

    const calculateEndDate = (startDate) => {
        const daysToAdd = 6;
        const oneDayMilliseconds = 24 * 60 * 60 * 1000;

        let currentDate = new Date(startDate);
        for (let i = 0; i < daysToAdd; i++) {
            currentDate = new Date(currentDate.getTime() + oneDayMilliseconds);

            // Skip Sundays
            if (currentDate.getDay() === 0) {
                i--;
            }
        }

        return currentDate;
    };

    const handleStartDateChange = (e) => {
        // setStartDate('')
        const newStartDate = e.target.value;
        setStartDate(newStartDate);

        if (newStartDate) {
            const calculatedEndDate = calculateEndDate(newStartDate);
            setEndDate(calculatedEndDate.toISOString().substr(0, 10));
        } else {
            setEndDate('');
        }
    };

    const columns = [
        { key: "sNo", label: "S.No", _style: { width: "2%" }, filter: false, sorter: false },
        {
            key: "class",
            label: "Class",
            _style: { width: "5%" },
        },
        {
            key: "sub",
            label: "Subject",
            _style: { width: "5%" },
        },
        {
            key: "unit",
            label: "Unit / Session",
            _style: { width: "15%" },
        },
        {
            key: "period",
            label: "Period",
            _style: { width: "8%" },
        },
        {
            key: "status",
            label: "Status",
            _style: { width: "8%" },
        },
    ]

    const [appraisel, setAppraisal] = useState([])

    function formatDate(date) {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    }

    const currentDate = new Date();
    const formattedDate = formatDate(currentDate);


// Monthly API Function 
    const ViewDailyReporttoPrint = async (type) => {
        // console.log(startDate,endDate,type,"reportype")
        let retrData = JSON.parse(localStorage.userCreds);
        setAppraisal([])
        let body = {};

        if (type === "daily") {
            body = {
                refStaffID: retrData.staffID,
                requestType: type,
                startDate: todayValue.replace("-", "/").replace("-", "/")
            };
        } else if (type === "weekly") {
            body = {
                refStaffID: retrData.staffID,
                requestType: type,
                startDate: startDate.replace("-", "/").replace("-", "/"),
                endDate: endDate.replace("-", "/").replace("-", "/")
            };
        } else if (type === "monthly") {
            
            const [year, month] = monthValue.split("-");
            body = {
                refStaffID: retrData.staffID,
                requestType: type,
                month:  parseInt(month, 10),
                year:  parseInt(year, 10),
            };
        }
        // console.log(body,"checkweekmonthbdy")
        try {
            const res = await gettoViewDailyTask(body)
           
        // if (res) {
            if (res.status === "success") {
               
                if (Object.keys(res.value).length !== 0 ) {
                  
                  console.log(Object.keys(res.value), "Object.keys(res.value)");
                  let TData = []
      
                  for (let index = 0; index < Object.keys(res.value).length; index++) {
                    const date = (Object.keys(res.value))[index];
                    const Res = {
                      date: date,
                      data: res.value[`${date}`].map((x, i) => {
                        // console.log(x, "date");
                        console.log(res.value[`${date}`], "res.value[`${date}`]");
                        return {
                            sNo: i + 1,
                            sub: x.subjectName,
                            unit: x.unitName,
                            period: x.period,
                            class: x.variableName,
                            status: x.status
                        }
                      })
                    }
                    TData.push(Res)
                  }           
                 
                  setAppraisal(TData)
                  setStartDate('')
                }else{
                   
                    toast.error("No items found")
                    setStartDate('')
                }
            } 
        // }
        } catch (e) {
            console.log(e)
            // toast.error("No items found")
        }
    }

    // const handleDownload = () => {
    //     const doc = new jsPDF();
      
    //     const allTablesData = []; // Store all tablesData arrays

    //     appraisel.forEach((x, index) => {
    //         console.log(x, "tabledatadata");
            
    //         const tablesData = x.data.map((data, index) => {
    //             console.log(data, "tabledatadata");
                
    //             return [
    //                 index + 1,
    //                 data ? data.class : "",
    //                 data ? data.sub : "",
    //                 data ? data.unit : "",
    //                 data ? data.period : "",
    //                 data ? data.status : "",
    //             ];
    //         });
    
    //         console.log(tablesData);
    //         allTablesData.push(...tablesData); 
    //     });
    
    //     console.log(allTablesData, "allTablesData"); 
    //     const tableHeaders = [
    //         'sNo',
    //         'class',
    //         'sub',
    //         'unit',
    //         'period',
    //         'status',
    //     ];
    //     const tableWidth = 200;
    //     const columnWidths = [30, 30, 30, 30, 30, 30];
    //     doc.setFontSize(22);
    //     doc.text(75, 15, 'Today Appraisal Report')
    //     doc.autoTable({
    //         head: [tableHeaders],
    //         body: allTablesData,
    //         styles: {
    //             fontSize: 8,
    //             font: 'helvetica',
    //             celPadding: 2,
    //             minCellHeight: 2,
    //         },
    //         theme: "grid",
    //         headStyles: {
    //             fillColor: [16, 126, 183],
    //             textColo: [255, 255, 255],
    //             fontSize: 9,
    //             padding: 0,
    //         },
    //         startY: 20,
    //         margins: { top: 10 },
    //         columnStyles: { 0: { cellWidth: 10 } },
    //     });
    //     doc.save('Appraisal Report.pdf');
    // };
    const handleDownload = () => {

        const doc = new jsPDF();
    
        let startY = 20;
        const spaceBetweenTables = 5;
    
        for (let index = 0; index < appraisel.length; index++) {
          const x = appraisel[index];
          const Date = appraisel[index].date;
    
          if (index === 0) {
            doc.setFont('helvetica', 'bold'); // Set the font style
            doc.setFontSize(16); // Set the font size
            doc.text(75, 15, 'Appraisal Report'); // Centered text
          }
    
          if (index !== 0) {
            startY += spaceBetweenTables; // Add space between tables
          }
    
          doc.setFont('helvetica', 'normal'); // Set the font style
          doc.setFontSize(10); // Set the font size
          doc.text(90, startY + 7, `${Date}`);
    
    
          const tableHeaders = [
            'SNo',
            'CLASS',
            'SUBJECT',
            'UNIT/SESSION',
            'PERIOD',
          ];
    
          let tableData = [];
    
    
          for (let index = 0; index < x.data.length; index++) {
            const datas = x.data[index];
    console.log(datas,"datasreport")
            tableData.push([
              index + 1,
              datas ? datas.class : '',
              datas ? datas.sub : '',
              datas ? datas.unit : '',
              datas ? datas.period : '',
            ]);
    
          }
    
    
          // Add additional text above the table
    
          // doc.addField()
          doc.autoTable({
            head: [tableHeaders],
            body: tableData,
            styles: {
              fontSize: 8,
              font: 'helvetica',
              cellPadding: 2,
              minCellHeight: 2,
            },
            theme: "grid",
            headStyles: {
              fillColor: [16, 126, 183],
              textColor: [255, 255, 255],
              fontSize: 9,
              padding: 0,
            },
            startY: startY + 10, // Adjust the starting Y position
            margin: { top: 10 },
            columnStyles: { 0: { cellWidth: 10 } }, // Adjust the first column width
          });
    
          startY = doc.autoTable.previous.finalY; // Update the Y position after rendering the table
    
        }
    
    
    
     
        const tableWidth = 200; // Adjust as needed
        const columnWidths = [30, 30, 30, 30, 30]; // Adjust as needed
    
        
        doc.save('AppraisalReport.pdf');
      };
    
    return (
        <div>

            {!appraisalReport ? (
                <div className='mt-5'>
                    <div className="d-flex flex-column justify-content-center flex-md-row">
                        <KButton
                            value="Monthly Report"
                            className={`px-6 py-3 mr-3 diarybtnclass ${state.month ? "activediarybtn" : ""}`}
                            onClick={() => {
                                setState({
                                    month: true,
                                    week: false,
                                    today: false,
                                })
                                setStartDate('')
                            }}
                        />
                        <KButton
                            value="Weekly Report"
                            className={`px-6 py-3 mr-3 diarybtnclass ${state.week ? "activediarybtn" : ""}`}
                            onClick={() => {
                                setState({
                                    month: false,
                                    week: true,
                                    today: false,
                                })
                            }}
                        />
                        <KButton
                            value="Today Report"
                            className={`px-6 py-3 mr-3 diarybtnclass ${state.today ? "activediarybtn" : ""}`}
                            onClick={() => {
                                setState({
                                    month: false,
                                    week: false,
                                    today: true,
                                })
                                setStartDate('')
                            }}
                        />
                    </div>

                    {state.today && (
                        <>
                            <div className='d-flex justify-content-center mt-5'>
                                <div className="pl-md-3 pl-0 pt-2 pt-md-0 w-25">
                                    <label>Select Date</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        value={todayValue}
                                        onChange={(e) => { setTodayValue(e.target.value) }}
                                    />
                                </div>
                            </div>

                            <div className='d-flex justify-content-end gap-2 appraiselviewBtn'>
                                <button type="button" class="btn btn-primary" disabled={!todayValue} onClick={() => {
                                    setAppraisalReport(true);
                                    setState({
                                        month: false,
                                        week: false,
                                        today: true,
                                    })
                                    ViewDailyReporttoPrint("daily")
                                }}>View</button>
                                <button type="button" class="btn btn-danger" onClick={() => {
                                    setState({
                                        month: false,
                                        week: false,
                                        today: false,
                                    });
                                    setEndDate('');
                                    setMonthValue('');
                                    setTodayValue('');
                                }}>Cancel</button>
                            </div>
                        </>
                    )}

                    {state.week && (
                        <>
                            <div className='d-flex justify-content-center mt-5'>
                                <div className="pl-md-3 pl-0 pt-2 pt-md-0 w-25">
                                    <label>From </label>
                                    <input
                                        type="date"
                                        value={startDate}
                                        onChange={handleStartDateChange}
                                        className="form-control"
                                    />
                                </div>
                                <div className="pl-md-3 pl-0 pt-2 pt-md-0 w-25">
                                    <label>To</label>
                                    <input
                                        type="date"
                                        value={endDate}
                                        readOnly
                                        className="form-control"
                                    />
                                </div>
                            </div>

                            <div className='d-flex justify-content-end gap-2 appraiselviewBtn'>
                                <button type="button" class="btn btn-primary" disabled={!endDate} onClick={() => {
                                    setAppraisalReport(true);
                                    setState({
                                        month: false,
                                        week: true,
                                        today: false,
                                    });
                                    ViewDailyReporttoPrint("weekly")
                                }}>View</button>
                                <button type="button" class="btn btn-danger" onClick={() => {
                                    setState({
                                        month: false,
                                        week: false,
                                        today: false,
                                    })
                                    setStartDate('');
                                    setEndDate('');
                                    setMonthValue('');
                                    setTodayValue('');
                                }}>Cancel</button>
                            </div>
                        </>
                    )}

                    {state.month && (
                        <>
                            <div className='d-flex justify-content-center mt-5'>
                                <div className="pl-md-3 pl-0 pt-2 pt-md-0 w-25">
                                    <label>Select Month</label>
                                    <input
                                        type="month"
                                        className="form-control"
                                        value={monthValue}
                                        onChange={(e) => { setMonthValue(e.target.value) }}
                                    />
                                </div>
                            </div>

                            <div className='d-flex justify-content-end gap-2 appraiselviewBtn'>
                                <button type="button" class="btn btn-primary" disabled={!monthValue} onClick={() => {
                                    setAppraisalReport(true);
                                    setState({
                                        month: true,
                                        week: false,
                                        today: false,
                                    });
                                    ViewDailyReporttoPrint("monthly");
                                }}>View</button>
                                <button type="button" class="btn btn-danger" onClick={() => {
                                    setState({
                                        month: false,
                                        week: false,
                                        today: false,
                                    });
                                    setEndDate('');
                                    setMonthValue('');
                                    setTodayValue('');
                                }}>Cancel</button>
                            </div>
                        </>
                    )}

                  
                </div>

            ) : (
                <>


                    {state.month && (
                        <div className='todaytaskcalass mt-5'>
                            Monthly Apprasial Report for {localStorage.length > 0 &&
                                JSON.parse(localStorage.userCreds).adminName}
                        </div>
                    )}

                    {state.week && (
                        <div className='todaytaskcalass mt-5'>
                            Weekly Apprasial Report for {localStorage.length > 0 &&
                                JSON.parse(localStorage.userCreds).adminName}
                        </div>
                    )}

                    {state.today && (
                        <div className='todaytaskcalass mt-5'>
                            Today Apprasial Report for {localStorage.length > 0 &&
                                JSON.parse(localStorage.userCreds).adminName}
                        </div>
                    )}


                    <div className='paddingtaskReport mt-4'>
                        {/* <CSmartTable
                            activePage={1}
                            clickableRows
                            columns={columns}
                            columnFilter
                            columnSorter
                            items={appraisel}
                            tableProps={{
                                striped: true,
                                hover: true,
                            }}
                        /> */}
                          {/* {appraisel.length === 0? (
                       <h5>No data found</h5>
                      ) : ( */}
                    <div>
                         {
              appraisel.map((x, i) => {
                return (
                  <div className={'mb-5'}>
                    <div
                      className='py-3 font-weight-bold d-flex justify-content-center align-items-center'
                    >{x.date}</div>
                    <CSmartTable
                      activePage={1}
                      clickableRows
                      columns={columns}
                      columnFilter
                      columnSorter
                      items={x.data}
                      tableProps={{
                        striped: true,
                        hover: true,
                      }}
                    />
                  </div>
                )
              })
            }
                    </div>
                    {/* )} */}
                        
                    </div>

                    <div className='d-flex justify-content-end gap-2 taskreportprintbtn'>
                        <button type="button" class="btn btn-primary" onClick={() => { handleDownload() }}>Print</button>
                        <button type="button" class="btn btn-danger" onClick={() => {
                            setAppraisalReport(false);
                            setEndDate('');
                            setMonthValue('');
                            setTodayValue('');
                        }}>Back</button>
                    </div>
                </>
            )}

        </div>
    )
}

export default AppraisalReport
