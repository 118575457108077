// Npm Packages
import React, { Fragment, useState, useEffect } from "react";

import parse from 'html-react-parser';
import moment from "moment";
import { toast } from "react-toastify";
import { CSmartTable } from "@coreui/react-pro";

// Hooks
import useResponsive from "../../hooks/useResponsive";

// Component or Elements
import Header from "./HeaderNavbar";
import Footer from "./Footer";
import { ExportCSV } from "./ExportCSV";
// Styles
import "./StudentAssignment.css";
import {
  getDynamicSubjects,
  assignmentStudentsReport_subjectlist,
  QuestionAndAnswerOnly_Table_List,
  Teacher_Review_StudentName_List_API,
  GetStudentAssignmentDetails_API,
} from "../../services/Apiservices";
// Icons


import KButton from "./common/KButton";
import KHeader from "./common/KHeader";
import KRequiredLabel from "./common/KRequiredLabel";
import KEditor from "./common/KEditor";
import Warning from "./Toastify/Warning";
import Success from "./Toastify/Success";
import Danger from "./Toastify/Danger";
import image from "../../Assets/Img_Meet.png";

import {
  CBreadcrumbItem,
  CBreadcrumb,
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
const StudentCreateReport = ({
  VideoConfFunc,
  tConvert,
  CuttentDateTime,
  tableBgColors,
  openBookview,
  showProfile,
  falseShowProfile,
  notofShowProfile,
  ShowNotifications,
  showNotification,
  falseShowNotification,
  disableOnOuterTouch,
  shoq,
  KpostIframe,
  closeForm,
  openForm,
  trueShowNotification,
  goToMainRoutes
}) => {
  // Initialization
  // Hooks Initialization
  const { isBigScreen } = useResponsive();
  const [assignmentReport, setAssignmentReport] = useState(false);
  const parse = require('html-react-parser');
  // Raj - Start--------------------
  const [subjectsData, setsubjectsData] = React.useState([]);
  const [assignment_Stauts, setAssignment_Status] = useState(false);
  const [physics_tabel, setPhysics_Tabel] = useState(false);
  const [assig_tabel, setAssig_tabel] = useState(false);
  const [assign_Btn, setAssign_Btn] = useState(true);
  const [visible, setVisible] = useState(false);
  const [breadcrumAs, setBreadcrumAs] = useState(true);
  const [breadcrumAs1, setBreadcrumAs1] = useState(false);
  const [breadcrumAs2, setBreadcrumAs2] = useState(false);
  const [breadcrumAs3, setBreadcrumAs3] = useState(false);
  const [visibleK, setVisibleK] = useState(false);
  const [studentNameList, setStudentNameList] = useState([]);
  const [questionReportName,setQuestionReportName] = useState([]);
  // Assignment Report .......................
  const [breadcrumAr1, setBreadcrumAr1] = useState(false);
  const [breadcrumAr2, setBreadcrumAr2] = useState(false);
  const [breadcrumAr3, setBreadcrumAr3] = useState(false);
  const [breadcrumAs4, setBreadcrumAs4] = useState(false);
  const [breadcrumAs5, setBreadcrumAs5] = useState(false);
  const [assignmentReportIO, setAssignmentReportIO] = useState(true);
  const [totalmarks, setTotalmarks] = useState([]);
  const [totalmarksOther, setTotalmarksOther] = useState([]);
  
  const [totalmarksReport, setTotalmarksReport] = useState([]);
  
  const [subject, setSubject] = useState(false);
  const [subjectReport, setSubjectReport] = useState(false);
  const [mainTable, setMainTable] = useState(false);
  const [subTable, setSubTable] = useState(false);
  const [subTableBox, setSubTableBox] = useState(false);
  const [report, setReport] = useState(false);
  const [asstTables, setasstTables] = useState([]);
  const [asstTablesSubName, setAsstTablesSubName] = useState([]);
  const [studentArryID, setStudentArryID] = useState([]);
  
  const [asstTablesSubNameRep, setAsstTablesSubNameRep] = useState([]);
  const[titleStatus,setTitleStatus] = useState(true)
  const[titleReport,setTitleReport] = useState(false)
  const [userscredsData, setuserscredsData] = useState([]);
  const[question,setQuestion] = useState([]);
  const[answer,setAnswer] = useState([]);
  const[questionReport,setQuestionReport] = useState([]);
  const[answerReport,setAnswerReport] = useState([]);
  const [studentNameMatch,setStudentNameMatch] = useState([])
  const[extraAssignment,setExtraAssignment] = useState([])
  const[assignmentReportList,setAssignmentReportList] = useState([])
  const[assignmentReportListdata,setAssignmentReportListData] = useState([{
    No:"",
    SubjectName:"",
    Assignment:"",
    Assignedby:"",
    lsd:"",
    submittedDate:"",
    TotalMarks:"",
    studentId:"",
    assignmentId:"",
   
}])
// console.log("sdfjklsdhfqSA",assignmentReportList)
  const[assignmentReportListdata1,setAssignmentReportListData1] = useState({
    assignmentId:"",
  })

  const[assignmentReportListdata2,setAssignmentReportListData2] = useState({
    assignmentId:"",
  })
  // console.log("4564sdff",assignmentReportListdata1)
const[assignmentSecondData,setAssignmentSecondData] = useState([{

  No:"",
  assignmentId:"",
  SubjectName: "",
  subBId:"",
  assignmentName: "",
  Assignedby: "",
  TotalMark: "",
  MarksObtained:"",
  Status:"",
  submittedDate:"",
  DateofSubmission:"",
  Review:"",
}])




  // const [ teacherTable, setTeacherTable ] = useState(false);

  // .............Assignment Status Sample Data......  START.....

  useEffect(() => {
    // const userCreds = await JSON.parse(localStorage.getItem("userCreds"));
    if (localStorage.length > 0) {
      let retrData = JSON.parse(localStorage.userCreds);
      // let studentId = JSON.parse(localStorage.userCreds.username);
      const getSubjectListfunc = async () => {
        let allquesbod, allquestres;

        if (retrData.userType === "Teaching") {
          allquesbod = {
            // refInstitutionId: retrData.refInstID,
            // staffId: retrData.staffID,
            refStaffID: retrData.staffID,
          };
        
        }else {
          allquesbod = {
          
          refInstID: retrData.refInstID,
          refSectionID: retrData.classID,
          refVariableID: retrData.variableID,
          studentId:retrData.username,
       
        };         
        }
        allquestres = await getDynamicSubjects(allquesbod);

        if(allquestres.status === "403"){
          goToMainRoutes()
        }
        
        if (allquestres.status === "success") {
          setuserscredsData(allquestres.value);
          CourseListFunc(allquestres.value);
         
          // Student_Assignment_Status_Table(allquestres.value);
         
          // if(allquestres.value.length > 0){
          //   let dataOPtion = await allquestres.value.  ((e) => {
          //     return { value: e.className+' - '+e.sectionName+' - '+e.subjectName, label: e.className+' - '+e.sectionName+' - '+e.subjectName, katSubjectId: e.katbookSubjectId, kamSubjectId : e.subjectId, className: e.className, classId: e.classId, sectionId: e.sectionId, sectionName: e.sectionName };
          //   });

          //   setsubjectsData(dataOPtion);

          // }
        }
      };

      getSubjectListfunc();
      
    }
  }, [localStorage]);

  // list of all subject
  const CourseListFunc = async (course) => {
    if (course.length > 0) {
      let dataOPtion = await course.map((e) => {
        return {
          value: e.KATBOOK_SUBJECT_ID,
          label: e.SUBJECT_NAME,
          kamSubjectId: e.SUBJECT_ID,
          subjectId: e.KATBOOK_SUBJECT_ID,
          userId: localStorage.length > 0 && JSON.parse(localStorage.userCreds).userName,
        };
      });
      // console.log("12345", dataOPtion);
      setsubjectsData(dataOPtion);
      // Student_Assignment_Status_Table()
    } else {
    }
  };
  

   useEffect(() => {
    
    Student_Assignment_Status_Table(subjectsData.subjectId,);
    
    // Report_Student_Assignment_Table_Second(subjectsData.subjectId);
  }, []);

// Assignment list API 28.04.2023

const Student_Assignment_Status_Table = async (katbookSubjectId,studentId) => { 
  
  let response;
      const body = {
        
          studentId:localStorage.length > 0 && JSON.parse(localStorage.userCreds).userName,
          classId:localStorage.length > 0 && JSON.parse(localStorage.userCreds).variableID,
          sectionId:localStorage.length > 0 && JSON.parse(localStorage.userCreds).classID,
          katbookSubjectId:katbookSubjectId,
      }
      // console.log("dfsdfs5545644",body)
       setasstTables([]);
    try {
      // response = await assignmentStudentsReport_subjectlist(katbookSubjectId,studentId);
      response = await assignmentStudentsReport_subjectlist(body);
      
      console.log(response.data, "aaaa");
      if (response) {
        if (response.status==="success") {
          // toast.success(response.message);
          if (response.data) {
            const data = response.data.map((x, index) => {
              
              return {
                No: index + 1,
                assignmentId:x?.assignmentId,
                SubjectName: x?.katbookSubjectName,
                subBId: x.katbookSubjectId,
                assignmentName: x.assignmentName,
                Assignedby: x.staffName,
                katbookSubjectId:x.katbookSubjectId,
                TotalMark: x.totalMarks,
                // MarksObtained:`${x.obtainedMarks == '' ? '-' : x.obtainedMarks}`,
                Status:x.studentPosted===true?"Submitted":"Not Submitted",
                submittedDate: `${moment(x?.postedDate).format("DD MMM YYYY") === 'Invalid date' ? '-' : moment(x.postedDate).format("DD MMM YYYY") }`,
                DateofSubmission: `${moment(x.lsd).format("DD MMM YYYY")}`,
                
                Review:`${x?.teacherReview === true?"Reviewed":"Not Review"}`,
                
              };
            });
            
            let uniqueArr = []
            // const getUniqueValue = data
            for(let z=0;z<data.length;z++){
              if(uniqueArr.filter(y=>y.SubjectName === data[z].SubjectName).length === 0){
                uniqueArr.push(data[z])
              }
            }
            // uniqueArr
            setAsstTablesSubName(uniqueArr);
            setasstTables(data);
            
          }
        } else {
          // toast.error(response.message);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };


  const Student_Status_Question = async (assignmentId) => {
    let response;
    setQuestion([]);
    const body = {
      studentId: localStorage.length > 0 && JSON.parse(localStorage.userCreds).userName,
      assignmentId: assignmentId,

    };
    // console.log("456454",body)
    try {
      response = await QuestionAndAnswerOnly_Table_List(body);
      // console.log(response, "qalist123455");
      if (response) {
        if (response.status==="success") {
          // toast.success(response.message);
          if (response.data) {
            const data = response.data.map((x, index) => {
             
              return {
                No: index + 1,
                
                Question : x.question,
                answerType:x.answerType,
                //  correctAnswer:`${x.studentAnswer === null ? '-' : x.studentAnswer}`,
                 comment:`${x?.comment === null ? '-' : x?.comment}` || `${x?.comment === "" ? '-' : x?.comment}`, 

                // Comment:`${x?.Comment === 'undefined' ? '-' : x?.Comment}`, 
                // MarksObtained:`${x.studentMark === null ? 'Teacher Not Review ' : x.studentMark}`,
                // MarksObtained: `${x.answerType === "RadioButton" ? x.studentAnswer === x.correctAnswer ? x.marks : 0 : x.studentMark  || x.studentMark == null ? x.studentMark :"-" } `,
                MarksObtained: `${x.answerType === "RadioButton" ? (x.studentAnswer === x.correctAnswer ? x.marks : 0) : (x.studentMark !== null ? x.studentMark : "-")}`,
                answerType:x.answerType,
                option: x.options === "" || x.options === null ? "" : `${JSON.parse(x.options)?.map((x, i) => `${i + 1}.${x.options}`).join('\n')}`,
                
                marks:x.marks,
                correctAnswer:x.correctAnswer,
                studentAnswer:`${x.studentAnswer === null ? 'Student Not Answer' : x.studentAnswer}`,
                // studentMark:x.studentMark,
               
                studentMark: `${x.answerType === "RadioButton" ? (x.studentAnswer === x.correctAnswer ? x.marks : 0) : (x.studentMark !== null ? x.studentMark : "-")}`,
                  
              };
              
            });
            setQuestion(data);
            // console.log("asjkjasldj",data)
            let totalMarks = 0;
            for (let i = 0; i < data.length; i++) {
              const x = data[i];
              if (x.answerType === "RadioButton") {
                totalMarks = x.studentAnswer === x.correctAnswer ? Number(totalMarks) + Number(x?.marks ?? 0) : Number(totalMarks) +  0;
               
              }
               else{
                totalMarks = x.studentMark !== null ? Number(totalMarks) + Number(x?.studentMark ?? 0) : Number(totalMarks) +  0;
              }
              
            }
            if (isNaN(totalMarks)) {
              totalMarks = "-";
            }
            setTotalmarks(totalMarks);
            // console.log("Totalmarks:", totalMarks);
          }
        } 
        
        else {
          toast.error(response.message);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const studentID = localStorage.length > 0 && JSON.parse(localStorage.userCreds).userName
  
  const Student_Status_Answeronly = async (assignmentId,studentID) => {
    let response;
    setAnswer([]);    
    try {
      response = await QuestionAndAnswerOnly_Table_List(assignmentId,studentID);
      // console.log(response, "qalist");
      if (response) {
        if (response.status==="success") {
          // toast.success(response.message);
          if (response.data) {
            const data = response.data.map((x, index) => {
              return {
                No: index + 1,
                correctAnswer:x?.correctAnswer,
                QuestionId: x.questionId,
                comment:`${x?.teacherComment === 'undefined' ? '-' : x?.teacherComment}`, 
                
                obtainedMark:`${x?.obtainedMark === 'undefined' ? '-' : x?.obtainedMark}`, 
              };
            });
            setAnswer(data);
          }
        } else {
          // toast.error(response.message);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };


  const StudentName_List = async (sectionId,assignmentId) => {    
    let response;
    const studentName = localStorage.length > 0 && JSON.parse(localStorage.userCreds).userName
    const assignmentIdData = assignmentId;
    setStudentNameList([])
    const body = {
              
              classId:JSON.stringify(sectionId),
              
            };
          
    try {

      response = await Teacher_Review_StudentName_List_API(body);
  
      if (response.status === "success") {
     
        if (response.value) {
    
            const stuarray = [];
            const data  = [];


          for (let index = 0; index < response.value.length; index++) {
            const x = response.value[index];
            const i = index
           

              const bodyStuAssign = {
                studentId:x.userid,
                assignmentId: assignmentIdData,
              };

              const getassignmentsData = await GetStudentAssignmentDetails_API(bodyStuAssign);
              
              const stuVals = {
                No: i + 1,
                Name: `${x?.firstname} ${x?.lastname}`,
                studentId:x.userid,
                assignmentId:getassignmentsData?.data?.assignmentId,
                status:x?.mobilenumber,
                status: `${getassignmentsData?.data?.studentPosted === true ? "Submitted" : "Not Submitted" } `,
                studentPostedDate: `${getassignmentsData?.data?.studentPostedDate === undefined ? '-' :moment(getassignmentsData?.data?.studentPostedDate).format("DD-MMM-YYYY") }`,
                isSubmitted : getassignmentsData?.data?.studentPosted,
                teacherReview: `${getassignmentsData?.data?.teacherReview === true ? "Reviewed" : "Not Reviewed" } `,
                isReviewed : getassignmentsData?.data?.teacherReview,
                teacherReviewDate: getassignmentsData?.data?.teacherReviewDate ? getassignmentsData?.data?.teacherReviewDate : "",
                studentTotalMarks: `${getassignmentsData?.data?.studentTotalMarks === 0  ? '-' : getassignmentsData?.data?.studentTotalMarks}`,
                
              };
              stuarray.push(stuVals);
             
            
            data.push({
              No: i + 1,
              Name: `${x?.firstname} ${x?.lastname}`,
              studentId:x.userid,
              status:x?.mobilenumber,
            });
          }

            setStudentNameList(stuarray);
            const dataStudent = stuarray.filter(x=>x?.studentId !== studentName )
            // console.log("dataStuden111t",dataStudent)
            setStudentNameMatch(dataStudent)
            let studentarrId = []

            for(let z=0;z<data.length;z++){
              if(studentarrId.filter(y=>y.studentId === data[z].studentId).length === 0){
                studentarrId.push(data[z])
              }
            }
            
            setStudentArryID(studentarrId);
            // console.log("dsfjkjsd",studentarrId)
          // toast.success(response.status);

        } else {
          // toast.error(response.message);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  // const StudentName_List = async (sectionId, assignmentId) => {    
  //   let response;
  //   const studentName = localStorage.length > 0 && JSON.parse(localStorage.userCreds).userName
  //   // console.log("ksldf",studentName)
  //   const assignmentIdData = assignmentId;
  //   const body = {
              
  //             classId:JSON.stringify(sectionId),
              
  //           };
          
  //   try {
  //     response = await Teacher_Review_StudentName_List_API(body);
  
  //     if (response.status==="success") {
  //       if (response.value) {
         
  //         // toast.success(response.message);
  //         // console.log("sddf",response.value)
  //           let stuarray = [];
  //           for(let k=0 ; k < response.value.length; k++ ) {
  //             const x = response.value[k];
  //             const i = k;

  //             const getassignmentdetails = async  (i) => {

  //               const bodyStuAssign = {
  //                 // Change body and API URL if new API 
  //                 // studentId:localStorage.length > 0 && JSON.parse(localStorage.userCreds).userName,
  //                 studentId:x.userid,
  //                 assignmentId: assignmentIdData,
  //                 // classId:localStorage.length > 0 && JSON.parse(localStorage.userCreds).variableID,
  //                 // sectionId:localStorage.length > 0 && JSON.parse(localStorage.userCreds).classID,   
  //               };
                
  //               //  let getassignmentsData = await assignmentStudentsReport_subjectlist(bodyStuAssign);
  //                let getassignmentsData = await GetStudentAssignmentDetails_API(bodyStuAssign);
                 
  //               //  console.log(getassignmentsData,"YYYYYYYYYYYYYYYYYYY");
  //               //  console.log(getassignmentsData.data.filter(item=> item.assignmentId === assignmentReportListdata1.assignmentId),"YYYYYYYYYYYYYYYYYYY");
  //               const stuVals = {
  //                 No: i + 1,
  //                 Name: `${x?.firstname} ${x?.lastname}`,
  //                 studentId:x.userid,
  //                 status:x?.mobilenumber,
  //                 studentPosted: getassignmentsData?.data?.studentPosted ? getassignmentsData?.data?.studentPosted : "",
  //                 studentPostedDate: `${getassignmentsData?.data?.studentPostedDate === undefined ? '-' :getassignmentsData?.data?.studentPostedDate }`,
  //                 teacherReview: getassignmentsData?.data?.teacherReview ? getassignmentsData?.data?.teacherReview : "",
  //                 teacherReviewDate: getassignmentsData?.data?.teacherReviewDate ? getassignmentsData?.data?.teacherReviewDate : "",
  //                 studentTotalMarks: `${getassignmentsData?.data?.studentTotalMarks === 0  ? '-' : getassignmentsData?.data?.studentTotalMarks}`,
                  
  //               };
  //               return stuVals
                
  //               //  assignmentReportListdata1.assignmentId
  //             };
  //             const returnAssgn =await getassignmentdetails(i);

  //             // stuarray.push(returnAssgn);

  //             studentNameList.push(returnAssgn)
              
  //           }
  //             // map(async(x, i) => {

           
  //             // return returnAssgn;

  //             // console.log(returnAssgn,"YYYYYYYYYYYYYYYYYYY");
              
  //             // return {
  //             //   No: i + 1,
  //             //   Name: `${x?.firstname} ${x?.lastname}`,
  //             //   studentId:x.userid,
  //             //   status:x?.mobilenumber,
                

  //             // };
  //           // });
  //           // console.log(stuarray,stuarray.length,"YYYYYYYYYYYYYYYYYYY");
  //           // setStudentNameList(stuarray);
  //           // console.log("ksldf",studentName)
            
  //           // console.log("Sdadasd1114154",data);
  //           const dataStudent = stuarray.filter(x=>x?.studentId !== studentName )
  //           // console.log("dataStuden111t",dataStudent)
  //           setStudentNameMatch(dataStudent)
           
            
  //           let studentarrId = []

  //           // for(let z=0;z<data.length;z++){
  //           //   if(studentarrId.filter(y=>y.studentId === data[z].studentId).length === 0){
  //           //     studentarrId.push(data[z])
  //           //   }
  //           // }
            
  //           setStudentArryID(studentarrId);
  //           // console.log("dsfjkjsd",studentarrId)
  //       } else {
  //         toast.error(response.message);
  //       }
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  const Student_Assignment_Report = async () => {
    let response;

    const body = {
        
      studentId:localStorage.length > 0 && JSON.parse(localStorage.userCreds).userName,
      classId:localStorage.length > 0 && JSON.parse(localStorage.userCreds).variableID,
      sectionId:localStorage.length > 0 && JSON.parse(localStorage.userCreds).classID,
      
  }
    setAssignmentReportList([]);    
    try {
      
      response = await assignmentStudentsReport_subjectlist(body);
     
      // console.log(response.data, "report1757");
      if (response) {
        if (response.status==="success") {
          // toast.success(response.message);
          if (response.data) {
            const data = response.data.map((x, index) => {
              return {
                No: index + 1,
                assignmentValue:x?.assignmentId,
                assignmentId:x?.assignmentId,
                
                SubjectName: x?.katbookSubjectName,
                studentId:x.studentId,
                Assignment:x?.assignmentName,
                Assignedby:x.staffName,
                lsd:`${moment(x.lsd).format("DD-MMM-YYYY")}`,
                submittedDate:`${moment(x.postedDate).format("DD-MMM-YYYY")}`,
                TotalMarks:x.totalMarks,
                studentPosted:x.studentPosted,
                sectionId:x.sectionId,
                teacherReview:x.teacherReview,
              
              };
            });
            setAssignmentReportList(data);
            
            // console.log("sdkljhkhdasd",data)
          //  Only display studentPosted = true filter
            const student_post = data.filter(x=>x.teacherReview === true)
            setAsstTablesSubNameRep(student_post)
                    
          }

        } else {
          toast.error(response.message);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

    
  const Student_Report_Question = async (assignmentValue) => {
    let response;
    setQuestionReport([]);
    const body = {
      studentId: localStorage.length > 0 && JSON.parse(localStorage.userCreds).userName,
      assignmentId: assignmentValue,

    };
    // console.log("456487",body)
    try {
      // response = await QuestionAndAnswer_Table_List(body);
      response = await QuestionAndAnswerOnly_Table_List(body);
      
      // console.log(response, "www");
      if (response) {
        if (response.status==="success") {
          // toast.success(response.message);
          if (response.data) {
            const data = response.data.map((x, index) => {
              return {
                
                S_no: index + 1,
                questionId:x.questionId,
                Question:x.question,
              
                // comment:`${x?.comment === null ? '-' : x?.comment}`,
                comment: `${x.answerType === "RadioButton" ? "-" : (x.comment !== null ? x.comment : "-")}`,
                // obtainedMark: x.answerType === "RadioButton" ? x.studentAnswer === x.correctAnswer ? x.marks : 0 : x.studentMark,
                // obtainedMark: `${x.answerType === "RadioButton" ? (x.studentAnswer === x.correctAnswer ? x.marks : 0) : (x.studentMark !== null ? x.studentMark : "-")}`,
                obtainedMark: `${x.answerType === "RadioButton" ? (x.studentAnswer === x.correctAnswer ? x.marks : 0) : (x.studentMark !== null ? x.studentMark : "-")}`,
                
                correctAnswer:x.correctAnswer,
                studentAnswer:`${x.studentAnswer === null ? 'Student Not Answer' : x.studentAnswer}`,
                marks:x.marks,
                answerType:x.answerType,
                option: x.options === "" || x.options === null ? "" : `${JSON.parse(x.options)?.map((x, i) => `${i + 1}.${x.options}`).join('\n')}`,
                
                studentMark: `${x.answerType === "RadioButton" ? (x.studentAnswer === x.correctAnswer ? x.marks : 0) : (x.studentMark !== null ? x.studentMark : "-")}`,
                 
              };
            });
            setQuestionReport(data);
            // console.log("dksdfjklsjfd",data)
            let totalMarks = 0;
            for (let i = 0; i < data.length; i++) {
              const x = data[i];
              if (x.answerType === "RadioButton") {
                
                totalMarks = x.studentAnswer === x.correctAnswer ? Number(totalMarks) + Number(x?.marks ?? 0) : Number(totalMarks) +  0;
              } else{
                totalMarks = x.studentMark !== null ? Number(totalMarks) + Number(x?.studentMark ?? 0) : Number(totalMarks) +  0;
              }                          
            }
            if(isNaN(totalMarks)){
              totalMarks="-";
            }
            setTotalmarksReport(totalMarks);
          }
        } else {
          toast.error(response.message);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  const Report_studentId = localStorage.length > 0 && JSON.parse(localStorage.userCreds).userName
  

  const StudentName_Report_Question_API = async (studentId) => {
    let response;
    setQuestionReportName([]);
    const body = {
      studentId:studentId,
      // studentId: localStorage.length > 0 && JSON.parse(localStorage.userCreds).userName,
      assignmentId: assignmentReportListdata1.assignmentId,
      
    };
    // console.log("dfhkjhewr454",body)
    try {
      // response = await QuestionAndAnswer_Table_List(body);
      response = await QuestionAndAnswerOnly_Table_List(body);
      
      // console.log(response.data, "www");
      if (response) {
        if (response.status==="success") {
          // toast.success(response.message);
          if (response.data) {
            const data = response.data.map((x, index) => {
              return {
                
                S_no: index + 1,
                questionId:x._id,
                Question:x.question,
                // obtainedMark:x.answerType === "RadioButton" ? x.studentAnswer === x.correctAnswer ? x.marks : 0 : x.studentMark, 
                obtainedMark: `${x.answerType === "RadioButton" ? (x.studentAnswer === x.correctAnswer ? x.marks : 0) : (x.studentMark !== null ? x.studentMark : "-")}`,

                // comment:x.answerType !== "RadioButton" ? x.studentAnswer !== x.correctAnswer ? x.comment : "Not" : x.comment, 
                comment: `${x.answerType === "RadioButton" ? (x.studentAnswer !== x.correctAnswer ? (x.studentAnswer === x.correctAnswer ? x.comment : "-") : "-") : (x.comment !== null ? x.comment : "-")}`,

                // comment:`${x?.comment === null ? '-' : x?.comment}`,
                // obtainedMark:`${x.studentMark === null ? '-' : x.studentMark}`,
                correctAnswer:x.correctAnswer,
                studentAnswer:`${x.studentAnswer === null ? 'Student Not Answer' : x.studentAnswer}`,
                answerType:x.answerType,
                option: x.options === "" || x.options === null ? "" : `${JSON.parse(x.options)?.map((x, i) => `${i + 1}.${x.options}`).join('\n')}`,
                studentMark: `${x.answerType === "RadioButton" ? (x.studentAnswer === x.correctAnswer ? x.marks : 0) : (x.studentMark !== null ? x.studentMark : "-")}`,
                  
              };
            });
            setQuestionReportName(data);
            let totalMarks = 0;
            for (let i = 0; i < data.length; i++) {
              const x = data[i];
              if (x.answerType === "RadioButton") {
                
                totalMarks = x.studentAnswer === x.correctAnswer ? Number(totalMarks) + Number(x?.marks ?? 0) : Number(totalMarks) +  0;
              } else{
                totalMarks = x.studentMark !== null ? Number(totalMarks) + Number(x?.studentMark ?? 0) : Number(totalMarks) +  0;
              }
                             
            }
            if(isNaN(totalMarks)){
              totalMarks = "-";
            }
          
            setTotalmarksOther(totalMarks);
          }
        } else {
          toast.error(response.message);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const Student_Report_Answeronly = async (assignmentId,Report_studentId) => {
    let response;
    setAnswerReport([]);    
    try {
      response = await QuestionAndAnswerOnly_Table_List(assignmentId,Report_studentId);
      // console.log(response, "answer");
      if (response) {
        if (response.status==="success") {
          // toast.success(response.message);
          if (response.data) {
            const data = response.data.map((x, index) => {
              return {
                No: index + 1,
                correctAnswer:x?.correctAnswer,
                QuestionId: x.questionId,
                comment:`${x?.teacherComment === 'undefined' ? '-' : x?.teacherComment}`, 
                obtainedMark:`${x.obtainedMark == '' ? '-' : x.obtainedMark}`,
              };
            });
            setAnswerReport(data);
          }
        } else {
          // toast.error(response.message);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const Student_Assignment_Report_ExtraAPI = async () => {
    let response;
    // studentNameList
    const MyData = studentNameList.map((x) =>{
      return(
        {
          studentIdNew:x.studentId,
        }
      )
    })
    
    // console.log("hjkdfhg",MyData)
    const body = {
      // studentId:localStorage.length > 0 && JSON.parse(localStorage.userCreds).userName,
      studentId:MyData,
      classId:localStorage.length > 0 && JSON.parse(localStorage.userCreds).variableID,
      sectionId:localStorage.length > 0 && JSON.parse(localStorage.userCreds).classID,
         
  }
  // console.log("5545sdfds-body",body)
  setExtraAssignment([]);    
    try {
      
      response = await assignmentStudentsReport_subjectlist(body);
     
      // console.log(response.data, "report1757asasas");
      if (response) {
        if (response.status==="success") {
          // toast.success(response.message);
          if (response.data) {
            const data = response.data.map((x, index) => {
              return {
                No: index + 1,
                assignmentValue:x?.assignmentId,
                assignmentId:x?.assignmentId,
                
                SubjectName: x?.katbookSubjectName,
                studentId:x.studentId,
                Assignment:x?.assignmentName,
                Assignedby:x.staffName,
                lsd:`${moment(x.lsd).format("DD-MMM-YYYY")}`,
                submittedDate:`${moment(x.postedDate).format("DD-MMM-YYYY")}`,
                TotalMarks:x.totalMarks,
                studentPosted:x.studentPosted,
                sectionId:x.sectionId,
              
              };
            });
            // setExtraAssignment(data);
            // console.log("data0002",data)
          //  Only display studentPosted = true filter
            const dataNew =data.filter(x=>x.studentPosted === true)
            setExtraAssignment(dataNew)
            // console.log("jfdsdfhsdf",dataNew)
                      
          }

        } else {
          // toast.error(response.message);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

    

  const columns_FirstTabel_Assign_status = [
    {
      key: "No",
      label: "S.No",
      _style: { width: "6%" },
    },
    {
      key: "SubjectName",
      label: "Subject Name",
      _style: { width: "12%" },
    },
    {
      key: "assignmentName",
      label: "Assignment Name ",
      _style: { width: "13%" },
    },
    {
      key: "Assignedby",
      label: "Assigned by",
      _style: { width: "10%" },
    },
    {
      key: "DateofSubmission",
      label: "Date of Submission",
      _style: { width: "15%" },
    },
    {
      key: "submittedDate",
      label: "Submitted Date",
      _style: { width: "12%" },
    },
    {
      key: "Status",
      label: "Status",
      _style: { width: "8%" },
    },
    {
      key: "Review",
      label: "Review",
      _style: { width: "8%" },
    },
    // {
    //   key: "MarksObtained",
    //   label: "Marks Obtained 01",
    //   _style: { width: "12%" },
    // },
    {
      key: "TotalMark",
      label: "Marks",
      _style: { width: "10%" },
    },

    { key: "Action", _style: { width: "12%" },filter: false, sorter: false,},
  ];

  const columns_SecondTabel_Assign_status = [
    // {
    //   key: "No",
    //   label: "S.no",
    //   _style: { width: "6%" },
    // },
    {
      key: "SubjectName",
      label: "Subject Name",
      _style: { width: "12%" },
    },
    {
      key: "assignmentName",
      label: "Assignment Name",
      _style: { width: "15%" },
    },
    {
      key: "Assignedby",
      label: "Assigned by",
      _style: { width: "10%" },
    },
    {
      key: "DateofSubmission",
      label: "Date of Submission",
      _style: { width: "15%" },
    },
    {
      key: "submittedDate",
      label: "Submitted Date",
      _style: { width: "12%" },
    },
    {
      key: "Status",
      label: "Status",
      _style: { width: "12%" },
    },
    {
      key: "Review",
      label: "Review",
      _style: { width: "8%" },
    },
    // {
    //   key: "MarksObtained",
    //   label: "Marks Obtained",
    //   _style: { width: "12%" },
    // },
    {
      key: "TotalMark",
      label: "Marks",
      _style: { width: "10%" },
    },
  ];
  

  const columns_ThirdTabel_Assign_status = [
    {
      key: "No",
      label: "S.No",
      _style: { width: "6%" },
    },
    {
      key: "Question",
      label: "List of Question and Answer",
      _style: { width: "65%" },
    },
    // {
    //   key: "correctAnswer",
    //   label: "Student Answer",
    //   _style: { width: "30%" },
    // },
    {
        key: "MarksObtained",
        label: "Marks Obtained",
        _style: { width: "15%" },
      },
    {  key: "comment", 
       label: "Comment", 
       _style: { width: "15%" } },
  ];

  const QuestionAnswer_Report = [
    {
      key: "S_no",
      label: "S.No",
      _style: { width: "5%" },
    },
    {
      key: "Question",
      label: "List of Question and Answer",
      _style: { width: "70%" },
    },
    // {
    //   key: "correctAnswer",
    //   label: "Answer",
    //   _style: { width: "25%" },
    // },
    {
      key: "obtainedMark",
      label: "Marks Obtained",
      _style: { width: "12%" },
    },
    {
      key: "comment",
      label: "Comment",
      _style: { width: "12%" },
    },
  ];
  
  const QuestionAnswer_Report_Otherstudent = [
    {
      key: "S_no",
      label: "S.No",
      _style: { width: "8%" },
    },
    {
      key: "Question",
      label: "List of Question and Answer",
      _style: { width: "70%" },
    },
    {
      key: "obtainedMark",
      label: "Marks Obtained",
      _style: { width: "12%" },
    },
    {
      key: "comment",
      label: "Comment",
      _style: { width: "12%" },
    },
  ];


  const columnsss = [
    {
      key: "No",
      label: "No",
      _style: { width: "5%" },
    },
    {
      key: "NameofStudent",
      label: "Name of the Student",
      _style: { width: "15%" },
    },
    {
      key: "SubmittedDate",
      label: "Submitted Date",
      _style: { width: "12%" },
    },
    {
      key: "Status",
      label: "Status",
      _style: { width: "10%" },
    },
    {
      key: "MarksObtained",
      label: "Marks Obtained",
      _style: { width: "12%" },
    },
    {
      key: "Action",
      _style: { width: "10%" },
    },
  ];

  const columnquet = [
    {
      key: "id",
      label: "No",
      filter: false,
      sorter: false,
      _style: { width: "10%" },
    },
    {
      key: "name",
      label: "Question",
      filter: true,
      sorter: true,
      _style: { width: "25%" },
    },
    {
      key: "role",
      label: "Answer",

      filter: true,
      sorter: true,
      _style: { width: "25%" },
    },
    {
      key: "status",
      label: "Marks",

      _style: { width: "20%" },
      filter: true,
      sorter: true,
    },
  ];
 
  const usersData1lquest = [
    {
      id: 1,
      name: "What is your name ?",
      role: "My name is Jai Balaji",
      status: "100",
    },
    {
      id: 2,
      name: "What is your company name ?",
      role: "My company name is Kpost Software pvt ltd",
      status: "100",
    },
    {
      id: 3,
      name: "What is your Designation  ?",
      role: "My designation is Software Developer",
      status: "100",
    },
  ];

  const columnsssassi = [
    {
      key: "No",
      label: "Subject",
      _style: { width: "15%" },
    },
    {
      key: "NameofStudent",
      label: "Assignment Name01",
      _style: { width: "13%" },
    },
    {
      key: "SubmittedDate",
      label: "Assigned By",
      _style: { width: "12%" },
    },
    {
      key: "Status",
      label: "Date of Submission",
      _style: { width: "13%" },
    },
    {
      key: "submitday",
      label: "Submitted Date",
      _style: { width: "12%" },
    },
    {
      key: "total",
      label: "Total Marks",
      _style: { width: "12%" },
    },
    {
      key: "MarksObtained",
      label: "Marks Obtained",
      _style: { width: "12%" },
    },
  ];

  const usersData111asi = [
    {
      No: "Physics",
      NameofStudent: "Assignment 1",
      SubmittedDate: "Girija Balaji",
      Status: "23-04-2021",
      submitday: "19-04-2021",
      total: "25",
      MarksObtained: "20",
    },
  ];

  const usersData111 = [
    {
      No: 1,
      NameofStudent: "Anbalagan",
      SubmittedDate: "12.03.2023",
      Status: "Reviewed",
      submitday: "fdf",
      total: "fdf",
      MarksObtained: "25",
    },
    {
      No: 2,
      NameofStudent: "Bala Krishna",
      SubmittedDate: "12.03.2001",
      Status: "To Review",
      MarksObtained: "NA",
    },
    {
      No: 3,
      NameofStudent: "karthick",
      SubmittedDate: "31.12.2015",
      Status: "To Review",
      MarksObtained: "NA",
    },
    {
      No: 4,
      NameofStudent: "Prathap",
      SubmittedDate: "15.01.2022",
      Status: "NA",
      MarksObtained: "NA",
    },
  ];

  const assignmentData_2_Assign_status = [
    {
      No: 1,
      Subject: "Physics0000",
      AssignmentName: "Assignment 1",
      Assignedby: "Rajesh",
      DateofSubmission: "15.11.2015",
      SubmittedDate: "15.01.2022",
      Status: "Submitted",
      TotalMark: "54",
      MarksObtained: "95",
    },
  ];

  const assignmentData_3_Assign_status = [
    {
      No: 1,
      Question: "What is your name ?",
      Answer: "My name is Jai Balaji",
      Mark: "85",
    },
    {
      No: 2,
      Question: "What is your company name ?",
      Answer: "My company name is Kpost Software pvt ltd",
      Mark: "74",
    },
    {
      No: 3,
      Question: "What is your Designation ?",
      Answer: "My designation is Software Developer",
      Mark: "95",
    },
  ];


  // Assignment Status Sample Data ........... END ...........

  // Assignment Report Sample Data ............START ...........


  const student_Table_NameList = [
    {
      key: "No",
      label: "S.No",
      filter: true,
      sorter: true,
      _style: { width: "7%" },
    },
    {
      key: "Name",
      label: "Name of the Student",
      filter: true,
      sorter: true,
      _style: { width: "20%" },
    },
    {
      key: "studentPostedDate",
      label: "Submitted Date",
      filter: true,
      sorter: true,
      _style: { width: "20%" },
    },
    {
      key: "studentTotalMarks",
      label: "Student Total Marks",
      _style: { width: "20%" },
      filter: true,
      sorter: true,
    },
    { key: "Action", _style: { width: "10%" } },
  ];


  const Assignment_Report_table = [
    // {
    //   key: "No",
    //   label: "S.no",
    //   _style: { width: "5%" },
    // },
    {
      key: "SubjectName",
      label: "Subject",
      _style: { width: "8%" },
    },
    {
      key: "Assignment",
      label: "Assignment Name",
      _style: { width: "12%" },
    },
    {
      key: "Assignedby",
      label: "Assigned by",
      _style: { width: "10%" },
    },
    {
      key: "lsd",
      label: "Date of Submission",
      _style: { width: "12%" },
    },
    {
      key: "submittedDate",
      label: "Submitted Date",
      _style: { width: "12%" },
    },
    {
      key: "TotalMarks",
      label: "Marks",
      _style: { width: "10%" },
    },
    // {
    //   key: "MarksObtained",
    //   label: "Marks Obtained",
    //   _style: { width: "10%" },
    // },
    // {
    //   key: "Status",
    //   label: "Status",
    //   _style: { width: "10%" },
    // },
  ];


  // Assignment Report Sample Data ............START ...........

  // Raj END -------------------------------
  return (
    <div onClick={() => disableOnOuterTouch()}>
      {/* Header Section Starts  */}
      <div className="Navbar">
        <Header
          showProfile={showProfile}
          falseShowProfile={falseShowProfile}
          notofShowProfile={notofShowProfile}
          ShowNotifications={ShowNotifications}
          showNotification={showNotification}
          falseShowNotification={falseShowNotification}
        />
      </div>

      {/* Header Section Ends */}

      <div className={"Dash_home_QWI"}>
        <div
          className="myattendcard2w p-3"
          style={{
            height: window.innerHeight - 117 + "px",
            overflowY: "auto",
          }}
        >
          {/* Content Starts */}
          <Fragment>
            {/* Raj - Start---------------------------- */}
            
            <div className="container-fluid px-5 py-3">
              <div className="textleft">
              {titleStatus && (
                <KHeader 
                title={`Student Assignment Status - ${moment().format("DD MMM YYYY")}` }
               // containerClassName={`marginTop-20 justify-content-front`}
               LastElement={<div></div>}
             />
              )}

               {titleReport && (
                <KHeader 
                title={`Student Assignment Report - ${moment().format("DD MMM YYYY")}` }
               // containerClassName={`marginTop-20 justify-content-front`}
               LastElement={<div></div>}
             />
              )}

              
              </div>
                  
              {breadcrumAs && (
                <div >
                  <CBreadcrumb>
                    <CBreadcrumbItem
                      active
                      onClick={() => {
                        setAssignmentReport(false);
                        setAssignment_Status(false);
                        setAssign_Btn(true);
                        setMainTable(false);
                        setSubTable(false);
                        setSubject(false);
                      }}
                    >
                      Report
                    </CBreadcrumbItem>
                  </CBreadcrumb>
                </div>
              )}

              {breadcrumAs1 && (
                <div>
                  <CBreadcrumb>
                    <CBreadcrumbItem
                      onClick={() => {
                        setAssign_Btn(true);
                        setAssignmentReport(false);
                        setAssignment_Status(false);
                        setPhysics_Tabel(false);
                        setBreadcrumAs1(false);
                        setBreadcrumAs(true);
                        setSubject(false);
                        setTitleStatus(true);
                        setTitleReport(false);
                      }}
                    >
                      Report
                    </CBreadcrumbItem>
                    <CBreadcrumbItem active>Assignment Status</CBreadcrumbItem>
                  </CBreadcrumb>
                </div>
              )}

              {assign_Btn && (
                <div className="">
                  <div className="d-flex flex-column flex-md-row">
                    <KButton
                      value="Assignment Status "
                      className={`px-6 py-3 mr-3  ${
                        isBigScreen ? "w-25" : "w-100"
                      }`}
                      onClick={() => {
                        setAssignment_Status(!assignment_Stauts);
                        setAssignmentReport(false);
                        setPhysics_Tabel(false);
                        setAssig_tabel(false);
                        setAssign_Btn(false);
                        setBreadcrumAs1(true);
                        setBreadcrumAs(false);
                        setTitleStatus(true);
                        setTitleReport(false);
                      }}
                    />

                    <KButton
                      value="Assignment Report"
                      className={`px-6 py-3 mr-3 ${
                        isBigScreen ? "w-25" : "w-100 mt-2"
                      }`}
                      onClick={() => {
                        setAssignmentReport(!assignmentReport);
                        setAssignment_Status(false);
                        setPhysics_Tabel(false);
                        setAssign_Btn(false);
                        setBreadcrumAs(false);
                        setBreadcrumAr1(true);
                        setSubject(false);
                        setTitleStatus(false);
                        setTitleReport(true);
                      }}
                    />

                    <KButton
                      value="Attendance Report"
                      className={`px-6 py-3 mr-3 ${
                        isBigScreen ? "w-25" : "w-100 mt-2"
                      }`}
                      isDisabled={true}
                    />

                    <KButton
                      value="Katbook Coverage Report"
                      className={`px-6 py-3 ${
                        isBigScreen ? "w-25" : "w-100 mt-2"
                      }`}
                      isDisabled={true}
                    />
                  </div>
                </div>
              )}

              {breadcrumAs2 && (
                <div>
                  <CBreadcrumb>
                    <CBreadcrumbItem
                      onClick={() => {
                        setAssign_Btn(true);
                        setBreadcrumAs(true);
                        setAssignmentReport(false);
                        setAssignment_Status(false);
                        setPhysics_Tabel(false);
                        setBreadcrumAs1(false);
                        setBreadcrumAs2(false);
                      }}
                    >
                      Report
                    </CBreadcrumbItem>
                    <CBreadcrumbItem
                      onClick={() => {
                        setAssign_Btn(false);
                        setAssignmentReport(false);
                        setAssignment_Status(true);
                        setPhysics_Tabel(false);
                        setBreadcrumAs(false);
                        setBreadcrumAs1(true);
                        setBreadcrumAs2(false);
                      }}
                    >
                      Assignment Status
                    </CBreadcrumbItem>
                    <CBreadcrumbItem active>
                      Subjects
                    </CBreadcrumbItem>
                    {/* {asstTablesSubName.map((x, e) => (
                      <CBreadcrumbItem active>{x.SubjectName}</CBreadcrumbItem>
                    ))} */}

                  </CBreadcrumb>
                </div>
              )}

              {assignment_Stauts && (
                <div className="d-flex flex-row w-100 flex-md-row mt-3 gap-3">
                  {/* {subjectsData.map((x, e) => ( */}

                  
                  {asstTablesSubName.map((x, e) => (
                    
                    <div key={e}>
                      <KButton
                        className={`px-6 py-3 mr-3  
                          w-100
                        `}
                        style={{
                          width: "100%",
                          paddingLeft: "3rem",
                          paddingRight: "3rem",
                        }}
                        value={x.SubjectName}

                        onClick={() => {
                          setPhysics_Tabel(!physics_tabel);
                          setAssignment_Status(false);
                          setBreadcrumAs(false);
                          setBreadcrumAs1(false);
                          setBreadcrumAs2(true);
                          Student_Assignment_Status_Table(x.kamSubjectId, x.userId);
                         
                        }}
                      />
                    </div>
                  ))}
                </div>
              )}

              {physics_tabel && (
                <div>
                  <div className="dd-flex flex-column pl-3 pr-3 tabelstyle ">
                    <CSmartTable
                      items={asstTables}
                      columnFilter
                      columnSorter
                      itemsPerPage={5}
                      itemsPerPageSelect={asstTables.length > 5}
                      pagination
                      columns={columns_FirstTabel_Assign_status}
                      scopedColumns={{
                        Action: (item) => (
                          <td>
                            {userscredsData ? (
                                <button
                                  type="button"
                                  className=" clickmeBtn"
                                  title="Delete"
                                  onClick={() => {
                                    setAssig_tabel(false);
                                    setPhysics_Tabel(false);
                                    setBreadcrumAs(false);
                                    setBreadcrumAs1(false);
                                    setBreadcrumAs2(false);
                                    setBreadcrumAs3(true);
                                    setBreadcrumAs4(false);
                                    Student_Status_Question(item.assignmentId);
                                                                     
                                    Student_Status_Answeronly(item.assignmentId, studentID);
                                    setAssignmentSecondData([{
                                      No:item.No,
                                      assignmentId:item.assignmentId,
                                      SubjectName:item.SubjectName,
                                      subBId:item.subBId,
                                      assignmentName:item.assignmentName,
                                      Assignedby: item.Assignedby,
                                      TotalMark: item.TotalMark,
                                      MarksObtained:item.MarksObtained,
                                      Status:item.Status,
                                      submittedDate:item.submittedDate,
                                      DateofSubmission:item.DateofSubmission,
                                      Review:item.Review,
                                    }])


                                    // filterQA()
                                    // filterQuestionandAnswer(answer.answerId, answer.QuestionId)
                                  
                                  }}
                                >
                                  View 
                                  {/* <i className="fa fa-trash-o" /> */}
                                </button>
                            ) : (
                              <button
                                type="button"
                                className=" clickmeBtn "
                                title="Delete"
                                onClick={() => {
                                  // setTeacherTable(!teacherTable);
                                  setAssig_tabel(false);
                                  setPhysics_Tabel(false);
                                  setBreadcrumAs(false);
                                  setBreadcrumAs1(false);
                                  setBreadcrumAs2(false);
                                  setBreadcrumAr3(false);
                                  setBreadcrumAs4(true);
                                }}
                              >
                                Teacher - View
                                {/* <i className="fa fa-trash-o" /> */}
                              </button>
                            )}
                            &nbsp;
                          </td>
                        ),
                        Action1: (item) => <td></td>,
                      }}
                      tableProps={{
                        hover: true,
                        responsive: true,
                      }}
                    />
                  </div>
                </div>
              )}

              {breadcrumAs3 && (
                <div>
                  <CBreadcrumb>
                    <CBreadcrumbItem
                      onClick={() => {
                        setAssign_Btn(true);
                        setAssignmentReport(false);
                        setAssignment_Status(false);
                        setAssig_tabel(false);
                        setBreadcrumAs(true);
                        setBreadcrumAs1(false);
                        setBreadcrumAs2(false);
                        setBreadcrumAs3(false);
                        setBreadcrumAs4(false);
                      }}
                    >
                      Report
                    </CBreadcrumbItem>
                    <CBreadcrumbItem
                      onClick={() => {
                        setAssign_Btn(false);
                        setAssignmentReport(false);
                        setAssignment_Status(true);
                        setAssig_tabel(false);
                        setBreadcrumAs1(true);
                        setBreadcrumAs2(false);
                        setBreadcrumAs3(false);
                        setPhysics_Tabel(false);
                        setBreadcrumAs4(false);
                      }}
                    >
                      Assignment Status
                    </CBreadcrumbItem>
                    <CBreadcrumbItem
                      onClick={() => {
                        setAssign_Btn(false);
                        setAssignmentReport(false);
                        setAssignment_Status(false);
                        setAssig_tabel(false);
                        setPhysics_Tabel(true);
                        setBreadcrumAs1(false);
                        setBreadcrumAs2(true);
                        setBreadcrumAs3(false);
                        setBreadcrumAs4(false);
                      }}
                    >
                       <CBreadcrumbItem style={{paddingLeft:"15px"}}>
                      Subjects
                    </CBreadcrumbItem>
                      {/* {asstTablesSubName.map((x, e) => (
                      <CBreadcrumbItem style={{paddingLeft:"15px"}}>{x.SubjectName}</CBreadcrumbItem>
                      ))} */}

                    </CBreadcrumbItem>
                    <CBreadcrumbItem active>Question and Answer </CBreadcrumbItem>
                  </CBreadcrumb>

                  <div className="d-flex justify-content-end mt-3">
                    <button
                      className="backBtn"
                      onClick={() => {
                        setAssign_Btn(false);
                        setAssignmentReport(false);
                        setAssignment_Status(false);
                        setAssig_tabel(false);
                        setPhysics_Tabel(true);
                        setBreadcrumAs1(false);
                        setBreadcrumAs2(true);
                        setBreadcrumAs3(false);
                      }}
                    >
                      Back
                    </button>
                  </div>

                  <div className="d-flex flex-column mt-2 pl-3 pr-3 tabelstyle ">
                    <CSmartTable
                      items={assignmentSecondData}
                      // columnSorter
                      
                      // itemsPerPage={5}
                      // itemsPerPageSelect={assignmentSecondData.length > 5}
                      // pagination
                      columns={columns_SecondTabel_Assign_status}
                      scopedColumns={{
                        Action: (item) => (
                          <tr>
                            <td></td>
                          </tr>
                        ),
                      }}
                      tableProps={{
                        hover: true,
                      }}
                    />
                  </div>
                  
                  {/* <div className="titleQA mt-4">
                    <h4>Question and Answer </h4>
                    <span></span>
                  </div> */}
                  <div className="mt-5 d-flex justify-content-between">
                    <h4>Question and Answer </h4>
                   
                    <h5 className="mr-3">
                    {/* Total Marks :{question.map((x)=>x.studentMark)} */}
                    Total Marks : {totalmarks}
                    
                    </h5>
                  </div>
                  <div className="d-flex flex-column pl-3 pr-3 tabelstyle ">
                    <CSmartTable
                      items={question}
                      columnSorter
                      columnFilter
                      itemsPerPage={5}
                      itemsPerPageSelect={question.length > 5}
                      pagination
                      columns={columns_ThirdTabel_Assign_status}
                      scopedColumns={{
                        // Question : (item) =>(<td className="textalign">{parse(item.Question)}</td>),

                        Question : (item) =>(<td className="textalign">
                        
                       <span><b>Question :</b>{parse(item.Question)}</span>
                       <div>
     
                                {(
                                  item.answerType === "RadioButton" && 
                                  <span className="optionName"> 

                                  {parse(item.option)}
                                </span>

                                )}
                            </div>

                       <span><b>Correct Answer :</b> {parse(item.correctAnswer)}</span>
                       <span >
                       <b>Student Answer : </b> {parse(item.studentAnswer)} </span>
                       {/* style={ item.studentAnswer === null ? { color:'red'} : {color : '#000'} } */}
                     </td>),
                     MarksObtained: (item) => (
                      <td>
                        {/* {JSON.stringify(item)} */}
                        {/* {question.find(data => data.QuestionId === item.questionId)?.MarksObtained
                          ?
                          parse(question.find(data => data.QuestionId === item.questionId)?.MarksObtained)
                          : <p style={{color:"red"}}>Teacher Not Review</p>
                        } */}
                        {item.MarksObtained}

                      </td>
                    ),
                    comment: (item) => (
                      <td>
                        {/* {JSON.stringify(item)} */}
                        {item.comment}
                        {/* {question.find(data => data.QuestionId === item.questionId)?.comment
                          ?
                          parse(question.find(data => data.QuestionId === item.questionId)?.comment)
                          : <p style={{color:"red"}}>-</p>
                        } */}
                      </td>
                    ),

                      }}
                      tableProps={{
                        hover: true,
                        responsive: true,
                      }}
                    />
                  </div>
                </div>
              )}

              {assig_tabel && (
                <div>
                  <div className="d-flex justify-content-end mt-3">
                    <button
                      className="backBtn"
                      onClick={() => {
                        setPhysics_Tabel(true);
                        setAssignment_Status(false);
                        setAssig_tabel(false);
                      }}
                    >
                      Back
                    </button>
                  </div>

                  <div className="d-flex flex-column pl-3 pr-3 tabelstyle ">
                    <CSmartTable
                      items={assignmentData_2_Assign_status}
                      // columnSorter
                      // pagination
                      // itemsPerPage={5}
                      // itemsPerPageSelect={assignmentData_2_Assign_status.length > 5}
                      columns={columns_SecondTabel_Assign_status}
                      scopedColumns={{
                        Action: (item) => (
                          <tr>
                            <td></td>
                          </tr>
                        ),
                      }}
                      tableProps={{
                        hover: true,
                        responsive: true,
                      }}
                    />
                  </div>
                  <div className="titleQA mt-4">
                    <p>Question and Answer</p>
                  </div>
                  <div className="d-flex flex-column pl-3 pr-3 tabelstyle ">
                    <CSmartTable
                      items={assignmentData_3_Assign_status}
                      columnSorter
                      columnFilter
                      
                      itemsPerPage={5}
                      itemsPerPageSelect={assignmentData_3_Assign_status.length > 5}
                      pagination
                      columns={columns_ThirdTabel_Assign_status}
                      scopedColumns={{
                        Action: (item) => (
                          <td>
                            <button
                              type="button"
                              className=" clickmeBtn "
                              title="Delete"
                              onClick={() => {
                                setVisible(!visible);
                              }}
                            >
                              Click
                              {/* <i className="fa fa-trash-o" /> */}
                            </button>
                          </td>
                        ),
                      }}
                      tableProps={{
                        hover: true,
                        responsive: true,
                      }}
                    />
                  </div>
                </div>
              )}
              <>
                <CModal
                  alignment="center"
                  visible={visible}
                  onClose={() => setVisible(false)}
                  className="ModalHeight"
                >
                  <div
                    style={{
                      backgroundColor: "white",
                      padding: "10px 30px 30px 30px",
                      borderRadius:"5px",
                   }}
                  >
                    <CModalHeader></CModalHeader>
                    <CModalBody className="pt-2 pb-5 pr-4 pl-4">
                      Lorem ipsum dolor sit amet. Ad expedita ullam aut incidunt
                      enim sit officiis voluptatem quo laudantium unde. Ex
                      dolorem autem et minus voluptates nam incidunt consequatur
                      in adipisci cumque
                    </CModalBody>
                  </div>
                </CModal>
              </>
{/* Assignment Status ......END */}

              {breadcrumAs4 && (
                <div >
                  <CBreadcrumb>
                    <CBreadcrumbItem
                      onClick={() => {
                        setAssign_Btn(true);
                        setAssignmentReport(false);
                        setAssignment_Status(false);
                        setAssig_tabel(false);
                        setBreadcrumAs(true);
                        setBreadcrumAs1(false);
                        setBreadcrumAs2(false);
                        setBreadcrumAs4(false);
                        setBreadcrumAs3(false);
                      }}
                    >
                      Report
                    </CBreadcrumbItem>
                    <CBreadcrumbItem
                      onClick={() => {
                        setAssign_Btn(false);
                        setAssignmentReport(false);
                        setAssignment_Status(true);
                        setAssig_tabel(false);
                        setBreadcrumAs1(true);
                        setBreadcrumAs2(false);
                        setBreadcrumAs3(false);
                        setBreadcrumAs4(false);
                        setPhysics_Tabel(false);
                      }}
                    >
                      Assignment Status
                    </CBreadcrumbItem>
                    <CBreadcrumbItem
                      onClick={() => {
                        setAssign_Btn(false);
                        setAssignmentReport(false);
                        setAssignment_Status(false);
                        setAssig_tabel(false);
                        setPhysics_Tabel(true);
                        setBreadcrumAs1(false);
                        setBreadcrumAs2(true);
                        setBreadcrumAs3(false);
                        setBreadcrumAs4(false);
                      }}
                    >
                      Physics
                    </CBreadcrumbItem>
                    <CBreadcrumbItem active>Assignment</CBreadcrumbItem>
                  </CBreadcrumb>

                  <div className="d-flex justify-content-end mt-3 mb-3">
                    <button
                      className="backBtn"
                      onClick={() => {
                        setBreadcrumAs4(false);
                        setPhysics_Tabel(true);
                        setBreadcrumAs2(true);
                      }}
                    >
                      Back
                    </button>
                  </div>

                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <KHeader
                      containerClassName={
                        "align-items-center borderColor marginLeft-0 w-auto"
                      }
                      frontElement={
                        <div className="d-flex gap-4">
                          <div> Total : 35 </div>
                          <div> Submit : 30 </div>
                          <div> UnSubmit : 5 </div>
                          <div> Review : 20 </div>
                        </div>
                      }
                    />
                  </div>

                  <div
                    className="mx-5 my-3"
                    style={{ textAlign: "center", border: "1px solid #dadbdd" }}
                  >
                    <CSmartTable
                      columns={columnsss}
                      columnFilter
                      columnSorter
                      items={usersData111}
                      itemsPerPage={5}
                      itemsPerPageSelect={usersData111.length > 5}
                      pagination
                      scopedColumns={{
                        Action: (item) => (
                          <td>
                            <button
                              type="button"
                              className=" clickmeBtn "
                              onClick={() => {
                                setAssig_tabel(false);
                                setPhysics_Tabel(false);
                                setBreadcrumAs(false);
                                setBreadcrumAs1(false);
                                setBreadcrumAs2(false);
                                setBreadcrumAr3(false);
                                setBreadcrumAs4(false);
                                setBreadcrumAs5(true);
                              }}
                            >
                              Click
                              {/* <i className="fa fa-trash-o" /> */}
                            </button>
                          </td>
                        ),
                      }}
                      tableProps={{
                        striped: true,
                        hover: true,
                        responsive: true,
                      }}
                    />
                  </div>
                </div>
              )}
              {breadcrumAs5 && (
                <div>
                  <CBreadcrumb>
                    <CBreadcrumbItem
                      onClick={() => {
                        setAssign_Btn(true);
                        setAssignmentReport(false);
                        setAssignment_Status(false);
                        setAssig_tabel(false);
                        setBreadcrumAs(true);
                        setBreadcrumAs1(false);
                        setBreadcrumAs2(false);
                        setBreadcrumAs4(false);
                        setBreadcrumAs3(false);
                        setBreadcrumAs5(false);
                      }}
                    >
                      Report
                    </CBreadcrumbItem>
                    <CBreadcrumbItem
                      onClick={() => {
                        setAssign_Btn(false);
                        setAssignmentReport(false);
                        setAssignment_Status(true);
                        setAssig_tabel(false);
                        setBreadcrumAs1(true);
                        setBreadcrumAs2(false);
                        setBreadcrumAs3(false);
                        setBreadcrumAs4(false);
                        setBreadcrumAs5(false);
                        setPhysics_Tabel(false);
                      }}
                    >
                      Assignment Status
                    </CBreadcrumbItem>
                    <CBreadcrumbItem
                      onClick={() => {
                        setAssign_Btn(false);
                        setAssignmentReport(false);
                        setAssignment_Status(false);
                        setAssig_tabel(false);
                        setPhysics_Tabel(true);
                        setBreadcrumAs1(false);
                        setBreadcrumAs2(true);
                        setBreadcrumAs3(false);
                        setBreadcrumAs4(false);
                        setBreadcrumAs5(false);
                      }}
                    >
                      Physics
                    </CBreadcrumbItem>
                    <CBreadcrumbItem active>Assignment</CBreadcrumbItem>
                  </CBreadcrumb>

                  <div className="d-flex justify-content-end mt-3 mb-3">
                    <button
                      className="backBtn"
                      onClick={() => {
                        setBreadcrumAs4(true);
                        setPhysics_Tabel(false);
                        setBreadcrumAs2(false);
                        setBreadcrumAs5(false);
                      }}
                    >
                      Back
                    </button>
                  </div>

                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <KHeader
                      title="Assignment Report for Anbalagan"
                      style={{
                        marginLeft: "0px !important",
                      }}
                    />
                  </div>

                  <div
                    className="mx-5 my-3"
                    style={{
                      textAlign: "center",
                      border: "1px solid #dadbdd",
                    }}
                  >
                    <CSmartTable
                      columns={columnsssassi}
                      columnFilter
                      columnSorter
                      items={usersData111asi}
                      itemsPerPage={5}
                      itemsPerPageSelect={usersData111asi.length > 5}
                      pagination
                      tableProps={{
                        striped: true,
                        hover: true,
                        responsive: true,
                      }}
                    />
                  </div>

                  <div className="mt-4 mx-3">
                    {" "}
                    <KHeader
                      title="Question & Answers"
                      style={{
                        marginLeft: "0px !important",
                      }}
                    />
                  </div>
                  <div
                    className="mx-5"
                    style={{ textAlign: "center", border: "1px solid #dadbdd" }}
                  >
                    <CSmartTable
                      columns={columnquet}
                      columnFilter
                      columnSorter
                      items={usersData1lquest}
                      tableProps={{
                        striped: true,
                        hover: true,
                        responsive: true,
                      }}
                    />
                  </div>
                </div>
              )}

              {/* ------------END  - Raj-------------------------- */}
            
            
             {/* Assignment Report Screen Page start */}

              {breadcrumAr1 && (
                <div>
                  <CBreadcrumb>
                    <CBreadcrumbItem
                      onClick={() => {
                        setAssign_Btn(true);
                        setAssignmentReport(false);
                        setAssignment_Status(false);
                        setBreadcrumAr1(false);
                        setPhysics_Tabel(false);
                        setBreadcrumAs1(false);
                        setBreadcrumAs2(false);
                        setBreadcrumAs(true);
                      }}
                    >
                      Report
                    </CBreadcrumbItem>

                    <CBreadcrumbItem active>Assignment Report </CBreadcrumbItem>
                  </CBreadcrumb>
                </div>
              )}
              {breadcrumAr2 && (
                <div>
                  <CBreadcrumb>
                    <CBreadcrumbItem
                      onClick={() => {
                        setAssign_Btn(true);
                        setAssignmentReport(false);
                        setAssignment_Status(false);
                        setBreadcrumAr1(false);
                        setPhysics_Tabel(false);
                        setBreadcrumAs1(false);
                        setBreadcrumAs2(false);
                        setBreadcrumAs(true);
                        setBreadcrumAr1(false);
                        setBreadcrumAr2(false);
                        setBreadcrumAs4(false);
                        setSubject(false);
                        setReport(false);
                      }}
                    >
                      Report
                    </CBreadcrumbItem>

                    <CBreadcrumbItem
                      onClick={() => {
                        setAssign_Btn(false);
                        setAssignmentReport(true);
                        setAssignment_Status(false);
                        setBreadcrumAr1(true);
                        setBreadcrumAr2(false);
                        setPhysics_Tabel(false);
                        setBreadcrumAs1(false);
                        setBreadcrumAs2(false);
                        setBreadcrumAs4(false);
                        setBreadcrumAs(true);
                        setBreadcrumAs(false);
                        setSubject(false);
                        setReport(false);
                      }}
                    >
                      Assignment Report 
                    </CBreadcrumbItem>
                    <CBreadcrumbItem active>
                      Subjects
                    </CBreadcrumbItem>
                    {/* {asstTablesSubName.map((x, e) => (
                      <CBreadcrumbItem active>{x.SubjectName}</CBreadcrumbItem>
                    ))} */}
                    
                  </CBreadcrumb>
                </div>
              )}

              {breadcrumAr3 && (
                <div>
                  <CBreadcrumb>
                    <CBreadcrumbItem
                      onClick={() => {
                        setAssign_Btn(true);
                        setAssignmentReport(false);
                        setAssignment_Status(false);
                        setBreadcrumAr1(false);
                        setPhysics_Tabel(false);
                        setBreadcrumAs1(false);
                        setBreadcrumAs2(false);
                        setBreadcrumAs(true);
                        setBreadcrumAr1(false);
                        setBreadcrumAr2(false);
                        setBreadcrumAr3(false);
                        setBreadcrumAs4(false);
                        setSubject(false);
                        setSubjectReport(false);
                        setMainTable(false);
                        setSubTable(false);
                        setSubTableBox(false);
                        setReport(false);
                      }}
                    >
                      Report
                    </CBreadcrumbItem>

                    <CBreadcrumbItem
                      onClick={() => {
                        setAssign_Btn(false);
                        setAssignmentReport(true);
                        setAssignment_Status(false);
                        setBreadcrumAr1(true);
                        setBreadcrumAr2(false);
                        setPhysics_Tabel(false);
                        setBreadcrumAs1(false);
                        setBreadcrumAs2(false);
                        setBreadcrumAs(true);
                        setBreadcrumAs(false);
                        setSubject(false);
                        setSubject(false);
                        setSubjectReport(false);
                        setBreadcrumAr3(false);
                        setBreadcrumAs4(false);
                        setMainTable(false);
                        setSubTable(false);
                        setSubTableBox(false);
                        setReport(false);
                      }}
                    >
                      Assignment Report 
                    </CBreadcrumbItem>
                    <CBreadcrumbItem
                      onClick={() => {
                        setAssign_Btn(false);
                        setAssignmentReport(false);
                        setAssignment_Status(false);
                        setBreadcrumAr1(false);
                        setBreadcrumAr2(false);
                        setPhysics_Tabel(false);
                        setBreadcrumAs1(false);
                        setBreadcrumAs(false);
                        setBreadcrumAs(false);
                        setReport(false);
                        setSubject(true);
                        setSubjectReport(false);
                        setBreadcrumAr3(false);
                        setBreadcrumAs4(false);
                        setBreadcrumAr2(true);
                        setMainTable(false);
                        setSubTable(false);
                        setSubTableBox(false);
                      }} 
                    >
                      <CBreadcrumbItem style={{paddingLeft:"15px"}}>
                      Subjects
                    </CBreadcrumbItem>
                      {/* {asstTablesSubName.map((x, e) => (
                      <CBreadcrumbItem style={{paddingLeft:"15px"}}>{x.SubjectName}</CBreadcrumbItem>
                      ))} */}

                    
                    </CBreadcrumbItem>

                    <CBreadcrumbItem active>Assignment </CBreadcrumbItem>
                  </CBreadcrumb>
                </div>
              )}

              {assignmentReport && (
                <>
                  <div className="d-flex flex-row w-100 flex-md-row mt-3 gap-3">
                    {asstTablesSubName.map((x, e) => (
                      <div key={e}>
                        <KButton
                          className={`px-6 py-3 mr-3  
                          w-100
                        `}
                          style={{
                            width: "100%",
                            paddingLeft: "3rem",
                            paddingRight: "3rem",
                          }}
                          value={x.SubjectName}
                          onClick={() => {
                            setAssignmentReportIO(false);
                            setAssignmentReport(false);
                            setSubject(true);
                            setSubjectReport(false);
                            setBreadcrumAr1(false);
                            setBreadcrumAr2(true);
                            Student_Assignment_Status_Table(x.kamSubjectId, x.userId);
                            Student_Assignment_Report(x.value, x.userId)
                            
                            //Report_Student_Assignment_Table_Second(x.value, x.userId);
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </>
              )}

              {/* {subject && (
                <div className="d-flex flex-row w-100 flex-md-row mt-3 gap-3">
                  <KButton
                    value={
                      <div
                        onClick={() => {
                          setAssignmentReportIO(false);
                          setAssignmentReport(false);
                          setBreadcrumAr3(true);
                          setBreadcrumAs4(false);
                          setBreadcrumAr2(false);
                          setSubject(false);
                          setSubjectReport(true);
                        }}
                      >
                        
                               {assignmentReportList.map((x, e) => (
                                        <div key={e}  >
                                          <KButton
                                          
                                            className={`px-6 py-3 mr-3  
                                            w-100
                                          `}
                                            style={{
                                              width: "100%",
                                              paddingLeft: "3rem",
                                              paddingRight: "3rem",
                                            }}
                                            // value={x.Assignment}
                                            onClick={() => {
                                              setAssignmentReportIO(false);
                                              setAssignmentReport(false);
                                              setSubject(true);
                                              setSubjectReport(false);
                                              setBreadcrumAr1(false);
                                              setBreadcrumAr2(true);
                                              
                                             
                                            }}
                                            
                                          />
                                          <div style={{ fontSize: "18px" }}>{x.Assignment}</div>
                                          <div style={{ fontSize: "13px" }}>Total Marks - {x.TotalMarks}</div>
                                          <div className="d-flex flex-row gap-3 justify-content-center">
                                          <div style={{ fontSize: "12px" }}>
                                            <u>Posted Date:</u> {x.PostedDate}
                                          </div>
                                          <div style={{ fontSize: "12px" }}>
                                            <u>Date of Submission</u> - {x.submittedDate}
                                          </div>
                                          </div>
                                        
                                        </div>
                                      ))}

                        
                      </div>
                    }
                   
                    
                  />
                 
                </div>
              )} */}
             
            {subject && (
              <div>
                <div className="flex-wrap d-flex flex-row w-100 flex-md-row mt-3 gap-3">
                  
                  {/* {assignmentReportList.map((x, e) => ( */}
                  {asstTablesSubNameRep.map((x, e) => (
                  
                  <div key={e}>
                      <KButton
                       className={`SubjectStyle NewstyleBox`}
                       value={
                        <div>
                        <div className="titless">{x.Assignment}</div>
                        <div className="SubTitless">{x.SubjectName}</div>
                        <div className="titleDown">

                          <span>Total Mark : <b>{x.TotalMarks}</b> </span><br />
                          <span>Last Date of Submission :<b> {x.lsd} </b></span>
                       
                       </div>                                                              
                       </div>}
                        // className={`px-6 py-3 mr-3  
                        //   w-80 
                        // `}
                        // style={{
                        //   width: "100%",
                        //   paddingLeft: "1rem",
                        //   paddingRight: "1rem",
                        // }}
                        // value={
                        //   <div>
                        //   <div style={{ fontSize: "16px",padding:"10px" }}>{x.Assignment}</div>
                        //   <div style={{ fontSize: "12px",paddingBottom:"15px" }}>Total Marks 01- {x.TotalMarks}</div>
                        //   <div className="d-flex flex-row gap-3 justify-content-center">
                        //   <div style={{ fontSize: "12px",paddingBottom:"15px" }}>
                        //     <u>Posted Date :</u> {x.submittedDate}
                        //   </div>
                        //   <div style={{ fontSize: "12px"}}>
                        //     <u>Last Date of Submission</u> - {x.lsd}

                        //   </div>
                        //   </div>
                        //   </div>
                        //   }
                        
                        onClick={() => {
                         
                          setAssignmentReportIO(false);
                          setAssignmentReport(false);
                          setSubject(false);
                          setSubjectReport(true);
                          setBreadcrumAr1(false);
                          setBreadcrumAr2(false);
                          setBreadcrumAr3(true);
                          setBreadcrumAs4(false);
                          setSubjectReport(true);
                          
                          // Student_Assignment_Report_Table(x.assignmentValue);
                          Student_Report_Question(x.assignmentValue)
                          Student_Report_Answeronly(x.assignmentValue,Report_studentId)                    
                          setAssignmentReportListData([{
                            No:x.No,
                            SubjectName:x.SubjectName,
                            Assignment:x?.Assignment,
                            Assignedby:x.Assignedby,
                            lsd:x.lsd,
                            TotalMarks:x?.TotalMarks,
                            submittedDate:x?.submittedDate,
                            studentId:x.studentId,
                            assignmentId:x.assignmentId,
                        }])
                        setAssignmentReportListData1({
                          
                          assignmentId:x.assignmentId,
                         
                      })
                      setAssignmentReportListData2({
                          
                        assignmentId:x.assignmentId,
                       
                    })
                        StudentName_List(x.sectionId, x.assignmentId)
                        
                        }}
                          
                      />
                    
                    </div>
                  ))}
                </div>
              </div>
              )}


              {subjectReport && (
                <>
                  <div className=" d-flex flex-column justify-content-start flex-md-row ">
                    
                    <KButton
                      value="My Report"
                      className={`px-6 py-3 mr-3 ${
                        isBigScreen ? "w-25" : "w-100 mt-2"
                      }`}
                      onClick={() => {
                        setMainTable(false);
                        setSubTable(false);
                        setSubTableBox(false);
                        setReport(true);
                        // Student_Assignment_Report_Table(x.assignmentValue);
                                                
                      }}
                    />

                    <KButton
                      value="Other Students Report"
                      className={`px-6 py-3 ${
                        isBigScreen ? "w-25" : "w-100 mt-2"
                      }`}
                      onClick={() => {
                        setMainTable(true);
                        setSubTable(true);
                        setSubTableBox(false);
                        setReport(false);
                        Student_Assignment_Report_ExtraAPI()
                      }}
                    />
                  </div>
                </>
              )}

              {mainTable && (
                <>
                  <div className="  mt-5 d-flex justify-content-between">
                    <h4>Other Student Assignment Report</h4>
                    <button
                      className="backBtn mb-2"
                      onClick={() => {
                        setMainTable(false);
                        setSubTable(false);
                      }}
                    >
                      Back
                    </button>
                  </div>
                  <div className="tabelstyle ">
                    <CSmartTable
                      columns={Assignment_Report_table}
                      // columnFilter
                      // columnSorter
                      itemsPerPage={5}
                      itemsPerPageSelect={assignmentReportListdata.length > 5}
                      pagination
                      items={assignmentReportListdata}
                      tableProps={{
                        striped: true,
                        hover: true,
                        responsive: true,
                      }}
                    />
                  </div>
                </>
              )}

              {subTable && (
                <>
                  <div className="d-flex justify-content-center mt-4 pl-5 pr-5">
                    <h4>All Report </h4>
                  </div>
                  <div className="d-flex justify-content-center pl-5 pr-5">
                    <div
                      className="tabelstyle"
                      style={{
                        width: "80%",
                      }}
                    >
                      <CSmartTable
                        columns={student_Table_NameList}
                        items={studentNameMatch.map((x,i)=>({
                          ...x,
                          No : i + 1,
                        }))}

                        // items={studentNameList}
                        // items={studentNameMatch}
                        
                        columnFilter
                        columnSorter
                        itemsPerPage={100}
                        // itemsPerPageSelect={studentNameList.length > 5}
                        // pagination
                        
                        scopedColumns={{
                         
                          // studentPostedDate: (item) => (
                            
                          //     <td >
                                
                          //       {studentNameList.find(data => data.studentId === item.studentId)?.studentPostedDate
                          //         ?
                          //         parse(studentNameList.find(data => data.studentId === item.studentId)?.studentPostedDate)
                          //         : "-"
                          //       }
                          //     </td>
                          //   ),
                          //   studentTotalMarks: (item) => (
                              
                          //       <td >
                          //         {studentNameList.find(data => data.studentId === item.studentId)?.studentTotalMarks
                          //           ?
                          //           parse(studentNameList.find(data => data.studentId === item.studentId)?.studentTotalMarks)
                          //           : "-"
                          //         }
                          //       </td>
                          //     ),
                          
                          Action: (item) => (
                            
                            <td>
                              <button
                                type="button"
                                className=" clickmeBtn "
                                title="Delete"
                                onClick={() => {
                                  setSubTableBox(true);
                                  setSubTable(false);
                                  setMainTable(false);
                                  setReport(false);
                                  StudentName_Report_Question_API(item.studentId)
                                }}
                              >
                                View 
                              </button>
                            </td>
                          ),
                        }}
                        tableProps={{
                          striped: true,
                          hover: true,
                          responsive: true,
                        }}
                      />
                    </div>
                  </div>
                  <br />
                </>
              )}

              {subTableBox && (
                <>
                  <div className="mt-5 d-flex justify-content-between">
                    <h4>Question and Answer</h4>
                    <h5 className="mr-3">Total Marks : {totalmarksOther}
                    </h5>
                    <button
                      className="backBtn mb-2"
                      onClick={() => {
                        setMainTable(true);
                        setSubTable(true);
                        setSubTableBox(false);
                      }}
                    >
                      Back
                    </button>
                  </div>
                  <div className="tabelstyle">
                    <CSmartTable
                      columns={QuestionAnswer_Report_Otherstudent}
                      columnFilter
                      columnSorter
                      itemsPerPage={5}
                      itemsPerPageSelect={questionReportName.length > 5}
                      pagination
                      items={questionReportName}
                      scopedColumns={{
                        Question : (item) =>(<td className="textalign">
                          
                          <span><b>Question :</b>{parse(item.Question)}</span>
                          <div>
     
                                 {(
                                   item.answerType === "RadioButton" && 
                                   <span className="optionName"> 
     
                                   {parse(item.option)}
                                 </span>
     
                                 )}
                             </div>

                          <span><b>Correct Answer :</b> {parse(item.correctAnswer)}</span>
                          <span>
                          <b>Student Answer :</b> {parse(item.studentAnswer)}</span>
                        </td>),}}
                      tableProps={{
                        striped: true,
                        hover: true,
                        responsive: true,
                      }}
                    />
                  </div>
                </>
              )}

              {report && (
                <>
                  <div className="mt-5 d-flex justify-content-between">
                    <h4>My Report </h4>
                    <button
                      className="backBtn mb-2"
                      onClick={() => {
                        setMainTable(false);
                        setSubTable(false);
                        setSubTableBox(false);
                        setReport(false);
                      }}
                    >
                      Back
                    </button>
                  </div>

                  <div
                    className="tabelstyle "
                    // style={{ textAlign: "center", border: "1px solid #dadbdd" }}
                  >
                    <CSmartTable
                      items={assignmentReportListdata}
                      // columnSorter
                     
                      // itemsPerPage={5}
                      // itemsPerPageSelect={assignmentReportListdata.length > 5}
                      // pagination
                      columns={Assignment_Report_table}
                      tableProps={{ hover: true, responsive: true }}
                    />
                  </div>

                 <div className="mt-5 d-flex justify-content-between">
                    <h4>Question and Answer</h4>
                   
                    <h5 className="mr-3">
                    {/* Total Marks :{question.map((x)=>x.studentMark)} */}
                    Total Marks : {totalmarksReport}
                    
                    </h5>
                  </div>

                  <div
                    className=""
                    style={{ textAlign: "center", border: "1px solid #dadbdd" }}
                  >
                    {/* report table */}
                    <CSmartTable
                      items={questionReport}
                      columnFilter
                      columnSorter
                      itemsPerPage={5}
                      itemsPerPageSelect={questionReport.length > 5}
                      pagination
                      columns={QuestionAnswer_Report}
                      scopedColumns={{
                        Question : (item) =>(<td className="textalign">
                          
                          <span><b>Question :</b>{parse(item.Question)}</span>
                          <div>
     
                              {(
                                item.answerType === "RadioButton" && 
                                <span className="optionName"> 

                                {parse(item.option)}
                              </span>

                              )}
                          </div>

                          <span><b>Correct Answer :</b> {parse(item.correctAnswer)}</span>
                          <span>
                          <b>Student Answer :</b> {parse(item.studentAnswer)}</span>
                        </td>),

                        comment: (item) => (
                          <td>
                            {/* {JSON.stringify(item)} */}
                            {/* {item.comment} */}
                            {questionReport.find(data => data.questionId === item.questionId)?.comment
                              ?
                              parse(questionReport.find(data => data.questionId === item.questionId)?.comment)
                              : <p style={{color:"red"}}>-</p>                           
                            }
                          </td>
                        ),
                        obtainedMark: (item) => (
                          <td>
                            
                            {/* {item.obtainedMark} */}
                            {questionReport.find(data => data.questionId === item.questionId)?.obtainedMark
                              ?
                              parse(questionReport.find(data => data.questionId === item.questionId)?.obtainedMark)
                              : <p style={{color:"red"}}>-</p>                           
                            }
                          </td>
                        ),
                      
                      }}

                    

                      tableProps={{ hover: true, responsive: true }}
                    />
                  </div>
                </>
              )}
              <CModal
                alignment="center"
                visible={visibleK}
                onClose={() => setVisibleK(false)}
                className="ModalHeight"
              >
                <div
                  style={{
                    backgroundColor: "white",
                    padding: "10px 30px 30px 30px",
                    borderRadius:"5px",
                  }}
                >
                  <CModalHeader></CModalHeader>

                  <div style={{ padding: "25px", border: "1px solid #c6c8cb" }}>
                    <div
                      className="d-flex flex-row gap-3 align-items-center pb-3"
                      style={{ borderBottom: "1px solid #efefef" }}
                    >
                      <div>
                        <img src={image} alt="" width={30} height={30} />
                      </div>
                      <div>
                        <span style={{ fontWeight: "600" }}>
                          Balasubramani Physics
                        </span>
                      </div>
                    </div>

                    <div className="mt-3">
                      <h6>Well tried. Answer can be more focused.</h6>
                    </div>
                  </div>
                </div>
              </CModal>
            </div>
          </Fragment>
          {/* Raj END------------- */}
          {/* Content Ends */}
        </div>
      </div>

      {/* Footer Section Starts */}
      <Footer
        openForm={openForm}
        shoq={shoq}
        closeForm={closeForm}
        KpostIframe={KpostIframe}
      />
      {/* Footer Section Ends */}
    </div>
  );
};

export default StudentCreateReport;
