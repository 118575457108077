import React,{useState,useEffect} from 'react'
import Header from "./HeaderNavbar";
import Footer from "./Footer";
import {getDynamicSubjects,getAllKatBookSubjects,getAllKatBookSubjectsCount, getClarifyCount} from "../../services/Apiservices"
import KatbookBooks from "./KatbookBooks";
import Subload from "../../Assets/subject_loading.gif";
import NoBooks from "../../Assets/No_books.png";
import { Authcontext } from "./Context/Context";
import { toast } from "react-toastify";
import Danger from "../components/Toastify/Danger";

const BookView = ({showProfile,
  falseShowProfile,
  notofShowProfile,
  ShowNotifications,
  showNotification,
  falseShowNotification,
  disableOnOuterTouch,
  shoq,
  KpostIframe,
  closeForm,
  openForm,
  goToMainRoutes,
  CheckTokenProfile
}) => {
    const { state, dispatch } = React.useContext(Authcontext);
    const [Units, setUnits] = useState(state?.userSubjects.length > 0 ? state?.userSubjects : []);
    const [userCredsvalues, setuserCredsvalues] = useState('');
    const [subfetchs, setSubfetchs] = useState(state?.userSubjects.length > 0 ? false : true);
    // console.log(state, "CONTEXT STORAGE");
    
    useEffect(() => {
        document.title = "Kampus Book View";
        if(localStorage.length > 0){
    
          let retrData = JSON.parse(localStorage.userCreds);
          setuserCredsvalues(retrData);

          const getfetchbooks = async () => {
          
            let allquesbod,allquestres,resSubjectsList;

            if(retrData.userType === "Teaching"){

            if(retrData?.classSubjectDetails.length > 0){

              let sessionList = [];
              retrData?.classSubjectDetails.filter(item => item.katbookSubjectId !== null && item.katbookSubjectId !== "null" && item.katbookSubjectId !== "").map((q) => {
                return sessionList.push(q.katbookSubjectId)
              });

              let BodBooks = {
                books: sessionList
              };

              try {

                resSubjectsList = await getAllKatBookSubjects(BodBooks,retrData.userName);
  
                if(resSubjectsList.data.length > 0){
  
                  // let FinalSUbsData = resSubjectsList.data.map((g) => {
                  //   return {
                  //     subjectID: retrData?.classSubjectDetails.filter(item => item.katbookSubjectId === g?.bookId)[0]["subjectId"],
                  //     subjectName: retrData?.classSubjectDetails.filter(item => item.katbookSubjectId === g?.bookId)[0]["subjectName"],
                  //     refInstID: retrData.refInstID,
                  //     refMediumID: retrData.mediumId ? retrData.mediumId : 1,
                  //     refStandardID: retrData?.classSubjectDetails.filter(item => item.katbookSubjectId === g?.bookId)[0]["sectionId"],
                  //     className : retrData?.classSubjectDetails.filter(item => item.katbookSubjectId === g?.bookId)[0]["className"],
                  //     sectionName : retrData?.classSubjectDetails.filter(item => item.katbookSubjectId === g?.bookId)[0]["sectionName"],
                  //     Books: g
                  //   }
                  // });

                  let FinalSUbsData = retrData?.classSubjectDetails.map((g) => {
                    return {
                      subjectID: g?.subjectId,
                      subjectName: g?.subjectName,
                      refInstID: retrData.refInstID,
                      refMediumID: retrData.mediumId ? retrData.mediumId : 1,
                      refStandardID: g?.sectionId,
                      className : g?.className,
                      sectionName : g?.sectionName,
                      Books: resSubjectsList?.data?.filter(item => item.bookId === g?.katbookSubjectId)[0]
                    }
                  });
  
                  setUnits(FinalSUbsData);
                  dispatch({ type: "KATBOOKS", payload: FinalSUbsData });
                } else {
                  setUnits([])
                }
  
                } catch (err) {
                  console.log(err,"eeeeeeeeeeeee");
                }
  
                setSubfetchs(false);
            } else {
              setUnits([])
              setSubfetchs(false);
            }

          
            } else {
  
              allquesbod = {
                refInstID: retrData.refInstID,
                refSectionID: retrData.classID,
                refVariableID: retrData.variableID,
              };

              allquestres = await getDynamicSubjects(allquesbod);

              if(allquestres.status === "403"){
                goToMainRoutes()
              }

              if(allquestres?.status === 'success'){
                
              let sessionList = [];
              allquestres.value.filter(item => item.KATBOOK_SUBJECT_ID !== null && item.KATBOOK_SUBJECT_ID !== "null" && item.KATBOOK_SUBJECT_ID !== "").map((q) => {
                return sessionList.push(q.KATBOOK_SUBJECT_ID)
              });

              let BodBooks = {
                books: sessionList
              };

              try {

              resSubjectsList = await getAllKatBookSubjects(BodBooks,retrData.userName);

              if(resSubjectsList.data.length > 0){

                let FinalSUbsData = resSubjectsList.data.map((g) => {
                  return {
                    subjectID: allquestres.value.filter(item => item.KATBOOK_SUBJECT_ID === g?.bookId)[0]["SUBJECT_ID"],
                    subjectName: allquestres.value.filter(item => item.KATBOOK_SUBJECT_ID === g?.bookId)[0]["SUBJECT_NAME"],
                    Books: g
                  }
                });

                setUnits(FinalSUbsData);
                dispatch({ type: "KATBOOKS", payload: FinalSUbsData });
              } else {
                setUnits([])
              }

              } catch (err) {
                console.log(err,"eeeeeeeeeeeee");
              }

              setSubfetchs(false);
              // console.log(resSubjectsList,"eeeeeeeeeeeee");
  
              } else {
                setUnits([])
                setSubfetchs(false);
              }
  
            }

        }

        getfetchbooks();
      
        } else {
        }

        CheckTokenProfile()
        
      },[]);

  return (
    <div
    onClick = {()=>disableOnOuterTouch()}
    >
        <div className="Navbar">
        <Header 
        showProfile={showProfile}
        falseShowProfile={falseShowProfile}
        notofShowProfile={notofShowProfile}
        institutionName={localStorage.length > 0 && JSON.parse(localStorage.userCreds).institutionName.toUpperCase()}
        ShowNotifications={ShowNotifications}
        showNotification={showNotification}
        falseShowNotification={falseShowNotification}
        />
        </div>
        <div className={"Dash_home_QWI"}>
        {subfetchs ? (
            <>
              <div
                style={{
                  height: window.innerHeight - 116 + "px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src={Subload} alt="" style={{ width: "25%" }} />
              </div>
            </>
          ) : (
        <div className={"Subject_container"}
        style={{
            marginBottom: "40px"
        }}
        >
            <div className={"H_katbook"}>
            <h4 className={"Margin_Left_Card"}>Subjects</h4>
            </div>

            <div className={"Container marginBottom-20"}>
            {Units.length > 0 ? (
            Units.sort().map((x, i) => {
                return <KatbookBooks count={i} key={x._id} x={x} usercreds={userCredsvalues} />;
            })
            ) : (
              <div
                style={{
                  height: window.innerHeight - 266 + "px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src={NoBooks} alt="" style={{ width: "25%" }} />
              </div>
            )
            }
            </div>
        </div>
          )
        }

        <Footer 
          openForm={openForm}
          shoq={shoq}
          closeForm={closeForm}
          KpostIframe={KpostIframe}
        />

        </div>
          
    </div>
  )
};

export default BookView