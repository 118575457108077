// Npm Packages
import React, { Fragment, useState, useEffect } from "react";

import parse from 'html-react-parser';
import moment from "moment";
import { toast } from "react-toastify";
import { CSmartTable } from "@coreui/react-pro";

// Hooks
import useResponsive from "../../hooks/useResponsive";

// Component or Elements
import Header from "./HeaderNavbar";
import Footer from "./Footer";
import { ExportCSV } from "./ExportCSV";
// Styles
import "./StudentAssignment.css";
import {
  getDynamicSubjects,
  assignmentStudentsReport_subjectlist,
  QuestionAndAnswerOnly_Table_List,
  Teacher_Review_StudentName_List_API,
  Teacher_CreateAssignemt_Table,
  GetStudentAssignmentDetails_API,
} from "../../services/Apiservices";
// Icons


import KButton from "./common/KButton";
import KHeader from "./common/KHeader";
import KRequiredLabel from "./common/KRequiredLabel";
import KEditor from "./common/KEditor";
import Warning from "./Toastify/Warning";
import Success from "./Toastify/Success";
import Danger from "./Toastify/Danger";
import image from "../../Assets/Img_Meet.png";

import {
  CBreadcrumbItem,
  CBreadcrumb,
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
const TeacherReportStudentCreate = ({
  VideoConfFunc,
  tConvert,
  CuttentDateTime,
  tableBgColors,
  openBookview,
  showProfile,
  falseShowProfile,
  notofShowProfile,
  ShowNotifications,
  showNotification,
  falseShowNotification,
  disableOnOuterTouch,
  shoq,
  KpostIframe,
  closeForm,
  openForm,
  trueShowNotification,
  goToMainRoutes
}) => {
  // Initialization
  // Hooks Initialization
  const { isBigScreen } = useResponsive();
  const [assignmentReport, setAssignmentReport] = useState(false);
  const parse = require('html-react-parser');
  // Raj - Start--------------------
  const [subjectsData, setsubjectsData] = React.useState([]);
  const [assignment_Stauts, setAssignment_Status] = useState(false);
  const [physics_tabel, setPhysics_Tabel] = useState(false);
  const [assig_tabel, setAssig_tabel] = useState(false);
  const [assign_Btn, setAssign_Btn] = useState(true);
  const [visible, setVisible] = useState(false);
  const [breadcrumAs, setBreadcrumAs] = useState(true);
  const [breadcrumAs1, setBreadcrumAs1] = useState(false);
  const [breadcrumAs2, setBreadcrumAs2] = useState(false);
  const [breadcrumAs3, setBreadcrumAs3] = useState(false);
  const [visibleK, setVisibleK] = useState(false);
  const [studentNameList, setStudentNameList] = useState([]);
 
  const [questionReportName,setQuestionReportName] = useState([]);
  // Assignment Report .......................
  const [breadcrumAr1, setBreadcrumAr1] = useState(false);
  const [breadcrumAr2, setBreadcrumAr2] = useState(false);
  const [breadcrumAr3, setBreadcrumAr3] = useState(false);
  const [breadcrumAs4, setBreadcrumAs4] = useState(false);
  const [breadcrumAs5, setBreadcrumAs5] = useState(false);
  const [assignmentReportIO, setAssignmentReportIO] = useState(true);
  const [assignmentstatusST, setAssignmentstatusST] = useState(false);
  const [subject, setSubject] = useState(false);
  const [reviewTableStatus, setReviewTableStatus] = useState(false);
  
  const [subjectReport, setSubjectReport] = useState(false);
  const [mainTable, setMainTable] = useState(false);
  const [subTable, setSubTable] = useState(false);
  const [subTableBox, setSubTableBox] = useState(false);
  const [report, setReport] = useState(false);
  const [asstTables, setasstTables] = useState([]);
  const [assignmentstatusSTReport, setAssignmentstatusSTReport] = useState(false);
  const [studentArryID, setStudentArryID] = useState([]);
  
  const [asstTablesSubNameRep, setAsstTablesSubNameRep] = useState([]);
  const[titleStatus,setTitleStatus] = useState(true)
  const[titleReport,setTitleReport] = useState(false)
  const [userscredsData, setuserscredsData] = useState([]);
  const[question,setQuestion] = useState([]);
  const [totalmarks, setTotalmarks] = useState([]);
  const[answer,setAnswer] = useState([]);
  const[subjectNameArrydata,setSubjectNameArrydata] = useState([])
  const[questionReport,setQuestionReport] = useState([]);
  const[answerReport,setAnswerReport] = useState([]);
  const [studentNameMatch,setStudentNameMatch] = useState([])
  const[extraAssignment,setExtraAssignment] = useState([])
  const[subjectNameArrydata1,setSubjectNameArrydata1] = useState([])
  const[assignmentReportList,setAssignmentReportList] = useState([])
  const [name,setName] = useState([]);
  const [nameReport,setNameReport] = useState([]);
  
  // console.log("sdfjsuf",name)
  const[assignmentReportListdata,setAssignmentReportListData] = useState([{
    
    No:"",
    SubjectName:"",
    Assignment:"",
    Assignedby:"",
    lsd:"",
    submittedDate:"",
    TotalMarks:"",
    studentId:"",
   
}])
// console.log("sdfjklsdhfqSA",assignmentReportListdata)
  const[assignmentReportListdata1,setAssignmentReportListData1] = useState({
    assignmentId:"",
  })
 
const[assignmentSecondData,setAssignmentSecondData] = useState([{

  No:"",
  assignmentId:"",
  SubjectName: "",
  subBId:"",
  assignmentName: "",
  Assignedby: "",
  TotalMarks: "",
  MarksObtained:"",
  Status:"",
  submittedDate:"",
  DateofSubmission:"",
  Review:"",
}])

const[assignmentSecondDataReport,setAssignmentSecondDataReport] = useState([{

  No:"",
  assignmentId:"",
  SubjectName: "",
  assignmentName:"",
  subBId:"",
  Assignment: "",
  Assignedby: "",
  TotalMarks: "",
  MarksObtained:"",
  submittedDate:"",
  Status:"",
  lsd:"",
  DateofSubmission:"",
  Review:"",
}])

  // .............Assignment Status Sample Data......  START.....

  useEffect(() => {
    // const userCreds = await JSON.parse(localStorage.getItem("userCreds"));
    if (localStorage.length > 0) {
      let retrData = JSON.parse(localStorage.userCreds);
      // let studentId = JSON.parse(localStorage.userCreds.username);
      const getSubjectListfunc = async () => {
        let allquesbod, allquestres;

        if (retrData.userType === "Teaching") {
          allquesbod = {
            // refInstitutionId: retrData.refInstID,
            // staffId: retrData.staffID,
            refStaffID: retrData.staffID,
          };
        
        }else {
          allquesbod = {
          
          refInstID: retrData.refInstID,
          refSectionID: retrData.classID,
          refVariableID: retrData.variableID,
          studentId:retrData.username,
       
        };         
        }
        allquestres = await getDynamicSubjects(allquesbod);

        if(allquestres.status === "403"){
          goToMainRoutes()
        }
        
        if (allquestres.status === "success") {
          setuserscredsData(allquestres.value);
          CourseListFunc(allquestres.value);
         
        }
      };
      getSubjectListfunc();
      
    }
  }, [localStorage]);

  // list of all subject
  const CourseListFunc = async () => {
    
    const SubjectList = JSON.parse(await localStorage.getItem("userCreds"))?.classSubjectDetails ?? [];
    
    if (SubjectList.length > 0) {
      let dataOPtion = await SubjectList.map((e) => {
        return {
          ...e,
          value: `${e.katbookSubjectId} ${e.sectionId}`,
          label: `${e.className}-${e.sectionName} ${e.subjectName}`,
          kamSubjectId: e.katbookSubjectId,
          sectionId:e.sectionId,
          classId:e.classId,
         
        };
      });
      setsubjectsData(dataOPtion);
      // console.log("subjectI13123D",dataOPtion)
    }
  };

   useEffect(() => {
    

    
    // Report_Student_Assignment_Table_Second(subjectsData.subjectId);
  }, []);

// Assignment list API 28.04.2023

const Student_Assignment_Status_Table = async (katbookSubjectId,classId,sectionId) => { 
  
  let response;
      const body = {
        
          staffId:localStorage.length > 0 && JSON.parse(localStorage.userCreds).staffID,
          classId:classId,
          sectionId:sectionId,
          katbookSubjectId:katbookSubjectId,
      }
      // console.log("4785963",body)
       setasstTables([]);
    try {
      // response = await assignmentStudentsReport_subjectlist(katbookSubjectId,studentId);
      response = await Teacher_CreateAssignemt_Table(body);
      
      // console.log(response.data, "aasdasdasaa");
      if (response) {
        
        if (response.status==="success") {
          // toast.success(response.message);
          if (response.data) {
            const data = response.data.map((x, index) => {
              
              return {
                No: index + 1,
                assignmentId:x?.assignmentId,
                SubjectName: x?.katbookSubjectName,
                subBId: x.katbookSubjectId,
                assignmentName: x.assignmentName,
                Assignedby: x.staffName,
                katbookSubjectId:x.katbookSubjectId,
                sectionId:x.sectionId,
                TotalMarks: x.totalMarks,
                MarksObtained:`${x.obtainedMarks == '' ? '-' : x.obtainedMarks}`,
                Status:x.studentPosted === true?"Submitted":"Not Submitted",
                DateofSubmission: `${moment(x.lsd).format("DD MMM YYYY")}`,
                submittedDate: `${moment(x?.lsd).format("DD MMM YYYY") === null ? '-' : moment(x.lsd).format("DD MMM YYYY") }`,
                Review:x?.teacherReview === true ? "Reviewed" : "Not Review",
               
              };
            });
            setasstTables(data);
            // console.log("45654dsf",data)

            let subjectNameArry = []

            for(let z=0;z<data.length;z++){
              if(subjectNameArry.filter(y=>y.SubjectName === data[z].SubjectName).length === 0){
                subjectNameArry.push(data[z])
              }
            }
            setSubjectNameArrydata(subjectNameArry)
            // console.log("jskfsf",subjectNameArry)


          }
        } 
        else {
          toast.error(response.message);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const Student_Assignment_Report_Table = async (katbookSubjectId,classId,sectionId) => {
    let response;

    const body = {
      
      staffId:localStorage.length > 0 && JSON.parse(localStorage.userCreds).staffID,
      classId:classId,
      sectionId:sectionId,
      katbookSubjectId:katbookSubjectId,
        
      // studentId:localStorage.length > 0 && JSON.parse(localStorage.userCreds).userName,
      // classId:localStorage.length > 0 && JSON.parse(localStorage.userCreds).variableID,
      // sectionId:localStorage.length > 0 && JSON.parse(localStorage.userCreds).classID,
      
  }
  // console.log("klsf",body)
    // setAssignmentReportList([]);    
    try {
      // response = await assignment_Report_List(subjectId,studentId);
      response = await Teacher_CreateAssignemt_Table(body);
     
      // console.log(response.data, "report1757");
      if (response) {
        if (response.status==="success") {
          // toast.success(response.message);
         
          if (response.data) {
            const data = response.data.map((x, index) => {
              return {
                No: index + 1,
                assignmentValue:x?.assignmentId,
                assignmentId:x?.assignmentId,
                
                SubjectName: x?.katbookSubjectName,
                studentId:x.studentId,
                Assignment:x?.assignmentName,
                Assignedby:x.staffName,
                lsd:`${moment(x.lsd).format("DD-MMM-YYYY")}`,
                submittedDate:`${moment(x.postedDate).format("DD-MMM-YYYY")}`,
                TotalMarks:x.totalMarks,
                studentPosted:x.studentPosted,
                sectionId:x.sectionId,
                Status:x.studentPosted === true?"Submitted":"Not Submitted",
                Review:x?.teacherReview === true ? "Reviewed" : "Not Review",
               
              
              };
            });
            setAssignmentReportList(data);
            // console.log("sdkljhkhdasd",data)
          //  Only display studentPosted = true filter
            const student_post =data.filter(x=>x.studentPosted === true)
            setAsstTablesSubNameRep(student_post)
            // console.log("45a",student_post)


            let subjectNameArry1 = []

            for(let z=0;z<data.length;z++){
              if(subjectNameArry1.filter(y=>y.SubjectName === data[z].SubjectName).length === 0){
                subjectNameArry1.push(data[z])
              }
            }
            setSubjectNameArrydata1(subjectNameArry1)

          }

        }
         else {
          toast.error(response.message);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };


  const Student_Status_Question = async (assignmentId,studentId) => {
    let response;
    setQuestion([]);
    const body = {
      studentId: studentId,
      assignmentId: assignmentId,

    };
    // console.log("456454",body)
    try {
      response = await QuestionAndAnswerOnly_Table_List(body);
      // console.log(response.data, "qalist123455");
      if (response) {
        if (response.status==="success") {
          // toast.success(response.message);
          if (response.data) {
            const data = response.data.map((x, index) => {
             
              return {
                No: index + 1,
                // questionId:x._id,
                Question : x.question,
                // Question:`
                //   Question :${x?.question }
                //   Correct Answer : ${x?.correctAnswer}
                //   Student Answer : ${x?.studentAnswer === '' ? 'Student Not Answer' : x?.studentAnswer}`
                //   ,
                //  correctAnswer:`${x.studentAnswer === null ? '-' : x.studentAnswer}`,
                 comment:`${x?.comment === null ? '-' : x?.comment}`, 
                
                // Comsment:`${x?.Comment === 'undefined' ? '-' : x?.Comment}`, 
                // MarksObtained:`${x.studentMark == null ? '-' : x.studentMark}`,
                correctAnswer:x.correctAnswer,
                MarksObtained: `${x.answerType === "RadioButton" ? (x.studentAnswer === x.correctAnswer ? x.marks : 0) : (x.studentMark !== null ? x.studentMark : "-")}`,
                marks:x.marks,
                studentAnswer:`${x.studentAnswer === null ? 'Student Not Answer' : x.studentAnswer}`,
                answerType:x.answerType,
                option: x.options === "" || x.options === null ? "" : `${JSON.parse(x.options)?.map((x, i) => `${i + 1}.${x.options}`).join('\n')}`,
                
                studentMark: `${x.answerType === "RadioButton" ? (x.studentAnswer === x.correctAnswer ? x.marks : 0) : (x.studentMark !== null ? x.studentMark : "-")}`,
                
              };
            });
            setQuestion(data);
            let totalMarks = 0;
            for (let i = 0; i < data.length; i++) {
              const x = data[i];
              if (x.answerType === "RadioButton") {
                totalMarks = x.studentAnswer === x.correctAnswer ? Number(totalMarks) + Number(x?.marks ?? 0) : Number(totalMarks) +  0;
               
              }
               else{
                totalMarks = x.studentMark !== null ? Number(totalMarks) + Number(x?.studentMark ?? 0) : Number(totalMarks) +  0;
              }
              
            }
            if(isNaN(totalMarks)){
              totalMarks="-";
            }
            setTotalmarks(totalMarks);

          }
        } 
         else {
          // toast.error(response.message);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const studentID = localStorage.length > 0 && JSON.parse(localStorage.userCreds).userName
  
  const StudentName_List_Status = async (sectionId,assignmentId) => {    
    let response;
    const studentName = localStorage.length > 0 && JSON.parse(localStorage.userCreds).userName
    const assignmentIdData = assignmentId;
    setStudentNameList([])
    const body = {
              
              classId:JSON.stringify(sectionId),
              
            };
          
    try {

      response = await Teacher_Review_StudentName_List_API(body);
  
      if (response.status === "success") {
        console.log("sddf1234565",response)
        if (response.value) {
    
            const stuarray = [];
            const data  = [];


          for (let index = 0; index < response.value.length; index++) {
            const x = response.value[index];
            const i = index
            // const getassignmentdetails = async  (i) => {

              const bodyStuAssign = {
                studentId:x.userid,
                assignmentId: assignmentIdData,
              };

              const getassignmentsData = await GetStudentAssignmentDetails_API(bodyStuAssign);
              
              const stuVals = {
                No: i + 1,
                Name: `${x?.firstname} ${x?.lastname}`,
                studentId:x.userid,
                assignmentId:getassignmentsData?.data?.assignmentId,
                // status:x?.mobilenumber,
                status: `${getassignmentsData?.data?.studentPosted === true ? "Submitted" : "Not Submitted" } `,
                studentPostedDate: `${getassignmentsData?.data?.studentPostedDate === undefined ? '-' :moment(getassignmentsData?.data?.studentPostedDate).format("DD-MMM-YYYY") }`,
                isSubmitted : getassignmentsData?.data?.studentPosted,
                teacherReview: `${getassignmentsData?.data?.teacherReview === true ? "Reviewed" : "Not Reviewed" } `,
                isReviewed : getassignmentsData?.data?.teacherReview,
                teacherReviewDate: getassignmentsData?.data?.teacherReviewDate ? getassignmentsData?.data?.teacherReviewDate : "",
                studentTotalMarks: `${getassignmentsData?.data?.studentTotalMarks === 0  ? '-' : getassignmentsData?.data?.studentTotalMarks}`,
                
              };
              stuarray.push(stuVals);
              // studentNameList.push(stuVals)
              
              //  assignmentReportListdata1.assignmentId
            // };
           

            //  const returnAssgn = getassignmentdetails(i);
            // console.log(returnAssgn,"YYYYYYYYYYYYYYYYYYY");
            
            data.push({
              No: i + 1,
              Name: `${x?.firstname} ${x?.lastname}`,
              studentId:x.userid,
              status:x?.mobilenumber,
            });
          }

            // const data = response.value.map((x, i) => {

            //   const getassignmentdetails = async  (i) => {

            //     const bodyStuAssign = {
            //       studentId:x.userid,
            //       assignmentId: assignmentIdData,
            //     };
  
            //     const getassignmentsData = await GetStudentAssignmentDetails_API(bodyStuAssign);
                
            //     const stuVals = {
            //       No: i + 1,
            //       Name: `${x?.firstname} ${x?.lastname}`,
            //       studentId:x.userid,
            //       assignmentId:getassignmentsData?.data?.assignmentId,
            //       // status:x?.mobilenumber,
            //       status: `${getassignmentsData?.data?.studentPosted === true ? "Submitted" : "Not Submitted" } `,
            //       studentPostedDate: `${getassignmentsData?.data?.studentPostedDate === undefined ? '-' :moment(getassignmentsData?.data?.studentPostedDate).format("DD-MMM-YYYY") }`,
            //       isSubmitted : getassignmentsData?.data?.studentPosted,
            //       teacherReview: `${getassignmentsData?.data?.teacherReview === true ? "Reviewed" : "Not Reviewed" } `,
            //       isReviewed : getassignmentsData?.data?.teacherReview,
            //       teacherReviewDate: getassignmentsData?.data?.teacherReviewDate ? getassignmentsData?.data?.teacherReviewDate : "",
            //       studentTotalMarks: `${getassignmentsData?.data?.studentTotalMarks === 0  ? '-' : getassignmentsData?.data?.studentTotalMarks}`,
                  
            //     };
            //     stuarray.push(stuVals);
            //     // studentNameList.push(stuVals)
                
            //     //  assignmentReportListdata1.assignmentId
            //   };
             

            //    const returnAssgn = getassignmentdetails(i);
            //   // console.log(returnAssgn,"YYYYYYYYYYYYYYYYYYY");
              
            //   // return {
            //     // No: i + 1,
            //     // Name: `${x?.firstname} ${x?.lastname}`,
            //     // studentId:x.userid,
            //     // status:x?.mobilenumber,
                

            //   // };
            // });
            // console.log(stuarray,"YYYYYYYYYYYYYYYYYYY");
            setStudentNameList(stuarray);
            
            // console.log("Sdadasd1114154",data);
            const dataStudent = data.filter (x=>x.studentId !== studentName)
            setStudentNameMatch(dataStudent)
            // console.log("Sdadasd11111114154",studentNameMatch);
            
            let studentarrId = []

            for(let z=0;z<data.length;z++){
              if(studentarrId.filter(y=>y.studentId === data[z].studentId).length === 0){
                studentarrId.push(data[z])
              }
            }
            
            setStudentArryID(studentarrId);
            // console.log("dsfjkjsd",studentarrId)
          // toast.success(response.status);

        } else {
          // toast.error(response.message);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };


     


  const Student_Report_Question = async (assignmentValue) => {
    let response;
    setQuestionReport([]);
    const body = {
      studentId: localStorage.length > 0 && JSON.parse(localStorage.userCreds).userName,
      assignmentId: assignmentValue,

    };
    // console.log("456487",body)
    try {
      // response = await QuestionAndAnswer_Table_List(body);
      response = await QuestionAndAnswerOnly_Table_List(body);
      
      // console.log(response, "www");
      if (response) {
        if (response.status==="success") {
          // toast.success(response.message);
          if (response.data) {
            const data = response.data.map((x, index) => {
              return {
                
                S_no: index + 1,
                questionId:x._id,
                Question:x.question,
                
                //   Question:`
                //   Question :${x?.question }
                //   Correct Answer : ${x?.correctAnswer}
                //   Student Answer : ${x?.studentAnswer === '' ? 'Student Not Answer' : x?.studentAnswer}`
                //   ,
                
                comment:`${x?.comment === null ? '-' : x?.comment}`,
                obtainedMark:`${x.studentMark === null ? '-' : x.studentMark}`,
                correctAnswer:x.correctAnswer,
                studentAnswer:`${x.studentAnswer === null ? 'Student Not Answer' : x.studentAnswer}`,
                
              };
            });
            setQuestionReport(data);
          }
        } else {
          // toast.error(response.message);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  const Report_studentId = localStorage.length > 0 && JSON.parse(localStorage.userCreds).userName
  

  const StudentName_Report_Question_API = async (studentId) => {
    let response;
    setQuestionReportName([]);
    const body = {
      studentId:studentId,
      // studentId: localStorage.length > 0 && JSON.parse(localStorage.userCreds).userName,
      assignmentId: assignmentReportListdata1.assignmentId,
      
    };
    // console.log("dfhkjhewr454",body)
    try {
      // response = await QuestionAndAnswer_Table_List(body);
      response = await QuestionAndAnswerOnly_Table_List(body);
      
      // console.log(response.data, "www");
      if (response) {
        if (response.status==="success") {
          // toast.success(response.message);
          if (response.data) {
            const data = response.data.map((x, index) => {
              return {
                
                S_no: index + 1,
                questionId:x._id,
                Question:x.question,
                comment:`${x?.comment === null ? '-' : x?.comment}`,
                obtainedMark:`${x.studentMark === null ? '-' : x.studentMark}`,
                correctAnswer:x.correctAnswer,
                studentAnswer:`${x.studentAnswer === null ? 'Student Not Answer' : x.studentAnswer}`,
                
              };
            });
            setQuestionReportName(data);
          }
        } else {
          // toast.error(response.message);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };




  const Student_Report_Answeronly = async (assignmentId,Report_studentId) => {
    let response;
    setAnswerReport([]);    
    try {
      response = await QuestionAndAnswerOnly_Table_List(assignmentId,Report_studentId);
      // console.log(response, "answer");
      if (response) {
        if (response.status==="success") {
          // toast.success(response.message);
          if (response.data) {
            const data = response.data.map((x, index) => {
              return {
                No: index + 1,
                correctAnswer:x?.correctAnswer,
                QuestionId: x.questionId,
                comment:`${x?.teacherComment === 'undefined' ? '-' : x?.teacherComment}`, 
                obtainedMark:`${x.obtainedMark == '' ? '-' : x.obtainedMark}`,
              };
            });
            setAnswerReport(data);
          }
        } else {
          // toast.error(response.message);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const SecondData = [
    {
      key: "SubjectName",
      label: "Subject Name",
      _style: { width: "12%" },
    },
    {
      key: "assignmentName",
      label: "Assignment Name",
      _style: { width: "13%" },
    },
    {
      key: "Assignedby",
      label: "Assigned by",
      _style: { width: "10%" },
    },
    {
      key: "submittedDate",
      label: "Submitted Date",
      _style: { width: "12%" },
    },
    // {
    //   key: "Status",
    //   label: "Status",
    //   _style: { width: "8%" },
    // },
    // {
    //   key: "Review",
    //   label: "Review",
    //   _style: { width: "8%" },
    // },
    
    {
      key: "TotalMarks",
      label: "Marks",
      _style: { width: "10%" },
    },

  ];

  // const columns_FirstTabel_Assign_status = [
  //   {
  //     key: "No",
  //     label: "S.no",
  //     _style: { width: "6%" },
  //   },
  //   {
  //     key: "SubjectName",
  //     label: "Subject Name 01",
  //     _style: { width: "12%" },
  //   },
  //   {
  //     key: "assignmentName",
  //     label: "Assignment Name",
  //     _style: { width: "13%" },
  //   },
  //   {
  //     key: "Assignedby",
  //     label: "Assigned by",
  //     _style: { width: "10%" },
  //   },
  //   {
  //     key: "DateofSubmission",
  //     label: "Date of Submission",
  //     _style: { width: "15%" },
  //   },
  //   {
  //     key: "submittedDate",
  //     label: "Submitted Date",
  //     _style: { width: "12%" },
  //   },
  //   {
  //     key: "Status",
  //     label: "Status",
  //     _style: { width: "8%" },
  //   },
  //   {
  //     key: "Review",
  //     label: "Review",
  //     _style: { width: "8%" },
  //   },
  //   // {
  //   //   key: "MarksObtained",
  //   //   label: "Marks Obtained 01",
  //   //   _style: { width: "12%" },
  //   // },
  //   {
  //     key: "TotalMark",
  //     label: "Total Marks",
  //     _style: { width: "10%" },
  //   },

  //   { key: "Action", _style: { width: "12%" },filter: false, sorter: false,},
  // ];

  const columns_SecondTabel_Assign_status = [
    // {
    //   key: "No",
    //   label: "S.no",
    //   _style: { width: "6%" },
    // },
    {
      key: "SubjectName",
      label: "Subject Name",
      _style: { width: "12%" },
      sorter:false,
    },
    {
      key: "assignmentName",
      label: "Assignment Name",
      _style: { width: "15%" },
      sorter:false,
    },
    {
      key: "Assignedby",
      label: "Assigned by",
      _style: { width: "10%" },
      sorter:false,
    },
    {
      key: "DateofSubmission",
      label: "Date of Submission ",
      _style: { width: "15%" },
      sorter:false,
    },
    // {
    //   key: "submittedDate",
    //   label: "Submitted Date",
    //   _style: { width: "12%" },
    // },
    {
      key: "TotalMarks",
      label: "Marks",
      _style: { width: "10%" },
      sorter:false,
    },
    // {
    //   key: "MarksObtained",
    //   label: "Marks Obtained",
    //   _style: { width: "12%" },
    // },
    
  ];

  const columns_SecondTabel_Assign_Report = [
    {
      key: "No",
      label: "S.No",
      _style: { width: "6%" },
    },
    {
      key: "SubjectName",
      label: "Subject Name",
      _style: { width: "12%" },
    },
    {
      key: "Assignment",
      label: "Assignment Name",
      _style: { width: "15%" },
    },
    {
      key: "Assignedby",
      label: "Assigned by",
      _style: { width: "10%" },
    },
    {
      key: "lsd",
      label: "Date of Submission",
      _style: { width: "15%" },
    },
    // {
    //   key: "submittedDate",
    //   label: "Submitted Date",
    //   _style: { width: "12%" },
    // },
    {
      key: "TotalMarks",
      label: "Marks",
      _style: { width: "10%" },
    },
    // {
    //   key: "MarksObtained",
    //   label: "Marks Obtained",
    //   _style: { width: "12%" },
    // },
    
  ];
  
  const columns_ThirdTabel_Assign_status = [
    {
      key: "No",
      label: "S.No",
      _style: { width: "6%" },
    },
    {
      key: "Question",
      label: "List of Question and Answer",
      _style: { width: "65%" },
    },
    // {
    //   key: "correctAnswer",
    //   label: "Student Answer",
    //   _style: { width: "30%" },
    // },
    {
        key: "MarksObtained",
        label: "Marks Obtained",
        _style: { width: "15%" },
      },
    {  key: "comment", 
       label: "Comment", 
       _style: { width: "15%" } },
  ];

  const QuestionAnswer_Report = [
    {
      key: "S_no",
      label: "S.No",
      _style: { width: "5%" },
    },
    {
      key: "Question",
      label: "List of Question and Answer ",
      _style: { width: "70%" },
    },
    // {
    //   key: "correctAnswer",
    //   label: "Answer",
    //   _style: { width: "25%" },
    // },
    {
      key: "obtainedMark",
      label: "Marks Obtained",
      _style: { width: "12%" },
    },
    {
      key: "comment",
      label: "Comment",
      _style: { width: "12%" },
    },
  ];
  
  const QuestionAnswer_Report_student = [
    {
      key: "S_no",
      label: "S.No",
      _style: { width: "8%" },
    },
    {
      key: "Question",
      label: "List of Question and Answer ",
      _style: { width: "70%" },
    },
    {
      key: "obtainedMark",
      label: "Marks Obtained",
      _style: { width: "12%" },
    },
    {
      key: "comment",
      label: "Comment",
      _style: { width: "12%" },
    },
  ];


  const columnsss = [
    {
      key: "No",
      label: "No",
      _style: { width: "5%" },
    },
    {
      key: "NameofStudent",
      label: "Name of the Student",
      _style: { width: "15%" },
    },
    {
      key: "SubmittedDate",
      label: "Submitted Date",
      _style: { width: "12%" },
    },
    {
      key: "Status",
      label: "Status",
      _style: { width: "10%" },
    },
    {
      key: "MarksObtained",
      label: "Marks Obtained",
      _style: { width: "12%" },
    },
    {
      key: "Action",
      _style: { width: "10%" },
    },
  ];

  const columnquet = [
    {
      key: "id",
      label: "No",
      filter: false,
      sorter: false,
      _style: { width: "10%" },
    },
    {
      key: "name",
      label: "Question",
      filter: true,
      sorter: true,
      _style: { width: "25%" },
    },
    {
      key: "role",
      label: "Answer",

      filter: true,
      sorter: true,
      _style: { width: "25%" },
    },
    {
      key: "status",
      label: "Marks",

      _style: { width: "20%" },
      filter: true,
      sorter: true,
    },
  ];
 
  const usersData1lquest = [
    {
      id: 1,
      name: "What is your name ?",
      role: "My name is Jai Balaji",
      status: "100",
    },
    {
      id: 2,
      name: "What is your company name ?",
      role: "My company name is Kpost Software pvt ltd",
      status: "100",
    },
    {
      id: 3,
      name: "What is your Designation  ?",
      role: "My designation is Software Developer",
      status: "100",
    },
  ];

  const columnsssassi = [
    {
      key: "No",
      label: "Subject",
      _style: { width: "15%" },
    },
    {
      key: "NameofStudent",
      label: "Assignment Name01",
      _style: { width: "13%" },
    },
    {
      key: "SubmittedDate",
      label: "Assigned By",
      _style: { width: "12%" },
    },
    {
      key: "Status",
      label: "Date of Submission",
      _style: { width: "13%" },
    },
    {
      key: "submitday",
      label: "Submitted Date",
      _style: { width: "12%" },
    },
    {
      key: "total",
      label: "Marks",
      _style: { width: "12%" },
    },
    {
      key: "MarksObtained",
      label: "Marks Obtained",
      _style: { width: "12%" },
    },
  ];

  const usersData111asi = [
    {
      No: "Physics",
      NameofStudent: "Assignment 1",
      SubmittedDate: "Girija Balaji",
      Status: "23-04-2021",
      submitday: "19-04-2021",
      total: "25",
      MarksObtained: "20",
    },
  ];

  const usersData111 = [
    {
      No: 1,
      NameofStudent: "Anbalagan",
      SubmittedDate: "12.03.2023",
      Status: "Reviewed",
      submitday: "fdf",
      total: "fdf",
      MarksObtained: "25",
    },
    {
      No: 2,
      NameofStudent: "Bala Krishna",
      SubmittedDate: "12.03.2001",
      Status: "To Review",
      MarksObtained: "NA",
    },
    {
      No: 3,
      NameofStudent: "karthick",
      SubmittedDate: "31.12.2015",
      Status: "To Review",
      MarksObtained: "NA",
    },
    {
      No: 4,
      NameofStudent: "Prathap",
      SubmittedDate: "15.01.2022",
      Status: "NA",
      MarksObtained: "NA",
    },
  ];

  const assignmentData_2_Assign_status = [
    {
      No: 1,
      Subject: "Physics0000",
      AssignmentName: "Assignment 1",
      Assignedby: "Rajesh",
      DateofSubmission: "15.11.2015",
      SubmittedDate: "15.01.2022",
      Status: "Submitted",
      TotalMark: "54",
      MarksObtained: "95",
    },
  ];

  const assignmentData_3_Assign_status = [
    {
      No: 1,
      Question: "What is your name ?",
      Answer: "My name is Jai Balaji",
      Mark: "85",
    },
    {
      No: 2,
      Question: "What is your company name ?",
      Answer: "My company name is Kpost Software pvt ltd",
      Mark: "74",
    },
    {
      No: 3,
      Question: "What is your Designation ?",
      Answer: "My designation is Software Developer",
      Mark: "95",
    },
  ];

  // Assignment Status Sample Data ........... END ...........

  // Assignment Report Sample Data ............START ...........


  const student_Table_NameList = [
    {
      key: "No",
      label: "S.No",
      filter: true,
      sorter: true,
      _style: { width: "7%" },
    },
    {
      key: "Name",
      label: "Name of the Student",
      filter: true,
      sorter: true,
      _style: { width: "20%" },
    },
    {
      key: "studentPostedDate",
      label: "Submitted Date",
      filter: true,
      sorter: true,
      _style: { width: "20%" },
    },
    {
      key: "status",
      label: "Status",
      filter: true,
      sorter: true,
      _style: { width: "15%" },
    },
    {
      key: "studentTotalMarks",
      label: "Total Obtained Marks",
      _style: { width: "20%" },
      filter: true,
      sorter: true,
    },
    // { key: "Action", _style: { width: "10%" } },
  ];

  const student_Table_NameList_Report = [
    {
      key: "No",
      label: "S.No",
      filter: true,
      sorter: true,
      _style: { width: "7%" },
    },
    {
      key: "Name",
      label: "Name of the Student",
      filter: true,
      sorter: true,
      _style: { width: "20%" },
    },
    {
      key: "studentPostedDate",
      label: "Submitted Date",
      filter: true,
      sorter: true,
      _style: { width: "20%" },
    },
    // {
    //   key: "status",
    //   label: "Status",
    //   filter: true,
    //   sorter: true,
    //   _style: { width: "15%" },
    // },
    {
      key: "studentTotalMarks",
      label: "Total Obtained Marks",
      _style: { width: "20%" },
      filter: true,
      sorter: true,
    },
    { key: "Action", _style: { width: "10%" } },
  ];

  const Assignment_Report_table = [
    // {
    //   key: "No",
    //   label: "S.no",
    //   _style: { width: "5%" },
    // },
    {
      key: "SubjectName",
      label: "Subject",
      _style: { width: "8%" },
    },
    {
      key: "Assignment",
      label: "Assignment Name",
      _style: { width: "12%" },
    },
    {
      key: "Assignedby",
      label: "Assigned by",
      _style: { width: "10%" },
    },
    {
      key: "lsd",
      label: "Date of Submission",
      _style: { width: "12%" },
    },
    {
      key: "submittedDate",
      label: "Submitted Date",
      _style: { width: "12%" },
    },
    {
      key: "TotalMarks",
      label: "Marks",
      _style: { width: "10%" },
    },
    // {
    //   key: "MarksObtained",
    //   label: "Marks Obtained",
    //   _style: { width: "10%" },
    // },
    // {
    //   key: "Status",
    //   label: "Status",
    //   _style: { width: "10%" },
    // },
  ];


  // Assignment Report Sample Data ............START ...........

  // Raj END -------------------------------
  return (
    <div onClick={() => disableOnOuterTouch()}>
      {/* Header Section Starts  */}
      <div className="Navbar">
        <Header
          showProfile={showProfile}
          falseShowProfile={falseShowProfile}
          notofShowProfile={notofShowProfile}
          ShowNotifications={ShowNotifications}
          showNotification={showNotification}
          falseShowNotification={falseShowNotification}
        />
      </div>

      {/* Header Section Ends */}
       
     


      <div className={"Dash_home_QWI"}>
        <div
          className="myattendcard2w p-1"
          style={{
            height: window.innerHeight - 117 + "px",
            overflowY: "auto",
          }}
        >
          {/* Content Starts */}
         
    
          <Fragment>
            {/* Raj - Start---------------------------- */}
            <div className=" container-fluid px-5 py-3">
              <div className="textleft">
                {titleStatus && (
                  <KHeader
                  title="Teacher Assignment Status"
                  containerClassName={`marginTop-20`}
                />
              //   <KHeader 
              //    title={`Teacher Assignment Status - ${moment().format("DD MMM YYYY")}` }
              //    // containerClassName={`marginTop-20 justify-content-front`}
              //    LastElement={<div></div>}
              //  />
                )}

                {titleReport && (
                  <KHeader 
                  title={`Teacher Assignment Report - ${moment().format("DD MMM YYYY")}` }
                // containerClassName={`marginTop-20 justify-content-front`}
                LastElement={<div></div>}
              />
                )}

              
             </div>
                  
              {breadcrumAs && (
                <div >
                  <CBreadcrumb>
                    <CBreadcrumbItem
                      active
                      onClick={() => {
                        setAssignmentReport(false);
                        setAssignment_Status(false);
                        setAssign_Btn(true);
                        setMainTable(false);
                        setSubTable(false);
                        setSubject(false);
                        setReviewTableStatus(false);
                        setReviewTableStatus(false);
                        setAssignmentstatusST(false);

                      }}
                    >
                      Report
                    </CBreadcrumbItem>
                  </CBreadcrumb>
                </div>
              )}

              {breadcrumAs1 && (
                <div>
                  <CBreadcrumb>
                    <CBreadcrumbItem
                      onClick={() => {
                        setAssign_Btn(true);
                        setAssignmentReport(false);
                        setAssignment_Status(false);
                        setPhysics_Tabel(false);
                        setBreadcrumAs1(false);
                        setBreadcrumAs(true);
                        setSubject(false);
                        setTitleStatus(true);
                        setTitleReport(false);
                        setReviewTableStatus(false);
                        setAssignmentstatusST(false);
                      }}
                    >
                      Report
                    </CBreadcrumbItem>
                    <CBreadcrumbItem active>Assignment Status</CBreadcrumbItem>
                  </CBreadcrumb>
                </div>
              )}

              {assign_Btn && (
                <div className="">
                  <div className="d-flex flex-column flex-md-row">
                    <KButton
                      value="Assignment Status"
                      className={`px-6 py-3 mr-3  ${
                        isBigScreen ? "w-25" : "w-100"
                      }`}
                      onClick={() => {
                        setAssignment_Status(!assignment_Stauts);
                        setAssignmentReport(false);
                        setPhysics_Tabel(false);
                        setAssig_tabel(false);
                        setAssign_Btn(false);
                        setBreadcrumAs1(true);
                        setBreadcrumAs(false);
                        setTitleStatus(true);
                        setTitleReport(false);
                        setReviewTableStatus(false);
                      }}
                    />

                    <KButton
                      value="Assignment Report"
                      className={`px-6 py-3 mr-3 ${
                        isBigScreen ? "w-25" : "w-100 mt-2"
                      }`}
                      onClick={() => {
                        setAssignmentReport(!assignmentReport);
                        setAssignment_Status(false);
                        setPhysics_Tabel(false);
                        setAssign_Btn(false);
                        setBreadcrumAs(false);
                        setBreadcrumAr1(true);
                        setSubject(false);
                        setTitleStatus(false);
                        setTitleReport(true);
                      }}
                    />

                    <KButton
                      value="Attendance Report"
                      className={`px-6 py-3 mr-3 ${
                        isBigScreen ? "w-25" : "w-100 mt-2"
                      }`}
                      isDisabled={true}
                    />

                    <KButton
                      value="Katbook Coverage Report"
                      className={`px-6 py-3 ${
                        isBigScreen ? "w-25" : "w-100 mt-2"
                      }`}
                      isDisabled={true}
                    />
                  </div>
                </div>
              )}

              {breadcrumAs2 && (
                <div>
                  <CBreadcrumb>
                    <CBreadcrumbItem
                      onClick={() => {
                        setAssign_Btn(true);
                        setBreadcrumAs(true);
                        setAssignmentReport(false);
                        setAssignment_Status(false);
                        setPhysics_Tabel(false);
                        setBreadcrumAs1(false);
                        setBreadcrumAs2(false);
                        setReviewTableStatus(false);
                       
                        setAssignmentstatusST(false);
                      }}
                    >
                      Report
                    </CBreadcrumbItem>
                    <CBreadcrumbItem
                      onClick={() => {
                        setAssign_Btn(false);
                        setAssignmentReport(false);
                        setAssignment_Status(true);
                        setPhysics_Tabel(false);
                        setBreadcrumAs(false);
                        setBreadcrumAs1(true);
                        setBreadcrumAs2(false);
                        setReviewTableStatus(false);
                        setAssignmentstatusST(false);
                      }}
                    >
                      Assignment Status
                    </CBreadcrumbItem>
                    <CBreadcrumbItem active>
                      Subjects
                    </CBreadcrumbItem>
                    {/* {subjectNameArrydata.map((x, e) => (
                      <CBreadcrumbItem active>{x.SubjectName}</CBreadcrumbItem>
                    ))} */}
                  </CBreadcrumb>
                </div>
              )}

              {assignment_Stauts && (
                <div className="d-flex flex-row w-100 flex-md-row mt-3 gap-3">
                  {/* {subjectsData.map((x, e) => ( */}

                  {/* teacher status */}
                  {subjectsData.map((x, e) => (
                    
                    <div key={e}>
                      <KButton
                        className={`px-6 py-3 mr-3  
                          w-100
                        `}
                        style={{
                          width: "100%",
                          paddingLeft: "3rem",
                          paddingRight: "3rem",
                        }}
                        value={x.label}

                        onClick={() => {
                          Student_Assignment_Status_Table(x.kamSubjectId, x.classId, x.sectionId);
                        
                          setPhysics_Tabel(!physics_tabel);
                          setAssignment_Status(false);
                          setBreadcrumAs(false);
                          setBreadcrumAs1(false);
                          setBreadcrumAs2(true);
                          setReviewTableStatus(false);
                          setAssignmentstatusST(false);
                         
                        }}
                      />
                    </div>
                  ))}
                </div>
              )}


          
{breadcrumAs3 && (
                <div>
                  <CBreadcrumb>
                    <CBreadcrumbItem
                      onClick={() => {
                        setAssign_Btn(true);
                        setAssignmentReport(false);
                        setAssignment_Status(false);
                        setAssig_tabel(false);
                        setBreadcrumAs(true);
                        setBreadcrumAs1(false);
                        setBreadcrumAs2(false);
                        setBreadcrumAs3(false);
                        setBreadcrumAs4(false);
                        setReviewTableStatus(false);
                        setAssignmentstatusST(false);
                        setPhysics_Tabel(false);
                      }}
                    >
                      Report
                    </CBreadcrumbItem>
                    <CBreadcrumbItem
                      onClick={() => {
                        setAssign_Btn(false);
                        setAssignmentReport(false);
                        setAssignment_Status(true);
                        setAssig_tabel(false);
                        setBreadcrumAs1(true);
                        setBreadcrumAs2(false);
                        setBreadcrumAs3(false);
                        setPhysics_Tabel(false);
                        setBreadcrumAs4(false);
                        setAssignmentstatusST(false);
                        setReviewTableStatus(false);
                      }}
                    >
                      Assignment Status
                    </CBreadcrumbItem>
                    <CBreadcrumbItem
                      onClick={() => {
                        setAssign_Btn(false);
                        setAssignmentReport(false);
                        setAssignment_Status(false);
                        setAssig_tabel(false);
                        setPhysics_Tabel(true);
                        setBreadcrumAs1(false);
                        setBreadcrumAs2(true);
                        setBreadcrumAs3(false);
                        setBreadcrumAs4(false);
                        setAssignmentstatusST(false);
                         setReviewTableStatus(false);    
                      }}
                    >
                      <CBreadcrumbItem style={{paddingLeft:"15px"}}>
                      Subjects
                    </CBreadcrumbItem>
                      {/* {subjectNameArrydata.map((x, e) => (
                      <CBreadcrumbItem style={{paddingLeft:"15px"}}>{x.SubjectName}</CBreadcrumbItem>
                       ))} */}


                    </CBreadcrumbItem>
                    <CBreadcrumbItem active>Student Assignment Status List</CBreadcrumbItem>
                  </CBreadcrumb>
                   <br />
                 
                </div>
              )}

              {physics_tabel && (
                <div>
                  <div className="flex-wrap mt-1 pl-3 d-flex flex-column flex-md-row gap-3 pt-2">
                   {/* status */}
                  {asstTables.map((x, e) => (
                  
                  <div key={e}>
                      <KButton
                        className={`SubjectStyle NewstyleBox`}
                        // className={`px-6 py-3 mr-3  
                        //   w-100 assignmentbox
                        // `}
                        // style={{
                        //   width: "100%",
                        //   paddingLeft: "3rem",
                        //   paddingRight: "3rem",
                        // }}
                        value={
                                 <div>
                                 <div className="titless">{x.assignmentName}</div>
                                 <div className="SubTitless">{x.SubjectName}</div>
                                 <div className="titleDown">
                                   <span>Total Mark: <b>{x.TotalMarks}</b> </span><br />
                                   <span>Last Date of Submission :<b> {x.submittedDate} </b></span>
                                </div>                                                              
                                 </div>
                          }
                        
                        onClick={() => {
                            setAssig_tabel(false);
                            setPhysics_Tabel(false);
                            setBreadcrumAs(false);
                            setBreadcrumAs1(false);
                            setBreadcrumAs2(false);
                            setBreadcrumAs3(true);
                            setBreadcrumAs4(false);
                            setReviewTableStatus(true);

                            setAssignmentSecondData([{
                              No:x.No,
                              assignmentId:x.assignmentId,
                              SubjectName:x.SubjectName,
                              subBId:x.subBId,
                              assignmentName:x.assignmentName,
                              Assignedby: x.Assignedby,
                              TotalMarks: x.TotalMarks,
                              MarksObtained:x.MarksObtained,
                              Status:x.Status,
                              submittedDate:x.submittedDate,
                              DateofSubmission:x.DateofSubmission,
                              Review:x.Review,
                              assignmentId:x.assignmentId,
                            }])
                            
                          // Student_Assignment_Report_Table(x.assignmentValue);
                          Student_Report_Question(x.assignmentValue)
                          Student_Report_Answeronly(x.assignmentValue,Report_studentId)                    
                          StudentName_List_Status(x.sectionId,x.assignmentId)
                        
                        }}
                          
                      />
                    
                    </div>

                  ))}

                  
                  </div>
                </div>
              )}


            

             {reviewTableStatus && (

      <>

  
        <div className="d-flex justify-content-end mt-1 mb-2">
            <button
              className="backBtn"
              onClick={() => {
                setBreadcrumAs3(true);
                setPhysics_Tabel(true)
                setReviewTableStatus(false);
              }}
            >
              Back
            </button>
       </div>
      < div className="tabelstyle">

                  <CSmartTable
                    columns={SecondData}
                    items={assignmentSecondData}
                    scopedColumns={{
                    
                    }}
                    tableProps={{
                      striped: true,
                      hover: true,
                      responsive: true,
                    }}
                  />
      </div>
      <br />
        <div style={{ display: "flex", justifyContent: "center" }}>
        <KHeader
          containerClassName={
            "align-items-center borderColor marginLeft-0 w-auto "
          }
          frontElement={
            <div className="d-flex gap-4">
              <div> <b>Total</b> : {studentNameList.length} </div>
              <div> <b>Reviewed</b> : {studentNameList.filter(z=>z.isReviewed === true).length}</div>
              <div> <b>Not Submitted</b> : {studentNameList.filter(z=>z.isSubmitted !== true).length}</div>
              <div> <b>To Review</b> : {studentNameList.filter(z=>z.isReviewed !== true).length} </div>
            </div>
          }
        />
      </div>
     
              <div className="mt-2 d-flex justify-content-center pl-5 pr-5">
              <div
                className="tabelstyle"
                style={{
                  width: "80%",
                }}
              >
              {/* assignment status student name list */}
                <CSmartTable
                  // items={studentNameList}

                  columns={student_Table_NameList}
                  columnFilter
                  columnSorter
                  items={studentNameList.map((x,i) =>({
                    ...x,
                    No:i + 1,
                  }))}
                  
                  itemsPerPage={100}
                  // itemsPerPageSelect={studentNameList.length > 5}
                  // pagination
                
                  scopedColumns={{
                  
                    SubmittedDate: (item) => (
                    
                        <td >
                          {/* {JSON.stringify(studentNameMatch)} */}
                          {assignmentReportListdata.find(data => data.studentId === item.studentId)?.submittedDate
                            ?
                            parse(assignmentReportListdata.find(data => data.studentId === item.studentId)?.submittedDate)
                            : "-"
                          }
                        </td>
                      ),
                      TotalMarks: (item) => (
                        
                          <td >
                            {assignmentReportListdata.find(data => data.studentId === item.studentId)?.TotalMarks
                              ?
                              parse(assignmentReportListdata.find(data => data.studentId === item.studentId)?.TotalMarks)
                              : "-"
                            }
                          </td>
                        ),
                      
                    Action: (item) => (
                      
                      <td>
                        <button
                          type="button"
                          className=" clickmeBtn "
                          title="Delete"
                          onClick={() => {
                            // console.log("4545154",item)
                            setSubTableBox(false);
                            setSubTable(false);
                            setMainTable(false);
                            setReport(false);
                            setAssignmentstatusST(true);
                            setReviewTableStatus(false);
                            setName({
                              Name:item.Name,
                            })
                            
                            Student_Status_Question(item.assignmentId,item.studentId)
                            // StudentName_Report_Question_API(item.studentId)
                          }}
                        >
                          View 
                        </button>
                      </td>
                    ),
                  }}
                  tableProps={{
                    striped: true,
                    hover: true,
                    responsive: true,
                  }}
                />
              </div>
              </div>
              <br />  <br />
              </>
              )}

              {assignmentstatusST &&(
                <>
                <div className="titleQA_center">
                    <h4>Assignment Status of <span style={{color:"#3377ff"}}>{name.Name}</span></h4>
                  </div>
                 <div className="d-flex justify-content-end mt-3">
                          <button
                            className="backBtn"
                            onClick={() => {
                              setAssign_Btn(false);
                              setAssignmentReport(false);
                              setAssignment_Status(false);
                              setAssig_tabel(false);
                              setPhysics_Tabel(false);
                              setBreadcrumAs1(false);
                              setBreadcrumAs2(false);
                              setBreadcrumAs3(true);
                              setReviewTableStatus(true);
                              setAssignmentstatusST(false);
                            }}
                          >
                            Back
                          </button>
                 </div>
                 <div className=" d-flex flex-column mt-2 pl-3 pr-3 tabelstyle ">
                    <CSmartTable
                      items={assignmentSecondData}
                      columnSorter
                      itemsPerPage={5}
                      itemsPerPageSelect={assignmentSecondData.length > 5}
                      pagination

                      columns={columns_SecondTabel_Assign_status}
                      scopedColumns={{

                        MarksObtained: (item) => (
                            
                              <td >
                                
                                {question.find(data => data.questionId === item.questionId)?.MarksObtained
                                  ?
                                  parse(question.find(data => data.questionId === item.questionId)?.MarksObtained)
                                  : "-"
                                }
                              </td>
                            ),
                        
                           

                        Action: (item) => (
                          <tr>
                            <td></td>
                          </tr>
                        ),
                      }}
                      tableProps={{
                        striped: true,
                        hover: true,
                        responsive: true,
                      }}
                    />
                  </div>
                  <div className="mt-5 d-flex justify-content-between">
                    <h4>Question and Answer </h4>
                    <h5 className="mr-3">
                     Total Marks :{totalmarks}
                    </h5>
                  </div>
                  <div className="d-flex flex-column pl-3 pr-3 tabelstyle ">
                    <CSmartTable
                      items={question}
                      columnSorter
                      columnFilter
                      itemsPerPage={5}
                      itemsPerPageSelect={question.length > 5}
                      pagination
                      columns={columns_ThirdTabel_Assign_status}
                      scopedColumns={{
                        // Question : (item) =>(<td className="textalign">{parse(item.Question)}</td>),

                        Question : (item) =>(<td className="textalign">
                        
                       <span><b>Question :</b>{parse(item.Question)}</span>
                       <div>

                            {(
                              item.answerType === "RadioButton" && 
                              <span className="optionName"> 

                              {parse(item.option)}
                            </span>

                            )}
                        </div>
                       <span><b>Correct Answer :</b> {parse(item.correctAnswer)}</span>
                       <span >
                       <b>Student Answer : </b> {parse(item.studentAnswer)}</span>
                       
                     </td>),
                      MarksObtained: (item) => (
                        <td>
                         
                          {item.MarksObtained}
  
                        </td>
                      ),
                      comment: (item) => (
                        <td>
                         {item.comment}
                          {/* {question.find(data => data.QuestionId === item.questionId)?.comment
                            ?
                            parse(question.find(data => data.QuestionId === item.questionId)?.comment)
                            : <p style={{color:"red"}}>-</p>
                          } */}
                        </td>
                      ),
                      }}
                      tableProps={{
                        hover: true,
                        responsive: true,
                      }}
                    />
                  </div>
                  </>
                )}


              {assig_tabel && (
                <div>
                  <div className="d-flex justify-content-end mt-3">
                    <button
                      className="backBtn"
                      onClick={() => {
                        setPhysics_Tabel(true);
                        setAssignment_Status(false);
                        setAssig_tabel(false);
                      }}
                    >
                      Back
                    </button>
                  </div>

                  <div className="d-flex flex-column pl-3 pr-3 tabelstyle ">
                    <CSmartTable
                      items={assignmentData_2_Assign_status}
                      columnSorter
                      itemsPerPage={5}
                      itemsPerPageSelect={assignmentData_2_Assign_status.length > 5}
                      pagination
                      columns={columns_SecondTabel_Assign_status}
                      scopedColumns={{
                        Action: (item) => (
                          <tr>
                            <td></td>
                          </tr>
                        ),
                      }}
                      tableProps={{
                        hover: true,
                        responsive: true,
                      }}
                    />
                  </div>
                  <div className="titleQA mt-4">
                    <p>Question and Answer</p>
                  </div>
                  <div className="d-flex flex-column pl-3 pr-3 tabelstyle ">
                    <CSmartTable
                      items={assignmentData_3_Assign_status}
                      columnSorter
                      columnFilter
                      itemsPerPage={5}
                      itemsPerPageSelect={assignmentData_3_Assign_status.length > 5}
                      pagination
                      columns={columns_ThirdTabel_Assign_status}
                      scopedColumns={{
                        Action: (item) => (
                          <td>
                            <button
                              type="button"
                              className=" clickmeBtn "
                              title="Delete"
                              onClick={() => {
                                setVisible(!visible);
                              }}
                            >
                              Click
                              {/* <i className="fa fa-trash-o" /> */}
                            </button>
                          </td>
                        ),
                      }}
                      tableProps={{
                        hover: true,
                        responsive: true,
                      }}
                    />
                  </div>
                </div>
              )}
              <>
                <CModal
                  alignment="center"
                  visible={visible}
                  onClose={() => setVisible(false)}
                  className="ModalHeight"
                >
                  <div
                    style={{
                      backgroundColor: "white",
                      padding: "10px 30px 30px 30px",
                      borderRadius:"5px",
                    }}
                  >
                    <CModalHeader></CModalHeader>
                    <CModalBody className="pt-2 pb-5 pr-4 pl-4">
                      Lorem ipsum dolor sit amet. Ad expedita ullam aut incidunt
                      enim sit officiis voluptatem quo laudantium unde. Ex
                      dolorem autem et minus voluptates nam incidunt consequatur
                      in adipisci cumque
                    </CModalBody>
                  </div>
                </CModal>
              </>
{/* Assignment Status ......END */}

             {breadcrumAs4 && (
                <div >
                  <CBreadcrumb>
                    <CBreadcrumbItem
                      onClick={() => {
                        setAssign_Btn(true);
                        setAssignmentReport(false);
                        setAssignment_Status(false);
                        setAssig_tabel(false);
                        setBreadcrumAs(true);
                        setBreadcrumAs1(false);
                        setBreadcrumAs2(false);
                        setBreadcrumAs4(false);
                        setBreadcrumAs3(false);
                      }}
                    >
                      Report
                    </CBreadcrumbItem>
                    <CBreadcrumbItem
                      onClick={() => {
                        setAssign_Btn(false);
                        setAssignmentReport(false);
                        setAssignment_Status(true);
                        setAssig_tabel(false);
                        setBreadcrumAs1(true);
                        setBreadcrumAs2(false);
                        setBreadcrumAs3(false);
                        setBreadcrumAs4(false);
                        setPhysics_Tabel(false);
                      }}
                    >
                      Assignment Status
                    </CBreadcrumbItem>
                    <CBreadcrumbItem
                      onClick={() => {
                        setAssign_Btn(false);
                        setAssignmentReport(false);
                        setAssignment_Status(false);
                        setAssig_tabel(false);
                        setPhysics_Tabel(true);
                        setBreadcrumAs1(false);
                        setBreadcrumAs2(true);
                        setBreadcrumAs3(false);
                        setBreadcrumAs4(false);
                      }}
                    >
                      Physics   
                   
                    </CBreadcrumbItem>
                    <CBreadcrumbItem active>Assignment</CBreadcrumbItem>
                  </CBreadcrumb>

                  <div className="d-flex justify-content-end mt-3 mb-3">
                    <button
                      className="backBtn"
                      onClick={() => {
                        setBreadcrumAs4(false);
                        setPhysics_Tabel(true);
                        setBreadcrumAs2(true);
                      }}
                    >
                      Back
                    </button>
                  </div>

                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <KHeader
                      containerClassName={
                        "align-items-center borderColor marginLeft-0 w-auto"
                      }
                      frontElement={
                        <div className="d-flex gap-4">
                          <div> Total : 35 </div>
                          <div> Submit : 30 </div>
                          <div> UnSubmit : 5 </div>
                          <div> Review : 20 </div>
                        </div>
                      }
                    />
                  </div>

                  <div
                    className="mx-5 my-3"
                    style={{ textAlign: "center", border: "1px solid #dadbdd" }}
                  >
                    <CSmartTable
                      columns={columnsss}
                      columnFilter
                      columnSorter
                      items={usersData111}
                      scopedColumns={{
                        Action: (item) => (
                          <td>
                            <button
                              type="button"
                              className=" clickmeBtn "
                              onClick={() => {
                                setAssig_tabel(false);
                                setPhysics_Tabel(false);
                                setBreadcrumAs(false);
                                setBreadcrumAs1(false);
                                setBreadcrumAs2(false);
                                setBreadcrumAr3(false);
                                setBreadcrumAs4(false);
                                setBreadcrumAs5(true);
                              }}
                            >
                              Click
                              {/* <i className="fa fa-trash-o" /> */}
                            </button>
                          </td>
                        ),
                      }}
                      tableProps={{
                        striped: true,
                        hover: true,
                        responsive: true,
                      }}
                    />
                  </div>
                </div>
              )}
              {breadcrumAs5 && (
                <div>
                  <CBreadcrumb>
                    <CBreadcrumbItem
                      onClick={() => {
                        setAssign_Btn(true);
                        setAssignmentReport(false);
                        setAssignment_Status(false);
                        setAssig_tabel(false);
                        setBreadcrumAs(true);
                        setBreadcrumAs1(false);
                        setBreadcrumAs2(false);
                        setBreadcrumAs4(false);
                        setBreadcrumAs3(false);
                        setBreadcrumAs5(false);
                      }}
                    >
                      Report
                    </CBreadcrumbItem>
                    <CBreadcrumbItem
                      onClick={() => {
                        setAssign_Btn(false);
                        setAssignmentReport(false);
                        setAssignment_Status(true);
                        setAssig_tabel(false);
                        setBreadcrumAs1(true);
                        setBreadcrumAs2(false);
                        setBreadcrumAs3(false);
                        setBreadcrumAs4(false);
                        setBreadcrumAs5(false);
                        setPhysics_Tabel(false);
                      }}
                    >
                      Assignment Status
                    </CBreadcrumbItem>
                    <CBreadcrumbItem
                      onClick={() => {
                        setAssign_Btn(false);
                        setAssignmentReport(false);
                        setAssignment_Status(false);
                        setAssig_tabel(false);
                        setPhysics_Tabel(true);
                        setBreadcrumAs1(false);
                        setBreadcrumAs2(true);
                        setBreadcrumAs3(false);
                        setBreadcrumAs4(false);
                        setBreadcrumAs5(false);
                      }}
                    >
                      Physics
                    </CBreadcrumbItem>
                    <CBreadcrumbItem active>Assignment</CBreadcrumbItem>
                  </CBreadcrumb>

                  <div className="d-flex justify-content-end mt-3 mb-3">
                    <button
                      className="backBtn"
                      onClick={() => {
                        setBreadcrumAs4(true);
                        setPhysics_Tabel(false);
                        setBreadcrumAs2(false);
                        setBreadcrumAs5(false);
                      }}
                    >
                      Back
                    </button>
                  </div>

                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <KHeader
                      title="Assignment Report for Anbalagan"
                      style={{
                        marginLeft: "0px !important",
                      }}
                    />
                  </div>

                  <div
                    className="mx-5 my-3"
                    style={{
                      textAlign: "center",
                      border: "1px solid #dadbdd",
                    }}
                  >
                    <CSmartTable
                      columns={columnsssassi}
                      columnFilter
                      columnSorter
                      items={usersData111asi}
                      tableProps={{
                        striped: true,
                        hover: true,
                        responsive: true,
                      }}
                    />
                  </div>

                  <div className="mt-4 mx-3">
                    {" "}
                    <KHeader
                      title="Question & Answers"
                      style={{
                        marginLeft: "0px !important",
                      }}
                    />
                  </div>
                  <div
                    className="mx-5"
                    style={{ textAlign: "center", border: "1px solid #dadbdd" }}
                  >
                    <CSmartTable
                      columns={columnquet}
                      columnFilter
                      columnSorter
                      items={usersData1lquest}
                      tableProps={{
                        striped: true,
                        hover: true,
                        responsive: true,
                      }}
                    />
                  </div>
                </div>
              )}

              {/* ------------END  - Raj-------------------------- */}
            
 {/* Assignment Report Screen Page start */}

              {breadcrumAr1 && (
                <div>
                  <CBreadcrumb>
                    <CBreadcrumbItem
                      onClick={() => {
                        setAssign_Btn(true);
                        setAssignmentReport(false);
                        setAssignment_Status(false);
                        setBreadcrumAr1(false);
                        setPhysics_Tabel(false);
                        setBreadcrumAs1(false);
                        setBreadcrumAs2(false);
                        setBreadcrumAs(true);
                        setReviewTableStatus(false);
                      }}
                    >
                      Report
                    </CBreadcrumbItem>

                    <CBreadcrumbItem active>Assignment Report </CBreadcrumbItem>
                  </CBreadcrumb>
                </div>
              )}
              {breadcrumAr2 && (
                <div>
                  <CBreadcrumb>
                    <CBreadcrumbItem
                      onClick={() => {
                        setAssign_Btn(true);
                        setAssignmentReport(false);
                        setAssignment_Status(false);
                        setBreadcrumAr1(false);
                        setPhysics_Tabel(false);
                        setBreadcrumAs1(false);
                        setBreadcrumAs2(false);
                        setBreadcrumAs(true);
                        setBreadcrumAr1(false);
                        setBreadcrumAr2(false);
                        setBreadcrumAs4(false);
                        setSubject(false);
                        setReport(false);
                        setReviewTableStatus(false);
                        setAssignmentstatusSTReport(false);
                      }}
                    >
                      Report
                    </CBreadcrumbItem>

                    <CBreadcrumbItem
                      onClick={() => {
                        setAssign_Btn(false);
                        setAssignmentReport(true);
                        setAssignment_Status(false);
                        setBreadcrumAr1(true);
                        setBreadcrumAr2(false);
                        setPhysics_Tabel(false);
                        setBreadcrumAs1(false);
                        setBreadcrumAs2(false);
                        setBreadcrumAs4(false);
                        setBreadcrumAs(true);
                        setBreadcrumAs(false);
                        setSubject(false);
                        setReport(false);
                        setReviewTableStatus(false);
                        setAssignmentstatusSTReport(false);
                      }}
                    >
                      Assignment Report
                    </CBreadcrumbItem>
                    <CBreadcrumbItem active>
                      Subjects
                    </CBreadcrumbItem>

                    {/* {subjectNameArrydata1.map((x, e) => (
                      <CBreadcrumbItem active>{x.SubjectName}</CBreadcrumbItem>
                    ))} */}

                    {/* wait */}
                  </CBreadcrumb>
                </div>
              )}

              {breadcrumAr3 && (
                <div>
                  <CBreadcrumb>
                    <CBreadcrumbItem
                      onClick={() => {
                        setAssign_Btn(true);
                        setAssignmentReport(false);
                        setAssignment_Status(false);
                        setBreadcrumAr1(false);
                        setPhysics_Tabel(false);
                        setBreadcrumAs1(false);
                        setBreadcrumAs2(false);
                        setBreadcrumAs(true);
                        setBreadcrumAr1(false);
                        setBreadcrumAr2(false);
                        setBreadcrumAr3(false);
                        setBreadcrumAs4(false);
                        setSubject(false);
                        setSubjectReport(false);
                        setMainTable(false);
                        setSubTable(false);
                        setSubTableBox(false);
                        setReport(false);
                        setAssignmentstatusSTReport(false);
                      }}
                    >
                      Report
                    </CBreadcrumbItem>

                    <CBreadcrumbItem
                      onClick={() => {
                        setAssign_Btn(false);
                        setAssignmentReport(true);
                        setAssignment_Status(false);
                        setBreadcrumAr1(true);
                        setBreadcrumAr2(false);
                        setPhysics_Tabel(false);
                        setBreadcrumAs1(false);
                        setBreadcrumAs2(false);
                        setBreadcrumAs(true);
                        setBreadcrumAs(false);
                        setSubject(false);
                        setSubject(false);
                        setSubjectReport(false);
                        setBreadcrumAr3(false);
                        setBreadcrumAs4(false);
                        setMainTable(false);
                        setSubTable(false);
                        setSubTableBox(false);
                        setReport(false);
                        setReviewTableStatus(false);
                        setAssignmentstatusSTReport(false);
                      }}
                    >
                      Assignment Report
                    </CBreadcrumbItem>
                    <CBreadcrumbItem
                      onClick={() => {
                        setAssign_Btn(false);
                        setAssignmentReport(false);
                        setAssignment_Status(false);
                        setBreadcrumAr1(false);
                        setBreadcrumAr2(false);
                        setPhysics_Tabel(false);
                        setBreadcrumAs1(false);
                        setBreadcrumAs(false);
                        setBreadcrumAs(false);
                        setReport(false);
                        setSubject(true);
                        setSubjectReport(false);
                        setBreadcrumAr3(false);
                        setBreadcrumAs4(false);
                        setBreadcrumAr2(true);
                        setMainTable(false);
                        setSubTable(false);
                        setSubTableBox(false);
                        setReviewTableStatus(false);
                        setAssignmentstatusSTReport(false);
                      }}
                    >
                       <CBreadcrumbItem style={{paddingLeft:"15px"}}>
                      Subjects
                    </CBreadcrumbItem>
{/*                       
                      {subjectNameArrydata1.map((x, e) => (
                      <CBreadcrumbItem style={{paddingLeft:"15px"}}>{x.SubjectName}</CBreadcrumbItem>
                    ))} */}

                    </CBreadcrumbItem>
                    <CBreadcrumbItem active>Student Report List</CBreadcrumbItem>
                  </CBreadcrumb>
                </div>
              )}

              {assignmentReport && (
                <>
                  <div className="d-flex flex-row w-100 flex-md-row mt-3 gap-3">
                    {subjectsData.map((x, e) => (
                      <div key={e}>
                        <KButton
                          className={`px-6 py-3 mr-3  
                          w-100
                        `}
                          style={{
                            width: "100%",
                            paddingLeft: "3rem",
                            paddingRight: "3rem",
                          }}
                          value={x.label}
                          onClick={() => {
                            setAssignmentReportIO(false);
                            setAssignmentReport(false);
                            setSubject(true);
                            setSubjectReport(false);
                            setBreadcrumAr1(false);
                            setBreadcrumAr2(true);
                            setReviewTableStatus(false);
                            // Student_Assignment_Status_Table(x.kamSubjectId, x.userId);
                            Student_Assignment_Report_Table(x.kamSubjectId, x.classId, x.sectionId);
                            
                            //Report_Student_Assignment_Table_Second(x.value, x.userId);
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </>
              )}

              {/* {subject && (
                <div className="d-flex flex-row w-100 flex-md-row mt-3 gap-3">
                  <KButton
                    value={
                      <div
                        onClick={() => {
                          setAssignmentReportIO(false);
                          setAssignmentReport(false);
                          setBreadcrumAr3(true);
                          setBreadcrumAs4(false);
                          setBreadcrumAr2(false);
                          setSubject(false);
                          setSubjectReport(true);
                        }}
                      >
                        
                               {assignmentReportList.map((x, e) => (
                                        <div key={e}  >
                                          <KButton
                                          
                                            className={`px-6 py-3 mr-3  
                                            w-100
                                          `}
                                            style={{
                                              width: "100%",
                                              paddingLeft: "3rem",
                                              paddingRight: "3rem",
                                            }}
                                            // value={x.Assignment}
                                            onClick={() => {
                                              setAssignmentReportIO(false);
                                              setAssignmentReport(false);
                                              setSubject(true);
                                              setSubjectReport(false);
                                              setBreadcrumAr1(false);
                                              setBreadcrumAr2(true);
                                              
                                             
                                            }}
                                            
                                          />
                                          <div style={{ fontSize: "18px" }}>{x.Assignment}</div>
                                          <div style={{ fontSize: "13px" }}>Total Marks - {x.TotalMarks}</div>
                                          <div className="d-flex flex-row gap-3 justify-content-center">
                                          <div style={{ fontSize: "12px" }}>
                                            <u>Posted Date:</u> {x.PostedDate}
                                          </div>
                                          <div style={{ fontSize: "12px" }}>
                                            <u>Date of Submission</u> - {x.submittedDate}
                                          </div>
                                          </div>
                                        
                                        </div>
                                      ))}

                        
                      </div>
                    }
                   
                    
                  />
                 
                </div>
              )} */}
             
            {subject && (
                <div className="flex-wrap d-flex flex-row w-100 flex-md-row mt-3 gap-3">
                  
                  {/* {assignmentReportList.map((x, e) => ( */}
                  
                  {/* report */}

                  {assignmentReportList.map((x, e) => (
                  
                  <div key={e}>
                      <KButton
                      className={`SubjectStyle NewstyleBox`}
                      value={
                       <div>
                       <div className="titless">{x.Assignment}</div>
                       <div className="SubTitless">{x.SubjectName}</div>
                       <div className="titleDown">
                         <span>Total Mark  : <b>{x.TotalMarks}</b> </span><br />
                         <span>Last Date of Submission :<b> {x.lsd} </b></span>
                      </div>                                                              
                       </div>
                      }
                        // className={`px-6 py-3 mr-3  
                        //   w-100 assignmentbox
                        // `}
                        // style={{
                        //   width: "100%",
                        //   paddingLeft: "3rem",
                        //   paddingRight: "3rem",
                        // }}
                        // value={
                        //   <div >
                        //   <div style={{ fontSize: "18px",padding:"10px" }}>{x.Assignment}</div>
                        //   <div style={{ fontSize: "13px",paddingBottom:"15px" }}>{x.SubjectName}</div>
                        //   <div className="d-flex flex-row gap-3 justify-content-center">
                        //   <div style={{ fontSize: "12px",paddingBottom:"15px" }}>
                        //     <u>Last Date of Submission :</u> {x.lsd}
                        //   </div>
                        //   <div style={{ fontSize: "12px"}}>
                        //     <u>Total Marks</u> - {x.TotalMarks}

                        //   </div>
                        //   </div>
                        //   </div>
                        //   }
                        
                        onClick={() => {
                         
                          setAssignmentReportIO(false);
                          setAssignmentReport(false);
                          setSubject(false);
                          setSubjectReport(true);
                          setBreadcrumAr1(false);
                          setBreadcrumAr2(false);
                          setBreadcrumAr3(true);
                          setBreadcrumAs4(false);
                          setSubjectReport(true);
                          setReviewTableStatus(false);
                          // Student_Assignment_Report_Table(x.assignmentValue);
                          Student_Report_Question(x.assignmentValue)
                          Student_Report_Answeronly(x.assignmentValue,Report_studentId)                    
                          setAssignmentSecondDataReport([{
                            No:x.No,
                            assignmentId:x.assignmentId,
                            SubjectName:x.SubjectName,
                            assignmentName:x.Assignment,
                            subBId:x.subBId,
                            Assignment:x.Assignment,
                            Assignedby: x.Assignedby,
                            TotalMarks: x.TotalMarks,
                            MarksObtained:x.MarksObtained,
                            submittedDate:x.lsd,
                            Status:x.Status,
                            lsd:x.lsd,
                            DateofSubmission:x.DateofSubmission,
                            Review:x.Review,
                            assignmentId:x.assignmentId,
                          }])
                        setAssignmentReportListData1({
                          
                          assignmentId:x.assignmentId,
                         
                      })

                      StudentName_List_Status(x.sectionId,x.assignmentId)
                        
                        }}
                          
                      />
                    
                    </div>
                  ))}
                </div>
              )}


              {subjectReport && (
                
                <>

                    <div className="d-flex justify-content-end mt-1 mb-2">
                                            <button
                                    className="backBtn"
                                    onClick={() => {
                                      setSubject(true);
                                      setSubjectReport(false);
                                    }}
                                  >
                                    Back 
                                  </button>
                     </div>
                     < div className="tabelstyle">
                                  <CSmartTable
                                    columns={SecondData}
                                    items={assignmentSecondDataReport}
                                    scopedColumns={{
                                    
                                    }}
                                    tableProps={{
                                      striped: true,
                                      hover: true,
                                      responsive: true,
                                    }}
                                  />
                        </div>
                        
                         
                   {/* Report Screen Table */}
                          <br />
                          <div style={{ display: "flex", justifyContent: "center" }}>
                            <KHeader
                              containerClassName={
                                "align-items-center borderColor marginLeft-0 w-auto"
                              }
                              frontElement={
                                <div className="d-flex gap-4">
                                  <div> <b>Total</b> : {studentNameList.length} </div>
                                  <div> <b>Reviewed</b> : {studentNameList.filter(z=>z.isReviewed === true).length}</div>
                                  <div> <b>Not Submitted</b> : {studentNameList.filter(z=>z.isSubmitted !== true).length}</div>
                                  <div> <b>To Review</b> : {studentNameList.filter(z=>z.isReviewed !== true).length} </div>
                                </div>
                              }
                            />
                          </div>
                          <div className="mt-2 d-flex justify-content-center pl-5 pr-5">
                    <div
                      className="tabelstyle"
                      style={{
                        width: "80%",
                      }}
                    >
 {/* assignment report student name list */}
                      <CSmartTable
                        columns={student_Table_NameList_Report}
                        columnFilter
                        columnSorter
                        itemsPerPage={10}
                        itemsPerPageSelect={studentNameList.length > 10}
                        pagination
                        // items={studentNameList}
                        items={studentNameList.map((x,i) =>({
                          ...x,
                          No:i + 1,
                        }))}
                        scopedColumns={{
                         
                          SubmittedDate: (item) => (
                           
                              <td >
                                {/* {JSON.stringify(studentNameMatch)} */}
                                {assignmentReportListdata.find(data => data.studentId === item.studentId)?.submittedDate
                                  ?
                                  parse(assignmentReportListdata.find(data => data.studentId === item.studentId)?.submittedDate)
                                  : "-"
                                }
                              </td>
                            ),
                            TotalMarks: (item) => (
                              
                                <td >
                                  {assignmentReportListdata.find(data => data.studentId === item.studentId)?.TotalMarks
                                    ?
                                    parse(assignmentReportListdata.find(data => data.studentId === item.studentId)?.TotalMarks)
                                    : "-"
                                  }
                                </td>
                              ),
                            
                          Action: (item) => (
                            
                            <td>
                              <button
                                type="button"
                                className=" clickmeBtn "
                                title="Delete"
                                onClick={() => {
                                  // console.log("4545",item)
                                  setSubTableBox(false);
                                  setSubTable(false);
                                  setMainTable(false);
                                  setReport(false);
                                  setSubjectReport(false)
                                  setAssignmentstatusSTReport(true);
                                  setNameReport({
                                    Name:item.Name,
                                  })
                                  setReviewTableStatus(false);
                                  Student_Status_Question(item.assignmentId,item.studentId)
                                  // StudentName_Report_Question_API(item.studentId)
                                }}
                              >
                                View
                              </button>
                            </td>
                          ),
                        }}
                        tableProps={{
                          striped: true,
                          hover: true,
                          responsive: true,
                        }}
                      />
                    </div>
                  </div>

                          <br /><br /><br />

                </>
              )}


               {assignmentstatusSTReport &&(
                <>
                <div className="titleQA_center">
                    <h4>Assignment Report of <span style={{color:"#3377ff"}}>{nameReport.Name}</span> </h4>
                  </div>
                 <div className="d-flex justify-content-end mt-3">
                          <button
                            className="backBtn"
                            onClick={() => {
                              setAssign_Btn(false);
                              setAssignmentReport(false);
                              setAssignment_Status(false);
                              setAssig_tabel(false);
                              setPhysics_Tabel(false);
                              setBreadcrumAs1(false);
                              setBreadcrumAs2(false);
                              setBreadcrumAs3(false);
                              setReviewTableStatus(false);
                              setSubjectReport(true)
                              setAssignmentstatusSTReport(false);
                            }}
                          >
                            Back
                          </button>
                 </div>
                 <div className="d-flex flex-column mt-2 pl-3 pr-3 tabelstyle ">
                    <CSmartTable
                      items={assignmentSecondDataReport}
                      columnSorter
                      pagination
                      columns={columns_SecondTabel_Assign_Report}
                      scopedColumns={{

                        MarksObtained: (item) => (
                            
                              <td >
                                
                                {question.find(data => data.questionId === item.questionId)?.MarksObtained
                                  ?
                                  parse(question.find(data => data.questionId === item.questionId)?.MarksObtained)
                                  : "-"
                                }
                              </td>
                            ),
                        
                        Action: (item) => (
                          <tr>
                            <td></td>
                          </tr>
                        ),
                      }}
                      tableProps={{
                        hover: true,
                      }}
                    />
                  </div>
                  {/* Assignment Report */}
                  <div className="mt-5 d-flex justify-content-between">
                    <h4>Question and Answer </h4>
                    <h5 className="mr-3">Total Marks :{totalmarks}</h5>
                  </div>
                  <div className="d-flex flex-column pl-3 pr-3 tabelstyle ">
                    <CSmartTable
                      items={question}
                      columnSorter
                      columnFilter
                      itemsPerPage={5}
                      itemsPerPageSelect={question.length > 5}
                      pagination

                      columns={columns_ThirdTabel_Assign_status}
                      scopedColumns={{
                        // Question : (item) =>(<td className="textalign">{parse(item.Question)}</td>),

                        Question : (item) =>(<td className="textalign">
                        
                       <span><b>Question :</b>{parse(item.Question)}</span>
                                  <div>

                                  {(
                                    item.answerType === "RadioButton" && 
                                    <span className="optionName"> 

                                    {parse(item.option)}
                                  </span>

                                  )}
                                  </div>
                       <span><b>Correct Answer :</b> {parse(item.correctAnswer)}</span>
                       <span >
                       <b>Student Answer : </b> {parse(item.studentAnswer)}</span>
                       {/* style={ item.studentAnswer === null ? { color:'red'} : {color : '#000'} } */}
                     </td>),
                      MarksObtained: (item) => (
                        <td>
                         
                          {item.MarksObtained}
  
                        </td>
                      ),
                      comment: (item) => (
                        <td>
                         {item.comment}
                          {/* {question.find(data => data.QuestionId === item.questionId)?.comment
                            ?
                            parse(question.find(data => data.QuestionId === item.questionId)?.comment)
                            : <p style={{color:"red"}}>-</p>
                          } */}
                        </td>
                      ),
                      }}
                      tableProps={{
                        hover: true,
                        responsive: true,
                      }}
                    />
                  </div>
                  </>
                )}

              {mainTable && (
                <>
                  <div className="  mt-5 d-flex justify-content-between">
                    <h4>Other Student Assignment Report</h4>
                    <button
                      className="backBtn mb-2"
                      onClick={() => {
                        setMainTable(false);
                        setSubTable(false);
                      }}
                    >
                      Back
                    </button>
                  </div>
                  <div className="tabelstyle ">
                    <CSmartTable
                      columns={Assignment_Report_table}
                      // columnFilter
                      columnSorter
                      itemsPerPage={5}
                      itemsPerPageSelect={assignmentReportListdata.length > 5}
                      pagination
                      items={assignmentReportListdata}
                      tableProps={{
                        striped: true,
                        hover: true,
                        responsive: true,
                      }}
                    />
                  </div>
                </>
              )}

              {subTable && (
                <>
                  <div className="mt-4 pl-5 pr-5">
                    <h4>All Report</h4>
                  </div>
                  <div className="d-flex justify-content-center pl-5 pr-5">
                    <div
                      className="tabelstyle"
                      style={{
                        width: "100%",
                      }}
                    >
                      <CSmartTable
                        columns={student_Table_NameList}
                        columnFilter
                        columnSorter
                        itemsPerPage={5}
                        itemsPerPageSelect={studentNameList.length > 5}
                        pagination

                        items={studentNameList}
                        scopedColumns={{
                         
                          SubmittedDate: (item) => (
                            // console.log("dfjksdf",item),
                              <td >
                                {/* {JSON.stringify(studentNameMatch)} */}
                                {assignmentReportListdata.find(data => data.studentId === item.studentId)?.submittedDate
                                  ?
                                  parse(assignmentReportListdata.find(data => data.studentId === item.studentId)?.submittedDate)
                                  : "-"
                                }
                              </td>
                            ),
                            TotalMarks: (item) => (
                              // console.log("dfjksdf",item),
                                <td >
                                  {assignmentReportListdata.find(data => data.studentId === item.studentId)?.TotalMarks
                                    ?
                                    parse(assignmentReportListdata.find(data => data.studentId === item.studentId)?.TotalMarks)
                                    : "-"
                                  }
                                </td>
                              ),
                            
                          Action: (item) => (
                            
                            <td>
                              <button
                                type="button"
                                className=" clickmeBtn "
                                title="Delete"
                                onClick={() => {
                                  setSubTableBox(true);
                                  setSubTable(false);
                                  setMainTable(false);
                                  setReport(false);
                                  StudentName_Report_Question_API(item.studentId)
                                }}
                              >
                                View
                              </button>
                            </td>
                          ),
                        }}
                        tableProps={{
                          striped: true,
                          hover: true,
                          responsive: true,
                        }}
                      />
                    </div>
                  </div>
                </>
              )}

              {subTableBox && (
                <>
                  <div className="mt-5 d-flex justify-content-between">
                    <h4>Question and Answer </h4>
                    <button
                      className="backBtn mb-2"
                      onClick={() => {
                        setMainTable(true);
                        setSubTable(true);
                        setSubTableBox(false);
                      }}
                    >
                      Back 
                    </button>
                  </div>
                  <div className="tabelstyle">
                    <CSmartTable
                      columns={QuestionAnswer_Report_student}
                      columnFilter
                      columnSorter
                      itemsPerPage={5}
                      itemsPerPageSelect={questionReportName.length > 5}
                      pagination
                      items={questionReportName}
                      scopedColumns={{
                        Question : (item) =>(<td className="textalign">
                          
                          <span><b>Question :</b>{parse(item.Question)}</span>
                          <span><b>Correct Answer :</b> {parse(item.correctAnswer)}</span>
                          <span>
                          <b>Student Answer :</b> {parse(item.studentAnswer)}</span>
                        </td>),}}
                      tableProps={{
                        striped: true,
                        hover: true,
                        responsive: true,
                      }}
                    />
                  </div>
                </>
              )}

              {report && (
                <>
                  <div className="mt-5 d-flex justify-content-between">
                    <h4>My Report</h4>
                    <button
                      className="backBtn mb-2"
                      onClick={() => {
                        setMainTable(false);
                        setSubTable(false);
                        setSubTableBox(false);
                        setReport(false);
                      }}
                    >
                      Back
                    </button>
                  </div>

                  <div
                    className="tabelstyle "
                    // style={{ textAlign: "center", border: "1px solid #dadbdd" }}
                  >
                    <CSmartTable
                      items={assignmentReportListdata}
                      columnSorter
                      itemsPerPage={5}
                      itemsPerPageSelect={assignmentReportListdata.length > 5}
                      pagination

                      columns={Assignment_Report_table}
                      tableProps={{ hover: true, responsive: true }}
                    />
                  </div>

                  <div className="mt-4">
                    <h4>Question & Answer</h4>
                  </div>

                  <div
                    className=""
                    style={{ textAlign: "center", border: "1px solid #dadbdd" }}
                  >
                    <CSmartTable
                      items={questionReport}
                      columnFilter
                      columnSorter
                      itemsPerPage={5}
                      itemsPerPageSelect={questionReport.length > 5}
                      pagination
                      columns={QuestionAnswer_Report}
                      scopedColumns={{
                        Question : (item) =>(<td className="textalign">
                          
                          <span><b>Question :</b>{parse(item.Question)}</span>
                          <span><b>Correct Answer :</b> {parse(item.correctAnswer)}</span>
                          <span>
                          <b>Student Answer :</b> {parse(item.studentAnswer)}</span>
                        </td>),
//  style={ item.studentAnswer === null ? { color:'#000'} : {color : 'red'} }
                        //   correctAnswer: (item) => (
                        //   <td>
                        //     {/* {JSON.stringify(item)} */}
                        //     {answerReport.find(data => data.QuestionId === item.questionId)?.correctAnswer
                        //       ?
                        //       parse(answerReport.find(data => data.QuestionId === item.questionId)?.correctAnswer)
                        //       : "-"                            
                        //     }
                        //   </td>
                        // ),
                        // comment: (item) => (
                        //   <td>
                        //     {/* {JSON.stringify(item)} */}
                        //     {answerReport.find(data => data.QuestionId === item.questionId)?.comment
                        //       ?
                        //       parse(answerReport.find(data => data.QuestionId === item.questionId)?.comment)
                        //       : "-"                            
                        //     }
                        //   </td>
                        // ),
                        // obtainedMark: (item) => (
                        //   <td>
                        //     {/* {JSON.stringify(item)} */}
                        //     {answerReport.find(data => data.QuestionId === item.questionId)?.obtainedMark
                        //       ?
                        //       parse(answerReport.find(data => data.QuestionId === item.questionId)?.obtainedMark)
                        //       : "-"
                        //     } 81227 93638
                        //   </td>
                        // ),
                      
                      }}

                      // scopedColumns={{
                      //   Action: (item) => (
                      //     <td>
                      //       {/* <button
                      //         type="button"
                      //         className="clickmeBtn"
                      //         title="Delete"
                      //         onClick={() => {
                      //           setVisibleK(true);
                      //         }}
                      //       >
                      //         Click Me
                      //       </button> */}
                      //     </td>
                      //   ),
                      // }}

                      tableProps={{ hover: true, responsive: true }}
                    />
                  </div>
                </>
              )}
              
              <CModal
                alignment="center"
                visible={visibleK}
                onClose={() => setVisibleK(false)}
                className="ModalHeight"
              >
                <div
                  style={{
                    backgroundColor: "white",
                    padding: "10px 30px 30px 30px",
                    borderRadius:"5px",
                  }}
                >
                  <CModalHeader></CModalHeader>

                  <div style={{ padding: "25px", border: "1px solid #c6c8cb" }}>
                    <div
                      className="d-flex flex-row gap-3 align-items-center pb-3"
                      style={{ borderBottom: "1px solid #efefef" }}
                    >
                      <div>
                        <img src={image} alt="" width={30} height={30} />
                      </div>
                      <div>
                        <span style={{ fontWeight: "600" }}>
                          Balasubramani Physics
                        </span>
                      </div>
                    </div>

                    <div className="mt-3">
                      <h6>Well tried. Answer can be more focused.</h6>
                    </div>
                  </div>
                </div>
              </CModal>

            </div>
          </Fragment>
          {/* Raj END------------- */}
          {/* Content Ends */}
        </div>
      </div>

      {/* Footer Section Starts */}
      
      <Footer
        openForm={openForm}
        shoq={shoq}
        closeForm={closeForm}
        KpostIframe={KpostIframe}
      />
      
      {/* Footer Section Ends */}
    </div>
  );
};

export default TeacherReportStudentCreate;
