
import React, { Fragment, useState, useEffect } from "react";
import parse from "html-react-parser";
import moment from "moment";
import { toast } from "react-toastify";
import { CModal, CModalHeader } from "@coreui/react";
// Hooks
import useResponsive from "../../hooks/useResponsive";

// Component or Elements
import Header from "./HeaderNavbar";
import Footer from "./Footer";
import { ExportCSV } from "./ExportCSV";
// Styles
import "./StudentAssignment.css";

// Icons
import { IoIosArrowBack } from "react-icons/io";
import { AiOutlineFileAdd } from "react-icons/ai";

// Custom Components
import KDropdown from "./common/KDropdown";
import KInput from "./common/KInput";
import KButton from "./common/KButton";
import KHeader from "./common/KHeader";
import KRequiredLabel from "./common/KRequiredLabel";
import KEditor from "./common/KEditor";
import Warning from "./Toastify/Warning";
import Success from "./Toastify/Success";
import Danger from "./Toastify/Danger";
import { CSmartTable } from "@coreui/react-pro";
// Services
import {
  getAllStudentAssignments,
  getAssignmentQuestion,
  getStudentAssignment,
  postAnswersByStudent,
  getTableData,
  getTableDataLocal,
  Question_Answer_Submit,
  Student_assignment_table,
  Current_Assignment_List_table,
  assignmentStudentsReport_subjectlist,
} from "../../services/Apiservices";

const StudentAssignment = ({
  showProfile,
  falseShowProfile,
  notofShowProfile,
  ShowNotifications,
  showNotification,
  falseShowNotification,
  disableOnOuterTouch,
  shoq,
  KpostIframe,
  closeForm,
  openForm,
  goToMainRoutes
}) => {
  // Initialization
  // Hooks Initialization
  const { isBigScreen } = useResponsive();

  // SetState Initialization
  const [csvStructure, setCsvStructure] = useState([]);
  const [triggerEffect, setTriggerEffect] = useState(false);
  const [answerEditor, setAnswerEditor] = useState([]);
  const [draftCollection, setDraftCollection] = useState([]);
  const [responseCollection, setResponseCollection] = useState([]);
  const [answerData,setAnswerData] = useState([])
  const [tableAssignmentDisplay, setTableAssignmentDisplay] = useState([]);
  const [selectedAssignment, setSelectedAssignment] = useState("");
  const [dataVisible1,setDataVisible1] = useState(true);
  const [dataVisible2,setDataVisible2] = useState(false);
 
  const [visible, setVisible] = useState(false);
  const [datacheck,setDatacheck] = useState([]);
  const [questionDisplay, setQuestionDisplay] = useState([
    
    // {
    //   _id:"87878798798",
    //   question:"Hello"
    // }

  ]);
//  console.log("kjkljasjkas",questionDisplay)
  const[assignmentSecondData,setAssignmentSecondData] = useState([{

    No:"",
    assignmentId:"",
    SubjectName: "",
    assignmentName: "",
    staffName: "",
    totalMarks: "",
    lsd:"",
    studentPosted:"",
  }])
  

  useEffect(() => {
    assignment_table();
    // SubmitAnswer();
   
      Student_Answer_Poster_checker();
    
  }, []);

  // useEffect(() => {
  //   if (triggerEffect) {
  //     Student_Answer_Poster_checker();
  //   }
  // }, [triggerEffect]);
  
  // function refreshPage() {
  //   window.location.reload(false);
  // }

  const Student_Answer_Poster_checker = async () => { 
  
    setDatacheck([]);
    let response;
        const body = {
          
            studentId:localStorage.length > 0 && JSON.parse(localStorage.userCreds).userName,
            classId:localStorage.length > 0 && JSON.parse(localStorage.userCreds).variableID,
            sectionId:localStorage.length > 0 && JSON.parse(localStorage.userCreds).classID,
            // katbookSubjectId:katbookSubjectId,
        }


        // console.log("4544564",body)
      try {
        // response = await assignmentStudentsReport_subjectlist(katbookSubjectId,studentId);
        response = await assignmentStudentsReport_subjectlist(body);
        
        // console.log(response.data, "aaaa");
        if (response) {
          if (response.status==="success") {
            // toast.success(response.message);
            if (response.data) {
              const data = response.data.map((x, index) => {
                
                return {
                  No: index + 1,
                  Status:x.studentPosted === true ?"Submitted":"Not Submitted",
                  StatusNew : x.studentPosted === true ?"Posted" : "Answer",
                  assignmentId:x?.assignmentId,
                  // SubjectName: x?.katbookSubjectName,
                  // subBId: x.katbookSubjectId,
                  assignmentName: x.assignmentName,
                  // Assignedby: x.staffName,
                  // katbookSubjectId:x.katbookSubjectId,
                  // TotalMark: x.totalMarks,
                  // MarksObtained:`${x.obtainedMarks == '' ? '-' : x.obtainedMarks}`,
                  
                  // submittedDate: `${moment(x.postedDate).format("DD MMM YYYY") == '-' ? '-' : moment(x.postedDate).format("DD MMM YYYY") }`,
                  // DateofSubmission: `${moment(x.lsd).format("DD MMM YYYY")}`,
                  
                  // Review:`${x?.teacherReview === true?"Reviewed":"Not Review"}`,
                
                };
              });
              setDatacheck(data);
              console.log("sdfjlksjfs",data)
              // console.log("sdfkjlsdflksdf",data)
              // let uniqueArr = []
              // const getUniqueValue = data
              // for(let z=0;z<data.length;z++){
              //   if(uniqueArr.filter(y=>y.SubjectName === data[z].SubjectName).length === 0){
              //     uniqueArr.push(data[z])
              //   }
              // }
              // uniqueArr
              // setAsstTablesSubName(uniqueArr);
              
              
            }
          } 
          else {
            toast.error(response.message);
          }
        }
      } catch (err) {
        console.log(err);
      }
    };
  



  const table_data = [
    {
      key: "No",
      label: "S.No",
      _style: { width: "5%" },
    },
    {
      key: "katbookSubjectName",
      label: "Subject Name",
      _style: { width: "12%" },
    },
    {
      key: "assignmentName",
      label: "Assignment Name",
      _style: { width: "15%" },
    },
    {
      key: "staffName",
      label: "Assignment By Teacher",
      _style: { width: "17%" },
    },
    {
      key: "lsd",
      label: "Last Date of Submission",
      _style: { width: "15%" },
    },
    
    {
      key: "totalMarks",
      label: "Marks",
      _style: { width: "10%" },
    },
    //  {
    //   key: "studentPosted",
    //   label: "Status",
    //   _style: { width: "10%" },
    // },

  { key: "Action", _style: { width: "10%" },filter: false, sorter: false,},
    
  ];

  
  const table_dataSecond = [
    // {
    //   key: "No",
    //   label: "S.no",
    //   _style: { width: "5%" },
    // },
    {
      key: "katbookSubjectName",
      label: "Subject Name",
      _style: { width: "12%" },
    },
    {
      key: "assignmentName",
      label: "Assignment Name",
      _style: { width: "15%" },
    },
    {
      key: "staffName",
      label: "Assignment By Teacher",
      _style: { width: "17%" },
    },
    {
      key: "lsd",
      label: "Last Date of Submission",
      _style: { width: "15%" },
    },
    
    {
      key: "totalMarks",
      label: "Marks",
      _style: { width: "10%" },
    },
    //  {
    //   key: "studentPosted",
    //   label: "Status",
    //   _style: { width: "10%" },
    // },

  // { key: "Action", _style: { width: "10%" },filter: false, sorter: false,},
    
  ];


  const AnswerEditorHandler = (key, value, data) => {
    const previousData = answerEditor.find((k) => k.key === key);
    const removeExistingKey = answerEditor.filter((k) => k.key !== key);
    if (data !== undefined) {
      setAnswerEditor([
        ...removeExistingKey,
        {
          key: key,
          value: value,
          ...data,
        },
      ]);
    } else {
      setAnswerEditor([
        ...removeExistingKey,
        {
          ...previousData,
          key: key,
          value: value,
        },
      ]);
    }
  };

  const AnswerCollectionHandler = (key, value, isDraft, toRemove) => {
    const removeDraftCollection = draftCollection.filter(
      (item) => item.key !== key
    );
    const removeResponseCollection = responseCollection.filter(
      (item) => item.key !== key
    );

    const response = {
      key: key,
      ...value,
    };

    if (toRemove) {
      setResponseCollection(removeResponseCollection);
      setDraftCollection(removeDraftCollection);
    } else {
      if (isDraft) {
        setDraftCollection([...removeDraftCollection, response]);
        setResponseCollection(removeResponseCollection);
      } else {
        setDraftCollection(removeDraftCollection);
        setResponseCollection([...removeResponseCollection, response]);
      }
    }
  };
  var entities = {
    amp: "&",
    apos: "'",
    "#x27": "'",
    "#x2F": "/",
    "#39": "'",
    "#47": "/",
    lt: "<",
    gt: ">",
    nbsp: " ",
    quot: '"',
  };
  function decodeHTMLEntities(text) {
    return text.replace(/&([^;]+);/gm, function (match, entity) {
      return entities[entity] || match;
    });
  }
  const Question_Answer_Poster = async (_id) => {
   
    // const {_id } = _id;
    const body = {

      studentId: localStorage.length > 0 && JSON.parse(localStorage.userCreds).userName,
      assignmentId:_id,
      
    };
   
    // console.log("Bou1",body)
    const response = await getTableData(body);
    // const response = await getTableDataLocal(body);
    
    if (response) {
      if (response.status==="success") {
        // const parsedArray = JSON.parse(response.data);
        setQuestionDisplay(response.data);
        setResponseCollection(response.data.filter(z=> !(z.studentAnswer === null || z.studentAnswer === "") ).map(x=>{
          return {...x,key : x.questionId , value : x.studentAnswer}
        }))

        setAnswerEditor(response.data.filter(z=> !(z.studentAnswer === null || z.studentAnswer === "") ).map(x=>{
          return {...x,key : x.questionId , value : x.studentAnswer}
        }))

        // console.log("sdfjlskfjsdlk",response.data)
        try {
          const userCreds = await JSON.parse(localStorage.getItem("userCreds"));
          
          const csvStructureData = response.data.map((x) => [
            userCreds.userName,
            _id,
            x.assignmentId,
            decodeHTMLEntities(String(x.question).replace(/<(.|\n)*?>/g, "")),
            x.answerType === "RadioButton"
              ? x.options.reduce((prev, next, index) => {
                  if (index === 1) {
                    return `${index}) ${decodeHTMLEntities(
                      String(prev.options).replace(/<(.|\n)*?>/g, "")
                    )}\n ${index + 1}) ${decodeHTMLEntities(
                      String(next.options).replace(/<(.|\n)*?>/g, "")
                    )}`;
                  }
                  return `${prev}\n ${index + 1} ${decodeHTMLEntities(
                    String(next.options).replace(/<(.|\n)*?>/g, "")
                  )}`;
                })
              : "",
            "",
          ]);

          setCsvStructure([
            [
              [
                "StudentId",
                "AssignmentId",
                "questionId",
                "Question",
                "Options (Note : Kindly Type the Option Number in Answer Field ) ",
                "Answer",
              ],
              ...csvStructureData,
            ],
          ]);
        } catch (err) {
          console.log(err, "CSVSTRUCTURE");
        }
        setSelectedAssignment(_id);




      } else {
        toast.error(response.message);
      }
    }
  };

 

  const postAnswer = async () => {
    
    const userCreds = await JSON.parse(localStorage.getItem("userCreds"));
   
    // var today = new Date();
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    const formattedToday = yyyy + '/' + mm + '/' + dd;

    const body = {
      studentId: userCreds.userName,
      assignmentId: selectedAssignment,
      posted: true,
      postedDate: formattedToday,
      teacherReview: false
   
    };
   
    try {
     
      const response = await postAnswersByStudent(body);
      // console.log("sdfklsdfk",response)
      // alert(response)
      if (response) {
        
        if (response.status==="success") {
          toast.success(response.message);
          Student_Answer_Poster_checker()                      
          setSelectedAssignment("");
          setDraftCollection([]);
          setResponseCollection([]);
          setQuestionDisplay([]);
          setAnswerEditor([]);
          assignment_table();
        }
       } else {
        toast.error(response.message);
        
      // } 
      } 
    } catch (error) {
      
      console.log(error);
    }
  };

 
  const SubmitAnswer = async (assignmentId, questionId, value) => {
    try {
      const userCreds = JSON.parse(localStorage.getItem("userCreds"));
      if (!userCreds || !userCreds.userName) {
        throw new Error("User credentials are missing or invalid.");
      }
  
      const body = {
        studentId: userCreds.userName,
        assignmentId: assignmentId,
        questionId: questionId,
        studentAnswer: value?.value ?? "",
      };
      // console.log("ques01",body)

      const response = await Question_Answer_Submit(body);
      if (response.status==="success") {
        toast.success(response.message);
        // throw new Error("Failed to submit answer.");
        const data = response.data.map((x, index) => {
             
          return {
            No: index + 1,
            studentAnswer:`${x.studentAnswer === "" ? 'Student Not Answer' : x.studentAnswer}`,
          };
        });
        setAnswerData(data);
        // console.log("sdfjksdfjsdjsdf",data)

      }
      else {
        toast.error(response.message);
      }
      
    } catch (err) {
      console.log(err);
      // toast.error("Answer was already posted to the question");
    }
  };


  const assignment_table = async () => {

    const userCreds = await JSON.parse(localStorage.getItem("userCreds"));
    
    if (userCreds) {
      try {
        
        const body = {
          sectionId:userCreds. classID,
          classId:userCreds.variableID,
        }
       
        const response = await Current_Assignment_List_table(body);
        //  console.log("jsdkjfksldf",response)

        if(response.status === "403"){
          goToMainRoutes()
        }
        if(response.status==="success"){
          if (response.data){
            const data = response.data.map((x, i) => {
               
              return {
                No: i + 1,
                  _id : x.assignmentId,
                  assignmentId:x.assignmentId,
                  staffId: x.staffId,
                  staffName: x.staffName,
                  staffDesignation: x.staffDesignation,
                  katbookSubjectId: x.katbookSubjectId,
                  katbookSubjectName:x.katbookSubjectName,
                  assignmentName: x.assignmentName,
                  refInstaId:x.refInstId,
                  username: x.username,
                  classId: x.classId,
                  sectionId: x.sectionId,
                  totalMarks: x.totalMarks,
                  // lsd: x.lsd,
                  lsd:`${moment(x.lsd).format("DD-MMM-YYYY")}`,
                  studentPosted:x.studentPosted === true ? "Submitted" : "Not Submitted",
                };
              });
              setTableAssignmentDisplay(data)
              // console.log(data,"dsfkfds1414")
            }
        }else{
          // toast.error(response.message)
        }
               
      } catch (error) {
        console.log(error);
      }
    }
  };


  return (
    <div onClick={() => disableOnOuterTouch()}>
      {/* Header Section Starts  */}
      <div className="Navbar">
        <Header
          showProfile={showProfile}
          falseShowProfile={falseShowProfile}
          notofShowProfile={notofShowProfile}
          ShowNotifications={ShowNotifications}
          showNotification={showNotification}
          falseShowNotification={falseShowNotification}
        />
      </div>

      {/* Header Section Ends */}

      <div className={"Dash_home_QWI"}>
        <div
          className="myattendcard2w p-3"
          style={{
            height: window.innerHeight - 117 + "px",
            overflowY: "auto",
          }}
        >
          {/* Assessment Table Starts */}
          
          {/* <KHeader
          
            style={{
              justifyContent: "space-between",
            }}
            frontElement={
              selectedAssignment !== "" && (
                <div
                  style={{
                    marginTop: "26px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setSelectedAssignment("");
                  }}
                >
                  <IoIosArrowBack size={25} />
                </div>
              )
            }
            title={`Student Current Assignment - ${moment().format("DD MMM YYYY")}`}
            containerClassName={`marginTop-20 justify-content-front`}
            LastElement={<div></div>}
          /> */}
          
 {/* Current Assignment Table start */}

          <div className="container-fluid px-5 py-3">
                  <div className="mb-4">
                  <h4>
                  Student Current Assignment - {`${moment().format("DD MMM YYYY")}`}
                  </h4> </div>
                   
              {dataVisible1 && (
                <>
                 
                  <div className="tabelstyle">
                      <CSmartTable
                        columns={table_data}
                        columnFilter
                        columnSorter
                        items={tableAssignmentDisplay}

                        itemsPerPage={5}
                        itemsPerPageSelect={tableAssignmentDisplay.length > 5}
                        pagination

                        scopedColumns={{
                        
                          Action: (item) => (
                           
                            <td>

                              {/* {JSON.stringify(datacheck)} */}
                              <KButton
                                type="button"
                                className=" clickmeBtn "
                                // check next API response Fillter 

                                isDisabled={datacheck.find(m => m.assignmentId === item.assignmentId )?.Status === "Submitted" ? true : false }
                                
                                onClick={() => {
                                  setSelectedAssignment("");
                                  setDataVisible1(false);
                                  setDataVisible2(true);
                                  setAssignmentSecondData([{
                                    No:item.No,
                                    assignmentId:item._id,
                                    assignmentName:item.assignmentName,
                                    katbookSubjectName:item.katbookSubjectName,
                                    staffName: item.staffName,
                                    totalMarks: item.totalMarks,
                                    lsd:item.lsd,
                                    studentPosted:item.studentPosted,
                                  }])
                                  Question_Answer_Poster(item.assignmentId);
                                  
                                }}
                              //  value="Answer 000"

                               value={datacheck.find(m => m.assignmentId === item.assignmentId )
                                ?.Status === "Submitted" ? "Posted" : "Answer" }
                                // value = {item.StatusNew }
                              
                               btnColor={"green"}
                              />
                                
                            </td>
                          ),
                        }}
                        tableProps={{
                          striped: true,
                          hover: true,
                          responsive: true,
                        }}
                      />
                   </div>


                  </>

              )}
               
              {dataVisible2 && (
                <>
                  <div className="mt-2 d-flex justify-content-end">
                    {/* <h4>Question and Answer </h4> */}
                    <button
                      className="backBtn mb-2"
                      onClick={() => {
                        
                        
                        setDataVisible1(true);
                        setDataVisible2(false);
                        setSelectedAssignment("");
                      }}
                    >
                      Back
                    </button>
                  </div>
                  <div className="mt-2 tabelstyle ">
                        <CSmartTable
                          items={assignmentSecondData}
                          // columnSorter
                          // columnFilter
                          // itemsPerPage={5}
                          // itemsPerPageSelect={assignmentSecondData.length > 5}
                          // pagination
                          columns={table_dataSecond}
                          scopedColumns={{
                            // Question : (item) =>(<td className="textalign">{parse(item.Question)}</td>),

                            
                          }}
                          tableProps={{
                            hover: true,
                            responsive: true,
                          }}
                        />
                     </div>
                 </>
             )}  
          
          <Fragment>
            {selectedAssignment !== "" && (
              <div className="container-fluid py-3">
                <h4 >Question & Answer</h4>
                {/* Download Upload Section Starts */}
                
                {/* <KHeader
                className="textLeft"
                   style={{
                    justifyContent: "space-between",
                   
                   }}
                  title={`Question & Answer`}
                 
                  containerClassName={`marginTop-20 justify-content-front`}
                  LastElement={
                    <div className="d-flex flex-row marginRight-20 align-items-end"> */}
                      {/* <ExportCSV csvData={csvStructure} fileName="Download"> */}
                        {/* <KButton
                          btnColor={"green"}
                          label="Download"
                          style={{
                            height: "fit-content",
                          }}
                          className="d-flex flex-row justify-content-center align-items-center py-2 mr-2"
                          RightIcon={
                            <div className="pl-1">
                              <AiOutlineFileAdd />
                            </div>
                          }
                        /> */}
                      {/* </ExportCSV> */}

                      {/* <KButton
                        RightIcon={
                          <div className="pl-1">
                            <AiOutlineFileAdd />
                          </div>
                        }
                        style={{
                          height: "fit-content",
                        }}
                        btnColor={"red"}
                        className="d-flex flex-row justify-content-center align-items-center py-2 mr-2"
                        label="Upload"
                      /> */}
                    {/* </div> */}
                  {/* } */}
                {/* /> */}
                {/* Download Upload Section Starts */}

                
                {questionDisplay.map((data, index) => (
                  <Fragment key={index}>
                    {/* Question Section Start */}
                    <div
                      className={`d-flex flex-row w-100 ${
                        index === 0 ? "marginTop-20" : "paddingTop-10"
                      } ${isBigScreen ? "paddingLeft-2" : ""}`}
                    >
                      <div className="col-8 d-flex flex-row align-items-center answerBox11 textQ">
                        <div className="d-flex flex-row align-items-center ">
                          <b>{index + 1}.</b>
                          {parse(data.question)} </div>
                        {/* preview answer */}
                        {/* <div className="textalign">
                        &nbsp;{data.studentAnswer=== null ? "": parse(data.studentAnswer)}
                        </div> */}
                      </div>
                      
                      <div className="col-4 d-flex flex-row align-items-end ">
                        <div className="d-flex flex-row">
                          {answerEditor.filter((item) => item.key === data.questionId)
                            .length === 0 ? (
                              
                            <KButton
                              onClick={() => {
                                AnswerEditorHandler(data.questionId, "", data);
                              }}
                              style={{
                                height: "fit-content",
                              }}
                              btnColor={"green"}
                              className="d-flex flex-row justify-content-center align-items-center py-2 px-3 mr-2"
                              label="Answer"

                              isDisabled={questionDisplay.find(m => m.questionId === data.questionId )?.studentAnswer !== null ? true : false }
                                
                            />
                            
                          ) : (
                            <Fragment >
                              {draftCollection.filter(
                                (item) => item.key === data.questionId
                              ).length > 0 && (
                                <Fragment>
                                  <KButton
                                    style={{
                                      height: "fit-content",
                                    }}
                                    className="d-flex flex-row justify-content-center align-items-center py-2 px-3 mr-2 "
                                    label="Edit"
                                    onClick={() => {
                                      AnswerCollectionHandler(
                                        data.questionId,
                                        answerEditor.find(
                                          (item) => item.key === data.questionId
                                        ),
                                        false,
                                        true
                                      );
                                    }}
                                  />
                                  <KButton
                                    onClick={() => {
                                      
                                      AnswerCollectionHandler(
                                        data.questionId,
                                        answerEditor.find(
                                          (item) => item.key === data.questionId
                                        ),
                                        false,
                                        false
                                      );
                                    }}
                                    style={{
                                      height: "fit-content",
                                    }}
                                    btnColor={"green"}
                                    className="d-flex flex-row justify-content-center align-items-center py-2 px-3 mr-2"
                                    label="Submit"
                                  />
                                </Fragment>
                              )}
                            </Fragment>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* Question Section End */}
                    {answerEditor.filter((item) => item.key === data.questionId).length >
                      0 && (
                      <Fragment>
                        {/* Answer Section Start */}
                        <div
                          className={`${isBigScreen ? "paddingLeft-2" : ""}`}
                        >
                          <div className="col-8 answerBox pb-2 pt-1" >
                            {/* <KRequiredLabel title={`Answer : ${index + 1}`} /> */}
                            {/* <KRequiredLabel title={`Answer :`} /> */}
                           
                            <Fragment>
                              {draftCollection.filter(
                                (item) => item.key === data.questionId
                              ).length > 0 ||
                              responseCollection.filter(
                                (item) => item.key === data.questionId
                              ).length > 0 ? (
                                <div className="py-2 px-2 d-flex align-items-center">
                                  <div>
                                    {parse(
                                      answerEditor.find(
                                        (item) => item.key === data.questionId
                                      )?.value
                                        ? answerEditor.find(
                                            (item) => item.key === data.questionId
                                          )?.value
                                        : ""
                                    )}
                                  </div>
                                </div>
                              ) : (
                                <Fragment>
                                  {answerEditor.find(
                                    (item) => item.key === data.questionId
                                  )?.answerType === "TextArea" ||
                                  answerEditor.find(
                                    (item) => item.key === data.questionId
                                  )?.answerType === "TextBox" ? (
                                    <>
                                    <KEditor
                                      // height={350}
                                      height={answerEditor.find((item) => item.key === data.questionId)?.answerType === "TextArea" ? 350 : 250}
      
                                      onChange={(value) => {
                                        // Limit the character count based on the answer type
                                        const maxChars = answerEditor.find((item) => item.key === data.questionId)?.answerType === "TextArea" ? 500 : 250;
                                        if (value.length <= maxChars) {
                                          AnswerEditorHandler(data.questionId, value);
                                        }
                                        else{
                                          toast.error("Exceeded character limit for TextBox!");
                                        }
                                      }}
                                      // onChange={(value) => {
                                      //   AnswerEditorHandler(data.questionId, value);
                                      //}}
                                      value={
                                        answerEditor.find(
                                          (item) => item.key === data.questionId
                                        )?.value
                                          ? answerEditor.find(
                                              (item) => item.key === data.questionId
                                            )?.value
                                          : ""
                                      }
                                      // <p>Testing</p>
                                    />
                                    {
                                        answerEditor.find((item) => item.key === data.questionId)?.answerType === "TextArea"
                                          ? "500 char only"
                                          : "250 char only"
                                      }
                                    {/* {answerEditor.find(item.answerType === "TextArea" ? "Max character 500 only ": "Max character 250 only")} */}
                                 </>
                                  ) : (
                                    answerEditor.find(
                                      (item) => item.key === data.questionId
                                    )?.answerType === "RadioButton" && (
                                      <Fragment>
                                         {/* const parsedArray = JSON.parse(response.data); */}
                                    
                                        {JSON.parse(data.options).map((option, i) => (
                                          <Fragment key={i}>
                                            <div className="d-flex flex-row">
                                              <div className="pr-2">
                                                <input
                                                  type="radio"
                                                  name={data._id}
                                                  checked={
                                                    answerEditor.find(
                                                      (item) =>
                                                        item.key === data.questionId
                                                    )?.value
                                                      ? answerEditor.find(
                                                          (item) =>
                                                            item.key === data.questionId
                                                        )?.value ==
                                                        option.options
                                                        ? true
                                                        : false
                                                      : false
                                                  }
                                                  onChange={(e) => {
                                                    AnswerEditorHandler(
                                                      data.questionId,
                                                      option.options
                                                    );
                                                  }}
                                                />
                                              </div>
                                              <div>
                                                <span>
                                                  {parse(option.options)} 
                                                </span>
                                              </div>
                                            </div>
                                          </Fragment>
                                        ))}
                                      </Fragment>
                                    )
                                  )}
                                </Fragment>
                              )}
                            </Fragment>

                            {answerEditor.filter((item) => item.key === data.questionId)
                              .length > 0 &&
                              draftCollection.filter(
                                (item) => item.key === data.questionId
                              ).length === 0 &&
                              responseCollection.filter(
                                (item) => item.key === data.questionId
                              ).length === 0 && (
                                <div className="d-flex flex-row justify-content-end pt-3">
                                  <KButton
                                    isDisabled={
                                      !(
                                        answerEditor.filter(
                                          (item) => item.key === data.questionId
                                        ).length > 0 &&
                                        answerEditor.find(
                                          (item) => item.key === data.questionId
                                        )?.value &&
                                        answerEditor.find(
                                          (item) => item.key === data.questionId
                                        )?.value.length > 0
                                      )
                                    }
                                    style={{
                                      height: "fit-content",
                                    }}
                                    onClick={() => {
                                      
                                      // console.log(data,"SUBMIT_DATA");

                                      SubmitAnswer(selectedAssignment,data.questionId,answerEditor.find(
                                        (item) => item.key === data.questionId
                                      ))
                                      AnswerCollectionHandler(
                                        data.questionId,
                                        answerEditor.find(
                                          (item) => item.key === data.questionId
                                        ),
                                        false,
                                        false
                                      );
                                    }}
                                    btnColor="green"
                                    className="d-flex flex-row justify-content-center align-items-center py-2 px-3 mr-2"
                                    label="Submit"
                                  /> 
                                  {/* Draft Button */}
                                  {/* <KButton
                                    isDisabled={
                                      !(
                                        answerEditor.filter(
                                          (item) => item.key === index
                                        ).length > 0 &&
                                        answerEditor.find(
                                          (item) => item.key === index
                                        )?.value &&
                                        answerEditor.find(
                                          (item) => item.key === index
                                        )?.value.length > 0
                                      )
                                    }
                                    onClick={() =>
                                      AnswerCollectionHandler(
                                        index,
                                        answerEditor.find(
                                          (item) => item.key === index
                                        ),
                                        true,
                                        false
                                      )
                                    }
                                    style={{
                                      height: "fit-content",
                                    }}
                                    className="d-flex flex-row justify-content-center align-items-center py-2 px-3 mr-2"
                                    label="Draft"
                                  /> */}
                                  <KButton
                                    style={{
                                      height: "fit-content",
                                    }}
                                    onClick={() => {
                                      const removeDraftCollection =
                                        draftCollection.filter(
                                          (item) => item.key !== data.questionId
                                        );
                                      const removeResponseCollection =
                                        responseCollection.filter(
                                          (item) => item.key !== data.questionId
                                        );
                                      const removeExistingKey =
                                        answerEditor.filter(
                                          (k) => k.key !== data.questionId
                                        );
                                      setAnswerEditor(removeExistingKey);
                                      setResponseCollection(
                                        removeResponseCollection
                                      );
                                      setDraftCollection(removeDraftCollection);
                                    }}
                                    btnColor="red"
                                    className="d-flex flex-row justify-content-center align-items-center py-2 px-3 mr-2"
                                    label="Cancel"
                                  />
                                </div>
                              )}
                          </div>
                        </div>
                        {/* Answer Section End */}
                      </Fragment>
                    )}
                  </Fragment>
                ))}

                {/* Post Button Starts */}
                {/* {JSON.stringify(responseCollection)} */}
                <div
                  className={` d-flex flex-row justify-content-end ${
                    isBigScreen ? "paddingRight-20" : ""
                  }`}
                >
                  
                  <div className="d-flex flex-row pt-3">
                    <KButton
                      btnColor={"green"}
                      style={{
                        height: "fit-content",
                      }}

                      isDisabled={
                        questionDisplay.length === responseCollection.length ? false:true
                       }
                      // isDisabled={questionDisplay.length > 0 ? true:responseCollection.length === questionDisplay.length ? false : isDisabled}

                      // isDisabled={refresh ? isDisabled : !(questionDisplay.length > 0 && responseCollection.length === questionDisplay.length)}

                      // isDisabled={ isDisabled }

                      // isDisabled={
                        
                      //   questionDisplay.length > 0 
                      //   &&
                      //   responseCollection.length === questionDisplay.length
                      //     ? false
                      //     : true 
                      //  }
                      

                        // || 
                        
                        // responseCollection.length !== 0 
                        //  && responseCollection.length === responseCollection.filter(m => m.value === "").length > 0 ? false : true
                      
                       // isDisabled={!(questionDisplay.length > 0
                      //    && responseCollection.length === questionDisplay.length)}

                        //  isDisabled={questionDisplay}
                         
                        //  isDisabled={questionDisplay.filter(m => m.studentAnswer !== null).length > 0 ? false : true}
                              
                      onClick={() => {
                        //postAnswer(selectedAssignment);
                        setVisible(true);
                        //setSelectedAssignment('')
                        }}
                      className="d-flex flex-row justify-content-center align-items-center px-4 py-2 mr-2"
                      label="Post"
                      
                    />
                   

                    {/* {JSON.stringify(questionDisplay)} */}
                  </div>
                </div>
                {/* Post Button  End */}

                <CModal
                    alignment="center"
                    visible={visible}
                    onClose={() => setVisible(false)}
                    className="ModalHeight"
                  >
                      <div
                        style={{
                          backgroundColor: "white",
                          padding: "10px 30px 30px 30px",
                          borderRadius:"5px",
                        }}
                      >
                      <div style={{ padding: "25px" }}>
                          <div className="d-flex justify-content-center mb-4">
                            <span
                              style={{
                                fontSize: "18px",
                                color: "black",
                                fontWeight: "500",
                                height: "auto",
                              }}
                            >
                              Are you sure to post the Answer ?
                  </span>
                </div>
                <div className="d-flex justify-content-around">
                  <KButton
                    value="Yes"
                    
                    onClick={() => {
                      Student_Answer_Poster_checker()                      
                      postAnswer(selectedAssignment);
                      // console.log(selectedAssignment,"tttttttttttttttttttttt")
                      setSelectedAssignment('');
                      setDataVisible1(true);
                      setDataVisible2(false);
                      setVisible(false);
                      // refreshPage();
                      // setTriggerEffect(true);
                    }}
                    

                    btnColor={"green"}
                    className={"px-4 py-1 ButtonSize"}
                  />
                  <KButton
                    value="No"
                    onClick={() => {
                      // PostCancelQuestion();
                      setDataVisible1(false);
                      setDataVisible2(true);
                      // setSelectedAssignment("");
                      setVisible(false);
                    }}
                    className={"px-4 py-1 ButtonSize"}
                    btnColor={"red"}
                  />
                </div>
              </div>
            </div>
          </CModal>
              </div>
            )}
          </Fragment>
          
           </div>
{/*        
          <div className="marginLeft-20 marginRight-20 paddingLeft-10">
            <table
              style={{
                borderCollapse: "separate",
                borderSpacing: "0px 15px",
              }}
              className="w-100 StudentAssignment__table"
            >
              <tr
                style={{
                  border: "1px solid #E6ECF1",
                }}
              >
               
                <th className="p-2">
                  <KRequiredLabel
                    className={"mb-0"}
                    title={"Subject"}
                    isRequired={false}
                  />
                </th>
                <th className="p-2">
                  <KRequiredLabel
                    className={"mb-0"}
                    title={"Assignment Name"}
                    isRequired={false}
                  />
                </th>
                <th className="p-2">
                  <KRequiredLabel
                    className={"mb-0"}
                    title={"Assignment By Teacher"}
                    isRequired={false}
                  />
                </th>
                <th className="p-2">
                  <KRequiredLabel
                    className={"mb-0"}
                    title={"Total Marks"}
                    isRequired={false}
                  />
                </th>
                <th className="p-2">
                  <KRequiredLabel
                    className={"mb-0"}
                    title={"Last Date of Submission"}
                    isRequired={false}
                  />
                </th>
              </tr>

              {tableAssignmentDisplay
                .filter((filteredData) => {
                  if (selectedAssignment === "") {
                    return filteredData;
                  } else if (filteredData._id === selectedAssignment) {
                    return filteredData;
                  }
                })

                .map((tableData, index) => (
                  <tr
                    className="py-3 StudentAssignment__tableElements"
                    style={{
                      border: "1px solid #E6ECF1",
                    }}
                    onClick={() => {
                      // if (selectedAssignment === "") {
                        Question_Answer_Poster(tableData);
                      // }
                    }}
                  >
                    <td className="p-2 StudentAssignment__tableStart">
                      {tableData.katbookSubjectName}
                    </td>
                    <td className="p-2">{tableData.assignmentName}</td>
                    <td className="p-2">{tableData.staffName}</td>
                    <td className="p-2">{tableData.totalMarks}</td>
                    <td className="p-2 StudentAssignment__tableEnd">
                      {`${moment(tableData.lsd).format("DD MMM YYYY")}`}
                    </td>
                  </tr>
                ))}
            </table>
          </div> */}
          
          {/* Assessment Table Ends */}
         
        </div>
      </div>
      {/* Footer Section Starts */}
      <Footer
        openForm={openForm}
        shoq={shoq}
        closeForm={closeForm}
        KpostIframe={KpostIframe}
      />
      {/* Footer Section Ends */}
    </div>
  );
};

export default StudentAssignment;
