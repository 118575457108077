import React, { useState, useEffect } from 'react'
import Header from "../HeaderNavbar";
import Footer from "../Footer";
import KButton from '../common/KButton/KButton';
import TodayTask from './TaskStatus';
import KHeader from '../common/KHeader/KHeader';
import TaskReport from './TaskReport';
import StatusReport from './StatusReport';
import AppraisalReport from './AppraisalReport';

const Diary = ({
    showProfile,
    falseShowProfile,
    notofShowProfile,
    ShowNotifications,
    showNotification,
    falseShowNotification,
    disableOnOuterTouch,
    shoq,
    KpostIframe,
    closeForm,
    openForm,
    goToMainRoutes,
    CheckTokenProfile
}) => {

    const [state, setState] = useState({
        update: false,
        Appraisal: false,
        Status: false,
        Task: false,
    })
    useEffect(() => {
        CheckTokenProfile()
      }, []);
    

    return (
        <div onClick={() => disableOnOuterTouch()}>
            <div className="Navbar">
                <Header
                    showProfile={showProfile}
                    falseShowProfile={falseShowProfile}
                    notofShowProfile={notofShowProfile}
                    ShowNotifications={ShowNotifications}
                    showNotification={showNotification}
                    falseShowNotification={falseShowNotification}
                />
            </div>

            <div className={"Dash_home_QWI"}>
                <div
                    className="myattendcard2w p-3"
                    style={{
                        height: window.innerHeight - 150 + "px",
                        overflowY: "auto",
                    }}
                >

                    <KHeader
                        title="Diary"
                        containerClassName={`marginTop-20`}
                    />
                    <div className='diarybutton'>

                        <div className="d-flex flex-column justify-content-center flex-md-row">
                            <KButton
                                value="Task Report"
                                className={`px-6 py-3 mr-3 diarybtnclass ${state.Task ? "activediarybtn" : ""}`}
                                onClick={() => {
                                    setState({
                                        update: false,
                                        Appraisal: false,
                                        Status: false,
                                        Task: true,
                                    })
                                }}
                            />
                            <KButton
                                value="Task Status Update "
                                className={`px-6 py-3 mr-3 diarybtnclass ${state.update ? "activediarybtn" : ""}`}
                                onClick={() => {
                                    setState({
                                        update: true,
                                        Appraisal: false,
                                        Status: false,
                                        Task: false,
                                    })
                                }}
                            />

                            <KButton
                                value="Appraisal Report"
                                className={`px-6 py-3 mr-3 diarybtnclass ${state.Appraisal ? "activediarybtn" : ""}`}
                                onClick={() => {
                                    setState({
                                        update: false,
                                        Appraisal: true,
                                        Status: false,
                                        Task: false,
                                    })
                                }}
                            />
                            <KButton
                                value="Status Report"
                                className={`px-6 py-3 mr-3 diarybtnclass ${state.Status ? "activediarybtn" : ""}`}
                                onClick={() => {
                                    setState({
                                        update: false,
                                        Appraisal: false,
                                        Status: true,
                                        Task: false,
                                    })
                                }}
                            />

                        </div>

                    </div>

                    {/* Task Status Update */}
                    {state.update && (
                        <div>
                            <TodayTask backtaskStatus={()=>{
                                 setState({
                                    update: false,
                                    Appraisal: false,
                                    Status: false,
                                    Task: false,
                                })
                            }}/>
                        </div>
                    )}

                    {/* Today task  */}

                    { state.Task && (
                        <div>
                            <TaskReport backTaskReport={()=>{
                                    setState({
                                        update: false,
                                        Appraisal: false,
                                        Status: false,
                                        Task: false,
                                    })
                            }}/>
                        </div>
                    )}

                    

                    {state.Appraisal && (
                        <div>
                            <AppraisalReport/>
                        </div>
                    )}

                    {state.Status && (
                        <div>
                            <StatusReport/>
                        </div>
                    )}
                    
                </div>
            </div>

            <Footer
                openForm={openForm}
                shoq={shoq}
                closeForm={closeForm}
                KpostIframe={KpostIframe}
            />
        </div>
    )
}

export default Diary
