import React, { useEffect, useState } from "react";
import Header from "./HeaderNavbar";
import Footer from "./Footer";
import { useMediaQuery } from "react-responsive";
import useResponsive from "../../hooks/useResponsive";
import KDropdown from "./common/KDropdown";
import KInput from "./common/KInput";
import KHeader from "./common/KHeader";
import KButton from "./common/KButton";
import { Row, Col } from "reactstrap";
import KEditor from "./common/KEditor";
import KRequiredLabel from "./common/KRequiredLabel";
import { AiOutlineEdit } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
import data from "../../../src/data.json";
import { CModal, CModalHeader } from "@coreui/react";
import { toast } from "react-toastify";
import Danger from "../components/Toastify/Danger";
import Warning from "../components/Toastify/Warning";
import Success from "../components/Toastify/Success";
import parse from "html-react-parser";
import { CCol, CSmartTable } from "@coreui/react-pro";
import moment from "moment";

import {
  getDynamicSubjects,
  POSTpOSTaSSIGNMENT,
  Teacher_CreateAssignemt_Table,
  QuestionAndAnswer_Table_List,
} from "../../services/Apiservices";
import { Action } from "@remix-run/router";

const PostAssignment = ({
  showProfile,
  falseShowProfile,
  notofShowProfile,
  ShowNotifications,
  showNotification,
  falseShowNotification,
  disableOnOuterTouch,
  shoq,
  KpostIframe,
  closeForm,
  openForm,
  goToMainRoutes
}) => {
  const { isBigScreen } = useResponsive();
  const [Post, setPost] = useState(false);
  const [visible, setVisible] = useState(false);
  const [subjectsData, setsubjectsData] = React.useState([]);
  const [singleSubject, setsingleSubject] = React.useState("");
  const [examsList, setexamsList] = React.useState([]);
  const [singleExamID, setsingleExamID] = React.useState("");
  const [AsstDate, setAsstDate] = useState("");
  const [AsstMarks, setAsstMarks] = useState([]);
  const [userscredsData, setuserscredsData] = useState([]);
  const [getAllQues, setgetAllQues] = useState([]);
  const [markValidation,setMarkValidation] = useState([]);
  const [questionTypesOption, setQuestionTypesOption] = useState("");
  const [questionType, setQuestionType] = useState("");
  const [detailtedans, setDetailtedans] = useState("");
  
  const [editOPtion, setEditOption] = useState(false);
  const [editOPtionText, setEditOptionText] = useState("");
  const [optionText, setoptionText] = React.useState("");
  const [option, setoption] = React.useState([]);
  const [correctAns, setCorrectAns] = React.useState({});
  const [textAns, setTextAns] = React.useState("");
  const [quesAns, setquesAns] = React.useState({});
  const [QuestionValue, setQuestionValue] = useState("");
  const [Quesmarks, setQuesmarks] = useState("");
  const [postTable, setPostTable] = useState([]);
  const [assignmentNameData,setAssignmentNameData] = useState()
  const [postAssignmentTable, setPostAssignmentTable] = useState(false);
  const optionsAns = [
    {
      value: "TextBox",
      id: "1",
      label: "TextBox",
    },
    { value: "TextArea", id: "2", label: "TextArea" },
    { value: "RadioButton", id: "3", label: "RadioButton" },
  ];

  const Question_Answer_table = [
    {
      key: "sno",
      label: "S.No",
      filter: false,
      sorter: false,
      _style: { width: "5%" },
    },
    // {
    //   key: "subjectName",
    //   label: "Subject",
    //   filter: true,
    //   sorter: false,
    //   _style: { width: "8%" },
    // },
    {
      key: "assignmentName",
      label: "Assignment",
      filter: true,
      sorter: false,
      _style: { width: "10%" },
    },
    {
      key: "qtype",
      label: "Type",
      filter: true,
      sorter: false,
      _style: { width: "8%" },
    },
    {
      key: "Question",
      label: "List of Question and Answer",
      filter: true,
      sorter: false,
      _style: { width: "70%" },
    },
    // {
    //   key: "Answer",
    //   label: "Correct Answer",
    //   filter: true,
    //   sorter: false,
    //   _style: { width: "20%" },
    // },
    {
      key: "Marks",
      label: "Marks",
      _style: { width: "8%" },
      filter: true,
      sorter: false,
    },
    // {
    //   key: "Posted",
    //   label: "Posted",
    //   _style: { width: "6%" },
    //   filter: true,
    //   sorter: false,
    // },
  ];

  // console.log("dfjskldfjsd",postTable)
  const showPostTable = async (assignmentId) => {
    setPostTable([]);
    let response;
   
    let body = {
      staffId: localStorage.length > 0 && JSON.parse(localStorage.userCreds).staffID,
      assignmentId: assignmentId,
     
  };
  
    try {
      
      // response = await PostAssignmentTable(assignmentId);
      response = await QuestionAndAnswer_Table_List(body);
     
      if (response) {
        if (response.status === "success") {
            // console.log("jlksdjklds",response.data)
          if (response.data) {
            
            const value = response.data.map((x, i) => {
              try {
                x.options = JSON.parse(x.options)?.map((option, index) => `${index + 1}. ${option.options}`).join(' ')
                
              } catch (error) {
                x.options = x.options;
              }
              return {
                sno: i + 1,
                subjectName:x.assignment.katbookSubjectName,
                assignmentName:x.assignment.assignmentName, 
                // assignmentName1:`${x.assignment.map(x=>x.assignmentName)}`, 
                     
                Question: x?.question,
                Answer: x?.correctAnswer,
                Marks: x?.marks,
                answerType:x.answerType,
                Posted:x.assignment.posted===true?"Posted":"Not Posted",
                qtype:x.questionType.questionTypeName,
                // option:x.options === "" || x.options === null ? "" : `${JSON.parse(x.options)?.map(x=>x.options)}`,
                // option: x.options === "" || x.options === null ? "" : `${JSON.parse(x.options)?.map((x, i) => `${i + 1}.${x.options}`).join('\n')}`
                option:x.options,
              };
            });
            setPostTable(value);
            let totalMarks = value.reduce((m, x) => m + (parseInt(x.Marks) || 0), 0);
            setMarkValidation(totalMarks);
            // console.log("shdfkjhsdkjhas",value.map(x=>x.assignmentName))

          }
          
        }else{
          toast.error(response.message)
        } 
      }
    } catch (e) {
      console.log(e);
    }
  };

  const CreatePostAssign = async () => {

     // var today = new Date();
     const today = new Date();
     const yyyy = today.getFullYear();
     let mm = today.getMonth() + 1; // Months start at 0!
     let dd = today.getDate();

     if (dd < 10) dd = '0' + dd;
     if (mm < 10) mm = '0' + mm;

     const formattedToday = yyyy + '/' + mm + '/' + dd;

    const data = {

      assignmentId: singleExamID.value,
      posted: true,
      teacherPostedDate: formattedToday,
      
    };
    
    // console.log("body05555",data)

    try {
      let res = await POSTpOSTaSSIGNMENT(data);
      if (res) {
        if (res.status==="success") {
          toast.success(res.message);
          setVisible(false);
        } else {
          toast.error(res.error);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const CancelPostAssign = () => {
    setsingleSubject("");
    setsingleExamID("");
    setAsstMarks("");
    setAsstDate("");
    setPostAssignmentTable(false);
  };

  const [AnswerType, setAnswerType] = useState({});
  const [updatedData, setupdatedData] = useState({});
  const [updatedContent, setupdatedContent] = useState(false);

  const Cancelpostfunc = async () => {
    setsingleSubject("");
    setsingleExamID("");
    setAsstDate("");
    setgetAllQues([]);
  };

  const PostQuestion = async () => {
    setVisible(false);
    toast.success("Assignment posted successfully.");
  };

  const PostCancelQuestion = async () => {
    setVisible(false);
  };

  const Cancelformfunc = async () => {
    setupdatedData({});
    setupdatedContent(false);
    setQuestionValue("");
    setQuestionType("");
    setQuesmarks("");
    setoption([]);
    setTextAns("");
    setquesAns("");
    setCorrectAns("");
  };


  const updateFunc = async (data) => {
    console.log(data, "UPDATE CONTENT DATA");
    // getAllQuestionTypeFuc();

    setQuestionType({
      value: questionTypesOption.filter(
        (item) => item._id === data.questionTypeId
      )[0].value,
      label: questionTypesOption.filter(
        (item) => item._id === data.questionTypeId
      )[0].value,
      _id: data.questionTypeId,
    });
    setupdatedData(data);
    setupdatedContent(true);
    setquesAns(data.correctAnswer);
    setQuestionValue(data.question);
    setQuesmarks(data.marks);
    console.log(
      questionTypesOption.filter((item) => item._id === data.questionTypeId)[0]
        .value,
      "UPDATE CONTENT DATA"
    );
    setAnswerType({
      value: data.answerType,
      label: data.answerType,
      id: optionsAns.filter(
        (item) => String(item.value) === String(data.answerType)
      )[0].id,
    });
    var listopt = [];
    data.options.map((x) => {
      var objData = {
        _id: x._id,
        name: x.option.trim(),
        value: parse(x.option.trim()),
        label: parse(x.option.trim()),
      };
      listopt.push(objData);
    });

    setoption(listopt);
    console.log(
      data.options.filter(
        (item) => String(item.option) === String(data.correctAnswer)
      )[0]._id,
      "UPDATE CONTENT DATA"
    );
    setCorrectAns({
      value: parse(data.correctAnswer.trim()),
      label: parse(data.correctAnswer.trim()),
      name: data.correctAnswer.trim(),
      _id: data.options.filter(
        (item) => String(item.option) === String(data.correctAnswer)
      )[0]._id,
    });
    console.log(
      {
        value: data.answerType,
        label: data.answerType,
        id: optionsAns.filter((item) => item.value === data.answerType)[0].id,
      },
      "UPDATE CONTENT DATA"
    );
  };

  const onChangeQuestion = async (name, e) => {
    console.log("ASDSAD", name, e);
    setQuestionValue(e);
    if (e !== "") {
    }
  };

  const onChangeAnswerType = async (name, e) => {
    setEditOption(false);
    setEditOptionText("");
    console.log(name, e);
    setoption([]);
    setCorrectAns({});
    setTextAns("");
    setAnswerType(e);
  };

  const addoption = async () => {
    console.log(optionText, "tttt");
    console.log(option, "tttt");
    console.log(optionText.trim(), "tttt");
    if (optionText !== "") {
      var count = 0;
      await option.map((data) => {
        if (optionText.trim() !== data.name) {
          count = count + 1;
        }
        return 1;
      });
      console.log("count", option.length, count);
      if (option.length !== count) {
        toast.warning(<Warning body={"Already this option value was added"} />);
      } else {
        console.log(optionText, "jjj");
        await setoption([
          ...option,
          {
            _id: count + 1,
            name: optionText.trim(),
            value: parse(optionText.trim()),
            label: parse(optionText.trim()),
          },
        ]);
        await setoptionText("");
        await setCorrectAns("");
      }
    }
  };

  const editOPtionFuc = async () => {
    console.log(optionText, "tttt");
    const findIndex = await option.findIndex((x) => {
      if (String(x._id) === String(editOPtionText._id)) {
        return true;
      }
      return false;
    });
    if (findIndex >= 0) {
      if (optionText !== "") {
        var count = 0;
        await option.map((data) => {
          if (optionText.trim() !== data.name) {
            count = count + 1;
          }
          return 1;
        });
        console.log("count", option.length, count);
        if (option.length !== count) {
          toast.warning(
            <Warning body={"Already this option value was added"} />
          );
        } else {
          console.log(optionText, "jjj");

          if (
            String(option[findIndex].name).toLocaleLowerCase().trim() !==
            String(optionText).toLocaleLowerCase().trim()
          ) {
            let data = await option.map((x) => {
              if (String(x._id) === String(editOPtionText._id)) {
                return {
                  _id: x._id,
                  name: optionText.trim(),
                  value: parse(optionText.trim()),
                  label: parse(optionText.trim()),
                };
              } else {
                return {
                  _id: x._id,
                  name: x.name.trim(),
                  value: parse(x.name.trim()),
                  label: parse(x.name.trim()),
                };
              }
            });

            await setoption("");
            await setoptionText("");
            await setCorrectAns("");
            await setEditOptionText("");
            await setEditOption("");
            await setoption(data);
            console.log(data, "tttt");
          } else {
            toast.warning(
              <Warning body={"Edit something to update the option!"} />
            );
          }
        }
      }
    } else {
      toast.warning(<Warning body={"Select vaild option text!"} />);
    }
  };

  const onChangeFuc = (e) => {
    console.log(e);
    if (editOPtion) {
      setoptionText(e);
    } else {
      setoptionText(e);
    }
  };


  const CourseListFunc = async () => {
    const SubjectList = JSON.parse(await localStorage.getItem("userCreds"))?.classSubjectDetails ?? [] ;
   
    if (SubjectList.length > 0) {
      let dataOPtion = await SubjectList.map((e) => {
        return {
          // value: e.KATBOOK_SUBJECT_ID,
          // label: e.SUBJECT_NAME,
          // kamSubjectId: e.SUBJECT_ID,
          ...e,
          value: `${e.katbookSubjectId} ${e.sectionId}`,
          data:e.katbookSubjectId,
          label: `${e.className}-${e.sectionName} ${e.subjectName}`,
          kamSubjectId: e.katbookSubjectId,
          sectionId:e.sectionId,
          classId:e.classId,
          
          // katSubjectId: e.KATBOOK_SUBJECT_ID,
          // value: e.className + " - " + e.sectionName + " - " + e.subjectName,
          // label: e.className + " - " + e.sectionName + " - " + e.subjectName,
          // className: e.className,
          // classId: e.classId,
          // sectionId: e.sectionId,
          // sectionName: e.sectionName,
        };
      });
      setsubjectsData(dataOPtion);
    }
  };

  const showAssignment = async (kamSubjectId,sectionId,classId) => {
    var response;
    setexamsList([]);
    let body={
      staffId:
      localStorage.length > 0 && JSON.parse(localStorage.userCreds).staffID,
      katbookSubjectId: kamSubjectId,
      classId: classId,
      sectionId:sectionId,
    }
    try {
      response = await Teacher_CreateAssignemt_Table(body);
      // console.log(response, "exampler1112");
      if (response) {
        if(response.status==="success"){
          const data = response.data.map((x) => {
            return {
              value: x.assignmentId,
              label: x.assignmentName,
              data: x,
              assignmentIdget: x.assignmentId,
            };
          });
          setexamsList(data);
        }else{
          toast.error(response.message)
        }
        
      }
    } catch (e) {
      console.log(e);
    }
  };

  const findTotMarksandLsd = (subjectId, AssignmentId) => {
    const findAssignmentBySubIdandAssId = examsList.find(
      (assign) =>
        assign.data.katbookSubjectId === subjectId &&
        assign.data.assignmentId === AssignmentId
    );

    const totMarks = findAssignmentBySubIdandAssId?.data.totalMarks;
    const exDate =
      findAssignmentBySubIdandAssId?.data.lsd.length > 5
        ? findAssignmentBySubIdandAssId?.data.lsd.slice(0, 10)
        : null;

    setAsstMarks(totMarks);
    setAsstDate(exDate);
    console.log(
      findAssignmentBySubIdandAssId,
      // subjectId,
      // AssignmentId,
      // AssignmentData,
      // exDate,
      "KIOIOIOIOIOIO"
    );
    // katbookSubjectId _id
  };

  

  useEffect(() => {
    if (localStorage.length > 0) {
      let retrData = JSON.parse(localStorage.userCreds);

      const getSubjectListfunc = async () => {
        let allquesbod, allquestres;

        if (retrData.userType === "Teaching") {
          allquesbod = {
            // refInstitutionId: retrData.refInstID,
            // staffId: retrData.staffID,
            refStaffID: retrData.staffID,
          };

          allquestres = await getDynamicSubjects(allquesbod);
        } else {
          allquesbod = {
            refInstitutionId: retrData.refInstID,
            sectionId: retrData.classID,
          };

          // allquestres =await  getDynamicTimetable(allquesbod);
        }

        if (allquestres.status === "403") {
        goToMainRoutes()
        }

        if (allquestres.status === "success") {
          setuserscredsData(allquestres.value);
          CourseListFunc(allquestres.value);
          // if(allquestres.value.length > 0){
          //   let dataOPtion = await allquestres.value.map((e) => {
          //     return { value: e.className+' - '+e.sectionName+' - '+e.subjectName, label: e.className+' - '+e.sectionName+' - '+e.subjectName, katSubjectId: e.katbookSubjectId, kamSubjectId : e.subjectId, className: e.className, classId: e.classId, sectionId: e.sectionId, sectionName: e.sectionName };
          //   });

          //   setsubjectsData(dataOPtion);

          // }
        }
      };

      getSubjectListfunc();
    }
  }, [localStorage]);

  return (
    <div onClick={() => disableOnOuterTouch()}>
      <div className="Navbar">
        <Header
          showProfile={showProfile}
          // profileIcon={KpIcon}
          // username={state.adminName ? state.adminName : state.user}
          falseShowProfile={falseShowProfile}
          notofShowProfile={notofShowProfile}
          // institutionName={state.institutionName && String(state.institutionName).toUpperCase()}
          ShowNotifications={ShowNotifications}
          showNotification={showNotification}
          falseShowNotification={falseShowNotification}
        />
      </div>
      <div className={"Dash_home_QWI"}>
        <div
          className="myattendcard2w p-3"
          style={{
            height: window.innerHeight - 117 + "px",
            overflowY: "auto",
          }}
        >
          <KHeader
            title="Post Assignment"
            containerClassName={`marginTop-20`}
          />

          <div className="container-fluid px-5 py-3">
            <div className="d-flex flex-column flex-md-row">
              <KDropdown
                containerClassName="w-100"
                label="Select Subject"
                isRequired={true}
                placeholder="Select Subject"
                value={singleSubject}
                options={subjectsData}
                onChange={(e) => {
                  setsingleSubject(e);
                  showAssignment(e.kamSubjectId,e.sectionId,e.classId);
                  setsingleExamID("");
                  setAsstMarks([]);
                  setAsstDate("");
                  // setPostAssignmentTable(false);
                  setPostTable([]);
                }}
              />
              {/* {JSON.stringify(examsList[0].data.posted)} */}
              <KDropdown
                containerClassName="pl-md-3 w-100"
                label="Assignment Name"
                isRequired={true}
                placeholder="Assignment Name"
                value={singleExamID}
                options={examsList.filter(y=>y.data.posted == false)}
                  onChange={(e) => {
                  showPostTable(e.value);
                  setsingleExamID(e);
                  findTotMarksandLsd(singleSubject?.data, e.value);
                  setPostAssignmentTable(true);
                  setAssignmentNameData(e?.label);
                }}
              />
              <KInput
                label="Total Marks"
                isRequired={true}
                readonly={true}
                placeholder="Enter Total Marks"
                containerClassName={`pl-md-3 pl-0 pt-2 pt-md-0 ${
                  isBigScreen ? "w-100" : "w-100"
                }`}
                inputClassName="w-100"
                type="number"
                isDisabled={true}
                value={AsstMarks}
                toast={toast}
                Warning={Warning}
              />
              <KInput
                placeholder={"dd--mm--yyyy"}
                readonly={true}
                label="Last Date of Submission"
                containerClassName="pl-md-3 pl-0 pt-2 pt-md-0 w-100"
                isRequired={true}
                isDisabled={true}
                value={AsstDate}
                type="date"
              />
            </div>
          </div>

      {postAssignmentTable && (      
        <div className="d-flex flex-column pl-3 pr-3 tabelstyle mt-3">
            <div className="d-flex justify-content-between">
                    <h4 className="ml-3">Question and Answer </h4>
                   
                    <h5 className="mr-5">
                      Total Marks : {markValidation}
                    </h5>
                  </div>
          <div
            className="mx-3 my-1 pl-3 pr-3"
            style={{ textAlign: "center", border: "0px solid #dadbdd" }}
          >
            <CSmartTable
              items={postTable}
              columnFilter
              columnSorter
              itemsPerPage={5}
              itemsPerPageSelect={postTable.length > 5}
              pagination
              columns={Question_Answer_table}
              scopedColumns={{
                Question: (item) => <td className="textalign">
                   <span><b>Question :</b>{parse(item.Question)}</span>  
                       {/* {parse(item.Question)} */}
                  
                  <div>

                    {(
                      item.answerType === "RadioButton" && 
                      <span className="optionName"> 
                     
                      {parse(item.option)}
                    </span>

                    )}
                  </div>
                  <span><b>Answer :</b> {parse(item.Answer)}</span>
                </td>,
        
                // Answer: (item) => <td className="textalign">{parse(item.Answer)}</td>,
                Action: (item) => (
                  <td className="gapflex">
                    <button
                      type="button"
                      className="btn btn-primary btn-sm addblue"
                      title="Edit"
                    >
                      <i className="fa fa-edit" />
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger btn-sm cancered"
                      title="Delete"
                    >
                      <i className="fa fa-trash-o" />
                    </button>
                  </td>
                ),
              }}
              tableProps={{ hover: true, responsive: true }}
            />
          </div>

          <div className="d-flex flex-column justify-content-end flex-md-row pr-5 mb-4">
            <KButton
              value="Post"
              
              onClick={() => {
                setPost(true);
                setVisible(true);
                // postQuestionsFunc()
                setexamsList([]);
              }}
              
              isDisabled={postTable.filter(m => m.Posted !== "Posted").length > 0 ? false : true }
             
              btnColor={"green"}
              className={`px-3 py-1 ButtonSize  ${
                isBigScreen ? "ml-3" : "mt-2 ml-4"
              }`}
            />
            <KButton
              value="Cancel"
              className={`px-3 py-1 ButtonSize ${
                isBigScreen ? "ml-3" : "mt-2 ml-4"
              }`}
              btnColor={"red"}
              onClick={() => {
                CancelPostAssign();
              }}
            />
          </div>
        </div>
        )}

          <CModal
            alignment="center"
            visible={visible}
            onClose={() => setVisible(false)}
            className="ModalHeight"
          >
            <div
              style={{
                backgroundColor: "white",
                padding: "10px 30px 30px 30px",
                borderRadius:"5px",
              }}
            >
              

              <div style={{ padding: "25px" }}>
                <div className="d-flex justify-content-center mb-4">
                  <span
                    style={{
                      fontSize: "18px",
                      color: "black",
                      fontWeight: "500",
                      height: "auto",
                    }}
                  >
                    Would you like to post {assignmentNameData}?
                  </span>
                </div>
                <div className="d-flex justify-content-around">
                  <KButton
                    value="Yes"
                    
                    onClick={() => {
                      CreatePostAssign();
                      CancelPostAssign();
                    }}
                    

                    btnColor={"green"}
                    className={"px-4 py-1 ButtonSize"}
                  />
                  <KButton
                    value="No"
                    onClick={() => {
                      PostCancelQuestion();
                    }}
                    className={"px-4 py-1 ButtonSize"}
                    btnColor={"red"}
                  />
                </div>
              </div>
            </div>
          </CModal>

          {updatedContent && (
            <>
              <KHeader title="Add Questions" />
              <div className="container-fluid px-5 py-3">
                <div className="d-flex flex-column flex-md-row marginTop-20">
                  <KDropdown
                    containerClassName="w-100"
                    label="Select Question Type"
                    isRequired={true}
                    edit={questionType ? true : false}
                    value={questionType && questionType}
                    options={questionTypesOption}
                    placeholder="Select Question Type"
                    labelStyle={{
                      fontSize: "20px",
                      marginBottom: "15px",
                    }}
                    onChange={(e) => {
                      // setUpdateData(data)
                      // console.log(e, "oooooooooooooooooo");
                      setQuestionType(e);
                    }}
                  />
                  <div className="w-100 pt-3 pt-md-0 d-flex align-items-end pl-0 pl-md-3 cursor-pointer"></div>
                </div>

                <div className="d-flex flex-row justify-content-between my-3">
                  <KRequiredLabel
                    title="Enter Question"
                    className="h5 mt-3"
                    style={{
                      display: "inline-block",
                      alignSelf: "flex-end",
                    }}
                  />
                  <KInput
                    label="Marks"
                    isRequired={true}
                    placeholder="Enter Marks"
                    containerClassName={`pl-md-3 pl-0 pt-2 pt-md-0 w-25`}
                    labelClassName={"width-maxContent"}
                    inputClassName="w-25"
                    type="number"
                    value={Quesmarks}
                    onChange={setQuesmarks}
                    toast={toast}
                    Warning={Warning}
                  />
                </div>
                <KEditor
                  height={isBigScreen ? 400 : 500}
                  value={QuestionValue}
                  handleEditorChange={(content) => {
                    onChangeQuestion("Question", content);
                  }}
                />
                <KDropdown
                  labelStyle={{
                    fontSize: "20px",
                    marginBottom: "15px",
                  }}
                  containerClassName={`marginTop-20 ${
                    isBigScreen ? "w-25" : "w-100"
                  }`}
                  label="Answer Type"
                  isRequired={true}
                  placeholder="Select Answer Type"
                  options={optionsAns}
                  value={AnswerType}
                  onChange={(e) => onChangeAnswerType("AnswerTypes", e)}
                  isDisabled={QuestionValue ? false : true}
                />

                {AnswerType && AnswerType.value === "RadioButton" && (
                  <>
                    <Row
                      style={{
                        marginTop: "17px",
                        color: "black",
                      }}
                    >
                      <Col sm="10" md="10" lg="7">
                        <div style={{ marginBottom: "20px", color: "black" }}>
                          <KRequiredLabel
                            title="Options"
                            className="h5 mt-3"
                            style={{
                              display: "inline-block",
                              alignSelf: "flex-end",
                            }}
                          />
                        </div>
                        {/* <CInput
                              style={{ marginTop: '8px' }}
                              onChange={(e) => {
                                onChangeFuc(e);
                              }}
                              value={optionText}
                              placeholder={"Options"}
                            /> */}
                        <KEditor
                          height={isBigScreen ? 400 : 500}
                          value={optionText}
                          handleEditorChange={(content) => {
                            onChangeFuc(content);
                          }}
                        />
                      </Col>

                      <Col sm="2" md="2" lg="4">
                        <KButton
                          width={60}
                          height={50}
                          value={editOPtion ? "UPDATE" : "ADD"}
                          className={`px-3 py-2 ${
                            isBigScreen ? "w-auto" : "w-100"
                          }`}
                          style={{
                            position: "absolute",
                            bottom: "0px",
                            marginBottom: "20px",
                          }}
                          onClick={() => {
                            if (optionText) {
                              if (editOPtion) {
                                editOPtionFuc();
                              }
                            }
                          }}
                        />
                      </Col>
                    </Row>
                  </>
                )}

                {option.length !== 0 && AnswerType.value === "RadioButton" && (
                  <Row
                    style={{
                      marginTop: "17px",
                      color: "black",
                      paddingLeft: "18px",
                    }}
                  >
                    <Col
                      sm="12"
                      md="6"
                      lg="3"
                      style={{ marginTop: "15px", marginBottom: "15px" }}
                    >
                      {/* <div style={{ marginBottom: '10px', color: 'black' }}>
                        <h6 style={{ fontWeight: 'bold' }}>{name}<span className="text-danger">{star}</span></h6>
                    </div> */}
                      {/* <KRequiredLabel
                          title="Correct Answers in Options"
                          className="h5 mt-3"
                          style={{
                            display: "inline-block",
                            alignSelf: "flex-end"
                          }}
                        /> */}
                      <KDropdown
                        label={"Correct Answers in Options *"}
                        options={option}
                        // value={correctAns}
                        value={correctAns}
                        onChange={(e) => {
                          setCorrectAns(e);
                          // console.log(e, "WWWWWWWWWWWWWW");
                        }}
                        placeholder={"Select correct answer"}
                      />
                    </Col>
                    <Col sm="2" md="2" lg="2">
                      {!editOPtion ? (
                        <KButton
                          width={60}
                          height={50}
                          className="btn btn-info"
                          style={{
                            position: "absolute",
                            bottom: "0px",
                            marginBottom: "20px",
                          }}
                          onClick={() => {
                            if (correctAns && correctAns._id) {
                              console.log(correctAns);
                              setEditOption(true);
                              setEditOptionText(correctAns);
                              setoptionText(correctAns.name);
                            } else {
                              toast.warning(
                                <Warning
                                  body={
                                    "Please edit an option which your are edit!."
                                  }
                                />
                              );
                            }
                          }}
                          value="EDIT"
                        />
                      ) : (
                        <KButton
                          width={60}
                          height={50}
                          className="btn btn-danger"
                          style={{
                            position: "absolute",
                            bottom: "0px",
                            marginBottom: "20px",
                          }}
                          onClick={() => {
                            setEditOption(false);
                            setEditOptionText("");
                            setoptionText("");
                          }}
                          value="CANCEL"
                        />
                      )}
                    </Col>
                  </Row>
                )}

                {AnswerType &&
                (AnswerType.value === "TextBox" ||
                  AnswerType.value === "TextArea") ? (
                  <>
                    <KRequiredLabel
                      title="Correct Answer"
                      className="h5 marginTop-20"
                    />
                    <KEditor
                      height={isBigScreen ? 400 : 500}
                      value={textAns || quesAns}
                      handleEditorChange={(content) => {
                        setquesAns(content);
                      }}
                    />
                  </>
                ) : null}

                <div
                  className="d-flex flex-column flex-md-row mt-3"
                  style={{
                    paddingTop: "30px",
                    paddingBottom: "100px",
                    float: "right",
                  }}
                >
                  <KButton
                    value="UPDATE"
                    onClick={() => {
                      // createNewQuestionsFunc()
                    }}
                    btnColor={"green"}
                    className={`px-4 py-2 `}
                  />
                  <KButton
                    onClick={() => {
                      Cancelformfunc();
                    }}
                    value="CANCEL"
                    className={`px-4 py-2 ${isBigScreen ? "ml-3" : "mt-2"}`}
                    btnColor={"red"}
                  />
                </div>
              </div>
            </>
          )}

          {!updatedContent && (
            <>
              <div className="container-fluid px-3 py-3">
                {getAllQues.length > 0 && (
                  <>
                    <div>
                      <div className="d-flex flex-row justify-content-between align-items-center pb-2">
                        <KHeader
                          title="Questions & Answers"
                          style={{
                            marginLeft: "0px !important",
                          }}
                        />
                        <KButton
                          value="Add"
                          onClick={() => {}}
                          className={"height-fitContent px-4 py-2 "}
                        />
                      </div>

                      {getAllQues.map((items, index) => (
                        <>
                          <div className="mx-5 my-3 ques_ans">
                            <div className="d-flex flex-row gap-3 align-items-center">
                              <div className="DivBox">
                                {/* Question 1 */}
                                <div className={"w-100 d-flex"}>
                                  <div className={"w-100 d-flex"}>
                                    <div>
                                      <b>Question {index + 1}:&nbsp;&nbsp;</b>
                                    </div>
                                  </div>
                                  <div className={"d-flex"}>
                                    <b>Marks:&nbsp;&nbsp;</b>10
                                  </div>
                                </div>
                                <div className={"w-100"}>
                                  {parse(items.question)}
                                </div>
                                {console.log(
                                  items.options,
                                  "OPTIONSSSSSSSSSSSSSSSSSS"
                                )}
                                {items.options.map((q, index1) => (
                                  <span style={{ display: "flex" }}>
                                    {index1 + 1})&nbsp;&nbsp;{parse(q.option)}
                                  </span>
                                ))}
                                {/* {parse(items.options)} */}
                                <div className="mt-3">
                                  {/* Answer 1 */}
                                  <div style={{ display: "flex" }}>
                                    <b>Answer :&nbsp;&nbsp;</b>
                                    {parse(items.correctAnswer)}
                                  </div>
                                </div>
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  paddingLeft: "145px",
                                  gap: "30px",
                                }}
                              >
                                <div
                                  className="borderCircleEdit"
                                  onClick={() => {
                                    updateFunc(items);
                                  }}
                                >
                                  <AiOutlineEdit size="25" />
                                </div>

                                <div className="borderCircleDelete">
                                  <RiDeleteBin6Line size="23" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ))}

                      <div
                        className="d-flex flex-column flex-md-row mt-3"
                        style={{
                          paddingTop: "30px",
                          paddingBottom: "100px",
                          float: "right",
                        }}
                      >
                        <KButton
                          value="POST"
                          // onClick={() => {
                          //   postQuestionsFunc()
                          // }}
                          btnColor={"green"}
                          className={`px-4 py-2 `}
                        />
                        <KButton
                          onClick={() => {
                            Cancelpostfunc();
                          }}
                          value="CANCEL"
                          className={`px-4 py-2 ${
                            isBigScreen ? "ml-3" : "mt-2"
                          }`}
                          btnColor={"red"}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>

      <Footer
        openForm={openForm}
        shoq={shoq}
        closeForm={closeForm}
        KpostIframe={KpostIframe}
      />
    </div>
  );
};

export default PostAssignment;