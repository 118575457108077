import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate
} from "react-router-dom";

import Home from "./views/components/Home";
import Dashboard from "./views/components/Dashboard";
import NewDashboard from "./views/components/NewDashboard";
import TeacherDashboard from "./views/components/TeacherDashboard";
import TDashboard from "./views/components/TDashboard";
import Timetable from "./views/components/Timetable";
import BookView from "./views/components/BookView";
import StudentReportCreate from "./views/components/ReportStudentCreate";
import TeacherReportStudentCreate from "./views/components/TeacherReportStudentCreate";
import KSMACC from "./views/components/Ksmacc";
import Assignment from "./views/components/Assignment";
import PastAssignment from "./views/components/PastAssignment"
import PendingAssignment from "./views/components/PendingAssignment"
import StudentAssignment from "./views/components/StudentAssignment";
import ViewAssignment from "./views/components/ViewAssignment";
import PostAssignment from "./views/components/PostAssignment";
import KatbookSessionPlan from "./views/components/KatbookSessionPlan"
import ViewSessionPlan from "./views/components/ViewSessionPlan"
import CreateQuestion from "./views/components/CreateQuestion";
import Diary from "./views/components/diary/Diary";
import Instructions from "./views/components/Instructions";
import Login from "./views/components/Login";
import Logout from "./views/components/Logout";
import Attendance from "./views/components/Attendance";
import { Authcontext } from "../src/views/components/Context/Context";
import { KampusProfile } from "./services/Apiservices";
import { toast } from "react-toastify";
import Danger from "./views/components/Toastify/Danger";

const MenuRoutes = ({
  VideoConfFunc,
  tConvert,
  CuttentDateTime,
  tableBgColors,
  openBookview,
  showProfile,
  falseShowProfile,
  notofShowProfile,
  ShowNotifications,
  showNotification,
  falseShowNotification,
  disableOnOuterTouch,
  shoq,
  KpostIframe,
  closeForm,
  openForm,
  trueShowNotification,
}) => {
  const navigate = useNavigate();
  const { state, dispatch } = React.useContext(Authcontext);
  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };

  const goToMainRoutes = () => {
    toast.error(<Danger body={`Your session has expired. Login again`} />);
    setTimeout(() => {
      dispatch({ type: 'LOGOUT' });
      const goToHomePage = () => navigate("/login");
      goToHomePage();
    },4000);
  }

  const CheckTokenProfile = async() => {
    let bodyData = {
      userLoginID : localStorage.getItem("userCreds")?.userName
    }
    let tokenres = await KampusProfile(bodyData);
    if(tokenres.status === "403"){
      goToMainRoutes()
    }
  }

  React.useEffect(() => {
    // console.log(localStorage.getItem("AccessToken"),"eeeeeeeeeee");
    if(localStorage.length > 0 && localStorage.getItem("AccessToken") && localStorage.getItem("AccessToken") != '' && localStorage.getItem("AccessToken") != undefined && localStorage.getItem("AccessToken") != "undefined" && localStorage.getItem("AccessToken") != null && localStorage.getItem("AccessToken") != "null"){
      // console.log(localStorage.getItem("AccessToken"),"alert");
      const decodedJwt = parseJwt(localStorage.getItem("AccessToken"));
        if (decodedJwt.exp * 1000 < Date.now()) {
          // console.log("expired","tokennnnnnnnnnnnn");
          const goToHomePage = () => navigate("/login");
          goToHomePage();
          dispatch({ type: 'LOGOUT' });
        } else {
          // localStorage.setItem("AccessToken", accessToken);
          // console.log("active","tokennnnnnnnnnnnn");
        }

        // CheckTokenProfile()
    } else {
      const goToHomePage = () => navigate("/login");
          goToHomePage();
          dispatch({ type: 'LOGOUT' });
    }
  },[window.location.pathname]);

return (
  <>
  <Routes>
            {localStorage.length > 0 && localStorage.userCreds !== undefined &&
            <>
            <Route exact path="/" element={<Home />} />
            <Route
              path="/dashboard"
              element={
                localStorage &&
                localStorage?.length > 0 &&
                localStorage.userCreds !== undefined &&
                JSON.parse(localStorage.userCreds) &&
                JSON.parse(localStorage.userCreds).userType === "Teaching" ? (
                  <NewDashboard
                    VideoConfFunc={VideoConfFunc}
                    tConvert={tConvert}
                    CuttentDateTime={CuttentDateTime}
                    tableBgColors={tableBgColors}
                    openBookview={openBookview}
                    showProfile={showProfile}
                    falseShowProfile={falseShowProfile}
                    notofShowProfile={notofShowProfile}
                    ShowNotifications={ShowNotifications}
                    showNotification={showNotification}
                    falseShowNotification={falseShowNotification}
                    trueShowNotification={trueShowNotification}
                    disableOnOuterTouch={disableOnOuterTouch}
                    shoq={shoq}
                    KpostIframe={KpostIframe}
                    closeForm={closeForm}
                    openForm={openForm}
                    goToMainRoutes={goToMainRoutes}
                  />
                ) : (
                  <NewDashboard
                    VideoConfFunc={VideoConfFunc}
                    tConvert={tConvert}
                    CuttentDateTime={CuttentDateTime}
                    tableBgColors={tableBgColors}
                    openBookview={openBookview}
                    showProfile={showProfile}
                    falseShowProfile={falseShowProfile}
                    notofShowProfile={notofShowProfile}
                    ShowNotifications={ShowNotifications}
                    showNotification={showNotification}
                    falseShowNotification={falseShowNotification}
                    trueShowNotification={trueShowNotification}
                    disableOnOuterTouch={disableOnOuterTouch}
                    shoq={shoq}
                    KpostIframe={KpostIframe}
                    closeForm={closeForm}
                    openForm={openForm}
                    goToMainRoutes={goToMainRoutes}
                  />
                )
              }
            />
            <Route
              path="/dashboardview"
              element={
                <NewDashboard
                  VideoConfFunc={VideoConfFunc}
                  tConvert={tConvert}
                  CuttentDateTime={CuttentDateTime}
                  tableBgColors={tableBgColors}
                  openBookview={openBookview}
                  showProfile={showProfile}
                  falseShowProfile={falseShowProfile}
                  notofShowProfile={notofShowProfile}
                  ShowNotifications={ShowNotifications}
                  showNotification={showNotification}
                  falseShowNotification={falseShowNotification}
                  trueShowNotification={trueShowNotification}
                  disableOnOuterTouch={disableOnOuterTouch}
                  shoq={shoq}
                  KpostIframe={KpostIframe}
                  closeForm={closeForm}
                  openForm={openForm}
                />
              }
            />
            <Route
              path="/BookView"
              element={
                <BookView
                  showProfile={showProfile}
                  falseShowProfile={falseShowProfile}
                  notofShowProfile={notofShowProfile}
                  ShowNotifications={ShowNotifications}
                  showNotification={showNotification}
                  falseShowNotification={falseShowNotification}
                  disableOnOuterTouch={disableOnOuterTouch}
                  shoq={shoq}
                  KpostIframe={KpostIframe}
                  closeForm={closeForm}
                  openForm={openForm}
                  goToMainRoutes={goToMainRoutes}
                  CheckTokenProfile={CheckTokenProfile}
                />
              }
            />
            <Route
              path="/tdashboard"
              element={
                <TDashboard
                  VideoConfFunc={VideoConfFunc}
                  tConvert={tConvert}
                  CuttentDateTime={CuttentDateTime}
                  tableBgColors={tableBgColors}
                  openBookview={openBookview}
                  showProfile={showProfile}
                  falseShowProfile={falseShowProfile}
                  notofShowProfile={notofShowProfile}
                  ShowNotifications={ShowNotifications}
                  showNotification={showNotification}
                  falseShowNotification={falseShowNotification}
                  trueShowNotification={trueShowNotification}
                  disableOnOuterTouch={disableOnOuterTouch}
                  shoq={shoq}
                  KpostIframe={KpostIframe}
                  closeForm={closeForm}
                  openForm={openForm}
                />
              }
            />
            <Route
              path="/timetable"
              element={
                <Timetable
                  VideoConfFunc={VideoConfFunc}
                  tConvert={tConvert}
                  CuttentDateTime={CuttentDateTime}
                  tableBgColors={tableBgColors}
                  openBookview={openBookview}
                  showProfile={showProfile}
                  falseShowProfile={falseShowProfile}
                  notofShowProfile={notofShowProfile}
                  ShowNotifications={ShowNotifications}
                  showNotification={showNotification}
                  falseShowNotification={falseShowNotification}
                  disableOnOuterTouch={disableOnOuterTouch}
                  shoq={shoq}
                  KpostIframe={KpostIframe}
                  closeForm={closeForm}
                  openForm={openForm}
                  goToMainRoutes={goToMainRoutes}
                />
              }
            />
            <Route
              path="/attendance"
              element={
                <Attendance
                  showProfile={showProfile}
                  falseShowProfile={falseShowProfile}
                  notofShowProfile={notofShowProfile}
                  ShowNotifications={ShowNotifications}
                  showNotification={showNotification}
                  falseShowNotification={falseShowNotification}
                  disableOnOuterTouch={disableOnOuterTouch}
                  shoq={shoq}
                  KpostIframe={KpostIframe}
                  closeForm={closeForm}
                  openForm={openForm}
                />
              }
            />
            <Route
              path="/assignment/past"
              element={
                localStorage &&
                localStorage?.length > 0 &&
                localStorage.userCreds !== undefined &&
                JSON.parse(localStorage.userCreds) &&
                JSON.parse(localStorage.userCreds).userType === "Student" &&
                <PastAssignment
                  showProfile={showProfile}
                  falseShowProfile={falseShowProfile}
                  notofShowProfile={notofShowProfile}
                  ShowNotifications={ShowNotifications}
                  showNotification={showNotification}
                  falseShowNotification={falseShowNotification}
                  disableOnOuterTouch={disableOnOuterTouch}
                  shoq={shoq}
                  KpostIframe={KpostIframe}
                  closeForm={closeForm}
                  openForm={openForm}
                  goToMainRoutes={goToMainRoutes}
                />
              }
            />

            <Route
              path="/exam/student"
              element={
                localStorage &&
                localStorage?.length > 0 &&
                localStorage.userCreds !== undefined &&
                JSON.parse(localStorage.userCreds) &&
                JSON.parse(localStorage.userCreds).userType === "Student" &&
                <StudentAssignment
                  showProfile={showProfile}
                  falseShowProfile={falseShowProfile}
                  notofShowProfile={notofShowProfile}
                  ShowNotifications={ShowNotifications}
                  showNotification={showNotification}
                  falseShowNotification={falseShowNotification}
                  disableOnOuterTouch={disableOnOuterTouch}
                  shoq={shoq}
                  KpostIframe={KpostIframe}
                  closeForm={closeForm}
                  openForm={openForm}
                  goToMainRoutes={goToMainRoutes}
                />
              }
            />

            <Route
              path="/StudentsReport"
              element={
                localStorage &&
                localStorage?.length > 0 &&
                localStorage.userCreds !== undefined &&
                JSON.parse(localStorage.userCreds) &&
                JSON.parse(localStorage.userCreds).userType === "Teaching" ? (
                  <TeacherReportStudentCreate
                  showProfile={showProfile}
                  falseShowProfile={falseShowProfile}
                  notofShowProfile={notofShowProfile}
                  ShowNotifications={ShowNotifications}
                  showNotification={showNotification}
                  falseShowNotification={falseShowNotification}
                  disableOnOuterTouch={disableOnOuterTouch}
                  shoq={shoq}
                  KpostIframe={KpostIframe}
                  closeForm={closeForm}
                  openForm={openForm}
                  goToMainRoutes={goToMainRoutes}
                />
                ):
                (
                  <StudentReportCreate
                  showProfile={showProfile}
                  falseShowProfile={falseShowProfile}
                  notofShowProfile={notofShowProfile}
                  ShowNotifications={ShowNotifications}
                  showNotification={showNotification}
                  falseShowNotification={falseShowNotification}
                  disableOnOuterTouch={disableOnOuterTouch}
                  shoq={shoq}
                  KpostIframe={KpostIframe}
                  closeForm={closeForm}
                  openForm={openForm}
                  goToMainRoutes={goToMainRoutes}
                />
                )
                
              }
            />

            <Route
              path="/exam/student"
              element={
                <StudentAssignment
                  showProfile={showProfile}
                  falseShowProfile={falseShowProfile}
                  notofShowProfile={notofShowProfile}
                  ShowNotifications={ShowNotifications}
                  showNotification={showNotification}
                  falseShowNotification={falseShowNotification}
                  disableOnOuterTouch={disableOnOuterTouch}
                  shoq={shoq}
                  KpostIframe={KpostIframe}
                  closeForm={closeForm}
                  openForm={openForm}
                />
              }
            />

            <Route
              path="/assignment/pending"
              element={
                localStorage &&
                localStorage?.length > 0 &&
                localStorage.userCreds !== undefined &&
                JSON.parse(localStorage.userCreds) &&
                JSON.parse(localStorage.userCreds).userType === "Student" &&
                <PendingAssignment
                  showProfile={showProfile}
                  falseShowProfile={falseShowProfile}
                  notofShowProfile={notofShowProfile}
                  ShowNotifications={ShowNotifications}
                  showNotification={showNotification}
                  falseShowNotification={falseShowNotification}
                  disableOnOuterTouch={disableOnOuterTouch}
                  shoq={shoq}
                  KpostIframe={KpostIframe}
                  closeForm={closeForm}
                  openForm={openForm}
                  goToMainRoutes={goToMainRoutes}
                />
              }
            />

            <Route
              path="/assignment/create"
              element={
                localStorage &&
                localStorage?.length > 0 &&
                localStorage.userCreds !== undefined &&
                JSON.parse(localStorage.userCreds) &&
                JSON.parse(localStorage.userCreds).userType === "Teaching" &&
                <Assignment
                  showProfile={showProfile}
                  falseShowProfile={falseShowProfile}
                  notofShowProfile={notofShowProfile}
                  ShowNotifications={ShowNotifications}
                  showNotification={showNotification}
                  falseShowNotification={falseShowNotification}
                  disableOnOuterTouch={disableOnOuterTouch}
                  shoq={shoq}
                  KpostIframe={KpostIframe}
                  closeForm={closeForm}
                  openForm={openForm}
                  goToMainRoutes={goToMainRoutes}
                />
              }
            />

            <Route
              path="/diary"
              element={
                localStorage &&
                localStorage?.length > 0 &&
                localStorage.userCreds !== undefined &&
                JSON.parse(localStorage.userCreds) &&
                JSON.parse(localStorage.userCreds).userType === "Teaching" &&
                  <Diary
                  showProfile={showProfile}
                  falseShowProfile={falseShowProfile}
                  notofShowProfile={notofShowProfile}
                  ShowNotifications={ShowNotifications}
                  showNotification={showNotification}
                  falseShowNotification={falseShowNotification}
                  disableOnOuterTouch={disableOnOuterTouch}
                  shoq={shoq}
                  KpostIframe={KpostIframe}
                  closeForm={closeForm}
                  openForm={openForm}
                  goToMainRoutes={goToMainRoutes}
                  CheckTokenProfile={CheckTokenProfile}
                />  
                
              }
            />

            <Route
              path="/KatbookSessionPlan"
              element={
                <KatbookSessionPlan
                  showProfile={showProfile}
                  falseShowProfile={falseShowProfile}
                  notofShowProfile={notofShowProfile}
                  ShowNotifications={ShowNotifications}
                  showNotification={showNotification}
                  falseShowNotification={falseShowNotification}
                  disableOnOuterTouch={disableOnOuterTouch}
                  shoq={shoq}
                  KpostIframe={KpostIframe}
                  closeForm={closeForm}
                  openForm={openForm}
                  goToMainRoutes={goToMainRoutes}
                />
              }
            />
            
            <Route
              path="/ViewSessionPlan"
              element={
                <ViewSessionPlan
                  showProfile={showProfile}
                  falseShowProfile={falseShowProfile}
                  notofShowProfile={notofShowProfile}
                  ShowNotifications={ShowNotifications}
                  showNotification={showNotification}
                  falseShowNotification={falseShowNotification}
                  disableOnOuterTouch={disableOnOuterTouch}
                  shoq={shoq}
                  KpostIframe={KpostIframe}
                  closeForm={closeForm}
                  openForm={openForm}
                  goToMainRoutes={goToMainRoutes}
                />
              }
            />


            <Route
              path="/assignment/view"
              element={
                localStorage &&
                localStorage?.length > 0 &&
                localStorage.userCreds !== undefined &&
                JSON.parse(localStorage.userCreds) &&
                JSON.parse(localStorage.userCreds).userType === "Teaching" && 
                <ViewAssignment
                  showProfile={showProfile}
                  falseShowProfile={falseShowProfile}
                  notofShowProfile={notofShowProfile}
                  ShowNotifications={ShowNotifications}
                  showNotification={showNotification}
                  falseShowNotification={falseShowNotification}
                  disableOnOuterTouch={disableOnOuterTouch}
                  shoq={shoq}
                  KpostIframe={KpostIframe}
                  closeForm={closeForm}
                  openForm={openForm}
                  goToMainRoutes={goToMainRoutes}
                  CheckTokenProfile={CheckTokenProfile}
                />
              }
            />
            <Route
              path="/assignment/post"
              element={
                localStorage &&
                localStorage?.length > 0 &&
                localStorage.userCreds !== undefined &&
                JSON.parse(localStorage.userCreds) &&
                JSON.parse(localStorage.userCreds).userType === "Teaching" && 
                <PostAssignment
                  showProfile={showProfile}
                  falseShowProfile={falseShowProfile}
                  notofShowProfile={notofShowProfile}
                  ShowNotifications={ShowNotifications}
                  showNotification={showNotification}
                  falseShowNotification={falseShowNotification}
                  disableOnOuterTouch={disableOnOuterTouch}
                  shoq={shoq}
                  KpostIframe={KpostIframe}
                  closeForm={closeForm}
                  openForm={openForm}
                  goToMainRoutes={goToMainRoutes}
                />
              }
            />

            <Route
              path="/assignment/createquestion"
              element={
                localStorage &&
                localStorage?.length > 0 &&
                localStorage.userCreds !== undefined &&
                JSON.parse(localStorage.userCreds) &&
                JSON.parse(localStorage.userCreds).userType === "Teaching" && 
                <CreateQuestion
                  showProfile={showProfile}
                  falseShowProfile={falseShowProfile}
                  notofShowProfile={notofShowProfile}
                  ShowNotifications={ShowNotifications}
                  showNotification={showNotification}
                  falseShowNotification={falseShowNotification}
                  disableOnOuterTouch={disableOnOuterTouch}
                  shoq={shoq}
                  KpostIframe={KpostIframe}
                  closeForm={closeForm}
                  openForm={openForm}
                  goToMainRoutes={goToMainRoutes}
                />
              }
            />
            

            <Route
              path="/ksmacc"
              element={
                <KSMACC
                  showProfile={showProfile}
                  falseShowProfile={falseShowProfile}
                  notofShowProfile={notofShowProfile}
                  ShowNotifications={ShowNotifications}
                  showNotification={showNotification}
                  falseShowNotification={falseShowNotification}
                  disableOnOuterTouch={disableOnOuterTouch}
                  shoq={shoq}
                  KpostIframe={KpostIframe}
                  closeForm={closeForm}
                  openForm={openForm}
                />
              }
            />
            </>
            }
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Logout />} />
          </Routes>
</>
);


};

export default MenuRoutes