import { Navigate } from 'react-router-dom';

const Home = () => {
    return (
      localStorage && localStorage?.length > 0 && localStorage.userCreds !==  undefined && JSON.parse(localStorage.userCreds) ? (
        <Navigate to="/dashboard" />
      ): (
        <Navigate to="/login" />
      )
      
    );
};


export default Home;