import React, {useState} from 'react'
import Header from "./HeaderNavbar";
import Footer from "./Footer";
import { useMediaQuery } from 'react-responsive'
import moment from "moment";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

const Attendance = ({
  showProfile,
  falseShowProfile,
  notofShowProfile,
  ShowNotifications,
  showNotification,
  falseShowNotification,
  disableOnOuterTouch,
  shoq,
  KpostIframe,
  closeForm,
  openForm
}) => {

  const [calendar, setCalendar] = useState(new Date());

  // console.log(new Date(),"JJJJJJJJJJJJJJ");

      const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
      })
      const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
      const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
      const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
      const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

      let AttendanceView;

      if(localStorage.length > 0 && JSON.parse(localStorage.userCreds).userType === "Student"){

        AttendanceView = [
          {
            name: "Present",
            number: "82",
            next: "10",
          },
          {
            name: "Absent",
            number: "5",
            next: "2",
          },
          {
            name: "Total Working Days",
            number: "288",
            next: "20",
          },
        ];

      } else {

        AttendanceView = [
          {
            name: "Present",
            number: "120",
            next: "30",
          },
          {
            name: "Absent",
            number: "24",
            next: "6",
          },
          {
            name: "Total Working Days",
            number: "288",
            next: "20",
          },
        ];

      }

      
    
      const hieghtChart = [
        "260px",
        "230px",
        "195px",
        "246px",
        "207px",
        "230px",
        "207px",
        "207px",
        "194px",
        "201px",
        "201px",
        "81px",
      ];
    
      const [isHovering, setIsHovering] = useState(false);
    
      const ChartMonth = [
        {
          name: "Jan",
        },
        {
          name: "Feb",
        },
        {
          name: "Mar",
        },
        {
          name: "Apr",
        },
        {
          name: "May",
        },
        {
          name: "Jun",
        },
        {
          name: "Jul",
        },
        {
          name: "Aug",
        },
        {
          name: "Sep",
        },
        {
          name: "Oct",
        },
        {
          name: "Nov",
        },
        {
          name: "Dec",
        },
      ];
    
      const colorsAttendance = ["#05A26F", "#FB4C4C", "#3073F9"];

    const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      const weeks = [
        "Mon",
        "Tue",
        "Wed",
        "Thu",
        "Fri",
        "Sat",
        "Sun",
      ];
      
  return (
    <div
    onClick = {()=>disableOnOuterTouch()}
    >
        <div className="Navbar">
        <Header 
        showProfile={showProfile}
        // profileIcon={KpIcon}
        // username={state.adminName ? state.adminName : state.user}
        falseShowProfile={falseShowProfile}
        notofShowProfile={notofShowProfile}
        // institutionName={state.institutionName && String(state.institutionName).toUpperCase()}
        institutionName={localStorage.length > 0 && JSON.parse(localStorage.userCreds).institutionName.toUpperCase()}
        ShowNotifications={ShowNotifications}
        showNotification={showNotification}
        falseShowNotification={falseShowNotification}

      />
        </div>
          {isDesktopOrLaptop &&
          <div className={"Dash_home_QWI"}>
    
<div className="myattendcard2w"
style={{
    height: window.innerHeight - 117 + "px",
    overflowY: "auto"
}}

>
  <div className="d-flex flex-row justify-content-between">
    {/* child1 */}
    <div className="d-flex flex-row marginTop-20 marginLeft-20">
      {/* child1.1 */}
      <h5 className={"Time_Table"}>
      {/* {localStorage.length > 0 && JSON.parse(localStorage.userCreds).userType === "Student" ? `Attendees for the date of ${moment().format("DD MMM, YYYY")}` : `Attendees for the date of ${moment().format("DD MMM, YYYY")}`} */}
      Attendance
      </h5>

     
      {/* child1.3 end*/}
    </div>
    {/* child 1 end */}

    {/* Child 2 */}
    <div className="d-flex flex-row px-3 marginTop-20 marginRight-20">
      {/* child 2.1 */}
      <div>
        <span className="green-bullet-sm">&#8226;</span>
      </div>
      {/* child 2.2 */}
      <div className="dateaten">{new Date().toLocaleDateString('en-us', { weekday: 'short', month:"short", day:"numeric", year:"numeric"})}</div>
    </div>
    {/* child 2 end */}
  </div>

  <div className="d-flex flex-row w-100 p-3 mb-5">
                  <div
                    className="col-md-8"
                    // className={"float-left column_Box padFirsLef maritopGin"}
                  >
                    <div className="viewAttend">
                      <h5 className={"AttendViHead"}>Total Attendance</h5>
                      <div className="d-flex flex-row gap-5 mt-2 px-4">
                        {AttendanceView.map((x, i) => {
                          return (
                            <div
                              className="d-flex flex-column gap-2"
                              style={{ color: colorsAttendance[i] }}
                              key={i}
                            >
                              <div className="text-center nameAttenvi">
                                {x.name}
                              </div>
                              <div className="text-center numberAttenvi">
                                {x.number}
                              </div>
                            </div>
                          );
                        })}
                      </div>

                      {isHovering && (
                        <div className="d-flex flex-column ml-5 w-25 shadow bg-white rounded hideattendance">
                          <div className="px-5 py-3 bg-secondary text-white">
                            Month - 83%
                          </div>
                          <div className="px-3 py-2 text-primary">
                            Working Days - 24
                          </div>
                          <div className="px-3 py-2 text-success">
                            Present - 30
                          </div>
                          <div className="px-3 py-2 text-danger">
                            Absent - 6
                          </div>
                        </div>
                      )}

                      <div className="d-flex flex-row justify-content-evenly align-items-end">
                        {ChartMonth.map((x, i) => {
                          return (
                            <div className="progressvi">
                              <div
                                className="barboriee1vi"
                                style={{
                                  height: hieghtChart[i],
                                }}
                                key={i}
                                onMouseOver={() => {
                                  if(i < 4){
                                    setIsHovering(true);
                                  }
                                }}
                                onMouseOut={() => {
                                  setIsHovering(false);
                                }}
                              ></div>
                              <div className="janatten">{x.name}</div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-md-4"
                    // className={"float-left column_Box padFirsLef maritopGin"}
                  >
                    <div className="viewAttend">
                      <h5 className={"AttendViHead"}>This Month</h5>
                      <div className="d-flex flex-row justify-content-between mt-3 px-4">
                        {AttendanceView.map((x, i) => {
                          return (
                            <div
                              className="d-flex flex-column gap-2"
                              style={{ color: colorsAttendance[i] }}
                              key={i}
                            >
                              <div className="text-center nameAttenvi">
                                {x.name}
                              </div>
                              <div className="text-center numberAttenvi">
                                {x.next}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div className="ClassendarVi">
                        <Calendar
                          onChange={setCalendar}
                          value={calendar}
                          maxDate={new Date()}
                        />
                        <br />
                        <p className="text-center">{calendar.toDateString()}</p>
                      </div>
                    </div>
                  </div>
                </div>

</div>
          </div>}

          {isTabletOrMobile &&
            <div className={"Dash_home_QWI"}>
          <h5 className={"MyAttenMobi"}>My Attendance</h5>
<div className="myattendcard2w"></div>
            <div className='d-flex flex-column px-3 upheight tabletv_Qi'>
                <div>
          <h5 className={"Time_Table"}>My Attendance</h5> </div>

          <div className="d-flex flex-row">
      <div>
        <span className="green-bullet-sm">&#8226;</span>
      </div>
      <div className="dateaten">{new Date().toLocaleDateString('en-us', { weekday: 'short', month:"short", day:"numeric", year:"numeric"})}</div>
    </div>
            </div>

            <div className="borderbottmobi">

            <div className="d-flex flex-row marginTop-20 justify-content-evenly ">
  <div className="d-flex flex-column align-items-center">
        <div className="attenparamobi">Present</div>
        <div className="attennumbermobi">153</div>
      </div>
      <div className="d-flex flex-column align-items-center">
        <div className="attenparamobi">Absent</div>
        <div className="attennumbermobi">17</div>
      </div>
      <div className="d-flex flex-column align-items-center">
        <div className="attenparamobi attenpadapada">
          Total Working days
        </div>
        <div className="attennumbermobi">170</div>
      </div>
      </div>


<div className="p-3">
      <div className="d-flex flex-row justify-content-around align-items-end">

            <div className="PROGRESS">
    <div className="barboriee1"></div>
    <div className="janatten">Jan</div>
    </div>
            <div className="PROGRESS">
    <div className="barboriee2"></div>
    <div className="janatten">Feb</div>
    </div>
            <div className="PROGRESS">
    <div className="barboriee8"></div>
    <div className="janatten">Mar</div>
    </div>
            <div className="PROGRESS">
    <div className="barboriee4"></div>
    <div className="janatten">Apr</div>
    </div>
            <div className="PROGRESS">
    <div className="barboriee5"></div>
    <div className="janatten">May</div>
    </div>
            <div className="PROGRESS">
    <div className="barboriee6"></div>
    <div className="janatten">Jun</div>
    </div>
            <div className="PROGRESS">
    <div className="barboriee7"></div>
    <div className="janatten">Jul</div>
    </div>
            <div className="PROGRESS">
    <div className="barboriee3"></div>
    <div className="janatten">Aug</div>
    </div>
            <div className="PROGRESS">
    <div className="barboriee9"></div>
    <div className="janatten">Sep</div>
    </div>
            <div className="PROGRESS">
    <div className="barboriee10"></div>
    <div className="janatten">Oct</div>
    </div>
            <div className="PROGRESS">
    <div className="barboriee11"></div>
    <div className="janatten">Nov</div>
    </div>
            <div className="PROGRESS">
    <div className="barboriee12"></div>
    <div className="janatten">Dec</div>
    </div>

  </div>
  </div>
            </div>

          </div>
          }


          <Footer 
          openForm={openForm}
          shoq={shoq}
          closeForm={closeForm}
          KpostIframe={KpostIframe}
        />

    </div>
  )
}

export default Attendance