import React, { useState } from 'react'
import KDropdown from '../common/KDropdown/KDropdown'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { CSmartTable } from '@coreui/react-pro'
import { getstatusReport } from '../../../services/Apiservices';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
const StatusReport = () => {

  const status = [
    { value: "all", label: "All" },
    { value: "Completed", label: "Completed" },
    { value: "Pending", label: "Pending" },
    { value: "Carried for the next day", label: "Carried for the Next Day" },
  ]

  const [statusValue, setstatusValue] = useState("");
  const [reportdata, setreportdata] = useState([]);
  const [reportTable, setreportTable] = useState(false);
  const columns = [
    //   {
    //     key: 'sNo',
    //     _style: { width: '5%' },
    //     filter: true,
    //     sorter: false,

    // },
    {
      key: "class",
      label: "Class",
      _style: { width: "10%" },
      sorter: false
    },
    {
      key: "unit",
      label: "Chapter / Unit Name",
      _style: { width: "20%" },
      sorter: false
    },
    {
      key: "session",
      label: "Session",
      _style: { width: "15%" },
      sorter: false
    },
    {
      key: "duration",
      label: "Duration (in Period)",
      _style: { width: "3%" },
      sorter: false
    },
    {
      key: "start_date",
      label: "Start Date",
      _style: { width: "7%" },
      sorter: false
    },
    {
      key: "end_date",
      label: "End Date",
      _style: { width: "7%" },
      sorter: false
    },
    {
      key: "status",
      label: "Status",
      _style: { width: "8%" },
      sorter: false
    },

  ]
  const handleDownload = () => {

    const doc = new jsPDF();

    let startY = 20;
    const spaceBetweenTables = 5;

    for (let index = 0; index < reportdata.length; index++) {
      const x = reportdata[index];
      const Date = reportdata[index].date;

      if (index === 0) {
        doc.setFont('helvetica', 'bold'); // Set the font style
        doc.setFontSize(16); // Set the font size
        doc.text(75, 15, 'Status Report'); // Centered text
      }

      if (index !== 0) {
        startY += spaceBetweenTables; // Add space between tables
      }

      doc.setFont('helvetica', 'normal'); // Set the font style
      doc.setFontSize(10); // Set the font size
      doc.text(90, startY + 7, `${Date}`);


      const tableHeaders = [
        'SNo',
        'CLASS',
        'SUBJECT',
        'UNIT',
        'SESSION',
        'PERIOD',
        'START DATE',
        'END DATE',
        'STATUS',
      ];

      let tableData = [];


      for (let index = 0; index < x.data.length; index++) {
        const datas = x.data[index];
        console.log(datas, "datasreport")
        tableData.push([
          index + 1,
          datas ? datas.class : '',
          datas ? datas.sub : '',
          datas ? datas.unit : '',
          datas ? datas.session : '',
          datas ? datas.duration : '',
          datas ? datas.start_date : '',
          datas ? datas.end_date : '',
          datas ? datas.status : '',
        ]);

      }


      // Add additional text above the table

      // doc.addField()
      doc.autoTable({
        head: [tableHeaders],
        body: tableData,
        styles: {
          fontSize: 8,
          font: 'helvetica',
          cellPadding: 2,
          minCellHeight: 2,
        },
        theme: "grid",
        headStyles: {
          fillColor: [16, 126, 183],
          textColor: [255, 255, 255],
          fontSize: 9,
          padding: 0,
        },
        startY: startY + 10, // Adjust the starting Y position
        margin: { top: 10 },
        columnStyles: { 0: { cellWidth: 10 } }, // Adjust the first column width
      });

      startY = doc.autoTable.previous.finalY; // Update the Y position after rendering the table

    }




    const tableWidth = 200; // Adjust as needed
    const columnWidths = [30, 30, 30, 30, 30]; // Adjust as needed


    doc.save('StatusReport.pdf');
  };
  const GetStatusReport = async () => {
    setreportTable(true)
    // setreportdata([])
    console.log(statusValue.value, "statusValue")
    let stausvalue = statusValue.value
    let retrData = JSON.parse(localStorage.userCreds);
    const bod = {
      refStaffID: retrData.staffID,
      status: stausvalue
    }
    try {
      const res = await getstatusReport(bod)

      if (res) {
        if (res.status === "success") {

          if (res.value) {
            console.log(Object.keys(res.value), "Object.keys(res.value)");
            let TData = []

            for (let index = 0; index < Object.keys(res.value).length; index++) {
              const date = (Object.keys(res.value))[index];
              const Res = {
                date: date,
                data: res.value[`${date}`].map((x, i) => {
                  // console.log(x, "date");
                  console.log(res.value[`${date}`], "res.value[`${date}`]");
                  return {
                    // sNo: i + 1,
                    class: x.variableName,
                    sub: x.subjectName,
                    unit: x.unitName,
                    session: x.sessionName,
                    duration: `${x.duration} duration in ${x.period} period`,
                    start_date: new Date(x.estStartDate).toLocaleDateString(),
                    end_date: new Date(x.estEndDate).toLocaleDateString(),
                    status: x.status,
                  }
                })
              }
              TData.push(Res)
            }

            setreportdata(TData)
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  }
  return (
    <div>

      {!reportTable ? (
        <>
          <div className='todaytaskcalass mt-5'>
            Status Report
          </div>

          <div className='d-flex justify-content-center mt-4'>
            <KDropdown
              containerClassName="pl-md-3 pl-0 pt-2 pt-md-0 w-25 nobold"
              label="Select Status"
              isRequired={true}
              placeholder="Select Status"
              options={status}
              value={statusValue}
              onChange={(e) => {
                console.log(e.label, "event");
                setstatusValue(e);
              }}
            />
          </div>

          {/* <div className='d-flex justify-content-end statusreportbtn'>
            <button type="button" class="btn btn-primary px-3" disabled={!statusValue} onClick={() => { GetStatusReport() }}> <FontAwesomeIcon icon={faPrint} /> Report</button>
          </div> */}
        </>
      ) : (
        <>
          <div>
            <div className='todaytaskcalass mt-5'>
              Status Report of {localStorage.length > 0 &&
                JSON.parse(localStorage.userCreds).adminName}
            </div>
          </div>
          <div className='paddingtaskReport mt-5'>

            {
              reportdata.map((x, i) => {
                return (
                  <div className={'mb-5'}>
                    <div
                      className='py-3 font-weight-bold d-flex justify-content-center align-items-center'
                    >{x.date}</div>
                    <CSmartTable
                      activePage={1}
                      clickableRows
                      columns={columns}
                      columnFilter
                      columnSorter
                      items={x.data}
                      tableProps={{
                        striped: true,
                        hover: true,
                      }}
                    />
                  </div>
                )
              })
            }

            <div className='d-flex justify-content-end gap-2 taskreportprintbtn'>
              <button type="button" class="btn btn-primary" onClick={() => { handleDownload() }}>Print</button>
              <button type="button" class="btn btn-danger" onClick={() => { setreportTable(false); setstatusValue("") }}>Back</button>
            </div>
          </div>
        </>
      )}

    </div>
  )
}

export default StatusReport
