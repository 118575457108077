// Npm Packages
import React, { Fragment, useState, useEffect } from "react";
import parse from "html-react-parser";
import moment from "moment";
import { toast } from "react-toastify";
import { CSmartTable } from "@coreui/react-pro";
// Hooks
import useResponsive from "../../hooks/useResponsive";

// Component or Elements
import Header from "./HeaderNavbar";
import Footer from "./Footer";

// Styles
import "./StudentAssignment.css";

// Icons
// Custom Components
import KDropdown from "./common/KDropdown";
import KInput from "./common/KInput";
import KButton from "./common/KButton";
import KHeader from "./common/KHeader";
import KRequiredLabel from "./common/KRequiredLabel";
import KEditor from "./common/KEditor";
import Warning from "./Toastify/Warning";
import Success from "./Toastify/Success";
import Danger from "./Toastify/Danger";

// Services
import {
  postAnswersByStudent,
  getTableData,
  Past_Assignment_List_table,
} from "../../services/Apiservices";

const PastAssignment = ({
  showProfile,
  falseShowProfile,
  notofShowProfile,
  ShowNotifications,
  showNotification,
  falseShowNotification,
  disableOnOuterTouch,
  shoq,
  KpostIframe,
  closeForm,
  openForm,
  goToMainRoutes
}) => {
  // Initialization
  // Hooks Initialization
  const { isBigScreen } = useResponsive();
  const [answerEditor, setAnswerEditor] = useState([]);
  const [draftCollection, setDraftCollection] = useState([]);
  const [responseCollection, setResponseCollection] = useState([]);
  const [totalmarksReport, setTotalmarksReport] = useState([]);
  
  const [tableAssignmentDisplay, setTableAssignmentDisplay] = useState([]);
  const [selectedAssignment, setSelectedAssignment] = useState("");
  const[dataVisible3,setDataVisible3] = useState(false);
  const[dataVisible1,setDataVisible1] = useState(true);
  const[dataVisible2,setDataVisible2] = useState(false);
  const [questionDisplay, setQuestionDisplay] = useState([
    // { exetr permited solution 
    //   _id:"87878798798",
    //   question:"Hello"
    // }
  ]);

  const[assignmentSecondData,setAssignmentSecondData] = useState([{

    No:"",
    assignmentId:"",
    SubjectName: "",
    assignmentName: "",
    Assignedby: "",
    totalMarks: "",
    lsd:"",
    
  }])
  
  useEffect(() => {
    // setDataVisible3(false);
    // setDataVisible1(true);
    // setDataVisible2(false);
    assignment_table();
    
  }, []);

  const columns_ThirdTabel_Assign_status = [
    {
      key: "No",
      label: "S.No",
      _style: { width: "6%" },
    },
    {
      key: "Question",
      label: "List of Question and Answer",
      _style: { width: "65%" },
    },
    // {
    //   key: "correctAnswer",
    //   label: "Student Answer",
    //   _style: { width: "30%" },
    // },
    {
        key: "MarksObtained",
        label: "Marks Obtained",
        _style: { width: "15%" },
      },
    {  key: "comment", 
       label: "Comment", 
       _style: { width: "15%" } },
  ];

  const POST_Assignment_table = [
    {
      key: "No",
      label: "S.No",
      _style: { width: "6%" },
    },
    {
      key: "katbookSubjectName",
      label: "Subject",
      _style: { width: "12%" },
    },
    {
      key: "assignmentName",
      label: "Assignment Name",
      _style: { width: "16%" },
    },
    {
        key: "staffName",
        label: "Assignment By Teacher",
        _style: { width: "15%" },
      },
    {  key: "totalMarks", 
       label: "Marks", 
       _style: { width: "15%" } },

    {  key: "lsd", 
       label:"Last Date of Submission", 
       _style: { width: "15%" } },
       
    // { key: "Action", _style: { width: "10%" } },
  ];

  const POST_Assignment_table2 = [
    // {
    //   key: "No",
    //   label: "S.no",
    //   _style: { width: "6%" },
    // },
    {
      key: "SubjectName",
      label: "Subject",
      _style: { width: "12%" },
    },
    {
      key: "assignmentName",
      label: "Assignment Name",
      _style: { width: "16%" },
    },
    {
        key: "Assignedby",
        label: "Assignment By Teacher",
        _style: { width: "15%" },
      },
    {  key: "totalMarks", 
       label: "Marks", 
       _style: { width: "15%" } },

    {  key: "lsd", 
       label: "Last Date of Submission", 
       _style: { width: "15%" } },
       
   
  ];


  const Student_Past_Question_Answer = async (assignmentId) => {
    let response;
    setQuestionDisplay([]);
    const body = {
      studentId: localStorage.length > 0 && JSON.parse(localStorage.userCreds).userName,
      assignmentId: assignmentId,

    };
    // console.log("sdf5f45",body)
    try {
      response = await getTableData(body);
      // console.log(response, "qalist123455");
      if (response) {
        if (response.status==="success") {
          // toast.success(response.message);
          if (response.data) {
            const data = response.data.map((x, index) => {
             
              return {
                No: index + 1,
               
                // Question:`
                //   Question :${x?.question }
                //   Correct Answer : ${x?.correctAnswer}
                //   Student Answer : ${x?.studentAnswer === '' ? 'Student Not Answer' : x?.studentAnswer}`
                //   ,
                //  correctAnswer:`${x.studentAnswer === null ? '-' : x.studentAnswer}`,
                 comment:`${x?.comment === '' ? '-' : x?.comment}`, 
                 Question:x.question,
                // Comsment:`${x?.Comment === 'undefined' ? '-' : x?.Comment}`, 
                // MarksObtained:`${x.studentMark == '' ? '-' : x.studentMark}`,
                
                MarksObtained: `${x.answerType === "RadioButton" ? (x.studentAnswer === x.correctAnswer ? x.marks : 0) : (x.studentMark !== null ? x.studentMark : "-")}`,
                marks:x.marks,
                correctAnswer:x.correctAnswer,
                studentAnswer:x.studentAnswer,
                answerType:x.answerType,
                option: x.options === "" || x.options === null ? "" : `${JSON.parse(x.options)?.map((x, i) => `${i + 1}.${x.options}`).join('\n')}`,
                  
                obtainedMark: `${x.answerType === "RadioButton" ? 
                (x.studentAnswer === x.correctAnswer ? x.marks : 0)
                 : (x.studentMark !== null ? x.studentMark : "-")}`,
             
                 studentMark: `${x.answerType === "RadioButton" ? (x.studentAnswer ? x.studentAnswer === x.correctAnswer ? x.marks : x.studentAnswer : "") : (x.studentMark !== null ? x.studentMark : "")}`,
              
              };
            });
            setQuestionDisplay(data);
            let totalMarks = 0;
            for (let i = 0; i < data.length; i++) {
              const x = data[i];
              if (x.answerType === "RadioButton") {
                
                totalMarks = x.studentAnswer === x.correctAnswer ? Number(totalMarks) + Number(x?.marks ?? 0) : Number(totalMarks) +  0;
              } else{
                totalMarks = x.studentMark !== null ? Number(totalMarks) + Number(x?.studentMark ?? 0) : Number(totalMarks) +  0;
              }
             
            }
            if (isNaN(totalMarks)) {
              totalMarks = "-";
            }
            setTotalmarksReport(totalMarks);
          }
        } 
              
        else {
          toast.error(response.message);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };


  const assignment_table = async () => {
    const userCreds = await JSON.parse(localStorage.getItem("userCreds"));
    
    if (userCreds) {
      try{
          const body = {
          sectionId:userCreds. classID,
          classId:userCreds.variableID,
          studentId:userCreds.userName,
        }
        // console.log("asdjklasjlkjdas",body)
        // const studentAssignment = await getStudentAssignment();
        const response = await Past_Assignment_List_table(body);

        if(response.status === "403"){
          goToMainRoutes()
        }
        
        if (response.status==="success") {
          // toast.success(response.message);
          if (response.data)        
          {
            // console.log("jfklsdjf",response.data)
            setTableAssignmentDisplay(response.data.map((x,i)=>{
              return({
                No:i+1,
                assignmentId : x.assignmentId,
                staffId: x.staffId,
                Assignedby:x.staffName,
                staffName: x.staffName,
                staffDesignation: x.staffDesignation,
                katbookSubjectId: x.katbookSubjectId,
                katbookSubjectName:x.katbookSubjectName,
                assignmentName: x.assignmentName,
                refInstaId:x.refInstId,
                username: x.username,
                classId: x.classId,
                sectionId: x.sectionId,
                totalMarks: x.totalMarks,
                // lsd: x.lsd,
                lsd:`${moment(x.lsd).format("DD-MMM-YYYY")}`,
                // lsd:`${moment(x.lsd).format("DD-MMM-YYYY")}`,
              })
            }));
          }
        } 
        else {
          // toast.error(response.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
   return (
    <div onClick={() => disableOnOuterTouch()}>
      {/* Header Section Starts  */}
      <div className="Navbar">
        <Header
          showProfile={showProfile}
          falseShowProfile={falseShowProfile}
          notofShowProfile={notofShowProfile}
          ShowNotifications={ShowNotifications}
          showNotification={showNotification}
          falseShowNotification={falseShowNotification}
        />
      </div>

      <div className={"Dash_home_QWI"}>
        <div
          className="myattendcard2w p-3"
          style={{
            height: window.innerHeight - 117 + "px",
            overflowY: "auto",
          }}
        >
          {/* Assessment Table Starts */}
          <div className="container-fluid px-5 py-3">

              <div className="">
                <div className="mb-4">
                   <h4>
                   Past Assignment - {`${moment().format("DD MMM YYYY")}`}
                   </h4>
                    
                </div>
                  {dataVisible1 && (
                    <div className="tabelstyle">
                      <CSmartTable
                        items={tableAssignmentDisplay}
                        columns={POST_Assignment_table}
                        columnFilter
                        columnSorter
                        itemsPerPage={5}
                        
                        itemsPerPageSelect={tableAssignmentDisplay.length > 5}
                        pagination
                        clickableRows
                        onRowClick={(item)=>{
                         
                            setDataVisible1(false);
                            setDataVisible2(true);
                            setDataVisible3(true);
                            Student_Past_Question_Answer(item.assignmentId)
                            setAssignmentSecondData([{
                              No:item.No,
                              assignmentId:item._id,
                              assignmentName:item.assignmentName,
                              SubjectName:item.katbookSubjectName,
                              Assignedby: item.Assignedby,
                              totalMarks: item.totalMarks,
                              lsd:item.lsd,
                             
                            }])
                            
                          
                        }}
                        // scopedColumns={{
                        //   Action: (item) => (
                        //     <td>
                        //       <button
                        //         type="button"
                        //         className=" clickmeBtn "
                        //         title="Delete"
                        //         onClick={() => {
                        //           setDataVisible1(false);
                        //           setDataVisible2(true);
                        //           setDataVisible3(true);
                        //           Student_Past_Question_Answer(item.assignmentId)
                        //           setAssignmentSecondData([{
                        //             No:item.No,
                        //             assignmentId:item._id,
                        //             assignmentName:item.assignmentName,
                        //             SubjectName:item.katbookSubjectName,
                        //             Assignedby: item.Assignedby,
                        //             totalMarks: item.totalMarks,
                        //             lsd:item.lsd,
                                   
                        //           }])
                                  
                        //         }}
                        //       >
                        //         view
                        //       </button>
                        //     </td>
                        //   ),
                        // }}
                        tableProps={{
                          striped: true,
                          hover: true,
                          responsive: true,
                        }}
                      />
                    </div>
                  )}
                  
                  {dataVisible2 && (
                    <div className="tabelstyle">
                      <CSmartTable
                        columns={POST_Assignment_table2}
                        // columnFilter
                        // columnSorter
                        items={assignmentSecondData}
                        // itemsPerPage={5}
                        // itemsPerPageSelect={assignmentSecondData.length > 5}
                        // pagination
                        scopedColumns={{
                          Action: (item) => (
                            <td>
                              
                            </td>
                          ),
                        }}
                        tableProps={{
                          striped: true,
                          hover: true,
                          responsive: true,
                        }}
                      />
                    </div>
                  )}

                  
              </div>
              <br />
              
              {dataVisible3 && (
                <>
                  <div className="mt-2 d-flex justify-content-between">
                    <h4>Question and Answer</h4>
                    <h5>Total Marks : <b>{totalmarksReport === undefined ? "-":"" && totalmarksReport === null ? "-":totalmarksReport } </b></h5>
                        
                    <button
                      className="backBtn"
                      onClick={() => {
                        // setMainTable(true);
                        // setSubTable(true);
                        // setSubTableBox(false);
                        setDataVisible3(false);
                        setDataVisible1(true);
                        setDataVisible2(false);
                        
                      }}
                    >
                      Back
                    </button>
                  </div>
                  <div className="mt-2 tabelstyle ">
                        <CSmartTable
                          items={questionDisplay}
                          columnSorter
                          columnFilter
                          itemsPerPage={5}
                          itemsPerPageSelect={questionDisplay.length > 5}
                          pagination
                          // assignmentData_3_Assign_status
                          columns={columns_ThirdTabel_Assign_status}
                          scopedColumns={{
                            // Question : (item) =>(<td className="textalign">{parse(item.Question)}</td>),
                            
                            Question : (item) =>(<td className="textalign">
                        
                            <span><b>Question :</b>{parse(item.Question)}</span>
                            <div>
     
                                 {(
                                   item.answerType === "RadioButton" && 
                                   <span className="optionName"> 
     
                                   {parse(item.option)}
                                 </span>
     
                                 )}
                             </div>
                            <span><b>Correct Answer :</b> {parse(item.correctAnswer)}</span>
                            <span >
                            <b>Student Answer : </b> {parse(item.studentAnswer)}</span>
                            
                          </td>),
                           MarksObtained: (item) => (
                             <td>
                              
                               {item.MarksObtained}
       
                             </td>
                           ),
                           comment: (item) => (
                             <td>
                              {item.comment}
                               {/* {questionDisplay.find(data => data.QuestionId === item.questionId)?.comment
                                 ?
                                 parse(questionDisplay.find(data => data.QuestionId === item.questionId)?.comment)
                                 : <p style={{color:"red"}}>-</p>
                               } */}
                             </td>
                           ),
                            
                          }}
                          tableProps={{
                            hover: true,
                            responsive: true,
                          }}
                        />
                     </div>
                 </>
             )}

           </div>
         
        </div>
      </div>
      {/* Footer Section Starts */}
      <Footer
        openForm={openForm}
        shoq={shoq}
        closeForm={closeForm}
        KpostIframe={KpostIframe}
      />
      {/* Footer Section Ends */}
    </div>
  );
};

export default PastAssignment;
