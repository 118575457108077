import React, { useState, useEffect } from "react";
import Header from "./HeaderNavbar";
import Footer from "./Footer";
import { CCol } from "@coreui/react";
import TimeTableClass from "./Dashboard_folder/TimeTableClass";
import TimeTableTeacher from "./Dashboard_folder/TimeTableWeekTeacher";
// import { MdVideocam } from "react-icons/md"
import TimeTableCard from "./Dashboard_folder/TimeTableCard";
import {
  getDynamicTimetable,
  getDynamicTimetableByStaff,
  getDynamicTimetableByStaffWithDate,
  getDynamicTimetableByDate,
  getUnitSessionNameFromtable,
} from "../../services/Apiservices";
import Img from "../../Assets/Img.png";
import Img1 from "../../Assets/Img.png";
import { format } from "date-fns";
import Subload from "../../Assets/subject_loading.gif";
import moment from "moment";
import { IoVideocam, IoCalendarOutline } from "react-icons/io5";
import { FaCaretLeft, FaCaretRight } from "react-icons/fa";
import Sample from "../../Assets/sample_Img.png";
import loadingGif from "../../Assets/loading-gif.gif";
import {
  AiOutlineLeft,
  AiOutlineRight,
  AiOutlineArrowLeft,
  AiOutlineDown,
  AiOutlineUp,
  AiOutlineSolution,
} from "react-icons/ai";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import { CContainer, CRow } from "@coreui/react-pro";
import { TiTickOutline } from "react-icons/ti";
import { IoIosClose } from "react-icons/io";
import { Authcontext } from "./Context/Context";
import { toast } from "react-toastify";
import Danger from "../components/Toastify/Danger";

const ResultDataView = ({ unitID, sessionID }) => {
  const [data, setData] = React.useState("");
  // const [Subdata,setSubData] = React.useState('')

  React.useEffect(() => {
    const asynccc = async () => {
      let getUnitVales = {
        uId: unitID,
        sId: sessionID,
      };

      let getresultUnits = await getUnitSessionNameFromtable(getUnitVales);
      // console.log(getresultUnits,"QQQQQQQQQQQQQQQQQQQQQQQ");

      // let variName = await getresultUnits.unit.variableName;
      setData(getresultUnits);
      // setSubData(getresultUnits.session.variableName)
    };
    asynccc();
  }, [unitID, sessionID]);

  return (
    <>
      <div>
        <span className="UnitFontNumber">
          {data.unit ? data.unit.variableName : ""}
          {/* {data.unit ? data.unit.variableName.toString().length > 25 ? data.unit.variableName.toString().slice(0,25)+'...' : data.unit.variableName : ""} */}
        </span>
      </div>
      <div>
        <span className="UnitFontNumber color_schedule">
          {data.session ? data.session.variableName : ""}
          {/* {data.session ? data.session.variableName.toString().length > 15 ? data.session.variableName.toString().slice(0,15)+'...' : data.session.variableName : ""} */}
        </span>
      </div>
    </>
  );
};

const ResultDataWeek = ({ unitID, sessionID }) => {
  const [data, setData] = React.useState("");
  // const [Subdata,setSubData] = React.useState('')

  React.useEffect(() => {
    const asynccc = async () => {
      let getUnitVales = {
        uId: unitID,
        sId: sessionID,
      };

      let getresultUnits = await getUnitSessionNameFromtable(getUnitVales);
      // console.log(getresultUnits,"QQQQQQQQQQQQQQQQQQQQQQQ");

      // let variName = await getresultUnits.unit.variableName;
      setData(getresultUnits);
      // setSubData(getresultUnits.session.variableName)
    };
    asynccc();
  }, [unitID, sessionID]);

  return (
    <>
      <span className="boxsec">
        {data.unit
          ? localStorage.length > 0 &&
            JSON.parse(localStorage.userCreds).userType === "Teaching"
            ? data.unit.variableName
            : data.unit.variableName.toString().length > 25
            ? data.unit.variableName.toString().slice(0, 25) + "..."
            : data.unit.variableName
          : ""}
      </span>
      <br />
      <span className="boxsec">
        {/* {data.session ? data.session.variableName : ""} */}
        {data.session
          ? localStorage.length > 0 &&
            JSON.parse(localStorage.userCreds).userType === "Teaching"
            ? data.session.variableName
            : data.session.variableName.toString().length > 15
            ? data.session.variableName.toString().slice(0, 15) + "..."
            : data.session.variableName
          : ""}
      </span>
    </>
  );
};

const Timetable = ({
  VideoConfFunc,
  tConvert,
  CuttentDateTime,
  tableBgColors,
  openBookview,
  showProfile,
  falseShowProfile,
  notofShowProfile,
  ShowNotifications,
  showNotification,
  falseShowNotification,
  disableOnOuterTouch,
  shoq,
  KpostIframe,
  closeForm,
  openForm,
  goToMainRoutes
}) => {
  const { state, dispatch } = React.useContext(Authcontext);
  // console.log(state,"CONTEXT STORAGE");
  const [userCredsvalues, setuserCredsvalues] = useState("");
  const [timeTableData, settimeTableData] = useState(state?.DayTimetable.length > 0 ? state?.DayTimetable : []);
  const [pendSession, setpendSession] = useState([]);
  const [weektimeTableData, setweektimeTableData] = useState(state?.WeekTimetable.length > 0 ? state?.WeekTimetable : []);
  const [selectedIndexValue, setselectedIndexValue] = useState(0);
  const [timeTableDataLength, settimeTableDataLength] = useState(state?.DayTimetable ? state?.DayTimetable.length : 0);
  const [timeTablePeriod, settimeTablePeriod] = useState(state?.DayTimeTablePeriod.length > 0 ? state?.DayTimeTablePeriod : []);
  const [weektimeTablePeriod, setweektimeTablePeriod] = useState(state?.WeekTimeTablePeriod.length > 0 ? state?.WeekTimeTablePeriod : []);
  const [weektimeTablePeriodUnique, setweektimeTablePeriodUnique] = useState(
    []
  );
  const [nextDateTimeTbale, setnextDateTimeTbale] = useState(state?.WeekTimetable.length > 0 ? Object.keys(state?.WeekTimetable)[
    Object.keys(state?.WeekTimetable).length - 1
  ].split(" ")[1] : "");
  const [prevDateTimeTable, setprevDateTimeTable] = useState(state?.WeekTimetable.length > 0 ? Object.keys(state?.WeekTimetable)[0].split(" ")[1] : "");
  const [TimeTablechangeCount, setTimeTablechangeCount] = useState(0);
  const [singleDateValue, setsingleDateValue] = useState([]);
  const [dateEnable, setdateEnable] = useState("");
  const [selectedDate, setselectedDate] = useState("");
  const [timerData, settimerData] = useState("");
  const [day, setDay] = useState(true);
  const [week, setWeek] = useState(false);
  const [subfetchs, setSubfetchs] = useState(true);
  const [prevTimeTableData, setprevTimeTableData] = useState([]);
  const [selectedtimetableDate, setselectedtimetableDate] = useState(
    moment().format("DD/MMM/YYYY")
  );
  const [onChangeDate, setonChangeDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [isLoading, setisLoading] = useState(state?.DayTimetable.length > 0 ? false : true);
  const Day_Week = () => {
    setDay(true);
    setWeek(false);
  };
  const Week_Day = () => {
    setDay(false);
    setWeek(true);
  };

  const CurrentDateItem = format(new Date(), "dd/MMM/yyyy");

  const yearMonth = format(new Date(), "dd MMM, yyyy");

  // console.log(format(new Date(),"dd/MMM/yyyy"),"CURRENT DATE");

  useEffect(() => {
    if (localStorage.length > 0) {
      let retrData = JSON.parse(localStorage.userCreds);

      setuserCredsvalues(retrData);

      // console.log(retrData);

      const getTimeTablefunc = async () => {
        let allquesbod, allquestres, weekallquesbod, weekallquestres;

        if (retrData.userType === "Teaching") {
          allquesbod = {
            refInstID: retrData.refInstID,
            refStaffID: retrData.staffID,
            refYearID: retrData.yearId,
            date: moment().format("DD/MM/YYYY"),
          };

          weekallquesbod = {
            refInstID: retrData.refInstID,
            refStaffID: retrData.staffID,
            refYearID: retrData.yearId,
          };

          allquestres = await getDynamicTimetableByStaffWithDate(allquesbod);

          weekallquestres = await getDynamicTimetableByStaff(weekallquesbod);
        } else {
          allquesbod = {
            refInstID: retrData.refInstID,
            refMediumID: retrData.mediumId,
            refYearID: retrData.yearID,
            refSectionID: retrData.classID,
            refVariableID: retrData.variableID,
            date: moment().format("DD/MM/YYYY"),
          };

          weekallquesbod = {
            refInstID: retrData.refInstID,
            refMediumID: retrData.mediumId,
            refYearID: retrData.yearID,
            refSectionID: retrData.classID,
            refVariableID: retrData.variableID,
          };

          allquestres = await getDynamicTimetableByDate(allquesbod);

          weekallquestres = await getDynamicTimetable(weekallquesbod);
        }

        if(allquestres.status === "403" || weekallquestres.status === "403"){
          goToMainRoutes();
        }

        if (allquestres.status === "success") {
          settimeTablePeriod(
            JSON.parse(allquestres.periodDetails).filter(
              (item) => item.breakName === undefined
            )
          );

          // console.log(allquestres.value.filter((item) => item.katbookSessionID !== null),"EEEEEEEEEEE");

          // console.log(allquestres.value,"EEEEEEEEEEE");

          settimeTableData(
            allquestres.value.filter((item) => item.katbookSessionID !== null)
          );

          setselectedIndexValue(0);

          settimeTableDataLength(
            allquestres.value.filter((item) => item.katbookSessionID !== null)
              .length
          );
        }

        if (weekallquestres.status === "success") {
          setweektimeTablePeriod(
            JSON.parse(weekallquestres.periodDetails).filter(
              (item) => item.breakName === undefined
            )
          );

          setweektimeTableData(weekallquestres.value);

          if(weekallquestres.value.length > 0){

          setnextDateTimeTbale(
            Object.keys(weekallquestres.value)[
              Object.keys(weekallquestres.value).length - 1
            ].split(" ")[1]
          );

          setprevDateTimeTable(
            Object.keys(weekallquestres.value)[0].split(" ")[1]
          );

          }

          setTimeTablechangeCount(TimeTablechangeCount);
        }

        setisLoading(false);
      };

      getTimeTablefunc();
    } else {
    }
  }, []);

  // console.log(CuttentDateTime, "TABLE COLORS");

  const updateDates = async (updateDate) => {
    // alert(updateDate);
    // alert(moment(updateDate).format("DD/MM/YYYY"));
    setselectedtimetableDate(moment(updateDate).format("DD/MMM/YYYY"));
    setonChangeDate(moment(updateDate).format("YYYY-MM-DD"));

    let allquesbod, allquestres;

    if (userCredsvalues.userType === "Teaching") {
      allquesbod = {
        refInstID: userCredsvalues.refInstID,
        refStaffID: userCredsvalues.staffID,
        refYearID: userCredsvalues.yearId,
        date: moment(updateDate).format("DD/MM/YYYY"),
      };

      allquestres = await getDynamicTimetableByStaffWithDate(allquesbod);
    } else {
      allquesbod = {
        refInstID: userCredsvalues.refInstID,
        refMediumID: userCredsvalues.mediumId,
        refYearID: userCredsvalues.yearID,
        refSectionID: userCredsvalues.classID,
        refVariableID: userCredsvalues.variableID,
        date: moment(updateDate).format("DD/MM/YYYY"),
      };

      allquestres = await getDynamicTimetableByDate(allquesbod);
    }

    if (allquestres.status === "success") {
      settimeTablePeriod(
        JSON.parse(allquestres.periodDetails).filter(
          (item) => item.breakName === undefined
        )
      );

      settimeTableData(
        allquestres.value.filter((item) => item.katbookSessionID !== null)
      );

      setselectedIndexValue(0);

      settimeTableDataLength(
        allquestres.value.filter((item) => item.katbookSessionID !== null)
          .length
      );
    }
  };

  const fetchNextTimeTableList = async (nextDate, prevDate) => {
    // alert(nextDate);

    let allquesbod, allquestres;

    if (userCredsvalues.userType === "Teaching") {
      allquesbod = {
        refInstID: userCredsvalues.refInstID,
        refStaffID: userCredsvalues.staffID,
        refYearID: userCredsvalues.yearId,
        lastTimeTableDate: nextDate,
      };

      allquestres = await getDynamicTimetableByStaff(allquesbod);
    } else {
      allquesbod = {
        refInstID: userCredsvalues.refInstID,
        refMediumID: userCredsvalues.mediumId,
        refYearID: userCredsvalues.yearID,
        refSectionID: userCredsvalues.classID,
        refVariableID: userCredsvalues.variableID,
        lastTimeTableDate: nextDate,
      };

      allquestres = await getDynamicTimetable(allquesbod);
    }

    if (allquestres.status === "success") {
      if (prevTimeTableData.length <= TimeTablechangeCount) {
        let datestore = {
          count: TimeTablechangeCount + 1,
          date: prevDate,
        };
        prevTimeTableData.push(datestore);
      }
      // console.log(prevTimeTableData, "prevTimeTableData");

      // console.log(JSON.parse(allquestres.periodDetails)[0].breakName);
      // console.log(
      //   JSON.parse(allquestres.periodDetails).filter(
      //     (item) => item.breakName === undefined
      //   )
      // );
      setweektimeTablePeriod(
        JSON.parse(allquestres.periodDetails).filter(
          (item) => item.breakName === undefined
        )
      );
      setweektimeTableData(allquestres.value);

      if(allquestres.value.length > 0){

      setnextDateTimeTbale(
        Object.keys(allquestres.value)[
          Object.keys(allquestres.value).length - 1
        ].split(" ")[1]
      );

      setprevDateTimeTable(Object.keys(allquestres.value)[0].split(" ")[1]);

      }

      setTimeTablechangeCount(TimeTablechangeCount + 1);

      // console.log(TimeTablechangeCount + 1, "prevTimeTableData");
    }
  };

  const fetchPrevTimeTableList = async () => {
    // alert(moment(prevTimeTableData[TimeTablechangeCount - 1].date).subtract(1, 'day').format("DD/MMM/YYYY"))
    let allquesbod, allquestres;

    if (userCredsvalues.userType === "Teaching") {
      allquesbod = {
        refInstID: userCredsvalues.refInstID,
        refStaffID: userCredsvalues.staffID,
        refYearID: userCredsvalues.yearId,
        lastTimeTableDate: moment(
          prevTimeTableData[TimeTablechangeCount - 1].date
        )
          .subtract(1, "day")
          .format("DD/MMM/YYYY"),
      };

      allquestres = await getDynamicTimetableByStaff(allquesbod);
    } else {
      allquesbod = {
        refInstID: userCredsvalues.refInstID,
        refMediumID: userCredsvalues.mediumId,
        refYearID: userCredsvalues.yearID,
        refSectionID: userCredsvalues.classID,
        refVariableID: userCredsvalues.variableID,
        lastTimeTableDate: moment(
          prevTimeTableData[TimeTablechangeCount - 1].date
        )
          .subtract(1, "day")
          .format("DD/MMM/YYYY"),
      };

      allquestres = await getDynamicTimetable(allquesbod);
    }

    if (allquestres.status === "success") {
      // console.log(prevTimeTableData, "prevTimeTableData");

      // console.log(JSON.parse(allquestres.periodDetails)[0].breakName);
      // console.log(
      //   JSON.parse(allquestres.periodDetails).filter(
      //     (item) => item.breakName === undefined
      //   )
      // );
      setweektimeTablePeriod(
        JSON.parse(allquestres.periodDetails).filter(
          (item) => item.breakName === undefined
        )
      );
      setweektimeTableData(allquestres.value);

      if(allquestres.value.length > 0){

      setnextDateTimeTbale(
        Object.keys(allquestres.value)[
          Object.keys(allquestres.value).length - 1
        ].split(" ")[1]
      );

      setprevDateTimeTable(Object.keys(allquestres.value)[0].split(" ")[1]);

      }

      setTimeTablechangeCount(TimeTablechangeCount - 1);

      // console.log(TimeTablechangeCount - 1, "prevTimeTableData");
    }
  };

  const fetchdateWisetable = async (datevalue, index) => {
    setsingleDateValue(timeTableData[datevalue]);
    setselectedDate(datevalue);
    // setdateEnable(index);

    if (CurrentDateItem === datevalue.split(" ")[1]) {
      setdateEnable(0);
    } else {
      if (index === 0) {
        setdateEnable("");
      } else {
        setdateEnable(index);
      }
    }
  };


  const NextPendingSessions = async(x) =>{

    if(pendSession.length > 0 && pendSession.filter((q) => String(q.timetableId) === String(x.id)).length > 0){
      let objIndex = pendSession.findIndex((obj) => String(obj.timetableId) === String(x.id));
      let prevCount = pendSession[objIndex].pendingsessioncount;
      if(prevCount === 1){
      let RemainSessions = pendSession.filter((q) => String(q.timetableId) !== String(x.id))
      setpendSession([...RemainSessions]);
      console.log([...pendSession],"Pending session details");

    } else {

      let RemainSessions = pendSession.filter((q) => String(q.timetableId) !== String(x.id))
      let bod = {
        "timetableId": x.id,
        "pendingsessioncount" : prevCount - 1
      }
      setpendSession([...RemainSessions, bod]);
      console.log([...pendSession, bod],"Pending session details");
      
    }

    }
  }

  const viewPendingSessions = async(x) =>{
    console.log(x,"Pending session details");
    console.log(pendSession,"Pending session details");
    console.log(pendSession.length,"Pending session details");
    if(pendSession.length > 0 && pendSession.filter((q) => String(q.timetableId) === String(x.id)).length > 0){
      let objIndex = pendSession.findIndex((obj) => String(obj.timetableId) === String(x.id));
      let prevCount = pendSession[objIndex].pendingsessioncount;
      let RemainSessions = pendSession.filter((q) => String(q.timetableId) !== String(x.id))
      // console.log(pendSession[objIndex],"Pending session details");
      // console.log(pendSession[objIndex].pendingsessioncount,"Pending session details");
      // pendSession[objIndex].pendingsessioncount = pendSession[objIndex].pendingsessioncount + 1;
      // console.log(pendSession,"Pending session details");
      // setpendSession(pendSession);

      let bod = {
        "timetableId": x.id,
        "pendingsessioncount" : prevCount + 1
      }
      setpendSession([...RemainSessions, bod]);
      console.log([...pendSession, bod],"Pending session details");
    } else {
      let bod = {
        "timetableId": x.id,
        "pendingsessioncount" : 1
      }
      setpendSession([...pendSession, bod]);
      console.log([...pendSession, bod],"Pending session details");
    }
  }

  // console.log(moment().diff(onChangeDate,'days'),"date change");

  return (
    <div onClick={() => disableOnOuterTouch()}>
      <Header
        showProfile={showProfile}
        // profileIcon={KpIcon}
        // username={state.adminName ? state.adminName : state.user}
        falseShowProfile={falseShowProfile}
        notofShowProfile={notofShowProfile}
        // institutionName={state.institutionName && String(state.institutionName).toUpperCase()}
        institutionName={
          localStorage.length > 0 &&
          JSON.parse(localStorage.userCreds).institutionName.toUpperCase()
        }
        ShowNotifications={ShowNotifications}
        showNotification={showNotification}
        falseShowNotification={falseShowNotification}
      />
      {week && (
        <div
          className={"timeTable_Home_scrn"}
          style={
            {
              // display:"none"
            }
          }
        >
          <div className={"Padding_Time"}>
            <div className={"Time_Apr"}>
              <div>
                <span className={"Time_Table tableText"}>Timetable</span>
              </div>

              <div className={"M_D_W"}>
                <div>
                  <span className={"Apr_22"}>{yearMonth}</span>
                </div>

                <div className={"Switch_Day_Week"}>
                  <div className={"Switch_Day"} onClick={Day_Week}>
                    <span className={"Switch_Day_Font"}>Day</span>
                  </div>
                  <div className={"Switch_Week"}>
                    <span className={"Switch_Week_Font"}>Week</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {isLoading ? (
            <>
              <div
                className={"mt-4 mb-4"}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: "300px",
                }}
              >
                <img src={loadingGif} width="40px" />
              </div>
            </>
          ) : (
            <>
              {Object.keys(weektimeTableData).length > 0 && (
                <CContainer style={{ maxWidth: "1500px" }}>
                  <div className="px-3 py-4">
                    <div className="tableborderstyle">
                      <table>
                        <tr>
                          <th className="tableheadDay">Days</th>

                          {JSON.parse(localStorage.userCreds).userType ===
                            "Student" &&
                            weektimeTablePeriod.map((x, i) => (
                              <th className="tablehead">
                                Period {x.PeriodNumber}
                                <p className="tabletime">
                                  ({tConvert(x.StartTime)} -{" "}
                                  {tConvert(x.EndTime)})
                                </p>
                              </th>
                            ))}

                          {JSON.parse(localStorage.userCreds).userType ===
                            "Teaching" && (
                            <th colSpan={10} className="tablehead">
                              Teacher Timetable List
                            </th>
                          )}
                        </tr>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            onClick={() => {
                              if (TimeTablechangeCount > 0) {
                                fetchPrevTimeTableList();
                              }
                            }}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              // borderRight: "1px solid #c1c1c1",
                              // borderBottom: "1px solid #c1c1c1",
                              // borderLeft: "1px solid #c1c1c1",
                              borderBottomLeftRadius: "10px",
                              borderBottomRightRadius: "10px",
                              width: "50px",
                              cursor:
                                TimeTablechangeCount === 0 ? "" : "pointer",
                              backgroundColor:
                                TimeTablechangeCount === 0
                                  ? "#c1c1c1"
                                  : "#1653cc",
                            }}
                          >
                            <AiOutlineUp
                              style={{
                                color:
                                  TimeTablechangeCount > 0 ? "#fff" : "#ccc",
                              }}
                              size={20}
                            />
                          </div>
                        </div>

                        {JSON.parse(localStorage.userCreds).userType ===
                          "Student" &&
                          Object.keys(weektimeTableData).map((z, i) => {
                            return (
                              <>
                                <tr>
                                  <td className="days">
                                    {moment(z.split(" ")[1]).format("MMM DD")}
                                  </td>

                                  {/* { console.log(weektimeTableData[z],"GGGGGGGGGGGGGGGGGGG")} */}

                                  {weektimeTableData[z].map((x, index) => {
                                    return (
                                      <>
                                        {x?.subjectName ? (
                                          <>
                                            <td className="tdata">
                                              <div className="boxtab">
                                                <span
                                                  onClick={() => {
                                                    openBookview(
                                                      x.katbookUnitID,
                                                      x.katbookSessionID,
                                                      x.refSubjectID,
                                                      x.refSectionID
                                                    );
                                                  }}
                                                  className="boxtitle"
                                                >
                                                  {/* {x.subjectName}{" "} */}
                                                  {x.subjectName.toString()
                                                    .length > 13
                                                    ? x.subjectName
                                                        .toString()
                                                        .slice(0, 10) + "..."
                                                    : x.subjectName}
                                                </span>
                                                &nbsp;&nbsp;
                                                {x?.katbookUnitID !== null &&
                                                  x?.katbookSessionID !==
                                                    null && (
                                                    <span
                                                      onClick={() => {
                                                        // alert(selectedtimetableDate)
                                                        if (
                                                          (z.split(" ")[1] ===
                                                            moment().format(
                                                              "DD/MMM/YYYY"
                                                            ) &&
                                                            CuttentDateTime <
                                                              weektimeTablePeriod.filter(
                                                                (g) =>
                                                                  String(
                                                                    g.PeriodNumber
                                                                  ) ===
                                                                  String(
                                                                    x.period
                                                                  )
                                                              )[0]?.EndTime) ||
                                                          z.split(" ")[1] !==
                                                            moment().format(
                                                              "DD/MMM/YYYY"
                                                            )
                                                        ) {
                                                          VideoConfFunc(
                                                            z.split(" ")[1],
                                                            x?.subjectName,
                                                            weektimeTablePeriod.filter(
                                                              (g) =>
                                                                String(
                                                                  g.PeriodNumber
                                                                ) ===
                                                                String(x.period)
                                                            )[0]?.StartTime,
                                                            weektimeTablePeriod.filter(
                                                              (g) =>
                                                                String(
                                                                  g.PeriodNumber
                                                                ) ===
                                                                String(x.period)
                                                            )[0]?.EndTime,
                                                            x?.staffName,
                                                            x?.staffDesignation,
                                                            x?.katbookUnitID,
                                                            x?.katbookSessionID,
                                                            x?.refStaffID,
                                                            x?.id,
                                                            x?.refSubjectID,
                                                            x?.refSectionID
                                                          );
                                                        }
                                                        // document.querySelector("body").style.overflow = "hidden";
                                                      }}
                                                    >
                                                      <IoVideocam
                                                        style={{
                                                          color:
                                                            z.split(" ")[1] ===
                                                            moment().format(
                                                              "DD/MMM/YYYY"
                                                            )
                                                              ? String(
                                                                  CuttentDateTime
                                                                ) <
                                                                String(
                                                                  weektimeTablePeriod.filter(
                                                                    (g) =>
                                                                      String(
                                                                        g.PeriodNumber
                                                                      ) ===
                                                                      String(
                                                                        x.period
                                                                      )
                                                                  )[0]?.EndTime
                                                                )
                                                                ? "#1653cc"
                                                                : "#ccc"
                                                              : "#1653cc",
                                                          cursor:
                                                            z.split(" ")[1] ===
                                                            moment().format(
                                                              "DD/MMM/YYYY"
                                                            )
                                                              ? CuttentDateTime <
                                                                weektimeTablePeriod.filter(
                                                                  (g) =>
                                                                    String(
                                                                      g.PeriodNumber
                                                                    ) ===
                                                                    String(
                                                                      x.period
                                                                    )
                                                                )[0]?.EndTime
                                                                ? "pointer"
                                                                : "default"
                                                              : "pointer",
                                                        }}
                                                        className="VideoSize-Color"
                                                      />
                                                    </span>
                                                  )}
                                                <div
                                                  onClick={() => {
                                                    openBookview(
                                                      x.katbookUnitID,
                                                      x.katbookSessionID,
                                                      x.refSubjectID,
                                                      x.refSectionID
                                                    );
                                                  }}
                                                >
                                                  <ResultDataWeek
                                                    unitID={x?.katbookUnitID}
                                                    sessionID={
                                                      x?.katbookSessionID
                                                    }
                                                  />
                                                </div>
                                                {x?.katbookUnitID !== null &&
                                                  x?.katbookSessionID !==
                                                    null && (
                                                    <div className=" d-flex gap-1 mt-2">
                                                      <div>
                                                        <img
                                                          src={Sample}
                                                          alt=""
                                                          width={20}
                                                        />
                                                      </div>
                                                      <div>
                                                        <span
                                                          className={"boxsec"}
                                                        >
                                                          {/* Sheeba V */}

                                                          {localStorage.length >
                                                            0 &&
                                                          JSON.parse(
                                                            localStorage.userCreds
                                                          ).userType ===
                                                            "Teaching"
                                                            ? JSON.parse(
                                                                localStorage.userCreds
                                                              ).adminName
                                                            : x.staffName}
                                                          {/* {x.staffName} */}
                                                        </span>
                                                      </div>
                                                    </div>
                                                  )}
                                              </div>
                                            </td>
                                          </>
                                        ) : (
                                          <>
                                            <td> - </td>
                                          </>
                                        )}
                                      </>
                                    );
                                  })}
                                </tr>
                              </>
                            );
                          })}

                        {JSON.parse(localStorage.userCreds).userType ===
                          "Teaching" &&
                          Object.keys(weektimeTableData).map((z, i) => {
                            return (
                              <>
                                <tr>
                                  <td className="days">
                                    {moment(z.split(" ")[1]).format("MMM DD")}
                                  </td>

                                  {/* { console.log(weektimeTableData[z],"GGGGGGGGGGGGGGGGGGG")} */}

                                  {weektimeTableData[z].map((x, index) => {
                                    let SUbjectSc = `${x.subjectName} ${x.variableName} ${x.section}`;

                                    return (
                                      <>
                                        {x?.subjectName ? (
                                          <>
                                            <td className="tdata">
                                              Period{" "}
                                              {
                                                weektimeTablePeriod.filter(
                                                  (q) =>
                                                    String(q.PeriodNumber) ===
                                                    String(x.period)
                                                )[0].PeriodNumber
                                              }
                                              <p className="tabletime">
                                                (
                                                {tConvert(
                                                  weektimeTablePeriod.filter(
                                                    (g) =>
                                                      String(g.PeriodNumber) ===
                                                      String(x.period)
                                                  )[0]?.StartTime
                                                )}{" "}
                                                -{" "}
                                                {tConvert(
                                                  weektimeTablePeriod.filter(
                                                    (g) =>
                                                      String(g.PeriodNumber) ===
                                                      String(x.period)
                                                  )[0]?.EndTime
                                                )}
                                                )
                                              </p>
                                              <div className="boxtab">
                                                <span
                                                  onClick={() => {
                                                    openBookview(
                                                      x.katbookUnitID,
                                                      x.katbookSessionID,
                                                      x.refSubjectID,
                                                      x.refSectionID
                                                    );
                                                  }}
                                                  className="boxtitle"
                                                >
                                                  {/* {x.subjectName}{" "} */}
                                                  {/* {SUbjectSc.toString().length > 13 ? SUbjectSc.toString().slice(0,10)+'...' : SUbjectSc} */}
                                                  {SUbjectSc}
                                                </span>
                                                &nbsp;&nbsp;
                                                {x?.katbookUnitID !== null &&
                                                  x?.katbookSessionID !==
                                                    null && (
                                                    <span
                                                      onClick={() => {
                                                        // alert(selectedtimetableDate)
                                                        if (
                                                          (z.split(" ")[1] ===
                                                            moment().format(
                                                              "DD/MMM/YYYY"
                                                            ) &&
                                                            CuttentDateTime <
                                                              weektimeTablePeriod.filter(
                                                                (g) =>
                                                                  String(
                                                                    g.PeriodNumber
                                                                  ) ===
                                                                  String(
                                                                    x.period
                                                                  )
                                                              )[0]?.EndTime) ||
                                                          z.split(" ")[1] !==
                                                            moment().format(
                                                              "DD/MMM/YYYY"
                                                            )
                                                        ) {
                                                          VideoConfFunc(
                                                            z.split(" ")[1],
                                                            x?.subjectName,
                                                            weektimeTablePeriod.filter(
                                                              (g) =>
                                                                String(
                                                                  g.PeriodNumber
                                                                ) ===
                                                                String(x.period)
                                                            )[0]?.StartTime,
                                                            weektimeTablePeriod.filter(
                                                              (g) =>
                                                                String(
                                                                  g.PeriodNumber
                                                                ) ===
                                                                String(x.period)
                                                            )[0]?.EndTime,
                                                            x?.staffName,
                                                            x?.staffDesignation,
                                                            x?.katbookUnitID,
                                                            x?.katbookSessionID,
                                                            x?.refStaffID,
                                                            x?.id,
                                                            x?.refSubjectID,
                                                            x?.refSectionID
                                                          );
                                                        }
                                                        // document.querySelector("body").style.overflow = "hidden";
                                                      }}
                                                    >
                                                      {x.katbookUnitID !==
                                                        null && (
                                                        <IoVideocam
                                                          style={{
                                                            color:
                                                              z.split(
                                                                " "
                                                              )[1] ===
                                                              moment().format(
                                                                "DD/MMM/YYYY"
                                                              )
                                                                ? String(
                                                                    CuttentDateTime
                                                                  ) <
                                                                  String(
                                                                    weektimeTablePeriod.filter(
                                                                      (g) =>
                                                                        String(
                                                                          g.PeriodNumber
                                                                        ) ===
                                                                        String(
                                                                          x.period
                                                                        )
                                                                    )[0]
                                                                      ?.EndTime
                                                                  )
                                                                  ? "#1653cc"
                                                                  : "#ccc"
                                                                : "#1653cc",
                                                            cursor:
                                                              z.split(
                                                                " "
                                                              )[1] ===
                                                              moment().format(
                                                                "DD/MMM/YYYY"
                                                              )
                                                                ? CuttentDateTime <
                                                                  weektimeTablePeriod.filter(
                                                                    (g) =>
                                                                      String(
                                                                        g.PeriodNumber
                                                                      ) ===
                                                                      String(
                                                                        x.period
                                                                      )
                                                                  )[0]?.EndTime
                                                                  ? "pointer"
                                                                  : "default"
                                                                : "pointer",
                                                          }}
                                                          className="VideoSize-Color"
                                                        />
                                                      )}
                                                    </span>
                                                  )}
                                                <div
                                                  onClick={() => {
                                                    openBookview(
                                                      x.katbookUnitID,
                                                      x.katbookSessionID,
                                                      x.refSubjectID,
                                                      x.refSectionID
                                                    );
                                                  }}
                                                >
                                                  <ResultDataWeek
                                                    unitID={x?.katbookUnitID}
                                                    sessionID={
                                                      x?.katbookSessionID
                                                    }
                                                  />
                                                </div>
                                                {x?.katbookUnitID !== null &&
                                                  x?.katbookSessionID !==
                                                    null && (
                                                    <div className=" d-flex gap-1 mt-2">
                                                      <div>
                                                        <img
                                                          src={Sample}
                                                          alt=""
                                                          width={20}
                                                        />
                                                      </div>
                                                      <div>
                                                        <span
                                                          className={"boxsec"}
                                                        >
                                                          {/* Sheeba V */}

                                                          {localStorage.length >
                                                            0 &&
                                                          JSON.parse(
                                                            localStorage.userCreds
                                                          ).userType ===
                                                            "Teaching"
                                                            ? JSON.parse(
                                                                localStorage.userCreds
                                                              ).adminName
                                                            : x.staffName}
                                                          {/* {x.staffName} */}
                                                        </span>
                                                      </div>
                                                    </div>
                                                  )}
                                              </div>
                                            </td>
                                          </>
                                        ) : (
                                          <>
                                            <td> - </td>
                                          </>
                                        )}
                                      </>
                                    );
                                  })}
                                </tr>
                              </>
                            );
                          })}

                        <div
                          style={{
                            padding: "0px 20px",
                            marginTop: "-25px",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            onClick={() => {
                              fetchNextTimeTableList(
                                nextDateTimeTbale,
                                prevDateTimeTable
                              );
                            }}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              // borderRight: "1px solid #c1c1c1",
                              // borderTop: "1px solid #c1c1c1",
                              // borderLeft: "1px solid #c1c1c1",
                              borderTopLeftRadius: "10px",
                              borderTopRightRadius: "10px",
                              width: "50px",
                              cursor: "pointer",
                              backgroundColor: "#1653cc",
                            }}
                          >
                            <AiOutlineDown
                              size={20}
                              style={{ color: "#fff" }}
                            />
                          </div>
                        </div>
                      </table>
                    </div>
                  </div>
                </CContainer>
              )}

              {Object.keys(weektimeTableData).length === 0 && (
                <CContainer style={{ maxWidth: "1500px" }}>
                  <div className="px-3 py-4">
                    <div className="tableborderstyle">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "200px",
                          fontSize: "25px",
                          width: "100%",
                        }}
                      >
                        Timetable not available
                      </div>
                    </div>
                  </div>
                </CContainer>
              )}
            </>
          )}
        </div>
      )}

      {day && (
        <div>
          <CCol md="12">
            <div
              className={"timeTable_Home_scrn"}
              style={
                {
                  // display:"none"
                }
              }
            >
              <>
                <div className={"Padding_Time"}>
                  <div className={"Time_Apr"}>
                    <div>
                      <span className={"Time_Table tableText"}>Timetable</span>
                    </div>

                    <div className={"F_Gap"}>
                      {/* <div>
                          <span className={"Apr_22"}>{yearMonth}</span>
                        </div> */}

                      <div className="d-flex align-items-center gap-1 border_time">
                        <div
                          onClick={() => {
                            // alert(moment(selectedtimetableDate).format('dddd'))
                            if (
                              moment(selectedtimetableDate).format("dddd") ===
                              "Monday"
                            ) {
                              updateDates(
                                moment(
                                  selectedtimetableDate,
                                  "DD/MMM/YYYY"
                                ).add(-2, "days")
                              );
                            } else {
                              updateDates(
                                moment(
                                  selectedtimetableDate,
                                  "DD/MMM/YYYY"
                                ).add(-1, "days")
                              );
                            }
                          }}
                        >
                          <FaCaretLeft
                            size={25}
                            className="Icon_color_TT cursor-pointer"
                          />
                        </div>
                        <div className="DateDesign">
                          <div className="DateDesignFont">
                            {selectedtimetableDate}
                          </div>
                        </div>
                        <div
                          onClick={() => {
                            // alert(moment(selectedtimetableDate).format('dddd'))
                            if (
                              moment(selectedtimetableDate).format("dddd") ===
                              "Saturday"
                            ) {
                              updateDates(
                                moment(
                                  selectedtimetableDate,
                                  "DD/MMM/YYYY"
                                ).add(2, "days")
                              );
                            } else {
                              updateDates(
                                moment(
                                  selectedtimetableDate,
                                  "DD/MMM/YYYY"
                                ).add(1, "days")
                              );
                            }
                          }}
                        >
                          <FaCaretRight
                            size={25}
                            className="Icon_color_TT cursor-pointer"
                          />
                        </div>
                      </div>

                      <div className={"Switch_Day_Week"}>
                        <div className={"Switch_Day_Blue"}>
                          <span className={"Switch_Day_Font_Timetable"}>
                            {" "}
                            Day{" "}
                          </span>
                        </div>
                        <div className={"Switch_Week_White"} onClick={Week_Day}>
                          <span className={"Switch_Week_Font_Timetable"}>
                            {" "}
                            Week{" "}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {isLoading ? (
                  <>
                    <div
                      className={"mt-4 mb-4"}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        minHeight: "300px",
                      }}
                    >
                      <img src={loadingGif} width="40px" />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="OuterSemiDiv1">
                      {timeTableData.length > 0 &&
                        timeTableData.map((x, index) => {
                          let SUbjectSc = `${x.subjectName} ${
                            localStorage.length > 0 &&
                            JSON.parse(localStorage.userCreds).userType ===
                              "Teaching"
                              ? `${x.variableName} ${x.section}`
                              : ""
                          }`;

                          let PendingSessData, pendSubject;
                          if(x.pendingSession !== null && x.pendingSession.length > 0 && pendSession.filter((q) => String(q.timetableId) === String(x.id)).length > 0 && pendSession.filter((q) => String(q.timetableId) === String(x.id))[0]?.pendingsessioncount > 0){
                            PendingSessData = x.pendingSession[pendSession.filter((q) => String(q.timetableId) === String(x.id))[0]?.pendingsessioncount - 1];
                            pendSubject = `${PendingSessData.subjectName} ${
                            localStorage.length > 0 &&
                            JSON.parse(localStorage.userCreds).userType ===
                              "Teaching"
                              ? `${PendingSessData.variableName} 
                                    ${PendingSessData.section}`
                              : ""
                          }`;
                          } else {
                            PendingSessData = []
                          }

                          return (
                            <>
                            { pendSession.filter((q) => String(q.timetableId) === String(x.id)).length > 0 && pendSession.filter((q) => String(q.timetableId) === String(x.id))[0]?.pendingsessioncount > 0 ?
                            (
                            <>
                            <div className="col-sm-4 paddingTopBot 1">
                              <div className="OuterSemiDiv2 padding-left-0">
                                <div className="mob-display-none">
                                  <div className="d-flex flex-row-reverse align-items-center justify-content-between gap-5 pad_TC_Sub">
                                    <div className="NumberCountDay">
                                      <div className="Today-Font">
                                        0{PendingSessData.period}
                                      </div>
                                    </div>
                                    <div>
                                      <span
                                        style={{
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          openBookview(
                                            PendingSessData.katbookUnitID,
                                            PendingSessData.katbookSessionID,
                                            PendingSessData.refSubjectID,
                                            PendingSessData.refSectionID
                                          );
                                        }}
                                        className="SubjectFontSub"
                                      >
                                        {/* Chemistry */}
                                        {/* {SUbjectSc} */}

                                        {/* Subject Name */}

                                        {SUbjectSc.toString().length > 25
                                          ? SUbjectSc.toString().slice(0, 25) +
                                            "..."
                                          : SUbjectSc}

                                        {/* Subject Name */}

                                        {/* {x.subjectName}&nbsp;
                                        {localStorage.length > 0 && JSON.parse(localStorage.userCreds).userType === "Teaching" ?
                                        `${x.variableName} 
                                      ${x.section}` : ''} */}
                                      </span>
                                      {/* {localStorage.length > 0 && JSON.parse(localStorage.userCreds).userType === "Teaching" &&
                                      <span
                                      style={{
                                          cursor: "pointer",
                                        }}
                                        className="E-Font"
                                        >
                                      &nbsp;&nbsp;{x.variableName}&nbsp;
                                      {x.section}
                                      </span>
                                      } */}
                                    </div>

                                    <div></div>
                                  </div>
                                </div>

                                <div className="DisplayNoneWeb">
                                  <div className="d-flex justify-content-center pad_TC_Sub">
                                    <div className="NumberCountDay">
                                      <div className="Today-Font">
                                        0{PendingSessData.period}
                                      </div>
                                    </div>
                                    <div>
                                      <span
                                        style={{
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          openBookview(
                                            PendingSessData.katbookUnitID,
                                            PendingSessData.katbookSessionID,
                                            PendingSessData.refSubjectID,
                                            PendingSessData.refSectionID
                                          );
                                        }}
                                        className="SubjectFontSub"
                                      >
                                        {/* Chemistry */}
                                        {/* {SUbjectSc} */}

                                        {/* Subject Name */}

                                        {SUbjectSc.toString().length > 25
                                          ? SUbjectSc.toString().slice(0, 25) +
                                            "..."
                                          : SUbjectSc}

                                        {/* Subject Name */}

                                        {/* {x.subjectName}&nbsp;
                                        {localStorage.length > 0 && JSON.parse(localStorage.userCreds).userType === "Teaching" ?
                                        `${x.variableName} 
                                      ${x.section}` : ''} */}
                                      </span>
                                      {/* {localStorage.length > 0 && JSON.parse(localStorage.userCreds).userType === "Teaching" &&
                                      <span
                                      style={{
                                          cursor: "pointer",
                                        }}
                                        className="E-Font"
                                        >
                                      &nbsp;&nbsp;{x.variableName}&nbsp;
                                      {x.section}
                                      </span>
                                      } */}
                                    </div>

                                    <div></div>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center">
                                {x.pendingSession !== null && x.pendingSession.length > 0 &&
                                <>
                                { pendSession?.filter((q) => String(q.timetableId) === String(x.id)).length > 0 && x.pendingSession.length - pendSession.filter((q) => String(q.timetableId) === String(x.id))[0]?.pendingsessioncount === 0 ? 
                                (
                                  <></>
                                ) : (
                                  <div className="d-flex flex-column"
                                  
                                  >
                                    <div className="left_arrow_TT"
                                    onClick={() => {
                                    viewPendingSessions(x)
                                  }}
                                  >
                                      <MdChevronLeft
                                        size={20}
                                        className="left_arrow_font_TT cursor-pointer"
                                      />
                                    </div>
                                    <div className="left_position">{pendSession.length > 0 && pendSession.filter((q) => String(q.timetableId) === String(x.id)).length > 0 ? x.pendingSession.length - pendSession.filter((q) => String(q.timetableId) === String(x.id))[0]?.pendingsessioncount : x.pendingSession.length}</div>
                                  </div>
                                )
                                  }
                                  </>
                                  }

                                  <div className="PD_20_Sub_U_S">
                                    <div className="back_color_subject"
                                    style={{
                                      backgroundColor: PendingSessData.status === "Pending" ? "#fee0e0" : PendingSessData.status === "Completed" ? "" : ""
                                    }}
                                    >
                                      {/* Video and Time */}

                                      <div className="Video_Gap_Mob pb-1">
                                        {/* <div
                                          onClick={() => {
                                            // alert(selectedtimetableDate)
                                            if (
                                              (selectedtimetableDate ===
                                                moment().format(
                                                  "DD/MMM/YYYY"
                                                ) &&
                                                CuttentDateTime <
                                                  timeTablePeriod.filter(
                                                    (g) =>
                                                      String(g.PeriodNumber) ===
                                                      String(PendingSessData.period)
                                                  )[0]?.EndTime) ||
                                              selectedtimetableDate !==
                                                moment().format("DD/MMM/YYYY")
                                            ) {
                                              VideoConfFunc(
                                                selectedtimetableDate,
                                                PendingSessData?.subjectName,
                                                timeTablePeriod.filter(
                                                  (g) =>
                                                    String(g.PeriodNumber) ===
                                                    String(PendingSessData.period)
                                                )[0]?.StartTime,
                                                timeTablePeriod.filter(
                                                  (g) =>
                                                    String(g.PeriodNumber) ===
                                                    String(PendingSessData.period)
                                                )[0]?.EndTime,
                                                PendingSessData?.staffName,
                                                PendingSessData?.staffDesignation,
                                                PendingSessData?.katbookUnitID,
                                                PendingSessData?.katbookSessionID,
                                                PendingSessData?.refStaffID,
                                                PendingSessData?.id,
                                                PendingSessData?.refSubjectID,
                                                PendingSessData?.refSectionID
                                              );
                                            }
                                            // document.querySelector("body").style.overflow = "hidden";
                                          }}
                                        >
                                          <IoVideocam
                                            style={{
                                              color:
                                                selectedtimetableDate ===
                                                moment().format("DD/MMM/YYYY")
                                                  ? String(CuttentDateTime) <
                                                    String(
                                                      timeTablePeriod.filter(
                                                        (g) =>
                                                          String(
                                                            g.PeriodNumber
                                                          ) === String(PendingSessData.period)
                                                      )[0]?.EndTime
                                                    )
                                                    ? "#1653cc"
                                                    : "#ccc"
                                                  : "#1653cc",
                                              cursor:
                                                selectedtimetableDate ===
                                                moment().format("DD/MMM/YYYY")
                                                  ? CuttentDateTime <
                                                    timeTablePeriod.filter(
                                                      (g) =>
                                                        String(
                                                          g.PeriodNumber
                                                        ) === String(PendingSessData.period)
                                                    )[0]?.EndTime
                                                    ? "pointer"
                                                    : "default"
                                                  : "pointer",
                                            }}
                                            className="VideoSize-Color"
                                          />
                                        </div> */}
                                        {/* <div className="DisplayNone">
                                          <span className="Time-Font-am">
                                            {tConvert(
                                              timeTablePeriod.length > 0 &&
                                                timeTablePeriod.filter(
                                                  (g) =>
                                                    String(g.PeriodNumber) ===
                                                    String(PendingSessData.period)
                                                )[0]?.StartTime
                                            )}
                                            &nbsp; - &nbsp;
                                            {tConvert(
                                              timeTablePeriod.length > 0 &&
                                                timeTablePeriod.filter(
                                                  (g) =>
                                                    String(g.PeriodNumber) ===
                                                    String(PendingSessData.period)
                                                )[0]?.EndTime
                                            )}
                                          </span>
                                        </div>
                                        <div className="DisplayNoneWeb">
                                          <span className="Time-Font-am">
                                            {tConvert(
                                              timeTablePeriod.length > 0 &&
                                                timeTablePeriod.filter(
                                                  (g) =>
                                                    String(g.PeriodNumber) ===
                                                    String(PendingSessData.period)
                                                )[0]?.StartTime
                                            )}
                                            &nbsp; - &nbsp;
                                            {tConvert(
                                              timeTablePeriod.length > 0 &&
                                                timeTablePeriod.filter(
                                                  (g) =>
                                                    String(g.PeriodNumber) ===
                                                    String(PendingSessData.period)
                                                )[0]?.EndTime
                                            )}
                                          </span>
                                        </div> */}
                                        <div className={"Time-Font-am"}>
                                        {PendingSessData?.estStartDate && moment(new Date(PendingSessData?.estStartDate)).format("DD MMM YYYY")}
                                        </div>
                                      </div>

                                      {/* Video and Time */}

                                      <div
                                        style={{
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          openBookview(
                                            PendingSessData.katbookUnitID,
                                            PendingSessData.katbookSessionID,
                                            PendingSessData.refSubjectID,
                                            PendingSessData.refSectionID
                                          );
                                        }}
                                        className="d-flex flex-column align-items-start gap-1"
                                      >
                                        <ResultDataView
                                          unitID={PendingSessData?.katbookUnitID}
                                          sessionID={PendingSessData?.katbookSessionID}
                                        />
                                      </div>

                                      <div className="d-flex align-items-center justify-content-between mt-2">
                                        <div className="d-flex align-items-center gap-1">
                                          <div>
                                            <img
                                              src={Sample}
                                              alt=""
                                              width={25}
                                            />
                                          </div>
                                          <div>
                                            <span className="ImgNameFont">
                                              {/* Sheeba V */}

                                              {localStorage.length > 0 &&
                                              JSON.parse(localStorage.userCreds)
                                                .userType === "Teaching"
                                                ? JSON.parse(
                                                    localStorage.userCreds
                                                  ).adminName
                                                : x.staffName}
                                              {/* {x.staffName} */}
                                            </span>
                                          </div>
                                        </div>

                                        {/* Scheduled Task */}
                                        {PendingSessData.status === "Scheduled" &&
                                        <div className="d-flex align-items-center gap-1">
                                          <div>
                                            <AiOutlineSolution
                                              size={20}
                                              className="color_schedule"
                                            />
                                          </div>
                                          <div className="ImgNameFont color_schedule">
                                            {PendingSessData?.status}
                                          </div>
                                        </div>
                                        }
                                        {/* Scheduled Task */}

                                        {/* Completed Task */}
                                        {PendingSessData.status === "Completed" &&
                                        <div className="d-flex align-items-center gap-1">
                                            <div className="">
                                              <TiTickOutline
                                                size={25}
                                                className="color_complete_background"
                                              />
                                            </div>
                                            <div className="ImgNameFont color_complete">
                                              Completed
                                            </div>
                                          </div>
                                        }
                                        {/* Completed Task */}

                                        {/* Not complete Task */}
                                        {PendingSessData.status === "Pending" &&
                                        <div className="d-flex align-items-center gap-1">
                                            <div className="">
                                              <IoIosClose
                                                size={25}
                                                className="color_In_complete_background"
                                              />
                                            </div>
                                            <div className="ImgNameFont color_In_complete">
                                              Not Completed
                                            </div>
                                          </div>
                                          }
                                        {/* Not complete Task */}

                                      
                                      </div>
                                    </div>
                                  </div>
                                  { console.log(pendSession,"Pending session details") }
                                  {pendSession?.length > 0 && pendSession.filter((q) => String(q.timetableId) === String(x.id))[0]?.pendingsessioncount > 0 &&
                                    <div className="d-flex flex-column"
                                    
                                    >
                                    <div className="right_arrow_TT"
                                    onClick={() => {
                                      NextPendingSessions(x)
                                    }}
                                    >
                                      <MdChevronRight
                                        size={20}
                                        className="right_arrow_font_TT cursor-pointer"
                                      />
                                    </div>
                                    <div className="right_position">{pendSession.filter((q) => String(q.timetableId) === String(x.id))[0]?.pendingsessioncount}</div>
                                  </div>
                                  }
                                </div>
                              </div>
                            </div>
                            </>
                            ) : (
                              <>
                            <div className="col-sm-4 paddingTopBot 1">
                              <div className="OuterSemiDiv2 padding-left-0">
                                <div className="mob-display-none">
                                  <div className="d-flex flex-row-reverse align-items-center justify-content-between gap-5 pad_TC_Sub">
                                    <div className="NumberCountDay">
                                      <div className="Today-Font">
                                        0{x.period}
                                      </div>
                                    </div>
                                    <div>
                                      <span
                                        style={{
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          openBookview(
                                            x.katbookUnitID,
                                            x.katbookSessionID,
                                            x.refSubjectID,
                                            x.refSectionID
                                          );
                                        }}
                                        className="SubjectFontSub"
                                      >
                                        {/* Chemistry */}
                                        {/* {SUbjectSc} */}

                                        {/* Subject Name */}

                                        {SUbjectSc.toString().length > 25
                                          ? SUbjectSc.toString().slice(0, 25) +
                                            "..."
                                          : SUbjectSc}
                                          

                                        {/* Subject Name */}

                                        {/* {x.subjectName}&nbsp;
                                        {localStorage.length > 0 && JSON.parse(localStorage.userCreds).userType === "Teaching" ?
                                        `${x.variableName} 
                                      ${x.section}` : ''} */}
                                      </span>
                                      {/* {localStorage.length > 0 && JSON.parse(localStorage.userCreds).userType === "Teaching" &&
                                      <span
                                      style={{
                                          cursor: "pointer",
                                        }}
                                        className="E-Font"
                                        >
                                      &nbsp;&nbsp;{x.variableName}&nbsp;
                                      {x.section}
                                      </span>
                                      } */}
                                    </div>

                                    <div></div>
                                  </div>
                                </div>

                                <div className="DisplayNoneWeb">
                                  <div className="d-flex justify-content-center pad_TC_Sub">
                                    <div className="NumberCountDay">
                                      <div className="Today-Font">
                                        0{x.period}
                                      </div>
                                    </div>
                                    <div>
                                      <span
                                        style={{
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          openBookview(
                                            x.katbookUnitID,
                                            x.katbookSessionID,
                                            x.refSubjectID,
                                            x.refSectionID
                                          );
                                        }}
                                        className="SubjectFontSub"
                                      >
                                        {/* Chemistry */}
                                        {/* {SUbjectSc} */}

                                        {/* Subject Name */}

                                        {SUbjectSc.toString().length > 25
                                          ? SUbjectSc.toString().slice(0, 25) +
                                            "..."
                                          : SUbjectSc}

                                        {/* Subject Name */}

                                        {/* {x.subjectName}&nbsp;
                                        {localStorage.length > 0 && JSON.parse(localStorage.userCreds).userType === "Teaching" ?
                                        `${x.variableName} 
                                      ${x.section}` : ''} */}
                                      </span>
                                      {/* {localStorage.length > 0 && JSON.parse(localStorage.userCreds).userType === "Teaching" &&
                                      <span
                                      style={{
                                          cursor: "pointer",
                                        }}
                                        className="E-Font"
                                        >
                                      &nbsp;&nbsp;{x.variableName}&nbsp;
                                      {x.section}
                                      </span>
                                      } */}
                                    </div>

                                    <div></div>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center">
                                {x.pendingSession !== null && x.pendingSession.length > 0 &&
                                <>
                                { pendSession?.filter((q) => String(q.timetableId) === String(x.id)).length > 0 && x.pendingSession.length - pendSession.filter((q) => String(q.timetableId) === String(x.id))[0]?.pendingsessioncount === 0 ? 
                                (
                                  <></>
                                ) : (
                                  <div className="d-flex flex-column"
                                  
                                  >
                                    <div className="left_arrow_TT"
                                    onClick={() => {
                                    viewPendingSessions(x)
                                  }}
                                  >
                                      <MdChevronLeft
                                        size={20}
                                        className="left_arrow_font_TT cursor-pointer"
                                      />
                                    </div>
                                    <div className="left_position">{pendSession.length > 0 && pendSession.filter((q) => String(q.timetableId) === String(x.id)).length > 0 ? x.pendingSession.length - pendSession.filter((q) => String(q.timetableId) === String(x.id))[0]?.pendingsessioncount : x.pendingSession.length}</div>
                                  </div>
                                )
                                  }
                                  </>
                                  }

                                  <div className="PD_20_Sub_U_S">
                                    <div className="back_color_subject"
                                    style={{
                                      backgroundColor: x.status === "Pending" ? "#fee0e0" : x.status === "Completed" ? "" : ""
                                    }}
                                    >
                                      {/* Video and Time */}

                                      <div className="Video_Gap_Mob pb-1">
                                        <div
                                          onClick={() => {
                                            // alert(selectedtimetableDate)
                                            if (
                                              (selectedtimetableDate ===
                                                moment().format(
                                                  "DD/MMM/YYYY"
                                                ) &&
                                                CuttentDateTime <
                                                  timeTablePeriod.filter(
                                                    (g) =>
                                                      String(g.PeriodNumber) ===
                                                      String(x.period)
                                                  )[0]?.EndTime) ||
                                              selectedtimetableDate !==
                                                moment().format("DD/MMM/YYYY")
                                            ) {
                                              VideoConfFunc(
                                                selectedtimetableDate,
                                                x?.subjectName,
                                                timeTablePeriod.filter(
                                                  (g) =>
                                                    String(g.PeriodNumber) ===
                                                    String(x.period)
                                                )[0]?.StartTime,
                                                timeTablePeriod.filter(
                                                  (g) =>
                                                    String(g.PeriodNumber) ===
                                                    String(x.period)
                                                )[0]?.EndTime,
                                                x?.staffName,
                                                x?.staffDesignation,
                                                x?.katbookUnitID,
                                                x?.katbookSessionID,
                                                x?.refStaffID,
                                                x?.id,
                                                x?.refSubjectID,
                                                x?.refSectionID
                                              );
                                            }
                                            // document.querySelector("body").style.overflow = "hidden";
                                          }}
                                        >
                                          <IoVideocam
                                            style={{
                                              color:
                                                selectedtimetableDate ===
                                                moment().format("DD/MMM/YYYY")
                                                  ? String(CuttentDateTime) <
                                                    String(
                                                      timeTablePeriod.filter(
                                                        (g) =>
                                                          String(
                                                            g.PeriodNumber
                                                          ) === String(x.period)
                                                      )[0]?.EndTime
                                                    )
                                                    ? "#1653cc"
                                                    : "#ccc"
                                                  : "#1653cc",
                                              cursor:
                                                selectedtimetableDate ===
                                                moment().format("DD/MMM/YYYY")
                                                  ? CuttentDateTime <
                                                    timeTablePeriod.filter(
                                                      (g) =>
                                                        String(
                                                          g.PeriodNumber
                                                        ) === String(x.period)
                                                    )[0]?.EndTime
                                                    ? "pointer"
                                                    : "default"
                                                  : "pointer",
                                            }}
                                            className="VideoSize-Color"
                                          />
                                        </div>
                                        <div className="DisplayNone">
                                          <span className="Time-Font-am">
                                            {tConvert(
                                              timeTablePeriod.length > 0 &&
                                                timeTablePeriod.filter(
                                                  (g) =>
                                                    String(g.PeriodNumber) ===
                                                    String(x.period)
                                                )[0]?.StartTime
                                            )}
                                            &nbsp; - &nbsp;
                                            {tConvert(
                                              timeTablePeriod.length > 0 &&
                                                timeTablePeriod.filter(
                                                  (g) =>
                                                    String(g.PeriodNumber) ===
                                                    String(x.period)
                                                )[0]?.EndTime
                                            )}
                                          </span>
                                        </div>
                                        <div className="DisplayNoneWeb">
                                          <span className="Time-Font-am">
                                            {tConvert(
                                              timeTablePeriod.length > 0 &&
                                                timeTablePeriod.filter(
                                                  (g) =>
                                                    String(g.PeriodNumber) ===
                                                    String(x.period)
                                                )[0]?.StartTime
                                            )}
                                            &nbsp; - &nbsp;
                                            {tConvert(
                                              timeTablePeriod.length > 0 &&
                                                timeTablePeriod.filter(
                                                  (g) =>
                                                    String(g.PeriodNumber) ===
                                                    String(x.period)
                                                )[0]?.EndTime
                                            )}
                                          </span>
                                        </div>
                                      </div>

                                      {/* Video and Time */}

                                      <div
                                        style={{
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          openBookview(
                                            x.katbookUnitID,
                                            x.katbookSessionID,
                                            x.refSubjectID,
                                            x.refSectionID
                                          );
                                        }}
                                        className="d-flex flex-column align-items-start gap-1"
                                      >
                                        <ResultDataView
                                          unitID={x?.katbookUnitID}
                                          sessionID={x?.katbookSessionID}
                                        />
                                      </div>

                                      <div className="d-flex align-items-center justify-content-between mt-2">
                                        <div className="d-flex align-items-center gap-1">
                                          <div>
                                            <img
                                              src={Sample}
                                              alt=""
                                              width={25}
                                            />
                                          </div>
                                          <div>
                                            <span className="ImgNameFont">
                                              {/* Sheeba V */}

                                              {localStorage.length > 0 &&
                                              JSON.parse(localStorage.userCreds)
                                                .userType === "Teaching"
                                                ? JSON.parse(
                                                    localStorage.userCreds
                                                  ).adminName
                                                : x.staffName}
                                              {/* {x.staffName} */}
                                            </span>
                                          </div>
                                        </div>

                                        {/* Scheduled Task */}
                                        {x.status === "Scheduled" &&
                                        <div className="d-flex align-items-center gap-1">
                                          <div>
                                            <AiOutlineSolution
                                              size={20}
                                              className="color_schedule"
                                            />
                                          </div>
                                          <div className="ImgNameFont color_schedule">
                                            {x?.status}
                                          </div>
                                        </div>
                                        }
                                        {/* Scheduled Task */}

                                        {/* Completed Task */}
                                        {x.status === "Completed" &&
                                        <div className="d-flex align-items-center gap-1">
                                            <div className="">
                                              <TiTickOutline
                                                size={25}
                                                className="color_complete_background"
                                              />
                                            </div>
                                            <div className="ImgNameFont color_complete">
                                              Completed
                                            </div>
                                          </div>
                                        }
                                        {/* Completed Task */}

                                        {/* Not complete Task */}
                                        {x.status === "Pending" &&
                                        <div className="d-flex align-items-center gap-1">
                                            <div className="">
                                              <IoIosClose
                                                size={25}
                                                className="color_In_complete_background"
                                              />
                                            </div>
                                            <div className="ImgNameFont color_In_complete">
                                              Not Completed
                                            </div>
                                          </div>
                                          }
                                        {/* Not complete Task */}
                                      </div>
                                    </div>
                                  </div>
                                  { console.log(pendSession,"Pending session details") }
                                  {pendSession?.length > 0 && pendSession.filter((q) => String(q.timetableId) === String(x.id))[0]?.pendingsessioncount > 0 &&
                                  <div className="d-flex flex-column"
                                  
                                  >
                                    <div className="right_arrow_TT"
                                    onClick={() => {
                                    NextPendingSessions(x)
                                  }}
                                  >
                                      <MdChevronRight
                                        size={20}
                                        className="right_arrow_font_TT cursor-pointer"
                                      />
                                    </div>
                                    <div className="right_position">{pendSession.filter((q) => String(q.timetableId) === String(x.id))[0]?.pendingsessioncount}</div>
                                  </div>
                                  }
                                </div>
                              </div>
                            </div>
                            </>
                            )
                            }
                            
                            </>
                          );
                        })}
                      {timeTableData.length === 0 && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "200px",
                            fontSize: "25px",
                            width: "100%",
                          }}
                        >
                          Timetable not available
                        </div>
                      )}
                    </div>
                  </>
                )}
              </>
            </div>
          </CCol>
        </div>
      )}

      <Footer
        openForm={openForm}
        shoq={shoq}
        closeForm={closeForm}
        KpostIframe={KpostIframe}
      />
    </div>
  );
};

export default Timetable;
