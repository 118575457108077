import React, { useState, useRef } from "react";
import FormButton from './FormButton'
import FormInput from './FormInput'
import {KampusLogin} from "../../../services/Apiservices";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Warning from "../Toastify/Warning";
import Success from "../Toastify/Success";
import Danger from "../Toastify/Danger";
import { FiEyeOff,FiEye } from "react-icons/fi"
import { FaUser,FaLock } from "react-icons/fa"
import {Authcontext} from "../Context/Context"
import { CModal, } from "@coreui/react";
import { GrClose } from "react-icons/gr";

function Form() {

  
 const {state, dispatch} = React.useContext(Authcontext)
//  console.log(state,"CONTEXT STORAGE");
  const [passwordType, setPasswordType] = useState("password");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({ userName: "", password: "" });
  const passwordRef = useRef();
  const navigate = useNavigate();
  const [loginAlert, setloginAlert] = useState(false);
  const [isLogged, setisLogged] = useState(false);
  const alertRef = React.useRef(null);

  const togglePassword =(value)=>{
    if(passwordType==="password")
    {
     setPasswordType(value)
     return;
    }
    setPasswordType(value)
  }

  const submitkampusLogin = async(islogged) => {

    if (userName === "") {
      toast.warning(<Warning body={"Enter your user name"} />);
      setErrors({ ...errors, userName: "Enter your user name" });
    } else if (password === "") {
      toast.warning(<Warning body={"Enter your password"} />);
      setErrors({ ...errors, password: "Enter your Password" });
    } else if (userName && password) {
          let res;
          try {

            if(islogged){
              res = await KampusLogin({
                userLoginID: userName,
                password: password,
                isClearLogin: true
              });
            } else {
              res = await KampusLogin({
                userLoginID: userName,
                password: password,
                // isClearLogin: true
              });
            }
            

            if (res.status === "Login Success") {
              // toast.success(<Success body={res.status} />);
              localStorage.setItem("AccessToken", res.value.accessToken);
              localStorage.setItem("userCreds", JSON.stringify(res.value));
              setisLogged(false)
              dispatch({type:"LOGIN",payload :res.value})
              navigate("/dashboard");
              // refreshPage();
            } else  if (res.status === "failure") {
              // toast.error(<Danger body={res.message} />);
              setisLogged(false)
              setloginAlert(true)
            } else {
              toast.error(<Danger body={"Please check your username and password!."} />);
            }
          } catch (e) {
            toast.error(<Danger body={"Please check your username and password!."} />);
            console.log(e);
          }
    }

  };
  function refreshPage(){ 
    window.location.reload(); 
  }
  return (
    <>
    <form
      onSubmit={(e) => {
        e.preventDefault();
        submitkampusLogin(isLogged);
      }}
      >
    <div>
       <FormInput
       value={userName}
       func={setUserName}
       reference={passwordRef}
       textIcon={FaUser}
       description="Username" placeholder="Enter your username" 
       type="text" />
     <FormInput
     value={password}
     func={setPassword}
     reference={passwordRef}
     textIcon={FaLock}
     description="Password" placeholder="Enter your password" type={passwordType} />
   
      <span className="pswd_toggle" 
      onMouseEnter={()=>togglePassword("text")}
            onMouseLeave={()=>togglePassword("password")}
      // onClick={togglePassword}
      >
      { passwordType==="password"? <FiEyeOff /> :<FiEye /> }
      </span>
                    
     <FormButton
     title="Log in"/>
    </div>
    </form>
    { userName && password && 
    <CModal
          alignment="center"
          visible={loginAlert}
          onClose={() => {
            clearInterval(alertRef.current);
            setloginAlert(false);
            document.querySelector("body").style.overflow = "auto";
          }}
          backdrop="static"
        >
          <div className={"login_alert_modal"}>
                <div>
            <div>
              <div className={"D_Flex_Chapter"}>
                <div><span className={"h5_Eng"}>Alert!</span></div>
                <div>
                  <GrClose
                    fontWeight={500}
                    size="22"
                    style={{ cursor: "pointer", color: "red" }}
                    onClick={() => {
                      clearInterval(alertRef.current);
                      setloginAlert(false);
                      document.querySelector("body").style.overflow = "auto";
                    }}
                  />
                </div>
              </div>
              <div className={"D_Flex_Div"}>
                <div>
                  <span className={"login_alert_txt"}>User has already signed in to another system. Do you want to continue in this system ?</span>
                </div>
              </div>
            </div>

            <div className={"Button_End"}>
                  <button className={"Login_alert_btn Button meeting_disable"} 
                    onClick={() => {
                      clearInterval(alertRef.current);
                      setloginAlert(false);
                      document.querySelector("body").style.overflow = "auto";
                    }}
                    >
                    <span className={"Button_J_M"}>CANCEL</span>
                  </button>&nbsp;&nbsp;
                  <button className={"Login_alert_btn Button"}
                  onClick={() => {
                    setloginAlert(false)
                    setisLogged(true)
                    submitkampusLogin(true);
                  }}
                   >
                    <span className={"Button_J_M"}>OKAY</span>
                  </button>
            </div>

            </div>
          </div>
    </CModal>
    }
        </>
  )
}

export default Form