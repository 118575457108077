import { CCard } from "@coreui/react";
import React from "react";
import { FiBookOpen } from "react-icons/fi";
import CryptoJS from "crypto-js";
// const CryptoJS = require("crypto-js");

const KatbookBooks = (props) => {
  // const userCreds = state.creds;
  const { x } = props;
  // console.log(x,"KATBOOOKKKKS");
  let KatbookUrl = `https://test.katbook.com`;
  // let KatbookUrl = `http://localhost:8000`;
  const key = "KPOSTKatBooks#$*(@#)!!";
  const BooksText = `${x.Books.path.split('"parentVaribaleId"')[0]}"parentVaribaleId":{"username":"${props.usercreds.userName}","code":"${props.usercreds.accessToken}"}}/${props.usercreds.userType === "Teaching" ? x.refStandardID : props.usercreds.classID}/${x.subjectID}/${props.usercreds.refInstID}/${props.usercreds.userType === "Teaching" ? x.refMediumID : props.usercreds.mediumId}`;

  const Bookencrypted = CryptoJS.AES.encrypt(
    JSON.stringify(BooksText),
    key
  ).toString();
  // console.log(Bookencrypted,"encrypted dataaaaa");

  let BTSUbjectName = `${x.subjectName} ${props.usercreds.userType === "Teaching" ? `${x.className} ${x.sectionName}` : ''}`

  console.log(BTSUbjectName.toString().length,"Book name length");

  return (
    <>
      <div key={1} className={"Margin_Left_Card"}>
        <CCard className={"Card_Book_List"} style={{ marginTop: x.Books.coverImage !== undefined ? "" : "30px"}}>
        { x.Books.coverImage !== undefined &&
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div style={{ width: "45%" }}>
              <img
                onClick={() => {
                  var newOpen = window.open(
                    `${KatbookUrl}/#/kampus/BookView/${Bookencrypted}`
                  );
                  if (newOpen == null || typeof(newOpen)=='undefined') {
                    alert("Turn off your pop-up blocker!");
                  }
                //   window.close();
                }}
                src={
                  x.Books.coverImage
                    // .replace(/\//, "//")
                    .replaceAll("192.168.2.202:2504", "api1.katbook.com")
                    .replaceAll("192.168.2.207:2504", "api1.katbook.com")
                    .replaceAll(
                      "http://api1.katbook.com",
                      "https://api1.katbook.com"
                    )
                    .replaceAll(
                      "http:/api1.katbook.com",
                      "https://api1.katbook.com"
                    )
                  // .replaceAll('192.168.2.202:2504', '192.168.2.202:2504').replaceAll('192.168.2.207:2504', '192.168.2.202:2504').replaceAll("http://api1.katbook.com", "https://api1.katbook.com").replaceAll('http:/api1.katbook.com',"https://api1.katbook.com")
                }
                className={"Image_Size"}
                alt=""
              />
            </div>
          </div>
        }

          <div className="Pad_Mar">
            <div 
            style={{
              cursor: "pointer"
            }}
            onClick={() => {
                  var newOpen = window.open(
                    `${KatbookUrl}/#/kampus/BookView/${Bookencrypted}`
                  );
                  if (newOpen == null || typeof(newOpen)=='undefined') {
                    alert("Turn off your pop-up blocker!");
                  }
                //   window.close();
                }}
                >
              <h5 className={"Booklist_Subject"}>
              
              {BTSUbjectName.toString().length > 40 ? BTSUbjectName.toString().slice(0,40)+'...' : BTSUbjectName}
              {/* {x.subjectName.toString().length > 25 ? x.subjectName.toString().slice(0,30)+'...' : x.subjectName}
              {props.usercreds.userType === "Teaching" &&
              ` ${x?.className}  ${x?.sectionName}`
              } */}
              </h5>
              
            </div>
            {/* { props.usercreds.userType === 'Student' &&
            <div style={{ marginTop: "5px" }}>
              <img src={StLogo} width="22" height="22" alt="" /> &nbsp;
              <span className="Manoj_span">
                {x.teacherName}
              </span>
            </div>
            } */}
            <div className={"w-100"}>
            <div className={"myProgress marginTop-10"} >
            <div className={props.count === 0 ? "myBar" : props.count === 1 ? "grennmyBar" : props.count === 2 ? "orangemyBar" : props.count === 3 ? "purplemyBar" : props.count === 4 ? "pinkmyBar" : "myBar"} style={{width: props.count === 0 ? "50%" : props.count === 1 ? "90%" : props.count === 2 ? "65%" : props.count === 3 ? "30%" : props.count === 4 ? "88%" : "40%"}}></div>
            </div>
            <div className={"myprogress_pertg"}>
              <span className={"Percent_Font"}>
                {props.count === 0 ? "50%" : props.count === 1 ? "90%" : props.count === 2 ? "65%" : props.count === 3 ? "30%" : props.count === 4 ? "88%" : "40%"}
              </span>
            </div>
            </div>

            <div className="bookopenjai">

                <div className="bokopenjai">
                    <div style={{display:"flex",alignItems:"center",gap:"3px"}}>
                      <FiBookOpen className={"BookOpen_Icon"} />
                      <span className={"Units_Font"} >0/{x?.Books?.unitCount ? x?.Books?.unitCount : 0} Units</span>
                    </div>
                    <div className="bookcoverd"> 
                        <span className={"Portions_covered"} style={{width:"max-content"}}>
                          Portions covered</span>
                    </div>
                </div>


                <div className={"Display_Mob_Contents"}>
                    <span className={"Cla_Status"}>Clarification Status: </span>
                </div>

                <div className="bookclarify2jai">

                <div className="d-flex pl-1 align-items-center">
                    <span className="green-bullet-sm">&#8226;</span>
                    <span className="color-66747F Clarify_Font"
                      style={{
                        width:'fit-content',
                        height:'fit-content',
                        marginTop: "0px",
                      }}
                    > 
                    Clarified ({x?.Books?.clarifiedCount ? x?.Books?.clarifiedCount : 0})</span>
                  </div>
                  <div className="d-flex pl-1 align-items-center">
                    <span className="orange-bullet-sm">&#8226;</span>
                    <span className="color-66747F Y_T_Clarify"
                      style={{
                        width:'fit-content',
                        height:'fit-content',
                        marginTop: "0px",
                      }}
                    > 
                    Yet to Clarify ({x?.Books?.unclarifiedCount ? x?.Books?.unclarifiedCount : 0})</span>
                  </div>
                  
                  
                </div>
            </div>



          </div>
        </CCard>
      </div>
    </>
  );
};

export default KatbookBooks;
