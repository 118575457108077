import React, {useState,useEffect} from "react";
import Header from "./HeaderNavbar";
import Footer from "./Footer";
import parse from 'html-react-parser';
import { useMediaQuery } from "react-responsive";
import useResponsive from "../../hooks/useResponsive";
import KHeader from "./common/KHeader";
import KButton from "./common/KButton";
import KDropdown from "./common/KDropdown";
import KInput from "./common/KInput";
import data from "../../../src/data.json";
import { AiOutlineLeft } from "react-icons/ai";
import KEditor from "./common/KEditor";
import { CSmartTable } from "@coreui/react-pro";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CCol, CModal, CModalBody, CModalHeader, CModalTitle } from "@coreui/react";
import mert from "../../Assets/Img_Meet.png";

import { toast } from "react-toastify";
import moment from 'moment';
import Warning from "./Toastify/Warning";
import Success from "./Toastify/Success";
import Danger from "./Toastify/Danger";

import {
  Teacher_Review_verify_API,
  QuestionAndAnswer_Table_List,
  QuestionAndAnswerOnly_Table_List,
  Teacher_Review_StudentName_List_API,
  Teacher_Question_Answer_POSTE_Data,
  Teacher_Review_verify_Each_Question_API,
  GetStudentAssignmentDetails_API,
  Posted_Assignment_Data_API,
  assignmentStudentsReport_subjectlist,

} from "../../services/Apiservices"

import { useAsyncError } from "react-router-dom";
import KRequiredLabel from "./common/KRequiredLabel/KRequiredLabel";

const ViewAssignment = ({
  showProfile,
  falseShowProfile,
  notofShowProfile,
  ShowNotifications,
  showNotification,
  falseShowNotification,
  disableOnOuterTouch,
  shoq,
  KpostIframe,
  closeForm,
  openForm,
  goToMainRoutes,
  CheckTokenProfile
}) => {
  const { isBigScreen } = useResponsive();
  const [showButton, setShowButton] = React.useState(false);
  const [showSubject, setShowSubject] = React.useState(false);
  const [clickIsDisabled, setClickIsDisabled] = useState(false);

  const [showPosted, setShowPosted] = React.useState(false);
  const [showPostedQA, setShowPostedQA] = React.useState(false);
  const [showPostedW, setShowPostedW] = React.useState(false);
  const [showPostedMonth, setShowPostedMonth] = React.useState(false);
  
  const [showPostedQAW, setShowPostedQAW] = React.useState(false);
  const [showPostedQAM, setShowPostedQAM] = React.useState(false);
  
  const [asstDateW1, setAsstDateW1] = useState("");
  const [asstDateW2, setAsstDateW2] = useState("");
  const [asstDateM1, setAsstDateM1] = useState("");
  const [asstDateM2, setAsstDateM2] = useState("");

  const [totalmarksReport, setTotalmarksReport] = useState([]);
  
  const [subjectDetail, setSubjectDetail] = useState(false);
  const [desError, setDesError] = useState("");
  const [editorTask, seteditorTask] = useState("");
  const [visible, setVisible] = useState(false);
  const [dataVisible, setDataVisible] = useState(false);
  const[showMark,setShowMark] = useState(false) 
  const [text, setText] = useState('');
  const [mark, setMark] = useState('');
  const[titleView,setTitleView] = useState(true);
  const[titleReView,setTitleReView] = useState(false);
  const[viewList,setViewList]=useState([]);
  const[todayList,setTodayList]=useState([]);
  const[WeeklyList,setWeeklyList]=useState([]);
  const[monthlyList,setMonthlyList]=useState([]);
  const[checkPostBtn,setCheckPostBtn]=useState([]);
  const [asstDate, setAsstDate] = useState("");
  
  const[questionList,setQuestionList] = useState([]);
  const[answerList,setAnswerList]=useState([]);
  const [minDate, setMinDate] = useState(null);
  const [studentNameId,setStudentNameId] = useState("");
  const [studentName,setStudentName] = useState("");
  const [isError, setIsError] = useState(false);
  const[titleDraft,setTitleDraft] = useState(false);
  const[titlePost,setTitlePost] = useState(false);
  const[reviewedList,setReviewedList] = useState([]);
  const [studentArryID, setStudentArryID] = useState([]);
  const[assignmentSublist,setAssignmentSubList] = useState({

    katbookSubjectName:"",
    assignmentName:"",
    assignmentId:"",
    lsd:"",
    totalMarks:"",
    classId:"",
    sectionId:"",
  
  });
  const[assignmentSecondData,setAssignmentSecondData] = useState([{

    No:"",
    correctAnswer:"",
    katbookSubjectName: "",
    assignmentName:"",
    assignmentId: "",
    lsd: "",
    totalMarks: "",
    MarksObtained:"",
    classId:"",
    sectionId:"",
    Assignedby:"",
    teacherPostedDate:"",
  }])

  const[studentNameList,setStudentNameList] = useState([]);
  const [teacherReviewedRes,setTeacherReviewedRes] = useState([]);
  const[dataVis,setDataVis] = useState(true);
  const [student_ID,setStudent_ID] = useState([]);
  const [isClicked, setIsClicked] = useState(false);
  
  const [isPosted, setIsPosted] = useState(localStorage.getItem('isPosted') === 'true');
  const [isStudentNotAnswered, setIsStudentNotAnswered] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');
  
  const DateValidation = (e) => {
    // const TodayDate = moment().format("DD MMM YYYY")
    const EnterDate = moment(e).format('DD MMM YYYY')
     if (EnterDate) {
      setAsstDate(e)
      setErrorMessage('')
    } else {
      setAsstDate('')
     
    }
  }
  const handleKey = (e) => {
    e.preventDefault();
    setErrorMessage('Keyboard input is not allowed');
  };

  const handleClickBtn = () => {
    if (!isClicked) {
      // Perform the desired action here
      // console.log('Button clicked!');
      setIsClicked(true);
    }
  };
  const handleClick = () => {
    setIsPosted(true);
    setIsStudentNotAnswered(false);
    localStorage.setItem('isPosted', 'true');
    
  };

  useEffect(() => {
    if (isPosted) {
      localStorage.setItem('isPosted', 'true');
     
    }
  }, [isPosted]);

  useEffect(() => {
  CheckTokenProfile();
  }, []);

  useEffect(() => {
    if (answerList.filter((m) => m.studentAnswer === 'Student Not Answer')) {
      setIsStudentNotAnswered(true);
      
    }
  }, [answerList]);

  const HandleTeacherReviewedRes = (answerId,comment,studentMark) => {
    
    const dataToSave = {
    
      answerId,comment,studentMark
    
    }
   
  
//  Pod : 16433406021

    if(teacherReviewedRes.filter(x=>x.answerId === answerId ).length > 0){
      const RemovePreviousValue = teacherReviewedRes.filter(x=>x.answerId !== answerId )
      setTeacherReviewedRes([...RemovePreviousValue,dataToSave ])
    }else{
      setTeacherReviewedRes([...teacherReviewedRes,dataToSave])
    }

  }


  const handleTextReviewChange = (event) => {
      setText(event.target.value);
     
  };

  const handleTextMarkChange = (event) => {
    setMark(event.target.value);
  };

    // const handleTextChangeClick = () => {
    //   setcomment(text)
    // }

useEffect( () =>{
  const min_date = new Date(new Date());
    setMinDate(moment(min_date).format("YYYY-MM-DD"));
  // teacherViewList()
},[]);


const teacherViewList = async () => {
  let response;
  setViewList([]);  
  let body ={
    staffId:
    localStorage.length > 0 && JSON.parse(localStorage.userCreds).staffID,
    posted:true
  
  }  
  try {
    response = await Teacher_Question_Answer_POSTE_Data(body);
        
    // console.log(response, "view");
    if (response) {
      if (response.status === "success") {
        toast.success(response.message);
        if (response.data) {
         
          const data = response.data.map((x, index) => {
            return {

              No: index + 1,
              correctAnswer:x?.correctAnswer,
              katbookSubjectName:x.katbookSubjectName,
              assignmentName:x.assignmentName,
              assignmentId:x.assignmentId,
              lsd:`${moment(x.lsd).format("DD-MMM-YYYY")}`,
              totalMarks:x.totalMarks,
              classId:x?.classId,
              sectionId:x.sectionId,
              
            };
          });
          setViewList(data);
          // console.log("data1111",data);  
      }
      }
      else {
       toast.error(response.message);
      }
    }
  } catch (err) {
    console.log(err);
  }
};


    // const Report_studentId = localStorage.length > 0 && JSON.parse(localStorage.userCreds).userName
    
const Report_studentId = studentNameList.studentId

const Anwser_List = async (assignmentId,studentId) => {
      let response;
      let body;
      setAnswerList([]);  

      body={
        assignmentId:assignmentId,
        studentId:studentId
      } 
      
      try {
        response = await QuestionAndAnswerOnly_Table_List(body);
             
        if (response) {
          
            // toast.success(response.message);
           
            if (response.status === "success" ) {
              // console.log(response.data,"DSOAJDSOJDSOASDJ");
              const data = response.data.map((x, index) => {
                return {
                  S_no: index + 1,
                  questionId : x.questionId,
                  // Question:`
                  // Question :${x?.question }
                  // Correct Answer : ${x?.correctAnswer}
                  // Student Answer : ${x?.studentAnswer === null ? 'Student Not Answer' : x?.studentAnswer}`
                  // ,
                  Question:x.question,
                  correctAnswer:x?.correctAnswer,
                  
                  studentAnswer:`${x?.studentAnswer === null ? 'Student Not Answer' : x?.studentAnswer}`, 
                  answerType:x.answerType,
                  // obtainedMark:x?.studentMark,
                  QuestionMarks:x.marks,
                  marks:x.marks,
                  comment: x?.comment,
                  answerId:x.answerId,
                  option: x.options === "" || x.options === null ? "" : `${JSON.parse(x.options)?.map((x, i) => `${i + 1}.${x.options}`).join('\n')}`,
                  
                  obtainedMark: `${x.answerType === "RadioButton" ? 
                  (x.studentAnswer === x.correctAnswer ? x.marks : 0)
                   : (x.studentMark !== null ? x.studentMark : "-")}`,
               
                   studentMark: `${x.answerType === "RadioButton" ? (x.studentAnswer ? x.studentAnswer === x.correctAnswer ? x.marks : x.studentAnswer : "") : (x.studentMark !== null ? x.studentMark : "")}`,
                

                };
              });
              setAnswerList(data);
              // console.log("11111",data)
              let totalMarks = 0;
              for (let i = 0; i < data.length; i++) {
                const x = data[i];
                if (x.answerType === "RadioButton") {
                  
                  totalMarks = x.studentAnswer === x.correctAnswer ? Number(totalMarks) + Number(x?.marks ?? 0) : Number(totalMarks) +  0;
                } else{
                  totalMarks = x.studentMark !== null ? Number(totalMarks) + Number(x?.studentMark ?? 0) : Number(totalMarks) +  0;
                }
               
              }
              if (isNaN(totalMarks)) {
                totalMarks = "-";
              }
              setTotalmarksReport(totalMarks);

            }
         
          else {
            toast.error(response.message);
          }
        }
      } catch (err) {
        console.log(err);
      }
};
  
const Teacher_Review_verify_each_Question = async (body) => {


      try {
        const userCreds = JSON.parse(localStorage.getItem("userCreds"));
        if (!userCreds || !userCreds.userName) {
          throw new Error("User credentials are missing or invalid.");
        }
    
        // const body = {
        //   answerId:answerId,
        //   comment: "bad",
        //   studentMark: "10"
               
          
        // };
      //  console.log("jdkasasjfd",body)
        const response = await Teacher_Review_verify_Each_Question_API(body);
        if (response.status==="success") {
          toast.success(response.message);
          Anwser_List(assignmentSublist.assignmentId,student_ID)
          // Anwser_List()
          // setEachAns(response.data)
          // throw new Error("Failed to submit answer.");
        }else {
          toast.error(response.message);
        }
        
      } catch (err) {
        console.log(err);
        // toast.error("Answer was already posted to the question");
      }
    };

  

const Teacher_Review_verify = async () => {
     const today = new Date();
      const yyyy = today.getFullYear();
      let mm = today.getMonth() + 1; // Months start at 0!
      let dd = today.getDate();
      if (dd < 10) dd = '0' + dd;
      if (mm < 10) mm = '0' + mm;

      const ReviewDate = yyyy + '/' + mm + '/' + dd;

      let body = {
        
          studentId:student_ID,
          assignmentId: assignmentSublist.assignmentId,
          teacherReviewed: true,
          teacherReviewDate: ReviewDate,
                
      }
      // console.log("156rwer454",body)
    try {
      // var res = await Teacher_Review_verify_API({data:teacherReviewedRes});
      var res = await Teacher_Review_verify_API(body);
     
      if (res) {
        if(res.status==="success"){
          if (res.data) {
            toast.success(res.message);
            const data = `${res.success === true ? "Reviewed" : "Not Reviewed"}`;
  
                // Question :  ${x?.question}
                // Answer : ${x?.correctAnswer}`,
                // correctAnswer:x?.correctAnswer,
                // StudentAnswer:x.studentAnswer,
                // Question:x.question,
  
                setReviewedList(data)
            
            // QuestionandAnswer_Update_Function()
            
          } 
        }
        else(
          toast.error(res.message)
        )
               
      }
    } catch (error) {
      console.log(error);
    }
  };
 
  const StudentName_List = async (sectionId,assignmentId) => {    
    let response;
    const studentName = localStorage.length > 0 && JSON.parse(localStorage.userCreds).userName
    const assignmentIdData = assignmentId;
    setStudentNameList([])
    const body = {
              
              classId:JSON.stringify(sectionId),
              
            };
          
    try {

      response = await Teacher_Review_StudentName_List_API(body);
  
      if (response.status === "success") {
     
        if (response.value) {
    
            const stuarray = [];
            const data  = [];


          for (let index = 0; index < response.value.length; index++) {
            const x = response.value[index];
            const i = index
           

              const bodyStuAssign = {
                studentId:x.userid,
                assignmentId: assignmentIdData,
              };

              const getassignmentsData = await GetStudentAssignmentDetails_API(bodyStuAssign);
              
              const stuVals = {
                No: i + 1,
                Name: `${x?.firstname} ${x?.lastname}`,
                studentId:x.userid,
                assignmentId:getassignmentsData?.data?.assignmentId,
              
                status: `${getassignmentsData?.data?.studentPosted === true ? "Submitted" : "Not Submitted" } `,
                studentPostedDate: `${getassignmentsData?.data?.studentPostedDate === undefined ? '-' :moment(getassignmentsData?.data?.studentPostedDate).format("DD-MMM-YYYY") }`,
                isSubmitted : getassignmentsData?.data?.studentPosted,
                teacherReview: `${getassignmentsData?.data?.teacherReview === true ? "Reviewed" : "Not Reviewed" } `,
                isReviewed : getassignmentsData?.data?.teacherReview,
                teacherReviewDate: getassignmentsData?.data?.teacherReviewDate ? getassignmentsData?.data?.teacherReviewDate : "",
                studentTotalMarks: `${getassignmentsData?.data?.studentTotalMarks === 0  ? '-' : getassignmentsData?.data?.studentTotalMarks}`,
                
              };
              stuarray.push(stuVals);
             
            
            data.push({
              No: i + 1,
              Name: `${x?.firstname} ${x?.lastname}`,
              studentId:x.userid,
              status:x?.mobilenumber,
            });
          }

            setStudentNameList(stuarray);
            
            let studentarrId = []

            for(let z=0;z<data.length;z++){
              if(studentarrId.filter(y=>y.studentId === data[z].studentId).length === 0){
                studentarrId.push(data[z])
              }
            }
            
            setStudentArryID(studentarrId);
            // console.log("dsfjkjsd",studentarrId)
          // toast.success(response.status);

        } else {
          // toast.error(response.message);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  // const StudentName_List = async (sectionId,assignmentId) => {    
  //   let response;
  //   const studentName = localStorage.length > 0 && JSON.parse(localStorage.userCreds).userName
  //   const assignmentIdData = assignmentId;
  //   const body = {
              
  //             classId:JSON.stringify(sectionId),
              
  //           };
  //         // console.log("dsfsdf",body)
  //   try {
  //     response = await Teacher_Review_StudentName_List_API(body);
  
     
  //     if (response.status==="success") {
  //       if (response.value) {
         
  //         //toast.success(response.message);
          
  //           let stuarray = [];
  //           const data = response.value.map((x, i) => {

  //             const getassignmentdetails = async  (i) => {

  //               const bodyStuAssign = {
                   
  //                 studentId:x.userid,
  //                 assignmentId: assignmentIdData,
  //               };
  //               // console.log("dskflsdfk",body)
  //               //  let getassignmentsData = await Teacher_CreateAssignemt_Table(bodyStuAssign);
  //                let getassignmentsData = await GetStudentAssignmentDetails_API(bodyStuAssign);
                 
  //               //  console.log(getassignmentsData,"YYYYYYYYYYYYYYYYYYY");
  //               //  console.log(getassignmentsData.data.filter(item=> item.assignmentId === assignmentReportListdata1.assignmentId),"YYYYYYYYYYYYYYYYYYY");
  //               const stuVals = {
  //                 No: i + 1,
  //                 Name: `${x?.firstname} ${x?.lastname}`,
  //                 studentId:x.userid,
  //                 assignmentId:getassignmentsData?.data?.assignmentId,
  //                 status: `${getassignmentsData?.data?.studentPosted === true ? "Submitted" : "Not Submitted" } `,
  //                 studentPostedDate: `${getassignmentsData?.data?.studentPostedDate === undefined ? '-' :getassignmentsData?.data?.studentPostedDate }`,
  //                 isSubmitted : getassignmentsData?.data?.studentPosted,
  //                 teacherReview: `${getassignmentsData?.data?.teacherReview === true ? "Reviewed" : "Not Reviewed" } `,
  //                 isReviewed : getassignmentsData?.data?.teacherReview,
  //                 teacherReviewDate: getassignmentsData?.data?.teacherReviewDate ? getassignmentsData?.data?.teacherReviewDate : "",
  //                 studentTotalMarks: `${getassignmentsData?.data?.studentTotalMarks === 0  ? '-' : getassignmentsData?.data?.studentTotalMarks}`,
                  
  //               };
  //               // stuarray.push(stuVals);
  //               studentNameList.push(stuVals)
  //             };
             
  //              const returnAssgn = getassignmentdetails(i);
             
  //           });
  //           console.log(studentNameList,"StudentNameList");
  //           // setStudentNameList(stuarray);
            
  //           // const dataStudent = data.filter (x=>x.studentId !== studentName)
  //           // setStudentNameMatch(dataStudent)
           
  //         //   let studentarrId = []

  //         //   for(let z=0;z<data.length;z++){
  //         //     if(studentarrId.filter(y=>y.studentId === data[z].studentId).length === 0){
  //         //       studentarrId.push(data[z])
  //         //     }
  //         //   }
            
  //         //   setStudentArryID(studentarrId);
  //         //  console.log("dsklsd",studentarrId)
  //       } else {
  //         toast.error(response.error);
  //       }
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };



// Posted Assignment Menu Function 

  // Today Function
  const PostedAssignment_Todaydata = async (selectedDate) => {
    let response;
    setTodayList([]);
    let date = selectedDate;
 
    let body ={

      staffId:
      localStorage.length > 0 && JSON.parse(localStorage.userCreds).staffID,
      startDate:date,
      endDate:date,
    
    }  
    // console.log("dfjlksdjfklsd",body)
    try {
     
      response = await Posted_Assignment_Data_API(body);
       
      if (response) {
        if(response.status==="success"){
          toast.success(response.message);
          if (response.data) {
            // console.log("skdlfjskd",response)
            const data = response.data.map((x, index) => {
              return {
  
                No: index + 1,
                correctAnswer:x?.correctAnswer,
                katbookSubjectName:x.katbookSubjectName,
                assignmentName:x.assignmentName,
                assignmentId:x.assignmentId,
                lsd:`${moment(x.lsd).format("DD-MMM-YYYY")}`,
                totalMarks:x.totalMarks,
                classId:x?.classId,
                sectionId:x.sectionId,
                Assignedby:x.staffName,
                teacherPostedDate:`${moment(x.teacherPostedDate).format("DD-MMM-YYYY")}`,
              };
            });
            setTodayList(data);
            
        }
        }else{
          toast.error(response.message)
        }
          
        
        
      }
    } catch (err) {
      console.log(err);
    }
  };
  
  // Weekly function
  
  const PostedAssignment_Weeklydata = async (DateW1,DateW2) => {
    let response;
    setWeeklyList([]);
    
    let body ={

      staffId:
      localStorage.length > 0 && JSON.parse(localStorage.userCreds).staffID,
      startDate:DateW1,
      endDate:DateW2,
    
    }  
    
    try {
     
      response = await Posted_Assignment_Data_API(body);
       
      if (response.status==="success") {
       
          toast.success(response.message);
          if (response.data) {
            // console.log("skdlfjskd111",response)
            const data = response.data.map((x, index) => {
              return {
                
                No: index + 1,
                    
                correctAnswer:x?.correctAnswer,
                katbookSubjectName:x.katbookSubjectName,
                assignmentName:x.assignmentName,
                    
                assignmentId:x.assignmentId,
                lsd:`${moment(x.lsd).format("DD-MMM-YYYY")}`,
                totalMarks:x.totalMarks,
                    
                classId:x?.classId,
                sectionId:x.sectionId,
                Assignedby:x.staffName, 
                teacherPostedDate:`${moment(x.teacherPostedDate).format("DD-MMM-YYYY")}`,
              };
            });
            setWeeklyList(data);
            
        }
               
      }
      else {
        toast.error(response.message);
       }
    } catch (err) {
      console.log(err);
    }
  };


  const PostedAssignment_Monthlydata = async (DateM1,DateM2) => {
    let response;
    setMonthlyList([]);
    
    let body ={
      staffId:
      localStorage.length > 0 && JSON.parse(localStorage.userCreds).staffID,
      startDate:DateM1,
      endDate:DateM2,
     }  
    
    try {
     
      response = await Posted_Assignment_Data_API(body);
       
      if (response.status==="success") {
       
          toast.success(response.message);
          if (response.data) {
            // console.log("skdlfjskd111",response)
            const data = response.data.map((x, index) => {
              return {
  
                No: index + 1,
                correctAnswer:x?.correctAnswer,
                katbookSubjectName:x.katbookSubjectName,
                assignmentName:x.assignmentName,
                assignmentId:x.assignmentId,
                lsd:`${moment(x.lsd).format("DD-MMM-YYYY")}`,
                totalMarks:x.totalMarks,
                classId:x?.classId,
                sectionId:x.sectionId,
                Assignedby:x.staffName,
                teacherPostedDate:`${moment(x.teacherPostedDate).format("DD-MMM-YYYY")}`,
              };
            });
            setMonthlyList(data);
            
        }
        
      }
      else {
        toast.error(response.message);
       }
    } catch (err) {
      console.log(err);
    }
  };

  const Question_Answer_List = async (assignmentId) => {
    let response;
    setQuestionList([]);
    const body = {
      assignmentId: assignmentId,
      staffId:
      localStorage.length > 0 && JSON.parse(localStorage.userCreds).staffID,
     
    };
 
    try {
      response = await QuestionAndAnswer_Table_List(body);
      
      if (response.status==="success") {
        
          if (response.data) {
            // console.log(response.data, "wwssw");
            const data = response.data.map((x, index) => {
              return {
                S_no: index + 1,
                questionId:x._id,
                // Question:`
                // Question :  ${x?.question}
                // Answer : ${x?.correctAnswer}`,
                correctAnswer:x?.correctAnswer,
                studentAnswer:`${x?.studentAnswer === null ? 'Student Not Answer' : x?.studentAnswer}`, 
                marks:x.marks,
                comment: x?.comment,      
                Question:x.question,
                answerId:x.answerId,
                answerType:x.answerType,
                option: x.options === "" || x.options === null ? "" : `${JSON.parse(x.options)?.map((x, i) => `${i + 1}.${x.options}`).join('\n')}`,
                questionCreationId:x.questionCreationId,
                obtainedMark: `${x.answerType === "RadioButton" ? 
                (x.studentAnswer === x.correctAnswer ? x.marks : 0)
                 : (x.studentMark !== null ? x.studentMark : "-")}`,
             
              };  
            });
             setQuestionList(data);

          }
        // }
         else {
          toast.error(response.message);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

// const assignmentIdToCheck = assignmentSublist.assignmentId;
// const matchingAssignment = checkPostBtn.find(item => item.assignmentId === assignmentIdToCheck);
// const isDisable = matchingAssignment ? matchingAssignment.teacherReview : true;
// console.log(isDisable,matchingAssignment,assignmentIdToCheck,"sdkflsdjfkdsljfk");

const data = checkPostBtn.filter(x => x.assignmentId === assignmentSublist.assignmentId)
// console.log(data,"1213")
const matchingAssignment = data.find(m => m.assignmentId === assignmentSublist.assignmentId);
// console.log("dskfsdfk",data,matchingAssignment)
const isDisable = matchingAssignment ? matchingAssignment.teacherReview : true;
// console.log("data000002",isDisable)

const Check_teacher_reviewed_PostBtn = async (studentId) => {
    let response;
    setCheckPostBtn([]);
    const body = {
      studentId: studentId,
      classId:assignmentSublist.classId,
      // classId:localStorage.length > 0 && JSON.parse(localStorage.userCreds).staffID,
      sectionId:assignmentSublist.sectionId,
    };
  //  console.log("sdjksdfjksdfkjsdf",body)
    try {
      response = await assignmentStudentsReport_subjectlist(body);
      
      if (response.status==="success") {
        
          if (response.data) {
            // console.log(response.data, "wwsasasw1125");
            const data = response.data.map((x, index) => {
              return {
                S_no: index + 1,
                assignmentId:x.assignmentId,
                teacherReview:x.teacherReview,
                assignmentName:x.assignmentName,
              };  
            });
            setCheckPostBtn(data);
            // console.log("dkjdsjksd",data)

          }
        // }
         else {
          toast.error(response.message);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
 
 
  const validateDes = (e) => {
    // const Des = strip_tags(e)
    var d = document.createElement("div");
    d.innerHTML = e;
    // console.log(e, "TEXT DESCRIPTION");
    // console.log(d.innerText.length, "TEXT DESCRIPTION");
    if (d.innerText.length < 5) {
      setDesError("Description should be min 6 letters");
    } else {
      setDesError();
    }
  };

  const [textEditor, setTextEditor] = useState(false);
  const [categoryView,setCategoryView] = useState("");

  const categoryViewList = [
    "draft",
    "posted",
    "review"
  ]
  const Student_Name_List_Data = [
    {
      key: "No",
      label: "S.no",
      filter: true,
      sorter: true,
      _style: { width: "5%" },
    },
    {
      key: "Name",
      label: "Student Name List",
      filter: true,
      sorter: true,
      _style: { width: "25%" },
    },
    {
      key: "status",
      label: "Status",
     _style: { width: "15%" },
      filter: true,
      sorter: true,
    },
  ];

  
  const Assignment_AllDetails = [
    // {
    //   key: "No",
    //   label: "S no",
    //   filter: false,
    //   sorter: false,
    //   _style: { width: "8%" },
    // },
    {
      key: "katbookSubjectName",
      label: "Subject Name",
      filter: false,
      sorter: false,
      _style: { width: "20%" },
    },
    {
      key: "Assignedby",
      label: "Assigned by",
      filter: false,
      sorter: false,
      _style: { width: "20%" },
    },
    {
      key: "assignmentName",
      label: "Assignment Name",
      filter: false,
      sorter: false,
      _style: { width: "25%" },
    },
    {
      key: "lsd",
      label: "Last Submitted Date",
      filter: false,
      sorter: false,
      _style: { width: "20%" },
    },
    {
      key: "totalMarks",
      label: "Total Marks",
      _style: { width: "15%" },
      filter: false,
      sorter: false,
    },
  ];

  const QuestionanswerTable1 = [
    {
      key: "S_no",
      label: "S No",
      filter: true,
      sorter: true,
      _style: { width: "7%" },
    },
    {
      key: "Question",
      label: "List of Question and Answer ",
      filter: true,
      sorter: true,
      _style: { width: "80%" },
    },
    {
      key: "obtainedMark",
      label: "Marks ",
      _style: { width: "10%" },
      filter: true,
      sorter: true,
    },
    // {
    //   key: "comment",
    //   label: "comment",
    //  _style: { width: "10%" },
    //   filter: true,
    //   sorter: true,
    // },
    
  ];


  const QuestionanswerTable = [
    {
      key: "S_no",
      label: "S No",
      filter: true,
      sorter: true,
      _style: { width: "8%" },
    },
    {
      key: "Question",
      label: "List of Question and Answer",
      filter: true,
      sorter: true,
      _style: { width: "50%" },
    },
    {
      key: "QuestionMarks",
      label: "Q.Marks",
      _style: { width: "10%" },
      filter: true,
      sorter: false,
    },
    {
      key: "obtainedMark",
      label: "Marks ",
      _style: { width: "10%" },
      filter: true,
      sorter: false,
    },
    
    {
      key: "comment",
      label: "Comment",
     _style: { width: "10%" },
      filter: true,
      sorter: false,
    },
    { key: "Action",
    filter: false,
    sorter: false,
     _style: { width: "10%" } },
  ];



  return (
    <div onClick={() => disableOnOuterTouch()}>
      <div className="Navbar">
        <Header
          showProfile={showProfile}
          // profileIcon={KpIcon}
          // username={state.adminName ? state.adminName : state.user}
          falseShowProfile={falseShowProfile}
          notofShowProfile={notofShowProfile}
          // institutionName={state.institutionName && String(state.institutionName).toUpperCase()}
          ShowNotifications={ShowNotifications}
          showNotification={showNotification}
          falseShowNotification={falseShowNotification}
        />
      </div>
        
        <div className={"Dash_home_QWI "}>
        <div
          className="myattendcard2w p-3"
          style={{
            height: window.innerHeight - 117 + "px",
            overflowY: "auto",
          }}
        >
          <div>
              {titleView && (
                <KHeader
                title="View Assignment"
                containerClassName={`marginTop-20`}
              />
                
              )}
              {titleReView && (
                <KHeader 
                title={`Review / Evaluate Assignments - ${moment().format("DD MMM YYYY")}` }
                LastElement={<div></div>}
              />
              )}
               {titleDraft && (
                <KHeader 
                title={`Draft Assignments - ${moment().format("DD MMM YYYY")}` }
                LastElement={<div></div>}
              />
              )}
               {titlePost && (
                <KHeader 
                title={`Posted Assignments - ${moment().format("DD MMM YYYY")}` }
                LastElement={<div></div>}
              />
              )}

          </div>
         
          <div className="container-fluid px-5 py-3">
          
            {/* {showSubject && ( */}
              <div className="d-flex flex-column flex-md-row ">
                <KButton
                  value="Draft Assignments"
                  className={`px-6 py-3 mr-3  ${isBigScreen ? "w-25" : "w-100"}`}
                  onClick={()=>{
                    setTitleDraft(true);
                    setSubjectDetail(false);
                    setTitleView(false);
                    setTitleReView(false);
                    setTitlePost(false);
                    setShowSubject(false);
                    setShowPosted(false);
                    setTodayList([]);
                    setShowPosted(false);
                    setShowPostedQA(false);
                    setShowPostedW(false);
                    setCategoryView(categoryViewList[0])}}
                    isDisabled={true}
                />

              <KButton
                value="Posted Assignments"
                onClick={() => {
                  setCategoryView(categoryViewList[1]);
                  setShowButton(!showButton);
                  setTitleDraft(false);
                  setSubjectDetail(false);
                  setShowSubject(false);
                  setTitleView(false);
                  setTitleReView(false);
                  setTitlePost(true);
                  setTodayList([]);
                  setShowPosted(false);
                  setShowPostedQA(false);
                  setShowPostedW(false);
                  setShowPostedQAW(false);
                  setWeeklyList([]);
                  setAsstDateW1();
                  setAsstDateW2();
                  setShowPostedQAM(false);
                  setMonthlyList([]);
                  setAsstDateM1();
                  setAsstDateM2();
                  setShowPostedMonth(false);

                }}
                className={`px-6 py-3 mr-3 ${
                  isBigScreen ? "w-25" : "w-100 mt-2"
                }`}
                // onClick = {() =>{showNewQuestionBank()}}
              />

              <KButton
                value="Review / Evaluate Assignments"
                className={`px-6 py-3 ${isBigScreen ? "w-25" : "w-100 mt-2"}`}
                onClick={() => {
                  teacherViewList()
                  setTodayList([]);
                  setShowPosted(false);
                  setShowPostedQA(false);
                  setCategoryView(categoryViewList[2]);
                  setShowSubject(!showSubject);
                  setShowPosted(false);
                  setSubjectDetail(false);
                  setDataVisible(false);
                  setTitleDraft(false);
                  setTitleView(false);
                  setTitleReView(true);
                  setTitlePost(false);
                  setShowPostedW(false);
                  setShowPostedQAW(false);
                  setWeeklyList([]);
                  setAsstDateW1();
                  setAsstDateW2();
                  setShowPostedQAM(false);
                  setMonthlyList([]);
                  setAsstDateM1();
                  setAsstDateM2();
                  setShowPostedMonth(false);

                }}
              />
            </div>
          

            <div className="container-fluid px-0 py-3">
              {showButton && categoryView === categoryViewList[1] && (
                <div className="d-flex flex-column flex-md-row gap-2">
                  <KButton
                    value="Day"
                    onClick={() => {
                      setShowPosted(true);
                      setShowPostedW(false);
                      setAsstDate([]);
                      setAsstDateW1([]);
                      setAsstDateW2([]);
                      setWeeklyList([]);
                      setShowPostedMonth(false);
                      setAsstDateM1("");
                      setAsstDateM2("");
                      setTodayList([]);
                      setMonthlyList([])
                      setErrorMessage("");
                    }}
                    className={`px-3 py-2  W-15 ${
                      isBigScreen ? "W-15" : "w-50"
                    }`}
                  />

                  {/* ${isBigScreen ? "w-25" : "w-100"} */}
                  
                  <KButton
                    value="Week"
                    className={`px-3 py-2  W-15 ${
                      isBigScreen ? "W-15" : "w-50"
                    }`}
                    onClick={() => {
                      setShowPosted(false);
                      setShowPostedQA(false);
                      setShowPostedW(true);
                      setShowPosted(false);
                      setTodayList([]);
                      setMonthlyList([]);
                      setShowPostedMonth(false);
                      setShowPostedMonth(false);
                      setAsstDateM1("");
                      setAsstDateM2("");
                      setShowPostedQAM();
                      setWeeklyList([]);
                      setErrorMessage("");
                    }}
                  />
                  <KButton
                    value="Month"
                    className={`px-3 py-2  W-15 ${
                      isBigScreen ? "W-15" : "w-50"
                    }`}
                    onClick={() => {
                      setShowPosted(false);
                      setShowPostedQA(false);
                      setShowPostedW(false);
                      setShowPostedQAW(false);
                      setTodayList([]);
                      setAsstDateW1();
                      setAsstDateW2();
                      setShowPostedMonth(true);
                      setErrorMessage("");

                      
                    }}
                  />
                </div>
              )}
            </div>

            {showSubject && (
              <div className="container-fluid">              
                <div className="flex-wrap d-flex flex-column flex-md-row gap-3 pt-2 ">
                  {viewList.map((items) => (
                    <div
                      className="SubjectStyle1"
                      onClick={() => {
                        setDataVisible(false);
                        setAssignmentSubList(
                          { katbookSubjectName:items.katbookSubjectName,
                            assignmentName:items.assignmentName,
                            assignmentId:items.assignmentId,
                            lsd:items.lsd,
                            totalMarks:items.totalMarks,
                            classId:items.classId,
                            sectionId:items.sectionId,
                          }
                          
                        )
                        StudentName_List(items.sectionId,items.assignmentId)
                        // Question_List(items.assignmentId)
                        
                        // Anwser_List(items.assignmentId,studentNameList.studentId);
                        if (categoryView === categoryViewList[2]) {
                          setShowPosted(false);
                          setSubjectDetail(true);
                          setShowSubject(false);
                        } else {
                          setShowPosted(true);
                          setShowSubject(false);
                        }
                       
                      }
                    }

                    >
                      <div>
                        <h4>{items.Class} {items.katbookSubjectName}</h4>
                        <p style={{ fontWeight: "600", fontSize: "17px" }}>
                          {items.assignmentName}
                        </p>
                      </div>

                      <div>
                        {/* <span>Posted by : <b>{items.totalMarks}</b></span><br /> */}
                        <span>Total Marks : <b>{items.totalMarks}</b></span>
                        <p>Last Date Submission : <b>{items.lsd}</b></p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              )}           
        
            </div>
          
            {subjectDetail && (
              <div className="d-flex ">
                <div className="col-8">
                  <KHeader
                    containerClassName={
                      "align-items-center borderColor boxShowdow marginLeft-0 mr-3 p-3"
                    }
                    frontElement={
                      <div
                        className=""
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "35px",
                          alignItems:"center",
                        }}
                      >
                        <div>
                          <AiOutlineLeft
                            size="25"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setSubjectDetail(!subjectDetail);
                              setShowSubject(true);
                              setDataVisible(false);
                              setTextEditor(false);
                              setTotalmarksReport([]);
                              setStudentNameList([]);
                              setStudentName('');
                            }}
                          />
                        </div>
                            {/* <div >
                              <p>{assignmentSublist.assignmentName}</p>
                            </div> */}
                        <div>
                         
                          <h4>{assignmentSublist.katbookSubjectName} </h4>
                          <h4>{assignmentSublist.assignmentName}</h4>
                        </div>
                      </div>
                    }
                    LastElement={
                      <div
                        className=""
                        style={{
                          display: "flex",
                          gap: "20px",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "2px",
                          }}
                        >
                          <span>Posted by : <b>{studentName === '' ? "-": studentName }</b></span>
                          <span>Total Marks : <b>{totalmarksReport === undefined ? "-":"" && totalmarksReport === null ? "-":totalmarksReport }/{assignmentSublist.totalMarks} </b></span>
                          <span>Last Date of Submission : <b>{assignmentSublist.lsd} </b></span>
                        </div>
                        
                      </div>
                    }
                  />

                  

                  {textEditor && (
                    <>
                      <div className="mt-5">
                        <div className="d-flex flex-row justify-content-between align-items-center pb-3">
                          <div>
                            {/* <KHeader
                              title="Questions & Answers 01"                            
                            />     */}
                          </div>  
                          
                          <div>
                          {/* <KButton
                              className={
                                isPosted ? 'posted-button' : isStudentNotAnswered ? 'not-answered-button' : 'post-button'
                              }
                              btnColor={isPosted ? 'green' : isStudentNotAnswered ? 'red' : 'blue'}
                              value={isPosted ? 'Posted' : isStudentNotAnswered ? 'Student Not Answer' : 'Post'}
                              onClick={() => {
                                Teacher_Review_verify();
                                setTextEditor(false);
                                setTeacherReviewedRes([]);
                                handleClick();
                              }}
                              isDisabled={isError || isPosted || isStudentNotAnswered}
                            /> */}
                            {/* {JSON.stringify(assignmentSublist)}
                            <hr /> */}
                            {}
                            {/* { console.log(checkPostBtn,"TTTTTTTTTTT")} */}
                            
                            {/* { console.log(assignmentSublist.assignmentId,"TTTTTTTTTTT")} */}
                            {/* { console.log(checkPostBtn.filter(x => x.assignmentId === assignmentSublist.asssignmentId),"TTTTTTTTTTT")} */}
                            {/* {JSON.stringify(checkPostBtn.filter(x => x.assignmentId === assignmentSublist.assignmentId))} */}
                           {/* {JSON.stringify(checkPostBtn)} */}

                           {/* { console.log(answerList,"777777777777") } */}
                           {/* { console.log(checkPostBtn.filter(x => x.assignmentId === assignmentSublist.assignmentId).filter(x => x.teacherReview === true).length,"777777777777") } */}
                            
                          <KButton
                           
                            btnColor="green"
                            className={`px-3 py-2`}
                            value={'Submit All'}
                            // value={isPosted ? 'Post' : 'Post'}
                            // className={isPosted ? 'posted-button' : 'post-button'}
                            
                            // value={isPosted ? 'Posted' : isStudentNotAnswered ? 'Student Not Answer' : 'Post'}
                            // className={
                            //   isPosted ? 'posted-button' : isStudentNotAnswered ? 'not-answered-button' : 'post-button'
                            // }
                            onClick={() =>{
                              Teacher_Review_verify();
                              setTextEditor(false)
                              setTeacherReviewedRes([])
                              handleClick();
                              
                              }}
                            //  Ramesh Condition post btn disable
                              isDisabled={ 
                                checkPostBtn.filter(x => x.assignmentId === assignmentSublist.assignmentId).filter(x => x.teacherReview === true).length > 0 ? true : answerList.filter(c => c.answerType === "RadioButton").length > 0 ? (answerList.length === answerList.filter(m => m.studentMark !== '').length ? false : true) :
                                (answerList.length === answerList.filter(m => m.studentMark !== '').length ? false : true)
                              
                              }

                               // isDisabled={isError || isPosted || isStudentNotAnswered}
                              //  isDisabled={ isDisable === true ? true :false ||  
                              //   answerList.length === answerList.filter(m => m.studentMark !== '' && m.studentAnswer !== "Student Not Answer").length ? false : true 
                              //   }
                                //  || data.find(x => x.assignmentId === assignmentSublist.assignmentId && x.teacherReview === true ? false : true)
                                 
                                 //  ||
                                  // assignmentSublist.filter(x=>x.assignmentId === checkPostBtn.assignmentId).length > 0 ?.teacherReview === true ? false : true

                                // }
                                //  || isPosted 
                            //   isDisabled={!answerList.filter(m => m.answerType === "RadioButton" && m.comment === "")

                            //   || answerList.filter(m => m.answerType === "TextBox" || m.answerType === "TextArea" || m.answerType === "RadioButton"
                            //       && m.studentMark === "" || m.studentMark === "0").length > 0 ? true : false

                            //   || clickIsDisabled || answerList.filter(m => m.studentAnswer === "Student Not Answer").length > 0 ? true: false 
                             
                            // }

                              // isDisabled={!answerList.some(m => m.answerType === "RadioButton" && m.studentMark === "5")}
                            //   isDisabled={!answerList.some(m => {
                            //     if (m.answerType === "RadioButton") {
                            //       return m.studentMark === "5";
                            //     } else {
                            //       return m.studentMark === "";
                            //     }
                            //   }) || clickIsDisabled
                            // }
                            
                            // isDisabled={answerList.filter(m => m.studentMark === '' && m.answerType === "RadioButton").length > 0 ? true: false
                                     
                            //   || clickIsDisabled || isError ||                        
                            //   answerList.filter(m => m.studentAnswer === "Student Not Answer").length > 0 ? true: false 
                              // || reviewedList === "Reviewed" || clickIsDisabled 
                              
                              // || 
                              // reviewedList.filter(m => m.data === "Reviewed")
                              // || 
                              // answerList.filter(m => m.studentMark !== "").length > 0 ? true: false
                              // ||
                             
                              // || studentNameList.filter(z=>z.isReviewed === true)
                             //  || reviewedList.filter(m => m.data === "Reviewed").length > 0
                                 
                                 // ||
                              // answerList.filter(m => m.studentAnswer === "").length > 0 ? true: false
                            // }

                          /> 
                          {/* {JSON.stringify(answerList)} */}
                        </div>
                          {/* <div>
                          <KButton
                            onClick={() => {
                                setTextEditor(false);
                                setDataVisible(false);
                                setDataVis(false);
                              }}
                            value="Back"
                            className={`px-3 py-2`}
                            btnColor="red"
                          />
                          </div>                     */}
                        </div>                    
                          
                        <div>
                          <div
                            style={{
                              border: "1px solid #dadbdd",
                              textAlign: "center",
                            }}
                          >                   
                            <CSmartTable
                              columns = {QuestionanswerTable}
                              columnFilter
                              columnSorter
                              items = {answerList}
                              scopedColumns={{
                                // Question : (item) =>(<td className="textalign">{parse(item.Question)}</td>),                 
                               
                                Question : (item) =>(<td className="textalign">
                        
                                <span><b>Question :</b>{parse(item.Question)}</span>
                                        <div>
                                
                                      {(
                                        item.answerType === "RadioButton" && 
                                        <span className="optionName"> 

                                        {parse(item.option)}
                                      </span>

                                      )}
                                  </div>
                                
                                        
                                <span><b>Correct Answer:</b> {parse(item.correctAnswer)}</span>
                                <span >
                                <b>Student Answer : </b> {parse(item.studentAnswer)}</span>
                               
                              </td>),
                               
                               obtainedMark : (item) =>(<td >
                                {/* {JSON.stringify(item.QuestionMarks)} <hr /> */}
                               
                               {item.answerType == "RadioButton" ? item.studentAnswer == item.correctAnswer  ? item.marks : 0 : !item?.comment && !item?.obtainedMark ?
                               
                                (<><input type="number" 
                                className="markstext" 
                                placeholder="Type..." 
                                onChange={(event)=>{
                                  // console.log(item,answerList,"SDADSADASDSAADS");
                                  const value = event.target.value;
                                  if(value < 0){
                                    value=0;
                                  }
                                  if (Number(value) > Number(item.QuestionMarks)) {
                                    setIsError(true);
                                  } else {
                                    setIsError(false); 
                                    HandleTeacherReviewedRes(
                                      item.answerId,
                                      teacherReviewedRes.filter(x=> x.answerId === item.answerId).length >0 ?
                                      teacherReviewedRes.find(x=> x.answerId === item.answerId)?.comment ?? "": "",
                                      value
                                    )
    
                                  }
                                
                                }}
                                value={
                                  teacherReviewedRes.filter(x=> x.answerId === item.answerId).length >0 ?
                                  teacherReviewedRes.find(x=> x.answerId === item.answerId)?.studentMark ?? "": ""
                                }
                                /> {isError ? (
                                  <span className="error-message">
                                    Enter marks less than or equal to Q.Marks
                                  </span>
                                ) : (
                                  ""
                                )}
                                </>
                                ):(<p>
                                  
                                  { `${item?.obtainedMark === null ? 'Student Not Answer' : item.obtainedMark}` }
                                
                                 </p>)}</td>),
                                comment: (item) => {
                                  return (
                                    <td className="gapflex">
                                     
                                    {/* {item.answerType == "RadioButton" ? item.studentAnswer == item.correctAnswer  ? item.marks : 0 : !item?.comment && !item?.obtainedMark ? */}
                                   
                                    {/* {!item?.comment && !item?.obtainedMark  ?(<> <textarea id="text-area" value={ */}
                                   
                                   {(item.answerType === "RadioButton" ? item.comment ? 
                                     false : false : !item?.comment && !item?.obtainedMark )  ? (<> <textarea id="text-area" value={
                                                
                                                 teacherReviewedRes.filter(x=> x.answerId === item.answerId).length >0 ? 
                                                 teacherReviewedRes.find(x=> x.answerId === item.answerId)?.comment ?? "": ""
                                      } 
                                      
                                      onChange={(event)=>{
                                        const value = event.target.value;
                                        HandleTeacherReviewedRes(
                                          item.answerId,
                                          value,
                                          teacherReviewedRes.filter(x=> x.answerId === item.answerId).length >0 ?
                                          teacherReviewedRes.find(x=> x.answerId === item.answerId)?.studentMark ?? "": "",
                                        )
                                      }} placeholder="Review...."></textarea>
                                      </>):(<p>
                                        { `${item?.comment === null ? 'Student Not Answer' : item?.comment}` }
                                      </p>)
                                      }
                                    </td>
                                  );
                                },
                                Action: (item) => (
                                  <td>
                                  <KButton
                                      type="button"
                                      value="Submit"
                                      btnColor={"green"}
                                      className={`px-4 py-2 `}
                                      // Button disabled 
                                     isDisabled={isError || !(!item?.comment && !item?.obtainedMark || 
                                        !item.answerType === "RadioButton")
                                         ||
                                        answerList.filter(m => m.studentAnswer === "Student Not Answer").length > 0 ? true: false
                                        // || item?.comment !== "" ? true : false
                                      }
                                      
                                      onClick={() => {
                                        if(!item?.comment && !item?.obtainedMark){
                                          Teacher_Review_verify_each_Question(teacherReviewedRes.find(x=> x.answerId === item.answerId))
                                          setTeacherReviewedRes([])
                                          setDataVis(false)
                                        }
                                        }}
                                    />
                                     
                                  </td>
                                ),
                              }}
                              tableProps={{
                                striped: true,
                                hover: true,
                              }}
                            />
                          </div>
                        </div>

                        <CModal
                          alignment="center"
                          visible={visible}
                          onClose={() => setVisible(false)}
                          className="ModalHeight"
                        >
                          <div
                            style={{
                              backgroundColor: "white",
                              padding: "10px 30px 30px 30px",
                              borderRadius:"5px",
                            }}
                          >
                            <CModalHeader>
                              {/* <h5>
                      Date: {date()}
                    </h5> */}
                            </CModalHeader>

                            <div
                              style={{
                                padding: "25px",
                                border: "1px solid #c6c8cb",
                              }}
                            >
                              <div
                                className="d-flex flex-row gap-3 align-items-center pb-3"
                                style={{ borderBottom: "1px solid #efefef" }}
                              >
                                <div>
                                  <img
                                    src={mert}
                                    alt=""
                                    width={30}
                                    height={30}
                                  />
                                </div>
                                <div>
                                  <span style={{ fontWeight: "600" }}>
                                    Balasubramani Physics
                                  </span>
                                </div>
                              </div>

                              <div className="mt-3">
                                {/* <h6>Well tried. Answer can be more focused.</h6> */}
                                <div className="modelText">
                                    <label htmlFor="text-area">Enter the student Review:</label><br />
                                    <textarea id="text-area" value={text} onChange={handleTextReviewChange} placeholder="Review...."></textarea>
                                    <KButton
                                      value="Post"
                                      btnColor="green"
                                      className={`px-3 py-1 ButtonSize ${isBigScreen ? "ml-0" : "mt-2 ml-4"}`}
                                      onClick={() => {
                                        setVisible(false);
                                        handleTextReviewChange();
                                        setDataVisible(false)
                                      }}
                                    />
                                    {/* <p>{text}</p> */}
                                  </div>
                                </div>
                            </div>
                          </div>
                        </CModal>



                        

                      </div>
                    </>
                  )}

  {/* After Submit all data is showing */}
                 
                 {/* {dataVisible && (
                    <>
                      <div className="mt-5">
                        <div className="d-flex flex-row justify-content-between align-items-center pb-3">
                          <div>
                            <KHeader
                              title="Questions & Answers - Review"                            
                            />    
                          </div>  
                                           
                        </div>                    
                          
                        <div>
                          <div
                            style={{
                              border: "1px solid #dadbdd",
                              textAlign: "center",
                            }}
                          >
                            <CSmartTable
                              columns={QuestionanswerTable}
                              columnFilter
                              columnSorter
                              items={answerList}
                              scopedColumns={{
                                Question : (item) =>(<td className="textalign">{parse(item.Question)}</td>),
                                correctAnswer : (item) =>(<td className="textalign">{parse(item.correctAnswer)}</td>),
                                studentAnswer: (item) => (
                                  <td>
                                   
                                    {answerList.find(data => data.QuestionId === item.questionId)?.studentAnswer
                                      ?
                                      parse(answerList.find(data => data.QuestionId === item.questionId)?.studentAnswer)
                                      : "-"
                                    
                                    }
                                  </td>
                               ),
                               comment: (item) => (
                                <td>
                                  
                                  {answerList.find(data => data.QuestionId === item.questionId)?.comment
                                    ?
                                    parse(answerList.find(data => data.QuestionId === item.questionId)?.comment)
                                    : "-"
                                  
                                  }
                                </td>
                                
                             ),
                             obtainedMark: (item) => (
                              <td>
                                
                                {answerList.find(data => data.QuestionId === item.questionId)?.obtainedMark
                                  ?
                                  parse(answerList.find(data => data.QuestionId === item.questionId)?.obtainedMark)
                                  : "-"
                                
                                }
                              </td>
                              
                           ),                                                           
                              }}
                              tableProps={{
                                striped: true,
                                hover: true,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )} */}

                </div>

                <div className="col-4">
                 <KHeader
                    containerClassName={
                      "align-items-center borderColor marginLeft-0 boxShowdow"
                    }
                    frontElement={
                      <div className="d-flex gap-4 title_center " >
                        <div> Total : <b>{studentNameList.length} </b></div>
                        <div> UnSubmit : <b> {studentNameList.filter(z=>z.isSubmitted !== true).length} </b></div>                           
                        <div> Submit : <b> {studentNameList.filter(z=>z.isSubmitted === true).length} </b></div>
                        <div> Reviewed : <b>{studentNameList.filter(z=>z.isReviewed === true).length} </b></div>
                      </div>
                    }
                  />
                <div>
                          <div
                            style={{
                              border: "1px solid #dadbdd",
                              textAlign: "center",marginTop:"10px",
                            }}
                          >
                            <CSmartTable
                              columns={Student_Name_List_Data}
                              // items={studentNameList}
                              items={studentNameList.map((x,i)=>({
                                ...x,
                                No : i + 1,
                              }))}
                              itemsPerPage={100}
                              
                              columnFilter
                              columnSorter
                              
                              scopedColumns={{ 
                                
                                Name : (items) =>(<td className="textalign point"
                                onClick={() =>{
                                //  alert(JSON.stringify(items))
                                  // if(String(items.status) === String("Submitted"){
                                  Anwser_List(assignmentSublist.assignmentId,items.studentId);
                                  setStudent_ID(items.studentId);
                                  Check_teacher_reviewed_PostBtn(items.studentId);
                                  setTextEditor(true);
                                  setDataVisible(false);
                                  setStudentNameId(items.studentId);
                                  setStudentName(items.Name);
                                  setDataVis(true);
                                  // }
                                }}
                                // disabled={studentNameList.filter((m) => m.status !== 'Submitted').length > 0}
                                // isDisabled={
                                //   studentNameList.filter(m => m.status !== "Submitted").length > 0
                                //     ? true
                                //     : false
                                // }
                                >{parse(items.Name)}
                                
                                </td>),


                                }}
                              tableProps={{
                                striped: true,
                                hover: true,
                              }}
                            />
                          </div>
                        </div>
                 
                </div>
                
              </div>
            )}


{/* Posted Assignment button Today */}

            {showPosted && (
              <div>
                

                <div className="container-fluid px-5 py-3">
                  <div className="d-flex flex-column flex-md-row">
                  <KInput
                            placeholder="dd--mm--yyyy"
                            label="Select Date"
                            containerClassName="pl-md-4 pl-0 pt-2 pt-md-0 w-25"
                            labelClassName="width-maxContent"
                            inputClassName="w-100"
                            isRequired={true}
                            value={asstDate}
                           
                            // min={minDate}
                            onKeyDown = {handleKey}
                            // onMouseDown={(e) =>e.preventDefalt()}
                            onChange={(e) => {
                              const selectedDate = e; 
                              // setAsstDate(selectedDate); 
                              DateValidation(e)
                              PostedAssignment_Todaydata(selectedDate); 
                              setAsstDateM1('');
                              
                            }}
                            type="date"
                            error={errorMessage}
                          />

                  </div>

                  <div className="flex-wrap mt-3 pl-3 d-flex flex-column flex-md-row gap-3 pt-2 ">
                        {todayList.map((items) => (
                          <div
                            className="SubjectStyle1"
                            onClick={() => {
                              setShowPostedQA(true);
                              setShowPosted(false);
                              Question_Answer_List(items.assignmentId)
                              setShowButton(false)
                              setAssignmentSecondData([{
                                No:items.No,
                                correctAnswer:items.correctAnswer,
                                katbookSubjectName:items.katbookSubjectName,
                                assignmentName:items.assignmentName,
                                assignmentId:items.assignmentId,
                                lsd: items.lsd,
                                totalMarks: items.totalMarks,
                                MarksObtained:items.MarksObtained,
                                classId:items.classId,
                                sectionId:items.sectionId,
                                Assignedby:items.Assignedby,
                                
                              }])
                            }
                          }
                         >

                      <div>
                        <h4>{items.Class} {items.katbookSubjectName}</h4>
                        <p style={{ fontWeight: "600", fontSize: "17px" }}>
                          {items.assignmentName}
                        </p>
                      </div>
                      <div>
                        <span>Total Marks : <b>{items.totalMarks}</b></span><br />
                        <span>Teacher Posted Date: <b><br />{items.teacherPostedDate}</b></span> <br />
                        <span>Last Date Submission : <b>{items.lsd}</b></span>
                      </div>
                    </div>
                  ))}
                </div>

                </div>
        
              </div>
            )} 


            {showPostedQA && (
                <div className="container-fluid px-5 py-3">
                    <div className="mt-1 d-flex justify-content-between">
                      <h4>Assignment Details - Today</h4>
                      <button
                        className="backBtn mb-2"
                        onClick={() => {
                          setShowPosted(true);
                          setShowPostedQA(false);
                          
                          // setQuestionList([]);
                          setShowButton(true)
                          setErrorMessage('')

                          
                        }}
                      > 
                        Back 
                      </button>
                    </div>
                    <div className=" d-flex flex-column mt-2 pl-3 pr-3 tabelstyle">
                      {/* Assignment details Table */}
                      <CSmartTable
                        columns={Assignment_AllDetails}
                        // columnFilter
                        // columnSorter
                        // itemsPerPage={5}
                        // itemsPerPageSelect={todayList.length > 5}
                        // pagination
                        items={assignmentSecondData}
                        scopedColumns={{
                          
                          
                        }}
                        tableProps={{
                          striped: true,
                          hover: true,
                          responsive: true,
                        }}
                      />
                    </div>
                    <div className="tabelstyle">
                      {/* Question and Answer Details */}
                      <h4 className="py-2">Question and Answer Details</h4>
                      <CSmartTable
                        columns={QuestionanswerTable1}
                        columnFilter
                        columnSorter
                        itemsPerPage={5}
                        itemsPerPageSelect={questionList.length > 5}
                        pagination
                        items={questionList}
                        scopedColumns={{
                          Question : (item) =>(<td className="textalign">
                            {/* {JSON.stringify(item)} */}
                            <span><b>Question : </b>{parse(item.Question)}</span>
                                <div>
      
                                   {(
                                     item.answerType === "RadioButton" && 
                                     <span className="optionName"> 
      
                                     {parse(item.option)}
                                   </span>
      
                                   )}
                               </div>

                            <span><b>Correct Answer :</b> {parse(item.correctAnswer)}</span>
                            {/* <span>
                            <b>Student Answer :</b> {parse(item.studentAnswer)}</span> */}
                          </td>),
                        
                          obtainedMark: (item) => (
                            <td>
                               {item.marks}

                               {/* {questionList.find(data => data.QuestionId === item.questionId)?.marks
                                ?
                                 davit billa 80980 43806 - 1000

                                parse(questionList.find(data => data.QuestionId === item.questionId)?.marks)
                                : <p style={{color:"red"}}>-</p>                           
                              } */}
                          
                            </td>
                          ),
                          
                        }}
                        tableProps={{
                          striped: true,
                          hover: true,
                          responsive: true,
                        }}
                      />
                    </div>
              </div>
              )}
          

           {/* Posted Assignment button Weekly  */}

           {showPostedW && (
              <div>
                <div className="container-fluid px-5 py-3">
                  <div className="d-flex flex-column flex-md-row">
                         <KInput
                            placeholder="dd--mm--yyyy"
                            label="Select Start Date"
                            containerClassName="pl-md-4 pl-0 pt-2 pt-md-0 w-25"
                            labelClassName="width-maxContent"
                            inputClassName="w-100"
                            isRequired={true}
                            value={asstDateW1}
                            // min={minDate}
                            onChange={(e) => {
                              const DateW1 = moment(e).format('YYYY/MM/DD');
                              const DateW2 = moment(e).add(5, 'days').format('YYYY/MM/DD') 
                              
                              // console.log("dfsdfsd",DateW1,DateW2)
                              // const formattedStartDate = DateW1 ;
                              // alert(formattedStartDate)
                              // console.log(e,moment(moment(e).add(5, 'days')).format("YYYY-MM-DD"));
                              setAsstDateW1(e);
                              setAsstDateW2(moment(moment(e).add(5, 'days')).format("YYYY-MM-DD"));
                              PostedAssignment_Weeklydata(DateW1,DateW2);
                              }}
                            type="date"
                          />
                         
                          <KInput
                            placeholder="dd--mm--yyyy"
                            label="Select End Date"
                            containerClassName="pl-md-4 pl-0 pt-2 pt-md-0 w-25"
                            labelClassName="width-maxContent"
                            inputClassName="w-100"
                            // isRequired={true}
                            value={asstDateW2}
                            disabled={!asstDateW1}
                            type="date"
                          />

                  </div>

                  <div className="flex-wrap mt-3 pl-3 d-flex flex-column flex-md-row gap-3 pt-2 ">
                        {WeeklyList.map((items) => (
                          <div
                            className="SubjectStyle1"
                            onClick={() => {
                              setShowPostedQAW(true);
                              setShowPosted(false);
                              setShowPostedW(false);
                              Question_Answer_List(items.assignmentId)
                              setShowButton(false)
                              setAssignmentSecondData([{
                                No:items.No,
                                correctAnswer:items.correctAnswer,
                                katbookSubjectName:items.katbookSubjectName,
                                assignmentName:items.assignmentName,
                                assignmentId:items.assignmentId,
                                lsd: items.lsd,
                                totalMarks: items.totalMarks,
                                MarksObtained:items.MarksObtained,
                                classId:items.classId,
                                sectionId:items.sectionId,
                                Assignedby:items.Assignedby,
                                teacherPostedDate:items.teacherPostedDate,
                              }])
                            }
                          }

                    >
                      <div>
                        <h4>{items.Class} {items.katbookSubjectName}</h4>
                        <p style={{ fontWeight: "600", fontSize: "17px" }}>
                          {items.assignmentName}
                        </p>
                      </div>

                      <div>
                        <span>Total Marks : <b>{items.totalMarks}</b></span><br />
                        <span>Teacher Posted Date: <b><br />{items.teacherPostedDate}</b></span> <br />
                        <span>Last Date Submission : <b>{items.lsd}</b></span>
                      </div>
                    </div>
                  ))}
                </div>

                </div>
        
              </div>
            )} 

            {showPostedQAW && (
                <div className="container-fluid px-5 py-3">
                    <div className="mt-1 d-flex justify-content-between">
                      <h4>Assignment Details - Weekly </h4>
                      <button
                        className="backBtn mb-2"
                        onClick={() => {
                         
                          setShowPostedW(true);
                          setShowPostedQAW(false);
                          setShowPostedMonth()
                          setShowButton(true)
                          
                        }}
                      > 
                        Back
                      </button>
                    </div>
                    <div className="tabelstyle">
                      {/* Assignment details Table */}
                      <CSmartTable
                        columns={Assignment_AllDetails}
                        // columnFilter
                        // columnSorter
                        
                        items={assignmentSecondData}
                        scopedColumns={{
                          
                          
                        }}
                        tableProps={{
                          striped: true,
                          hover: true,
                          responsive: true,
                        }}
                      />
                    </div>
                    <div className="tabelstyle">
                      {/* Question and Answer Details */}
                      <h4 className="py-2">Question and Answer Details </h4>
                      <CSmartTable
                        columns={QuestionanswerTable1}
                        columnFilter
                        columnSorter
                        itemsPerPage={5}
                        itemsPerPageSelect={questionList.length > 5}
                        pagination
                        items={questionList}
                        scopedColumns={{
                          Question : (item) =>(<td className="textalign">
                            {/* {JSON.stringify(item)} */}
                            <span><b>Question : </b>{parse(item.Question)}</span>
                                <div>
      
                                   {(
                                     item.answerType === "RadioButton" && 
                                     <span className="optionName"> 
      
                                     {parse(item.option)}
                                   </span>
      
                                   )}
                               </div>

                            <span><b>Correct Answer :</b> {parse(item.correctAnswer)}</span>
                           
                          </td>),
                        
                          obtainedMark: (item) => (
                            <td>
                               {item.marks}
                               
                          
                            </td>
                          ),
                          
                        }}
                        tableProps={{
                          striped: true,
                          hover: true,
                          responsive: true,
                        }}
                      />
                    </div>
              </div>
              )}

           {/* Posted Assignment button Monthly  */}

           {showPostedMonth && (
              <div>
                <div className="container-fluid px-5 py-3">
               
                  <div className="pl-4">
                  <label className="fontLabel width-fit width-maxContent required-field" htmlFor="datepicker" style={{ display: 'block', marginBottom: '8px' }}>Select Month</label>
                    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                    <DatePicker
                          placeholderText="dd/mm/yyyy"
                          title="Select Month"
                          
                          id="datepicker"
                          selected={asstDateM1}
                          className="dateNew"
                          onKeyDown = {handleKey}
                          onMouseDown={(e) =>e.preventDefalt()}
                          onChange={(date) => {
                            const formattedDateM1 = moment(date).format("YYYY/MM/DD");
                            const formattedDateM2 = moment(date).add(1, "months").format("YYYY/MM/DD");
                            setAsstDateM1(date);
                            setAsstDateM2(moment(moment(date).add(1, "months")).format("YYYY/MM/DD"));
                            PostedAssignment_Monthlydata(formattedDateM1, formattedDateM2);
                          }}
                          dateFormat="dd/MM/yyyy"
                          showMonthYearPicker
                          // isClearable 
                          error={errorMessage}      
                    />

                    </div>
                         {/* {/* <KInput
                            placeholder="dd--mm--yyyy"
                            label="Select Month"
                            containerClassName="pl-md-4 pl-0 pt-2 pt-md-0 w-25"
                            labelClassName="width-maxContent"
                            inputClassName="w-100"
                            isRequired={true}
                            dateFormat="MM/YYYY"
                            value={asstDateM1}
                            // min={minDate}
                            onChange={(e) => {
                              const DateM1 = moment(e).format('MM/YYYY');
                              const DateM2 = moment(e).add(1, 'months').format('YYYY/MM/DD');
                              setAsstDateM1(e);
                              setAsstDateM2(moment(moment(e).add(1, 'months')).format("YYYY-MM-DD"));
                              PostedAssignment_Monthlydata(DateM1,DateM2);

                              }}
                            type="date"
                          /> */}  
                          {/*                          
                          <KInput
                            placeholder="dd--mm--yyyy"
                            label="Select Month"
                            containerClassName="pl-md-4 pl-0 pt-2 pt-md-0 w-25"
                            labelClassName="width-maxContent"
                            inputClassName="w-100"
                            // isRequired={true}
                            value={asstDateM2}
                            disabled={!asstDateM1}
                            type="date"
                          />  */}

                  </div>

                  <div className="flex-wrap mt-3 pl-3 d-flex flex-column flex-md-row gap-3 pt-2 ">
                        {monthlyList.map((items) => (
                          <div
                            className="SubjectStyle1"
                            onClick={() => {
                              setShowPostedQAW(false);
                              setShowPosted(false);
                              setShowPostedW(false);
                              Question_Answer_List(items.assignmentId)
                              setShowButton(false);
                              setShowPostedQAM(true);
                              setShowPostedMonth(false)
                              setAssignmentSecondData([{
                                No:items.No,
                                correctAnswer:items.correctAnswer,
                                katbookSubjectName:items.katbookSubjectName,
                                assignmentName:items.assignmentName,
                                assignmentId:items.assignmentId,
                                lsd: items.lsd,
                                totalMarks: items.totalMarks,
                                MarksObtained:items.MarksObtained,
                                classId:items.classId,
                                sectionId:items.sectionId,
                                Assignedby:items.Assignedby,
                                
                              }])
                            }
                          }

                    >
                      <div>
                        <h4>{items.Class} {items.katbookSubjectName}</h4>
                        <p style={{ fontWeight: "600", fontSize: "17px" }}>
                          {items.assignmentName}
                        </p>
                      </div>

                      <div>
                        <span>Total Marks : <b>{items.totalMarks}</b></span><br />
                        <span>Teacher Posted Date: <b><br />{items.teacherPostedDate}</b></span> <br />
                        <span>Last Date Submission : <b>{items.lsd}</b></span>
                      </div>
                    </div>
                  ))}
                </div>

                </div>
        
              </div>
            )} 

            {showPostedQAM && (
                <div className="container-fluid px-5 py-3">
                    <div className="mt-1 d-flex justify-content-between">
                      <h4>Assignment Details - Monthly </h4>
                      <button
                        className="backBtn mb-2"
                        onClick={() => {
                         
                          setShowPostedMonth(true);
                          setShowPostedQAM(false);
                          setShowButton(true)
                         

                          
                        }}
                      > 
                        Back
                      </button>
                    </div>
                    <div className="tabelstyle">
                      {/* Assignment details Table */}
                      <CSmartTable
                        columns={Assignment_AllDetails}
                        // columnFilter
                        // columnSorter
                        
                        items={assignmentSecondData}
                        scopedColumns={{
                          
                          
                        }}
                        tableProps={{
                          striped: true,
                          hover: true,
                          responsive: true,
                        }}
                      />
                    </div>
                    <div className="tabelstyle">
                      {/* Question and Answer Details */}
                      <h4 className="py-2">Question and Answer Details</h4>
                      <CSmartTable
                        columns={QuestionanswerTable1}
                        columnFilter
                        columnSorter
                        itemsPerPage={5}
                        itemsPerPageSelect={questionList.length > 5}
                        pagination
                        items={questionList}
                        scopedColumns={{
                          Question : (item) =>(<td className="textalign">
                            {/* {JSON.stringify(item)} */}
                            <span><b>Question : </b>{parse(item.Question)}</span>
                                <div>
      
                                   {(
                                     item.answerType === "RadioButton" && 
                                     <span className="optionName"> 
      
                                     {parse(item.option)}
                                   </span>
      
                                   )}
                               </div>

                            <span><b>Correct Answer :</b> {parse(item.correctAnswer)}</span>
                           
                          </td>),
                        
                          obtainedMark: (item) => (
                            <td>
                               {item.marks}
                               
                          
                            </td>
                          ),
                          
                        }}
                        tableProps={{
                          striped: true,
                          hover: true,
                          responsive: true,
                        }}
                      />
                    </div>
              </div>
              )}


          </div>
        </div>
       
      <Footer
        openForm={openForm}
        shoq={shoq}
        closeForm={closeForm}
        KpostIframe={KpostIframe}
      />
    </div>
  );
};

export default ViewAssignment;
