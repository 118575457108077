import React, { useEffect, useState } from "react";
import Header from "./HeaderNavbar";
import Footer from "./Footer";
import KDropdown from "./common/KDropdown";
import KInput from "./common/KInput";
import KButton from "./common/KButton";
import KHeader from "./common/KHeader";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Row, Col } from "reactstrap";
import useResponsive from "../../hooks/useResponsive";
import KEditor from "./common/KEditor";
import KRequiredLabel from "./common/KRequiredLabel";
import parse from "html-react-parser";
import { AiOutlineEdit } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FcCancel } from "react-icons/fc";
import { FaEdit } from "react-icons/fa";

import data from "../../data.json";
import {
  Teacher_Get_Question_Type,
  
  getQuestionTypeByInst,
  // updateQuestionType,
  // getTeacherSubjects,
  getDynamicSubjects,
 
  // getTableAssignment,
  // getQuestionType,
  // getMarksandLsd,
  // getQuestionTable,
  // postQuestionType,
  // putQuestionType,
  // postQuestionv2Create,
  DeleteQuestion,
  Teacher_CreateAssignemt_Table,
  Teacher_Question_Type_Update,
  Teacher_Create_Question_Type,
  Teacher_Create_QuestionAndAnswer,
  QuestionAndAnswer_Table_List,
 
  Teacher_Question_Answer_Delete,
  
  
} from "../../services/Apiservices";
import { toast } from "react-toastify";
import Danger from "./Toastify/Danger";
import Warning from "./Toastify/Warning";
import Success from "./Toastify/Success";
import { CButton } from "@coreui/react";
import { CSmartTable,CModal } from "@coreui/react-pro";
import { useRef } from "react";

const CreateQuestion = ({
  showProfile,
  falseShowProfile,
  notofShowProfile,
  ShowNotifications,
  showNotification,
  falseShowNotification,
  disableOnOuterTouch,
  shoq,
  KpostIframe,
  closeForm,
  openForm,
  goToMainRoutes
}) => {
  const { isBigScreen } = useResponsive();
  const QuesRef = useRef();
  const EXISTING_QUESTION_BANK_CODE = "EQBC";
  const EXISTING_Assignment = "EAS";
  const NEW_QUESTION_BANK_CODE = "NQBC";
  const ADD_QUESTION_DISPLAY = "AQD";
  const [num, setNum] = useState("");
  const [num1, setNum1] = useState("");
  const [marksList, setMarksList] = useState([]);
  const [posted,setPosted] = useState([]);
  const [visible, setVisible] = useState(false);
  const [visibleE, setVisibleE] = useState(false);
  const [reloadTable, setReloadTable] = useState(false);
  const [reloadTableTest, setReloadTableTest] = useState(false);
  
  const[questionTypeData1,setQuestionTypeData1] = useState([])
  const[questionCreationDataId,setQuestionCreationDataId] = useState([{
    questionCreationId:"",
   }])

   const[questionCreationDataIdExit,setQuestionCreationDataIdExit] = useState([{
    questionCreationId:"",
   }])

   const [isEditing, setIsEditing] = useState(false);
   const [textValue, setTextValue] = useState('');
   const [textData, setTextData] = useState('');
 


  const [showScreen, setShowScreen] = React.useState({
    show: false,
    Type: "",
  });
  const [table, setTable] = useState(false);
  const [createQuesBtn, setcreateQuesBtn] = useState(false);
  const [question, setQuestion] = React.useState(false);
  const [add, setAdd] = React.useState(false);
  const [subjectsData, setsubjectsData] = React.useState([]);
  const [singleSubject, setsingleSubject] = React.useState("");
  const [singleSubject1, setsingleSubject1] = React.useState();
  const [singleSubjectExit, setsingleSubjectExit] = React.useState();
  
  const [AssignmentData, setAssignmentData] = React.useState([]);
  const [assignmentDataId, setAssignmentDataId] = React.useState();
  // console.log("jfklsdfjdf",assignmentDataId)
  const [acadeYear, setAcadeYear] = React.useState([]);
  const [singleAssignment, setsingleAssignment] = React.useState("");
  const [AssignmentDataExit, setAssignmentDataExit] = React.useState([]);
  const [singleExitAssignment, setsingleExitAssignment] = React.useState("");
  const [existingquestionTable, setExistingquestionTable] = useState([]);

  const [AsstTitle, setAsstTitle] = useState("");
  const [AsstDate, setAsstDate] = useState("");
  const [AsstMarks, setAsstMarks] = useState([]);
  // console.log("Marks",AsstMarks)
  const [userscredsData, setuserscredsData] = useState([]);
  
  const [asstAddBtn, setasstAddBtn] = useState(false);
  const [questionTypesOption, setQuestionTypesOption] = useState("");
  const [questionType, setQuestionType] = useState("");
  const [questionTypeData, setQuestionTypeData] = useState([]);
  const [questionTypeName, setQuestionName] = useState("");
  const [edit, setEdit] = useState(false);
  const [QTupdateBtn, setQTupdateBtn] = useState(false);
  const [QTaddBtn, setQTaddBtn] = useState(true);
  const [QTadd, setQTadd] = useState(false);
  const [detailtedans, setDetailtedans] = useState("");

  const [editOPtion, setEditOption] = useState(false);
  const [editOPtionText, setEditOptionText] = useState("");
  const [optionText, setoptionText] = React.useState("");
  const [option, setoption] = React.useState([]);
  const [correctAns, setCorrectAns] = React.useState({});
  const [textAns, setTextAns] = React.useState("");
  const [quesAns, setquesAns] = React.useState({});
  const [QuestionValue, setQuestionValue] = useState("");
  const [ExamsData, setExamsData] = useState([]);
  const [asstQuestionsData, setAsstQuestionsData] = useState([]);
  const [Quesmarks, setQuesmarks] = useState("");
  const optionsAns = [
    {
      value: "TextBox",
      id: "1",
      label: "TextBox",
    },
    { value: "TextArea", id: "2", label: "TextArea" },
    { value: "RadioButton", id: "3", label: "RadioButton" },
  ];
  const [AnswerType, setAnswerType] = useState({});
  const [getAllQues, setgetAllQues] = useState([]);
  const [questionTable, setQuestionTable] = useState([]);
  const [dropYear, setdropYear] = useState(false);
  const [questionForAssign, setquestionForAssign] = useState(false);
  const [tableQuestionBank, setTableQuestionBank] = useState(false);
  const [clearData, setClearData] = useState(false);
  const [unitData,setUnitData] = useState([])
  const[exitAssign,setExitAssign]=useState([])
  const [questionid, setQuestionid] = useState("");
  const [isError, setIsError] = useState(false);
  const [markValidation,setMarkValidation] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowsId, setSelectedRowsId] = useState(0);
  const [editExQuestionId,setEditExQuestionId] = useState("")

  // console.log("markValidationmarkValidation",markValidation)

  // console.log("sdjsdfjsdfjksdf",selectedRows)

  const handleRowClick = (item) => {
    // console.log(item , "handleRowClick");
    setSelectedRows(
      (item.length > 0)
        ? item.map((x) => ({
            questionCreationId: x.questionCreationId,
            question: x.Question,
            answerType: x.answerType,
            assignmentId:x.assignmentId,
            questionTypeId:x.questionTypeId, 
            answerType:x.answerType,
            // options:x.option || `${JSON.stringify(x.options === "" ? [] : [])}` || x.options === null ? "" : [],
            options:x.option,
            marks:x.Marks,
            realOptions : x.realOptions,

            

            correctAnswer:x?.correctAnswer,
           
          }))
        : []
    );
  };

  const handleNumChange = (event) => {
  
    const limit = 3;
    setNum(
      event.target.value
      // .slice(0, limit)
    );
  
  };
  // if save button clicked after go to page top in screen
  const saveButtonRef = useRef(null);

  const pagetop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  

  // Check if a row is selected
  const isRowSelected = (rowId) => {
    return selectedRows.includes(rowId);
  };
  function refreshPage(){ 
    window.location.reload(); 
}

const handleNumChange1 = (event) => {
  const limit = 3;
  setNum1(
    event.target.value
    // .slice(0, limit)
  );
};

  const handleEditClick = (item) => {
    // console.log(item,"itemitemitemitem");
    setEditExQuestionId(item.questionCreationId);
    };
    
  const handleSaveClick = (questionId,value,selectedRows) => {
    
    setExistingquestionTable(existingquestionTable.map(x=> {
 
        if(x.questionCreationId === questionId){
          x.Marks = `${value}`
        }
        return x
       }))
    const updatedSelectedRows = selectedRows.filter((row) => row.questionCreationId !== questionId);
     setSelectedRows(updatedSelectedRows);
    setEditExQuestionId('')
    setIsEditing(false);
    // setSelectedRows((selectedRows) => [...selectedRows]);
     //isRowSelected(item.questionCreationId)
    //setTextValue('');
       
  };

  const handleTextAreaChange = (event) => {

    setTextValue(event.target.value);

    const DataMark = event.target.value;

   //  if(existingquestionTable.filter(x => x.questionCreationId === questionCreationId ).length > 0){
     
   //   let removeData = existingquestionTable.filter(x=>x.questionCreationId !== questionCreationId )
      
   //    setTextValue([...removeData,DataMark ])
    
   //   }else{
    
   //     setTextValue([...removeData,DataMark])
    
   //   }

  };




  const columns_questionandAnswer = [
    {
      key: "sno",
      label: "S.No",
      filter: false,
      sorter: false,
      _style: { width: "5%" },
    },
    {
      key: "AssignmentName",
      label: "Assignment",
      filter: true,
      sorter: false,
      _style: { width: "10%" },
    },
    {
      key: "QuestionType",
      label: "Type",
      filter: true,
      sorter: false,
      _style: { width: "10%" },
    },
    {
      key: "Question",
      label: "List of Question and Answer",
      filter: true,
      sorter: false,
      _style: { width: "60%" },
    },
   
    {
      key: "Marks",
      label: "Marks",
      _style: { width: "8%" },
      filter: true,
      sorter: false,
    },
    {
      key: "show_details",
      label: "Action",
      _style: { width: "6%" },
      filter: false,
      sorter: false,
    },
  ];

  const ExistingQuestion_BankTable = [
    {
      key: "sno",
      label: "S.No",
      filter: false,
      sorter: false,
      _style: { width: "5%" },
    },
   
    // {
    //   key: "AssignmentName",
    //   label: "Assignment Name",
    //   filter: true,
    //   sorter: false,
    //   _style: { width: "12%" },
    // },
    
    {
      key: "Question",
      label: "List of Question and Answer",
      filter: true,
      sorter: false,
      _style: { width: "75%" },
    },
    // {
    //   key: "Answer",
    //   label: "Answer",
    //   filter: true,
    //   sorter: false,
    //   _style: { width: "20%" },
    // },
    {
      key: "Marks",
      label: "Marks",
      _style: { width: "12%" },
      filter: true,
      sorter: false,
    },
    {
      key: "show_details",
      label: "Action",
      _style: { width: "8%" },
      filter: false,
      sorter: false,
    },
  ];

  const ExistingAssignmentTable = [
    {
      key: "sno",
      label: "S.No",
      filter: false,
      sorter: false,
      _style: { width: "5%" },
    },
    {
      key: "Question",
      label: "List of Question and Answer",
      filter: true,
      sorter: false,
      _style: { width: "75%" },
    },
    // {
    //   key: "Answer",
    //   label: "Answer",
    //   filter: true,
    //   sorter: false,
    //   _style: { width: "15%"},
    // },
    {
      key: "Marks",
      label: "Marks",
      _style: { width: "12%" },
      filter: true,
      sorter: false,
    },
    {
      key: "show_details",
      label: "Action",
      _style: { width: "7%" },
      filter: false,
      sorter: false,
    },
  ];
  
  const CreateQuestionDataClear = () => {
    setShowScreen({
      show: false,
      Type: "false",
    });
    setTable(false);
    setsingleSubject(false);
    // showAssignment(false);
    setsingleAssignment(false);
    setAsstMarks(false);
    setAsstDate(false);
    setIsError(false);
  };

  const showQuestionAndAnswerTable = async (assignmentId) => {
    setQuestionTable([]);
    var response;
    let body = {
      staffId: localStorage.length > 0 && JSON.parse(localStorage.userCreds).staffID,
      assignmentId: assignmentId,
    };
    try {
      response = await QuestionAndAnswer_Table_List(body);
      if (response) {
        if (response.status==="success") {
          if (response.data) {
            const data = response.data.map((x, i) => {
              try {
                x.options = JSON.parse(x.options)?.map((option, index) => `${index + 1}. ${option.options}`).join(' ')
              } catch (error) {
                x.options = x.options;
              }
              return {
                sno: i + 1,
                AssignmentName: x.assignment.assignmentName,
                Question: x.question,
                Answer: x?.correctAnswer,
                Marks: x?.marks,
                answerType: x.answerType,
                questionCreationId: x.questionCreationId,
                Posted: x.assignment.posted === true?"Posted":"Not Posted",
                option: x.options,
                PostedAss: x.assignment.posted,
                QuestionType:x.questionType.questionTypeName,
              };
            });
            setQuestionTable(data);
            // console.log("44sdffsd",data)

            let totalMarks = data.reduce((m, x) => m + (parseInt(x.Marks) || 0), 0);
            
            // console.log("Total marks:", totalMarks);
            

            // let totalMarks = data.map((x) => x.Marks).reduce((acc, curr) => acc + curr, 0);
            
            // let totalMarks = data.reduce((m, x) => m + (x.Marks || 0), 0);
            // console.log("sdjfksdfjsd",totalMarks)
            
            
            // let totalMarks = data.map((x=>x.Marks));
           
            // for (let i = 0; i < totalMarks; i++) {
             
            //   totalMarks = x.Marks? Number(totalMarks) + Number(x?.Marks ?? 0) + 0;

            // }
            setMarkValidation(totalMarks);
            // console.log("markvalidation", markValidation)
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const showExitQuestionAndAnswerTable = async (value) => {
    setExistingquestionTable([]);
       var response;
       
       let body = {
        staffId: localStorage.length > 0 && JSON.parse(localStorage.userCreds).staffID,
        assignmentId: value,
        
    };
    // console.log("dsfklf",body)
    try {
      response = await QuestionAndAnswer_Table_List(body);
      if (response) {
        if (response.status === "success") {
          // console.log(response.data, "123546");
          toast.success(response.message);
          if (response.data) {
            toast.success(response.message);
            const data = response.data.map((x, i) => {
              const realOptions = x.options;
              // console.log("jdksdjfkjfds",realOptions)
              try {
                x.options = JSON.parse(JSON.stringify(JSON.parse(x.options)?.map((option, index) => `${index + 1}. ${option.options}`).join(' ')))
                
              } catch (error) {
                x.options = x.options;
              }

              return {

                sno: i + 1,
                AssignmentName: x.assignment.assignmentName,
                assignmentId:x.assignment.assignmentId,
                questionTypeId:x.questionType.questionTypeId,
                Question: x.question,
                Answer: x?.correctAnswer,
                correctAnswer:x?.correctAnswer,
                Marks: x?.marks,
                
                questionCreationId:x.questionCreationId,
                Posted:x.assignment.posted===true?"Posted":"Not Posted",
                // option: x.options === "" || x.options === null ? "" : `${JSON.parse(x.options)?.map((x, i) => `${i + 1}.${x.options}`).join('\n')}`,
               PostedAss:x.assignment.posted,
               answerType:x.answerType,
              //  option: x.options === "" || x.options === null ? "" : `${JSON.parse(x.options)?.map((x, i) => `${i + 1}.${x.options}`).join('\n')}`,
               option: x.options,
               realOptions : realOptions,
               obtainedMark: `${x.answerType === "RadioButton" ? 
               (x.studentAnswer === x.correctAnswer ? x.marks : 0)
                : (x.studentMark !== null ? x.studentMark : "-")}`,
              };
            });
            setExistingquestionTable(data);
            
            
          }
        }
        else{
          toast.error(response.message)
        }
        
      }
    } catch (e) {
      console.log(e);
    }
  };

  const showAssignment = async (kamSubjectId,sectionId,classId) => {
    var response,resp;
    setAssignmentData([]);
        let body={
          
          staffId:
          localStorage.length > 0 && JSON.parse(localStorage.userCreds).staffID,
          katbookSubjectId: kamSubjectId,
          classId: classId,
          sectionId:sectionId,
               
        }

    try {
      response = await Teacher_CreateAssignemt_Table(body);
      if (response) {
        if (response.status === "success") {
          // console.log(response.data, "123546");
          // toast.success(response.message);
          if (response.data) {

            // toast.success(response.message);
            const data = response.data.map((x, i) => {
              return {

                sno: i + 1,
                data:x,
                label: `${x.assignmentName}-${x.posted === true ? "Posted" : "Not Posted"}`,
   
                // label:x.assignmentName,
                value:x.assignmentId ,
                assignmentIdget:x.assignmentId,
                subjectId:x.katbookSubjectId,
                posted:x.posted === true ? "Posted ": "Not Posted"
                };
            });
            setAssignmentData(data);
            // console.log("dsjksdf",data)
            }
        }
         else{
            toast.error(response.message)
          }
        
        
      }
    } catch (e) {
      console.log(e);
    }
  };

  // console.log("45sdf",posted)
// const showAssignment = async (kamSubjectId,sectionId,classId) => {
//     var response,resp;
//     setAssignmentData([]);
//     let body={
      
//       staffId:
//       localStorage.length > 0 && JSON.parse(localStorage.userCreds).staffID,
//       katbookSubjectId: kamSubjectId,
//       classId: classId,
//       sectionId:sectionId,
           
//     }
//     try {
//       response = await Teacher_CreateAssignemt_Table(body);
//       // console.log(response, "exampler");
//       if (response.status==="success") {

//         let dataRes = []

//         for(let ind =0;ind<response.data.length;ind++){
//             let bod = {
//             staffId: localStorage.length > 0 && JSON.parse(localStorage.userCreds).staffID,
//             assignmentId: response.data[ind].assignmentId,
//         };
//         resp = await QuestionAndAnswer_Table_List(bod);
//         if (resp.status==="success") {
//           if (resp.data) {
//             dataRes.push({
//                 data:response.data[ind],
//                 value:response.data[ind].assignmentId,
//                 label: `${response.data[ind]?.assignmentName}-${resp.data.find(m=>m.assignment.assignmentId === response.data[ind].assignmentId)?.assignment.posted===true ?"Posted":"Not Posted"}` ,
//                 assignmentIdget:response.data[ind].assignmentId,
//                 subjectId:response.data[ind].katbookSubjectId,
//             })
//           }
//         }

//         }
       
//         setAssignmentData(dataRes);
//       //  console.log("sdjkksdjfkjsd",dataRes)
//       }
//     } catch (e) {
//       console.log(e);
//     }
// };

// Existing Assignment
const showExistingAssignment = async (kamSubjectId, sectionId, classId) => {
  // alert()
  var response, resp;
  setAssignmentDataExit([]);

  let body = {
    staffId: localStorage.length > 0 && JSON.parse(localStorage.userCreds).staffID,
    katbookSubjectId: kamSubjectId,
    classId: classId,
    sectionId: sectionId,
  };

  try {
    response = await Teacher_CreateAssignemt_Table(body);

    if (response.status==="success") {
      let dataRes = [];

      for (let ind = 0; ind < response.data.length; ind++) {
        let bod = {
          staffId: localStorage.length > 0 && JSON.parse(localStorage.userCreds).staffID,
          assignmentId: response.data[ind].assignmentId,
        };
        resp = await QuestionAndAnswer_Table_List(bod);
        if (resp && resp.data && resp.data.find(m => m.assignment.assignmentId === response.data[ind].assignmentId)?.assignment.posted) {
          dataRes.push({
            data: response.data[ind],
            value: response.data[ind].assignmentId,
            label: `${response.data[ind]?.assignmentName}-Posted`,
            assignmentIdget: response.data[ind].assignmentId,
            subjectId: response.data[ind].katbookSubjectId,
            assignmentId: response.data[ind].assignmentId,
            subjectName: response.data[ind].katbookSubjectName,
           
          });
        }
      }

      setAssignmentDataExit(dataRes);
      
    }
  } catch (e) {
    console.log(e);
  }
};

  const showQuestionType = async () => {
    
    var response;
    // setQuestionTypeData([]);
    try {
      response = await Teacher_Get_Question_Type();
      
      console.log(response, 'asdfgtrew')
      if (response.status==="success") {
      
        const data = response.data.map((x) => {
          return {
            value: x.questionTypeId,
            label: x?.questionTypeName,
            // isActive:x.isActive,
            // createdBy:x.createdBy,
            // createdTime:x.createdTime,
            // modifiedBy:x.modifiedBy,
            // modifiedTime:x.modifiedTime,
            // questionTypeId:x.questionTypeId,
            // questionTypeName:x.questionTypeName,
          };
        });
        setQuestionTypeData(data);
        console.log("sdfjskdlfjsd",data)
      }
    } catch (e) {
      console.log(e);
    }
  };




  const updateQuestionTypeFuc = async () => {
    let result;
    let body = {
      questionTypeId: questionType.value,
      questionTypeName: questionTypeName,
    };
    // console.log(body,'222')
    try {
      result = await Teacher_Question_Type_Update(body);
      if (result.status==="success") {
        toast.success(<Success body={result.message} />);
        questionCancelFuc();
        showQuestionType()
      } else {
        toast.error(<Danger body={result.message} />);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateQuestionType = (data) => {
    // console.log(data, "fdatas");
    setQuestionName(data.label);
    setQuestionType({
      value: data.value,
      label: data.label,
    });
    setEditConfirmBtn(true);
  };
  
// Question and Answer update


  const unitSelect = [
    {
      value: "Unit 1",
      label: "Unit 1 - Electrostatics",
    },
    {
      value: "Unit 2",
      label: "Unit 2 - Current Electricity",
    },
    {
      value: "Unit 3",
      label: "Unit 3 - Ray Optics",
    },
    {
      value: "Unit 4",
      label: "Unit 4 - Wave Optics",
    },
    {
      value: "Unit 5",
      label: "Unit 5 - Electromagnetic Optics",
    },
  ];

  
  const SelectQType = [
    {
      value: "ShortAnswer",
      label: "Short Answer",
    },
    {
      value: "LongAnswer",
      label: "Long Answer",
    },
    {
      value: "MCQs",
      label: "MCQs",
    },
   
  ];

 
  const acdamyearChane = (e) => {
    if (e.value === "2019-2020") {
      setdropYear(true);
    }
    if (e.value === "2020-2021") {
      setdropYear(false);
    }
    if (e.value === "2021-2022") {
      setdropYear(false);
    }
  };

  const AssignmentSelectChange = (e) => {
    if (e.value === "Assignment 1") {
      setquestionForAssign(true);
    }
    if (e.value === "Assignment 2") {
      setquestionForAssign(false);
    }
    if (e.value === "Assignment 3") {
      setquestionForAssign(false);
    }
  };

  const notify = () => {
    toast("Assignment save Succesfully");
  };

  const CourseListFunc = async () => {
    
    const SubjectList = JSON.parse(await localStorage.getItem("userCreds"))?.classSubjectDetails ?? [];
    
    if (SubjectList.length > 0) {
      let dataOPtion = await SubjectList.map((e) => {
        return {
          ...e,
          value: `${e.katbookSubjectId} ${e.sectionId}`,
          // value: e.katbookSubjectId,
          data:e.katbookSubjectId,
          label: `${e.className}-${e.sectionName} ${e.subjectName}`,
          kamSubjectId: e.katbookSubjectId,
          sectionId:e.sectionId,
          classId:e.classId,
          // kamSubjectId: e.subjectId,
          

          // value: e.KATBOOK_SUBJECT_ID,
          // label: e.SUBJECT_NAME,
          // kamSubjectId: e.SUBJECT_ID,
          //  e.className + " - " + e.sectionName + " - " +
          //  e.className + " - " + e.sectionName + " - " +
          // katSubjectId: e.katbookSubjectId,
          // subjectName: e.subjectName,
          // className: e.className,
          // classId: e.classId,
          // sectionId: e.sectionId,
          // sectionName: e.sectionName,
        };
      });
      setsubjectsData(dataOPtion);
      // console.log("subjectID",dataOPtion)
    }
  };


  const EmptyAssigtCreateData = () => {
    // alert();
    setsingleSubject("");
    setAsstTitle("");
    setAsstMarks("");
    setQuestionValue("");
    setQuestionType("");
    setQuesmarks("");
    setoption([]);
    setTextAns("");
    setquesAns("");
    setCorrectAns("");
    setQTupdateBtn(false);
    setasstAddBtn(false);
    setIsError(false);
    setShowScreen({
      show: false,
      Type: "",
    });
  };

  const EmptyAssigtCreateDataNew = () => {
    // alert();
    setIsError(false);
    setAsstTitle("");
    setAsstMarks("");
    setQuestionValue("");
    setQuestionType("");
    setQuesmarks("");
    setoption([]);
    setTextAns("");
    setquesAns("");
    setCorrectAns("");
    setQTupdateBtn(false);
    setasstAddBtn(false);
    setShowScreen({
      show: false,
      Type: "",
    });
  };

  const EmptyQuesData = () => {
    setQuestionValue("");
    setQuestionType("");
    setQuesmarks("");
    setoption([]);
    setTextAns("");
    setquesAns("");
    setCorrectAns("");
    setQTupdateBtn(false);
    setIsError(false);
  };


  const onDelete = async (questionCreationId) => {
    let response;

      let body={
        questionCreationId:questionCreationId
      }

      // console.log(body,"datatattata")
    
      try {
      // response = await DeleteQuestion(questionid);
      response = await Teacher_Question_Answer_Delete(body);
      
      if (response.status==="success") {
        const result = questionTable.filter(
          (item) => item.questionid !== questionid
        );
        showQuestionAndAnswerTable(singleAssignment.value);
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onDeleteExit = async (questionCreationId) => {
    let response;

      let body={
        questionCreationId:questionCreationId
      }

      // console.log(body,"125")
    
      try {
      // response = await DeleteQuestion(questionid);
      response = await Teacher_Question_Answer_Delete(body);
      
      if (response.success) {
        const result = questionTable.filter(
          (item) => item.questionid !== questionid
        );
        showExitQuestionAndAnswerTable(singleExitAssignment.value);
        toast.success(response.message);
      } else {
        toast.error(response.error);
      }
    } catch (error) {
      console.log(error);
    }
  };
  



  const Create_QuestionandAnswers = async () => {
    // console.log(correctAns?.value, "ANSWERRRRR", option);

    

    const optionsData = option.map((x) => {
      // return x.name;
      return {
        options: x.name,
      };

    });
    const optionsString = JSON.stringify(optionsData);

    const data = {
      staffId:localStorage.length > 0 && JSON.parse(localStorage.userCreds).staffID,
      subjectId: singleSubject1,
      subjectName: singleSubject?.label,
      assignmentId: singleAssignment?.value,
      questionTypeId: questionType?.value,
      question: QuestionValue,
      answerType: AnswerType.value,
      options:optionsString,
      
      // options:"",        
      // options: option.map((x) => {
      //    return {
      //     option: x.name,
      //   };
      // }),
     
      correctAnswer:
        AnswerType.value === "RadioButton"
          ? correctAns.name
          : textAns || quesAns,
      marks: Quesmarks,
    };

    // console.log("Body_",data)
    // Teacher_CreateAssignemt_Table
    try {
      var res = await Teacher_Create_QuestionAndAnswer(data);
      if (res) {
        if (res.data) {
          toast.success(res.message);
          // toast.success(<Success body={res.message} />) 
          setAsstQuestionsData(res.data)
          showQuestionAndAnswerTable(singleAssignment.value);
          setQuestionType("");
          setQuestionValue("");
          setQuesmarks("");
          setCorrectAns("");
          setTextAns("");
          setquesAns("");
          setAnswerType({});
          // QuestionandAnswer_Update_Function()
        } 
        else {
          // toast.error(<Danger body={res.message} />)
          toast.error(res.message);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  
  // console.log("lfkkfl",asstQuestionsData )

  
  const Create_QuestionandAnswersExit = async () => {

    let Totalmarks;
    Totalmarks = 0;
    selectedRows.map((x) => {
      Totalmarks = Number(Totalmarks) + Number(x.marks);
    });

    // console.log(Totalmarks,"marrkssum");

    let finalmaarks = Number(Totalmarks) + Number(markValidation);
    // console.log(finalmaarks,"marrkssum");
    if(finalmaarks <= AsstMarks){

          let prev = selectedRows;


    const optionsString = selectedRows.map((x)=> ({ 
      options : x.options
    }));
    const dataValue = `${JSON.stringify(optionsString)}`
    // console.log("ksdfjfkjsdf",`${JSON.stringify(dataValue)}`)
    

    const dataSelect = selectedRows.map(async(x) =>{
      // console.log(selectedRows,"selectedRows");
      const body = {
        staffId:localStorage.length > 0 && JSON.parse(localStorage.userCreds).staffID,
        subjectId: singleSubject1,
        subjectName: singleSubject?.label,
        questionTypeId: x.questionTypeId,
        assignmentId:assignmentDataId,
        question: x.question,
        answerType:x.answerType,
        // questionCreationId:x.questionCreationId,
        correctAnswer:x.correctAnswer,  
        options:x.realOptions, 
        marks:x.marks,    
      }
    // console.log("dlksdjflkjdsf",body)

      try {
        var res = await Teacher_Create_QuestionAndAnswer(body);
        if (res) {
         
          if (res.data) {
            toast.success(res.message);
            // setAsstQuestionsData(res.data)
            // showQuestionAndAnswerTable(singleAssignment.value);
            // setQuestionType("");
            // setQuestionValue("");
            // setQuesmarks("");
            // setCorrectAns("");
            // setTextAns("");
            // setquesAns("");
            // setAnswerType({});
            prev = selectedRows.filter(y=>y.questionCreationId !== body.questionCreationId )
           
            showQuestionAndAnswerTable(singleAssignment.value);
           
          } 
          else {
            // toast.error(<Danger body={res.message} />)
            toast.error(res.message);
          }
        }
      } catch (error) {
        console.log(error);
      }

      

    })
      // alert("success")
    } else {
      // alert("error")
      toast.error("Please enter marks less than or equal to Total Mark")
    }


      
  };
  


const updateQuestionandAnswerData = (data) => {
  // console.log(data, "data");
  setShowScreen({
    show: true,
    Type: "",
  });
  setEditOption(false);
  setEditOptionText("");
  setoption([]);
  setCorrectAns({});
  setTextAns("");
  setAnswerType();
  setShowScreen({
    show: true,
    Type: NEW_QUESTION_BANK_CODE,
  });
  setQuestionValue(data.name);
  setQuestionType({
    value: data?.questionType?.value,
    label: data?.qtype,
  });
  setTextAns(data.role);
  setQuesmarks(data.status);
};


  const ResetToDefaultScreen = () => {
    setShowScreen({
      show: false,
      Type: "",
    });
  };

  const ShowAddQuestions = () => {
    setShowScreen({
      show: true,
      Type: ADD_QUESTION_DISPLAY,
    });
  };

  const showExistingQuestionBank = () => {
    setShowScreen({
      show: true,
      Type: EXISTING_Assignment,
    });
    setdropYear(false);
    setquestionForAssign(false);
  };

  const showRealExistingQuestionBank = () => {
    setShowScreen({
      show: true,
      Type: EXISTING_QUESTION_BANK_CODE,
    });
  };

  const showNewQuestionBank = () => {
    setShowScreen({
      show: true,
      Type: NEW_QUESTION_BANK_CODE,
    });
  };



  useEffect(() => {
    if (localStorage.length > 0) {
      let retrData = JSON.parse(localStorage.userCreds);

      const getSubjectListfunc = async () => {
        let allquesbod, allquestres;

        if (retrData.userType === "Teaching") {
          allquesbod = {
            // refInstitutionId: retrData.refInstID,
            // staffId: retrData.staffID,
            refStaffID: retrData.staffID,
          };

          allquestres = await getDynamicSubjects(allquesbod);

        } else {
          allquesbod = {
            refInstitutionId: retrData.refInstID,
            sectionId: retrData.classID,
          };

          // allquestres =await  getDynamicTimetable(allquesbod);
        }

        if(allquestres.status === "403"){
          goToMainRoutes();
        }

        if (allquestres.status === "success") {
          setuserscredsData(allquestres.value);
          CourseListFunc(allquestres.value);
          // if(allquestres.value.length > 0){
          //   let dataOPtion = await allquestres.value.map((e) => {
          //     return { value: e.className+' - '+e.sectionName+' - '+e.subjectName, label: e.className+' - '+e.sectionName+' - '+e.subjectName, katSubjectId: e.katbookSubjectId, kamSubjectId : e.subjectId, className: e.className, classId: e.classId, sectionId: e.sectionId, sectionName: e.sectionName };
          //   });

          //   setsubjectsData(dataOPtion);

          // }
        }
      };

      getSubjectListfunc();
    }
  }, [localStorage]);

  const questionTypeSaveFuc = () => {
    // console.log(questionTypeName.length,"LLLLLLLLLLLLLLLL");
    if (questionTypeName.length > 0) {
      if (questionType) {
        if (
          questionType &&
          questionTypeName !== questionType.questionTypeName
        ) {
          updateQuestionTypeFuc();
        } else {
          toast.error(
            <Warning
              body={"Please edit something to update question type name!."}
            />
          );
        }
      } else {
        // createQuestionTypeFuc();
      }
    } else {
      toast.error(<Warning body={"Please enter question type name!."} />);
    }
  };

  const CreateAssignmentFunc = () => {
    // console.log(singleSubject, "XX CREATE ASSIGNMENT");
    // console.log(AsstTitle, "XX CREATE ASSIGNMENT");
    // console.log(AsstMarks, "XX CREATE ASSIGNMENT");

    if (singleSubject.length === 0) {
      toast.error(<Warning body={"Please select course"} />);
    } else if (AsstTitle === "") {
      toast.error(<Warning body={"Please enter exam name"} />);
    } else if (AsstMarks === "") {
      toast.error(<Warning body={"Please enter total marks"} />);
    } else {
      // alert();
      // CreateAssignmentNew();
      setasstAddBtn(true);
      ShowAddQuestions();
    }
  };

  const questionCancelFuc = (update) => {
    if (update) {
      setQuestionName(update.value);
      // getAllQuestionTypeFuc();
    } else {
      setEdit(false);
      setQTadd(false);
      setQTupdateBtn(false);
      setQuestionType("");
      setQuestionTypesOption("");
      setQuestionName("");
      // getAllQuestionTypeFuc();
      setDetailtedans("");
    }
  };

  const [editConfirmBtn, setEditConfirmBtn] = useState(false);
  const [createConfirmBtn, setCreateConfirmBtn] = useState(false);



  const createQuestionTypeData = async () => {
    const body = {
      questionTypeName: questionTypeName,
    };
    var res = await Teacher_Create_Question_Type(body);
    if (res) {
      if (res) {
        toast.success(res.message);
        
        setQTadd(false);
        setQTaddBtn(true);
        setCreateConfirmBtn(false);
        showQuestionType();
      } else {
        toast.error(res.error);
      }
    }
  };


  const createQuestionTypeDatanew = async () => {
   
    var response;

    const body = {
      questionTypeName: questionTypeName,
    };
     
    try {
      response = await Teacher_Create_Question_Type(body);
      
      if (response) {
       
          if (response.data) {
            toast.success(response.message);
            // console.log("datattatata",response)
            const data = response.data.map((x, index) => {
              return {
                id: index + 1,
                createdBy:x.createdBy,
                createdTime:x.createdTime,
                isActive:x.isActive,
                modifiedBy:x.modifiedBy,
                modifiedTime:x.modifiedTime,
                questionTypeId:x.questionTypeId,
                questionTypeName:x.questionTypeName,
              };
            });
            setQuestionTypeData1(data);
            setQTadd(false);
            setQTaddBtn(true);
            setCreateConfirmBtn(false);
            showQuestionType();
            // console.log("dfsdfsdfafa",data)
          }
       
      }
    } catch (err) {
      console.log(err);
    }
  };


  const selectQuestNames = (question) => {
    setQuestionName(question);
  };

  const onChangeQuestion = async (name, e) => {
    // console.log("ASDSAD", name, e);
    setQuestionValue(e);
    if (e !== "") {
    }
  };

  const onChangeAnswerType = async (name, e) => {
    setEditOption(false);
    setEditOptionText("");
    // console.log(name, e);
    setoption([]);
    setCorrectAns({});
    setTextAns("");
    setAnswerType(e);
  };

  const addoption = async () => {
    // console.log(optionText, "tttt");
    // console.log(option, "tttt");
    // console.log(optionText.trim(), "tttt");
    if (optionText !== "") {
      var count = 0;
      await option.map((data) => {
        if (optionText.trim() !== data.name) {
          count = count + 1;
        }
        return 1;
      });
      // console.log("count", option.length, count);
      if (option.length !== count) {
        toast.warning(<Warning body={"Already this option value was added"} />);
      } else {
        // console.log(optionText, "jjj");
        await setoption([
          ...option,
          {
            _id: count + 1,
            name: optionText.trim(),
            value: parse(optionText.trim()),
            label: parse(optionText.trim()),
          },
        ]);
        await setoptionText("");
        await setCorrectAns("");
      }
    }
  };

  const editOPtionFuc = async () => {
    // console.log(optionText, "tttt");
    const findIndex = await option.findIndex((x) => {
      if (String(x._id) === String(editOPtionText._id)) {
        return true;
      }
      return false;
    });
    if (findIndex >= 0) {
      if (optionText !== "") {
        var count = 0;
        await option.map((data) => {
          if (optionText.trim() !== data.name) {
            count = count + 1;
          }
          return 1;
        });
        // console.log("count", option.length, count);
        if (option.length !== count) {
          toast.warning(
            <Warning body={"Already this option value was added"} />
          );
        } else {
          // console.log(optionText, "jjj");

          if (
            String(option[findIndex].name).toLocaleLowerCase().trim() !==
            String(optionText).toLocaleLowerCase().trim()
          ) {
            let data = await option.map((x) => {
              if (String(x._id) === String(editOPtionText._id)) {
                return {
                  _id: x._id,
                  name: optionText.trim(),
                  value: parse(optionText.trim()),
                  label: parse(optionText.trim()),
                };
              } else {
                return {
                  _id: x._id,
                  name: x.name.trim(),
                  value: parse(x.name.trim()),
                  label: parse(x.name.trim()),
                };
              }
            });

            await setoption("");
            await setoptionText("");
            await setCorrectAns("");
            await setEditOptionText("");
            await setEditOption("");
            await setoption(data);
            // console.log(data, "tttt");
          } else {
            toast.warning(
              <Warning body={"Edit something to update the option!"} />
            );
          }
        }
      }
    } else {
      toast.warning(<Warning body={"Select vaild option text!"} />);
    }
  };

  const onChangeFuc = (e) => {
    // console.log(e);
    if (editOPtion) {
      setoptionText(e);
    } else {
      setoptionText(e);
    }
  };



  const findTotMarksandLsd = (subjectId, AssignmentId) => {
    const findAssignmentBySubIdandAssId = AssignmentData.find(
      (assign) =>
        assign.data.katbookSubjectId === subjectId &&
        assign.data.assignmentId === AssignmentId
    );

    const totMarks = findAssignmentBySubIdandAssId?.data.totalMarks;
    const exDate = 
      findAssignmentBySubIdandAssId?.data.lsd.length > 5
        ? findAssignmentBySubIdandAssId?.data.lsd.slice(0, 10)
        : null;

    setAsstMarks(totMarks);
    setAsstDate(exDate);
    // console.log(
    //   findAssignmentBySubIdandAssId,
    //   subjectId,
    //   AssignmentId,
    //   AssignmentData,
    //   exDate,
    //   "AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA"
    // );
    // katbookSubjectId _id
    
  };

  return (
    <div onClick={() => disableOnOuterTouch()}>
      <div className="Navbar">
        <Header
          showProfile={showProfile}
          falseShowProfile={falseShowProfile}
          notofShowProfile={notofShowProfile}
          ShowNotifications={ShowNotifications}
          showNotification={showNotification}
          falseShowNotification={falseShowNotification}
        />
      </div>
      <div className={"Dash_home_QWI"}>
        <div
          className="myattendcard2w p-3"
          // style={{
          //   height: window.innerHeight - 117 + "px",
          //   overflowY: "auto",
          // }}
        >
          <KHeader
            title="Create Question"
            containerClassName={`marginTop-20`}
          />
          <div className="container-fluid px-5 py-3">
            {/* Create Assignment Component Starts With Responsive */}
            <div className="d-flex flex-column flex-md-row">
              <KDropdown
                containerClassName="w-100"
                label="Select Subject "
                isRequired={true}
                placeholder="Select Subject"
                value={singleSubject}
                options={subjectsData}
                onChange={(e) => {
                  setsingleSubject(e);
                  setsingleSubject1(e.kamSubjectId);
                  showAssignment(e.kamSubjectId,e.sectionId,e.classId);
                  showExistingAssignment(e.kamSubjectId,e.sectionId,e.classId);
                  // setQTupdateBtn(true);
                  // setQTadd(true);
                  setsingleAssignment("");
                  setAsstMarks([])
                  setAsstDate("");
                  setTable(false);
                  EmptyAssigtCreateDataNew()
                }}
               
              /> 
              
              
              <KDropdown
                containerClassName="pl-md-3 pl-0 pt-2 pt-md-0 w-100"
                label="Assignment Name"
                isDisabled={!singleSubject}
                isRequired={true}
                placeholder="Select Assignment"
                value={singleAssignment}
                options={AssignmentData}
               
                onChange={(e) => {
                  showQuestionAndAnswerTable(e.value);
                  setsingleAssignment(e);
                  setAssignmentDataId(e.value);
                  showQuestionType(e);
                  // findTotMarksandLsd(singleSubject?.value, e.value);
                  findTotMarksandLsd(singleSubject?.data, e?.value);
                  setTable(e);
                  setClearData(true);
                  setShowScreen(false);
                  setsingleExitAssignment("");
                }}
              />
              <KInput
                label="Total Marks"
                isRequired={true}
                placeholder="Enter Total Marks"
                containerClassName={`pl-md-3 pl-0 pt-2 pt-md-0 ${
                  isBigScreen ? "w-100" : "w-100"
                }`}
                inputClassName="w-100"
                type="number"
                // isDisabled={true}
                disabled={!singleSubject || !singleAssignment} 
                value={AsstMarks}
               
                // onChange={setAsstMarks}
                toast={toast}
                Warning={Warning}
              />
              <KInput
                placeholder={"dd--mm--yyyy"}
                label="Last Date of Submission"
                containerClassName="pl-md-3 pl-0 pt-2 pt-md-0 w-100"
                isRequired={true}
                // isDisabled={true}
                disabled={!singleSubject || !singleAssignment || !AsstMarks} 
                value={AsstDate}
                // onChange={(e) => {
                //   getAllQuestionsFuc(e);
                // }}
                type="date"
              />

              {clearData && (
                <div
                  className={`${
                    asstAddBtn ? "w-100" : "w-100"
                  } pt-3 pt-md-0 d-flex align-items-end pl-0 pl-md-3 cursor-pointer`}
                >
                  <KButton
                    onClick={() => {
                      CreateQuestionDataClear();
                      setClearData(false);

                    }}
                    value="Clear"
                    className={`px-3 py-2 ${isBigScreen ? "w-auto" : "w-100"}`}
                    btnColor="red"
                  />
                </div>
              )}

              {asstAddBtn ? (
                <div className="w-100 pt-3 pt-md-0 d-flex align-items-end pl-0 pl-md-3 cursor-pointer">
                  <KButton
                    onClick={() => {
                      EmptyAssigtCreateData();
                    }}
                    value="CANCEL"
                    className={`px-3 py-2 ${isBigScreen ? "w-auto" : "w-100"}`}
                    isDisabled={false}
                    btnColor="red"
                  />
                </div>
              ) : (
                <div className="w-100 pt-3 pt-md-0 d-flex align-items-end pl-0 pl-md-3 cursor-pointer"></div>
              )}
            </div>
          </div>

          {table && (
            <div>
              <div
                className={`${
                  asstAddBtn ? "" : "w-100"
                } pt-3 pt-md-0 d-flex align-items-end justify-content-end pr-5 pl-md-1 cursor-pointer`}
              >
              
               <h5 className="mr-3">
                  Total Marks : {markValidation === 0 ? 0 : markValidation}
              </h5>
                <KButton
                  onClick={() => {
                    // CreateAssignmentFunc();
                    setShowScreen({
                      show: true,
                      Type: "",
                    });
                  }}
                  
                  isDisabled={
                    questionTable.length === 0 || questionTable.filter(m => m.Posted === "Not Posted").length > 0
                      ? false
                      : true
                  }

                  // isDisabled={questionTable.filter(m => m.Posted === "Not Posted").length > 0 ? false : true  }
                  
                  // isDisabled={questionTable.filter(m => m.Question !== "").length}
                  value="Create Question"
                  className={`px-3 py-2 ${isBigScreen ? "w-auto" : "w-100"}`}
                  btnColor="green"
                />
              </div>

              <div
                className="mx-5 my-3"
                style={{ textAlign: "center", border: "0px solid #dadbdd" }}
              >
                <CSmartTable
                  items={questionTable}
                  columnFilter
                  columnSorter
                  itemsPerPage={5}
                  itemsPerPageSelect={questionTable.length > 5}
                  pagination
                  columns={columns_questionandAnswer}
                  scopedColumns={{
                    // Question: (item) => <td>{parse(item.Question)}</td>,
                    
                    Question: (item) => <td className="textalign">
                      <span><b>Question :</b>{parse(item.Question)}</span>
                    {/* {parse(item.Question)} */}
                            <div>
          
                              {(
                                item.answerType === "RadioButton" && 
                                <span className="optionName"> 
                                
                                {parse(item?.option ?? "")}
                              </span>
          
                              )}
                            </div>
                            <span><b>Answer :</b> {parse(item.Answer)}</span>
                 </td>,
                   
                   
                    // Answer: (item) => <td className="textalign">{parse(item.Answer)}</td>,
                    
                    
                    show_details:(items) => (
                      <td className="gapflex">
                        {/* <button
                          type="button"
                          className="btn btn-primary btn-sm addblue mr-2 ml-2"
                          title="Edit"
                          // onClick={updateQuestionandAnswer(item)}
                          // onClick={QuestionandAnswer_Update_Function(item.value)}
                          
                        > 
                          <i className="fa fa-edit" />
                        </button> */}
                        {/* {JSON.stringify(items)} */}
                        <button
                          type="button"
                          className="btn btn-danger btn-sm cancered red-color"
                          title="Delete"
                          // Review delete btn
                          onClick={() => {
                            setVisible(true);
                            setQuestionCreationDataId({
                              questionCreationId:items.questionCreationId
                            })
                            // onDelete(items.questionCreationId);
                          }}
                            disabled={
                            questionTable.filter(m => m.Posted !== "Posted").length > 0
                              ? false
                              : true
                          }
                        >
                        
                          <i className="fa fa-trash-o" />
                        </button>
                      </td>
                    ),
                  }}
                  tableProps={{ hover: true, responsive: true }}
                />
                <CModal
                      alignment="center"
                      visible={visible}
                      onClose={() => setVisible(false)}
                      className="ModalHeight"
                    >
                <div
                  style={{
                    backgroundColor: "white",
                    padding: "10px 30px 30px 30px",
                    borderRadius:"5px",
                  }}
                >
                  <div style={{ padding: "25px" }}>
                    <div className="d-flex justify-content-center mb-4">
                      <span
                        style={{
                          fontSize: "18px",
                          color: "black",
                          fontWeight: "500",
                          height: "auto",
                        }}
                      >
                        Are you sure want to Delete ?
                      </span>
                    </div>
                    <div className="d-flex justify-content-around">
                      <KButton
                        value="Yes"
                        
                        onClick={() => {
                          // CreatePostAssign();
                          onDelete(questionCreationDataId.questionCreationId);
                          setVisible(false);
                         
                          // CancelPostAssign();
                        }}
                        

                        btnColor={"green"}
                        className={"px-4 py-1 ButtonSize"}
                      />
                      <KButton
                        value="No"
                        onClick={() => {
                          // PostCancelQuestion();
                          setVisible(false);
                        }}
                        className={"px-4 py-1 ButtonSize"}
                        btnColor={"red"}
                      />
                    </div>
                  </div>
                </div>
                </CModal>
              </div>
            </div>
          )}
          {/* Create Assignment Component Ends */}
              {showScreen.show && (
                <React.Fragment>
                  {/* Add Questions Starts With Responsive */}
                  <br />
                  <KHeader title="Add Questions" />
                  <div className="container-fluid px-5 py-3">
                    <div className="d-flex flex-column flex-md-row">
                      <KButton
                        value="Existing Question Bank"
                        className={`px-6 py-3 mr-3  ${
                          isBigScreen ? "w-25" : "w-100"
                        }`}
                        onClick={() => {
                          showRealExistingQuestionBank();
                          setExistingquestionTable([]);
                          setsingleExitAssignment([])
                          setTableQuestionBank(false)
                          setIsError(false);
                        }}
                        isDisabled={true}
                      />

                      <KButton
                        value="Existing Assignments"
                        
                        className={`px-6 py-3 mr-3  ${
                          isBigScreen ? "w-25" : "w-100 mt-2"
                        }`}
                        onClick={() => {
                          showExistingQuestionBank();
                          setIsError(false);
                        }}
                        // isDisabled={true}
                      />
                       
                      <KButton
                        value="Create New Question Bank"
                        className={`px-6 py-3 ${
                          isBigScreen ? "w-25" : "w-100 mt-2"
                        }`}
                        // isDisabled={false}
                        onClick={() => {
                          showNewQuestionBank();
                          setIsError(false);
                          setTable(false);
                          // console.log(ExamsData, "CREATE NEW EXAMS");
                        }}
                      />
                    </div>
                    {showScreen.Type == NEW_QUESTION_BANK_CODE && (
                      <React.Fragment>
                        {/* Creation Of Question Header */}

                        <div className="d-flex flex-column flex-md-row marginTop-20 mb-4">
                          {!edit && !QTadd && (
                            <KDropdown
                              containerClassName="w-25"
                              label="Select Question Type"
                              isRequired={true}
                              edit={questionType ? true : false}
                              value={questionType}
                              options={questionTypeData}
                              placeholder="Select Question Type"
                              labelStyle={{
                                fontSize: "20px",
                                marginBottom: "15px",
                              }}
                              onChange={(e) => {
                                // setUpdateData(data);
                                // console.log(e.value, "oooooooooooooooooo");
                                setQuestionType(e);
                                setQTupdateBtn(true);
                                setQTaddBtn(false);
                                setQuestionType(e);
                                // setquesAns("");
                              }}
                            />
                          )}
                          {(edit || QTadd) && (
                            <KInput
                              label="Question Type Name"
                              isRequired={true}
                              placeholder="Enter the Question Type  Name"
                              // width={"300px"}
                              containerClassName="pl-0 pt-2 pt-md-0 w-25"
                              value={questionTypeName}
                              onChange={(e) => {
                                selectQuestNames(e);
                                setQuestionName(e);
                              }}
                              SaveData={() => {
                                questionTypeSaveFuc();
                              }}
                            />
                          )}
                          <div className="w-100 pt-3 pt-md-0 d-flex align-items-end pl-0 pl-md-3 cursor-pointer">
                            {QTaddBtn && (
                              <KButton
                                onClick={(e) => {
                                  questionCancelFuc();
                                  setCreateConfirmBtn(true);
                                  setQTaddBtn(false);
                                  setQTadd(true);
                                }}
                                value={"ADD"}
                                className={`create px-3 py-2 ${
                                  isBigScreen ? "w-auto" : "w-100"
                                }`}
                                isDisabled={false}
                              />
                            )}
                            {createConfirmBtn && (
                              <KButton
                                onClick={() => {
                                  createQuestionTypeData();
                                }}
                                value={"CREATE"}
                                className={`create px-3 py-2 ${
                                  isBigScreen ? "w-auto" : "w-100"
                                }` }
                                isDisabled={false}
                              />
                            )}
                            {createConfirmBtn && (
                              <KButton
                                onClick={() => {
                                  questionCancelFuc();
                                  setCreateConfirmBtn(false);
                                  setQTaddBtn(true);
                                }}
                                value="CANCEL"
                                className={`ml-3 px-3 py-2 ${
                                  isBigScreen ? "w-auto" : "w-100"
                                }`}
                                isDisabled={false}
                                btnColor="red"
                                txtColor="#fff"
                              />
                            )}

                            {QTupdateBtn && (
                              <KButton
                                onClick={(e) => {
                                  questionCancelFuc(questionType);
                                  setQTadd(true);
                                  setQTupdateBtn(false);
                                  setEditConfirmBtn(true);
                                  updateQuestionType(questionType);
                                }}
                                value={"UPDATE"}
                                className={`px-3 py-2 ${
                                  isBigScreen ? "w-auto" : "w-100"
                                }`}
                                isDisabled={false}
                              />
                            )}
                            {editConfirmBtn && (
                              <KButton
                                onClick={() => {
                                  updateQuestionTypeFuc(questionType.value);
                                  setEditConfirmBtn(false);
                                  setQTaddBtn(true);
                                }}
                                value={"CONFIRM"}
                                className={`create px-3 py-2 ${
                                  isBigScreen ? "w-auto" : "w-100"
                                }`}
                                isDisabled={false}
                              />
                            )}

                            {editConfirmBtn && (
                              <KButton
                                onClick={() => {
                                  questionCancelFuc();
                                  setEditConfirmBtn(false);
                                  setQTaddBtn(true);
                                }}
                                value="CANCEL"
                                className={`ml-3 px-3 py-2 ${
                                  isBigScreen ? "w-auto" : "w-100"
                                }`}
                                isDisabled={false}
                                btnColor="red"
                                txtColor="#fff"
                              />
                            )}
                          </div>
                        </div>
                        <div>
                         {/* <p>Question total marks :{markValidation}<br /> {Quesmarks} </p>   */}
                        {/* {JSON.stringify(questionType.questionTypeName)} */}
                        <KInput
                            label="Marks"
                            isRequired={true}
                            placeholder="Enter Marks"
                            containerClassName={`pl-0 pt-2 pt-md-0 w-25`}
                            labelClassName={"width-maxContent"}
                            inputClassName="w-25"
                            type="number"
                            // onKeyDown={(e) => {
                            //   if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                            //     e.preventDefault(); // Prevents arrow key scroll
                            //   }
                            // }}
                            onWheel={(e) => e.preventDefault()}
                            // isDisabled={!questionType || typeof questionType !== "object" || Object.keys(questionType).length === 0}
                            
                            value={Quesmarks}
                            onChange={(value) =>{
                             
                              // console.log(Number(value),"marrkssum");
                              // console.log(Number(markValidation),"marrkssum");
                              // console.log(Number(value) + Number(markValidation),"marrkssum");

                              if(Number(value) + Number(markValidation) > 0 && Number(value) + Number(markValidation) <= AsstMarks){
                                setIsError(false); 
                                setQuesmarks(value);
                                // setcreateQuesBtn(true);
                              } else {
                                setIsError(true);
                                //setcreateQuesBtn(false);
                                
                              }
                             
                              // if (Number(value) >= Number(AsstMarks) && Number(value) >= Number(markValidation) 
                              //     || Number(value) >= Number(markValidation) ) {
                              //   setIsError(true);
                              // } else {
                              //   setIsError(false); 
                              //   setQuesmarks(value);

                              // }
                            }}

                            // toast={toast}
                            // Warning={Warning}
                          />
                           {isError ? (
                              <span className="error-message">
                                Please enter marks less than or equal to Total Mark
                              </span>
                            ) : (
                              ""
                            )}
                        </div>
                        
                        {/* <div className="d-flex flex-row justify-content-between">
                          <div className="d-flex flex-row">
                            <div className="d-flex align-items-end mb-2">
                              <IoMdArrowRoundBack
                                className="cursor-pointer"
                                onClick={() => ResetToDefaultScreen()}
                                size={25}
                              />
                            </div>
                            <KHeader title="New Question Bank" />
                          </div>
                          <div className="d-flex align-items-end mb-2">
                            <KButton value="Create" className={`px-3 py-1`} />
                          </div>
                        </div> */}
                        {/* 
      Creation Of Question Header Ends
    */}
                        <div className="d-flex flex-row justify-content-between my-3">
                          <KRequiredLabel
                            title="Enter Question"
                            className="h5 mt-3"
                            style={{
                              display: "inline-block",
                              alignSelf: "flex-end",
                            }}
                          />
                        </div>
                        <KEditor 
                          className={"TEST"}
                          height={isBigScreen ? 250 : 450}
                          value={QuestionValue}
                          onChange={setQuestionValue}
                          handleEditorChange={(content) => {
                            onChangeQuestion("Question", content);
                          }}
                        />
                        <KDropdown
                          labelStyle={{
                            fontSize: "20px",
                            marginBottom: "15px",
                          }}
                          containerClassName={`marginTop-20 ${
                            isBigScreen ? "w-25" : "w-100"
                          }`}
                          label="Answer Type"
                          isRequired={true}
                          placeholder="Select Answer Type"
                          options={optionsAns}
                          value={AnswerType}
                          onChange={(e) => onChangeAnswerType("AnswerTypes", e)}
                          isDisabled={QuestionValue ? false : true || !isError}
                        />

                        {AnswerType && AnswerType.value === "RadioButton" && (
                          <>
                            <Row
                              style={{
                                marginTop: "17px",
                                color: "black",
                              }}
                            >
                              <Col sm="10" md="10" lg="7">
                                <div
                                  style={{ marginBottom: "20px", color: "black" }}
                                >
                                  <KRequiredLabel
                                    title="Options"
                                    className="h5 mt-3"
                                    style={{
                                      display: "inline-block",
                                      alignSelf: "flex-end",
                                    }}
                                  />
                                </div>
                                {/* <CInput
                                  style={{ marginTop: '8px' }}
                                  onChange={(e) => {
                                    onChangeFuc(e);
                                  }}
                                  value={optionText}
                                  placeholder={"Options"}
                                /> */}
                                <KEditor
                                  height={isBigScreen ? 250 : 450}
                                  value={optionText}
                                  handleEditorChange={(content) => {
                                    onChangeFuc(content);
                                  }}
                                />
                              </Col>

                              <Col sm="2" md="2" lg="4">
                                <KButton
                                  width={60}
                                  height={50}
                                  value={editOPtion ? "UPDATE" : "ADD"}
                                  isDisabled={optionText === ''}
                                  className={`px-3 py-2 ${
                                    isBigScreen ? "w-auto" : "w-100"
                                  }`}
                                  style={{
                                    position: "absolute",
                                    bottom: "0px",
                                    marginBottom: "20px",
                                  }}
                                  onClick={() => {
                                    if (optionText) {
                                      if (editOPtion) {
                                        editOPtionFuc();
                                      } else {
                                        addoption();
                                      }
                                    } else {
                                      toast.warning(
                                        <Warning body={"Enter Your Option Name"} />
                                      );
                                    }
                                  }}
                                />
                              </Col>
                            </Row>
                          </>
                        )}

                        {option.length !== 0 &&
                          AnswerType.value === "RadioButton" && (
                            <Row
                              style={{
                                marginTop: "17px",
                                color: "black",
                                paddingLeft: "18px",
                              }}
                            >
                              <Col
                                sm="12"
                                md="6"
                                lg="3"
                                style={{ marginTop: "15px", marginBottom: "15px" }}
                              >
                                {/* <div style={{ marginBottom: '10px', color: 'black' }}>
                                <h6 style={{ fontWeight: 'bold' }}>{name}<span className="text-danger">{star}</span></h6>
                            </div> */}
                                {/* <KRequiredLabel
                                  title="Correct Answers in Options"
                                  className="h5 mt-3"
                                  style={{
                                    display: "inline-block",
                                    alignSelf: "flex-end"
                                  }}
                                /> */}
                                <KDropdown
                                  label={"Correct Answers in Options *"}
                                  options={option}
                                  // value={correctAns}
                                  value={correctAns}
                                  onChange={(e) => {
                                    setCorrectAns(e);
                                    setcreateQuesBtn(true);
                                    // console.log(e, "WWWWWWWWWWWWWW");
                                  }}
                                  placeholder={"Select correct answer"}
                                />
                              </Col>
                              <Col sm="2" md="2" lg="2">
                                {!editOPtion ? (
                                  <KButton
                                    width={60}
                                    height={50}
                                    className="btn btn-info"
                                    style={{
                                      position: "absolute",
                                      bottom: "0px",
                                      marginBottom: "20px",
                                    }}
                                    onClick={() => {
                                      if (correctAns && correctAns._id) {
                                        // console.log(correctAns);
                                        setEditOption(true);
                                        setEditOptionText(correctAns);
                                        setoptionText(correctAns.name);
                                      } else {
                                        toast.warning(
                                          <Warning
                                            body={
                                              "Please edit an option which your are edit!."
                                            }
                                          />
                                        );
                                      }
                                    }}
                                    value="EDIT"
                                  />
                                ) : (
                                  <KButton
                                    width={60}
                                    height={50}
                                    className="btn btn-danger"
                                    style={{
                                      position: "absolute",
                                      bottom: "0px",
                                      marginBottom: "20px",
                                    }}
                                    onClick={() => {
                                      setEditOption(false);
                                      setEditOptionText("");
                                      setoptionText("");
                                    }}
                                    value="CANCEL"
                                  />
                                )}
                              </Col>
                            </Row>
                          )}

                        {AnswerType &&
                        (AnswerType.value === "TextBox" ||
                          AnswerType.value === "TextArea") ? (
                          <>
                            <KRequiredLabel
                              title="Correct Answer"
                              className="h5 marginTop-20"
                            />
                            <KEditor
                              height={isBigScreen ? 250 : 450}
                              value={textAns || quesAns}
                              handleEditorChange={(content) => {
                                let maxCharLimit = AnswerType.value === "TextBox" ? 250 : 500;
                                if (content.length <= maxCharLimit) {
                                  setquesAns(content);
                                  setcreateQuesBtn(true);
                                }else{
                                  toast.error("Exceeded character limit for TextBox!");
                                }
                                
                              }}

                            />

                             <p>{AnswerType.value === "TextBox" ? "250 characters only" : "500 characters only" } 
                             {/* 
                             {AnswerType.value === "TextArea" ? 250 : 500 - (textAns || quesAns).length} characters remaining} */}
                             </p>

                          </>
                        ) : null}

                        <div
                          className="d-flex flex-column flex-md-row mt-3"
                          style={{
                            paddingTop: "30px",
                            paddingBottom: "100px",
                            float: "right",
                          }}
                        >
                       { createQuesBtn ?  
                       <KButton
                              value="SAVE"
                              ref={saveButtonRef}
                              onClick={() => {
                                setTable(true);
                                setShowScreen(false);
                                setcreateQuesBtn(false);
                                Create_QuestionandAnswers();
                                // pagetop();
                                // showQuestionAndAnswerTable(AssignmentData.value);
                                setClearData(false);
                                // QuestionandAnswer_Update_Function(questionType.value)
                              }}
                              isDisabled={!AnswerType || !Quesmarks || isError }
                             
                              btnColor="green"
                              className="px-4 py-2"
                            /> :   <KButton
                            value="SAVE"
                            isDisabled
                            btnColor={"green"} 
                            className={`px-4 py-2 `}
                          /> }
                          <KButton
                            onClick={() => {
                              setTable(true);
                              setShowScreen({ show: false, Type: "" });
                              setQuestionType("");
                              setQuestionValue("");
                              setQuesmarks("");
                              setCorrectAns("");
                              setTextAns("");
                              setquesAns("");
                              setAnswerType({});
                            }}
                            value="CANCEL"
                            className={`px-4 py-2 ${isBigScreen ? "ml-3" : "mt-2"}`}
                            btnColor={"red"}
                          />
                        </div>
                      </React.Fragment>
                    )}
                  </div>

                  {showScreen.Type == EXISTING_QUESTION_BANK_CODE && (
                    <React.Fragment>
                      {/* 
    Creation Of Question Header
    */}

    <div className="container-fluid px-5 py-3">
    <div className="d-flex flex-column flex-md-row">
                        <KDropdown
                          containerClassName={`pl-md-3 pl-0 pt-2 pt-md-0 ${
                            isBigScreen ? "w-25" : "w-100"
                          }`}
                          label="Units"
                          isRequired={true}
                          placeholder="Select Units"
                          options={unitSelect}
                          onChange={() => {
                            // setTableQuestionBank(true);
                          }}
                        />
                         <KDropdown
                          containerClassName={`pl-md-3 pl-0 pt-2 pt-md-0 ${
                            isBigScreen ? "w-25" : "w-100"
                          }`}
                          label="Select Question Type"
                          isRequired={true}
                          placeholder="Select Question Type"
                          options={SelectQType}
                          isDisabled={!unitSelect}
                          onChange={() => {
                            setTableQuestionBank(true);
                          }}
                        />
                      </div>

                      {tableQuestionBank && (
                        <div className="mt-5">
                          <h4>Question and Answer Details</h4>
                          <div
                            className="mx-5 my-3"
                            style={{ textAlign: "center", border: "1px solid #dadbdd" }}
                          >
                            
                            <CSmartTable
                              columns={ExistingQuestion_BankTable}
                              columnFilter
                              columnSorter
                              items={unitData}
                              itemsPerPage={5}
                              itemsPerPageSelect={unitData.length > 5}
                              pagination
                              selectedRows={selectedRows}
                              onSelectedItemsChange={(item) => handleRowClick(item)}
                              checked={{
                                checked: (item) => isRowSelected(item.questionCreationId),
                                // unchecked: (item) => !isRowSelected(item.questionCreationId),
                              }}
                              // checked={{
                              //   checked: (item) => isRowSelected(item.questionCreationId),
                              // }}
                              scopedColumns={{
                                show_details: (item) => {
                                  return (
                                    <td className="gapflex">
                                      <button
                                        type="button"
                                        className="btn btn-primary btn-sm addblue"
                                        title="Edit"
                                      >
                                        <i className="fa fa-edit" />
                                      </button>
                                      <button
                                        type="button"
                                        className="btn btn-danger btn-sm cancered"
                                        title="Delete"
                                      >
                                        <i className="fa fa-trash-o" />
                                      </button>
                                    </td>
                                  );
                                },
                              }}
                              tableProps={{ hover: true, responsive: true }}
                            />
                          </div>

                          <div className="d-flex flex-column justify-content-end flex-md-row pr-5 pt-3">
                            <KButton
                              value="Save"
                              btnColor={"green"}
                              className={`px-3 py-1 ButtonSize  ${
                                isBigScreen ? "ml-3" : "mt-2 ml-4"
                              }`}
                              onClick={() => {
                                notify();
                              }}
                            />
                            {/* <KButton
                              value="Delete"
                              className={`px-3 py-1 ButtonSize ${
                                isBigScreen ? "ml-3" : "mt-2 ml-4"
                              }`}
                              btnColor={"red"}
                            /> */}
                          </div>
                        </div>
                      )}
    </div>
                    

                    
                    </React.Fragment>
                  )}
                  {showScreen.Type == EXISTING_Assignment && (
                    <React.Fragment>
                      {/* 
      Creation Of Question Header
    */}

                     <div className="container-fluid px-5 py-3"> 
                      <div className="d-flex flex-column flex-md-row marginTop-20">
                       
                                <KDropdown
                                    containerClassName={` pl-0 pt-2 pt-md-0 ${
                                      isBigScreen ? "w-25" : "w-100"
                                    }`}
                                    label="Select the Assignments "
                                    isRequired={true}
                                    placeholder="Select the Assignments"
                                    value={singleExitAssignment}
                                    options={AssignmentDataExit}
                                  
                                    onChange={(e) => {
                                      // AssignmentSelectChange(e);
                                      setsingleExitAssignment(e);
                                      setsingleSubjectExit(e.data.katbookSubjectId)

                                      showExitQuestionAndAnswerTable(e.value);
                                      setquestionForAssign(true);
                                      setIsEditing(false);
                                      setTextValue('');
                                      //setTable(false);
                                    }}
                                  />
                        {/* {dropYear ? (
                          <>
                            {" "}
                            <KButton
                              value="2019-2020"
                              className={`px-6 py-2 mr-3  ${
                                isBigScreen ? "w-25" : "w-100"
                              }`}
                            />
                          </>
                        ) : (
                          <>
                            <KDropdown
                              containerClassName={`pl-md-3 pl-0 pt-2 pt-md-0 ${
                                isBigScreen ? "w-25" : "w-100"
                              }`}
                              label="Select the Academic Year"
                              isRequired={true}
                              placeholder="Select the Academic Year"
                              options={acdamyear}
                              value={acadeYear}
                              onChange={(e) => {
                                acdamyearChane(e);
                              }}
                            />
                          </>
                        )}
                        <KDropdown
                          containerClassName={`pl-md-3 pl-0 pt-2 pt-md-0 ${
                            isBigScreen ? "w-25" : "w-100"
                          }`}
                          label="Select the Assignments"
                          isRequired={true}
                          placeholder="Select the Assignments"
                          value={singleExitAssignment}
                          options={AssignmentDataExit}
                         
                          onChange={(e) => {
                            // AssignmentSelectChange(e);
                            setsingleExitAssignment(e);
                            showExitQuestionAndAnswerTable(e.value);
                            setquestionForAssign(true);
                            setTable(false);
                          }}
                        /> */}
                      </div>

                      {questionForAssign && (
                        <div className="container-fluid px-5 py-3">
                        
                        <div className="">
                           <div className="mt-3 d-flex justify-content-between">
                                <h4>Question and Answer Details</h4>
                                <h5 className="">
                                    Total Assignment Marks : {markValidation === 0 ? 0 : markValidation} / {AsstMarks === 0 ? 0 : AsstMarks}
                                </h5>
                                
                                <button
                                  className="backBtn mb-2"
                                  onClick={() => {
                                    // setShowPosted(true);
                                    setquestionForAssign(false);
                                    setsingleExitAssignment("")
                                    setAcadeYear("");
                                  }}
                                > 
                                  Back
                                </button>
                               </div>  
                          <div
                            className=""
                            style={{ textAlign: "center", border: "0px solid #dadbdd" }}
                          >
                            <CSmartTable
                             key={reloadTable ? "table-reload" : "table-initial"}
                             ref={QuesRef}
                             columns={ExistingAssignmentTable}
                             columnFilter
                             columnSorter
                             selectable
                             items={existingquestionTable}
                             itemsPerPage={5}
                             itemsPerPageSelect={existingquestionTable.length > 5}
                             pagination
                             id={selectedRowsId}
                            //  selected={selectedRows}
                             onSelectedItemsChange={(item) => handleRowClick(item)}
                             checked={{
                               checked: (item) => isRowSelected(item.questionCreationId),
                             }}
                              
                              scopedColumns={{ 
                               
                                show_details: (item) => {
                                  return (
                                    <td className="gapflex">
                                      {/* {JSON.stringify(selectedRows)} */}
                                              <button
                                                type="button"
                                                className="btn btn-primary mr-1 btn-sm addblue"
                                                title="Edit"
                                                onClick={(event) =>{
                                                  handleEditClick(item);
                                                  setTextValue('');
                                                }}
                                                // value={}
                                                disabled={!selectedRows.filter(m => m.questionCreationId === item.questionCreationId).length > 0 ? false : true }
                                               
                                              >
                                               <i className="fa fa-edit" />
                                              </button>
                                            
                                      {/* existing ass */}
                                     
                                                  {/* <button
                                                          type="button"
                                                          className="btn btn-danger btn-sm cancered red-color"
                                                          title="Delete"
                                                          onClick={() => {
                                                            setVisibleE(true);
                                                            setQuestionCreationDataIdExit({
                                                              questionCreationId:item.questionCreationId
                                                            })
                                                          
                                                          }}
                                                                >
                                                    <i className="fa fa-trash-o" />
                                                  </button> */}
                                            </td>
                                          );
                                        },
                                         Question : (item) =>(<td className="textalign">
                                             
                                              <span><b>Question : </b>{parse(item.Question)}</span>
                                                  <div>
                        
                                                    {(
                                                      item.answerType === "RadioButton" && 
                                                      <span className="optionName"> 
                        
                                                      {parse(item.option)}
                                                    </span>
                        
                                                    )}
                                                </div>

                                              <span><b>Correct Answer :</b> {parse(item.correctAnswer)}</span>
                                              
                                         </td>),
                                            Marks: (item) => (
                                              <td>
                                                
                                                <div>
                                                  {editExQuestionId == item.questionCreationId  ? (
                                                    <>
                                                      <input
                                                        type="number"
                                                        className="numberWidth mb-2"
                                                        value={textValue}
                                                        onChange={handleTextAreaChange}
                                                      />
                                                      <button
                                                        type="button"
                                                        className="clickmeBtn mb-2 mr-1"
                                                        title="Save_Check"
                                                        onClick={(e) => {
                                                          setIsEditing(false);
                                                          setTextValue('');
                                                          setEditExQuestionId('');
                                                          e.preventDefault();
                                                          handleSaveClick(item.questionCreationId, textValue, selectedRows);
                                                         
                                                        }}
                                                        
                                                        disabled={textValue === '' || textValue < 1 }
                                                      >
                                                        <i className="fa fa-check" />
                                                      </button>
                                                      <button
                                                        type="button"
                                                        className="backBtn mb-2"
                                                        title="Cancel"
                                                        onClick={() => {
                                                          setIsEditing(false);
                                                          setTextValue('');
                                                          setEditExQuestionId('')
                                                        }}
                                                      >
                                                       <i className="fa fa-close" />
                                                      </button>
                                                    </>
                                                  ) : (
                                                    <>
                                                    <div>{ parse(item.Marks) }</div>
                                                    </>
                                                  )}
                                                 </div>
                                            
                                              </td>
                                            ),

                                        }}
                                       
                                        sorterValue={{ column: 'name', state: 'asc' }}
                                       
                                        tableProps={{
                                          striped: true,
                                          hover: true,
                                          responsive:true,
                                        }}
                            />
                                      {/* <div>Selected Rows: {JSON.stringify(selectedRows)}</div> */}
                                      <CModal
                                                alignment="center"
                                                visible={visibleE}
                                                onClose={() => setVisibleE(false)}
                                                className="ModalHeight"
                                              >
                                          <div
                                            style={{
                                              backgroundColor: "white",
                                              padding: "10px 30px 30px 30px",
                                              borderRadius:"5px",
                                            }}
                                          >
                                            <div style={{ padding: "25px" }}>
                                              <div className="d-flex justify-content-center mb-4">
                                                <span
                                                  style={{
                                                    fontSize: "18px",
                                                    color: "black",
                                                    fontWeight: "500",
                                                    height: "auto",
                                                  }}
                                                >
                                                  Are you sure want to Delete ?
                                                </span>
                                              </div>
                                              <div className="d-flex justify-content-around">
                                                <KButton
                                                  value="Yes"
                                                  
                                                  onClick={() => {
                                                    // CreatePostAssign();
                                                    onDeleteExit(questionCreationDataIdExit.questionCreationId);
                                                    setVisibleE(false);
                                                  
                                                    // CancelPostAssign();
                                                  }}
                                                  

                                                  btnColor={"green"}
                                                  className={"px-4 py-1 ButtonSize"}
                                                />
                                                <KButton
                                                  value="No"
                                                  onClick={() => {
                                                    // PostCancelQuestion();
                                                    setVisibleE(false);
                                                  }}
                                                  className={"px-4 py-1 ButtonSize"}
                                                  btnColor={"red"}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                      </CModal>
                          </div>

                          <div className="mt-3 d-flex justify-content-end">
                            {/* {JSON.stringify(singleAssignment)}                 */}
                            <KButton
                             value="Save" 
                             btnColor="green"
                             className={`px-3 py-1 ButtonSize ${isBigScreen ? "ml-3" : "mt-2 ml-4"}`}
                             onClick={() => {
                              //  showExistingAssignment()
                               Create_QuestionandAnswersExit();
                               setTable(true);
                               setSelectedRows([]);
                               setReloadTable((prevReloadTable) => !prevReloadTable);
                               
                             }}
                             
                             isDisabled={selectedRows.filter(m => m.questionCreationId).length > 0 ? false : true}
                                    
                            />
                            {/* <KButton
                              value="Delete"
                              className={`px-3 py-1 ButtonSize ${
                                isBigScreen ? "ml-3" : "mt-2 ml-4"
                              }`}
                              btnColor={"red"}
                            /> */}
                          </div>
                        </div>
                        
                        </div>
                      )}
    </div>
                    </React.Fragment>
                  )}
        
                  {/* Question & Answers with Responsive Starts */}

                  <div className="container-fluid px-3 py-3">
                    {question && (
                      <div>
                        <div className="d-flex flex-row justify-content-between align-items-center pb-2">
                          <KHeader
                            title="Question & Answers"
                            style={{
                              marginLeft: "0px !important",
                            }}
                          />
                          <KButton
                            value="Add"
                            onClick={() => {
                              setQuestion(false);
                              showNewQuestionBank();
                            }}
                            className={"height-fitContent px-4 py-2 "}
                          />
                        </div>

                        {getAllQues.map((items, index) => (
                          <div className="mx-5 my-3">
                            <div className="d-flex flex-row gap-3 align-items-center">
                              <div className="DivBox">
                                {/* Question 1 */}
                                {parse(items.question)}
                                {items.options.map((q, index) => (
                                  <div>{parse(q.option)}</div>
                                ))}
                                {/* {parse(items.options)} */}
                              </div>

                              <KInput
                                placeholder="Enter Marks"
                                type="text"
                                value={items.marks}
                              />
                              <div
                                style={{
                                  display: "flex",
                                  // paddingLeft: "145px",
                                  gap: "30px",
                                }}
                              >
                                <div className="borderCircleEdit">
                                  <AiOutlineEdit size="20" />
                                </div>

                                <div className="borderCircleDelete">
                                  <RiDeleteBin6Line size="20" />
                                </div>
                              </div>
                            </div>

                            <div className="DivBoxBig mt-3 mb-5">
                              {/* Answer 1 */}
                              {parse(items.correctAnswer)}
                            </div>
                          </div>
                        ))}
                      </div>
                    )}

                    {/* Question & Answers with Responsive Ends */}
                  </div>
                  {/* Add Questions Starts With Responsive Ends */}
                
                
                
                </React.Fragment>
              )}
        </div>
        
      </div>

      <Footer
        openForm={openForm}
        shoq={shoq}
        closeForm={closeForm}
        KpostIframe={KpostIframe}
      />
    </div>
  );
};

export default CreateQuestion;