import React, { useEffect, useState,useRef } from "react";
import Header from "./HeaderNavbar";
import Footer from "./Footer";
import KDropdown from "./common/KDropdown";
import KInput from "./common/KInput";
import KButton from "./common/KButton";
import KHeader from "./common/KHeader";
import useResponsive from "../../hooks/useResponsive";
import moment from "moment";
import Danger from "../components/Toastify/Danger";
import Warning from "../components/Toastify/Warning";
import Success from "../components/Toastify/Success";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from "react-toastify";
import html2pdf from 'html2pdf.js';
import { BiDownload } from "react-icons/bi";
import jsPDF from 'jspdf';
// import 'jspdf-autotable'; // Import the autotable module
// API Import
import {
  SessionPlan_MediumofEdu_API,
  SessionPlan_YearofAc_API,
  SessionPlan_stand_API,
  SessionPlan_session_API,
  SessionPlan_subject_API,
  SessionPlan_scheduleBtn_API,
  SessionPlan_scheduleBtnCompar_API,
  SessionPlan_scheduleConfirmBtn_API,
  ViewSessionPlan_RescheduleBtn_API,
  ViewSessionPlan_RescheduleConfirmBtn_API,
} from "../../services/Apiservices";
import { CFormLabel, CSmartTable } from "@coreui/react-pro";
import {
  CBreadcrumbItem,
  CBreadcrumb,
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CCol,
} from "@coreui/react";
// Function Start
const ViewSessionPlan = ({
  showProfile,
  falseShowProfile,
  notofShowProfile,
  ShowNotifications,
  showNotification,
  falseShowNotification,
  disableOnOuterTouch,
  shoq,
  KpostIframe,
  closeForm,
  openForm,
  goToMainRoutes
}) => {
  const { isBigScreen } = useResponsive();

  const [titleSession, setTitleSession] = useState(true);
  const [breadcrumSession, setBreadcrumSession] = useState(true);
  const [breadcrumSession1, setBreadcrumSession1] = useState(false);
  const [session1, setSession1] = useState(true);
  const [session2, setSession2] = useState(false);
  const [session3, setSession3] = useState(false);
  
  const SCREENS = {
    SCREEN1:"SCREEN1",
    SCREEN2:"SCREEN2",
    SCREEN3:"SCREEN3",
    
  }

  const[displayScreen,setDisplayScreen] = useState(SCREENS.SCREEN1)

  const [mediumofEduDataView, setMediumofEduDataView] = React.useState([]);
  const [mediumofEduDropView, setMediumofEduDropView] = React.useState("");

  const [yearofAccDataView, setYearofAccDataView] = React.useState([]);
  const [yearofAccDataDropView, setyearofAccDataDropView] = React.useState("");
  const [yearID, setYearID] = useState("");
  const [standardDataView, setStandardDataView] = React.useState([]);
  const [standardDataDropView, setstandardDataDropView] = React.useState("");

  const [sessionDataView, setSessionDataView] = React.useState([]);
  const [sessionDataDropView, setSessionDataDropView] = React.useState("");

  const [subjectsDataView, setSubjectsDataView] = React.useState([]);
  const [subjectsDataDropView, setSubjectsDataDropView] = React.useState("");

  const [scheduleDataView, setScheduleDataView] = useState([]);
  const [scheduleDataView1, setScheduleDataView1] = useState([]);
  
  const [scheduleDataCpmView, setScheduleDataCpmView] = useState([]);
  const [ViewBtn, setViewBtn] = useState([]);
  
  const [lastVariableIDDataView, setLastVariableIDDataView] = useState("");
  const [lastVariableIDDataView1, setLastVariableIDDataView1] = useState("");
  
  const [minDate, setMinDate] = useState(null);
  const [durationDataView,setDurationDataView]=useState('');
  const [reScheduleConfirmBtn,setReScheduleConfirmBtn] = useState([]);
  const[allId,setAllId]=useState([{
    katbookUnitID:"",katbookSessionID:"",katbookVariableID:"",
  }])
  const [allData, setAllData] = useState([{
    refInstID: "",
    refMediumID: "",
    refYearID: "",
    refVariableID:"",
    refSectionID:"",
    refStaffID: "",
    variableDetails:"",
    katbookSessionID:"",
    
    katbookUnitID:"",
    refSubjectID: "",
    katbookVariableID:"",
   
  }]);
  // console.log("sdfjlweuiw",allData)

  const [errorMessage, setErrorMessage] = useState("");
  const [visible, setVisible] = useState(false);
  const [visiblePrt, setVisiblePrt] = useState(false);
  
  const [error, setError] = useState(false);
  const [AsstDate, setAsstDate] = useState('');

  const LSDValidationView = (e) => {
    const TodayDate = moment().format("DD MMM YYYY");
    const EnterDate = moment(e).format("DD MMM YYYY");
    if (EnterDate >= TodayDate) {
      setAsstDate(e)
      setErrorMessage("");
    } else {
      setAsstDate('')
    }
  };

  // const DateValidation = (e) => {
  //   // const TodayDate = moment().format("DD MMM YYYY")
  //   const EnterDate = moment(e).format('DD MMM YYYY')
  //    if (EnterDate) {
  //     setAsstDate(e)
  //     setErrorMessage('')
  //   } else {
  //     setAsstDate('')
     
  //   }
  // }

  useEffect( () =>{
    const min_date = new Date(new Date());
      setMinDate(moment(min_date).format("YYYY-MM-DD"));
    // teacherViewList()
    ViewSessionPlan_MediumofEdu();
  },[]);
  const handleKey = (e) => {
    e.preventDefault();
    setErrorMessage('Keyboard input is not allowed');
  };

const [DurationDataRes ,setDurationDataRes] = useState([])

  const handleInputChange = (e,unitId,sessionId) => {

    const RemovePreviousValue = DurationDataRes.filter(y=>y.sessionId !== sessionId)
    setDurationDataRes([
      ...RemovePreviousValue,
      {
        value :e,
        unitId,
        sessionId,
      }
      // ,
      // {
      //   value:scheduleDataView.duration,
      //   unitId,
      //   sessionId
      // }
    ])
  };

  const handleRescheduleChange = (value,sessionId) => {
    const getRemovedObject = scheduleDataView.find(y=>y.katbookSessionID == sessionId)
    const RemovePreviousValue = scheduleDataView.filter(y=>y.katbookSessionID != sessionId)
    setScheduleDataView([
      ...RemovePreviousValue,
      {
        ...getRemovedObject,
        duration:value
      }
    ])
  }


  const ViewAllChapterTable = [
    {
      key: "Sno",
      label: "S.No",
      filter: true,
      sorter: true,
      _style: { width: "5%" },
    },
    {
      key: "Chapter",
      label: "Chapter / Unit Name	",
      filter: true,
      sorter: true,
      _style: { width: "30%" },
    },
    {
      key: "Session",
      label: "Session",
      filter: true,
      sorter: true,
      _style: { width: "20%" },
    },
    {
      key: "Duration",
      label: "Duration",
      _style: { width: "10%" },
      filter: true,
      sorter: true,
    },
    {
      key: "StartDate",
      label: "Start Date",
      _style: { width: "10%" },
      filter: true,
      sorter: true,
    },
    {
      key: "EndDate",
      label: "End Date",
      _style: { width: "10%" },
      filter: true,
      sorter: true,
    },
  ];
  
  const ViewChapterTable = [
    {
      key: "Sno",
      label: "S.No",
      filter: true,
      sorter: true,
      _style: { width: "5%" },
    },
    {
      key: "Chapter",
      label: "Chapter / Unit Name	",
      filter: true,
      sorter: true,
      _style: { width: "40%" },
    },
    {
      key: "Session",
      label: "Session",
      filter: true,
      sorter: true,
      _style: { width: "20%" },
    },
    {
      key: "Duration",
      label: "Duration",
      _style: { width: "15%" },
      filter: true,
      sorter: true,
    },
  ];

  const ClearFunction = () => {
    setMediumofEduDropView("");
    setyearofAccDataDropView("");
    setstandardDataDropView("");
    setSessionDataDropView("");
    setSubjectsDataDropView("");
    setDurationDataRes([]);
  };
  // API Function Calling
 

  const ViewSessionPlan_MediumofEdu = async () => {
    let response;
    setMediumofEduDataView([]);

    let body = {
      instID:
        localStorage.length > 0 && JSON.parse(localStorage.userCreds).refInstID,
    };
    // console.log("bodsdsds",body)
    try {
      response = await SessionPlan_MediumofEdu_API(body);

      if(response.status === "403"){
        goToMainRoutes()
      }

      if (response.status === "success") {
        toast.success(response.message);
        if (response.value) {
          // console.log("skdlfjskd111",response)
          const data = response.value.map((x, index) => {
            return {
              No: index + 1,
              value: x.mediumId,
              label: x.mediumName,
            };
          });
          setMediumofEduDataView(data);
        }
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const ViewSessionPlan_YearofAc_Fun = async (value) => {
    let response;
    setYearofAccDataView([]);

    let body = {
      refInstID:
        localStorage.length > 0 && JSON.parse(localStorage.userCreds).refInstID,
      refMediumID: value,
    };
    // console.log("jkdlkf", body);
    try {
      response = await SessionPlan_YearofAc_API(body);

      if (response.status === "success") {
        toast.success(response.message);
        if (response.value) {
          const data = response.value.map((x, index) => {
            return {
              No: index + 1,
              value: x.yearID,
              label: x.academicYear,
              yearID: x.yearID,
              fromDate: x.fromDate,
              toDate: x.toDate,
            };
          });
          setYearofAccDataView(data);
        }
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const ViewSessionPlan_Standard_Fun = async (value) => {
    let response;
    setStandardDataView([]);

    let body = {
      refInstID:
        localStorage.length > 0 && JSON.parse(localStorage.userCreds).refInstID,
      parentTier: 0,
    };
    //  console.log("jksd1225dlkf",body)
    try {
      response = await SessionPlan_stand_API(body);

      if (response.status === "success") {
        toast.success(response.message);
        if (response.value) {
          // console.log("body1232", response);
          const data = response.value.map((x, index) => {
            return {
              No: index + 1,
              value: x.variableID,
              label: x.tierValue,
              parentTier: x.parentTier,
              tierName: x.tierName,
              MediumID: x.refCategoryID,
            };
          });
          setStandardDataView(data);
        }
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const ViewSessionPlan_Session_Fun = async (value, MediumID) => {
    let response;
    setSessionDataView([]);

    let body = {
      refInstID:
        localStorage.length > 0 && JSON.parse(localStorage.userCreds).refInstID,
      refMediumID: MediumID,
      refVariableID: value,
    };
    //  console.log("jksd1225dlkf1111",body)
    try {
      response = await SessionPlan_session_API(body);

      if (response.status === "success") {
        toast.success(response.message);
        if (response.value) {
          // console.log("ssss", response);
          const data = response.value.map((x, index) => {
            return {
              No: index + 1,
              value: x.sectionID,
              label: x.sectionName,
              refMediumID: x.refMediumID,
              tierName: x.tierName,
              sectionID: x.sectionID,
              refYearId: x.refYearId,
              refVariableID: x.refVariableID,
            };
          });
          setSessionDataView(data);
          // console.log("12311", data);
        }
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const ViewSessionPlan_Subject_Fun = async (
    value,
    refMediumID,
    sectionID,
    refVariableID
  ) => {
    let response;
    setSubjectsDataView([]);

    let body = {
      refInstID:
        localStorage.length > 0 && JSON.parse(localStorage.userCreds).refInstID,
      refMediumID: refMediumID,
      refSectionID: sectionID,
      refStaffID:
        localStorage.length > 0 && JSON.parse(localStorage.userCreds).staffID,
      refVariableID: refVariableID,
      refYearID: yearID,
    };

    try {
      response = await SessionPlan_subject_API(body);

      if (response.status === "success") {
        toast.success(response.message);
        if (response.value) {
          // console.log("ssssssssxx", response);

          const data = response.value.map((x, index) => {
            return {
              No: index + 1,
              value: x.refSubjectID,
              label: x.subjectName,
              refInstID: x.refInstID,
              refMediumID: x.refMediumID,
              refYearID: x.refYearID,
              refVariableID: x.refVariableID,
              refSubjectID: x.refSubjectID,
              refStaffID: x.refStaffID,
              refSectionID: x.refSectionID,
              variableDetails:x.variableDetails,
              
            };
          });
          setSubjectsDataView(data);
          // console.log("dsff",data)
        }
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // ReSchedule button click API calling

  const ViewSessionPlan_ReSchedule_Fun = async (
    refMediumID,
    refSectionID,
    refVariableID,
    refSubjectID,
    refInstID,
    refYearID
  ) => {
    let response;
    setScheduleDataView([]);

    let body = {
      refInstID: refInstID,
      refMediumID: refMediumID,
      refSectionID: refSectionID,
      refStaffID:
        localStorage.length > 0 && JSON.parse(localStorage.userCreds).staffID,
      refVariableID: refVariableID,
      refYearID: refYearID,
      refSubjectID: refSubjectID,
    };

    try {
      response = await ViewSessionPlan_RescheduleBtn_API(body);

      if (response.status === "success") {
        toast.success(response.message);
        // console.log("jklsd12354jfk",response);
        if (response.value) {
         
          const ObjectKey =  Object.keys(response.value)

          let data = []
          
          for(let k=0;k<ObjectKey.length;k++){
            const singleKD = response.value[`${ObjectKey[k]}`].map((x, index) => {
              const newStartDate = new Date(x.estStartDate);
            const newEndDate = new Date(x.estEndDate);
              return {
                 No: index + 1,
                 id:x.id,
                 refInstID:x.refInstID,
                 refMediumID:x.refMediumID,
                 refYearID:x.refYearID,
                 refSubjectID:x.refSubjectID,
                 refStaffID:x.refStaffID,
                 refVariableID:x.refVariableID,
                 variableDetails:x.variableDetails,
                 katbookUnitID:x.katbookUnitID,
                 katbookSessionID: x.katbookSessionID,
                 lastVariableID:x.lastVariableID,
                 refSectionID:x.refSectionID,
                 katbookVariableID:x.katbookVariableID,
                 modifiedBy:x.modifiedBy,
                 StartDate: formatDate(newStartDate),
                 EndDate: formatDate(newEndDate),
                 duration:x.duration,
                 estEndDate:null,
                 estStartDate:AsstDate,
               };
             });
             data = [...data,...singleKD]
          }
         setScheduleDataView(data);
        //  console.log("dsfasasasasasadwesdfs",data)
         
         let value = data.length > 0 ? [data[0].katbookVariableID] : []; // Extracting the lastVariableID value from the first element of "data" array
          setLastVariableIDDataView(value);
          // console.log(value,"opiopi");
        }
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const RefreshPage = ()=>{
    window.location.reload();
 }
  
  // Reschedule btn confirm btn 
const ViewSessionPlan_ScheduleConfirmBtn_Fun = async (
  ) => {
    let response;
    setReScheduleConfirmBtn([]);
    let body = scheduleDataView.map(x=>({
      ...x,
      estStartDate: AsstDate , 
      // duration:DurationData,
    
    }))

  // console.log("ReScheduleConfirmBtn-Body",body)

    try {
      response = await ViewSessionPlan_RescheduleConfirmBtn_API(body);
  
      if (response.status === "success") {
        toast.success(<p>Reschedule Successfully</p>);
        if (response.value) {
          const data = response.value.map((x, index) => {
            return {
              No: index + 1,
             
            };
          });
          setReScheduleConfirmBtn(data);
         
        }
      } else {
        toast.error(response.value);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const ViewSessionPlan_ReScheduleCpm_Fun = async () => {
    let response;
    setScheduleDataCpmView([]);
    const id = lastVariableIDDataView;
    try {
      response = await SessionPlan_scheduleBtnCompar_API(id);
     
      if (response.success === true ) {
             
        if (response) {
         
          const data = response.variable.map((x, index) => {
          
            return {
              Sno: index + 1,
              Chapter:x.unitName,
              Session:x.sessionName,
              unitId:x.unitId,
              unitName:x.unitName,
              sessionId:x.sessionId,
              sessionName:x.sessionName,

            };
            
          });
         
          setScheduleDataCpmView(data);
          // console.log("sdfhksjfdhs",data)
        }
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.log(err);
    }
  };



// View Button Click Function

const formatDate = (date) => {
  const day = date.getDate().toString().padStart(2, '0');
  const monthNames = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();

  return `${day}-${month}-${year}`;
};


const ViewSessionPlan_Fun = async (
  refMediumID,
  refSectionID,
  refVariableID,
  refSubjectID,
  refInstID,
  refYearID
) => {
  let response;
  setScheduleDataView1([]);

  let body = {
    refInstID: refInstID,
    refMediumID: refMediumID,
    refSectionID: refSectionID,
    refStaffID:
      localStorage.length > 0 && JSON.parse(localStorage.userCreds).staffID,
    refVariableID: refVariableID,
    refYearID: refYearID,
    refSubjectID: refSubjectID,
  };

  try {
    response = await ViewSessionPlan_RescheduleBtn_API(body);

    if (response.status === "success") {
      toast.success(response.message);

      if (response.value) {
        const ObjectKey = Object.keys(response.value);
        let data = [];

        for (let k = 0; k < ObjectKey.length; k++) {
          const singleKD = response.value[ObjectKey[k]].map((x, index) => {
            const newStartDate = new Date(x.estStartDate);
            const newEndDate = new Date(x.estEndDate);

            const newData = {
             
              No: index + 1,
              id: x.id,
              refInstID: x.refInstID,
              refMediumID: x.refMediumID,
              refYearID: x.refYearID,
              refSubjectID: x.refSubjectID,
              katbookUnitID: x.katbookUnitID,
              katbookSessionID: x.katbookSessionID,
              lastVariableID: x.lastVariableID,
              refSectionID: x.refSectionID,
              katbookVariableID: x.katbookVariableID,
              Duration: x.duration,
              StartDate: formatDate(newStartDate),
              EndDate: formatDate(newEndDate),
            };

            data.push(newData);
            return newData;
          });

          data = [...data, ...singleKD];
        }

        setScheduleDataView1(data);
        // console.log("durationduration",data)
        if (data.length > 0) {
          const value = [data[0].katbookVariableID];
          setLastVariableIDDataView1(value);
        }
      }
    } else {
      toast.error(response.message);
    }
  } catch (err) {
    console.log(err);
  }
};

const ViewFun = async () => {
  let response;
  setViewBtn([]);
  const id = lastVariableIDDataView1;
  try {
    response = await SessionPlan_scheduleBtnCompar_API(id);
   
    if (response.success === true ) {
           
      if (response) {
       
        const data = response.variable.map((x, index) => {
        
          return {
            Sno: index + 1,
            Chapter:x.unitName,
            Session:x.sessionName,
            unitId:x.unitId,
            unitName:x.unitName,
            sessionId:x.sessionId,
            sessionName:x.sessionName,

          };
          
        });
       
        setViewBtn(data);
        // console.log("sdfhksjfdhs",data)
      }
    } else {
      toast.error(response.message);
    }
  } catch (err) {
    console.log(err);
  }
};

// Print Function

const handlePrint = () => {
  const tableRows = ViewBtn.map((data, index) => {
    const viewBtnItem = scheduleDataView1([index]);
    // console.log(viewBtnItem,"viewBtnItemviewBtnItem")
    return `
      <tr>
        <td>${index + 1}</td>
        <td>${data ? data.Chapter : ''}</td>
        <td>${data ? data.Session : ''}</td>
    
        <td>${viewBtnItem.Duration || ''}</td>
        <td>${viewBtnItem.StartDate || ''}</td>
        <td>${viewBtnItem.EndDate || ''}</td>
      </tr>
    `;
  }).join('');
  
  const content = `
    <html>
      <head>
        <style>
         
          table {
            border-collapse: collapse;
            width: 95%;
            margin: 0 auto;
          }
          th, td {
            border: 1px solid black;
            padding: 8px;
            text-align: left;
          }
        </style>
      </head>
      <body>
      <h3 style=" text-align: center;">View Session Plan Details </h3>
        <table>
          <tr>
            <th>Sno</th>
            <th>Chapter</th>
            <th>Session</th>
            <th>Duration</th>
            <th>StartDate</th>
            <th>EndDate</th>
            
           
          </tr>
          ${tableRows}
        </table>
      </body>
    </html>
  `;
  const printWindow = window.open('', '_blank');
  printWindow.document.open();
  printWindow.document.write(content);
  printWindow.document.close();

  // Wait for a short while to ensure content is rendered before printing
  setTimeout(() => {
    printWindow.print();
    printWindow.close();
  }, 1000);
  
};

// Download pdf 

// const handleDownload = async () => {
//   const tableRows = ViewBtn.map((data, index) => {
//     const viewBtnItem = scheduleDataView1[index];
//     return `
//       <tr>
//         <td>${index + 1}</td>
//         <td>${data ? data.Chapter : ''}</td>
//         <td>${data ? data.Session : ''}</td>
//         <td>${viewBtnItem.Duration || ''}</td>
//         <td>${viewBtnItem.StartDate || ''}</td>
//         <td>${viewBtnItem.EndDate || ''}</td>
//       </tr>
//     `;
//   }).join('');
  
//     console.log("tableRowstableRows",tableRows)
//   const content =
  
//  `
//    <html>
//       <head>
//         <style>
//           table {
//             border-collapse: collapse;
//             width: 95%;
//             margin: 0 auto;
//           }
//           th, td {
//             border: 1px solid black;
//             padding: 8px;
//             text-align: left;
//           }
//         </style>
//       </head>
//       <body>
//         <h3 style="text-align: center;">View Session Plan Details</h3>
//         <table>
//           <tr>
//             <th>Sno</th>
//             <th>Chapter</th>
//             <th>Session</th>
//             <th>Duration</th>
//             <th>StartDate</th>
//             <th>EndDate</th>
//           </tr>
//           ${tableRows}
//         </table>
//       </body>
//     </html>
//   `;

//   const pdfOptions = {
//     margin: 10,
//     filename: 'View_Session_Plan_Details.pdf',
//     image: { type: 'jpeg', quality: 0.98 },
//     html2canvas: { scale: 2 },
//     jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
//   };
//   document.getElementById("print-content").innerHTML = content;

//   try {
//     const pdf = await html2pdf(document.getElementById("print-content")).set(pdfOptions).outputPdf();
    
//     // console.log("pdfpdfpdfpdf",pdf);
//     // const blob = new Blob([pdf], { type: 'application/pdf' });
//     // const url = URL.createObjectURL(blob);
  
//     // const a = document.createElement('a');
//     // a.href = url;
//     // a.download = 'View_Session_Plan_Details.pdf';
//     // a.click();
//   } catch (error) {
//     console.error('Error generating PDF:', error);
//   }
// };

// const handleDownloadOld = () => {

//   const doc = new jsPDF(); // Create a new jsPDF instance

//   // Define your table data
//   const tableData = ViewBtn.map((data, index) => {
   
//     const viewBtnItem = scheduleDataView1.find(x => x.katbookSessionID === data.sessionId); 
    
//     return [ 

//       index + 1,
//       data ? data.Chapter : '',
//       data ? data.Session : '',
//       viewBtnItem.Duration || '-',
//       viewBtnItem.StartDate || '-',
//       viewBtnItem.EndDate || '-', 

//     ];

//   });

//   // Define column headers
//   const tableHeaders = [
//     'Sno',
//     'Chapter',
//     'Session',
//     'Duration',
//     'StartDate',
//     'EndDate',
//   ];

//   // Set the table width and column widths
//   const tableWidth = 190; // Adjust as needed
//   const columnWidths = [15, 30, 30, 30, 30, 30]; // Adjust as needed
  
//   doc.text(75,15,'View Session Plan Details'); // Centered text
  
//   doc.autoTable({
//     head: [tableHeaders],
//     body: tableData,
//     startY: 20, // Adjust the starting Y position
//     margin: { top: 10 },
//     theme:'grid',
//     headStyles: { 
//       fillColor: [60, 126, 183], 
//       textColor: [255, 255, 255], 
//       fontSize: 9, 
//       padding: 0, 
      
//     },
//     columnStyles: { 0: { cellWidth: 10 } }, // Adjust the first column width
//   });

//   // Save the PDF
//   doc.save('View_Session_Plan_Details.pdf');

// };

const [currentDates, setCurrentDate] = useState(new Date());
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDate(new Date());
    }, 86400000); // Update every 24 hours
    return () => {
      clearInterval(intervalId); // Cleanup the interval on unmount
    };
  }, []);

  const formatDates = (date) => {
    return date.toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

const handleDownload = () => {
    const doc = new jsPDF(); // Create a new jsPDF instance
    // Define your table data
    const tableData = ViewBtn.map((data, index) => {
      const viewBtnItem = scheduleDataView1.find(x => x.katbookSessionID === data.sessionId); 
   
      return [ 

        index + 1,
        data ? data.Chapter : '',
        data ? data.Session : '',
        viewBtnItem.Duration || '-',
        viewBtnItem.StartDate || '-',
        viewBtnItem.EndDate || '-', 
  
      ];
  
    });
    // Define column headers
    const tableHeaders = [
      'Sno',
      'Chapter',
      'Session',
      'Duration',
      'StartDate',
      'EndDate',
    ];
    // Set the table width and column widths
    const tableWidth = 190; // Adjust as needed
    const columnWidths = [15, 30, 30, 30, 30, 30]; // Adjust as needed
   // Add a new page to the PDF
    // doc.addPage();
    doc.setFontSize(12); // Set font size
    doc.text(45, 10, `${localStorage.length > 0 &&
      JSON.parse(localStorage.userCreds).institutionName.toUpperCase()}`);
    // Centered text
    doc.text(85, 18, `View Session Plan Details`);
    doc.text(15, 28, `Login by: ${localStorage.length > 0 && JSON.parse(localStorage.userCreds).adminName}`); // Centered text
    doc.text(150, 28, `Date: ${formatDate(currentDates)}`); // Centered text
    // Use the autotable method to generate the table
    doc.autoTable({
      head: [tableHeaders],
      body: tableData,
      styles: {
        fontSize: 8,
        font: 'helvetica',
        cellPadding: 2,
        minCellHeight: 2,
      },
      theme: "grid",
      headStyles: {
        fillColor: [16, 126, 183],
        textColor: [255, 255, 255],
        fontSize: 9,
        padding: 0,
      },
      startY: 30, // Adjust the starting Y position
      margin: { top: 10 },
      columnStyles: { 0: { cellWidth: 10 } }, // Adjust the first column width
    });

    // Save the PDF
    // doc.save(`${localStorage.length > 0 && JSON.parse(localStorage.userCreds).adminName}_${selectedOption.label}_${formatDate(currentDates)}.pdf`);
    // Save the PDF
     doc.save('View_Session_Plan_Details.pdf');

  };
  return (
    <div onClick={() => disableOnOuterTouch()}>
      <div id="print-content" 
        style={{
          // display :"none"
        }}
        >
        {/* <h1>Hello</h1> */}
      </div>
      <div className="Navbar">
        <Header
          showProfile={showProfile}
          falseShowProfile={falseShowProfile}
          notofShowProfile={notofShowProfile}
          ShowNotifications={ShowNotifications}
          showNotification={showNotification}
          falseShowNotification={falseShowNotification}
        />
      </div>
      <div className={"Dash_home_QWI"}>
        <div
          className="myattendcard2w p-3"
          style={{
            height: window.innerHeight - 117 + "px",
            overflowY: "auto",
          }}
        >
          {titleSession && (
            <KHeader
              title="View Session Plan "
              containerClassName={`marginTop-20`}
            />
          )}

          {breadcrumSession && (
            <div className="pl-4">
              {/* <CBreadcrumb>
                    <CBreadcrumbItem
                      active
                      onClick={() => {
                        setBreadcrumSession1(false);
                        setSession2(false);
                        
                      }}
                    >
                     Session Plan
                    </CBreadcrumbItem>
                  </CBreadcrumb> */}
            </div>
          )}

          {breadcrumSession1 && (
            <div className="pl-4">
              <div>
                <CBreadcrumb>
                  <CBreadcrumbItem
                    onClick={() => {

                    // setSession1(true);
                    
                    setDisplayScreen(SCREENS.SCREEN1);
                    
                    setBreadcrumSession(true);
                    setBreadcrumSession1(false);
                     
                    setDurationDataRes([]);
                     
                  // clearFunction();
                  // setSession2(false);
                         
                    }}
                  >
                    Session Plan
                  </CBreadcrumbItem>
                  <CBreadcrumbItem
                    active
                    onClick={() => {
                      // setSession1(false);

                    }}
                  >
                    Katbook Session Mapping
                  </CBreadcrumbItem>

                  {/* {subjectNameArrydata.map((x, e) => (
                      <CBreadcrumbItem active>{x.SubjectName}</CBreadcrumbItem>
                    ))} */}
                </CBreadcrumb>
              </div>
            </div>
          )}
          
          {/* first screen */}
          
          {displayScreen === SCREENS.SCREEN1 && (
            <div className="mt-3 px-5 py-3 d-flex align-items-end justify-content-start">
              <div className="widMax w-100">
             
                <CRow>
                <CCol sm={3} className="mb-3" >
                    <KDropdown
                      containerClassName="w-70"
                      label="Medium of Education"
                      isRequired={true}
                      placeholder="Medium of Education"
                      value={mediumofEduDropView}
                      options={mediumofEduDataView}
                      onChange={(e) => {
                        setMediumofEduDropView(e);
                        ViewSessionPlan_YearofAc_Fun(e.value);
                        setyearofAccDataDropView("");
                        setstandardDataDropView("");
                        setSessionDataDropView("");
                        setSubjectsDataDropView("");
                      }}
                    />
                  </CCol>
                  <CCol sm={3}>
                  <KDropdown
                      containerClassName="w-100"
                      label="Academic Year"
                      isRequired={true}
                      placeholder="Academic Year"
                      value={yearofAccDataDropView}
                      options={yearofAccDataView}
                      isDisabled={!mediumofEduDropView}
                      onChange={(e) => {
                        setyearofAccDataDropView(e);
                        ViewSessionPlan_Standard_Fun(e.value);
                        setYearID(e.yearID);
                        setstandardDataDropView("");
                        setSessionDataDropView("");
                        setSubjectsDataDropView("");
                      }}
                    />
                  </CCol>
                  <CCol sm={3}>
                  <KDropdown
                      containerClassName="w-100"
                      label="Standard"
                      isRequired={true}
                      placeholder="Standard"
                      value={standardDataDropView}
                      options={standardDataView}
                      isDisabled={!mediumofEduDropView || !yearofAccDataDropView}
                      onChange={(e) => {
                        setstandardDataDropView(e);
                        ViewSessionPlan_Session_Fun(e.value, e.MediumID);
                        setSessionDataDropView("");
                        setSubjectsDataDropView("");
                      }}
                    />
                  </CCol>
                  <CCol sm={3}>
                  <KDropdown
                      containerClassName="w-100"
                      label="Section"
                      isRequired={true}
                      placeholder="Section"
                      value={sessionDataDropView}
                      options={sessionDataView}
                      isDisabled={
                        !mediumofEduDropView ||
                        !yearofAccDataDropView ||
                        !standardDataDropView
                      }
                      onChange={(e) => {
                        setSessionDataDropView(e);
                        ViewSessionPlan_Subject_Fun(
                          e.value,
                          e.refMediumID,
                          e.sectionID,
                          e.refVariableID
                        );
                        setSubjectsDataDropView("");

                      }}
                    
                    />
                  </CCol>
                  <CCol sm={3}>
                  <KDropdown
                      containerClassName="w-100"
                      label="Subject"
                      isRequired={true}
                      placeholder="Subject"
                      value={subjectsDataDropView}
                      options={subjectsDataView}
                      isDisabled={
                        !mediumofEduDropView ||
                        !yearofAccDataDropView ||
                        !standardDataDropView ||
                        !sessionDataDropView
                      }
                     
                      onChange={(e) => {
                        
                        setSubjectsDataDropView(e);
                        ViewSessionPlan_ReSchedule_Fun(
                          e.refMediumID,
                          e.refSectionID,
                          e.refVariableID,
                          e.refSubjectID,
                          e.refInstID,
                          e.refYearID
                        );
                        ViewSessionPlan_Fun(e.refMediumID,
                          e.refSectionID,
                          e.refVariableID,
                          e.refSubjectID,
                          e.refInstID,
                          e.refYearID)
                        setAllData({
                          refInstID:e.refInstID,
                          refMediumID:e.refMediumID,
                          refYearID:e.refYearID,
                          refVariableID:e.refVariableID,
                          refSubjectID:e.refSubjectID,
                          refStaffID:e.refStaffID,
                          variableDetails:e.variableDetails,
                          katbookSessionID:e.katbookSessionID,
                          katbookUnitID:e.katbookUnitID,
                          katbookSessionID: e.katbookSessionID,
                        })

                        // setAllId({
                          
                        // })
                        
                        // setlAstVariableIDData(e.lastVariableID);
                        // ViewSessionPlan_ScheduleCpm_Fun(e.lastVariableID);
                      }}
                    
                    />
                      
                  </CCol>
              </CRow>
         
                <CRow>
                  <div className="mt-4 pl-2 gap-2 d-flex align-items-end justify-content-end">
                    
                  <KButton
                      className={`px-3 py-2 ${
                        isBigScreen ? "w-auto" : "w-100"
                      }`}
                      isDisabled={
                        !subjectsDataDropView ||
                        !mediumofEduDropView ||
                        !yearofAccDataDropView ||
                        !standardDataDropView ||
                        !sessionDataDropView
                      }
                      onClick={() => {
                        setBreadcrumSession(false);
                        setBreadcrumSession1(true);
                        setDisplayScreen(SCREENS.SCREEN3)
                        ViewFun();
                        // setSession1(false);
                        // setSession2(true);
                        // ViewSessionPlan_ScheduleCpm_Fun();
                      }}
                      value="View"
                    />
                    <KButton
                      className={`px-3 py-2 ${
                        isBigScreen ? "w-auto" : "w-100"
                      }`}
                      btnColor="green"
                      isDisabled={
                        !subjectsDataDropView ||
                        !mediumofEduDropView ||
                        !yearofAccDataDropView ||
                        !standardDataDropView ||
                        !sessionDataDropView
                      }
                      onClick={() => {
                        setBreadcrumSession(false);
                        setBreadcrumSession1(true);
                        setDisplayScreen(SCREENS.SCREEN2)
                        // setSession1(false);
                        // setSession2(true);
                        ViewSessionPlan_ReScheduleCpm_Fun();

                        setAsstDate('')
                        setErrorMessage("");
                      }}
                      value="Reschedule"
                    />
                    <KButton
                      className={`px-3 py-2 ${
                        isBigScreen ? "w-auto" : "w-100"
                      }`}
                      btnColor="red"
                      onClick={() => {
                        setBreadcrumSession(true);
                        setBreadcrumSession1(false);
                        ClearFunction();
                      }}
                      value="Clear"
                    />
                  </div>
                </CRow>
              </div>
            </div>
          )}

          {/* Second Screen - Reschedule */}

          {displayScreen === SCREENS.SCREEN2 && (
            <div className="container-fluid px-5 py-3">
              <div className="d-flex flex-column ">
                <div className="mb-3 d-flex align-items-end justify-content-center">
                  <CFormLabel className="cusfontLabel">
                    Subject Start Date 
                  </CFormLabel>
                  
                  <KInput
                    placeholder="dd--mm--yyyy"
                    // label="Subject Start Date"
                    containerClassName="pl-md-4 pl-0 pt-2 pt-md-0 w-25"
                    labelClassName="width-maxContent"
                    inputClassName="w-100"
                    isRequired={true}
                    value={AsstDate}
                    type="date"
                    min={minDate}
                    onKeyDown = {handleKey}
                    onChange={(e) => {
                      const selectedDate = e; 
                      // setAsstDate(selectedDate); 
                      
                      LSDValidationView(e)
                                            
                    }}
                   
                    error={errorMessage}
                  />
                </div>
              </div>
              {/*  Reschedule Table  */}
              
              <> 
                           
                <div className="tablewidthSession">
                  {/* {JSON.stringify(scheduleDataView.map((x=>x.duration)))} */}
                  {/* {JSON.stringify(DurationDataRes.map((x=>x.value)))} */}
                  
                  <CSmartTable
                    columns={ViewChapterTable}
                    // items={scheduleDataCpmView.filter(z=>
                    //   scheduleDataView.filter(b=>b.katbookSessionID === z.sessionId ).length > 0 ? true : false 
                    //   )}
                    items={scheduleDataCpmView}
                    columnFilter
                    columnSorter
                    itemsPerPage={250}
                    // itemsPerPageSelect={scheduleDataCpmView.length > 5}
                    // pagination
                    scopedColumns={{
                   

                      Duration: (items) => {
                        return (
                          
                          <td className="gapflex">
                      
                            <KInput
                                  
                              placeholder = "Enter Duration"
                              containerClassName = {`pl-md-3 pl-0 pt-2 pt-md-0 w-75`}
                              labelClassName = {"width-maxContent"}
                              inputClassName = "w-75"
                              className={`${
                                AsstDate ? 'cursorPointer' : 'NotcursorPointer'
                              }`}
                              type = "number"
                              value= {scheduleDataView.find(x=>x.katbookSessionID === items.sessionId )?.duration ?? ""}
                              onChange={(e)=>{
                              handleRescheduleChange(e,items.sessionId);
                              }}
                              isDisabled={!AsstDate}
                              // onChange={handleInputChange()}
                            
                            />      
                          </td>
                         
                        );
                      },
                    }}
                    tableProps={{
                      striped: true,
                      hover: true,
                    }}
                  />
                  {/* button */}
                </div>
                                
                <div className="mt-3 pl-5 pr-4 d-flex align-items-end justify-content-end gap-2">
                  <KButton
                    className={`px-3 py-2 ${isBigScreen ? "w-auto" : "w-100"}`}
                    btnColor="green"
                    onClick={() => {
                      setVisible(true);
                      
                    }}
                    value="Reschedule"
                    isDisabled={!AsstDate}
                  />
                  <KButton
                    className={`px-3 py-2 ${isBigScreen ? "w-auto" : "w-100"}`}
                    btnColor="red"
                    onClick={() => {
                      setBreadcrumSession(true);
                      setBreadcrumSession1(false);
                      setDisplayScreen(SCREENS.SCREEN1)
                      // setSession1(true);
                      // setSession2(false);
                    }}
                    value="Back"
                  />
                </div>

              </>

            </div>
          )}
          {/* Reschedule model */}
            <CModal
            alignment="center"
            visible={visible}
            onClose={() => {
              setVisible(false)
              
            }}
            className="ModalHeight"
          >
            <div
              style={{
                backgroundColor: "white",
                padding: "10px 30px 30px 30px",
                borderRadius:"5px",
              }}
            >
              

              <div style={{ padding: "25px" }}>
                <div className="d-flex justify-content-center mb-4">
                  <span
                    style={{
                      fontSize: "18px",
                      color: "black",
                      fontWeight: "500",
                      height: "auto",
                    }}
                  >
                    Are you sure to Reschedule?
                  </span>
                </div>
                <div className="d-flex justify-content-around">
                  <KButton
                    value="Yes"
                    
                    onClick={() => {

                      ViewSessionPlan_ScheduleConfirmBtn_Fun();
                      setVisible(false);
                      setAsstDate('')
                      ClearFunction()
                      
                      // RefreshPage();
                      // setSession2(false)
                      // setSession1(true);
                    
                    }}
                    
                    btnColor={"green"}
                    className={"px-4 py-1 ButtonSize"}
                  />
                  <KButton
                    value="No"
                    onClick={() => {
                      setVisible(false)
                      // PostCancelQuestion();
                      
                    }}
                    className={"px-4 py-1 ButtonSize"}
                    btnColor={"red"}
                  />
                </div>
              </div>
            </div>
           </CModal>
           
{/* Third section View Button click  */}
            {displayScreen === SCREENS.SCREEN3 && (
                <div className="container-fluid px-5 py-3">
                
                  {/* View All Data Table  */}
                  
                  <>
                   <div className="tablewidthSession">
                      {/* {JSON.stringify(scheduleDataView1)}
                      <hr />
                      {JSON.stringify(ViewBtn)} */}

                       
                        {/* <KButton
                          className={`px-3 py-2 ${isBigScreen ? "w-auto" : "w-100"}`}
                          btnColor="green"
                          onClick={() => {
                            handleDownload()
                            }}
                          value="Download"
                          /> */}
                   {/* View Button */}
                   {/* {JSON.stringify(ViewBtn)}
                   <hr />
                   {JSON.stringify(scheduleDataView)} */}
                   
                      <CSmartTable
                        columns={ViewAllChapterTable}
                        // items={scheduleDataCpmView}
                        items={ViewBtn}
                        // tableRef={tableRef}
                        columnFilter
                        columnSorter
                        itemsPerPage={200}
                        // itemsPerPageSelect={scheduleDataCpmView.length > 5}
                        // pagination
                        scopedColumns={{
                  
                          Duration: (items) => {
                            return (
                              <td className="gapflex">
                                {/* {JSON.stringify(items)} */}
                                  {scheduleDataView.find(data => data.katbookSessionID === items.sessionId)?.duration
                                    ?
                                    scheduleDataView.find(data => data.katbookSessionID === items.sessionId)?.duration
                                    : "-"                            
                                  }  
                              
                              </td>
                            
                            );
                          },
                          StartDate: (items) => {
                            return (
                              <td className="gapflex">
                                {/* {JSON.stringify(items)} */}
                                  {scheduleDataView.find(data => data.katbookSessionID === items.sessionId)?.StartDate
                                    ?
                                    scheduleDataView.find(data => data.katbookSessionID === items.sessionId)?.StartDate
                                    : "-"                            
                                  }  
                              
                              </td>
                            
                            );
                          },
                          EndDate: (items) => {
                            return (
                              <td className="gapflex">
                                {/* {JSON.stringify(items)} */}
                                  {scheduleDataView.find(data => data.katbookSessionID === items.sessionId)?.EndDate
                                    ?
                                    scheduleDataView.find(data => data.katbookSessionID === items.sessionId)?.EndDate
                                    : "-"                            
                                  }  
                              
                              </td>
                            
                            );
                          },

                        }}
                        tableProps={{
                          striped: true,
                          hover: true,
                        }}
                      />
                      {/* button */}
                    </div>
                    <div className="mt-3 pl-5 pr-4 d-flex align-items-end justify-content-end gap-2">
                      <KButton
                        className={`px-3 py-2 ${isBigScreen ? "w-auto" : "w-100"}`}
                        // btnColor="green"
                        onClick={() => {
                          setVisiblePrt(true);
                                                                        }}
                        value="Download"
                        />
                      <KButton
                        className={`px-3 py-2 ${isBigScreen ? "w-auto" : "w-100"}`}
                        btnColor="red"
                        onClick={() => {
                          setBreadcrumSession(true);
                          setBreadcrumSession1(false);
                          setDisplayScreen(SCREENS.SCREEN1)
                          // setSession1(true);
                          // setSession2(false);
                        }}
                        value="Back"
                      />
                    </div>
                  </>
                </div>
          )}
          {/* Print button Model */}
          <CModal
            alignment="center"
            visible={visiblePrt}
            onClose={() => {
              setVisiblePrt(false)
              
            }}
            className="ModalHeight"
          >
            <div
              style={{
                backgroundColor: "white",
                padding: "10px 30px 30px 30px",
                borderRadius:"5px",
              }}
            >
              

              <div style={{ padding: "25px" }}>
                <div className="d-flex justify-content-center mb-4">
                  <span
                    style={{
                      fontSize: "18px",
                      color: "black",
                      fontWeight: "500",
                      height: "auto",
                    }}
                  >
                    Would you like to Download?
                  </span>
                </div>
                <div className="d-flex justify-content-around">
                  <KButton
                    value="Yes"
                    
                    onClick={() => {
                      // handlePrint();
                      handleDownload();
                      // handleDownload();
                      // ViewSessionPlan_ScheduleConfirmBtn_Fun();
                      
                      setVisiblePrt(false);
                      // setSession2(false)
                      // setSession1(true);
                    }}
                    
                    btnColor={"green"}
                    className={"px-4 py-1 ButtonSize"}
                  />
                  <KButton
                    value="No"
                    onClick={() => {
                      setVisiblePrt(false)
                      // PostCancelQuestion();
                      
                    }}
                    className={"px-4 py-1 ButtonSize"}
                    btnColor={"red"}
                  />
                </div>
              </div>
            </div>
           </CModal>
        </div>
      </div>

      <Footer
        openForm={openForm}
        shoq={shoq}
        closeForm={closeForm}
        KpostIframe={KpostIframe}
      />
    </div>
  );
};

export default ViewSessionPlan;
