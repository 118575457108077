// import axios from "axios";

// const KAMPUSURL = "https://demoapi.ueducate.in";
const KAMPUSURL = "https://testapi1.kampus.org.in";
// const KAMPUSURL = "https://loadtestapi.kampus.org.in"; // load test java backend API URL
// const KAMPUSURL = "http://192.168.2.41:9090/KampusWebService_new";

// const KATBOOKURLV1 = `https://api1.katbook.com/v1`;
// const KATBOOKURLV1 = `http://192.168.2.33:2504/v1`;
const KATBOOKURLV1 = `https://demoapi1.katbook.com/v1`;
// const KATBOOKURLV1 = `https://lowtestapi.katbook.com/v1`;  // load test node js backend API URL

const AccessToken = localStorage.getItem("AccessToken");

// console.log(JSON.parse(localStorage.userCreds),"AccessToken");
console.log(AccessToken,"AccessToken");

export async function KampusLogin(body) {
  try {
    //   const token = localStorage.getItem(`token`);
    const response = await fetch(`${KAMPUSURL}/login/checklogin`, {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        // Authorization: token,  
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (error) { }
}

export async function KampusProfile(body) {
  const AccessToken = localStorage.getItem(`AccessToken`);
  try {
    const response = await fetch(
      `${KAMPUSURL}/student/studentdetails/getUserDetails`,
      {
        method: `POST`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AccessToken}`,
        },
        body: JSON.stringify(body),
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 400 || response.status === 404) {
      return await response.json();
    } else if (response.status === 401 || response.status === 403) {
      return {status: "403"}
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
}

export async function Kampuslogout(body, token) {
  const AccessToken = localStorage.getItem(`AccessToken`);
  try {
    const response = await fetch(
      `${KAMPUSURL}/student/studentdetails/logout`,
      {
        method: `POST`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 400 || response.status === 404) {
      return await response.json();
    } else if (response.status === 401 || response.status === 403) {
      return {status: "403"}
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    throw e;
  }
}

export async function getDynamicTimetable(body) {
  try {
      const AccessToken = localStorage.getItem(`AccessToken`);
    const response = await fetch(
      `${KAMPUSURL}/student/timeTableNew/getDynamicTimeTableBasedOnLessonPlan`,
      {
        method: `POST`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AccessToken}`,
        },
        body: JSON.stringify(body),
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 400 || response.status === 404) {
      return await response.json();
    } else if (response.status === 401 || response.status === 403) {
      return {status: "403"}
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (error) { }
}

export async function getDynamicTimetableByDate(body) {
  try {
    const AccessToken = localStorage.getItem(`AccessToken`);
    const response = await fetch(
      `${KAMPUSURL}/student/timeTableNew/getLessonPlanForDayWise`,
      {
        method: `POST`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AccessToken}`,
        },
        body: JSON.stringify(body),
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 400 || response.status === 404) {
      return await response.json();
    } else if (response.status === 401 || response.status === 403) {
      return {status: "403"}
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (error) { }
}

export async function getDynamicTimetableByStaff(body) {
  try {
    const AccessToken = localStorage.getItem(`AccessToken`);
    const response = await fetch(
      `${KAMPUSURL}/teacher/timeTableNew/getDynamicTimeTableForStaff`,
      {
        method: `POST`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AccessToken}`,
        },
        body: JSON.stringify(body),
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 404 || response.status === 400) {
      return await response.json();
    } else if (response.status === 401 || response.status === 403) {
      return {status: "403"}
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (error) { }
}

export async function getDynamicTimetableByStaffWithDate(body) {
  try {
    const AccessToken = localStorage.getItem(`AccessToken`);
    const response = await fetch(
      `${KAMPUSURL}/teacher/timeTableNew/getDayWiseTableForStaff`,
      {
        method: `POST`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AccessToken}`,
        },
        body: JSON.stringify(body),
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 404 || response.status === 400) {
      return await response.json();
    } else if (response.status === 401 || response.status === 403) {
      return {status: "403"}
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (error) { }
}

export async function getDynamicSubjects(body) {
  try {
    const AccessToken = localStorage.getItem(`AccessToken`);
    const response = await fetch(
      `${KAMPUSURL}/student/collabration/dynamicFetchSubjects`,
      {
        method: `POST`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AccessToken}`,
        },
        body: JSON.stringify(body),
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 404 || response.status === 400) {
      return await response.json();
    }  else if (response.status === 401 || response.status === 403) {
      return {status: "403"};
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (error) { }
}

export async function getAllKatBookSubjects(body, userId) {
  const response = await fetch(`${KATBOOKURLV1}/kampus/get/index/${userId}`, {
    method: `POST`,
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function getAllKatBookSubjectsCorporate(body) {
  const response = await fetch(`${KATBOOKURLV1}/kampus/get/book5`, {
    method: `POST`,
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function getAllKatBookSubjectsCount(body) {
  const response = await fetch(`${KATBOOKURLV1}/book/unitsandsessioncount1`, {
    method: `POST`,
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  // console.log(`Response`, response);
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    return await response.json();
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function getUnitSessionNameFromtable(body) {
  try {
    //   const token = localStorage.getItem(`token`);
    const response = await fetch(
      `${KATBOOKURLV1}/variables/subject/unitandsession`,
      {
        method: `POST`,
        headers: {
          "Content-Type": "application/json",
          // Authorization: token,
        },
        body: JSON.stringify(body),
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (error) {
    throw new Error(error);
  }
}

export async function getUnitSessionNameForBookView(body) {
  try {
    //   const token = localStorage.getItem(`token`);
    const response = await fetch(
      `${KATBOOKURLV1}/variables/bookview/unitandsession`,
      {
        method: `POST`,
        headers: {
          "Content-Type": "application/json",
          // Authorization: token,
        },
        body: JSON.stringify(body),
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401 || response.status === 400) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (error) {
    throw new Error(error);
  }
}

export async function kampusJoinMeeting(body) {
  // alert(3)
  // alert(JSON.stringify(body))
  const response = await fetch(`${KATBOOKURLV1}/meeting/join`, {
    method: `POST`,
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  // debugger
  // console.log(`Respons
  if (response.status === 200) {
    // alert("==if")
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    // alert("else if")
    return await response.json();
  } else {
    // alert("===")
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function kampusStartMeeting(body) {
  // alert(3)
  // alert(JSON.stringify(body))
  const response = await fetch(`${KATBOOKURLV1}/meeting/start`, {
    method: `POST`,
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  // debugger
  // console.log(`Respons
  if (response.status === 200) {
    // alert("==if")
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    // alert("else if")
    return await response.json();
  } else {
    // alert("===")
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function kampusInfoMeeting(body) {
  // alert(3)
  // alert(JSON.stringify(body))
  const response = await fetch(`${KATBOOKURLV1}/meeting/info`, {
    method: `POST`,
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  // debugger
  // console.log(`Respons
  if (response.status === 200) {
    // alert("==if")
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    // alert("else if")
    return await response.json();
  } else {
    // alert("===")
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

export async function kampusStopMeeting(params1, body) {
  // alert(3)
  // alert(JSON.stringify(body))
  const response = await fetch(`${KATBOOKURLV1}/meeting/stop`, {
    method: `PUT`,
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  // debugger
  // console.log(`Respons
  if (response.status === 200) {
    // alert("==if")
    return await response.json();
  } else if (response.status === 401 || response.status === 400) {
    // alert("else if")
    return await response.json();
  } else {
    // alert("===")
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

////////// start student and teacher fetching subjects  /////////

export async function getTeacherSubjects(body) {
  try {
    //   const token = localStorage.getItem(`token`);
    const response = await fetch(
      `${KAMPUSURL}/studentdetails/classDetailsAndSubjectDetailsForStaff`,
      {
        method: `POST`,
        headers: {
          "Content-Type": "application/json",
          // Authorization: token,
        },
        body: JSON.stringify(body),
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 404 || response.status === 400) {
      return await response.json();
    } else if (response.status === 401 || response.status === 403) {
      return {status: "403"}
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (error) {
    throw new Error(error);
  }
}


export async function getAllStudentAssignments(body) {
  try {
    const response = await fetch(
      `${KATBOOKURLV1}/assignments/students/getAll/subjects`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (
      response.status === 401 ||
      response.status === 400 ||
      response.status === 404
    ) {
      return await response.json();
    } else {
      let errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (err) {
    throw new Error(err);
  }
}
export async function getTableData(body) {
  const AccessToken = localStorage.getItem(`AccessToken`);
  try {
    const response = await fetch(

      `${KAMPUSURL}/student/studentAnswer/studentAnswerList`,
      
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AccessToken}`,
        },
        body: JSON.stringify(body),
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (
      response.status === 400 ||
      response.status === 404
    ) {
      return await response.json();
    } else if (response.status === 401 || response.status === 403) {
      return {status: "403"}
    } else {
      let errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (err) {
    throw new Error(err);
  }
}


export async function getAssignmentQuestion(body) {
  try {
    const response = await fetch(
      `${KATBOOKURLV1}/questions/institution/staff/getAll`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (
      response.status === 401 ||
      response.status === 400 ||
      response.status === 404
    ) {
      return await response.json();
    } else {
      let errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (err) {
    throw new Error(err);
  }
}

// export async function postAnswersByStudent(body) {
//   try {
//     const response = await fetch(`${KATBOOKURLV1}/student/answers/create`, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify(body),
//     });
//     if (response.status === 200) {
//       return await response.json();
//     } else if (
//       response.status === 401 ||
//       response.status === 400 ||
//       response.status === 404
//     ) {
//       return await response.json();
//     } else {
//       let errorResponse = await response.json();
//       throw new Error(errorResponse.error);
//     }
//   } catch (err) {
//     throw new Error(err);
//   }
// }

export async function postAnswersByStudent(body) {
  const AccessToken = localStorage.getItem(`AccessToken`);
  try {
      const response = await fetch(`${KAMPUSURL}/student/studentAnswer/postAnswer`, {
      
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AccessToken}`,
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (
      response.status === 400 ||
      response.status === 404
    ) {
      return await response.json();
    } else if (response.status === 401 || response.status === 403) {
      return {status: "403"}
    } else {
      let errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (err) {
    throw new Error(err);
  }
}


export async function getClarifyCount(body) {
  const AccessToken = localStorage.getItem(`AccessToken`);
  try {
    const response = await fetch(
      `${KAMPUSURL}/student/collabration/fetchCommentsCount`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AccessToken}`,
        },
        body: JSON.stringify(body),
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (
      response.status === 400 ||
      response.status === 404
    ) {
      return await response.json();
    } else if (response.status === 401 || response.status === 403) {
      return {status: "403"}
    } else {
      let errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (err) {
    throw new Error(err);
  }
}

export async function POSTpOSTaSSIGNMENT(body) {
  const AccessToken = localStorage.getItem(`AccessToken`);
  const response = await fetch(`${KAMPUSURL}/teacher/assignment/postAssignment`, {
  
    method: `PUT`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${AccessToken}`,
    },
    body: JSON.stringify(body),
  });
  // debugger
  // console.log(`Respons
  if (response.status === 200) {
    // alert("==if")
    return await response.json();
  } else if (response.status === 404 || response.status === 400) {
    // alert("else if")
    return await response.json();
  } else if (response.status === 401 || response.status === 403) {
    return {status: "403"}
  } else {
    // alert("===")
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

//Rajesh API - 25.04.2023


export async function assignmentStudentsReport_subjectlist(body) {
  const AccessToken = localStorage.getItem(`AccessToken`);
  try {
    const response = await fetch(
      `${KAMPUSURL}/student/studentAnswer/allAssignmentListForStudent`,
           
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AccessToken}`,
        },

        body: JSON.stringify(body),
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 400 || response.status === 404) {
      return await response.json();
    } else if (response.status === 401 || response.status === 403) {
      return {status: "403"}
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (err) { }
}

// New Kampus_API_Sriram

// .......Start.....Create Assignment page ..............
export async function Teacher_CreateAssignemt(body) {
  const AccessToken = localStorage.getItem(`AccessToken`);
  try {
    
       const response = await fetch(`${KAMPUSURL}/teacher/assignment/createAssignment`, {
    
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AccessToken}`,
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (
      response.status === 400 ||
      response.status === 404
    ) {
      return await response.json();
    } else if (response.status === 401 || response.status === 403) {
      return {status: "403"}
    } else {
      let errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (err) {
    throw new Error(err);
  }
}

export const Teacher_CreateAssignemt_Table = async (body) => {
  let response;
  const AccessToken = localStorage.getItem(`AccessToken`);
  try {
    response = await fetch(
    
      `${KAMPUSURL}/teacher/assignment/fetchStaffSubjectAssignment`,
      
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AccessToken}`,
        },
        body: JSON.stringify(body),
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 404 || response.status === 400) {
      return await response.json();
    } else if (response.status === 401 || response.status === 403) {
      return {status: "403"}
    } else {
      let errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    return new Error(e);
  }
};


export const GetStudentAssignmentDetails_API = async (body) => {
  let response;
  const AccessToken = localStorage.getItem(`AccessToken`);
  try {
    response = await fetch(
     
      `${KAMPUSURL}/student/assignment/getStudentAssignmentDetails`,
      
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AccessToken}`,
        },
        body: JSON.stringify(body),
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 404 || response.status === 400) {
      return await response.json();
    } else if (response.status === 401 || response.status === 403) {
      return {status: "403"}
    } else {
      let errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    return new Error(e);
  }
};

export async function Teacher_CreateAssignemt_Update(body) {
  const AccessToken = localStorage.getItem(`AccessToken`);
  try {
   
    const response = await fetch(`${KAMPUSURL}/teacher/assignment/updateAssignment`, {
     
      method: `PUT`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AccessToken}`,
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 404 || response.status === 400) {
      return await response.json();
    } else if (response.status === 401 || response.status === 403) {
      return {status: "403"}
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (error) {
    throw new Error(error);
  }
}

// ..........END...........................
// Create New Question Type and question -08.05.2023 
export const Teacher_Get_Question_Type = async () => {
  let response;
  const AccessToken = localStorage.getItem(`AccessToken`);
  try {
    
    response = await fetch(`${KAMPUSURL}/teacher/questionType/fetchQuestionType`, {
   
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AccessToken}`,
      },
    });
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 404 || response.status === 400) {
      return await response.json();
    } else if (response.status === 401 || response.status === 403) {
      return {status: "403"}
    } else {
      let errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (e) {
    return new Error(e);
  }
};


export async function Teacher_Create_Question_Type(body) {
  const AccessToken = localStorage.getItem(`AccessToken`);
  try {
   
    const response = await fetch(`${KAMPUSURL}/teacher/questionType/createQuestionType`, {
    
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AccessToken}`,
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (
      response.status === 400 ||
      response.status === 404
    ) {
      return await response.json();
    } else if (response.status === 401 || response.status === 403) {
      return {status: "403"}
    } else {
      let errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (err) {
    throw new Error(err);
  }
}

export async function Teacher_Question_Type_Update(body, questionid) {
  const AccessToken = localStorage.getItem(`AccessToken`);
  const response = await fetch(
   
   
    `${KAMPUSURL}/teacher/questionType/updateQuestionType`,
   
    {
      method: `PUT`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AccessToken}`,
      },
      body: JSON.stringify(body),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 404 || response.status === 400) {
    return await response.json();
  } else if (response.status === 401 || response.status === 403) {
    return {status: "403"}
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}



export async function Teacher_Create_QuestionAndAnswer(body) {
  const AccessToken = localStorage.getItem(`AccessToken`);
  try {
    
   
    const response = await fetch(`${KAMPUSURL}/teacher/questionCreation/saveCreateQuestion`, {
    
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AccessToken}`,
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (
      response.status === 400 ||
      response.status === 404
    ) {
      return await response.json();
    } else if (response.status === 401 || response.status === 403) {
      return {status: "403"}
    } else {
      let errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (err) {
    throw new Error(err);
  }
}

export async function QuestionAndAnswer_Table_List(body) {
  const AccessToken = localStorage.getItem(`AccessToken`);
  try {
   
   
    const response = await fetch(`${KAMPUSURL}/teacher/questionCreation/fetchCreatedQuestions`, {
   
      
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AccessToken}`,
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (
      response.status === 400 ||
      response.status === 404
    ) {
      return await response.json();
    } else if (response.status === 401 || response.status === 403) {
      return {status: "403"}
    } else {
      let errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (err) {
    throw new Error(err);
  }
}

export async function QuestionAndAnswerOnly_Table_List(body) {
  const AccessToken = localStorage.getItem(`AccessToken`);
  try {
    const response = await fetch(

      
       `${KAMPUSURL}/student/studentAnswer/studentAnswerList`,
      


      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AccessToken}`,
        },
        body: JSON.stringify(body),
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 400 || response.status === 404) {
      return await response.json();
    } else if (response.status === 401 || response.status === 403) {
      return {status: "403"}
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (err) { }
}

export async function Question_Answer_Submit(body) {
  const AccessToken = localStorage.getItem(`AccessToken`);
  try {
 
    const response = await fetch(`${KAMPUSURL}/student/studentAnswer/saveAnswer`, {
    

      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AccessToken}`,
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (
      response.status === 400 ||
      response.status === 404
    ) {
      return await response.json();
    } else if (response.status === 401 || response.status === 403) {
      return {status: "403"}
    } else {
      let errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (err) {
    throw new Error(err);
  }
}


export async function Teacher_Review_verify_Each_Question_API(body) {
  const AccessToken = localStorage.getItem(`AccessToken`);
  try {
    
   
    const response = await fetch(`${KAMPUSURL}/teacher/studentAnswer/updateAnswer`, {
    

      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AccessToken}`,
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (
      response.status === 400 ||
      response.status === 404
    ) {
      return await response.json();
    } else if (response.status === 401 || response.status === 403) {
      return {status: "403"}
    } else {
      let errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (err) {
    throw new Error(err);
  }
}


export async function Current_Assignment_List_table(body) {
  const AccessToken = localStorage.getItem(`AccessToken`);
  try {
    // const response = await fetch(`${KAMPUSURL}/assignment/fetchPostedAssignment`, {
    const response = await fetch(`${KAMPUSURL}/student/assignment/fetchCurrentAssignment`, {
   
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AccessToken}`,
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (
      response.status === 400 ||
      response.status === 404
    ) {
      return await response.json();
    } else if (response.status === 401 || response.status === 403) {
      return {status: "403"}
    } else {
      let errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (err) {
    throw new Error(err);
  }
}

export async function Past_Assignment_List_table(body) {
  const AccessToken = localStorage.getItem(`AccessToken`);
  try {
    // const response = await fetch(`${KAMPUSURL}/assignment/fetchPastAssignment`, {
    const response = await fetch(`${KAMPUSURL}/student/assignment/fetchPastAssignmentForStudent`, {
    
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AccessToken}`,
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (
      response.status === 400 ||
      response.status === 404
    ) {
      return await response.json();
    } else if (response.status === 401 || response.status === 403) {
      return {status: "403"}
    } else {
      let errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (err) {
    throw new Error(err);
  }
}


export async function Pending_Assignment_List_table(body) {
  const AccessToken = localStorage.getItem(`AccessToken`);
  try {
    // const response = await fetch(`${KAMPUSURL}/assignment/fetchPastAssignment`, {
    const response = await fetch(`${KAMPUSURL}/student/assignment/fetchPendingAssignmentForStudent`, {
    
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AccessToken}`,
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (
      response.status === 400 ||
      response.status === 404
    ) {
      return await response.json();
    } else if (response.status === 401 || response.status === 403) {
      return {status: "403"}
    } else {
      let errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (err) {
    throw new Error(err);
  }
}

export async function Teacher_Review_verify_API(body) {
  const AccessToken = localStorage.getItem(`AccessToken`);
  try {
   
       const response = await fetch(`${KAMPUSURL}/teacher/studentAnswer/updateTeacherReview
   
    `, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AccessToken}`,
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (
      response.status === 400 ||
      response.status === 404
    ) {
      return await response.json();
    } else if (response.status === 401 || response.status === 403) {
      return {status: "403"}
    } else {
      let errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (err) {
    throw new Error(err);
  }
}

// https://demoapi.ueducate.in/studentdetails/getStudentNameListForClassId

export async function Teacher_Review_StudentName_List_API(body) {
  const AccessToken = localStorage.getItem(`AccessToken`);
  try {
    // const response = await fetch(`${StudentName_List}`, {
    const response = await fetch(`${KAMPUSURL}/student/studentdetails/getStudentNameListForClassId`, {
      
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AccessToken}`,
      },
      body: JSON.stringify(body),
    });
    if (response.status === 200) {
      return await response.json();
    } else if (
      response.status === 400 ||
      response.status === 404
    ) {
      return await response.json();
    } else if (response.status === 401 || response.status === 403) {
      return {status: "403"}
    } else {
      let errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (err) {
    throw new Error(err);
  }
}


export async function Teacher_Question_Answer_Delete(body) {
  const AccessToken = localStorage.getItem(`AccessToken`);
  const response = await fetch(

    `${KAMPUSURL}/teacher/questionCreation/deleteCreatedQuestion`,
    {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AccessToken}`,
      },
      body: JSON.stringify(body),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 404 || response.status === 400) {
    return await response.json();
  } else if (response.status === 401 || response.status === 403) {
    return {status: "403"}
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}


export async function Teacher_Question_Answer_POSTE_Data(body) {
  const AccessToken = localStorage.getItem(`AccessToken`);
  const response = await fetch(

    `${KAMPUSURL}/student/assignment/fetchCurrentAssignment`,

    {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AccessToken}`,
      },
      body: JSON.stringify(body),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 404 || response.status === 400) {
    return await response.json();
  } else if (response.status === 401 || response.status === 403) {
    return {status: "403"}
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

// Today Function
export async function Posted_Assignment_Data_API(body) {
  const AccessToken = localStorage.getItem(`AccessToken`);
  const response = await fetch(

    `${KAMPUSURL}/teacher/assignment/fetchAssignmentByDuration`,

    {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AccessToken}`,
      },
      body: JSON.stringify(body),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 404 || response.status === 400) {
    return await response.json();
  } else if (response.status === 401 || response.status === 403) {
    return {status: "403"}
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}

// Weekly function
export async function Posted_Assignment_WeeklyData_API(body) {
  const AccessToken = localStorage.getItem(`AccessToken`);
  const response = await fetch(

    `${KAMPUSURL}/teacher/assignment/fetchAssignmentByDuration`,
    {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AccessToken}`,
      },
      body: JSON.stringify(body),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 404 || response.status === 400) {
    return await response.json();
  } else if (response.status === 401 || response.status === 403) {
    return {status: "403"}
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}


// Session plan screen Api - 01-08-2023 - Rajesh

export async function SessionPlan_MediumofEdu_API(body) {
  const AccessToken = localStorage.getItem(`AccessToken`);
  const response = await fetch(

    `${KAMPUSURL}/teacher/institutionregistration/getMediumByInstitution`,
    // https://demoapi.ueducate.in/institutionregistration/getMediumByInstitution
    {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
          "Authorization": `Bearer ${AccessToken}`,
      },
      body: JSON.stringify(body),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 404 || response.status === 400) {
    return await response.json();
  } else if (response.status === 401 || response.status === 403) {
    return {status: "403"}
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}


export async function SessionPlan_YearofAc_API(body) {
  const AccessToken = localStorage.getItem(`AccessToken`);
  const response = await fetch(

    `${KAMPUSURL}/teacher/academicYearSetup/getYearByInstId`,
    {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AccessToken}`,
      },
      body: JSON.stringify(body),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 404 || response.status === 400) {
    return await response.json();
  } else if (response.status === 401 || response.status === 403) {
    return {status: "403"}
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}


export async function SessionPlan_stand_API(body) {
  const AccessToken = localStorage.getItem(`AccessToken`);
  const response = await fetch(

    `${KAMPUSURL}/teacher/variable/getChildValueByParentTier`,
    {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AccessToken}`,
      },
      body: JSON.stringify(body),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 404 || response.status === 400) {
    return await response.json();
  } else if (response.status === 401 || response.status === 403) {
    return {status: "403"}
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}


export async function SessionPlan_session_API(body) {
  const AccessToken = localStorage.getItem(`AccessToken`);
  const response = await fetch(

    `${KAMPUSURL}/teacher/section/getSectionByVariableId`,
    {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AccessToken}`,
      },
      body: JSON.stringify(body),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 404 || response.status === 400) {
    return await response.json();
  } else if (response.status === 401 || response.status === 403) {
    return {status: "403"}
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}



export async function SessionPlan_subject_API(body) {
  const AccessToken = localStorage.getItem(`AccessToken`);
  const response = await fetch(

    `${KAMPUSURL}/teacher/staffSubject/getSubjectByStaffId`,
      {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AccessToken}`,
      },
      body: JSON.stringify(body),
    }
  );
   if (response.status === 200) {
      return await response.json();
  } else if (response.status === 404 || response.status === 400) {
      return await response.json();
  } else if (response.status === 401 || response.status === 403) {
    return {status: "403"}
  } else {
      let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}


export async function SessionPlan_scheduleBtn_API(body) {
  const AccessToken = localStorage.getItem(`AccessToken`);
  const response = await fetch(

    `${KAMPUSURL}/teacher/katbookIndex/fetchKatbookDetailsForLessonPlan`,
      {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AccessToken}`,
      },
      body: JSON.stringify(body),
    }
  );
   if (response.status === 200) {
      return await response.json();
  } else if (response.status === 404 || response.status === 400) {
      return await response.json();
  } else if (response.status === 401 || response.status === 403) {
    return {status: "403"}
  } else {
      let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}


export async function SessionPlan_scheduleBtnCompar_API(id) {
  try {
    const AccessToken = localStorage.getItem(`AccessToken`);
    const response = await fetch(
     `${KATBOOKURLV1}/teacher/variable/get/${id}`,
          {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AccessToken}`,
        },
      }
      );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 401) {
      return await response.json();
    }
    if (response.status === 400 || response.status === 404) {
      return await response.json();
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (err) { }
}

export async function SessionPlan_scheduleConfirmBtn_API(body) {
  const AccessToken = localStorage.getItem(`AccessToken`);
  const response = await fetch(

    `${KAMPUSURL}/teacher/katbookIndex/scheduleLessonPlanForStaff`,
    {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AccessToken}`,
      },
      body: JSON.stringify(body),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 404 || response.status === 400) {
    return await response.json();
  } else if (response.status === 401 || response.status === 403) {
    return {status: "403"}
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}



export async function ViewSessionPlan_RescheduleBtn_API(body) {
  const AccessToken = localStorage.getItem(`AccessToken`);
  const response = await fetch(

    `${KAMPUSURL}/teacher/katbookIndex/viewStaffLessonMap`,
    {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AccessToken}`,
      },
      body: JSON.stringify(body),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 404 || response.status === 400) {
    return await response.json();
  } else if (response.status === 401 || response.status === 403) {
    return {status: "403"}
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}



export async function ViewSessionPlan_RescheduleConfirmBtn_API(body) {
  const AccessToken = localStorage.getItem(`AccessToken`);
  const response = await fetch(

    `${KAMPUSURL}/teacher/katbookIndex/reScheduleLessonPlan`,
    {
      method: `POST`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${AccessToken}`,
      },
      body: JSON.stringify(body),
    }
  );
  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 404 || response.status === 400) {
    return await response.json();
  } else if (response.status === 401 || response.status === 403) {
    return {status: "403"}
  } else {
    let errorResponse = await response.json();
    throw new Error(errorResponse.error);
  }
}


export async function getDailyTask(body) {
  try {
    const AccessToken = localStorage.getItem(`AccessToken`);
    const response = await fetch(
      `${KAMPUSURL}/teacher/report/dailyTask/`,
      {
        method: `POST`,
        headers: {
          "Content-Type": "application/json",
            Authorization: `Bearer ${AccessToken}`,
          // Authorization: token,
        },
        body: JSON.stringify(body),
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 404 || response.status === 400) {
      return await response.json();
    } else if (response.status === 401 || response.status === 403) {
      return {status: "403"}
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (error) {
    throw new Error(error);
  }
}

export async function getWeeklyTask(body) {
  try {
    const AccessToken = localStorage.getItem(`AccessToken`);
    const response = await fetch(
      `${KAMPUSURL}/teacher/report/fetchGenericReport/`,
      {
        method: `POST`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AccessToken}`,
          // Authorization: token,
        },
        body: JSON.stringify(body),
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 404 || response.status === 400) {
      return await response.json();
    } else if (response.status === 401 || response.status === 403) {
      return {status: "403"}
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (error) {
    throw new Error(error);
  }
}

export async function gettoViewDailyTask(body) {
  try {
    const AccessToken = localStorage.getItem(`AccessToken`);
    const response = await fetch(
      `${KAMPUSURL}/teacher/report/getAppraisalReport/`,
      {
        method: `POST`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AccessToken}`,
        },
        body: JSON.stringify(body),
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 404 || response.status === 400) {
      return await response.json();
    } else if (response.status === 401 || response.status === 403) {
      return {status: "403"}
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (error) {
    throw new Error(error);
  }
}

export async function taskupdate(body) {
  try {
    const AccessToken = localStorage.getItem(`AccessToken`);
    const response = await fetch(
      `${KAMPUSURL}/teacher/report/updateTaskSheet/`,
      {
        method: `POST`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AccessToken}`,
        },
        body: JSON.stringify(body),
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 404 || response.status === 400) {
      return await response.json();
    } else if (response.status === 401 || response.status === 403) {
      return {status: "403"}
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (error) {
    throw new Error(error);
  }
}
export async function getstatusReport(body) {
  try {
    const AccessToken = localStorage.getItem(`AccessToken`);
    const response = await fetch(
      `${KAMPUSURL}/teacher/report/getStatusReport/`,
      {
        method: `POST`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${AccessToken}`,
        },
        body: JSON.stringify(body),
      }
    );
    if (response.status === 200) {
      return await response.json();
    } else if (response.status === 404 || response.status === 400) {
      return await response.json();
    } else if (response.status === 401 || response.status === 403) {
      return {status: "403"}
    } else {
      var errorResponse = await response.json();
      throw new Error(errorResponse.error);
    }
  } catch (error) {
    throw new Error(error);
  }
}
