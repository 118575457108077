import React from 'react'
import Form from './Form'
import FormHeader from './FormHeader'

function LoginForm() {
  return (
    <div className={"kampus_Main_Card"}
    style={{
      height: window.innerHeight +"px",
    }}
    >
        <div id="loginform">
          <FormHeader title="User Login" />
          <Form/>
        </div>
    </div>
  )
}
export default LoginForm