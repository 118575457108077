import React, { useEffect, useState } from "react";
import Header from "./HeaderNavbar";
import Footer from "./Footer";
import KDropdown from "./common/KDropdown";
import KInput from "./common/KInput";
import KButton from "./common/KButton";
import KHeader from "./common/KHeader";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Row, Col } from "reactstrap";
import useResponsive from "../../hooks/useResponsive";
import KEditor from "./common/KEditor";
import KRequiredLabel from "./common/KRequiredLabel";
import parse from "html-react-parser";
import { AiOutlineEdit } from "react-icons/ai";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FcCancel } from "react-icons/fc";
import { FaEdit } from "react-icons/fa";
import moment from "moment";
import data from "../../../src/data.json";
import {
  getDynamicSubjects,
  Teacher_CreateAssignemt,
  Teacher_CreateAssignemt_Table,
  Teacher_CreateAssignemt_Update,
} from "../../services/Apiservices";
import { toast } from "react-toastify";
import Danger from "../components/Toastify/Danger";
import Warning from "../components/Toastify/Warning";
import Success from "../components/Toastify/Success";
import { CButton } from "@coreui/react";
import { CSmartTable } from "@coreui/react-pro";
import { fromUnixTime } from "date-fns";

const Assignment = ({
  showProfile,
  falseShowProfile,
  notofShowProfile,
  ShowNotifications,
  showNotification,
  falseShowNotification,
  disableOnOuterTouch,
  shoq,
  KpostIframe,
  closeForm,
  openForm,
  goToMainRoutes
}) => {
  const { isBigScreen } = useResponsive();
  const EXISTING_QUESTION_BANK_CODE = "EQBC";
  const EXISTING_Assignment = "EAS";
  const NEW_QUESTION_BANK_CODE = "NQBC";
  const ADD_QUESTION_DISPLAY = "AQD";
  const [num, setNum] = useState("");
  const [num1, setNum1] = useState("");
  const [update, setUpdate] = useState(false);
  const [assignmentid, setassignmentid] = useState("");
  const [showScreen, setShowScreen] = React.useState({
    show: false,
    Type: "",
  });

  const [question, setQuestion] = React.useState(false);
  const [add, setAdd] = React.useState(false);
  const [subjectsData, setsubjectsData] = React.useState([]);
  const [singleSubject, setsingleSubject] = React.useState("");
  // console.log(singleSubject,"dfsfsdfs")
  const [AsstTitle, setAsstTitle] = useState("");
  const [AsstMarks, setAsstMarks] = useState("");
  const [AsstDate, setAsstDate] = useState('');
  const [userscredsData, setuserscredsData] = useState([]);

  const [minDate, setMinDate] = useState(null);

  const [asstAddBtn, setasstAddBtn] = useState(false);
  const [asstAddBtnClear, setasstAddBtnClear] = useState(false);
  const [asstTable, setasstTable] = useState(false);
  const [asstTables, setasstTables] = useState([]);
  const [questionTypesOption, setQuestionTypesOption] = useState("");
  const [questionType, setQuestionType] = useState("");
  const [questionTypeName, setQuestionName] = useState("");
  const [edit, setEdit] = useState(false);
  const [QTupdateBtn, setQTupdateBtn] = useState(false);
  const [QTadd, setQTadd] = useState(false);
  const [detailtedans, setDetailtedans] = useState("");
// console.log("15454",asstTables)
  const [editOPtion, setEditOption] = useState(false);
  const [editOPtionText, setEditOptionText] = useState("");
  const [optionText, setoptionText] = React.useState("");
  const [option, setoption] = React.useState([]);
  const [correctAns, setCorrectAns] = React.useState({});
  const [textAns, setTextAns] = React.useState("");
  const [quesAns, setquesAns] = React.useState({});
  const [QuestionValue, setQuestionValue] = useState("");
  const [ExamsData, setExamsData] = useState([]);
  const [AsstQuestionsData, setAsstQuestionsData] = useState([]);
  const [Quesmarks, setQuesmarks] = useState("");
  const optionsAns = [
    {
      value: "TextBox",
      id: "1",
      label: "TextBox",
    },
    { value: "TextArea", id: "2", label: "TextArea" },
    { value: "RadioButton", id: "3", label: "RadioButton" },
  ];
  const [AnswerType, setAnswerType] = useState({});
  const [getAllQues, setgetAllQues] = useState([]);
  const [dropYear, setdropYear] = useState(false);
  const [questionForAssign, setquestionForAssign] = useState(false);
  const [tableQuestionBank, setTableQuestionBank] = useState(false);
  const [unitName, setUnitName] = useState([]);
  const [isError, setIsError] = useState(false);

  const handleNumChange = (event) => {
    const limit = 3;
    setNum(
      event.target.value
      // .slice(0, limit)
    );
  };

  const handleNumChange1 = (event) => {
    const limit = 3;
    setNum1(
      event.target.value
      // .slice(0, limit)
    );
  };
  // const clickdoboptions = (e) => {
  //   const TodayDate = moment().format('DD-MM-YYYY')
  //   const EnterDate = moment(e.target.value).format('DD-MM-YYYY')

  //   if (EnterDate < TodayDate) {
  //     console.log("sdhfjksdfhjksdfh","DOBBBBBBBBBBBB")
  //     setAsstDate('')
  //   } else {
  //     console.log("dfsdf","DOBBBBBBBBBBBB")
  //     setAsstDate(e.target.value)
  //   }
  // }
  const [errorMessage, setErrorMessage] = useState('');

  const LSDValidation = (e) => {
    const TodayDate = moment().format("DD MMM YYYY")
    const EnterDate = moment(e).format('DD MMM YYYY')
     if (EnterDate >= TodayDate) {
      setAsstDate(e)
      setErrorMessage('')
    } else {
      setAsstDate('')
     
    }

  }
  const handleKeyDown = (e) => {
    e.preventDefault();
    setErrorMessage('Keyboard input is not allowed');
  };

  useEffect(() => {
    AssignmentTable();
  }, []);


  useEffect(() => {
    const min_date = new Date(new Date());
    setMinDate(moment(min_date).format("YYYY-MM-DD"));
    if (localStorage.length > 0) {
      let retrData = JSON.parse(localStorage.userCreds);
      // let subjectList = JSON.parse(localStorage.classSubjectDetails);
      // console.log("sdjhfsdhfkjdf",subjectList)
      const getSubjectListfunc = async () => {
        let allquesbod, allquestres ;

        if (retrData.userType === "Teaching") {
          allquesbod = {
            // refInstitutionId: retrData.refInstID,
            // staffId: retrData.staffID,
            refStaffID: retrData.staffID,
          };

          // allquestres = await getDynamicSubjects(allquesbod);

        }else{
          allquesbod = {

            refInstID: retrData.refInstID,
            refSectionID: retrData.classID,
            refVariableID: retrData.variableID,
         
          };
        }
        allquestres = await getDynamicSubjects(allquesbod);

        if (allquestres.status === "success") {
          setuserscredsData(allquestres.value);
          CourseListFunc(allquestres.value);

          // console.log(
          //   "LLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLL",
          //   allquesbod,
          //   allquestres
          // );
          // if(allquestres.value.length > 0){
          //   let dataOPtion = await allquestres.value.map((e) => {
          //     return { value: e.className+' - '+e.sectionName+' - '+e.subjectName, label: e.className+' - '+e.sectionName+' - '+e.subjectName, katSubjectId: e.katbookSubjectId, kamSubjectId : e.subjectId, className: e.className, classId: e.classId, sectionId: e.sectionId, sectionName: e.sectionName };
          //   });

          //   setsubjectsData(dataOPtion);

          // }

        }
      };

      getSubjectListfunc();
    }
  }, [localStorage]);






  
  const CourseListFunc = async (course) => {
   
    const SubjectList = JSON.parse(await localStorage.getItem("userCreds"))?.classSubjectDetails ?? [] ;
   
    // console.log(SubjectList,"SubjectListdadssd");
    if (SubjectList.length > 0) {
      let dataOPtion = SubjectList.map((e) => {
        // const SubjectListFind = SubjectList.find(x=>x.subjectId)
        return {
          ...e,
          value: `${e.katbookSubjectId} ${e.sectionId}`,
          // value:e.katbookSubjectId,
          data:e.katbookSubjectId,
          label: `${e.className}-${e.sectionName} ${e.subjectName}`,
          kamSubjectId: e.katbookSubjectId,
          sectionId:e.sectionId,
          classId:e.classId,
        };
      });
  
      setsubjectsData(dataOPtion);
    }
      // console.log("dfsdfsdf",subjectsData)
    };
  
    
     
  const CreateAssignmentNewfinal = async () => {
    let result;
    let body = {
      staffId:
        localStorage.length > 0 && JSON.parse(localStorage.userCreds).staffID,
        refInstId:
        localStorage.length > 0 && JSON.parse(localStorage.userCreds).refInstID,
        username:
        localStorage.length > 0 && JSON.parse(localStorage.userCreds).userName,
        katbookSubjectId: singleSubject.data,
        katbookSubjectName: singleSubject.label,
        kampusSubjectId: singleSubject.subjectId,
      classId: singleSubject.classId,
      className:singleSubject.className,
      sectionId:singleSubject.sectionId,
      sectionName:singleSubject.sectionName,
      assignmentName: AsstTitle,
      lsd: AsstDate,
      totalMarks: AsstMarks,
      staffName:
      localStorage.length > 0 && JSON.parse(localStorage.userCreds).adminName,
      staffDesignation:
        localStorage.length > 0 &&
        JSON.parse(localStorage.userCreds).designation,        
    };
    // console.log("body",body)
       try {
      result = await Teacher_CreateAssignemt(body);
      if (result){
      if (result.status==="success") {
        // console.log(result, "CREATE NEW Assignment");
        // setExamsData(result.data);
        // console.log(ExamsData, "CREATE NEW Assignment");
        toast.success("Assignment Created Succesfully");
        AssignmentTable(singleSubject.kamSubjectId,singleSubject.sectionId,singleSubject.classId);
      } else {
        toast.error(<Danger body={result.message} />);
      }
      }
    } catch (error) {
      console.log(error);
    }
  };



  const acdamyearChane = (e) => {
    if (e.value === "2019-2020") {
      setdropYear(true);
    }
    if (e.value === "2020-2021") {
      setdropYear(false);
    }
    if (e.value === "2021-2022") {
      setdropYear(false);
    }
  };

  const AssignmentSelectChange = (e) => {
    if (e.value === "Assignment 1") {
      setquestionForAssign(true);
    }
    if (e.value === "Assignment 2") {
      setquestionForAssign(false);
    }
    if (e.value === "Assignment 3") {
      setquestionForAssign(false);
    }
  };

  const notify = () => {
    toast("Assignment save Succesfully");
  };

  const AssignmentTable = async (kamSubjectId,sectionId,classId) => {
    setasstTables([]);
    var response;
    let body={
      
      staffId:
      localStorage.length > 0 && JSON.parse(localStorage.userCreds).staffID,
      katbookSubjectId: kamSubjectId,
      classId: classId,
      sectionId:sectionId,
           
    }
      //console.log("body01",body)
    try {
      response = await Teacher_CreateAssignemt_Table(body);
      // console.log(response, "kalai");
      if(response.status === "403"){
        goToMainRoutes();
      }

      if (response.status==="success") {
        // if (response.success) {
          if (response.data) {
            // toast.success(response.message);
            console.log("sdfsdfsdf",response)
            const result = response.data.map((x, index) => {
              return {
                id: index + 1,
                Course: x.katbookSubjectName,
                exam: x.assignmentName,
                marks: x.totalMarks,
                // date: x.lsd,
                katbookSubjectId:x.katbookSubjectId,
                kampusSubjectId:x.kampusSubjectId,
                classId:x.classId,
                sectionId:x.sectionId,
                dateNew:`${moment( x.lsd).format("DD-MMM-YYYY")}`,               
                date:
                  x.lsd.length > 5
                    ? x.lsd.slice(0, 10)
                    : new Date().getFullYear(),
                assignmentid: x.assignmentId,
                Posted:x.posted === true ? "Posted" : "Not Posted",

              };
            });
            setasstTables(result);
            // console.log("asss111",result)
          }
        // }
      }
    } catch (err) {
      console.log(err);
    }
  };
 
  const onUpdate = (data) => {
    console.log(data, "sasasass");
    setasstAddBtn(true);
    setasstAddBtnClear(false);
    setUpdate(true);
    setAsstTitle(data.exam);
    setAsstDate(data.date);
    setAsstMarks(data.marks);
    setassignmentid(data.assignmentid);
    CourseListFunc();

    // setsingleSubject({
    //   value: data?.singleSubject.data,
    //   label: data?.Course,
    // });

  };

  const UpdateAssignmentFunc = async (assignmentid) => {
    const data = {
      assignmentId: assignmentid,
      staffId:
        localStorage.length > 0 && JSON.parse(localStorage.userCreds).staffID,
      refInstId:localStorage.length > 0 && JSON.parse(localStorage.userCreds).refInstID,
      username: localStorage.length > 0 && JSON.parse(localStorage.userCreds).userName,
      
      katbookSubjectId: singleSubject.katbookSubjectId,
      katbookSubjectName:singleSubject.label,
      
      kampusSubjectId:singleSubject.subjectId,
      classId:singleSubject.classId,
      className:singleSubject.className,
      sectionId:singleSubject.sectionId,
      sectionName:singleSubject.sectionName,

      assignmentName: AsstTitle,
      lsd: AsstDate,
      totalMarks: AsstMarks,
      staffName:localStorage.length > 0 && JSON.parse(localStorage.userCreds).adminName,
      staffDesignation:localStorage.length > 0 &&
      JSON.parse(localStorage.userCreds).designation,
    };
    console.log("DATssssASSS",data)

    const response = await Teacher_CreateAssignemt_Update(data);
    if (response) {
      if (response.status==="success") {
        toast.success(response?.message);
        setUpdate(false);
        setAsstTitle("");
        setAsstMarks("");
        setAsstDate("");
        AssignmentTable(singleSubject.kamSubjectId,singleSubject.sectionId,singleSubject.classId);
        setasstAddBtnClear(true);
       
        // setsingleSubject("");
      } else {
        toast.error(response.message);
      }
    }
  };


  const EmptyAssigtCreateData = () => {
    // alert();
    setAsstTitle("");
    setIsError(false);
    setAsstMarks("");
    setAsstDate("");
    setQuestionValue("");
    setQuestionType("");
    setQuesmarks("");
    setoption([]);
    setTextAns("");
    setquesAns("");
    setCorrectAns("");
    setasstTable(true);
    setQTupdateBtn(false);
    setasstAddBtn(false);
    setasstAddBtnClear(true);
    setUpdate(false);
    setShowScreen({
      show: false,
      Type: "",
    });
  };

  const EmptyAssigtCreateDataClear = () => {
    // alert();
    setsingleSubject("");
    setErrorMessage('')
    setAsstTitle("");
    setAsstMarks("");
    setAsstDate("");
    setQuestionValue("");
    setQuestionType("");
    setQuesmarks("");
    setoption([]);
    setTextAns("");
    setquesAns("");
    setCorrectAns("");
    setasstTable(false);
    setQTupdateBtn(false);
    setasstAddBtnClear(false);
    setUpdate(false);
    setShowScreen({
      show: false,
      Type: "",
    });
  };

  const EmptyQuesData = () => {
    setQuestionValue("");
    setQuestionType("");
    setQuesmarks("");
    setoption([]);
    setTextAns("");
    setquesAns("");
    setCorrectAns("");
    setQTupdateBtn(false);
  };


  const CreateAssignmentFunc = () => {
    // console.log(singleSubject, "XX CREATE ASSIGNMENT");
    // console.log(AsstTitle, "XX CREATE ASSIGNMENT");
    // console.log(AsstMarks, "XX CREATE ASSIGNMENT");
    // alert()
    if (singleSubject.length === 0) {
      toast.error(<Warning body={"Please select subject"} />);
    } else if (AsstTitle === "") {
      toast.error(<Warning body={"Please enter Assignment name"} />);
    } else if (AsstMarks === "") {
      toast.error(<Warning body={"Please enter total marks"} />);
    } else if (AsstDate === "") {
      toast.error(<Warning body={"Please enter Last Date of Submission"} />);
    } else {
      setAsstTitle("");
      setAsstMarks("");
      setAsstDate("");
      CreateAssignmentNewfinal();
      setasstTable(true);
      setasstAddBtnClear(true);
     
    }
  };


// Assignment update

  const ShowAddQuestions = () => {
    setShowScreen({
      show: true,
      Type: ADD_QUESTION_DISPLAY,
    });
  };

  const showExistingQuestionBank = () => {
    setShowScreen({
      show: true,
      Type: EXISTING_Assignment,
    });
    setdropYear(false);
    setquestionForAssign(false);
  };

  const showRealExistingQuestionBank = () => {
    setShowScreen({
      show: true,
      Type: EXISTING_QUESTION_BANK_CODE,
    });
  };

  const showNewQuestionBank = () => {
    setShowScreen({
      show: true,
      Type: NEW_QUESTION_BANK_CODE,
    });
  };


  const questionTypeSaveFuc = () => {
    // console.log(questionTypeName.length,"LLLLLLLLLLLLLLLL");
    if (questionTypeName.length > 0) {
      if (questionType) {
        if (
          questionType &&
          questionTypeName !== questionType.questionTypeName
        ) {
         
        } else {
          toast.error(
            <Warning
              body={"Please edit something to update question type name!."}
            />
          );
        }
      } else {
        // createQuestionTypeFuc();
      }
    } else {
      toast.error(<Warning body={"Please enter question type name!."} />);
    }
  };

  
  // const CreateAssignmentFunc = async () => {
  //   try {
  //     const userCreds = await JSON.parse(localStorage.getItem("userCreds"));
  //     const answers = responseCollection.map((answer) => ({
  //       answer: answer.value,
  //       isCorrect: answer.value == answer.answer ? true : false,
  //       questionid: answer._id,
  //     }));
     
  //     const body = {
  //       answers: answers,
  //     };
  //     const res = await Teacher_CreateAssignemt(body);
  //     if (res) {
  //       if (res.success) {
  //         toast.success(<Success body={res.message} />);
         
  //       }
  //     } else {
  //       toast.danger(<Danger body={res?.error} />);
  //     }
  //   } catch (err) {
  //     console.log(err, "POST_ANSEWG");

  //     console.log(err);
  //   }
  // };


  const questionCancelFuc = (update) => {
    if (update) {
      setQuestionName(update.value);
      // getAllQuestionTypeFuc();
    } else {
      setEdit(false);
      setQTadd(false);
      setQTupdateBtn(false);
      setQuestionType("");
      setQuestionTypesOption("");
      setQuestionName("");
      // getAllQuestionTypeFuc();
      setDetailtedans("");
      EmptyAssigtCreateDataClear();
      
    }
  };

  const selectQuestNames = (question) => {
    setQuestionName(question);
  };

  const onChangeQuestion = async (name, e) => {
    // console.log("ASDSAD", name, e);
    setQuestionValue(e);
    if (e !== "") {
    }
  };

  const onChangeAnswerType = async (name, e) => {
    setEditOption(false);
    setEditOptionText("");
    // console.log(name, e);
    setoption([]);
    setCorrectAns({});
    setTextAns("");
    setAnswerType(e);
  };

  const addoption = async () => {
    // console.log(optionText, "tttt");
    // console.log(option, "tttt");
    // console.log(optionText.trim(), "tttt");
    if (optionText !== "") {
      var count = 0;
      await option.map((data) => {
        if (optionText.trim() !== data.name) {
          count = count + 1;
        }
        return 1;
      });
      // console.log("count", option.length, count);
      if (option.length !== count) {
        toast.warning(<Warning body={"Already this option value was added"} />);
      } else {
        // console.log(optionText, "jjj");
        await setoption([
          ...option,
          {
            _id: count + 1,
            name: optionText.trim(),
            value: parse(optionText.trim()),
            label: parse(optionText.trim()),
          },
        ]);
        await setoptionText("");
        await setCorrectAns("");
      }
    }
  };

  const editOPtionFuc = async () => {
    // console.log(optionText, "tttt");
    const findIndex = await option.findIndex((x) => {
      if (String(x._id) === String(editOPtionText._id)) {
        return true;
      }
      return false;
    });
    if (findIndex >= 0) {
      if (optionText !== "") {
        var count = 0;
        await option.map((data) => {
          if (optionText.trim() !== data.name) {
            count = count + 1;
          }
          return 1;
        });
        // console.log("count", option.length, count);
        if (option.length !== count) {
          toast.warning(
            <Warning body={"Already this option value was added"} />
          );
        } else {
          // console.log(optionText, "jjj");

          if (
            String(option[findIndex].name).toLocaleLowerCase().trim() !==
            String(optionText).toLocaleLowerCase().trim()
          ) {
            let data = await option.map((x) => {
              if (String(x._id) === String(editOPtionText._id)) {
                return {
                  _id: x._id,
                  name: optionText.trim(),
                  value: parse(optionText.trim()),
                  label: parse(optionText.trim()),
                };
              } else {
                return {
                  _id: x._id,
                  name: x.name.trim(),
                  value: parse(x.name.trim()),
                  label: parse(x.name.trim()),
                };
              }
            });

            await setoption("");
            await setoptionText("");
            await setCorrectAns("");
            await setEditOptionText("");
            await setEditOption("");
            await setoption(data);
            // console.log(data, "tttt");
          } else {
            toast.warning(
              <Warning body={"Edit something to update the option!"} />
            );
          }
        }
      }
    } else {
      toast.warning(<Warning body={"Select vaild option text!"} />);
    }
  };

  const onChangeFuc = (e) => {
    console.log(e);
    if (editOPtion) {
      setoptionText(e);
    } else {
      setoptionText(e);
    }
  };


  const asstcolumns = [
    {
      key: "id",
      label: "S.No",
      filter: false,
      sorter: false,
      _style: { width: "5%" },
    },
    {
      key: "Course",
      label: "Subject",
      filter: true,
      sorter: true,
      _style: { width: "20%" },
    },
    {
      key: "exam",
      label: "Assignment Name",

      filter: true,
      sorter: true,
      _style: { width: "25%" },
    },
    {
      key: "marks",
      label: "Total Marks",

      _style: { width: "20%" },
      filter: true,
      sorter: true,
    },
    {
      key: "dateNew",
      label: "Last Date of Submission",
      _style: { width: "20%" },
      filter: true,
      sorter: true,
    },
    {
      key: "show_details",
      label: "Action",
      _style: { width: "10%" },
      filter: false,
      sorter: false,
    },
  ];

  const columns = [
    {
      key: "id",
      label: "No",
      filter: false,
      sorter: false,
      _style: { width: "15%" },
    },
    {
      key: "name",
      label: "Question",
      filter: true,
      sorter: true,
      _style: { width: "25%" },
    },
    {
      key: "role",
      label: "Answer",

      filter: true,
      sorter: true,
      _style: { width: "25%" },
    },
    {
      key: "status",
      label: "Marks",

      _style: { width: "20%" },
      filter: true,
      sorter: true,
    },
    {
      key: "show_details",
      label: "Action",
      _style: { width: "10%" },
      filter: false,
      sorter: false,
    },
  ];

  const usersData = [
    {
      id: 1,
      name: "What is your name ?",
      role: "My name is Jai Balaji",
      status: "100",
    },
    {
      id: 2,
      name: "What is your company name ?",
      role: "My company name is Kpost Software pvt ltd",
      status: "100",
    },
    {
      id: 3,
      name: "What is your Designation  ?",
      role: "My designation is Software Developer",
      status: "100",
    },
  ];

  const acdamyear = [
    {
      value: "2019-2020",
      label: "2019-2020",
    },
    {
      value: "2020-2021",
      label: "2020-2021",
    },
    {
      value: "2021-2022",
      label: "2021-2022",
    },
  ];


  const assignmentSelect = [
    {
      value: "Assignment 1",
      label: "Assignment 1",
    },
    {
      value: "Assignment 2",
      label: "Assignment 2",
    },
    {
      value: "Assignment 3",
      label: "Assignment 3",
    },
  ];

  return (
    <div onClick={() => disableOnOuterTouch()}>
      <div className="Navbar">
        <Header
          showProfile={showProfile}
          falseShowProfile={falseShowProfile}
          notofShowProfile={notofShowProfile}
          ShowNotifications={ShowNotifications}
          showNotification={showNotification}
          falseShowNotification={falseShowNotification}
        />
       
      </div>
      <div className={"Dash_home_QWI"}>
        <div
          className="myattendcard2w p-3"
          // style={{
          //   height: window.innerHeight - 117 + "px",
          //   overflowY: "auto",
          // }}
        >
          <KHeader
            title="Create Assignment"
            containerClassName={`marginTop-20`}
          />
          <div className="container-fluid px-5 py-3">
            {/* Create Assignment Component Starts With Responsive */}
            <div className="d-flex flex-column flex-md-row">
              <KDropdown
                containerClassName="w-75"
                label="Select Subject "
                isRequired={true}
                placeholder="Select Subject"
                value={singleSubject}
                options={subjectsData}
               
                onChange={(e) => {
                  setsingleSubject(e);
                  setasstTable(true);
                  setasstAddBtnClear(true);
                  AssignmentTable(e.kamSubjectId,e.sectionId,e.classId);
                  setAsstTitle('');
                  setAsstMarks('');
                  setAsstDate('');
                  setUpdate(false);
                }}
               />

              <KInput
                label="Assignment Name"
                isRequired={true}
                disabled={!singleSubject}
                placeholder="Enter Assignment Name"
                // width={"300px"}
                containerClassName="pl-md-3 pl-0 pt-2 pt-md-0 w-75"
                onChange={setAsstTitle}
                value={AsstTitle}
              />
              <KInput
                label="Total Marks"
                isRequired={true}
                placeholder="Enter Total Marks"
                containerClassName="pl-md-3 pl-0 pt-2 pt-md-0 w-75"
                labelClassName={"width-maxContent"}
                inputClassName="w-100"
                type="number"
                value={AsstMarks}
                error={isError ? "It should be 3 digits only" : ""}
                // onChange={setAsstMarks}
                onChange={(value) => {
                  if (value < 0) {
                    value = 0;
                  }
                  setAsstMarks(value);
                  
                  if (value.length > 3) {
                    setIsError(true);
                  } else {
                    setIsError(false); 
                  }
                }}
                disabled={!singleSubject || !AsstTitle}
                // toast={toast}
                // Warning={Warning}
              />
              <KInput
                type="date"
                
                label="Last Date of Submission"
                containerClassName="pl-md-4 pl-0 pt-2 pt-md-0 w-75"
                labelClassName={"width-maxContent readonly"}
                inputClassName={"w-100"}
                isRequired={true}
                value={AsstDate}
                min={minDate}
                onKeyDown={handleKeyDown}
                // onMouseDown={(e) => e.preventDefault()} 
                disabled={!singleSubject || !AsstTitle || !AsstMarks}
                onChange={(e) => {
                  LSDValidation(e);
                }}
                
                error={errorMessage}
              />
             
              <div className="w-100 pt-3 pt-md-0 d-flex align-items-end  pl-0 pl-md-3 cursor-pointer">
                {update ? (
                  <>
                    <div>
                      <KButton
                        onClick={() => {
                          UpdateAssignmentFunc(assignmentid);
                          setasstAddBtn(true);
                        }}
                        value="Update"
                        isDisabled={isError}
                        className="px-3 py-2 w-100"
                      />
                    </div>

                    {asstAddBtn && (
                      <div className="w-100 pt-3 pt-md-0 d-flex align-items-end pl-0 pl-md-3 cursor-pointer">
                        <KButton
                          onClick={() => {
                            EmptyAssigtCreateData();
                            setIsError(false);
                          }}
                          value="CANCEL"
                          className={`px-3 py-2 ${
                            isBigScreen ? "w-auto" : "w-100"
                          }`}
                          isDisabled={false}
                          btnColor="red"
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <div className="">
                      <KButton
                        onClick={() => {
                          CreateAssignmentFunc();
                          AssignmentTable(singleSubject.sectionId);
                        }}

                        value="Save"

                        className="px-3 py-2 w-100"

                        // isDisabled={!AsstDate}
                        
                        isDisabled={!singleSubject || !AsstTitle || !AsstMarks || !AsstDate || isError}
                         
                      />
                    </div>

                    {asstAddBtnClear && (
                      <div className="w-100 pt-3 pt-md-0 d-flex align-items-end pl-0 pl-md-3 cursor-pointer">
                        <KButton
                          onClick={() => {
                            EmptyAssigtCreateDataClear();
                            setIsError(false);
                            
                          }}
                          value="Clear"
                          className={`px-3 py-2 ${
                            isBigScreen ? "w-auto" : "w-100"
                          }`}
                          isDisabled={false}
                          btnColor="red"
                        />
                        
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>

            {asstTable && (
              <div className="mt-5">
                <CSmartTable
                  // itemsPerPageSelect
                  items={asstTables}
                  itemsPerPage={5}
                  pagination
                  itemsPerPageSelect={asstTables.length > 5}
                  columnFilter
                  columnSorter
                  columns={asstcolumns}
                  scopedColumns={{
                    show_details: (item) => (
                      <td className="gapflex">
                        {/* {JSON.stringify(item)} */}
                        <button
                          type="button"
                          className="texteditBtn btn-primary btn-sm addblue  mr-2 ml-2"
                          title="Edit"
                          onClick={() => {
                            onUpdate(item);
                          }}
                          disabled={
                            item.Posted === "Posted"
                           ? true
                           : false
                          }
                        > 
                          <i className="fa fa-edit" />
                        </button>
                        {/* <button
                          type="button"
                          className="btn btn-danger btn-sm cancered"
                          title="Delete"
                          onClick={() => {
                            onDelete(item.assignmentid);
                          }}
                        >
                          <i className="fa fa-trash-o" />
                        </button> */}
                      </td>
                    ),
                  }}
                  tableProps={{ hover: true, responsive: true }}
                />
              </div>
            )}
          </div>
          {/* Create Assignment Component Ends */}
          {showScreen.show && (
            <React.Fragment>
              {/* Add Questions Starts With Responsive */}
              <KHeader title="Add Questions" />
              <div className="container-fluid px-5 py-3">
                <div className="d-flex flex-column flex-md-row">
                  <KButton
                    value="Existing Question Bank"
                    className={`px-6 py-3 mr-3  ${
                      isBigScreen ? "w-25" : "w-100"
                    }`}
                    onClick={() => {
                      showRealExistingQuestionBank();
                    }}
                  />

                  <KButton
                    value="Existing Assignments"
                    className={`px-6 py-3 mr-3  ${
                      isBigScreen ? "w-25" : "w-100 mt-2"
                    }`}
                    onClick={() => {
                      showExistingQuestionBank();
                    }}
                  />

                  <KButton
                    value="Create New Question Bank"
                    className={`px-6 py-3 ${
                      isBigScreen ? "w-25" : "w-100 mt-2"
                    }`}
                    onClick={() => {
                      showNewQuestionBank();
                      // getAllQuestionTypeFuc();
                      console.log(ExamsData, "CREATE NEW EXAMS");
                    }}
                  />
                </div>
                {showScreen.Type == NEW_QUESTION_BANK_CODE && (
                  <React.Fragment>
                    {/* Creation Of Question Header */}

                    <div className="d-flex flex-column flex-md-row marginTop-20">
                      {!edit && !QTadd && (
                        <KDropdown
                          containerClassName="w-100"
                          label="Select Question Type"
                          isRequired={true}
                          edit={questionType ? true : false}
                          value={questionType && questionType}
                          options={questionTypesOption}
                          placeholder="Select Question Type"
                          labelStyle={{
                            fontSize: "20px",
                            marginBottom: "15px",
                          }}
                          onChange={(e) => {
                            // setUpdateData(data)
                            // console.log(e.value, "oooooooooooooooooo");
                            setQuestionType(e);
                            setQTupdateBtn(true);
                            // setquesAns("");
                          }}
                        />
                      )}
                      {(edit || QTadd) && (
                        <KInput
                          label="Question Type  Name"
                          isRequired={true}
                          placeholder="Enter the Question Type  Name"
                          // width={"300px"}
                          containerClassName="pl-0 pt-2 pt-md-0 w-100"
                          value={questionTypeName && questionTypeName}
                          onChange={(e) => {
                            selectQuestNames(e);
                          }}
                          SaveData={() => {
                            // questionTypeSaveFuc();
                          }}
                        />
                      )}
                      <div className="w-100 pt-3 pt-md-0 d-flex align-items-end pl-0 pl-md-3 cursor-pointer">
                        {!QTadd && (
                          <KButton
                            onClick={() => {
                              if (QTupdateBtn) {
                                questionCancelFuc(questionType);
                                setQTadd(true);
                                setEdit(true);
                              } else {
                                questionCancelFuc();
                                setQTadd(true);
                              }
                            }}
                            value={QTupdateBtn ? "UPDATE" : "ADD"}
                            className={`px-3 py-2 ${
                              isBigScreen ? "w-auto" : "w-100"
                            }`}
                            isDisabled={false}
                          />
                        )}
                        {(edit || QTadd) && (
                          <KButton
                            onClick={() => {
                              // console.log(QTadd,"LLLLLLLLLLLLLLLL");
                              if (QTadd) {
                                // console.log(QTadd,"LLLLLLLLLLLLLLLL");
                                questionTypeSaveFuc();
                              } else {
                                questionCancelFuc();
                                setEdit(true);
                              }
                            }}
                            value={edit ? "CONFIRM" : "CONFIRM"}
                            className={`px-3 py-2 ${
                              isBigScreen ? "w-auto" : "w-100"
                            }`}
                            isDisabled={false}
                          />
                        )}
                        {(edit || QTadd) && (
                          <KButton
                            onClick={() => {
                             
                              questionCancelFuc();
                            }}
                            value="CANCEL"
                            className={`ml-3 px-3 py-2 ${
                              isBigScreen ? "w-auto" : "w-100"
                            }`}
                            isDisabled={false}
                            btnColor="red"
                            txtColor="#fff"
                          />
                        )}
                      </div>
                    </div>
                    <div className="d-flex flex-row justify-content-between">
                      <div className="d-flex flex-row">
                        <div className="d-flex align-items-end mb-2">
                          {/* <IoMdArrowRoundBack
                            className="cursor-pointer"
                            onClick={() => ResetToDefaultScreen()}
                            size={25}
                          /> */}
                        </div>
                        {/* <KHeader title="New Question Bank" /> */}
                      </div>
                      <div className="d-flex align-items-end mb-2">
                        {/* <KButton value="Create" className={`px-3 py-1`} /> */}
                      </div>
                    </div>
                    {/* 
  Creation Of Question Header Ends
*/}
                    <div className="d-flex flex-row justify-content-between my-3">
                      <KRequiredLabel
                        title="Enter Question"
                        className="h5 mt-3"
                        style={{
                          display: "inline-block",
                          alignSelf: "flex-end",
                        }}
                      />
                      <KInput
                        label="Marks"
                        isRequired={true}
                        placeholder="Enter Marks"
                        containerClassName={`pl-md-3 pl-0 pt-2 pt-md-0 w-25`}
                        labelClassName={"width-maxContent"}
                        inputClassName="w-25"
                        type="number"
                        value={Quesmarks}
                        onChange={setQuesmarks}
                        toast={toast}
                        Warning={Warning}
                      />
                    </div>
                    <KEditor
                      height={isBigScreen ? 400 : 500}
                      value={QuestionValue}
                      handleEditorChange={(content) => {
                        onChangeQuestion("Question", content);
                      }}
                    />
                    <KDropdown
                      labelStyle={{
                        fontSize: "20px",
                        marginBottom: "15px",
                      }}
                      containerClassName={`marginTop-20 ${
                        isBigScreen ? "w-25" : "w-100"
                      }`}
                      label="Answer Type"
                      isRequired={true}
                      placeholder="Select Answer Type"
                      options={optionsAns}
                      value={AnswerType}
                      onChange={(e) => onChangeAnswerType("AnswerTypes", e)}
                      isDisabled={QuestionValue ? false : true}
                    />

                    {AnswerType && AnswerType.value === "RadioButton" && (
                      <>
                        <Row
                          style={{
                            marginTop: "17px",
                            color: "black",
                          }}
                        >
                          <Col sm="10" md="10" lg="7">
                            <div
                              style={{ marginBottom: "20px", color: "black" }}
                            >
                              <KRequiredLabel
                                title="Options"
                                className="h5 mt-3"
                                style={{
                                  display: "inline-block",
                                  alignSelf: "flex-end",
                                }}
                              />
                            </div>
                            {/* <CInput
                              style={{ marginTop: '8px' }}
                              onChange={(e) => {
                                onChangeFuc(e);
                              }}
                              value={optionText}
                              placeholder={"Options"}
                            /> */}
                            <KEditor
                              height={isBigScreen ? 400 : 500}
                              value={optionText}
                              handleEditorChange={(content) => {
                                onChangeFuc(content);
                              }}
                            />
                          </Col>

                          <Col sm="2" md="2" lg="4">
                            <KButton
                              width={60}
                              height={50}
                              value={editOPtion ? "UPDATE" : "ADD"}
                              className={`px-3 py-2 ${
                                isBigScreen ? "w-auto" : "w-100"
                              }`}
                              style={{
                                position: "absolute",
                                bottom: "0px",
                                marginBottom: "20px",
                              }}
                              onClick={() => {
                                if (optionText) {
                                  if (editOPtion) {
                                    editOPtionFuc();
                                  } else {
                                    addoption();
                                  }
                                } else {
                                  toast.warning(
                                    <Warning body={"Enter Your Option Name"} />
                                  );
                                }
                              }}
                            />
                          </Col>
                        </Row>
                      </>
                    )}

                    {option.length !== 0 &&
                      AnswerType.value === "RadioButton" && (
                        <Row
                          style={{
                            marginTop: "17px",
                            color: "black",
                            paddingLeft: "18px",
                          }}
                        >
                          <Col
                            sm="12"
                            md="6"
                            lg="3"
                            style={{ marginTop: "15px", marginBottom: "15px" }}
                          >
                            {/* <div style={{ marginBottom: '10px', color: 'black' }}>
                            <h6 style={{ fontWeight: 'bold' }}>{name}<span className="text-danger">{star}</span></h6>
                        </div> */}
                            {/* <KRequiredLabel
                              title="Correct Answers in Options"
                              className="h5 mt-3"
                              style={{
                                display: "inline-block",
                                alignSelf: "flex-end"
                              }}
                            /> */}
                            <KDropdown
                              label={"Correct Answers in Options *"}
                              options={option}
                              // value={correctAns}
                              value={correctAns}
                              onChange={(e) => {
                                setCorrectAns(e);
                                // console.log(e, "WWWWWWWWWWWWWW");
                              }}
                              placeholder={"Select correct answer"}
                            />
                          </Col>
                          <Col sm="2" md="2" lg="2">
                            {!editOPtion ? (
                              <KButton
                                width={60}
                                height={50}
                                className="btn btn-info"
                                style={{
                                  position: "absolute",
                                  bottom: "0px",
                                  marginBottom: "20px",
                                }}
                                onClick={() => {
                                  if (correctAns && correctAns._id) {
                                    console.log(correctAns);
                                    setEditOption(true);
                                    setEditOptionText(correctAns);
                                    setoptionText(correctAns.name);
                                  } else {
                                    toast.warning(
                                      <Warning
                                        body={
                                          "Please edit an option which your are edit!."
                                        }
                                      />
                                    );
                                  }
                                }}
                                value="EDIT"
                              />
                            ) : (
                              <KButton
                                width={60}
                                height={50}
                                className="btn btn-danger"
                                style={{
                                  position: "absolute",
                                  bottom: "0px",
                                  marginBottom: "20px",
                                }}
                                onClick={() => {
                                  setEditOption(false);
                                  setEditOptionText("");
                                  setoptionText("");
                                }}
                                value="CANCEL"
                              />
                            )}
                          </Col>
                        </Row>
                      )}

                    {AnswerType &&
                    (AnswerType.value === "TextBox" ||
                      AnswerType.value === "TextArea") ? (
                      <>
                        <KRequiredLabel
                          title="Correct Answer"
                          className="h5 marginTop-20"
                        />
                        <KEditor
                          height={isBigScreen ? 400 : 500}
                          value={textAns || quesAns}
                          handleEditorChange={(content) => {
                            setquesAns(content);
                          }}
                        />
                      </>
                    ) : null}
                    <div
                      className="d-flex flex-column flex-md-row mt-3"
                      style={{
                        paddingTop: "30px",
                        paddingBottom: "100px",
                        float: "right",
                      }}
                    >
                      <KButton
                        value="SAVE "
                        onClick={() => {
                          // createNewQuestionsFunc();
                        }}
                        btnColor={"green"}
                        className={`px-4 py-2 `}
                      />
                      <KButton
                        value="CANCEL"
                        className={`px-4 py-2 ${isBigScreen ? "ml-3" : "mt-2"}`}
                        btnColor={"red"}
                      />
                    </div>
                  </React.Fragment>
                )}
              </div>

              {showScreen.Type == EXISTING_QUESTION_BANK_CODE && (
                <React.Fragment>
                  {/* 
Creation Of Question Header
*/}

                  <div className="mt-5"></div>
                  <div className="d-flex flex-column flex-md-row marginTop-20">
                    <KDropdown
                      containerClassName={`pl-md-3 pl-0 pt-2 pt-md-0 ${
                        isBigScreen ? "w-25" : "w-100"
                      }`}
                      label="Units"
                      isRequired={true}
                      placeholder="Select Units"
                      // options={unitSelect}
                      options={unitName}
                      onChange={(e) => {
                        // selectUnit(e.value);
                      }}
                    />
                  </div>

                  {tableQuestionBank && (
                    <div className="mt-5">
                      <div
                        style={{
                          border: "1px solid #dadbdd",
                          textAlign: "center",
                        }}
                      >
                        <CSmartTable
                          columns={columns}
                          items={usersData}
                          columnFilter
                          columnSorter
                          itemsPerPage={5}
                          itemsPerPageSelect={usersData.length > 5}
                          pagination
                          
                          scopedColumns={{
                            show_details: (item) => {
                              return (
                                <td className="gapflex">
                                  <button
                                    type="button"
                                    className="btn btn-primary btn-sm addblue"
                                    title="Edit"
                                  >
                                    <i className="fa fa-edit" />
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-danger btn-sm cancered"
                                    title="Delete"
                                  >
                                    <i className="fa fa-trash-o" />
                                  </button>
                                </td>
                              );
                            },
                          }}
                          selectable
                          tableProps={{
                            striped: true,
                            hover: true,
                          }}
                        />
                      </div>

                      <div className="d-flex flex-column justify-content-end flex-md-row pr-5 pt-3">
                        <KButton
                          value="Save"
                          btnColor={"green"}
                          className={`px-3 py-1 ButtonSize  ${
                            isBigScreen ? "ml-3" : "mt-2 ml-4"
                          }`}
                          onClick={() => {
                            notify();
                          }}
                        />
                        <KButton
                          value="Delete"
                          className={`px-3 py-1 ButtonSize ${
                            isBigScreen ? "ml-3" : "mt-2 ml-4"
                          }`}
                          btnColor={"red"}
                        />
                      </div>
                    </div>
                  )}
                </React.Fragment>
              )}
              {showScreen.Type == EXISTING_Assignment && (
                <React.Fragment>
                  {/* 
  Creation Of Question Header
*/}

                  <div className="mt-5"></div>
                  <div className="d-flex flex-column flex-md-row marginTop-20">
                    {dropYear ? (
                      <>
                        {" "}
                        <KButton
                          value="2019-2020"
                          className={`px-6 py-2 mr-3  ${
                            isBigScreen ? "w-25" : "w-100"
                          }`}
                        />
                      </>
                    ) : (
                      <>
                        <KDropdown
                          containerClassName={`pl-md-3 pl-0 pt-2 pt-md-0 ${
                            isBigScreen ? "w-25" : "w-100"
                          }`}
                          label="Select the Academic Year"
                          isRequired={true}
                          placeholder="Select the Academic Year"
                          options={acdamyear}
                          onChange={(e) => {
                            acdamyearChane(e);
                          }}
                        />
                      </>
                    )}
                    <KDropdown
                      containerClassName={`pl-md-3 pl-0 pt-2 pt-md-0 ${
                        isBigScreen ? "w-25" : "w-100"
                      }`}
                      label="Select the Assignments"
                      isRequired={true}
                      placeholder="Select the Assignments"
                      options={assignmentSelect}
                      onChange={(e) => {
                        AssignmentSelectChange(e);
                      }}
                    />
                  </div>

                  {questionForAssign && (
                    <div className="mt-5">
                      <div
                        style={{
                          border: "1px solid #dadbdd",
                          textAlign: "center",
                        }}
                      >
                        <CSmartTable
                          columns={columns}
                          columnFilter
                          columnSorter
                          items={usersData}
                          itemsPerPage={5}
                          itemsPerPageSelect={usersData.length > 5}
                          pagination
                          scopedColumns={{
                            show_details: (item) => {
                              return (
                                <td className="gapflex">
                                  <button
                                    type="button"
                                    className="btn btn-primary btn-sm addblue"
                                    title="Edit"
                                  >
                                    <i className="fa fa-edit" />
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-danger btn-sm cancered"
                                    title="Delete"
                                  >
                                    <i className="fa fa-trash-o" />
                                  </button>
                                </td>
                              );
                            },
                          }}
                          selectable
                          tableProps={{
                            striped: true,
                            hover: true,
                          }}
                        />
                      </div>

                      <div className="d-flex flex-column justify-content-end flex-md-row pr-5 pt-3">
                        <KButton
                          value="Save"
                          btnColor={"green"}
                          className={`px-3 py-1 ButtonSize  ${
                            isBigScreen ? "ml-3" : "mt-2 ml-4"
                          }`}
                          onClick={() => {
                            notify();
                          }}
                        />
                        <KButton
                          value="Delete"
                          className={`px-3 py-1 ButtonSize ${
                            isBigScreen ? "ml-3" : "mt-2 ml-4"
                          }`}
                          btnColor={"red"}
                        />
                      </div>
                    </div>
                  )}
                </React.Fragment>
              )}

              {/* Question & Answers with Responsive Starts */}

              <div className="container-fluid px-3 py-3">
                {question && (
                  <div>
                    <div className="d-flex flex-row justify-content-between align-items-center pb-2">
                      <KHeader
                        title="Question & Answers"
                        style={{
                          marginLeft: "0px !important",
                        }}
                      />
                      <KButton
                        value="Add"
                        onClick={() => {
                          setQuestion(false);
                          showNewQuestionBank();
                        }}
                        className={"height-fitContent px-4 py-2 "}
                      />
                    </div>

                    {getAllQues.map((items, index) => (
                      <div className="mx-5 my-3">
                        <div className="d-flex flex-row gap-3 align-items-center">
                          <div className="DivBox">
                            {/* Question 1 */}
                            {parse(items.question)}
                            {items.options.map((q, index) => (
                              <div>{parse(q.option)}</div>
                            ))}
                            {/* {parse(items.options)} */}
                          </div>

                          <KInput
                            placeholder="Enter Marks"
                            type="text"
                            value={items.marks}
                          />
                          <div
                            style={{
                              display: "flex",
                              // paddingLeft: "145px",
                              gap: "30px",
                            }}
                          >
                            <div className="borderCircleEdit">
                              <AiOutlineEdit size="20" />
                            </div>

                            <div className="borderCircleDelete">
                              <RiDeleteBin6Line size="20" />
                            </div>
                          </div>
                        </div>

                        <div className="DivBoxBig mt-3 mb-5">
                          {/* Answer 1 */}
                          {parse(items.correctAnswer)}
                        </div>
                      </div>
                    ))}
                  </div>
                )}

                {/* Question & Answers with Responsive Ends */}
              </div>
              {/* Add Questions Starts With Responsive Ends */}
            </React.Fragment>
          )}
        </div>
      </div>

      <Footer
        openForm={openForm}
        shoq={shoq}
        closeForm={closeForm}
        KpostIframe={KpostIframe}
      />
    </div>
  );
};

export default Assignment;