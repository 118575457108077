import React, {useEffect,useState,} from "react";
import Header from "./HeaderNavbar";
import Footer from "./Footer";
import { CCol, CRow } from "@coreui/react";
import Img from "../../Assets/Img.png";
import TimeTableCard from "./Dashboard_folder/TimeTableCard";
import { RiArrowDropUpLine, RiArrowDropDownLine } from "react-icons/ri";
import { IoIosArrowForward, IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
// import ExamSchedule from "./Dashboard_folder/ExamSchedule";
// import Assigments from "./Dashboard_folder/Assigments";
import Example from "./Dashboard_folder/Example";
import MyCarousel from "./Dashboard_folder/MyCarousel";
import Event from "./Dashboard_folder/Event";
import Exam_Result from "./Dashboard_folder/Exam_Result";
import Birthday from "./Dashboard_folder/Birthday";
import ksmaclogo from "../../Assets/ksmaclogo.png";
import { useLocation, useNavigate } from 'react-router-dom';
import {getDynamicTimetable,getDynamicTimetableByStaff} from "../../services/Apiservices"
import {format } from 'date-fns'
// import Class_NoticeBoardMobi from "./Dashboard_folder/Class_NoticeBoardMobi";
// import ClassNotice_Web from "./Dashboard_folder/ClassNotice_Web";
import kpost from "../../Assets/ksmaclogo.png";
// import Subload from "../../Assets/subject_loading.gif";
// import { MdVideocam } from "react-icons/md"
// import {getUnitSessionNameFromtable} from "../../services/Apiservices"
import Subload from "../../Assets/subject_loading.gif";
import TimeTableClass from './Dashboard_folder/TimeTableClass';
import TimeTableTeacher from './Dashboard_folder/TimeTableWeekTeacher';
import Img1 from "../../Assets/Img.png";
import Teacher_Exam_Result from "./Dashboard_folder/Teacher_Exam_Result";






const TDashboard = ({
  VideoConfFunc,
  tConvert,
  CuttentDateTime,
  tableBgColors,
  openBookview,
  showProfile,
  falseShowProfile,
  notofShowProfile,
  ShowNotifications,
  showNotification,
  falseShowNotification,
  disableOnOuterTouch,
  shoq,
  KpostIframe,
  closeForm,
  openForm,
  trueShowNotification, 
  // For Week
  day,subjectdate,subjectData,currentdateexists,
currentTime,JoinMeeting,OpenBook,cursor,peroidList,colorsList,fontcolor,
user1,image
  // For Week

}) => {

  const navigate = useNavigate();

  const [userCredsvalues, setuserCredsvalues] = useState('');
  const [timeTableData, settimeTableData] = useState([]);
  const [timeTablePeriod, settimeTablePeriod] = useState([]);
  const [singleDateValue, setsingleDateValue] = useState([]);
  const [dateEnable, setdateEnable] = useState('');
  const [selectedDate, setselectedDate] = useState('');

  const yearMonth = format(new Date(),"dd MMM, yyyy");
  const [subfetchs, setSubfetchs] = useState(true);
  const [showOption, setShowOption] = useState(false);
  

  // if(localStorage.length > 0){
  //   console.log(JSON.parse(localStorage.userCreds),"TTTTTTTTTTTTTTTTTTTTTTTTTTT");
  // } else {
  //   console.log("no local","TTTTTTTTTTTTTTTTTTTTTTTTTTT");
  //   <Navigate to="/login" />
  // }
  
  // const CuttentDateTime  = new Date().toLocaleString('en-US', { hour12: false, 
  //   hour: "numeric", 
  //   minute: "numeric"});

  const ApiClassNotice=[
    {date: "22 Jun 2022",
    notice: "Jan 26 will be holiday"},
    {date: "22 Jun 2022",
    notice: "Class field trip"},
    {date: "22 Jun 2022",
    notice: "Physics special class"},
    {date: "22 Jun 2022",
    notice: "Botany revision test"},
    {date: "22 Jun 2022",
    notice: "Chemistry Special class"},
    {date: "22 Jun 2022",
    notice: "Maths special class"},
    {date: "22 Jun 2022",
    notice: "Jan 26 will be holiday"},
    {date: "22 Jun 2022",
    notice: "Class field trip"},
    {date: "22 Jun 2022",
    notice: "Physics special class"},
    {date: "22 Jun 2022",
    notice: "Botany revision test"},
    {date: "22 Jun 2022",
    notice: "Chemistry Special class"},
    {date: "22 Jun 2022",
    notice: "Maths special class"},
  ]

  var items = [
    {
        image: "https://res.cloudinary.com/vairam24/image/upload/v1658816033/chess_competition_pxxggn.jpg",
        name: "School Chess Competition ",
        description: "View more"
    },
    {
        image: "https://res.cloudinary.com/vairam24/image/upload/v1658816033/NCC_Day_gvezyk.jpg",
        name: "School NCC Day",
        description: "View more"
    },
    // {
    //     image: "https://res.cloudinary.com/vairam24/image/upload/v1658816033/Library_Day_a94fxs.jpg",
    //     name: "School Library Day",
    //     description: "View more"
    // },
    // {
    //     image: "https://res.cloudinary.com/vairam24/image/upload/v1658816033/Speech_competition_gvlohh.jpg",
    //     name: "School Speech competition",
    //     description: "View more"
    // },
]
 
  const Assigment = [
  {subject:"Maths", banner: "Fourier series half range in an range of maths", color: "#06C270"},
  {subject:"English", banner: "Assignment 2", color: "#9B36EA"},
  {subject:"Physics", banner: "Assignment 3 in a position of assign_date of subject recruitment physics", color: "#FC5F37"},
  {subject:"Botany", banner: "Assignment 4", color: "#3377FF"},
  {subject:"Chemistry", banner: "Assignment 5", color: "#F68E13"},
  {subject:"Maths", banner: "Fourier series half range in an range of maths", color: "#06C270"},
  {subject:"English", banner: "Assignment 2", color: "#9B36EA"},
  {subject:"Physics", banner: "Assignment 3", color: "#FC5F37"},
  {subject:"Botany", banner: "Assignment 4", color: "#3377FF"},
  {subject:"Chemistry", banner: "Assignment 5", color: "#F68E13"},
]

const ExamSchedukeArray = [
  {subject:"English", unit:"Unit 1 - Unit name", date:20, month:"Sep"},
  {subject:"Tamil", unit:"Unit 1 - Unit name", date:21, month:"Sep"},
  {subject:"Maths", unit:"Unit 1 - Unit name", date:22, month:"Sep"},
  {subject:"Physics", unit:"Unit 1 - Unit name", date:23, month:"Sep"},
  {subject:"Chemistry", unit:"Unit 1 - Unit name", date:24, month:"Sep"},
  {subject:"Botany", unit:"Unit 1 - Unit name", date:25, month:"Sep"},
]

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let location = useLocation();
  // console.log(location.state?.refInstID,"conss");

  const current = new Date();
  const date = ` ${current.getDate()} ${
    months[current.getMonth()]
  } ${current.getFullYear()}`;

  const CurrentDateItem = format(new Date(),"dd/MMM/yyyy");

  useEffect(() => {

    if(localStorage.length > 0){

      let retrData = JSON.parse(localStorage.userCreds);

      setuserCredsvalues(retrData);

      // console.log(retrData,"YYYYYYYYYYYYYYYYYYYYYYYYYY");
      
      const getTimeTablefunc = async () => {

        let allquesbod,allquestres;

        if(retrData.userType === "Teaching"){

          allquesbod = {
            refInstID: retrData.refInstID,
            refStaffID: retrData.staffID,
            refYearID: retrData.yearId,
        };

        allquestres =await  getDynamicTimetableByStaff(allquesbod);

        } else {

          allquesbod = {
            refInstID: retrData.refInstID,
            refMediumID: retrData.mediumId,
            refYearID: retrData.yearID,
            refSectionID: retrData.classID,
            refVariableID: retrData.variableID,
          };

          allquestres =await  getDynamicTimetable(allquesbod);

        }
      
        

        if(allquestres.status === 'success'){
          // console.log(JSON.parse(allquestres.periodDetails)[0].breakName);
          // console.log(JSON.parse(allquestres.periodDetails).filter((item) => item.breakName === undefined));
          settimeTablePeriod(JSON.parse(allquestres.periodDetails).filter((item) => item.breakName === undefined));
          settimeTableData(allquestres.value);

          Object.keys(allquestres.value).map((item, i) => {

            if(i === 0){
              // console.log(allquestres.value[item]);
              setsingleDateValue(allquestres.value[item]);
              setselectedDate(item);
              // alert(CurrentDateItem)
              //   alert(item.split(" ")[1])
              if(CurrentDateItem === item.split(" ")[1]){
              setdateEnable(0);
              }
            } else {

            }

          })
        }

      };
  
      getTimeTablefunc();

      

    } else {
      const goToHomePage = () => navigate("/login");
      goToHomePage();
    }
    
  },[]);


//  console.log(CuttentDateTime,"TABLE COLORS");


  const fetchdateWisetable = async(datevalue, index) =>{

    setsingleDateValue(timeTableData[datevalue]);
    setselectedDate(datevalue);

    if(CurrentDateItem === datevalue.split(" ")[1]){
      setdateEnable(0);
    } else {
      if(index === 0){
        setdateEnable('');
      } else {
        setdateEnable(index);
      }
      
      }
    

  }

  const instDetail = localStorage.getItem("instDetail");
  const userInstDetail = JSON.parse(instDetail);

// console.log(JSON.parse(localStorage.userCreds).institutionName.toUpperCase())

 // Header Functions End
  return (
    <div style={{overflow: 'hidden'}}
    onClick = {()=>disableOnOuterTouch()}
    >
      <Header 
        showProfile={showProfile}
        // profileIcon={KpIcon}
        // username={state.adminName ? state.adminName : state.user}
        falseShowProfile={falseShowProfile}
        notofShowProfile={notofShowProfile}
        institutionName={localStorage.length > 0 && JSON.parse(localStorage.userCreds).institutionName.toUpperCase()}
        ShowNotifications={ShowNotifications}
        showNotification={showNotification}
        falseShowNotification={falseShowNotification}
        trueShowNotification={trueShowNotification}
      />
      <div className={"Dash_home_QWI"}>
        <CCol md="12" className="padkamin">
        <div className={"Padding_Time"} >
          <div className={"Time_Apr"}>
            <div>
              <span className={"Time_Table tableText"}>Timetable</span>
            </div>
            <div className={"M_D_W"}>
              <div>
                <span className={"Apr_22"}>{yearMonth}</span>
              </div>
            </div>
          </div>
          <div className={"Flex_Row_Period"}>
              <div className={"Period_Font"}>
              </div>
              { JSON.parse(localStorage.userCreds).userType === 'Student' && timeTablePeriod.map((x, i) => (
                <div className={"Class_Period"}>
                <span className={"Period_Font"}>
                    Period {x.PeriodNumber}
                </span>
                <span className={"Period_Time"}> 
                    ({tConvert(x.StartTime)} - {tConvert(x.EndTime)})
                </span>
                </div>
              ))}              
            </div>            
        </div>
        <div className={"timeTable_Home_scrns"} style={{
          }}>
            <>
    
      { JSON.parse(localStorage.userCreds).userType === 'Teaching' && Object.keys(timeTableData).map((x, i) => {
return (
      <>
<TimeTableTeacher
day={x.split(" ")[0].slice(0, 3)}
subjectdate={x.split(" ")[1]}
subjectData={timeTableData[x]}
tConvert={tConvert}
peroidList={timeTablePeriod}
colorsList={tableBgColors}
currentDate={CurrentDateItem}
currentTime={CuttentDateTime}
JoinMeeting={VideoConfFunc}
cursor={"pointer"}
currentdateexists={x.split(" ")[1] === CurrentDateItem ? 0 : 1}
OpenBook={openBookview}
format={format}
subject1={"English"}
subject2={"Maths"}
subject3={"Physics"}
subject4={"Botany"}
subject5={"Zoology"}
subject6={"Tamil"}
subject7={"Chemistry"}
subject8={"Maths"}
bordercolor1={"#9B36EA"} /*purple*/
bordercolor2={"#06C270"} /*green*/
bordercolor3={"#FC5F37"} /*orange*/
bordercolor4={"#3377FF"} /*blue*/
bordercolor5={"#3377FF"} /*blue*/
bordercolor6={"#FF3E84"} /*red*/
bordercolor7={"#F78E13"} /*light-orange*/
bordercolor8={"#06C270"} /*green*/
iconcolor={"#9B36EA"}
color={"#9B36EA"}
fontcolor={"#2A004A"}
cardcolor={"#FAF4FF"}
user1={"Catherin"}
image={Img1}
/>
    </>
      )
})}
      </>
      </div>
       
<div className={"Period_Font"}>
  
</div>

          <div className="containerHRFlow"></div>

       
        <div className="containerHRFlow"></div>
        </CCol>
        <CRow>
        <CCol md="8" className={"float-left column_Box paddingRight-0"}>
          <h5 className={"Time_Table"} style={{margin: "0px 20px"}}>Attendance</h5>
          <div className="digcolumnRownaki">
          <h5 className={"MyAttenMobi appbuild"}>Attendance</h5>
          <div className="d-flex flex-row minusMarilef2">
                {/* child 2.1 */}
                <div>
                  <span className="green-bullet-sm">&#8226;</span>
                </div>
                {/* child 2.2 */}
                <div className="dateaten">{date}</div>
              </div>
          </div>
          <div className="myattendcardteach">
            <div className="d-flex flex-row justify-content-between myatenteacher">
              {/* child1 */}
              <div className="d-flex flex-row gap-3">
                {/* child1.1 */}
                <div className="d-flex flex-column">
                  {/* child1.1.1 */}
                  <div className="attenpara">Present</div>
                  {/* child 1.1.2 */}
                  <div className="attennumber">153</div>
                </div>
                {/* child1.1 end*/}

                {/* Child1.2 */}
                <div className="d-flex flex-column padAbsent17">
                  {/* child 1.2.1 */}
                  <div className="attenpara">Absent</div>
                  {/* child 1.2.2 */}
                  <div className="attennumber padlef17">17</div>
                </div>
                {/* child1.2 end*/}

                {/* child 1.3 */}
                <div className="d-flex flex-column">
                  {/* child 1.3.1 */}
                  <div className="attenpara attenpadapada">
                    Total Working days
                  </div>
                  {/* child 1.3.2 */}
                  <div className="attennumber px-5">170</div>
                </div>
              </div>
            </div>

            <div className="d-flex flex-row justify-content-evenly align-items-end maririgh charttaech">
            <div className="PROGRESS">
    <div className="barboriee1"></div>
    <div className="janatten">Jan</div>
    </div>
            <div className="PROGRESS">
    <div className="barboriee2"></div>
    <div className="janatten">Feb</div>
    </div>
            <div className="PROGRESS">
    <div className="barboriee8"></div>
    <div className="janatten">Mar</div>
    </div>
            <div className="PROGRESS">
    <div className="barboriee4"></div>
    <div className="janatten">Apr</div>
    </div>
            <div className="PROGRESS">
    <div className="barboriee5"></div>
    <div className="janatten">May</div>
    </div>
            <div className="PROGRESS">
    <div className="barboriee6"></div>
    <div className="janatten">Jun</div>
    </div>
            <div className="PROGRESS">
    <div className="barboriee7"></div>
    <div className="janatten">Jul</div>
    </div>
            <div className="PROGRESS">
    <div className="barboriee9"></div>
    <div className="janatten">Aug</div>
    </div>
            <div className="PROGRESS">
    <div className="barboriee3"></div>
    <div className="janatten">Sep</div>
    </div>
            <div className="PROGRESS">
    <div className="barboriee10"></div>
    <div className="janatten">Oct</div>
    </div>
            <div className="PROGRESS">
    <div className="barboriee11"></div>
    <div className="janatten">Nov</div>
    </div>
            <div className="PROGRESS">
    <div className="barboriee12"></div>
    <div className="janatten">Dec</div>
    </div>
    </div>

<div className="teachPROGRESSweb">
    <div className="d-flex flex-row justify-content-between padfhjiko">
    <div className="chartsecteach">
      Class Attendance
    </div>
      <div className="">
          <IoIosArrowDown/>
      </div>
    </div>

<div className="Mothachart">
  <div className="chartSecrty">
    <div className="classdfgghjio">
        Class XII-A
    </div>
    <div className="Chartbackjioblue">
    <div className="rowcharblue">
    </div>
    </div>
    <div className="rowper">
      85%
    </div>
  </div>

  <div className="chartSecrty">
    <div className="classdfgghjio">
        Class XI-A
    </div>
    <div className="Chartbackjio">
    <div className="rowchar">
    </div>
    </div>
    <div className="rowper">
      80%
    </div>
  </div>

  <div className="chartSecrty">
    <div className="classdfgghjio">
        Class IX-B
    </div>
    <div className="Chartbackjiorange">
    <div className="rowcharorange">
    </div>
    </div>
    <div className="rowper">
      90%
    </div>
  </div>

  <div className="chartSecrty">
    <div className="classdfgghjio">
        Class VIII-A
    </div>
    <div className="Chartbackjioyeloo">
    <div className="rowcharyello">
    </div>
    </div>
    <div className="rowper">
      70%
    </div>
  </div>
  </div>
  </div>

  <div className="chartteachmobi">
  <div className="padfhjikomobi">
    <div className="chartsecteach">
      Class Attendance
    </div>
    </div>

    <div className="Mothachart">
    <div className="chartSecrty">
    <div className="classdfgghjio">
        Class XII-A
    </div>
    <div className="Chartbackjioblue">
    <div className="rowcharblue">
    </div>
    </div>
    <div className="rowper">
      85%
    </div>
  </div>

  <div className="chartSecrty">
    <div className="classdfgghjio">
        Class XI-A
    </div>
    <div className="Chartbackjio">
    <div className="rowchar">
    </div>
    </div>
    <div className="rowper">
      80%
    </div>
  </div>

  <div className="chartSecrty">
    <div className="classdfgghjio">
        Class IX-B
    </div>
    <div className="Chartbackjiorange">
    <div className="rowcharorange">
    </div>
    </div>
    <div className="rowper">
      90%
    </div>
  </div>

  <div className="chartSecrty">
    <div className="classdfgghjio">
        Class VIII-A
    </div>
    <div className="Chartbackjioyeloo">
    <div className="rowcharyello">
    </div>
    </div>
    <div className="rowper">
      70%
    </div>
  </div>
  </div>
  </div>

          </div>  
          
        <div style={{
          margin:"10px"
          }}>

          <CCol md="6" className={"float-left column_Box padFirsLef "}>

        <h5 className={"Time_Table"}>Assignments</h5>
        <h5 className={"AssigTimeMObi"}>Assignments </h5>
        <div className='cardassignmnetsss'> 
        <div style={{display:"flex",flexDirection:"row",gap:"25px",padding:"15px 0px"}}>
          <div className={"Class_Font"}>
            Class XII-A
          </div>
          <div className={"Class_Font_1"}>
            Class XI-C
          </div>
          <div className={"Class_Font_1"}>
            Class X-B
          </div>
        </div>
        <div className={"overfloADDIGNTEACH"}>
        {
            Assigment.map( (item) =>
            <div className='d-flex flex-column border-bottom maritb gap-4'>
            <div className='d-flex flex-row justify-content-start align-items-center gap-3'>
              
                <div className="assign_date">
                {date}
                </div>
                <div>
                {/* <span className='mathsassign'>                               
            {item.subject}
            </span> */}
                </div>
            </div>
            <div className='kildate pb-3'>
            {item.banner}
            </div>            
          </div>
            )         
        }
        </div>
        </div>
        <div className='AssigTimeMObi'> 
        {
            Assigment.map( (item, i) =>
            <div className='d-flex flex-column border-bottom maritb'>
            <div className='d-flex flex-row justify-content-start px-3'>
                <div className="assign_dateMobi">
                {date}
                </div>
                <div>
                <p className='mathsassignMobi' style={{color: item.color, backgroundColor: item.background}}>                               
            {item.subject}
            </p>
                </div>
            </div>
            <div className='kildateMobi  px-3 pb-2'>
            {item.banner}
            </div>            
        </div>
            )
        }
        </div>
        </CCol>
        <CCol md="6" className={"float-left column_Box padFirsLef paddingRight-0"}>
        <h5 className={"Time_Table"}>Exam schedule</h5>
        <h5 className={"exschDahmobi"}>Exam schedule</h5>
        
        <div className="examcard">
        <div style={{display:"flex",flexDirection:"row",gap:"25px",padding:"15px 0px"}}>
          <div className={"Class_Font"}>
            Class XII-A
          </div>
          <div className={"Class_Font_1"}>
            Class XI-C
          </div>
          <div className={"Class_Font_1"}>
            Class X-B
          </div>
        </div>
        {
                ExamSchedukeArray.map( (item) =>  
                <div className="d-flex flex-row" 
                style={{justifyContent: "flex-start",alignItems: "center",
                         gap: "20px",}}>
                <div className='engdateexa d-flex flex-column'>
                    <div className="engdatefirst">
                    {item.date}
                    </div>
                    <div className="engdatesec pt-1">
                    {item.month}
                    </div>
                </div>
                <div className='Border_Bottom ' style={{padding:"18px 0px"}}>
                <div className="engsub">
                {item.subject}
                    </div>
                    <div className="engsubdown">
                    {item.unit}
                    </div>
                </div>
                </div>
                 )}
            </div>
        <div className="examcardMobi">
        {
                ExamSchedukeArray.map( (item) =>  
                <div className="d-flex flex-row bodrigh" 
                style={{alignItems: "center"}}>
                <div className='engdateexa d-flex flex-row'>
                    <div className="engdatefirstmobi padtexhres">
                    {item.date}
                    </div>
                    <div className="engdatesecmobi mt-3 padtexhres1">
                    {item.month}
                    </div>
                </div>
                <div className="mobisubunit">
                <div className="engsubmobi">
                {item.subject}
                    </div>
                    <div className="engsubdownmobi">
                    {item.unit}
                    </div>
                </div>
                <div className="orderB"></div>
                </div>
                 )}
            </div>
        </CCol>
        </div>

          <div style={{margin:"10px"}}>
        <CCol md="6" className={"float-left column_Box padFirsLef maritopGin"}>
        <div className={"Time_Apr"}>
            <div>
              <h5 className={"Time_Table"}>Events</h5>
              <h5 className={"EvntTitle"}>Events</h5>
            </div>
            <div>
              <p className={"add_event"}>+  Add</p>
            </div>
          </div>
        <Event/>
        </CCol>

      <CCol md="6" className={"float-left column_Box desktabScholScrol padFirsLef paddingRight-0"}>
        {/* <Exam_Result/>       */}
        <h5 className={"Time_Table"}>Exam result</h5>
        <div className="flerojubet">
        <div>
      <h5 className={"MobiExaResu"}>Exam result</h5>
        </div>
      <div className="totuni_re_Mobi">
            Unit Test 1 &nbsp; 
            {showOption ? (
              <IoIosArrowUp
                onClick={() => {
                  setShowOption(false);
                }}
                className="seconary-navbar-icons"
              />
            ) : (
              <IoIosArrowDown
                onClick={() => {
                  setShowOption(true);
                }}
                className="seconary-navbar-icons"
              />
            )}
          </div>
          </div>

        <div className="ex_recard">


          <div style={{display:"flex",flexDirection:"row",gap:"30px"}}>
            <div className={"Unit_Font_1"}>
              Unit test-3
            </div>
            <div className={"Unit_Font_2"}>
              Unit test-2
            </div>
            <div className={"Unit_Font_2"}>
              Unit test-1
            </div>
          </div>
        {/* <div className="d-flex flex-row justify-content-between">
          <div className="d-flex flex-column gapExresu">
            <div className="totex_re">Total</div>
            <div>
              <span className="theeigex_re">380</span>
              <span className="fivex_re">/500</span>
            </div>
          </div>
          <div className="totuni_re">
            Unit Test 1 &nbsp; 
            {showOption ? (
              <IoIosArrowUp
                onClick={() => {
                  setShowOption(false);
                }}
                className="seconary-navbar-icons"
              />
            ) : (
              <IoIosArrowDown
                onClick={() => {
                  setShowOption(true);
                }}
                className="seconary-navbar-icons"
              />
            )}
          </div>
        </div>

        { showOption && (
                        <div className='position-absolute d-flex flex-column inter-font-500 QuestionType_Options_list' >
                            <div className='dropdown-profile-sub-title'>
                            Session 1  
                            </div>
                            <div className='dropdown-profile-sub-title'>
                            Session 2  
                            </div>
                            <div className='dropdown-profile-sub-title'>
                            Session 3  
                            </div>
                            <div className='dropdown-profile-sub-title'>
                            Session 4  
                            </div>
                        </div>
                      )}  */}

        <div className="d-flex flex-row justify-content-evenly MariOExResul">
          <div className="lanex_re">English</div>

          <div className="coverflexonejai">
            <div
              style={{
                width: "70%",
              }}
              className="coverthreejai111"
            ></div>
          </div>

          <div className="numex_re">70/100</div>
        </div>
        <div className="d-flex flex-row justify-content-evenly MariOExResul">
          <div className="lanex_re">Tamil</div>

          <div className="coverflexonejai">
            <div
              style={{
                width: "30%",
              }}
              className="coverthreejai444 "
            ></div>
          </div>

          <div className="numex_re">30/100</div>
        </div>
        <div className="d-flex flex-row justify-content-evenly MariOExResul">
          <div className="lanex_re">Maths</div>

          <div className="coverflexonejai">
            <div
              style={{
                width: "90%",
              }}
              className="coverthreejai111"
            ></div>
          </div>

          <div className="numex_re">90/100</div>
        </div>
        <div className="d-flex flex-row justify-content-evenly MariOExResul">
          <div className="lanex_re">Physics</div>

          <div className="coverflexonejai">
            <div
              style={{
                width: "64%",
              }}
              className="coverthreejai111"
            ></div>
          </div>

          <div className="numex_re">64/100</div>
        </div>
        <div className="d-flex flex-row justify-content-evenly MariOExResul">
          <div className="lanex_re">Chemistry</div>

          <div className="coverflexonejai">
            <div
              style={{
                width: "20%",
              }}
              className="coverthreejai444"
            ></div>
          </div>

          <div className="numex_re">20/100</div>
        </div>
        <div className="d-flex flex-row justify-content-evenly MariOExResul">
          <div className="lanex_re">Botany</div>

          <div className="coverflexonejai">
            <div
              style={{
                width: "80%",
              }}
              className="coverthreejai111"
            ></div>
          </div>

          <div className="numex_re">80/100</div>
        </div>
        <div className="d-flex flex-row justify-content-evenly MariOExResul">
          <div className="lanex_re">Zoology</div>

          <div className="coverflexonejai">
            <div
              style={{
                width: "72%",
              }}
              className="coverthreejai111"
            ></div>
          </div>

          <div className="numex_re">72/100</div>
        </div>
      </div> 



      </CCol>
      </div>       




        </CCol>
          
        <CCol md="4" className={"float-left paddingLeft-0 column_Box paddingRight-20"}>
        <h5 className={"Time_Table"}>Class Noticeboard</h5>
        <h5 className={"calssTitleMobi appbuild"}>Class Noticeboard</h5>
            <div className="Card_Teacher_classNotice">

            <div style={{display:"flex",flexDirection:"row",gap:"15px",padding:"15px 0px"}}>
          <div className={"Class_Font"}>
            Class XII-A
          </div>
          <div className={"Class_Font_1"}>
            Class XI-C
          </div>
          <div className={"Class_Font_1"}>
            Class X-B
          </div>
        </div>
          <div className="overflowteach">
            {
                ApiClassNotice.map( (item) => 
                 <div className="d-flex flex-column border-bottom classPadding ">
                <div className="teachclasdate">{item.date}</div>
                <div className="datebelownotice">{item.notice}</div>
              </div>)
          }
          </div>
            </div>

        <h5 className={"Time_Table"}>School Noticeboard</h5>
        <h5 className={"schollTitle appbuild"}>School Noticeboard</h5>
          <div className="cardschooltech mt-3">
          {
                items.map( (item, i) => 
                <div className="paddingleftrihtteach">
                     <p className='datetechschool'>
            {date}
        </p>
        <p className='scholteachname'>
                {item.name}
            </p>
            <div>
            <img src={item.image} className='imgteach'/>
            </div>
        </div>
        )
          }
          </div>

          <div>
        <h5 className={"Time_Table"}>Achivements</h5>
        <h5 className={"Achive_Title"}>Achivements</h5>
            <MyCarousel/>  
          {/* </CCol> */}
          <CCol md="12 padFirsLef2 maritopGin">
            <Birthday/>
            </CCol>
            </div>
        </CCol>



        </CRow>


        <CCol md="9" className={"float-left padkamin"}>
        

        <div className="containerHRFlow"></div>

        <CCol md="6" className={"float-left column_Box schoolScrollMobi"}>
        <Exam_Result/>      
        </CCol>

        <div className="containerHRFlow"></div>

        
      </CCol>


      {/* <CCol md="3" className={"float-left paddingLeft-0 column_Box marfgijn"}>
        <CCol md="12" className={"paddingLeft-0 padFirsLef2 maritopGin"}> */}
          {/* <div>
        <h5 className={"Time_Table"}>Achivements</h5>
        <h5 className={"Achive_Title"}>Achivements</h5>
            <MyCarousel/>  
          
          <CCol md="12 padFirsLef2 maritopGin">
            <Birthday/>
            </CCol>
            </div> */}
        {/* </CCol> */}



        <Footer 
          openForm={openForm}
          shoq={shoq}
          closeForm={closeForm}
          KpostIframe={KpostIframe}
        />


      </div>

</div>

  );
};

export default TDashboard;