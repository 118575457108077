import { useState, useEffect, useRef } from "react";
import { Link } from 'react-router-dom';
import Dropdown from './Dropdown';
import { BiChevronDown, BiHomeAlt, BiBookOpen, BiTable, BiListCheck  } from "react-icons/bi";

const MenuDropdownItems = ({ items, depthLevel  }) => {
    const [dropdown, setDropdown] = useState(false);
    let ref = useRef();

    // console.log(window.location.pathname,"TTTTTTTTTTTTTTTTT")

    useEffect(() => {
        const handler = (event) => {
         if (dropdown && ref.current && !ref.current.contains(event.target)) {
          setDropdown(false);
         }
        };
        document.addEventListener("mousedown", handler);
        document.addEventListener("touchstart", handler);
        return () => {
         // Cleanup the event listener
         document.removeEventListener("mousedown", handler);
         document.removeEventListener("touchstart", handler);
        };
       }, [dropdown]);

       const onMouseEnter = () => {
        window.innerWidth > 960 && setDropdown(true);
       };
       
       const onMouseLeave = () => {
        window.innerWidth > 960 && setDropdown(false);
       };

       const closeDropdown = () => {
        dropdown && setDropdown(false);
      };

    return (
     <li 
     onMouseEnter={onMouseEnter}
   onMouseLeave={onMouseLeave}
   onClick={closeDropdown}
     className="menu-items" ref={ref}>
      {/* {items.submenu && items.url ? ( */}
      {items.submenu && items.url ? (
       <>
       {/* <button 
    //    type="button" 
    //    aria-haspopup="menu"
    //    aria-expanded={dropdown ? "true" : "false"}
    //   onClick={() => setDropdown((prev) => !prev)}
       > */}
         {window.innerWidth < 960 && depthLevel === 0 ? (
        <>
        {items.title}
        </>
        
      ) : (
        <Link to={items.url} component={items.component}>{items.title}</Link>
      )}
      {depthLevel > 0 &&
window.innerWidth < 960 ? null : depthLevel > 0 &&
  window.innerWidth > 960 ? (
  <span>&raquo;</span>
) : (
  <span className="arrow" />
)}
        {/* </button> */}
        <Dropdown 
         submenus={items.submenu}
         dropdown={dropdown} 
         depthLevel={depthLevel}
        />
       </>
      ) : !items.url && items.submenu ? (
      <>

        <Link>{items.title}&nbsp;
        <BiChevronDown className={"menu_icon"} size="20" />
        </Link>
        <Dropdown 
         submenus={items.submenu}
         dropdown={dropdown} 
         depthLevel={depthLevel}
        />
      </>
    ) : (
      <>
        <Link to={items.url} className={items.url === window.location.pathname ? "active" : ""} component={items.component} >
        {items.page === 1 ?
        (<><BiHomeAlt size="20" /></>) : 
        items.page === 2 ?
        (<><BiBookOpen size="20" /></>) : 
        items.page === 3 ?
        (<><BiTable size="20" /></>) :
        items.page === 4 ?
        (<><BiListCheck size="20" /></>) :
        <></>
        }
         &nbsp;
        {items.title} </Link>
        </>
      )}
     </li>
    );
};

export default MenuDropdownItems;