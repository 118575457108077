import react from 'react';
import { menuItems } from './menuItems';
import { TeachermenuItems } from './TeachermenuItems';
import MenuItems from './MenuDropdownItems';

const Navbar = () => {
  const userType = JSON.parse(localStorage.getItem('userCreds'))?.userType
  // console.log(userType,"userTypeuserTypeuserType");
  return (
    <nav className={"SubMenu"}>
      <ul className="menus">
      {JSON.parse(localStorage.getItem('userCreds'))?.userType === "Student" && menuItems.map((menu, index) => {
    const depthLevel = 0;
    return <MenuItems items={menu} key={index} depthLevel={depthLevel} />;
   })}
   {JSON.parse(localStorage.getItem('userCreds'))?.userType === "Teaching" && TeachermenuItems.map((menu, index) => {
    const depthLevel = 0;
    return <MenuItems items={menu} key={index} depthLevel={depthLevel} />;
   })}
      </ul>
    </nav>
  );
};

export default Navbar;