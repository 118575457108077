import React, {useContext,useEffect,useState,} from "react";
import { Authcontext } from "./Context/Context";
import LoginForm from './Login/LoginForm';
import { Navigate } from 'react-router-dom';

const Login = () => {
  const { state, dispatch } = useContext(Authcontext);
  
  useEffect(() => {
    localStorage.clear();
    
  },[]);

    return (
        <LoginForm />
  );
};


export default Login;