import react from 'react';
import Navbar from './Navbar';

const SubHeader = () => {
    return (
      <header>
        <div className="nav-area">
          <Navbar />
        </div>
      </header>
    );
  };
  
  export default SubHeader;