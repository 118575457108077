import React from 'react';
import { useNavigate } from 'react-router-dom';
import kpostLogo from "../../Assets/ksmaclogo.png";
import { CCol } from "@coreui/react";
import ksmaccL from "../../Assets/ksmaclogo.png";
import Smacc from "../../Assets/smaccIcon.png"
import { RiArrowDropUpLine, RiArrowDropDownLine, RiFullscreenLine } from "react-icons/ri";
const Footer = ({
    openForm,
    shoq,
    closeForm,
    KpostIframe
}) => {

    const navigate = useNavigate();

    return (
        <>
        <CCol md="12" className="Mobile_ShowDesign">
            <CCol md="10" className={"float-left "}>
                <div>
                  <div className="FooterAlign">
                     <div className="d-flex flex-row px-4">
                      <div>
                        <span className="footpower">Powered by </span>
                        <span className="footyou">UEducate</span>
                      </div>
                      </div>
                      <div className="d-flex flex-row px-4">
                  <div className="allfoot">©2022 All rights reserved</div>
                      </div>
                      </div>

                      <div style={{
                        display:"flex",
                        justifyContent:"flex-end",
                        position:"fixed",
                        bottom:"20px",
                        right: "20px",
                        zIndex: 99999
                        }}
                        onClick={ () => {navigate("/ksmacc")} }>
                        <img src= {Smacc} width={"60px"} height={"60px"} 
                          style={{border:"1px solid #cccccc",borderRadius:"50%",backgroundColor:"white", objectFit: "contain"}} />
                      </div>
                </div>
            </CCol>
        </CCol>

        <div style={{
            // backgroundColor: "red",
            // position: "fixed",
            bottom: "0px",
            width: "100%"
        }}>
        <CCol md="12" className="WebShownDesign">

        <CCol md="6" className={"float-left webFoot"}>
            <div className="footerdash">
              <div className="d-flex flex-row">
                  <div className="paddPower">
                    <span className="footpower">Powered by </span>
                    <span className="footyou">UEducate</span>
                  </div>
                  </div>
              </div>
        </CCol>

        <CCol md="6" className={"float-left webFoot"}>
            <div className="footerdash">
              <div className="d-flex flex-row padd2022">
                <div>
              <div className="allfoot">©2022 All rights reserved</div>
              </div>
                  </div>
              </div>
        </CCol>

                <div className={"KpostIf_IyQ"} style={{ zIndex: "99999" }}>
                { KpostIframe && (
                    <div className={"Iframe_PItQ_X"}
                    style={{
                      height: window.innerHeight - 2 +"px"
                    }}
                    >   
                    <div
                      style={{
                        width: "500px",
                        height: "40px",
                        background: "#2E3A53",
                        boxShadow: "0px -4px 15px rgba(65, 68, 73, 0.18)",
                        borderRadius: "8px 8px 0px 0px",
                        zIndex: "999999",
                        marginRight: "20px"
                      }}
                    >
                      <div className="d-flex flex-row justify-content-between w-100 py-2 px-3 ">
                        <div className="d-flex flex-row">
                          <div className="d-flex align-items-center align-middle pr-5">
                            <img src={ksmaccL} alt="Ksmacc Logo" />
                          </div>

                          <div className="d-flex">
                            <div
                              style={{
                                fontFamily: "Inter",
                                fontStyle: "normal",
                                fontWeight: "600",
                                fontSize: "16px",
                                lineHeight: "24px",
                                color: "#fff",
                              }}
                            >
                              {" "}
                              K - SMACC
                            </div>
                          </div>
                        </div>
                        <div className="d-flex flex-row">
                        <div
                          className={"px-2"}
                          onClick={() => {
                            var Ksmaccopen = window.open(
                                `https://k.kampus.org.in/login?username=${localStorage.length > 0 && JSON.parse(localStorage.userCreds).userName}&password=${localStorage.length > 0 && JSON.parse(localStorage.userCreds).password}`
                                // `https://loadtestcommunication.kampus.org.in/login?username=${localStorage.length > 0 && JSON.parse(localStorage.userCreds).userName}&password=${localStorage.length > 0 && JSON.parse(localStorage.userCreds).password}`
                              );
                            if (Ksmaccopen == null || typeof Ksmaccopen == "undefined") {
                              alert("Turn off your pop-up blocker!");
                            }
                          }}
                          style={{ cursor: "pointer" }}
                          >
                        <RiFullscreenLine size={25} color={"#fff"} />
                      </div>
                        <div
                          className={"px-2"}
                          onClick={closeForm}
                          style={{ cursor: "pointer" }}
                        >
                          <RiArrowDropDownLine size={25} color={"#fff"} />
                        </div>
                        </div>
                      </div>
                    </div>    
                    <iframe
                        // title={`https://edu.indiakpost.com/login?username=${localStorage.length > 0 && JSON.parse(localStorage.userCreds).userName}&password=${localStorage.length > 0 && JSON.parse(localStorage.userCreds).password}`}
                        // title={`https://loadtestcommunication.kampus.org.in/login?username=${localStorage.length > 0 && JSON.parse(localStorage.userCreds).userName}&password=${localStorage.length > 0 && JSON.parse(localStorage.userCreds).password}`}
                        title={`https://k.kampus.org.in/login?username=${localStorage.length > 0 && JSON.parse(localStorage.userCreds).userName}&password=${localStorage.length > 0 && JSON.parse(localStorage.userCreds).password}`}
                        className={"w-100 h-100"}
                        // src={`https://edu.indiakpost.com/login?username=${localStorage.length > 0 && JSON.parse(localStorage.userCreds).userName}&password=${localStorage.length > 0 && JSON.parse(localStorage.userCreds).password}`}
                        // src={`https://loadtestcommunication.kampus.org.in/login?username=${localStorage.length > 0 && JSON.parse(localStorage.userCreds).userName}&password=${localStorage.length > 0 && JSON.parse(localStorage.userCreds).password}`}
                        src={`https://k.kampus.org.in/login?username=${localStorage.length > 0 && JSON.parse(localStorage.userCreds).userName}&password=${localStorage.length > 0 && JSON.parse(localStorage.userCreds).password}`}
                      />        
                    </div>
                    )}
                    {shoq && (
              <div className="d-flex flex-row float-right">
              <div
                  className="smacfoot"
                >
                  
                  <div className="d-flex flex-row justify-content-between w-100 py-2 px-3 ">
                    <div className="d-flex flex-row">
                      <div className="d-flex align-items-center align-middle pr-3">
                        <img src={ksmaccL} alt="Ksmacc Logo" />
                      </div>

                      <div className="d-flex">
                        <div
                          style={{
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontWeight: "600",
                            fontSize: "16px",
                            lineHeight: "24px",
                            paddingLeft: "10px",
                            color: "#fff",
                          }}
                        >
                          {" "}
                          K - SMACC
                        </div>
                      </div>
                    </div>
                    
                    <div
                      className="d-flex flex-row"
                      style={{ cursor: "pointer" }}
                      onClick={openForm}
                    >
                      <RiArrowDropUpLine size={25} color={"#fff"} />
                    </div>
                  </div>          
                </div>
                  </div>)}
                </div>
            

        </CCol>

        </div>
        </>
  );

};

export default Footer;

