import React, { useEffect, useState } from 'react'
import KDropdown from '../common/KDropdown/KDropdown'
import KInput from '../common/KInput/KInput';
import {
  CSmartTable,
} from '@coreui/react-pro'
import { getDailyTask, getGenericReport, getWeeklyTask } from '../../../services/Apiservices';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment'
import { toast } from 'react-toastify'
import { renderToString } from 'react-dom/server'
import parse from 'html-react-parser'
import { CButton, CCard, CCardBody, CCol, CForm, CFormInput, CFormLabel, CRow } from '@coreui/react'
import Select from 'react-select';
import { Fragment } from 'react';

const TaskReport = ({ backTaskReport }) => {

  const currentDate = new Date();
  const day = currentDate.getDate().toString().padStart(2, '0');
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  const year = currentDate.getFullYear();
  const formattedDate = `${year}-${month}-${day}`;



  const [daily, setDaily] = useState([]);
  const [weekly, setWeekly] = useState([]);
  const [monthly, setMonthly] = useState([]);
  const [periodiclly, setPeriodiclly] = useState([]);

  const columns = [
    {
      key: 'sNo', label: 'S.No',
      _style: { width: '5%' },
      filter: true,
      sorter: false,

    },
    {
      key: 'class',
      _style: { width: '10%' },
      filter: true,
      sorter: false,
    },
    {
      key: 'Subject',
      _style: { width: '10%' },
      filter: true,
      sorter: false,
    },
    {
      key: 'unit',
      label: 'Unit / Session',
      _style: { width: '30%' },
      filter: true,
      sorter: false,
    },
    {
      key: 'period',
      _style: { width: '10%' },
      filter: true,
      sorter: false,
    },

  ]

  const handleDownloadToday = () => {

    const doc = new jsPDF(); // Create a new jsPDF instance

    // Define your table data
    const tableData = daily.map((data, index) => {
      return [
        index + 1,
        data ? data.class : '',
        data ? data.Subject : '',
        data ? data.unit : '',
        data ? data.period : '',
      ];
    });

    // Define column headers
    const tableHeaders = [
      'Sno',
      'class',
      'Subject',
      'unit',
      'period',
    ];

    // Set the table width and column widths
    const tableWidth = 200; // Adjust as needed
    const columnWidths = [30, 30, 30, 30, 30]; // Adjust as needed

    // Add a new page to the PDF
    // doc.addPage();
    // doc.setFontSize(16); // Set font size
    doc.text(75, 15, 'Today Task Report'); // Centered text
    // Use the autotable method to generate the table
    doc.autoTable({
      head: [tableHeaders],
      body: tableData,
      styles: {
        fontSize: 8,
        font: 'helvetica',
        cellPadding: 2,
        minCellHeight: 2,
      },
      theme: "grid",
      headStyles: {
        fillColor: [16, 126, 183],
        textColor: [255, 255, 255],
        fontSize: 9,
        padding: 0,
      },
      startY: 20, // Adjust the starting Y position
      margin: { top: 10 },
      columnStyles: { 0: { cellWidth: 10 } }, // Adjust the first column width
    });

    // Save the PDF
    doc.save('DailyTaskReport.pdf');
  };

  const handleDownloadWeekly = () => {

    const doc = new jsPDF();

    let startY = 20;
    const spaceBetweenTables = 5;

    for (let index = 0; index < weekly.length; index++) {
      const x = weekly[index];
      const Date = weekly[index].date;

      if (index === 0) {
        doc.setFont('helvetica', 'bold'); // Set the font style
        doc.setFontSize(16); // Set the font size
        doc.text(75, 15, 'Weekly Task Report'); // Centered text
      }

      if (index !== 0) {
        startY += spaceBetweenTables; // Add space between tables
      }

      doc.setFont('helvetica', 'normal'); // Set the font style
      doc.setFontSize(10); // Set the font size
      doc.text(90, startY + 7, `${Date}`);


      const tableHeaders = [
        'SNo',
        'CLASS',
        'SUBJECT',
        'UNIT/SESSION',
        'PERIOD',
      ];

      let tableData = [];


      for (let index = 0; index < x.data.length; index++) {
        const datas = x.data[index];

        tableData.push([
          index + 1,
          datas ? datas.class : '',
          datas ? datas.Subject : '',
          datas ? datas.unit : '',
          datas ? datas.period : '',
        ]);

      }


      // Add additional text above the table

      // doc.addField()
      doc.autoTable({
        head: [tableHeaders],
        body: tableData,
        styles: {
          fontSize: 8,
          font: 'helvetica',
          cellPadding: 2,
          minCellHeight: 2,
        },
        theme: "grid",
        headStyles: {
          fillColor: [16, 126, 183],
          textColor: [255, 255, 255],
          fontSize: 9,
          padding: 0,
        },
        startY: startY + 10, // Adjust the starting Y position
        margin: { top: 10 },
        columnStyles: { 0: { cellWidth: 10 } }, // Adjust the first column width
      });

      startY = doc.autoTable.previous.finalY; // Update the Y position after rendering the table

    }



    // Define your table data
    // const tableData = weekly.map(x => (
    //   x.data.map((datas, index) => {
    //     return [
    //       index + 1,
    //       datas ? datas.class : '',
    //       datas ? datas.Subject : '',
    //       datas ? datas.unit : '',
    //       datas ? datas.period : '',
    //     ];
    //   })
    // ));
    // console.log(tableData, "tableDataddd");

    // Define column headers
    // const tableHeaders = [
    //   'Sno',
    //   'class',
    //   'Subject',
    //   'unit',
    //   'period',
    // ];

    // Set the table width and column widths
    const tableWidth = 200; // Adjust as needed
    const columnWidths = [30, 30, 30, 30, 30]; // Adjust as needed

    // Add a new page to the PDF
    // doc.addPage();
    // doc.setFontSize(16); // Set font size
    // Use the autotable method to generate the table

    // Save the PDF
    doc.save('WeeklyTaskReport.pdf');
  };

  const handleDownloadMonthly = () => {

    const doc = new jsPDF();

    let startY = 20;
    const spaceBetweenTables = 5;

    for (let index = 0; index < monthly.length; index++) {
      const x = monthly[index];
      const Date = monthly[index].date;

      if (index === 0) {
        doc.setFont('helvetica', 'bold'); // Set the font style
        doc.setFontSize(16); // Set the font size
        doc.text(75, 15, 'Monthly Task Report'); // Centered text
      }

      if (index !== 0) {
        startY += spaceBetweenTables; // Add space between tables
      }

      doc.setFont('helvetica', 'normal'); // Set the font style
      doc.setFontSize(10); // Set the font size
      doc.text(90, startY + 7, `${Date}`);


      const tableHeaders = [
        'SNo',
        'CLASS',
        'SUBJECT',
        'UNIT/SESSION',
        'PERIOD',
      ];

      let tableData = [];


      for (let index = 0; index < x.data.length; index++) {
        const datas = x.data[index];

        tableData.push([
          index + 1,
          datas ? datas.class : '',
          datas ? datas.Subject : '',
          datas ? datas.unit : '',
          datas ? datas.period : '',
        ]);

      }


      // Add additional text above the table

      // doc.addField()
      doc.autoTable({
        head: [tableHeaders],
        body: tableData,
        styles: {
          fontSize: 8,
          font: 'helvetica',
          cellPadding: 2,
          minCellHeight: 2,
        },
        theme: "grid",
        headStyles: {
          fillColor: [16, 126, 183],
          textColor: [255, 255, 255],
          fontSize: 9,
          padding: 0,
        },
        startY: startY + 10, // Adjust the starting Y position
        margin: { top: 10 },
        columnStyles: { 0: { cellWidth: 10 } }, // Adjust the first column width
      });

      startY = doc.autoTable.previous.finalY; // Update the Y position after rendering the table

    }
    // Define your table data
    // const tableData = weekly.map(x => (
    //   x.data.map((datas, index) => {
    //     return [
    //       index + 1,
    //       datas ? datas.class : '',
    //       datas ? datas.Subject : '',
    //       datas ? datas.unit : '',
    //       datas ? datas.period : '',
    //     ];
    //   })
    // ));
    // console.log(tableData, "tableDataddd");

    // Define column headers
    // const tableHeaders = [
    //   'Sno',
    //   'class',
    //   'Subject',
    //   'unit',
    //   'period',
    // ];

    // Set the table width and column widths
    const tableWidth = 200; // Adjust as needed
    const columnWidths = [30, 30, 30, 30, 30]; // Adjust as needed

    // Add a new page to the PDF
    // doc.addPage();
    // doc.setFontSize(16); // Set font size
    // Use the autotable method to generate the table

    // Save the PDF
    doc.save('MonthlyTaskReport.pdf');
  };

  const handleDownloadPeriodically = () => {

    const doc = new jsPDF();

    let startY = 20;
    const spaceBetweenTables = 5;

    for (let index = 0; index < periodiclly.length; index++) {
      const x = periodiclly[index];
      const Date = periodiclly[index].date;

      if (index === 0) {
        doc.setFont('helvetica', 'bold'); // Set the font style
        doc.setFontSize(16); // Set the font size
        doc.text(75, 15, 'Periodically Task Report'); // Centered text
      }

      if (index !== 0) {
        startY += spaceBetweenTables; // Add space between tables
      }

      doc.setFont('helvetica', 'normal'); // Set the font style
      doc.setFontSize(10); // Set the font size
      doc.text(90, startY + 7, `${Date}`);


      const tableHeaders = [
        'SNo',
        'CLASS',
        'SUBJECT',
        'UNIT/SESSION',
        'PERIOD',
      ];

      let tableData = [];


      for (let index = 0; index < x.data.length; index++) {
        const datas = x.data[index];

        tableData.push([
          index + 1,
          datas ? datas.class : '',
          datas ? datas.Subject : '',
          datas ? datas.unit : '',
          datas ? datas.period : '',
        ]);

      }


      // Add additional text above the table

      // doc.addField()
      doc.autoTable({
        head: [tableHeaders],
        body: tableData,
        styles: {
          fontSize: 8,
          font: 'helvetica',
          cellPadding: 2,
          minCellHeight: 2,
        },
        theme: "grid",
        headStyles: {
          fillColor: [16, 126, 183],
          textColor: [255, 255, 255],
          fontSize: 9,
          padding: 0,
        },
        startY: startY + 10, // Adjust the starting Y position
        margin: { top: 10 },
        columnStyles: { 0: { cellWidth: 10 } }, // Adjust the first column width
      });

      startY = doc.autoTable.previous.finalY; // Update the Y position after rendering the table

    }



    // Define your table data
    // const tableData = weekly.map(x => (
    //   x.data.map((datas, index) => {
    //     return [
    //       index + 1,
    //       datas ? datas.class : '',
    //       datas ? datas.Subject : '',
    //       datas ? datas.unit : '',
    //       datas ? datas.period : '',
    //     ];
    //   })
    // ));
    // console.log(tableData, "tableDataddd");

    // Define column headers
    // const tableHeaders = [
    //   'Sno',
    //   'class',
    //   'Subject',
    //   'unit',
    //   'period',
    // ];

    // Set the table width and column widths
    const tableWidth = 200; // Adjust as needed
    const columnWidths = [30, 30, 30, 30, 30]; // Adjust as needed

    // Add a new page to the PDF
    // doc.addPage();
    // doc.setFontSize(16); // Set font size
    // Use the autotable method to generate the table

    // Save the PDF
    doc.save('PeriodicllyTaskReport.pdf');
  };

  // useEffect(() => {
  //   GetDailyTask();
  // }, [])

  // Today Report API Function 
  const GetDailyTask = async () => {
    // setDaily([])
    let retrData = JSON.parse(localStorage.userCreds);
    const body = {
      // estStartDate: formattedDate,
    
      refStaffID: retrData.staffID,
    }
    try {
      const res = await getDailyTask(body)
  
      // if (res) {
        if (res.status === "success") {
          // toast.success(res.status)
          if (res.value !== null) {
            const data = res.value.map((x, i) => {
              return {
                sNo: i + 1,
                class: x.variableName,
                Subject: x.subjectName,
                unit: x.unitName,
                period: x.period,
              }
            })
            setDaily(data)
          }
          else{
            toast.error('No items found')
          }
        }
      // }
    } catch (e) {
      console.log(e);
      
    }
  }
// Weekly Report API Function 
  const GetWeeklyTask = async () => {
    
    let retrData = JSON.parse(localStorage.userCreds);
    const bod = {
      refStaffID: retrData.staffID,
      requestType: "weekly",
      startDate: startDate.replace("-", "/").replace("-", "/"),
      endDate: endDate.replace("-", "/").replace("-", "/")
    }
    try {
      const res = await getWeeklyTask(bod)
      console.log(res.value, "Aweirtyweekly")
      if (res) {
        if (res.status === "success") {
          if (res.value) {
            console.log(Object.keys(res.value), "Object.keys(res.value)");
            let TData = []

            for (let index = 0; index < Object.keys(res.value).length; index++) {
              const date = (Object.keys(res.value))[index];
              const Res = {
                date: date,
                data: res.value[`${date}`].map((x, i) => {
                  // console.log(x, "date");
                  console.log(res.value[`${date}`], "res.value[`${date}`]");
                  return {
                    sNo: i + 1,
                    class: x.variableName,
                    Subject: x.subjectName,
                    unit: `${x.unitName} / ${x.sessionName}`,
                    period: x.period,
                  }
                })
              }
              TData.push(Res)
            }
            // Object.keys(res.value).map((date) => {
            // })
            setWeekly(TData)
           
          }
        }else{
          toast.error(res.message)
         
        }
      }
    } catch (e) {
      console.log(e);
    }
  }
// Periodical Report API Function
  const GetPeriodicTask = async () => {
    let retrData = JSON.parse(localStorage.userCreds);
    const bod = {
      refStaffID: retrData.staffID,
      requestType: "periodically",
      startDate: periodicstartDate.replace("-", "/").replace("-", "/"),
      endDate: periodicEndDate.replace("-", "/").replace("-", "/")
    }

    try {
      const res = await getWeeklyTask(bod)
      console.log(res.value, "Aweirtyweekly")
      if (res) {
        if (res.status === "success") {
          if (res.value) {
            console.log(Object.keys(res.value), "Object.keys(res.value)");
            let TData = []

            for (let index = 0; index < Object.keys(res.value).length; index++) {
              const date = (Object.keys(res.value))[index];
              const Res = {
                date: date,
                data: res.value[`${date}`].map((x, i) => {
                  // console.log(x, "date");
                  console.log(res.value[`${date}`], "res.value[`${date}`]");
                  return {
                    sNo: i + 1,
                    class: x.variableName,
                    Subject: x.subjectName,
                    unit: `${x.unitName} / ${x.sessionName}`,
                    period: x.period,
                  }
                })
              }
              TData.push(Res)
            }
            // Object.keys(res.value).map((date) => {
            // })
            setPeriodiclly(TData)
          }
        }else{
          toast.error(res.message)
        }
      }
    } catch (e) {
      console.log(e);
    }
  }
// Monthly Report API Function 
  const GetMonthTask = async () => {
    let retrData = JSON.parse(localStorage.userCreds);
    const body = {
      refStaffID: retrData.staffID,
      requestType: "monthly",
      month: parseInt(monthDate.slice(6)),
      year: parseInt(monthDate.slice(0, 4))
    }
    try {
      const res = await getWeeklyTask(body)
      console.log(res.value, "Aweirtyweekly")
      if (res) {
        if (res.status ==="success") {
          if (res.value) {
            console.log(Object.keys(res.value), "Object.keys(res.value)");
            let TData = []

            for (let index = 0; index < Object.keys(res.value).length; index++) {
              const date = (Object.keys(res.value))[index];
              const Res = {
                date: date,
                data: res.value[`${date}`].map((x, i) => {
                  // console.log(x, "date");
                  console.log(res.value[`${date}`], "res.value[`${date}`]");
                  return {
                    sNo: i + 1,
                    class: x.variableName,
                    Subject: x.subjectName,
                    unit: `${x.unitName} / ${x.sessionName}`,
                    period: x.period,
                  }
                })
              }
              TData.push(Res)
            }
            // Object.keys(res.value).map((date) => {
            // })
            setMonthly(TData)
          }
        }else{
          toast.error(res.message)
        }
      }
    } catch (e) {
      console.log(e);
    }
  }


  const type = [
    { value: "today", label: "Today Task Report" },
    { value: "weekly", label: "Weekly Task Report" },
    { value: "monthly", label: "Monthly Task Report" },
    { value: "periodic", label: "Periodic Task Report" },
  ];

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [periodicstartDate, setPeriodicStartDate] = useState('');
  const [periodicEndDate, setPeriodicEndDate] = useState('');

  const [monthDate, setMonthDate] = useState('');

  const calculateEndDate = (selectedDate) => {
    const sevenDaysLater = new Date(selectedDate);
    sevenDaysLater.setDate(sevenDaysLater.getDate() + 7);

    // Skip Sunday
    if (sevenDaysLater.getDay() === 0) {
      sevenDaysLater.setDate(sevenDaysLater.getDate() + 1);
    }

    return sevenDaysLater;
  };

  const handleStartDateChange = (event) => {
    const selectedDate = new Date(event.target.value);
    const calculatedEndDate = calculateEndDate(selectedDate);

    const formattedEndDate = `${calculatedEndDate.getMonth() + 1}/${calculatedEndDate.getDate()}/${calculatedEndDate.getFullYear()}`;

    setStartDate(event.target.value);
    setEndDate(formattedEndDate);
  };



  const [state, setState] = useState({
    week: false,
    month: false,
    periodic: false,
  })

  const [report, setReport] = useState(true);
  const [toreport, setToReport] = useState(false);
  const [wereport, setWEReport] = useState(false);
  const [moreport, setMOReport] = useState(false);
  const [pereport, setPEReport] = useState(false);

  const handletypeReport = (e) => {
    
    if (e.value === "today") {
      setState({
        week: false,
        month: false,
        periodic: false,
      })
      GetDailyTask();
      setReport(false);
      setToReport(true);
      
    }

    if (e.value === "weekly") {
      setState({
        week: true,
        month: false,
        periodic: false,
      })
      setReport(true);
    }
    if (e.value === "monthly") {
      setState({
        week: false,
        month: true,
        periodic: false,
      })
      setReport(true);
    }
    if (e.value === "quarterly") {
      setState({
        week: false,
        month: false,
        periodic: true,
      })
      setReport(true);
    }
    if (e.value === "periodic") {
      setState({
        week: false,
        month: false,
        periodic: true,
      })
      setReport(true);
    }
  }

  return (
    <div>

      <div className='todaytaskcalass mt-5'>
        Task Report
      </div>

      {report && (
        <>
          <div className="d-flex flex-column flex-md-row justify-content-center mt-4">
            <Select
              className="dropDownClassName pl-md-3 pl-0 pt-2 pt-md-0 w-25"
              label="Select Type of Report"
              isRequired={true}
              placeholder="Select report"
              options={type}
              onChange={handletypeReport}
            />
          </div>
        </>
      )}

      {state.periodic && (
        <div className='d-flex flex-row justify-content-center mt-3'>
          <div className='d-flex flex-column ml-3 mr-3'>
            <label htmlFor="startDate">Start Date:</label>
            <input type="date" id="startDate" value={periodicstartDate} onChange={(e) => {
              setPeriodicStartDate(e.target.value)
            }} className='KInput__input' style={{ width: "300px" }} />
          </div>
          <div className='d-flex flex-column'>
            <label htmlFor="endDate">End Date:</label>
            <input type="date" id="endDate" value={periodicEndDate} onChange={(e) => {
              setPeriodicEndDate(e.target.value)
            }} className='KInput__input' style={{ width: "300px" }} />
          </div>
        </div>
      )}

      {state.periodic && (
        <>
          <div className='d-flex justify-content-end gap-2 paddingtaskReport'>
            <button type="button" class="btn btn-primary" onClick={() => {
              GetPeriodicTask()
              setReport(false);
              setPEReport(true);
              setWEReport(false);
              setState({
                week: false,
                month: false,
                periodic: false,
              });
              setPeriodicStartDate('');
              setPeriodicEndDate('');
            }}>View</button>
            <button type="button" class="btn btn-danger" onClick={() => {
              setState({
                week: false,
                month: false,
                periodic: false,
              })
            }}>Cancel</button>
          </div>
        </>
      )}

      {state.week && (
        <>
          <div className='d-flex flex-row justify-content-center mt-3'>
            <div className='d-flex flex-column ml-3 mr-3'>
              <label htmlFor="startDate">Start Date:</label>
              <input type="date" id="startDate" value={startDate} onChange={handleStartDateChange} className='KInput__input' style={{ width: "300px" }} />
            </div>
            <div className='d-flex flex-column'>
              <label htmlFor="endDate">End Date:</label>
              <input type="text" id="endDate" value={endDate} placeholder='mm/dd/yyy' readOnly className='KInput__input' style={{ width: "300px" }} />
            </div>
          </div>
        </>
      )}

      {state.week && (
        <>
          <div className='d-flex justify-content-end gap-2 paddingtaskReport'>
            <button type="button" class="btn btn-primary" onClick={() => {
              GetWeeklyTask()
              setReport(false);
              setWEReport(true);
              setState({
                week: false,
                month: false,
                periodic: false,
              });
              setStartDate('');
              setEndDate('');
            }}>View</button>
            <button type="button" class="btn btn-danger" onClick={() => {
              setState({
                week: false,
                month: false,
                periodic: false,
              })
            }}>Cancel</button>
          </div>
        </>
      )}




      {state.month && (
        <div className='d-flex flex-row justify-content-center mt-3'>
          <input
            label="Select Month"
            isRequired={true}
            type="month"
            placeholder="Enter Assignment Name"
            className='KInput__input'
            value={monthDate}
            onChange={(e) => {
              setMonthDate(e.target.value)
            }}
            style={{ width: "300px" }}
          />
        </div>
      )}

      {state.month && (
        <>
          <div className='d-flex justify-content-end gap-2 paddingtaskReport'>
            <button type="button" class="btn btn-primary" onClick={() => {
              GetMonthTask()
              setReport(false);
              setWEReport(false);
              setMOReport(true);
              setState({
                week: false,
                month: false,
                periodic: false,
              });
              setMonthDate('');
            }}>View</button>
            <button type="button" class="btn btn-danger" onClick={() => {
              setState({
                week: false,
                month: false,
                periodic: false,
              })
            }}>Cancel</button>
          </div>
        </>
      )}

      {toreport && (
        <div>
          <div className='paddingtaskReport mt-4'>
            <CSmartTable
              activePage={1}
              clickableRows
              columns={columns}
              columnFilter
              columnSorter
              items={daily}
              tableProps={{
                striped: true,
                hover: true,
              }}
            />
          </div>

          <div className='d-flex justify-content-end gap-2 taskreportprintbtn'>
            <button type="button" class="btn btn-primary" onClick={() => {
              handleDownloadToday()
            }}>Print</button>
            <button type="button" class="btn btn-danger" onClick={() => {
              setState({
                week: false,
                month: false,
                periodic: false,
              })
              setReport(true);
              setWEReport(false);
              setToReport(false);
            }}>Back</button>
          </div>

        </div>
      )}

      {wereport && (
        <div>
          <div className='paddingtaskReport mt-4'>
            {
              weekly.map((x, i) => {
                return (
                  <div className={'mb-5'}>
                    <div
                      className='py-3 font-weight-bold d-flex justify-content-center align-items-center'
                    >{x.date}</div>
                    <CSmartTable
                      activePage={1}
                      clickableRows
                      columns={columns}
                      columnFilter
                      columnSorter
                      items={x.data}
                      tableProps={{
                        striped: true,
                        hover: true,
                      }}
                    />
                  </div>
                )
              })
            }
            
          </div>

          <div className='d-flex justify-content-end gap-2 taskreportprintbtn'>
            <button type="button" class="btn btn-primary" onClick={() => {
              handleDownloadWeekly()
            }}>Print</button>
            <button type="button" class="btn btn-danger" onClick={() => {
              setState({
                week: false,
                month: false,
                periodic: false,
              })
              setReport(true);
              setWEReport(false);
              setToReport(false);
            }}>Back</button>
          </div>

        </div>
      )}




      {moreport && (
        <div>
          <div className='paddingtaskReport mt-4'>
            {
              monthly.map((x, i) => {
                return (
                  <div className={'mb-5'}>
                    <div
                      className='py-3 font-weight-bold d-flex justify-content-center align-items-center'
                    >{x.date}</div>
                    <CSmartTable
                      activePage={1}
                      clickableRows
                      columns={columns}
                      columnFilter
                      columnSorter
                      items={x.data}
                      tableProps={{
                        striped: true,
                        hover: true,
                      }}
                    />
                  </div>
                )
              })
            }

          </div>


          <div className='d-flex justify-content-end gap-2 taskreportprintbtn'>
            <button type="button" class="btn btn-primary" onClick={() => {
              handleDownloadMonthly()
            }}>Print</button>
            <button type="button" class="btn btn-danger" onClick={() => {
              setState({
                week: false,
                month: false,
                periodic: false,
              })
              setReport(true);
              setWEReport(false);
              setToReport(false);
              setMOReport(false);
              setMonthDate("");
            }}>Back</button>
          </div>

        </div>
      )}

      {pereport && (
        <div>
          <div className='paddingtaskReport mt-4'>
            {
              periodiclly.map((x, i) => {
                return (
                  <div className={'mb-5'}>
                    <div
                      className='py-3 font-weight-bold d-flex justify-content-center align-items-center'
                    >{x.date}</div>
                    <CSmartTable
                      activePage={1}
                      clickableRows
                      columns={columns}
                      columnFilter
                      columnSorter
                      items={x.data}
                      tableProps={{
                        striped: true,
                        hover: true,
                      }}
                    />
                  </div>
                )
              })
            }
          </div>


          <div className='d-flex justify-content-end gap-2 taskreportprintbtn'>
            <button type="button" class="btn btn-primary" onClick={() => {
              handleDownloadPeriodically()
            }}>Print</button>
            <button type="button" class="btn btn-danger" onClick={() => {
              setState({
                week: false,
                month: false,
                periodic: false,
              })
              setReport(true);
              setPEReport(false);
              setWEReport(false);
              setToReport(false);
              setMOReport(false);
              setPeriodicStartDate("");
              setPeriodicEndDate("");
            }}>Back</button>
          </div>
        </div>
      )}
    </div>
  )
}

export default TaskReport
