import React, { useEffect, useState } from "react";
import Header from "./HeaderNavbar";
import Footer from "./Footer";
import KDropdown from "./common/KDropdown";
import KInput from "./common/KInput";
import KButton from "./common/KButton";
import KHeader from "./common/KHeader";
import useResponsive from "../../hooks/useResponsive";
import moment from "moment";
import Danger from "../components/Toastify/Danger";
import Warning from "../components/Toastify/Warning";
import Success from "../components/Toastify/Success";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from "react-toastify";
// API Import
import {
  SessionPlan_MediumofEdu_API,
  SessionPlan_YearofAc_API,
  SessionPlan_stand_API,
  SessionPlan_session_API,
  SessionPlan_subject_API,
  SessionPlan_scheduleBtn_API,
  SessionPlan_scheduleBtnCompar_API,
  SessionPlan_scheduleConfirmBtn_API,
} from "../../services/Apiservices";
import { CFormLabel, CSmartTable } from "@coreui/react-pro";
import {
  CBreadcrumbItem,
  CBreadcrumb,
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CCol,
} from "@coreui/react";
// Function Start
const KatbookSessionPlan = ({
  showProfile,
  falseShowProfile,
  notofShowProfile,
  ShowNotifications,
  showNotification,
  falseShowNotification,
  disableOnOuterTouch,
  shoq,
  KpostIframe,
  closeForm,
  openForm,
  goToMainRoutes
}) => {
  const { isBigScreen } = useResponsive();

  const [titleSession, setTitleSession] = useState(true);
  const [breadcrumSession, setBreadcrumSession] = useState(true);
  const [breadcrumSession1, setBreadcrumSession1] = useState(false);
  const [session1, setSession1] = useState(true);
  const [session2, setSession2] = useState(false);

  const [mediumofEduData, setMediumofEduData] = React.useState([]);
  const [mediumofEduDrop, setMediumofEduDrop] = React.useState("");

  const [yearofAccData, setYearofAccData] = React.useState([]);
  const [yearofAccDataDrop, setyearofAccDataDrop] = React.useState("");
  const [yearID, setYearID] = useState("");

  const [standardData, setStandardData] = React.useState([]);
  const [standardDataDrop, setstandardDataDrop] = React.useState("");

  const [sessionData, setSessionData] = React.useState([]);
  const [sessionDataDrop, setSessionDataDrop] = React.useState("");

  const [subjectsData, setSubjectsData] = React.useState([]);
  const [subjectsDataDrop, setSubjectsDataDrop] = React.useState("");

  const [scheduleData, setScheduleData] = useState([]);
  const [scheduleDataCpm, setScheduleDataCpm] = useState([]);
  const [lastVariableIDData, setLastVariableIDData] = useState("");
  const [minDate, setMinDate] = useState(null);
  const [durationData,setDurationData]=useState('');
  const [scheduleConfirmBtn, setScheduleConfirmBtn] = useState([]);
  const[allId,setAllId]=useState([{
    katbookUnitID:"",katbookSessionID:"",katbookVariableID:"",
  }])
  const [allData, setAllData] = useState([{
    refInstID: "",
    refMediumID: "",
    refYearID: "",
    refVariableID:"",
    refSectionID:"",
    refStaffID: "",
    variableDetails:"",
    katbookSessionID:"",
    
    katbookUnitID:"",
    refSubjectID: "",
    katbookVariableID:"",
   
  }]);
  // console.log("sdfjlweuiw",allData)

  const [errorMessage, setErrorMessage] = useState("");
  const [visible, setVisible] = useState(false);
  const [error, setError] = useState(false);
  const [AsstDate, setAsstDate] = useState('');

  const LSDValidation = (e) => {
    const TodayDate = moment().format("DD MMM YYYY");
    const EnterDate = moment(e).format("DD MMM YYYY");
    if (EnterDate >= TodayDate) {
      setAsstDate(e)
      setErrorMessage("");
    } else {
      setAsstDate('')
    }
  };

  // const DateValidation = (e) => {
  //   // const TodayDate = moment().format("DD MMM YYYY")
  //   const EnterDate = moment(e).format('DD MMM YYYY')
  //    if (EnterDate) {
  //     setAsstDate(e)
  //     setErrorMessage('')
  //   } else {
  //     setAsstDate('')
     
  //   }
  // }

  useEffect( () =>{
    const min_date = new Date(new Date());
      setMinDate(moment(min_date).format("YYYY-MM-DD"));
    // teacherViewList()
  },[]);
  const handleKey = (e) => {
    e.preventDefault();
    setErrorMessage('Keyboard input is not allowed');
  };



  const handleInputChange = (value) => {
    setDurationData(value);
    if (value < 0) {
      setError(true);
      // toast.error('Please enter a non-negative value.');
    } else {
      setError(false);
    }
  };

  const ChapterTable = [
    {
      key: "Sno",
      label: "S.No",
      filter: true,
      sorter: true,
      _style: { width: "5%" },
    },
    {
      key: "Chapter",
      label: "Chapter/Unit Name	",
      filter: true,
      sorter: true,
      _style: { width: "40%" },
    },
    {
      key: "Session",
      label: "Session",
      filter: true,
      sorter: true,
      _style: { width: "20%" },
    },
    {
      key: "Duration",
      label: "Duration",
      _style: { width: "15%" },
      filter: true,
      sorter: true,
    },
  ];

  const ClearFunction = () => {
    setMediumofEduDrop("");
    setyearofAccDataDrop("");
    setstandardDataDrop("");
    setSessionDataDrop("");
    setSubjectsDataDrop("");
  };
  // API Function Calling
  useEffect(() => {
    SessionPlan_MediumofEdu();
  }, []);

  const SessionPlan_MediumofEdu = async () => {
    let response;
    setMediumofEduData([]);

    let body = {
      instID:
        localStorage.length > 0 && JSON.parse(localStorage.userCreds).refInstID,
    };
    // console.log("bodsdsds",body)
    try {
      response = await SessionPlan_MediumofEdu_API(body);

      if(response.status === "403"){
        goToMainRoutes();
      }

      if (response.status === "success") {
        toast.success(response.message);
        if (response.value) {
          // console.log("skdlfjskd111",response)
          const data = response.value.map((x, index) => {
            return {
              No: index + 1,
              value: x.mediumId,
              label: x.mediumName,
            };
          });
          setMediumofEduData(data);
        }
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const SessionPlan_YearofAc_Fun = async (value) => {
    let response;
    setYearofAccData([]);

    let body = {
      refInstID:
        localStorage.length > 0 && JSON.parse(localStorage.userCreds).refInstID,
      refMediumID: value,
    };
    // console.log("jkdlkf", body);
    try {
      response = await SessionPlan_YearofAc_API(body);

      if (response.status === "success") {
        toast.success(response.message);
        if (response.value) {
          const data = response.value.map((x, index) => {
            return {
              No: index + 1,
              value: x.yearID,
              label: x.academicYear,
              yearID: x.yearID,
              fromDate: x.fromDate,
              toDate: x.toDate,
            };
          });
          setYearofAccData(data);
        }
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const SessionPlan_Standard_Fun = async (value) => {
    let response;
    setStandardData([]);

    let body = {
      refInstID:
        localStorage.length > 0 && JSON.parse(localStorage.userCreds).refInstID,
      parentTier: 0,
    };
    //  console.log("jksd1225dlkf",body)
    try {
      response = await SessionPlan_stand_API(body);

      if (response.status === "success") {
        toast.success(response.message);
        if (response.value) {
          // console.log("body1232", response);
          const data = response.value.map((x, index) => {
            return {
              No: index + 1,
              value: x.variableID,
              label: x.tierValue,
              parentTier: x.parentTier,
              tierName: x.tierName,
              MediumID: x.refCategoryID,
            };
          });
          setStandardData(data);
        }
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const SessionPlan_Session_Fun = async (value, MediumID) => {
    let response;
    setSessionData([]);

    let body = {
      refInstID:
        localStorage.length > 0 && JSON.parse(localStorage.userCreds).refInstID,
      refMediumID: MediumID,
      refVariableID: value,
    };
    //  console.log("jksd1225dlkf1111",body)
    try {
      response = await SessionPlan_session_API(body);

      if (response.status === "success") {
        toast.success(response.message);
        if (response.value) {
          // console.log("ssss", response);
          const data = response.value.map((x, index) => {
            return {
              No: index + 1,
              value: x.sectionID,
              label: x.sectionName,
              refMediumID: x.refMediumID,
              tierName: x.tierName,
              sectionID: x.sectionID,
              refYearId: x.refYearId,
              refVariableID: x.refVariableID,
            };
          });
          setSessionData(data);
          // console.log("12311", data);
        }
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const SessionPlan_Subject_Fun = async (
    value,
    refMediumID,
    sectionID,
    refVariableID
  ) => {
    let response;
    setSubjectsData([]);

    let body = {
      refInstID:
        localStorage.length > 0 && JSON.parse(localStorage.userCreds).refInstID,
      refMediumID: refMediumID,
      refSectionID: sectionID,
      refStaffID:
        localStorage.length > 0 && JSON.parse(localStorage.userCreds).staffID,
      refVariableID: refVariableID,
      refYearID: yearID,
    };

    try {
      response = await SessionPlan_subject_API(body);

      if (response.status === "success") {
        toast.success(response.message);
        if (response.value) {
          // console.log("ssssssssxx", response);
          const data = response.value.map((x, index) => {
            return {
              No: index + 1,
              value: x.refSubjectID,
              label: x.subjectName,
              refInstID: x.refInstID,
              refMediumID: x.refMediumID,
              refYearID: x.refYearID,
              refVariableID: x.refVariableID,
              refSubjectID: x.refSubjectID,
              refStaffID: x.refStaffID,
              refSectionID: x.refSectionID,
              variableDetails:x.variableDetails,
              katbookUnitID:x.katbookUnitID,
              katbookSessionID:x.katbookSessionID,
              katbookVariableID:x.katbookVariableID,
            };
          });
          setSubjectsData(data);
         
        }
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // Schedule button click API calling

  const SessionPlan_Schedule_Fun = async (
    refMediumID,
    refSectionID,
    refVariableID,
    refSubjectID,
    refInstID,
    refYearID
  ) => {
    let response;
    setScheduleData([]);

    let body = {
      refInstID: refInstID,
      refMediumID: refMediumID,
      refSectionID: refSectionID,
      refStaffID:
        localStorage.length > 0 && JSON.parse(localStorage.userCreds).staffID,
      refVariableID: refVariableID,
      refYearID: refYearID,
      refSubjectID: refSubjectID,
    };

    try {
      response = await SessionPlan_scheduleBtn_API(body);

      if (response.status === "success") {
        toast.success(response.message);
        console.log("jklsdjfk",response);
        if (response.value) {
          const data = response.value.map((x, index) => {
            return {
              No: index + 1,
              id:x.id,
              refInstID:x.refInstID,
              refMediumID:x.refMediumID,
              refYearID:x.refYearID,
              refSubjectID:x.refSubjectID,
              katbookUnitID:x.katbookUnitID,
              katbookSessionID: x.katbookSessionID,
              lastVariableID:x.lastVariableID,
              refSectionID:x.refSectionID,
            };
          });
          setScheduleData(data);
         console.log("dsfasasasasasadwesdfs",data)
         let value = data.length > 0 ? [data[0].lastVariableID] : []; // Extracting the lastVariableID value from the first element of "data" array
         console.log("sdfkjdsf",value) 
         setLastVariableIDData(value);

          // console.log(value,"opiopi");

        }
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const SessionPlan_ScheduleCpm_Fun = async () => {
    let response;
    setScheduleDataCpm([]);
    const id = lastVariableIDData;
    console.log("dfasfsdfsf",id)
    try {
      response = await SessionPlan_scheduleBtnCompar_API(id);
     
      if (response.success === true ) {
             
        if (response) {
         
          const data = response.variable.map((x, index) => {
          
            return {
              Sno: index + 1,
              Chapter:x.unitName,
              Session:x.sessionName,
              unitId:x.unitId,
              unitName:x.unitName,
              sessionId:x.sessionId,
              sessionName:x.sessionName,

            };
            
          });
         
          setScheduleDataCpm(data);
          console.log("sdfhksjfdhs",data)
        }
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.log(err);
    }
  };

// Schedule btn confirm btn  


const SessionPlan_ScheduleConfirmBtn_Fun = async (
  
) => {
  let response;
  setScheduleConfirmBtn([]);

  let UnitID = scheduleData.map((x) => x.katbookUnitID)[0];
  let sectionID = scheduleData.map((x) => x.katbookSessionID)[0];
  let VariableID = scheduleData.map((x) => x.lastVariableID)[0];
  let refsectionID = subjectsData.map((x) => x.refSectionID)[0];
  // let value=scheduleData.map((e)=>{
  //   return e.katbookUnitID
  // })
  let body = {
    refInstID:allData.refInstID,
    refMediumID: allData.refMediumID,
    refYearID: allData.refYearID,
    refVariableID:allData.refVariableID,
    refSectionID:refsectionID,
    refSubjectID: allData.refSubjectID,
    refStaffID: allData.refStaffID,
    variableDetails: allData.variableDetails,

    katbookUnitID:UnitID,
    katbookSessionID:sectionID,
    katbookVariableID:VariableID,

    estStartDate:AsstDate,

    estEndDate:null,
    duration:durationData,

    createdBy:
    localStorage.length > 0 && JSON.parse(localStorage.userCreds).adminName,

  };
// console.log("sdfsdfasdwqeqwe",body)
  try {
    response = await SessionPlan_scheduleConfirmBtn_API([body]);

    if (response.status === "success") {
      toast.success(response.message);
      if (response.value) {
        const data = response.value.map((x, index) => {
          return {
            No: index + 1,
            // id:x.id,
            // refInstID:x.refInstID,
            // refMediumID:x.refMediumID,
            // refYearID:x.refYearID,
            // refSubjectID:x.refSubjectID,
            // katbookUnitID:x.katbookUnitID,
            // katbookSessionID: x.katbookSessionID,
            // lastVariableID:x.lastVariableID,
          };
        });
        setScheduleConfirmBtn(data);
        SessionPlan_ScheduleCpm_Fun();
        // setVisible(false)
      }
    } else {
      toast.error(response.message);
    }
  } catch (err) {
    console.log(err);
  }
};


  return (
    <div onClick={() => disableOnOuterTouch()}>
      <div className="Navbar">
        <Header
          showProfile={showProfile}
          falseShowProfile={falseShowProfile}
          notofShowProfile={notofShowProfile}
          ShowNotifications={ShowNotifications}
          showNotification={showNotification}
          falseShowNotification={falseShowNotification}
        />
      </div>
      <div className={"Dash_home_QWI"}>
        <div
          className="myattendcard2w p-3"
          style={{
            height: window.innerHeight - 117 + "px",
            overflowY: "auto",
          }}
        >
          {titleSession && (
            <KHeader
              title="Katbook Session Plan "
              containerClassName={`marginTop-20`}
            />
          )}

          {breadcrumSession && (
            <div className="pl-4">
              {/* <CBreadcrumb>
                    <CBreadcrumbItem
                      active
                      onClick={() => {
                        setBreadcrumSession1(false);
                        setSession2(false);
                        
                      }}
                    >
                     Session Plan
                    </CBreadcrumbItem>
                  </CBreadcrumb> */}
            </div>
          )}

          {breadcrumSession1 && (
            <div className="pl-4">
              <div>
                <CBreadcrumb>
                  <CBreadcrumbItem
                    onClick={() => {
                      setSession1(true);
                      setBreadcrumSession(true);
                      setBreadcrumSession1(false);
                      setSession2(false);
                    }}
                  >
                    Session Plan
                  </CBreadcrumbItem>
                  <CBreadcrumbItem
                    active
                    onClick={() => {
                      setSession1(false);
                    }}
                  >
                    Katbook Session Mapping
                  </CBreadcrumbItem>

                  {/* {subjectNameArrydata.map((x, e) => (
                      <CBreadcrumbItem active>{x.SubjectName}</CBreadcrumbItem>
                    ))} */}
                </CBreadcrumb>
              </div>
            </div>
          )}
          {/* first screen */}
          
         
          {session1 && (
            <div className="mt-3 px-5 py-3 d-flex align-items-end justify-content-start">
              <div className="widMax w-100">
             
                <CRow>
                <CCol sm={3} className="mb-3" >
                    <KDropdown
                      containerClassName="w-70"
                      label="Medium of Education"
                      isRequired={true}
                      placeholder="Medium of Education"
                      value={mediumofEduDrop}
                      options={mediumofEduData}
                      onChange={(e) => {
                        setMediumofEduDrop(e);
                        SessionPlan_YearofAc_Fun(e.value);
                        setyearofAccDataDrop("");
                        setstandardDataDrop("");
                        setSessionDataDrop("");
                        setSubjectsDataDrop("");
                      }}
                    />
                  </CCol>
                  <CCol sm={3}>
                  <KDropdown
                      containerClassName="w-100"
                      label="Academic Year"
                      isRequired={true}
                      placeholder="Academic Year"
                      value={yearofAccDataDrop}
                      options={yearofAccData}
                      isDisabled={!mediumofEduDrop}
                      onChange={(e) => {
                        setyearofAccDataDrop(e);
                        SessionPlan_Standard_Fun(e.value);
                        setYearID(e.yearID);
                        setstandardDataDrop("");
                        setSessionDataDrop("");
                        setSubjectsDataDrop("");
                      }}
                    />
                  </CCol>
                  <CCol sm={3}>
                  <KDropdown
                      containerClassName="w-100"
                      label="Standard"
                      isRequired={true}
                      placeholder="Standard"
                      value={standardDataDrop}
                      options={standardData}
                      isDisabled={!mediumofEduDrop || !yearofAccDataDrop}
                      onChange={(e) => {
                        setstandardDataDrop(e);
                        SessionPlan_Session_Fun(e.value, e.MediumID);
                        setSessionDataDrop("");
                        setSubjectsDataDrop("");
                      }}
                    />
                  </CCol>
                  <CCol sm={3}>
                  <KDropdown
                      containerClassName="w-100"
                      label="Section"
                      isRequired={true}
                      placeholder="Section"
                      value={sessionDataDrop}
                      options={sessionData}
                      isDisabled={
                        !mediumofEduDrop ||
                        !yearofAccDataDrop ||
                        !standardDataDrop
                      }
                      onChange={(e) => {
                        setSessionDataDrop(e);
                        SessionPlan_Subject_Fun(
                          e.value,
                          e.refMediumID,
                          e.sectionID,
                          e.refVariableID
                        );
                        setSubjectsDataDrop("");

                      }}
                    
                    />
                  </CCol>
                  <CCol sm={3}>
                  <KDropdown
                      containerClassName="w-100"
                      label="Subject"
                      isRequired={true}
                      placeholder="Subject"
                      value={subjectsDataDrop}
                      options={subjectsData}
                      isDisabled={
                        !mediumofEduDrop ||
                        !yearofAccDataDrop ||
                        !standardDataDrop ||
                        !sessionDataDrop
                      }
                     
                      onChange={(e) => {
                        
                        setSubjectsDataDrop(e);
                        SessionPlan_Schedule_Fun(
                          e.refMediumID,
                          e.refSectionID,
                          e.refVariableID,
                          e.refSubjectID,
                          e.refInstID,
                          e.refYearID
                        );
                        setAllData({
                          refInstID:e.refInstID,
                          refMediumID:e.refMediumID,
                          refYearID:e.refYearID,
                          refVariableID:e.refVariableID,
                          refSubjectID:e.refSubjectID,
                          refStaffID:e.refStaffID,
                          variableDetails:e.variableDetails,
                          katbookSessionID:e.katbookSessionID,
                          katbookUnitID:e.katbookUnitID,
                          katbookSessionID: e.katbookSessionID,
                        })
                        // setAllId({
                          
                        // })
                        
                        // setlAstVariableIDData(e.lastVariableID);
                        // SessionPlan_ScheduleCpm_Fun(e.lastVariableID);
                      }}
                    
                    />
                      
                  </CCol>
              </CRow>
         
                <CRow>
                  <div className="mt-4 pl-2 gap-2 d-flex align-items-end justify-content-end">
                    <KButton
                      className={`px-3 py-2 ${
                        isBigScreen ? "w-auto" : "w-100"
                      }`}
                      isDisabled={
                        !subjectsDataDrop ||
                        !mediumofEduDrop ||
                        !yearofAccDataDrop ||
                        !standardDataDrop ||
                        !sessionDataDrop
                      }
                      onClick={() => {
                        setBreadcrumSession(false);
                        setBreadcrumSession1(true);
                        setSession1(false);
                        setSession2(true);
                        SessionPlan_ScheduleCpm_Fun();
                      }}
                      value="Schedule"
                    />
                    <KButton
                      className={`px-3 py-2 ${
                        isBigScreen ? "w-auto" : "w-100"
                      }`}
                      btnColor="red"
                      onClick={() => {
                        setBreadcrumSession(true);
                        setBreadcrumSession1(false);
                        ClearFunction();
                      }}
                      value="Clear"
                    />
                  </div>
                </CRow>
              </div>
            </div>
          )}

          {/* Second Screen  */}

          {session2 && (
            <div className="container-fluid px-5 py-3">
              <div className="d-flex flex-column ">
                <div className="mb-3 d-flex align-items-end justify-content-center">
                  <CFormLabel className="cusfontLabel">
                    Subject Start Date
                  </CFormLabel>
                  <KInput
                    placeholder="dd--mm--yyyy"
                    // label="Subject Start Date"
                    containerClassName="pl-md-4 pl-0 pt-2 pt-md-0 w-25"
                    labelClassName="width-maxContent"
                    inputClassName="w-100"
                    isRequired={true}
                    value={AsstDate}
                    type="date"
                    min={minDate}
                    onKeyDown = {handleKey}
                    onChange={(e) => {
                      const selectedDate = e; 
                      // setAsstDate(selectedDate); 
                      LSDValidation(e)
                                            
                    }}
                   
                    error={errorMessage}
                  />
                </div>
              </div>
              {/*Table  */}
              <>
                <div className="tablewidthSession">
                  <CSmartTable
                    columns={ChapterTable}
                    items={scheduleDataCpm.filter(z=>
                      scheduleData.filter(b=>b.katbookSessionID === z.sessionId ).length > 0 ? true : false 
                      )}
                    columnFilter
                    columnSorter
                    // itemsPerPage={5}
                    // itemsPerPageSelect={scheduleDataCpm.length > 5}
                    pagination
                    scopedColumns={{
                    //  Chapter : (items) =>(
                    //  <td>
                        
                    //      {items.Chapter}
                        
                    //   </td>),
                      
                    //   Session : (items) =>(<td>
                    //     {items.Session}
                    //      {/* {JSON.stringify(scheduleData)}
                    //      <hr />
                    //     {JSON.stringify(items)}
                        
                    //    {scheduleData.find(data => data.katbookUnitID === items.unitId)?.Chapter
                    //          ?
                    //          scheduleData.find(data => data.katbookUnitID === items.unitId?.Chapter)
                    //          : "-"                            
                    //        }  */}
                    //  </td>),

                      Duration: (items) => {
                        return (
                          <td className="gapflex">
                            <KInput
                            
                              placeholder="Enter Duration"
                              containerClassName={`pl-md-3 pl-0 pt-2 pt-md-0 w-75`}
                              labelClassName={"width-maxContent"}
                              inputClassName="w-75"
                              type="number"
                              value={durationData}
                              onChange={handleInputChange}
                            
                            /> 
                           
                          </td>
                        );
                      },
                    }}
                    tableProps={{
                      striped: true,
                      hover: true,
                    }}
                  />
                  {/* button */}
                </div>
                <div className="mt-3 pl-5 pr-4 d-flex align-items-end justify-content-end gap-2">
                  <KButton
                    className={`px-3 py-2 ${isBigScreen ? "w-auto" : "w-100"}`}
                    btnColor="green"
                    onClick={() => {
                      setVisible(true);
                     
                    }}
                    value="Confirm"
                    isDisabled={durationData === "" || !AsstDate}
                  />
                  <KButton
                    className={`px-3 py-2 ${isBigScreen ? "w-auto" : "w-100"}`}
                    btnColor="red"
                    onClick={() => {
                      setBreadcrumSession(true);
                      setBreadcrumSession1(false);
                      setSession1(true);
                      setSession2(false);
                    }}
                    value="Back"
                  />
                </div>
              </>
            </div>
          )}
            <CModal
            alignment="center"
            visible={visible}
            onClose={() => {
              setVisible(false)
              
            }}
            className="ModalHeight"
          >
            <div
              style={{
                backgroundColor: "white",
                padding: "10px 30px 30px 30px",
                borderRadius:"5px",
              }}
            >
              

              <div style={{ padding: "25px" }}>
                <div className="d-flex justify-content-center mb-4">
                  <span
                    style={{
                      fontSize: "18px",
                      color: "black",
                      fontWeight: "500",
                      height: "auto",
                    }}
                  >
                    Would you like to post?
                  </span>
                </div>
                <div className="d-flex justify-content-around">
                  <KButton
                    value="Yes"
                    
                    onClick={() => {

                      SessionPlan_ScheduleConfirmBtn_Fun();
                      
                      setVisible(false);
                      // setSession2(false)
                      // setSession1(true);
                    }}
                    
                    btnColor={"green"}
                    className={"px-4 py-1 ButtonSize"}
                  />
                  <KButton
                    value="No"
                    onClick={() => {
                      setVisible(false)
                      // PostCancelQuestion();
                      
                    }}
                    className={"px-4 py-1 ButtonSize"}
                    btnColor={"red"}
                  />
                </div>
              </div>
            </div>
          </CModal>
        </div>
      </div>

      <Footer
        openForm={openForm}
        shoq={shoq}
        closeForm={closeForm}
        KpostIframe={KpostIframe}
      />
    </div>
  );
};

export default KatbookSessionPlan;
