import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { Authcontext } from "./Context/Context";

const Logout = () => {
    const { state, dispatch } = useContext(Authcontext);
    localStorage.clear();
    dispatch({ type: "LOGOUT" });
    return (
        <Navigate to="/login" />
    );
};

export default Logout;