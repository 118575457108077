import "../../App.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import { FiBell } from "react-icons/fi";
import { FaUserAlt, FaKey } from "react-icons/fa";
import { HiOutlineMail, HiOutlineLogout } from "react-icons/hi";
import { IoLogoBuffer } from "react-icons/io5";
import { Authcontext } from "./Context/Context";
import ShLogo from "../../Assets/school_logo.png";
import StLogo from "../../Assets/student_pic.png";
import { AiOutlineAppstore } from "react-icons/ai";
import { FiBookOpen } from "react-icons/fi";
import { BiTime } from "react-icons/bi";
import { MdInsertChartOutlined } from "react-icons/md";
import SubHeader from "../components/Menu/SubHeader";
import KAMPUSNEW from "../../Assets/KAMPUSNEW.png";

import Notify from "../../Assets/Noti_Img_1.png";
import Notify_Img from "../../Assets/Noti_Img_2.png";
import { TbArrowLeft } from "react-icons/tb";
import {Kampuslogout} from "../../services/Apiservices"
// import {} from 'react-icons/hi'
const HeaderNavbar = ({
  showProfile,
  notofShowProfile,
  falseShowProfile,
  translateText,
  institutionName,
  profileIcon,
  username,
  standard,
  dropdownEmail,
  dropdownUser,
  setCollapse,
  collapse,
  katbookPageToggel,
  SearchToggle,
  IndexToggle,
  HideFuc,
  BookToggle,
  CardToggle,
  exitAddCont,
  bookschatexitFun,
  falseShowLanguage,
  FalseShowSessions,
  clarifyfullview,
  setClarifyFullView,
  ShowNotifications,
  showNotification,
  falseShowNotification,
  trueShowNotification,
  userType,
}) => {
  // const [showNotification, setNotification] = useState(false);
  // const [showProfile, setShowprofile] = useState(false);
  const navigate = useNavigate();
  const [showSchoolToolTip, setShowSchoolToolTip] = useState(false);

  const location = useLocation();

  // console.log(
  //   location.pathname,
  //   "ROUTES NAMEEEEEEEEEEEEEEEEEEEEEEEEE",
  //   userType
  // );

  const [dark, setDark] = useState(false);

  const [light, setLight] = useState(false);

  const darkMode = () => {
    setDark(true);
    setLight(false);
  };

  const lightMode = () => {
    setDark(false);
    setLight(true);
  };

  // const ShowProfile = () => {
  //   // setShowprofile(!showProfile);
  //   notofShowProfile();
  //   // setNotification(false);
  // }
  // const ShowNotification = () => {
  //   setNotification(!showNotification);
  //   falseShowProfile();
  // }
  // console.log(profileIcon,"profileIcon");
  const ShowProfile = () => {
    notofShowProfile();
  };
  const ShowNotification = () => {
    ShowNotifications();
  };
  const { state, dispatch } = React.useContext(Authcontext);
  // console.log(profileIcon, "profileIcon", state);

  // const [active, setActive] = useState("12");

  // const [searchValue, setSearchValue] = useState(false);
  const [layoutView, setLayoutView] = useState(false);

  // let student = localStorage.getItem("student");

  const [showHover, setshowHover] = useState(false);
  // const [sideShow, setSideShow] = useState(true);

  const [zoomState, setZoomState] = useState(false);
  const [goToPage, setGoToPage] = useState(false);

  // const [showSessions, setSessions] = React.useState(false);
  // const [showLanguage, setShowLanguage] = React.useState(false);
  const [showBookmarks, setShowBookmarks] = React.useState(false);
  const [notes, setNotes] = React.useState(false);
  const [clarify, setClarify] = React.useState(false);

  const [showSearch, setShowSearch] = React.useState(false);
  const [showIndexDetails, setShowIndexDetails] = React.useState(false);
  const [showZoomsize, setZoomsize] = React.useState(false);
  // const [clarifyfullview, setClarifyFullView] = useState(false);

  const [ZoomIn, setZoomIn] = useState(false);
  const [ZoomOut, setZoomOut] = useState(false);

  // console.log(showNotification,"showNotification",clarifyfullview)
  //   const ShowClarify = () => {
  //     setClarifyFullView();
  //     setNotification(false)
  //     setShowBookmarks(false);
  //     // setShowLanguage(false);
  //     falseShowLanguage();

  //     // setSessions(false);
  //     FalseShowSessions();
  //     setClarify(false);
  //     setShowSearch(false);
  //     setShowIndexDetails(false);
  //     setZoomsize(false);
  //     setNotes(false);
  //     setCollapse(false);
  //     setZoomState(false);
  //     setZoomIn(false);
  //     setZoomOut(false);
  // }

  const logoutfunc = async () => {
    let accToken = localStorage.getItem(`AccessToken`);
    let logBod = {
      userLoginID : JSON.parse(localStorage.userCreds).userName
    }
    let res = await Kampuslogout(logBod, accToken);
    const goToHomePage = () => navigate("/login");
    goToHomePage();
    dispatch({ type: 'LOGOUT' });
  };

  return (
    <>
      <div
        style={{
          backgroundColor: "#E5E5E5",
        }}
      >
        <div className="navbar-container">
          <div
            className="web-display-none"
            style={{
              marginLeft: "10px",
              marginRight: "10px",
            }}
          >
            <img
              src={ShLogo}
              onMouseEnter={() => setShowSchoolToolTip(true)}
              onMouseLeave={() => setShowSchoolToolTip(false)}
              style={{
                // width: '43px',
                // height: '43px',
                width: "43px",
                borderRadius: "50%",
                marginRight: "10px",
                marginLeft: "10px",
              }}
              alt=""
            />
          </div>

          <div className="navbar-title">
          <img src={KAMPUSNEW} style={{ width: "100%"}} />
          </div>

          <div className={"Header_Dashboard schoolName"}>
            {localStorage.length > 0 &&
              JSON.parse(localStorage.userCreds).institutionName.toUpperCase()}
          </div>
          {/* <div className={"Header_Dashboard"} >
        <Link to={'/dashboard'} className={(location.pathname === '/dashboard')|| (location.pathname === '/tdashboard') ? "nav-link Book_Icon" : "Book_Icon"} >
          <AiOutlineAppstore className={"menu_icon"} size="20" />
          Dashboard
        </Link>

        <Link to={'/BookView'} className={location.pathname === '/BookView' ? "nav-link Book_Icon" : "Book_Icon"} >
          <FiBookOpen className={"menu_icon"} size="20" />
          Katbook
        </Link>

        <Link to={'/timetable'} className={location.pathname === '/timetable' ? "nav-link Book_Icon" : "Book_Icon"}>
          <BiTime className={"menu_icon"} size="20" /> 
          Timetable
        </Link>

        <Link to={'/attendance'} className={location.pathname === '/attendance' ? "nav-link Book_Icon" : "Book_Icon"}>
          <MdInsertChartOutlined className={"menu_icon"} size="20" /> 
          Attendance
        </Link>

      </div> */}
          <div
            className={"Margin_Right_25px"}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              // marginRight: '15px',
            }}
          >
            {/* <div className={"M_Right"}>
          <FiBell
           onClick={()=>ShowNotification()} 
           className='notification-hover' style={{cursor:"pointer"}} size={20} />
        </div> */}
            <div
              onClick={ShowProfile}
              style={{
                display: "flex",
                flexDirection: "row",
                // marginRight: '15px',
              }}
            >
              <div onClick={ShowProfile} className="cursor-hover">
                <img
                  src={StLogo}
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    marginRight: "10px",
                    marginLeft: "10px",
                  }}
                  alt=""
                />
              </div>
              <div
                style={{
                  cursor: "pointer",
                }}
                className="dropdown-profile"
              >
                <div className={"Name_Nikil"}>
                  {localStorage.length > 0 &&
                    JSON.parse(localStorage.userCreds).adminName}
                </div>
                <div className={"Class_section"}>
                  {localStorage.length > 0 &&
                    JSON.parse(localStorage.userCreds).designation}
                </div>
              </div>
            </div>
            <div
              className="mob-display-none"
              onClick={ShowProfile}
              style={{
                marginLeft: "10px",
                marginRight: "10px",
                cursor: "pointer",
              }}
            >
              {showProfile ? (
                <RiArrowDropUpLine className="secondary-navbaricons" />
              ) : (
                <RiArrowDropDownLine className="dropdown-profile-hover secondary-navbaricons" />
              )}
            </div>
            <div
              className="mob-display-none"
              style={{
                marginLeft: "10px",
                marginRight: "10px",
              }}
              class="vl"
            ></div>
            <div
              className="mob-display-none"
              style={{
                marginLeft: "10px",
                marginRight: "10px",
              }}
            >
              <img
                src={ShLogo}
                onMouseEnter={() => setShowSchoolToolTip(true)}
                onMouseLeave={() => setShowSchoolToolTip(false)}
                style={{
                  // width: '43px',
                  // height: '43px',
                  width: "43px",
                  borderRadius: "50%",
                  marginRight: "10px",
                  marginLeft: "10px",
                }}
                alt=""
              />
            </div>
          </div>
        </div>
        {showProfile ? (
          <div className="profile-dropdown-container">
            <div className="dropdown-profile-title">
              {translateText ? translateText.Account : "Account"}
            </div>
            <div
              className="dropdown-profile-sub-title"
              style={{
                paddingTop: "12px",
              }}
            >
              <FaUserAlt size={18} />
              <div className="dropdown-profile-sub-title-name">
                {localStorage.length > 0 &&
                  JSON.parse(localStorage.userCreds).adminName}
              </div>
            </div>
            <div className="dropdown-profile-sub-title">
              {
                <>
                  <FaKey size={18} />
                  <div className="dropdown-profile-sub-title-name">
                    {localStorage.length > 0 &&
                      JSON.parse(localStorage.userCreds).userName}
                  </div>
                </>
              }
            </div>
            <hr className={"marginTop-10 marginBottom-10"} />
            <div
              className="dropdown-profile-sub-title"
              onClick={() => {
                logoutfunc();
              }}
              style={{
                paddingBottom: "10px",
              }}
            >
              <HiOutlineLogout size={18} />
              <div className="dropdown-profile-sub-title-name">
                {translateText ? translateText.logout : "Logout"}
              </div>
            </div>
          </div>
        ) : showNotification ? (
          <div
            onClick={() => {
              trueShowNotification();
            }}
            className={"Notify_Design"}
          >
            <div className={"Display_Notify_None"}>
              <div className={"LeftArrow_Notify"}>
                <TbArrowLeft
                  size="23"
                  style={{ color: "#66747F", cursor: "pointer" }}
                  onClick={() => ShowNotification()}
                />
                <span className={"Notify_Font"}>Notifications</span>
              </div>
            </div>

            {/* <div style={{backgroundColor:"#E5E5E5"}}>
          <p>kalai</p>
          </div> */}
            <div className={"Padding_Mar"}>
              <div className="py-2 Back_Padd_Mar">
                <div className="D_Flex_1 ">
                  <div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "15px",
                      }}
                    >
                      <div>
                        <img src={Notify} />
                      </div>

                      <div className="ml-2">
                        <div className={"Notify_Flex_Row_Col"}>
                          <div className="Noti_Karthick">Karthick</div>
                          <div className="R_F_C ">
                            Request for Clarification
                          </div>
                        </div>
                        <div
                          style={{
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontWeight: 500,
                            fontSize: "12px",
                            color: "#ADB9C7",
                          }}
                        >
                          Class XI-A
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="Mins">
                    <span>20 mins ago</span>
                  </div>
                </div>

                <div>
                  <h6 className="Content_H6">
                    What is plant cloning used for?
                    {/* <span className={"V_Clarify"} 
          onClick={()=>{
            indexFun("STATIC_CLARIFY")
          }}
          >
          View Clarification
          </span> */}
                  </h6>
                </div>
              </div>
            </div>

            <div className={"Padding"}>
              <div className="py-3 Back_Padd_Mar ">
                <div className="D_Flex_1">
                  <div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "15px",
                      }}
                    >
                      <div>
                        <FiBell size={20} className="Noti_Bell" />
                      </div>
                      <div>
                        <div className={"Res_Pub"}>Results Published</div>
                        <span className="Noti_U_T_2">
                          Unit test 2 result has been published
                        </span>

                        {/* <div> 
            <span className='View_R'>View Result </span>
          </div> */}
                      </div>
                    </div>
                  </div>

                  <div className="Mins">
                    <span>1 day ago</span>
                  </div>
                </div>
              </div>
            </div>

            <div className={"Padding"}>
              <div className={"py-2 Back_Padd_Mar"}>
                <div className="D_Flex_1">
                  <div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "15px",
                      }}
                    >
                      <div>
                        <img src={Notify_Img} />
                      </div>

                      <div className="ml-2">
                        <div className={"Notify_Flex_Row_Col"}>
                          <div className="Noti_Karthick">Arun Kumar</div>
                          <div className="R_F_C ">Answer for Clarification</div>
                        </div>
                        <div
                          style={{
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontWeight: 500,
                            fontSize: "12px",
                            color: "#ADB9C7",
                          }}
                        >
                          Teacher
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="Mins">
                    <span>20 mins ago</span>
                  </div>
                </div>

                <div>
                  <h6 className="Content_H6">
                    The cloning of plants has many important commercial
                    implications. It allows a variety of a plant with desirable
                    characteristics to be produced cheaply, quickly and on a
                    large scale. Cloning often follows genetic engineering. It
                    allows many copies of the new organism to be produced.
                    {/* <span style={{color:"#3377FF",cursor:"pointer"}}>
          ..View Answer
          </span> */}
                  </h6>
                </div>
              </div>
            </div>

            <div className={"Padding"}>
              <div className={"Back_Padd_Mar1"}>
                <div
                  className="display_None_Web_A Back_Padd_Mar"
                  style={{ borderBottom: "1px solid #E6ECF1" }}
                >
                  <div className="D_Flex_A">
                    <div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "15px",
                        }}
                      >
                        <div>
                          <FiBell size={20} className="Noti_Bell" />
                        </div>

                        <div>
                          <span className="Announcement_Noti">
                            Announcements
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="Mins">
                      <span>1 week ago</span>
                    </div>
                  </div>
                  <div className={"Class_Holiday"}>
                    Apr 24 will be holiday for all the classes
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {showSchoolToolTip ? (
          <div className={"School_Tooltip"}>
            <div className={"School_Tooltip_Cube"}>
              <div
                style={{
                  width: 0,
                  height: 0,
                  borderLeft: "5px solid transparent",
                  borderRight: "5px solid transparent",
                  borderBottom: "5px solid #35414D",
                }}
              ></div>
            </div>
            <div
              className="inter-font-500 font-12-px color-EEF2F5"
              style={{
                backgroundColor: "#35414D",
                padding: "12px",
                borderRadius: "7px",
                height: "auto",
              }}
            >
              <div className="inter-font-500 font-12-px">
                <span style={{ color: "#EEF2F5", fontFamily: "Inter" }}>
                  {institutionName}
                </span>
              </div>
            </div>
          </div>
        ) : null}

        <div className={"main_subs"}>
          <SubHeader />
        </div>
      </div>
    </>
  );
};

HeaderNavbar.defaultProps = {
  showProfile: false,
  dropdownEmail: "",
  dropdownUser: "",
};

export default HeaderNavbar;
