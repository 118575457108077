const SessionSubMenu =  [
    // Teacher Login Menu
  {
    title: 'Katbook Session Plan',
    url: '/KatbookSessionPlan',
  },
  {
      title: 'View Session Plan',
      url: '/ViewSessionPlan',
  },
           
  ]

const subMenuAssessment =  [
    {
      title: 'Create Assignment',
      url: '../assignment/create',
    },
    {
      title: 'Create Question',
      url: '../assignment/createquestion',
    },
    {
      title: 'Post',
      url: '../assignment/post',
    },
    {
        title: 'View',
        url: '../assignment/view',
      },
           
  ]

export const TeachermenuItems = [
    // ...
    {
      title: 'Dashboard',
      page: 1,
      url: '/dashboard',
    },
    {
        title: 'Katbook',
        page: 2,
        url: '/BookView',
      },
      {
        title: 'Timetable',
        page: 3,
        url: '/timetable',
      }, 
      {
        title: 'Attendance',
        page: 4,
        url: '/attendance',
      },    
     {
        title : 'Assignment',
        submenu: subMenuAssessment
    },
    {
      title : 'Report',
      url: '/StudentsReport'
    },
    {
      title : 'Session Planning',
      submenu: SessionSubMenu
    },
    {
      title : 'Diary',
      url: '/diary'
      // submenu: diarySubMenu
    }
  ];